import { createStyles, rem } from 'hooks'

interface SidebarStyleProps {
  collapsed: boolean
  isMobile: boolean
}

export const useStyles = createStyles((theme, { collapsed, isMobile }: SidebarStyleProps) => ({
  sidebar: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    height: isMobile ? (collapsed ? rem(88) : '100%') : '100%',
    width: collapsed ? rem(81) : rem(260),
    backgroundColor: isMobile
      ? collapsed
        ? 'transparent'
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.white
      : theme.colorScheme === 'dark'
        ? theme.colors.dark[8]
        : theme.white,
    borderRight: isMobile
      ? collapsed
        ? 'none'
        : `1px solid ${theme.colors.gray[2]}`
      : `1px solid ${theme.colors.gray[2]}`,
    zIndex: 98,
    transition: 'transform .3s ease-in-out,width .3s ease-in-out',
    boxShadow: isMobile ? (collapsed ? 'none' : '0 0 10px 2px #00000026') : '0 0 10px 2px #00000026',
    overflow: 'hidden',
  },
  toggleLogoBox: {
    width: rem(260),
    borderBottom: isMobile
      ? collapsed
        ? ''
        : `1px solid ${theme.colors.gray[2]}`
      : `1px solid ${theme.colors.gray[2]}`,
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  listRoot: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
  },
  toggleButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: rem(84),
    height: rem(88),
  },
  actionIconToggle: {
    marginLeft: '-6px',
  },
  iconToggle: {
    transform: 'scale(1.2, 1)',
    color: isMobile ? (collapsed ? theme.white : theme.colors.dark[8]) : theme.colors.dark[8],
  },
  iconToggleBack: {
    color: isMobile ? theme.white : theme.colors.dark[8],
  },
}))
