import { useContext } from 'react'
import { TextLink, Grid, Stack, Text, Title, Box, BreadCrumbList } from 'components'
import { CardSkillsStandardsLvl4 } from '../CardSkillsStandardsLvl4'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { IconChevronLeft } from '@tabler/icons-react'
import { ILvl2Item, ILvl3Item } from '../ProjectSkillsStandards/interfaces'
import { useTranslation } from 'react-i18next'
import { CardSkillsStandardsLvl2 } from '../CardSkillsStandardsLvl2'
import { ListSkillsStandardsPage2Skeleton } from '../ListSkillsStandardsPageSkeleton'

export const ListSkillsStandardsPage2 = () => {
  const { t } = useTranslation()
  
  const DEFAULT_COLOR = 'gray'

  const {
    currentSkillStandard,
    currentTab,
    currentMoreItems,
    page2Data,
    page2Loaded,
    handleBack,
    skillsStandardsInfo,
  } = useContext(SkillsStandardsContext)

  const color = currentSkillStandard.color ? currentSkillStandard.color : DEFAULT_COLOR
  const lv1Name = currentSkillStandard.name ? currentSkillStandard.name : ''
  const lv1ID = currentSkillStandard.id ? currentSkillStandard.id : ''
  const lv2Name = currentSkillStandard.children?.length ? currentSkillStandard.children[0].name : ''
  const lv2ID = currentSkillStandard.children?.length ? currentSkillStandard.children[0].id : ''

  const noItems = page2Loaded === true && page2Data && page2Data.length === 0
  const hasItems =
    page2Loaded === true && page2Data && page2Data.length > 0 && currentSkillStandard && skillsStandardsInfo
  
  return (
    <>
      {!noItems && !hasItems && <ListSkillsStandardsPage2Skeleton />}
      {noItems && !hasItems && <Text>{t('new_project_page.no_items_found')}</Text>}
      {hasItems && (
        <Stack>
          <TextLink iconLeft={<IconChevronLeft />} onClick={() => handleBack()}>
            {t('new_project_page.back_previous_level')}
          </TextLink>
          {page2Data.map((lvl3: ILvl3Item) => (
            <Box mt='xs' key={lvl3.id}>
              <BreadCrumbList lvl1={lv1Name} lvl2={lv2Name} lvl3={lvl3.name} color={color} />
              <Grid mt='xs'>
                {lvl3.children && lvl3.children.length > 0 ? (
                  lvl3.children?.map(lvl4 => {
                    const info = skillsStandardsInfo.find(item => item.id === lvl4.id)
                    return (
                      <Grid.Col key={lvl4.id} xs={12} sm={6} md={6} lg={4}>
                        <CardSkillsStandardsLvl4
                          item={lvl4}
                          color={color}
                          lv1ID={lv1ID}
                          lv2ID={lv2ID}
                          developedStudents={info?.developedStudents}
                          ongoingProjects={info?.ongoingProjects}
                        />
                      </Grid.Col>
                    )
                  })
                ) : (
                  <Text>{t('new_project_page.no_items_found')}</Text>
                )}
              </Grid>
            </Box>
          ))}
          {currentMoreItems && currentMoreItems.length > 0 ? (
            <Box>
              <Title mt='lg' order={4}>
                {t('new_project_page.more_on')} {lv1Name}
              </Title>
              <Grid mt='xs'>
                {currentMoreItems.map((item: ILvl2Item) => (
                  <Grid.Col key={item.id} xs={12} sm={6} md={4} lg={3}>
                    <CardSkillsStandardsLvl2 item={item} color={color} type={currentTab} />
                  </Grid.Col>
                ))}
              </Grid>
            </Box>
          ) : null}
          <TextLink iconLeft={<IconChevronLeft />} onClick={() => handleBack()}>
            {t('new_project_page.back_previous_level')}
          </TextLink>
        </Stack>
      )}
    </>
  )
}
