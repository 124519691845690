import { useMediaQuery, useMantineTheme } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Stack, Button, FileListGoogleDrive, FileListUploaded } from 'components'
import { IconUpload, IconBrandGoogleDrive } from '@tabler/icons-react'

interface MultiUploadProps {
  onRemoveLocalFile: () => void
}

export const MultiUpload = ({ onRemoveLocalFile }: MultiUploadProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const files = [
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '1',
      name: 'Arquivo 1',
    },
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '2',
      name: 'Arquivo 2',
    },
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '3',
      name: 'Arquivo 3',
    },
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '4',
      name: 'Arquivo 4',
    },
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '5',
      name: 'Arquivo 5',
    },
    {
      src: 'https://robohash.org/natusquaetemporibus.png?size=300x300&set=set1',
      id: '6',
      name: 'Arquivo 6',
    },
  ]

  return (
    <Stack spacing='sm' align='flex-start'>
      <FileListUploaded onRemove={onRemoveLocalFile} files={files} />
      <Button fullWidth={isMobile} size='md' variant='light' leftIcon={<IconUpload />}>
        {t('general_buttons_inputs_upload_file')}
      </Button>
      <FileListGoogleDrive onRemove={onRemoveLocalFile} files={files} />
      <Button fullWidth={isMobile} size='md' variant='light' leftIcon={<IconBrandGoogleDrive />}>
        {t('general_buttons_google_drive')}
      </Button>
    </Stack>
  )
}
