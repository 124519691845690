
import { createStyles, rem } from 'hooks'

export const useStyles = createStyles(theme => ({
  button: {
    display: 'flex',
    width: rem(40),
    height: rem(40),
    border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[3]}`,
    borderRadius: '100%',
    justifyContent: 'center',
    padding: 0,
    alignItems: 'center',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
    },
    '&:disabled': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.colors.gray[5] : theme.colors.gray[5],
    },
  },
  checkbox: {
    display: 'none',
    input: {
      cursor: 'pointer',
    },
    marginRight: theme.spacing.sm,
  },
  checked: {
    backgroundColor: theme.colors.turquoise[4],
    color: theme.white,
    borderColor: theme.colors.turquoise[6],
    '&:hover': {
      backgroundColor: theme.colors.turquoise[5],
    },
  },
}))
