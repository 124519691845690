import styled from 'styled-components'

export const StyledIconPathFill = styled.path`
  fill: var(--color-black);
`

export const StyledIconTextPathFill = styled.path`
  fill: var(--color-text-primary);
`

export const StyledIconPathStroke = styled.path`
  stroke: ${({ disabled }) =>
    (disabled ? 'var(--color-gray-light)' : 'var(--color-black)')};
`

export const StyledIconCircleStroke = styled.circle`
  stroke: var(--color-black);
`
