import i18n from '../../../i18n'


export const today = new Date()
const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
const locale = i18n.language

export function toDateString (raw?: string, v2?: boolean): string {
  const date = raw ? new Date(raw) : today

  if (!(date instanceof Date)) {
    throw new Error('Should be a valid date string')
  }

  const formattedDate = new Intl.DateTimeFormat(locale).format(date)

  if (v2 && new Intl.DateTimeFormat(locale).format(today) === formattedDate) {
    return i18n.t('datetime.timespan.day.one')
  }

  if (v2 && new Intl.DateTimeFormat(locale).format(yesterday) === formattedDate) {
    return i18n.t('datetime.timespan.yesterday')
  }

  return formattedDate
}

export function toTodayString (v2?: boolean): string {
  return toDateString(undefined, v2)
}

export function toDate (raw: string): Date {
  return new Date(I18n.parseDate(raw))
}

export function isCurrentMonth (someDate: Date): boolean {
  return (
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export function isToday (someDate: Date): boolean {
  return (
    someDate.getDate() === today.getDate() && isCurrentMonth(someDate)
  )
}

export function toTimeString (raw?: string): string {
  if (!raw) {
    return ''
  }

  const date = toDate(raw)
  return new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric'
  }).format(date)
}

export function toMomentString (raw?: string): string {
  if (!raw) {
    return ''
  }

  const date = toDate(raw)
  if (!(date instanceof Date)) {
    return ''
  }

  if (isToday(date)) {
    return toTimeString(raw)
  }

  return toDateString(raw, true)
}
