import { Paper } from '@mantine/core'
import { IconAt } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { PaperStyled } from './styled'
import { TextInput } from 'components/TextInput'
import { Flex } from 'components/Flex'
import { useMediaQuery } from '@mantine/hooks'
import { Button } from 'components/Button'
import { useForm } from '@mantine/form'
import service, { IPayload } from './service'
import { useNotification } from 'context/useNotificationContext'
import { useEffect } from 'react'

export const BodyForgotPasswordScreen = () => {
  const { t } = useTranslation()
  const { handleNotification } = useNotification()
  const isMobile = useMediaQuery('(max-width: 600px)')

  const form = useForm({
    initialValues: {
      email: '',
    },
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const errorType = searchParams.get('error')

    switch (errorType) {
      case 'format_invalid':
        handleNotification(true, t('forgot_password.format_invalid'), true, 'error')
        break
      case 'not_found':
        handleNotification(true, t('forgot_password.not_found'), true, 'error')
        break
      case 'not_changed':
        handleNotification(true, t('forgot_password.not_changed'), true, 'error')
        break
      default:
        break
    }
  }, [])

  const handleSubmit = async (values: typeof form.values) => {
    const response = await service.saveEmail(values as IPayload)
    if (response.errors) {
      const mapperErrors = {
        format_invalid: t('student_creation_screen.invalid_email'),
        blank: t('errors_messages_empty'),
        not_found: t('forgot_password.error_not_found'),
      }
      const error = {
        email:
          mapperErrors[response?.errors] ||
          handleNotification(true, t('forgot_password.error_not_changed'), true, 'error'),
      }
      form.setErrors(error)
      handleNotification(true, t('student_creation_screen.mensage_error'), true, 'error')
    } else {
      handleNotification(true, t('forgot_password.notification_success'), true, 'success')
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Flex justify='center'>
        <PaperStyled>
          <Paper shadow='md' radius='md' p='xl'>
            <TextInput
              id='input-email'
              size='md'
              aria-label={t('forgot_password.email')}
              label={t('forgot_password.email')}
              name='email'
              withAsterisk
              {...form.getInputProps('email')}
              icon={<IconAt />}
            />
            <Flex justify='right'>
              <Button radius='xl' size='lg' type='submit' mt='lg' fullWidth={isMobile}>
                {t('forgot_password.reset_password')}
              </Button>
            </Flex>
          </Paper>
        </PaperStyled>
      </Flex>
    </form>
  )
}
