import { Routes as RoutesContainer, Route } from 'react-router-dom'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { ErrorPermission } from 'pages/ErrorPermission'
import { SignIn } from 'pages/SignIn'
import { EditReport } from 'pages/Projects/Lessons'
import { NewProjectList } from 'pages/ProjectsList'
import { NewProject } from 'pages/NewProject'
import { NewProjectView } from 'pages/NewProjectView'
import { SignOut } from 'pages/SignOut'
import { FinalAssessmentPage } from 'pages/FinalAssessmentPage'
import { ReportsDashboard, ReportsStudent, ReportsGroup } from 'pages/Reports'
import { StudentCreationScreen } from 'pages/StudentCreationScreen'
import { GuardianDashboardPage } from 'pages/GuardianDashboard'
import { TeacherDashboardPage } from 'pages/TeacherDashboard'
import { StudentDashboardPage } from 'pages/StudentDashboard'
import { ForgotPasswordScreen } from 'pages/ForgotPassword'
import { ChangePasswordScreen } from 'pages/ForgotPassword/ChangePasswordScreen'
import Students from 'pages/Students'
import { FeedPage } from 'pages/Feed'
import { NotFoundPage } from 'pages/NotFound'
import { FinalProjectReportPage } from 'pages/FinalProjectReport'

export type IProfiles = 'student' | 'tutor' | 'guardian' | 'manager'
export type USerKind = IProfiles[]

export const Routes = () => {
  return (
    <RoutesContainer>
      <Route
        path='/error_permission'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ErrorPermission />
          </ProtectedRoute>
        }
      />
      <Route path='account/signin' element={<SignIn />} />
      <Route path='account/signout' element={<SignOut />} />
      <Route path='/forgot-password' element={<ForgotPasswordScreen />} />
      <Route path='/new-password' element={<ChangePasswordScreen />} />
      <Route
        path='/feed'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <FeedPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='projects'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <NewProjectList />
          </ProtectedRoute>
        }
      />
      <Route
        path='/projects/new'
        element={
          <ProtectedRoute enabledProfiles={['tutor']} extraProtection='new-project'>
            <NewProject />
          </ProtectedRoute>
        }
      />
      <Route
        path='/projects/:id/edit'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <NewProject />
          </ProtectedRoute>
        }
      />
      <Route
        path='/projects/:id'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <NewProjectView />
          </ProtectedRoute>
        }
      />
      <Route
        path='projects/lessons/:lessonId/edit_report'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <EditReport />
          </ProtectedRoute>
        }
      />
      <Route
        path='/projects/final_assessments/:lessonId'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'manager']}>
            <FinalAssessmentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/final_project_report/:id'
        element={
          <ProtectedRoute enabledProfiles={['student', 'guardian', 'tutor', 'manager']}>
            <FinalProjectReportPage />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reports/dashboard'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <ReportsDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reports/student'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ReportsStudent />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reports/group'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ReportsGroup />
          </ProtectedRoute>
        }
      />
      <Route
        path='/learners'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <Students />
          </ProtectedRoute>
        }
      />
      <Route
        path='/learners/new'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <StudentCreationScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path='/learners/new/:id'
        element={
          <ProtectedRoute enabledProfiles={['tutor', 'manager']}>
            <StudentCreationScreen />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reports/student'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ReportsStudent />
          </ProtectedRoute>
        }
      />

      <Route
        path='/guardian_room/dashboard'
        element={
          <ProtectedRoute enabledProfiles={['guardian']}>
            <GuardianDashboardPage />
          </ProtectedRoute>
        }
      />

      <Route
        path='/teacher/dashboard'
        element={
          <ProtectedRoute enabledProfiles={['tutor']}>
            <TeacherDashboardPage />
          </ProtectedRoute>
        }
      />

      <Route
        path='/student_room/dashboard'
        element={
          <ProtectedRoute enabledProfiles={['student']}>
            <StudentDashboardPage />
          </ProtectedRoute>
        }
      />

      <Route
        path='/reports/dashboard'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ReportsDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path='/reports/group'
        element={
          <ProtectedRoute enabledProfiles={['student', 'tutor', 'guardian', 'manager']}>
            <ReportsGroup />
          </ProtectedRoute>
        }
      />

      <Route path='*' element={<NotFoundPage />} />
    </RoutesContainer>
  )
}
