import React from 'react'
import { IconChartDonut, IconUsers, IconTrash, IconEdit, IconUser, IconChartBar } from '@tabler/icons-react'
import { IActionIcon } from '../../pages/Students/types/student-types'

type IStudentAction = 'delete' | 'edit' | 'guardians' | 'mosaic' | 'show' | 'report'

const icons = {
  show: <IconUser />,
  edit: <IconEdit />,
  delete: <IconTrash />,
  mosaic: <IconChartDonut />,
  guardians: <IconUsers />,
  report: <IconChartBar />,
}

const ActionIcon: React.FC<IActionIcon> = ({ name }: { name?: IStudentAction }) => {
  return icons[name as IStudentAction] || null
}

export default ActionIcon
