import { Page, Text, View, Image, Font } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import { getInitials } from 'helpers'
import {
  ProjectsContainer,
  CurricularComponentScores,
  GroupSkillScores,
  SkillReport,
} from './utils'
import { IData } from './interface'
import styles from './styled'
import ReportFromHtml from './ReportFromHtml'

Font.registerHyphenationCallback(word => [word])

const initialState = {
  id: '',
  period: '',
  year: '',
  score: 0,
  skillScores: [],
  tutors: [],
  report: '',
  curricularComponentScores: [],
  groupSkillScores: [],
  projects: [],
  student: {
    interests: [],
    firstName: '',
    developedSkills: [],
    avatar: '',
    fullName: '',
    report_id: '',
  },
  group: '',
}

const CommonPdf = ({ data = initialState }: { data: IData }) => {
  const { t } = useTranslation()

  const avatar = data?.student.avatar

  const numberOfProjects = data?.projects.length
  const lookGroup = data?.groupSkillScores.length

  return (
    <Page size="A4" style={styles.page}>
      <View>
        <View style={styles.header}>
          <View style={styles.imgContainer}>
            {data?.student.avatar.length ? (
              <Image style={styles.avatar} src={avatar} />
            ) : (
              <Text>
                {data?.student.fullName.length
                  ? getInitials(data?.student.fullName)
                  : ''}
              </Text>
            )}
          </View>
          <View style={styles.headerText}>
            <Text style={styles.titleXL}>
              {t('end_of_term_reports.final_period_report')}
            </Text>
            <Text style={styles.titleXL}>{data?.student.fullName}</Text>
            <Text style={styles.titleLG}>{data.group}</Text>
            <Text style={styles.termDate}>
              {data?.period} {t('end_of_term_reports.of')} {data?.year}
            </Text>
          </View>
        </View>
        <View style={styles.PeriodTextContainer}>
          <Text style={styles.p}>
            {t('end_of_term_reports.introduction')}
          </Text>
        </View>
        {data?.skillScores.length > 0 && (
          <View style={styles.selfSkillsBlock}>
            <View>
              <Text style={styles.titleMD}>
                {t('end_of_term_reports.everyday_skills')}
              </Text>
              <Text style={styles.p}>
                {t('end_of_term_reports.everyday_skills_description')}
              </Text>
            </View>
            <View style={styles.skillsContainer}>
              {data?.skillScores.map((item, index) => (
                <SkillReport key={item.id} {...item} index={index} />
              ))}
            </View>
          </View>
        )}
        {lookGroup > 0 && (
          <View style={styles.groupSkillsBlock}>
            <View>
              <Text style={styles.titleMD}>
                {t('end_of_term_reports.look_at_the_group')}
              </Text>
              <Text style={styles.p}>
                {t('end_of_term_reports.look_at_the_group_description')}
              </Text>
            </View>
            <View style={styles.skillsContainer}>
              {data?.groupSkillScores.map((item, index) => (
                <GroupSkillScores key={item.id} {...item} index={index} />
              ))}
            </View>
          </View>
        )}
        {data?.report !== '' && (
          <View style={styles.contentBlock}>
            <Text style={styles.titleMD}>
              {t('end_of_term_reports.report_of')}
            </Text>
            <ReportFromHtml stringhtml={data?.report} />
          </View>
        )}
        {numberOfProjects > 0 && (
          <View style={styles.contentBlock}>
            <View>
              <Text style={styles.titleMD}>
                {t('end_of_term_reports.lumiar_practices')}
              </Text>
              <Text style={styles.p}>
                <Text style={styles.bold}>{numberOfProjects}</Text>
                {t(
                  'end_of_term_reports.lumiar_practices_description_2_pdf',
                )}
              </Text>
            </View>
            <View style={styles.skillsContainer}>
              {data?.projects.map(item => (
                <ProjectsContainer key={item.id} {...item} />
              ))}
            </View>
          </View>
        )}
        {data?.curricularComponentScores.length > 0 && (
          <View wrap={false} style={styles.contentBlock}>
            <View>
              <Text style={styles.titleMD}>
                {t('end_of_term_reports.evaluation_by_knowledge_area')}
              </Text>
              <Text style={styles.p}>
                {t(
                  'end_of_term_reports.evaluation_by_knowledge_area_description',
                )}
              </Text>
            </View>
            <View style={styles.ComponentScoresContainer}>
              {data?.curricularComponentScores.map(item => (
                <CurricularComponentScores key={item.id} {...item} />
              ))}
            </View>
          </View>
        )}
        <View wrap={false}>
          <View style={styles.teacherContainer}>
            <Text style={styles.titleMD}>
              {t('end_of_term_reports.thank_you')}
            </Text>
          </View>
          <View style={styles.tutorsContainer}>
            {data?.tutors.map((tutor, index) => (
              <View
                style={{
                  width: '30%',
                  display: 'flex',
                  flexDirection: 'row',
                  marginLeft: index % 3 === 0 ? '0px' : '6px',
                  marginBottom: '12px',
                }}
                key={tutor.id}
              >
                <View style={styles.imgContainerSmall}>
                  {tutor.avatar.length > 0 ? (
                    <Image style={styles.avatar} src={tutor.avatar} />
                  ) : (
                    <Text>
                      {tutor.fullName ? getInitials(tutor.fullName) : ''}
                    </Text>
                  )}
                </View>
                <View>
                  <Text style={styles.p}>{tutor.fullName}</Text>
                  <Text style={styles.educatorRole}>{tutor.educatorRole}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>
    </Page>
  )
}

export default CommonPdf
