import styled from 'styled-components'

export const ContainerGlobal = styled.div<{ isApp: boolean }>`
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding: 80px 40px 80px 120px;

  @media only screen and (min-width: 800px) and (max-width: 1366px) {
    padding: 80px 40px 80px 120px;
  }
  @media only screen and (max-width: 800px) {
    padding: ${({ isApp }) => (isApp ? '0' : '100px 20px 80px 20px')};
  }

  @media (max-width: 680px) {
    .rodal-dialog {
      padding: 0;
    }
  }

  .__react_component_tooltip.show {
    max-width: 600px;
  }
`

export const ContainerNotification = styled.div`
  max-width: 60rem;
  margin-left: auto;
  margin-top: 2rem;
  position: fixed;
  right: 40px;
  z-index: 1000000;

  @media only screen and (max-width: 800px) {
    max-width: 40rem;
    right: 20px;
  }
`
