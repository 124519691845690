export interface WeekDaySelectorProps {
  name: string
  label: string
  disabled?: boolean
  withAsterisk?: boolean
  handleWeekDays: (day: number[]) => void
  startDate?: string
  selectedDays?: number[]
  repeatAllWeek?: boolean
}

export const daysOfWeek = [
  'general_weekday_sunday_letter',
  'general_weekday_monday_letter',
  'general_weekday_tuesday_letter',
  'general_weekday_wednesday_letter',
  'general_weekday_thursday_letter',
  'general_weekday_friday_letter',
  'general_weekday_saturday_letter',
]
