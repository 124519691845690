import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea, useMediaQuery, useMantineTheme } from 'hooks'
import { LessonsContext } from '../../context/LessonsContext'
import { Box, Button, Grid, Modal, Stack, Text, TextInput } from 'components'
import { useForm, notifications, modals } from 'context'
import { ICriteriaFormValues, initialValues } from './interfaces'
import { IconX } from '@tabler/icons-react'
import { useStyles } from './styles'
import { IconHands } from 'components/Icons'
import _ from 'lodash'

interface IGenericObject<T> {
  [key: string]: T | undefined
}
interface IProps {
  lv4Items: any
  editedCriteria: any
  handleEvaluativeCriteria: (values: any) => void
  searchItem: (values: any) => void
}

interface iItem {
  id?: string
  _id?: string
  description: string
}

export const CriteriaModal = ({ lv4Items, handleEvaluativeCriteria, editedCriteria, searchItem }: IProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useStyles()
  const { openedCriteriaModal, closeCriteriaModal, editingCriteria } = useContext(LessonsContext)
  const [itemEdited, setItemEdited] = useState<null | iItem[]>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [initialValuesOpen, setInitialValuesOpen] = useState<ICriteriaFormValues>({} as ICriteriaFormValues)

  const validateField = (value: string | undefined) => (!value || value.trim().length < 3 ? t('required_field') : null)

  const form = useForm<ICriteriaFormValues>({
    initialValues,
    validate: {
      value1: validateField,
      value2: validateField,
      value3: validateField,
      value4: validateField,
      value5: validateField,
    },
  })

  const handleForm = async (values: ICriteriaFormValues) => {
    const attributes = Array.from({ length: 5 }, (_, i) => {
      const key = `value${i + 1}` as keyof ICriteriaFormValues
      return {
        score: i + 1,
        description: values[key],
        ...(itemEdited !== null ? { id: itemEdited[i]?.id } : null),
      }
    })

    const payloadItem = {
      _id: editingCriteria,
      activity_lv4_item_how_to_assesses_attributes: attributes,
    }

    handleEvaluativeCriteria(payloadItem)
    closeCriteriaModal()
  }

  const searchItemIfItExistsbyEditingCriteriaId = (lv4Items: any, editedCriteria: any) => {
    return (
      editedCriteria?.find((item: any) => item?._id === editingCriteria) ||
      lv4Items?.find((item: any) => item?.lv4ItemId === editingCriteria) ||
      null
    )
  }

  const initializeForm = () => {
    const item = searchItemIfItExistsbyEditingCriteriaId(lv4Items, editedCriteria)

    if (
      item?.activityLv4ItemHowToAssesses?.length > 0 ||
      item?.activity_lv4_item_how_to_assesses_attributes?.length > 0
    ) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }

    if (item) {
      const propertyNames = ['activityLv4ItemHowToAssesses', 'activity_lv4_item_how_to_assesses_attributes']
      propertyNames.forEach(propertyName => {
        if (Object.prototype.hasOwnProperty.call(item, propertyName)) {
          setItemEdited(item[propertyName])
          let temporary = {}
          for (let i = 0; i < 5; i++) {
            form.setFieldValue(`value${i + 1}`, item[propertyName][i]?.description)
            temporary = { ...temporary, [`value${i + 1}`]: item[propertyName][i]?.description }
          }

          setInitialValuesOpen(temporary as ICriteriaFormValues)
        }
      })
    }
  }

  const handleErrors = (errors: unknown) => {
    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: t('fill_the_required_fields'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const customIsEqual = (
    obj1: IGenericObject<string | undefined>,
    obj2: IGenericObject<string | undefined>,
  ): boolean => {
    return Object.keys(obj1).every(key => {
      const val1 = obj1[key]
      const val2 = obj2[key]
      return (val1 === undefined && val2 === '') || (val1 === '' && val2 === undefined) || val1 === val2
    })
  }

  useEffect(() => {
    openedCriteriaModal ? initializeForm() : (form.reset(), setItemEdited(null))
  }, [openedCriteriaModal])

  const handleOnClose = () => {
    const resetValues = () => {
      form.resetDirty()
      setInitialValuesOpen({} as ICriteriaFormValues)
      closeCriteriaModal()
      setItemEdited(null)
    }

    const openConfirmModal = () => {
      modals.openConfirmModal({
        style: { zIndex: 202, position: 'absolute' },
        title: t('general_changed_confirmation_modal_title'),
        size: 'lg',
        children: <Text size='sm'>{t('general_changed_confirmation_modal_message')}</Text>,
        labels: { confirm: t('new_project_page.confirm'), cancel: t('new_project_page.warnning_groupt_button1') },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onConfirm: resetValues,
      })
    }

    const isDifferent = _.isEmpty(initialValuesOpen) && Object.values(form.values).some(val => val !== '')

    if (isDifferent) {
      openConfirmModal()
      return
    } else {
      const openModalOnFirsAddCriteria =
        _.isEmpty(initialValuesOpen) &&
        _.isEqual(form.values, { value1: '', value2: '', value3: '', value4: '', value5: '' })
      const notWasChanged =
        _.isEqual(initialValuesOpen, form.values) ||
        customIsEqual(initialValuesOpen, form.values) ||
        openModalOnFirsAddCriteria

      if (notWasChanged) return resetValues()
      else openConfirmModal()
    }
  }

  return (
    <Modal
      zIndex={200}
      opened={openedCriteriaModal}
      onClose={handleOnClose}
      title={isEditing ? t('new_project_page.criteria_modal_title_edit') : t('new_project_page.criteria_modal_title')}
      size='xl'
      scrollAreaComponent={ScrollArea.Autosize}
      fullScreen={isMobile}
    >
      <form className={classes.form} onSubmit={form.onSubmit(handleForm, handleErrors)}>
        <Stack spacing='md'>
          <Text>{t('new_project_page.criteria_modal_text')}</Text>
          {Array.from({ length: 5 }, (_, value) => (
            <Grid key={value}>
              <Grid.Col span={12} xs={12} sm={2} className={classes.iconBox}>
                <IconHands width={56} height={56} color='black' value={value + 1} />
              </Grid.Col>
              <Grid.Col span={12} xs={12} sm={10}>
                <TextInput
                  name={`value${value + 1}`}
                  size='md'
                  placeholder={t(`rubric_${value + 1}`)}
                  label={t(`new_project_page.criteria_label_value${value + 1}`)}
                  {...form.getInputProps(`value${value + 1}`)}
                />
              </Grid.Col>
            </Grid>
          ))}
        </Stack>
        <Box className={classes.footer}>
          <Button variant='default' size='lg' onClick={handleOnClose}>
            {t('general_buttons_cancel')}
          </Button>
          <Button variant='filled' size='lg' type='submit'>
            {t('general_buttons_save')}
          </Button>
        </Box>
      </form>
    </Modal>
  )
}
