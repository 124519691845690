import React from 'react'
import { ButtonStyled, ButtonIconOnlyStyled } from './styled'
import { ButtonProps } from './interfaces'
import CircularLoader from '../../../../components/CircularLoader/CircularLoader'

const Button: React.FC<ButtonProps> = ({
  text,
  disabled = false,
  target = '_self',
  kind = 'primary',
  className,
  children,
  width,
  height,
  icon,
  iconPosition = 'right',
  colorText,
  colorBackground,
  colorBorder,
  size = 'small',
  hoverBackgroundColor,
  hoverColorBorder,
  hoverTextColor,
  onClick,
  active,
  hide = false,
  loading = false,
  ...rest
}: ButtonProps) => {
  const ButtonInstance =
    kind === 'iconOnly' ? ButtonIconOnlyStyled : ButtonStyled
  if (hide) {
    return <> </>
  }

  return (
    <ButtonInstance
      kind={kind}
      onClick={onClick}
      size={size}
      disabled={disabled}
      active={active}
      width={width}
      height={height}
      className={className}
      colorText={colorText}
      colorBackground={colorBackground ?? '#F4F6F9'}
      hoverBackgroundColor={hoverBackgroundColor ?? '#F4F6F9'}
      hoverTextColor={hoverTextColor}
      target={target}
      colorBorder={colorBorder ?? '1px solid var(--color-gray-light)'}
      hoverColorBorder={hoverColorBorder ?? '1px solid #625F6D'}
    >
      {icon && iconPosition === 'left' && icon}
      {text}
      {icon && iconPosition === 'right' && icon}
      {children}
      {loading && (
        <CircularLoader
          size={15}
          time={1}
          thickness={2}
          color='var(--color-gray-dark)'
        />
      )}
    </ButtonInstance>
  )
}

export default Button
