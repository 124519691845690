import styled, { keyframes } from 'styled-components'

export const rotate = keyframes`
  from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
`

export const Loader = styled.div<{
  size: number
  color: string
  thickness: number
  time: number
}>`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  margin-left: 5px;
  border: solid ${props => props.thickness}px ${props => props.color};
  border-style: solid;
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all ${props => props.time}s ease-in;
  -webkit-animation-name: rotate;
  -webkit-animation-duration: steps(${props => props.time});
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;

  transition: all ${props => props.time}s ease-in;
  animation-name: ${rotate};
  animation-duration: ${props => props.time}s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`
