import { useTranslation } from 'react-i18next'
import { Group, Text } from 'components'
import { IScoreInfo } from '../scoreComponentTypes'
import { IconHands } from 'components'

export const ScoreInfo = ({rubric, score}: IScoreInfo) => {
  const { t } = useTranslation()

  return (
    <>
      {score > 0 ? (
        <Group spacing='xs'>
          <IconHands width={36} height={36} color='black' value={score} />
          <Text size='md' fw='bold'>{rubric}</Text>
        </Group>
      ) : (
        <Text size='md' fw='bold'>{t('not_assessed')}</Text>
      )}
    </>
  )
}

export default ScoreInfo
