import { createContext, useState } from 'react'
import { useDisclosure } from 'hooks'
import { IProject } from '../interfaces'

interface ProjectListContextProps {
  activeTab: string | null
  setActiveTab: React.Dispatch<React.SetStateAction<string | null>>
  prevTab: string | null
  setPrevTab: React.Dispatch<React.SetStateAction<string | null>>
  projects: IProject[] | null
  setProjects: React.Dispatch<React.SetStateAction<IProject[] | null>>
  openedProjectFilterModal: boolean
  openProjectFilterModal: () => void
  closeProjectFilterModal: () => void
  activePage: number
  setActivePage: React.Dispatch<React.SetStateAction<number>>
  totalPages: number
  setTotalPages: React.Dispatch<React.SetStateAction<number>>
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  filterSchoolTermID: string | null
  setFilterSchoolTermID: React.Dispatch<React.SetStateAction<string | null>>
  filterFinalProduct: string[] | null
  setFilterFinalProduct: React.Dispatch<React.SetStateAction<string[] | null>>
  filterCycle: string | null
  setFilterCycle: React.Dispatch<React.SetStateAction<string | null>>
  filterGroup: string | null
  setFilterGroup: React.Dispatch<React.SetStateAction<string | null>>
  filterActive: boolean
  setFilterActive: React.Dispatch<React.SetStateAction<boolean>>
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  newProjectURL: string
}

export const ProjectListContext = createContext<ProjectListContextProps>({
  activeTab: null,
  setActiveTab: () => {},
  prevTab: null,
  setPrevTab: () => {},
  projects: null,
  setProjects: () => {},
  openedProjectFilterModal: false,
  openProjectFilterModal: () => {},
  closeProjectFilterModal: () => {},
  activePage: 1,
  setActivePage: () => {},
  totalPages: 1,
  setTotalPages: () => {},
  searchValue: '',
  setSearchValue: () => {},
  filterSchoolTermID: null,
  setFilterSchoolTermID: () => {},
  filterFinalProduct: null,
  setFilterFinalProduct: () => { },
  filterCycle: null,
  setFilterCycle: () => { },
  filterGroup: null,
  setFilterGroup: () => { },
  filterActive: false,
  setFilterActive: () => {},
  error: null,
  setError: () => { },
  newProjectURL: '',
})

export const ProjectListProvider = ({ children }: { children: React.ReactNode }) => {

  const [projects, setProjects] = useState<IProject[] | null>(null)
  const [activeTab, setActiveTab] = useState<string | null>('approved')
  const [prevTab, setPrevTab] = useState<string | null>('approved')
  const [openedProjectFilterModal, { open: openProjectFilterModal, close: closeProjectFilterModal }] =
  useDisclosure(false)
  const [activePage, setActivePage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [searchValue, setSearchValue] = useState('')
  const [filterSchoolTermID, setFilterSchoolTermID] = useState<string | null>(null)
  const [filterFinalProduct, setFilterFinalProduct] = useState<string[] | null>(null)
  const [filterCycle, setFilterCycle] = useState<string | null>(null)
  const [filterGroup, setFilterGroup] = useState<string | null>(null)
  const [filterActive, setFilterActive] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const newProjectURL = '/projects/new'

  return (
    <ProjectListContext.Provider
      value={{
        activeTab,
        setActiveTab,
        prevTab,
        setPrevTab,
        projects,
        setProjects,
        openedProjectFilterModal,
        openProjectFilterModal,
        closeProjectFilterModal,
        activePage,
        setActivePage,
        totalPages,
        setTotalPages,
        searchValue,
        setSearchValue,
        filterSchoolTermID,
        setFilterSchoolTermID,
        filterFinalProduct,
        setFilterFinalProduct,
        filterCycle,
        setFilterCycle,
        filterGroup,
        setFilterGroup,
        filterActive,
        setFilterActive,
        error,
        setError,
        newProjectURL,
      }}
    >
      {children}
    </ProjectListContext.Provider>
  )
}
