import styled from 'styled-components'

export const AvatarWrapper = styled.div<{ size: number }>`
  display: flex;
  flex-direction: column;
  background-color: #131d2c;
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  border-radius: ${props => `${props.size / 2}px`};
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

export const Initials = styled.span<{ size: number }>`
  color: white;

  font-style: normal;
  font-weight: 500;
  font-size: ${props => `${props.size / 16}rem`};
  line-height: 1.2;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
`
