import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TeacherDashboardContext } from '../../context/TeacherDashboardContext'
import { Carousel, NextTaskCard, EmptyStateBox, Stack, Heading } from 'components'
import { carouselDashboard, useCaroselStyles } from 'components/Carousel'
import { TasksListSkeleton } from '../TasksListSkeleton'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { useLocalizedDate, useLocalizedTime } from 'hooks'
import { IconArticleOff, IconChecklist } from '@tabler/icons-react'

export const TasksList = () => {
  const { classes } = useCaroselStyles()
  const { t, i18n } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { tasks, handleJournalClick } = useContext(TeacherDashboardContext)
  const localizeTime = useLocalizedTime()

  const localizeDate = useLocalizedDate(i18n.language)

  const showIndicator = (tasks && isMobile && tasks.length > 2) || (tasks && tasks.length > 3)

  return (
    <Stack spacing='xs'>
      <Heading size='md' text={t('new_teacher_dashboard.activities_title')} icon={<IconChecklist size={32} />} />
      {tasks === null && <TasksListSkeleton />}
      {tasks && tasks !== null && tasks.length > 0 && (
        <Carousel
          {...carouselDashboard}
          withIndicators={showIndicator || false}
          withControls={showIndicator || false}
          draggable={showIndicator || false}
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
          }}
        >
          {tasks.map((task, index) => (
            <Carousel.Slide key={index}>
              <NextTaskCard
                projectName={task.projectName}
                lessonName={task.lessonName}
                date={localizeDate(task.date)}
                time={localizeTime(task.timeStart) + ' - ' + localizeTime(task.timeEnd)}
                canFillEditReport={task.canFillEditReport || false}
                activities={task.activities}
                status={task.status || 'not_started'}
                hasAssessmentActivity={task.hasAssessmentActivity}
                hasDeliverableActivity={task.hasDeliverableActivity}
                handleJournalClick={() => handleJournalClick(`/projects/lessons/${task.lessonId}/edit_report`)}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      )}
      {tasks && tasks !== null && tasks.length === 0 && (
        <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('new_teacher_dashboard.no_tasks')} />
      )}
    </Stack>
  )
}
