import { useContext } from 'react'
import { FeatureToggleContext } from 'context'
import { IFeature } from 'interfaces'

export const useFeatureToggle = (featureKey?: string): boolean | IFeature[] => {
  const { features } = useContext(FeatureToggleContext)

  if (!featureKey) return features

  const featureFlag = features.find(feature => feature.key === featureKey && feature.state === 'on')

  return featureFlag ? true : false
}