import {
  IStudentsGroupReportPayload,
  IStudentsReportPayload,
} from '@legacy/common/components/apps/students/report/students-report-hook'

// eslint-disable-next-line import/no-default-export
export default {
  cities: {
    index: '/api/cities',
    show: (id: string) => `/api/cities/${id}`,
  },
  schools: {
    index: '/api/schools',
    update: (id: string) => `/api/schools/${id}`,
  },
  school_plans: {
    index: '/api/school_plans',
  },
  tutors: {
    index: '/manager/tutors.json',
  },
  students: {
    index: '/api/students',
    curriculums: (id: string) => `/api/students/${id}/curriculums`,
    groupCurriculums: (groupSchoolTermId: string) =>
      `/api/students/${groupSchoolTermId}/group_curriculums`,
    level4ItemsReport: ({
      student,
      developmentStageId,
      contentCurriculumId,
      dateStart,
      dateEnd,
    }: IStudentsReportPayload) =>
      `/api/students/lv4_items_report?student_id=${student}&development_stage_id=${developmentStageId}&content_curriculum_id=${contentCurriculumId}&date_start=${dateStart}&date_end=${dateEnd}`,
    filters: {
      byName: 'api/students?name=',
    },
    groups: {
      index: '/api/students/groups?report=true',
      level4ItemsReport: ({
        group,
        developmentStageId,
        contentCurriculumId,
        dateStart,
        dateEnd,
      }: IStudentsGroupReportPayload) =>
        `/api/students/group_lv4_items_report?group_school_term_id=${group}&development_stage_id=${developmentStageId}&content_curriculum_id=${contentCurriculumId}
&date_start=${dateStart}&date_end=${dateEnd}`,
    },
  },
  school_subscriptions: {
    get_by_school: (id: string) => `/api/school_subscriptions_by_school_id/${id}`,
    index: '/api/school_subscriptions',
  },
  users: {
    current: '/api/users/current',
    search_by_selected_school_and_name: (name: string) =>
      `/api/users/search_by_selected_school_and_name?q=${name}`,
  },
  schoolTutors: {
    update: (tutorId: string) => `/api/school_tutors_by_tutor/${tutorId}`,
  },
  activites: {
    delete: (projectId: string, id: string) =>
      `/projects/${projectId}/session_meetings/${id}`,
  },
  flipper: {
    features: '/flipper/api/features',
  },
  student_task: {
    get_by_id: (id: string) => `/api/student_tasks/${id}`,
    send_answer: 'api/student_task_deliveries',
  },
  sessionMeetingActivities: {
    delete: (projectId: string, id: string) =>
      `/projects/${projectId}/session_meeting_activities/${id}/destroy_all_activity_lv4_item`,
  },
  sessionCalls: {
    displayButton: (id: string) => `/session_calls/${id}/display_button`,
  },
  account: {
    hasFreemium: (email: string) => `/api/account/find?email=${encodeURIComponent(email)}`,
    hasUser: (username: string) => `/api/users/find?username=${username}`,
    hasUserEmail: (email: string) => `/api/users/find?email=${encodeURIComponent(email)}`,
    inviteInformation: (id: string) =>
      `/api/tutor/get_invite_information/${id}`,
    save: '/api/account/save',
    saveStudent: '/api/tutor/invite/create-student',
    countries: '/api/account/countries',
    schoolYears: (id: number) => `/api/account/years.json?country_id=${id}`,
  },
}
