import { createContext, useCallback, useEffect, useState } from 'react'
import { useListState, useDisclosure, useToggle } from 'hooks'

export interface IStudent {
  id: string
  name: string
  avatar: string
  checked: boolean
}

export interface ITeacher {
  id: string
  name: string
  avatar: string
  role: string
  checked: boolean
  email: string
}

export interface IMainTeacherSelect {
  value: string
  label: string
}

interface ParticipantsContextProps {
  schoolTerm: string | null
  setSchoolTerm: React.Dispatch<React.SetStateAction<string | null>>
  selectedGroup: string | null
  setSelectedGroup: React.Dispatch<React.SetStateAction<string | null>>
  selectedGroupLabel: string | null
  setSelectedGroupLabel: React.Dispatch<React.SetStateAction<string | null>>
  students: IStudent[]
  studentsHandler: {
    append: (value: IStudent) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<IStudent[]>>
  }
  teachers: ITeacher[]
  teachersHandler: {
    append: (value: ITeacher) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ITeacher[]>>
  }
  otherStudents: IStudent[]
  otherStudentsHandler: {
    append: (value: IStudent) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<IStudent[]>>
  }
  selectedOtherStudents: IStudent[]
  selectedOtherStudentsHandlers: {
    append: (value: IStudent) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<IStudent[]>>
  }
  otherTeachers: ITeacher[]
  otherTeachersHandler: {
    append: (value: ITeacher) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ITeacher[]>>
  }
  selectedOtherTeachers: ITeacher[]
  selectedOtherTeachersHandlers: {
    append: (value: ITeacher) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ITeacher[]>>
  }
  mainTeachersList: IMainTeacherSelect[]
  mainTeachersListHandler: {
    append: (value: IMainTeacherSelect) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<IMainTeacherSelect[]>>
  }
  studentsModalOpened: boolean
  openStudentsModal: () => void
  closeStudentsModal: () => void
  teachersModalOpened: boolean
  openTeachersModal: () => void
  closeTeachersModal: () => void
  studentsAction: boolean
  toggleStudentsAction: () => void
  handleToggleStudentsAction: () => void
  handleChangeStudent: (checked: boolean, id: string) => void
  handleRemoveStudent: (index: number) => void
  handleRemoveTeacher: (index: number) => void
  getCheckedStudentsQuantity: () => number
}

export const ParticipantsContext = createContext<ParticipantsContextProps>({
  schoolTerm: null,
  setSchoolTerm: () => {},
  selectedGroup: null,
  setSelectedGroup: () => {},
  selectedGroupLabel: null,
  setSelectedGroupLabel: () => {},
  students: [],
  studentsHandler: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  teachers: [],
  teachersHandler: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  otherStudents: [],
  otherStudentsHandler: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  selectedOtherStudents: [],
  selectedOtherStudentsHandlers: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  otherTeachers: [],
  otherTeachersHandler: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  selectedOtherTeachers: [],
  selectedOtherTeachersHandlers: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  mainTeachersList: [],
  mainTeachersListHandler: {
    append: () => {},
    remove: () => {},
    setState: () => {},
  },
  studentsModalOpened: false,
  openStudentsModal: () => {},
  closeStudentsModal: () => {},
  teachersModalOpened: false,
  openTeachersModal: () => {},
  closeTeachersModal: () => {},
  studentsAction: true,
  toggleStudentsAction: () => {},
  handleToggleStudentsAction: () => {},
  handleChangeStudent: () => {},
  handleRemoveStudent: () => {},
  handleRemoveTeacher: () => {},
  getCheckedStudentsQuantity: () => 0,
})

export const ParticipantsProvider = ({ children }: { children: React.ReactNode }) => {
  const [schoolTerm, setSchoolTerm] = useState<string | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null)
  const [selectedGroupLabel, setSelectedGroupLabel] = useState<string | null>(null)
  const [students, studentsHandler] = useListState<IStudent>([])
  const [teachers, teachersHandler] = useListState<ITeacher>([])
  const [otherStudents, otherStudentsHandler] = useListState<IStudent>([])
  const [selectedOtherStudents, selectedOtherStudentsHandlers] = useListState<IStudent>([])
  const [otherTeachers, otherTeachersHandler] = useListState<ITeacher>([])
  const [selectedOtherTeachers, selectedOtherTeachersHandlers] = useListState<ITeacher>([])
  const [studentsModalOpened, { open: openStudentsModal, close: closeStudentsModal }] = useDisclosure(false)
  const [teachersModalOpened, { open: openTeachersModal, close: closeTeachersModal }] = useDisclosure(false)
  const [studentsAction, toggleStudentsAction] = useToggle([true, false])
  const [mainTeachersList, mainTeachersListHandler] = useListState<IMainTeacherSelect>([])

  const handleRemoveStudent = useCallback(
    (index: number) => {
      selectedOtherStudentsHandlers.remove(index)
    },
    [selectedOtherStudentsHandlers],
  )

  const handleRemoveTeacher = useCallback(
    (index: number) => {
      selectedOtherTeachersHandlers.remove(index)
    },
    [selectedOtherTeachersHandlers],
  )

  const handleChangeStudent = useCallback(
    (checked: boolean, id: string) => {
      studentsHandler.applyWhere(
        student => student.id === id,
        student => {
          return {
            ...student,
            checked,
          }
        },
      )
    },
    [studentsHandler],
  )

  const handleChangeAllStudents = useCallback(() => {
    studentsHandler.setState(
      students.map(student => {
        return {
          ...student,
          checked: !studentsAction,
        }
      }),
    )
  }, [studentsAction, students])

  const handleToggleStudentsAction = useCallback(() => {
    handleChangeAllStudents()
    toggleStudentsAction()
  }, [studentsAction, handleChangeAllStudents])

  const getCheckedStudentsQuantity = () => {
    const checkedStudents = students.filter(student => student.checked === true)
    return checkedStudents.length
  }

  useEffect(() => {
    const teachersList = [...selectedOtherTeachers, ...teachers].map(teacher => {
      return {
        value: teacher.id,
        label: teacher.name,
      }
    })
    teachersList.sort((a, b) => {
      const labelA = a.label.toUpperCase()
      const labelB = b.label.toUpperCase()
      if (labelA < labelB) {
        return -1
      }
      if (labelA > labelB) {
        return 1
      }
      return 0
    })
    mainTeachersListHandler.setState(teachersList)
  }, [selectedOtherTeachers, teachers])

  return (
    <ParticipantsContext.Provider
      value={{
        schoolTerm,
        setSchoolTerm,
        selectedGroup,
        setSelectedGroup,
        selectedGroupLabel,
        setSelectedGroupLabel,
        students,
        studentsHandler,
        teachers,
        teachersHandler,
        otherStudents,
        otherStudentsHandler,
        selectedOtherStudents,
        selectedOtherStudentsHandlers,
        otherTeachers,
        otherTeachersHandler,
        selectedOtherTeachers,
        selectedOtherTeachersHandlers,
        mainTeachersList,
        mainTeachersListHandler,
        studentsModalOpened,
        openStudentsModal,
        closeStudentsModal,
        teachersModalOpened,
        openTeachersModal,
        closeTeachersModal,
        studentsAction,
        toggleStudentsAction,
        handleToggleStudentsAction,
        handleChangeStudent,
        handleRemoveStudent,
        handleRemoveTeacher,
        getCheckedStudentsQuantity,
      }}
    >
      {children}
    </ParticipantsContext.Provider>
  )
}
