import { Stack, Skeleton } from 'components'

export const ActivityDetailsSkeleton = () => {
  return (
    <Stack spacing='sm'>
      <Skeleton height={20} />
      <Skeleton height={20} />
      <Skeleton height={20} />
      <Skeleton height={20} />
    </Stack>
  )
}
