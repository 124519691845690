import { api } from 'helpers'
import { IReceivedCurrentUser } from '../../context/useCurrentUser/interface'

export async function getCurrentUser(): Promise<IReceivedCurrentUser> {
  try {
    const response = await api.get('/api/users/current')
    return response.data.data
  } catch (error: any) {
    return error
  }
}
