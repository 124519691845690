import { IAction } from 'interfaces'
export interface IProjectActions {
  readonly assessments: IAction
  readonly report: IAction
  readonly approve: IAction
  readonly newMeeting: IAction
  readonly publish: IAction
  readonly archive: IAction
  readonly featured: IAction
  readonly templateCategory: IAction
  readonly applications: IAction
  readonly finish: IAction
  readonly reopen: IAction
  readonly edit: IAction
  readonly edit_review: IAction
  readonly delete: IAction
  readonly mural: IAction
}