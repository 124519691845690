import { useContext, useMemo, createContext } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

import axios from 'axios'

import { paths } from 'common/paths'
import { useLocalStorage } from './useLocalStorage'
import { AuthContextValuesType, IUser } from './interface'

const initialValues = {
  user: undefined,
  login: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextValuesType>(initialValues)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage('user', undefined)
  const { i18n } = useTranslation()
  const login = async (data: IUser) => {
    i18n.changeLanguage(data.locale)
    setUser(data)
  }

  const logout = async () => {
    window.localStorage.removeItem('current_user')
    const removed = window.localStorage.getItem('current_user') === null
    setUser(undefined)
    if (removed) {
      Cookies.remove('url_to_redirect')
      setTimeout(async () => {
        await axios.delete(paths.account.signout)
        window.location.replace('/account/signin')
      }, 2000)
    }
  }

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
