import { ModalProps } from '@mantine/core'

export interface FullScreenConfig extends ModalProps {
  fullScreen: boolean
  transitionProps: object
  opened: boolean
  onClose: () => void
  zIndex?: number
}

export const fullScreenConfig: FullScreenConfig = {
  fullScreen: true,
  transitionProps: { transition: 'fade', duration: 200 },
  opened: false,
  onClose: () => {},
  zIndex: 999,
}
