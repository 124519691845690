/* eslint-disable max-len */
import React from 'react'
import { StyledIconPathFill } from './styled'

export function PeriodReportsIcon () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledIconPathFill
        d="M8 21.2308L8 20.2308L8 21.2308ZM6 17.7234V6.5H4V17.7234H6ZM18 6.5V17.7234H20V6.5H18ZM18 17.7234V18.2308H20V17.7234H18ZM16 20.2308L8 20.2308L8 22.2308L16 22.2308L16 20.2308ZM6 18.2308V17.7234H4V18.2308H6ZM8 4.50001L8.5 4.50001L8.5 2.50001L8 2.50001L8 4.50001ZM15.5 4.5L16 4.5L16 2.5L15.5 2.5L15.5 4.5ZM8 20.2308C6.89543 20.2308 6 19.3353 6 18.2308H4C4 20.4399 5.79086 22.2308 8 22.2308L8 20.2308ZM18 18.2308C18 19.3353 17.1046 20.2308 16 20.2308L16 22.2308C18.2091 22.2308 20 20.4399 20 18.2308H18ZM20 6.5C20 4.29086 18.2091 2.5 16 2.5L16 4.5C17.1046 4.5 18 5.39543 18 6.5H20ZM6 6.5C6 5.39544 6.89543 4.50001 8 4.50001L8 2.50001C5.79086 2.50001 4 4.29086 4 6.5H6Z"
      />
      <StyledIconPathFill
        d="M9 2.84613C9 2.29385 9.44772 1.84613 10 1.84613H14C14.5523 1.84613 15 2.29385 15 2.84613V4.99999C15 5.55228 14.5523 6 14 6H10C9.44772 6 9 5.55228 9 5V2.84613Z"
        strokeWidth="2"
      />
      <StyledIconPathFill
        d="M8.5 9C7.94772 9 7.5 9.44772 7.5 10C7.5 10.5523 7.94772 11 8.5 11V9ZM15.5 11C16.0523 11 16.5 10.5523 16.5 10C16.5 9.44772 16.0523 9 15.5 9V11ZM8.5 11H15.5V9H8.5V11Z"
      />
      <StyledIconPathFill
        d="M8.5 12.5C7.94772 12.5 7.5 12.9477 7.5 13.5C7.5 14.0523 7.94772 14.5 8.5 14.5V12.5ZM15.5 14.5C16.0523 14.5 16.5 14.0523 16.5 13.5C16.5 12.9477 16.0523 12.5 15.5 12.5V14.5ZM8.5 14.5H15.5V12.5H8.5V14.5Z"
      />
      <StyledIconPathFill
        d="M8.5 16C7.94772 16 7.5 16.4477 7.5 17C7.5 17.5523 7.94772 18 8.5 18V16ZM12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16V18ZM8.5 18H12V16H8.5V18Z"
      />
    </svg>
  )
}
