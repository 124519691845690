import { createStyles, rem } from 'hooks'

interface pageOneProps {
  errorLength: boolean
}

export const useStyles = createStyles((theme, { errorLength }: pageOneProps) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: theme.spacing.md,
    gap: theme.spacing.md,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
    borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.gray[2]}`,
  },
  content: {
    marginBottom: rem(90),
  },
  pageCounter: {
    display: 'flex',
    flexDirection: 'row-reverse',
    p: {
      color: errorLength ? '#ff0000' : 'inherit',
    },
  },
}))
