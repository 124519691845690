import { useContext } from 'react'
import { Grid, Stack } from 'components'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { ListSkillsStandardsPage2Skeleton } from '../ListSkillsStandardsPageSkeleton'
import { CardSearchItem } from '../CardSearchItem'
import { ILvl4Item } from '../ProjectSkillsStandards/interfaces'
import { useTranslation } from 'react-i18next'

export const ListSearchResults = () => {
  const { searchResults, searchResultsLoaded, skillsStandardsInfo } = useContext(SkillsStandardsContext)
  const { t } = useTranslation()

  const noItems = searchResultsLoaded && searchResults && searchResults.length === 0
  const hasItems = searchResultsLoaded && searchResults && searchResults.length > 0 && skillsStandardsInfo

  return (
    <>
      <Stack>
        {!noItems && !hasItems && <ListSkillsStandardsPage2Skeleton />}
        {noItems && !hasItems && t('new_project_page.no_items_found_search')}
        {hasItems && !noItems && (
          <Grid>
            {searchResults.map((item: ILvl4Item) => {
              const info = skillsStandardsInfo.find(infoItem => infoItem.id === item.id)
              return (
                <Grid.Col key={item.id} xs={12} sm={6} md={6} lg={4}>
                  <CardSearchItem
                    item={item}
                    lv1ID={item.lv1ID}
                    lv2ID={item.lv2ID}
                    color={item.color}
                    developedStudents={info?.developedStudents}
                    ongoingProjects={info?.ongoingProjects}
                  />
                </Grid.Col>
              )
            })}
          </Grid>
        )}
      </Stack>
    </>
  )
}
