import { createStyles } from 'hooks'

export const useStyles = createStyles(theme => ({
  root: {
    '.mantine-Image-figure': {
      '.mantine-Image-imageWrapper': {
        '.mantine-Image-image': {
          borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
        },
      },
    },
  },
}))
