import React from 'react'

export type Props = {
  label: string
  icon?: string
  // @TODO convert type to enum
  // type: 'primary' | 'secondary' | 'light' | 'clear' | 'next' | 'no-action' | 'clear-icon' | 'transparent'
  type?: string
  size?: 'xsmall' | 'small' | 'large' | 'icon' | 'icon-small'
  className?: string
  iconClassName?: string
  target?: '_self' | '_blank'
  disabled?: boolean
  endIcon?: boolean
  onClick: (event: React.MouseEvent<HTMLElement> | string) => void
}

export type Ref = (HTMLButtonElement & HTMLAnchorElement) | null

export const Button = React.forwardRef<Ref, Props>(
  (
    {
      label,
      icon,
      type = 'primary',
      size = 'small',
      className = '',
      iconClassName = '',
      onClick,
      target = '_self',
      disabled = false,
      endIcon = false,
    },
    ref
  ) => {
    const buttonContent = (
      <>
        {icon && !endIcon && <i className={`icon-${icon} ${iconClassName}`} />}
        {label}
        {icon && endIcon && (
          <i className={`ml-3 icon-${icon} ${iconClassName}`} />
        )}
        {type === 'next' && <i className='ml-2 icon-common-arrow-right' />}
      </>
    )

    if (disabled) {
      return (
        <button
          type='button'
          className={`new-buttons new-buttons-${type} new-buttons-${size} ${className} disabled`}
          ref={ref}
        >
          {buttonContent}
        </button>
      )
    }

    if (typeof onClick === 'function') {
      return (
        <button
          type='button'
          className={`new-buttons new-buttons-${type} new-buttons-${size} ${className}`}
          onClick={onClick}
          ref={ref}
        >
          {buttonContent}
        </button>
      )
    }

    return (
      <a
        href={onClick}
        target={target}
        className={`new-buttons new-buttons-${type} new-buttons-${size} ${className}`}
        ref={ref}
      >
        {buttonContent}
      </a>
    )
  }
)

Button.displayName = 'Button'

export default Button
