import { ILessonCardActivity } from 'interfaces'

interface ActivityTextProps {
  activity: string | ILessonCardActivity
}

export const Activity = ({ activity }: ActivityTextProps) => {
  const activityText = typeof activity === 'string' ? activity : activity.title

  return (
    <>
      {activityText}
    </>
  )
}
