import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import type { CamelCasedPropertiesDeep } from 'type-fest'
import { useEffect, useState } from 'react'
import { AssessmentOptionsType, AssessmentScoreList, IPagination } from './interfaces'
import { getListAssessmentScores } from './services'

export const useAssessmentScoreListHook = (
  studentId: string,
  urlId: string,
  type: AssessmentOptionsType,
) => {
  const [contentAssessmentScores, setContentAssessmentScores] = useState<undefined | AssessmentScoreList>()
  const [skillAssessmentScores, setSkillAssessmentScores] = useState<undefined | AssessmentScoreList>()
  const [loadingAssess, setLoadingAssess] = useState(true)

  useEffect(() => {
    if (loadingAssess) loadAssessmentScores()
  }, [loadingAssess])

  useEffect(() => {
    setLoadingAssess(true)
    setContentAssessmentScores(undefined)
    setSkillAssessmentScores(undefined)
  }, [studentId])

  const contentAssessmentScoresParamsList = (page: number) => ({
    student_id: studentId,
    type: 'content',
    page,
  })
    
  const skillAssessmentScoresParamsList = (
    page: number,
  ) => ({
    student_id: studentId,
    type: 'skill',
    page,
  })
    
  const page = (pagination?: CamelCasedPropertiesDeep<IPagination>) => {
    if (!pagination) {
      return 1
    } else if (pagination.isLastPage === false) {
      return pagination.nextPage
    } else {
      return false
    }
  }
    
  const updateContentAssessmentScoresList = async () => {
    const contentAssessmentScoresPage = page(contentAssessmentScores?.pagination)
    if (contentAssessmentScoresPage) {
      const params = contentAssessmentScoresParamsList(contentAssessmentScoresPage)
      const {data: contentAssessmentScoresResponse} = await getListAssessmentScores({urlId, params, type})
      const result = contentAssessmentScores 
        ? [...contentAssessmentScores.result, ...contentAssessmentScoresResponse.result]
        : contentAssessmentScoresResponse.result
      setContentAssessmentScores({
        result,
        pagination: contentAssessmentScoresResponse.pagination,
      })
      return contentAssessmentScoresResponse
    }
    return contentAssessmentScores
  }
    
      
  const updateSkillAssessmentScoresList = async (
    contentAssessmentScores?: AssessmentScoreList,
  ) => {
    const skillAssessmentScoresPage = page(skillAssessmentScores?.pagination)
    if (!skillAssessmentScoresPage || !contentAssessmentScores?.pagination.isLastPage) return
    const params = skillAssessmentScoresParamsList(skillAssessmentScoresPage)
    const {data: skillAssessmentScoresResponse} = await getListAssessmentScores({urlId, params, type})
    setSkillAssessmentScores({
      result: skillAssessmentScores
        ? [...skillAssessmentScores.result, ...skillAssessmentScoresResponse.result]
        : skillAssessmentScoresResponse.result,
      pagination: skillAssessmentScoresResponse.pagination,
    })
  }
    
  const loadAssessmentScores = async () => {
    const contentAssessScoreList = await updateContentAssessmentScoresList()
    await updateSkillAssessmentScoresList(contentAssessScoreList)
    setLoadingAssess(false)
  }
    
  const scrollRef: React.RefObject<HTMLDivElement> = useBottomScrollListener(() => {
    if (!skillAssessmentScores?.pagination.isLastPage) setLoadingAssess(true)
  }, { offset: 400 })

  return {
    scrollRef,
    contentAssessmentScores,
    skillAssessmentScores,
    loadingAssess,
  }
}