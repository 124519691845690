import { useContext, useEffect, useState } from 'react'
import { Modal, Stack, Divider, Button, Title, Grid, Box, EmptyStateBox, Group } from 'components'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context'
import { useStyles } from './styles'
import { IconMoodCog, IconBooks } from '@tabler/icons-react'
import { ProjectButtonsContext } from '../../context/ProjectButtonsContext'
import { SkillsStandardsContext } from 'pages/NewProject/context/SkillsStandardsContext'
import { StepperContext } from '../../context/StepperContext'
import { CardSelectedItem } from '../CardSelectedItem'
import { ILvl4Item } from '../ProjectSkillsStandards/interfaces'

export function ReviewSkillsStandardsModal() {
  const { t } = useTranslation()
  const user = useCurrentUser()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const standardsOnly = user.currentUser?.selected_school?.enable_only_bncc_content
  const {
    countSelected,
    selectedSkills,
    selectedStandards,
    reviewModalOpened,
    reviewModalClose,
    handleSaveDraft,
    handleSaveAndContinue,
    skillsStandardsInfo,
  } = useContext(SkillsStandardsContext)
  const { projectStatus } = useContext(StepperContext)

  const { buttonValues } = useContext(ProjectButtonsContext)

  const [sortedSkills, setSortedSkills] = useState<ILvl4Item[]>([])
  const [sortedStandards, setSortedStandards] = useState<ILvl4Item[]>([])

  const sortArray = (array: ILvl4Item[]) => {
    return array.sort((a, b) => {
      const lv1A = a.lv1ID.toUpperCase()
      const lv1B = b.lv1ID.toUpperCase()
      if (lv1A < lv1B) {
        return -1
      }
      if (lv1A > lv1B) {
        return 1
      }
      return 0
    })
  }

  useEffect(() => {
    if (selectedSkills && selectedSkills.length > 0) {
      setSortedSkills(sortArray(selectedSkills))
    }
  }, [selectedSkills])

  useEffect(() => {
    if (selectedStandards && selectedStandards.length > 0) {
      setSortedStandards(sortArray(selectedStandards))
    }
  }, [selectedStandards])

  return (
    <Modal
      zIndex={150}
      opened={reviewModalOpened}
      onClose={reviewModalClose}
      title={t('new_project_page.review_modal_title')}
      fullScreen
      classNames={classes}
    >
      <Stack className={classes.stack}>
        {standardsOnly ? null : (
          <>
            <Stack spacing='sm'>
              <Group spacing={4}>
                <IconMoodCog size='1.5rem' />
                <Title order={3}>{t('new_project_page.skills_modal_title')}</Title>
              </Group>
              {skillsStandardsInfo && selectedSkills && selectedSkills.length > 0 ? (
                <Grid>
                  {sortedSkills.map(item => (
                    <Grid.Col key={item.id} xs={12} sm={6} md={6} lg={4}>
                      <CardSelectedItem item={item} />
                    </Grid.Col>
                  ))}
                </Grid>
              ) : (
                <EmptyStateBox withBorder icon={<IconMoodCog />} text={t('new_project_page.no_skill_selected')} />
              )}
            </Stack>
            <Divider variant='solid' />
          </>
        )}
        <Stack spacing='sm'>
          <Group spacing={4}>
            <IconBooks size='1.5rem' />
            <Title order={3}>{t('new_project_page.standards_modal_title')}</Title>
          </Group>
          {skillsStandardsInfo && selectedStandards && selectedStandards.length > 0 ? (
            <Grid>
              {sortedStandards.map(item => (
                <Grid.Col key={item.id} xs={12} sm={6} md={6} lg={4}>
                  <CardSelectedItem item={item} />
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <EmptyStateBox withBorder icon={<IconBooks />} text={t('new_project_page.no_standard_selected')} />
          )}
        </Stack>
      </Stack>
      <Box className={classes.footer}>
        <Grid gutter={isMobile ? 'sm' : 'md'} justify='space-between' p={0} m={0}>
          <Grid.Col span={isMobile ? 12 : 'auto'}>
            <Button fullWidth={isMobile} variant='default' size='lg' onClick={reviewModalClose}>
              {t('new_project_page.button_add_more_items')}
            </Button>
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 'content'}>
            <Button
              loading={buttonValues[0].loading}
              disabled={buttonValues[0].disabled}
              fullWidth={isMobile}
              variant='default'
              size='lg'
              onClick={handleSaveDraft}
            >
              {projectStatus === 'draft' ? t('new_project_page.save_draft_button') : t('new_project_page.save_button')}
            </Button>
          </Grid.Col>
          <Grid.Col span={isMobile ? 12 : 'content'}>
            <Button
              loading={buttonValues[1].loading}
              disabled={buttonValues[1].disabled || countSelected() === 0}
              fullWidth={isMobile}
              variant='filled'
              size='lg'
              onClick={() => handleSaveAndContinue()}
            >
              {t('new_project_page.save_and_continue_button')}
            </Button>
          </Grid.Col>
        </Grid>
      </Box>
    </Modal>
  )
}
