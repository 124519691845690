import { Box } from 'components'
import { NavbarMenu } from './NavbarMenu'
import { useStyles } from './styles'

export const Navbar = () => {
  const { classes } = useStyles()
  return (
    <Box className={classes.navbarMenu}>
      <NavbarMenu />
    </Box>
  )
}
