import styled from 'styled-components'
import { IStar, IStarsSelector, IStarIcone } from './interface'

const StarIcone = styled.i<IStarIcone>`
  font-size: 1.8rem;
  margin-right: 10px;
  color: ${({ index, value }) => (index < value ? 'var(--color-primary-ultradark)' : 'var(--color-gray)')};
  cursor: pointer;
  transition: ease;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  :hover ~ i {
    color: var(--color-gray-light);
  }
`

const Star = ({ index, value, fieldName, onClick }: IStar) => {
  return (
    <StarIcone
      index={index}
      value={value}
      key={index}
      role="button"
      tabIndex={0}
      className={`${index < value ? 'icon-star' : 'icon-star-out'}`}
      onClick={() => onClick(fieldName, index + 1)}
    />
  )
}

const Label = styled.div``

const StarsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const StarsSelector = ({ total, fieldName, value, label, onClick, errors }: IStarsSelector) => {
  const arrayStars = () => {
    const stars = [...Array(total)].map((__, index) => (
      <Star fieldName={fieldName} index={index} value={value} key={index} onClick={onClick} />
    ))

    return stars
  }

  return (
    <StarsContainer>
      <Label>{label}</Label>
      <div className="stars-container">{arrayStars()}</div>
      {errors && <span className="validation-error">{errors}</span>}
    </StarsContainer>
  )
}
