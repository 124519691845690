import styled from 'styled-components'

export const StyledStudentReportFrame = styled.iframe`
  position: relative;
  height: calc(100vh - 200px);
  min-height: 450px;
  border-radius: 0.6rem;
  width: 100%;
  margin-bottom: 16px;
`

export const StyledTitle = styled.h1`
  margin-bottom: 4.5rem;
  margin-top: 1.5rem;
`

export const StyledContent = styled.div``

export const StyledFiltersBlock = styled.div`
  background-color: var(--color-white);
  padding: 2.4rem;
  margin-bottom: 2.4rem;
  border-radius: 0.6rem;

  & h5 {
    color: var(--color-black);
    margin-bottom: 0.8rem;
    margin-top: 0px;
    font-weight: 500;
    font-size: 1rem;
  }

  & p.label {
    font-weight: 500;
    font-size: 1rem;
  }

  & p > b {
    font-weight: 700;
  }
`
