import { useMemo } from 'react'
import GooglePicker from 'react-google-picker'
import UploadGoogle from '@legacy/common/_components/UploadGoogle'
import GDriveFile from '@legacy/common/_components/GDriveFile'
import { toastOptions } from '@legacy/common/_components/LumiarToast'
import { toast } from 'react-toastify'
import useGoogleDriveUpload from '@legacy/common/hooks/google-drive-upload-hook'
import { GoogleDriveUploadProps, IGoogleDriveFile } from '@legacy/common/types/GoogleDriveFiles'
import useEnvs from '@legacy/common/hooks/envs-hook'
import { useTranslation } from 'react-i18next'
import { GDriveContainer, GDriveGrid } from './styled'

const FEATURETOGGLE = 'google_drive_picker'

const GDriveWrapper = (props: GoogleDriveUploadProps) => {
  const { files, onDelete, onPost } = useGoogleDriveUpload(props)
  const { gClientId, gDeveloperKey, gScope } = useEnvs()
  const { t } = useTranslation()

  const trackMixpanelEnd = (file: IGoogleDriveFile) => {}

  const trackMixpanelStart = () => {}

  const handlePost = (docs: IGoogleDriveFile[]) => {
    if (docs) onPost(docs[0], trackMixpanelEnd)
  }

  return (
    <>
      <GDriveContainer>
        <GooglePicker
          clientId={gClientId}
          developerKey={gDeveloperKey}
          scope={[gScope]}
          onChange={({ docs }: { docs: IGoogleDriveFile[] }) => handlePost(docs)}
          onAuthFailed={() => toast.error(t('gdrive_loginError'), toastOptions)}
          multiselect={false}
          navHidden
          authImmediate={false}
          viewId='DOCS'
          onAuthenticate={trackMixpanelStart}
        >
          <UploadGoogle />
        </GooglePicker>
        <GDriveGrid>
          {files !== null ? (
            files?.map(file => <GDriveFile key={file.id} file={file} deleteFile={() => onDelete(file.id)} />)
          ) : (
            <div />
          )}
        </GDriveGrid>
      </GDriveContainer>
    </>
  )
}

export default GDriveWrapper
