import styled, { css } from 'styled-components'
import Tag from '@legacy/common/_atoms/Tag'

export const Objective = styled.p``

export const Button = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  margin: 0;
  min-height: 20px;
  min-width: 20px;
  text-align: center;
  text-decoration: none;
  &:focus {
    outline: 0;
  }
`
export const PropTitle = styled.h2`
  font-size: 1.8rem !important;
  line-height: 24px;
  margin-bottom: 25px;
`

export const MaterialTitle = styled.h2`
  font-size: 1.4rem !important;
  line-height: 24px;
  margin-bottom: 10px;
`

export const PlanningField = styled.div`
  p,
  span {
    font-size: 1rem;
    color: #808080;
    font-weight: 400;
    line-height: 1.3rem;
  }
`
export const Arrow = styled.span<{ open: boolean }>`
  border-bottom: 2px solid var(--color-black);
  border-left: 2px solid var(--color-black);
  display: block;
  height: 10px;
  position: relative;
  transform: rotate(-45deg);
  transition: all 0.3s linear;
  width: 10px;
  margin-top: -2px;

  ${props =>
    props.open &&
    css`
      transform: rotate(135deg);
    `}
`
export const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  h2 {
    padding: 0 !important;
  }
`
export const Title = styled.h2`
  color: var(--color-black);
  font-size: 1.8rem !important;
  font-weight: normal !important;
  text-align: center;
`
export const Card = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 16px;
  padding: 18px 23px;
  position: relative;
`
export const CardHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`
export const CardBody = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;

  h3 {
    color: var(--color-black);
    font-size: 1.8rem !important;
    font-weight: normal !important;
  }

  ${props =>
    props.open &&
    css`
      padding: 42px 16px;
      min-height: fit-content;
      max-height: 100%;
      opacity: 1;
    `}
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  svg:hover {
    * {
      fill: #625f6d;
    }
  }

  .stroke {
    svg:hover {
      * {
        stroke: #625f6d;
        fill: transparent;
      }
    }
  }

  .arrow {
    height: fit-content;

    svg:hover {
      * {
        fill: transparent;
      }
    }
  }
`

export const BoxReplicatedText = styled.div`
  background: var(--color-gray-light);
  border-radius: 6px;
  padding: 6px 8px;
  width: 126px;
  height: 26px;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;

  color: var(--color-black);

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`

export const TagStyled = styled(Tag)`
  margin-right: 8px;
`

export const Label = styled.label`
  line-height: 1.2rem;
  text-align: center;
`

export const ActivityTags = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`
