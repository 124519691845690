/* eslint-disable react/prop-types */
import { SchoolLabel, SchoolLogoColor } from './whitelabel'
import useTheme from './theme-hook'
import Branding from 'assets/branding.png'
import EscolaHubLogoImage from 'assets/logo-escolahub.png'
import { StyledLogoImage } from './styled'


const Logo = ({ color = SchoolLogoColor.Black }) => {
  const { theme } = useTheme()

  const logoObject = {
    [SchoolLabel.Default]: {
      [SchoolLogoColor.Black]: Branding,
      [SchoolLogoColor.White]: Branding,
      [SchoolLogoColor.Primary]: Branding,
      [SchoolLogoColor.Text]: Branding,
    },
    [SchoolLabel.EscolaHub]: {
      [SchoolLogoColor.Black]: EscolaHubLogoImage,
      [SchoolLogoColor.White]: EscolaHubLogoImage,
      [SchoolLogoColor.Primary]: EscolaHubLogoImage,
      [SchoolLogoColor.Text]: EscolaHubLogoImage,
    },
    [SchoolLabel.Branding]: {
      [SchoolLogoColor.Black]: Branding,
      [SchoolLogoColor.White]: Branding,
      [SchoolLogoColor.Primary]: Branding,
      [SchoolLogoColor.Text]: Branding,
    },
  }

  return (
    <StyledLogoImage
      src={logoObject[theme][color]}
      alt={theme}
      className={SchoolLabel.Branding}
    />
  )
}

export default Logo
