import { Carousel, ProjectCard } from 'components'
import { CardActions } from '../CardActions'
import { useTranslation } from 'react-i18next'
import { ProjectsSlideProps } from './interfaces'

export const ProjectsSlide = ({ project }: ProjectsSlideProps) => {
  const { t } = useTranslation()

  const badgeList = [
    {
      label: t(`modality.${project.modality}`),
      variant: 'filled',
      color: 'turquoise',
      size: 'lg',
    },
  ]
  return (
    <Carousel.Slide key={project.id}>
      <ProjectCard
        project={project}
        actions={<CardActions project={project} />}
        badges={badgeList}
        showPurpose={false}
        projectList={false}
      />
    </Carousel.Slide>
  )
}
