import { useEffect, useRef, useContext } from 'react'
import { FeedDrawerContext } from '../../../../../../contexts/FeedDrawerContext'
import { useDropzone, DropzoneOptions, ErrorCode } from 'react-dropzone'
import { Button, Loader, Box, Stack, Text, ActionIcon, Image } from 'components'
import { useTranslation } from 'react-i18next'
import * as serviceFeed from '../../../../../../serviceFeed/serviceFeed'
import { notifications } from 'context'
import { IconX, IconUpload, IconTrash } from '@tabler/icons-react'
import { useStyles } from './styles'

export const FileUploader = () => {
  const { sendFileLoader, feedId, setFeedId, files, setFiles, setHasChanges, setSendFileLoader } =
    useContext(FeedDrawerContext)
  const videoRef = useRef<HTMLVideoElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const { t } = useTranslation()
  const { classes } = useStyles()

  useEffect(() => {
    videoRef.current?.load()
  }, [files])

  const handleTrashButtonClick = () => {
    const fileId = files[0].file_id
    fileId
      ? serviceFeed.destroyFile(feedId, fileId)
      : serviceFeed.destroyFeed(feedId)

    setFeedId('')
    setFiles([])
  }

  const VideoPreviews = files
    .filter(file => file.type.includes('video'))
    .map((file, index) => {
      return (
        <Box className={classes.thumbContainer} key={file.name}>
          <video ref={videoRef} key={index} className={classes.video}>
            <source src={file.preview} />
          </video>
          <ActionIcon className={classes.actionIcon} variant='transparent' onClick={handleTrashButtonClick}>
            <IconTrash className={classes.icon} />
          </ActionIcon>
        </Box>
      )
    })

  const ImagePreviews = files
    .filter(file => file.type.includes('image'))
    .map(file => (
      <Box className={classes.thumbContainer} key={file.name}>
        <Image src={file.preview} ref={imageRef} className={classes.image} />
        <ActionIcon className={classes.actionIcon} variant='transparent' onClick={handleTrashButtonClick}>
          <IconTrash className={classes.icon} />
        </ActionIcon>
      </Box>
    ))

  const NINETY_NINE_MB = 103809024

  const options: DropzoneOptions = {
    accept: {
      'image/*': ['.jpeg', '.png', '.gif', '.heif'],
      'video/*': ['.avi', '.mov', '.webm', '.mp4', '.hevc'],
    },
    maxFiles: 1,
    maxSize: NINETY_NINE_MB,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        if (
          rejectedFiles[0].errors.some(
            err => err.code === ErrorCode.FileTooLarge,
          )
        )
          notifications.show({
            title: t('general_error_title'),
            message: t('file_with_max_size'),
            color: 'red',
            icon: <IconX size={24} />,
          })
        if (
          rejectedFiles[0].errors.some(
            err => err.code === ErrorCode.FileInvalidType,
          )
        )
          notifications.show({
            title: t('general_error_title'),
            message: t('file_type_not_allowed'),
            color: 'red',
            icon: <IconX size={24} />,
          })
        return
      }
      setHasChanges(true)
      setSendFileLoader(true)
      const formData = new FormData()
      formData.append('observation_file', acceptedFiles[0])
      formData.append('feed_id', feedId)
      const result = await serviceFeed.uploadFile(formData)
      setFeedId(result.feedId)
      setSendFileLoader(false)
      setFiles([
        {
          preview: result.observationFiles[0].observationFiles.downloadUrl,
          type: result.observationFiles[0].observationFiles.fileType,
          name: result.observationFiles[0].observationFiles.name,
          file_id: result.observationFiles[0].observationFiles.fileId,
        },
      ])
    },
  }

  const { getRootProps, getInputProps } = useDropzone(options)

  return (
    <Stack spacing='md' mt='sm'>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Button variant='light' onClick={() => {}} leftIcon={<IconUpload />} size='md'>
          {t('feed_upload_image_video')}
        </Button>
      </Box>
      {sendFileLoader ? (
        <Stack align='center' justify='center' spacing='xs'>
          <Loader />
          <Text size='sm'>{t('feed_sending_file')}</Text>
        </Stack>
      ) : (
        <Box>{[...ImagePreviews, VideoPreviews]}</Box>
      )}
    </Stack>
  )
}
