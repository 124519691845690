import { u } from 'msw/lib/glossary-297d38ba'
import { IModelErrors } from '../base-model'

export interface INewSession {
  readonly login: string
  readonly password: string
  readonly rememberMe: boolean
  readonly urlToRedirect: string
}

export const initialNewSession: INewSession = {
  login: '',
  password: '',
  rememberMe: false,
  urlToRedirect: ''
}

export interface INewMasterSession {
  readonly path: string
}

export type INewSessionErrors = IModelErrors<INewSession>

export const initialNewSessionErrors: INewSessionErrors = {
  login: [],
  password: []
}
