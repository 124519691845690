// @ts-nocheck
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { v4 as UuidV4 } from 'uuid'
import Lv4ItemContainer from '@legacy/common/_molecules/Lv4ItemContainer'
import { PropTitle } from '../styled/Activity'

// TODO: add interface to specificSkillAndContent
const SpecificSkillAndContent = ({ data = [], withBreakLine = false }) => {
  const { t } = useTranslation()
  const skillsSize = data.length
  if (skillsSize <= 0) {
    return null
  }

  return (
    <React.Fragment>
      <div>
        {withBreakLine && <hr />}
        <PropTitle>
          {t('activities_skillsAndContentAssessed')} ({skillsSize})
        </PropTitle>
      </div>
      {data.map(specificSkillAndContent => (
        <Lv4ItemContainer
          key={UuidV4()}
          name={specificSkillAndContent.name}
          lv1Name={specificSkillAndContent.lv1_name}
          id={specificSkillAndContent.id}
          curriculumKind={specificSkillAndContent.curriculum_kind}
          color={specificSkillAndContent.color}
          originalCodes={specificSkillAndContent.original_codes}
          cycles={specificSkillAndContent.cycles}
          hideContainer
        />
      ))}
    </React.Fragment>
  )
}

SpecificSkillAndContent.propTypes = {
  data: PropTypes.array,
  withBreakLine: PropTypes.bool,
}

export default SpecificSkillAndContent
