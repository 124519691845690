import { Flex, Title } from '@mantine/core'
import FileUpload from '@legacy/common/_components/FileUpload'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import styled from 'styled-components'
import updateMural from '@legacy/common/_shared/MuralItemsHelper'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: block;

  .files-list {
    margin-top: 1rem;
  }
`

const WrappFiles = ({ id, projectId }: { id: string; projectId: string }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Flex direction='column'>
        <Title order={6}>{t('students_edit_files')}</Title>
        <FileUpload
          uploadUrl={`/assessments/${id}/assessment_student_file_items`}
          fetchFilesUrl={`assessments/${id}/assessment_student_file_items.json`}
          deleteFilesUrl={`/assessments/${id}/assessment_student_file_items`}
          typeFile='file'
          buttonsByFileType
          customAction={updateMural({
            projectId: projectId,
          })}
        />

        <GDriveWrapper
          value={{
            resourceId: id,
            resourceClass: 'assessment_id',
          }}
        />
      </Flex>
    </Wrapper>
  )
}

export default WrappFiles
