import { useState, useCallback, useEffect } from 'react'
import { Rating } from '@mantine/core'
import { Title } from '../../../../components/Title'
import { Grid } from '../../../../components/Grid'
import { Flex } from '../../../../components/Flex'
import { Text } from '../../../../components/Text'
import { useTranslation } from 'react-i18next'
import { RatingItem, RatingProp } from '../../interfaces'
import { useMediaQuery } from '@mantine/hooks'

function convertToPayload(ratings: RatingProp[]): Record<string, number> {
  const result: Record<string, number> = {}
  for (const rating of ratings) {
    const key = rating.id.replace(/-/g, '_')
    result[key] = rating.rating
  }
  return result
}

const IndividualRating = ({
  id,
  rating,
  onChange,
}: {
  id: string
  rating: number
  onChange: (id: string, value: number) => void
}) => {
  const handleChange = (value: number) => {
    onChange(id, value)
  }
  const largeScreen = useMediaQuery('(min-width: 700px)')

  return <Rating value={rating} onChange={handleChange} size={largeScreen ? 'md' : 'xl'} />
}

export const MeetingRating = ({
  updatePayloadRatting,
  moods,
}: {
  updatePayloadRatting: (update: any) => void
  moods: any
}) => {
  const { t } = useTranslation()
  const largeScreen = useMediaQuery('(min-width: 700px)')

  const titleDictionary: Record<string, string> = {
    moodMasterEvaluationByStudent: t('students_rating_1'),
    moodGroupDeveloped: t('students_rating_2'),
    moodStudentDeveloped: t('students_rating_3'),
    moodStudentLiked: t('students_rating_4'),
    moodStudentTookPart: t('students_rating_5'),
  }

  const ratingsList2: { id: string; name: string; rating: any }[] = Object.entries(moods).map(([id, rating]) => ({
    id,
    name: titleDictionary[id],
    rating,
  }))

  const [globalRating, setGlobalRating] = useState<RatingItem[]>(ratingsList2)

  const handleRatingChange = useCallback(
    (id: string, value: number) => {
      const updatedGlobalRating = globalRating.map(rating => {
        if (rating.id === id) {
          return {
            ...rating,
            rating: value,
          }
        }
        return rating
      })
      setGlobalRating(updatedGlobalRating)
    },
    [globalRating],
  )

  useEffect(() => {
    const result = convertToPayload(globalRating)
    updatePayloadRatting(result)
  }, [globalRating])

  return (
    <>
      <Title order={5}>{t('students_reflection')}</Title>
      <Grid gutter={3} gutterXs='md' align='center' mb='md'>
        {globalRating.map(rating => (
          <Grid.Col key={rating.id} span={largeScreen ? 4 : 12} mt={largeScreen ? 0 : 'md'}>
            <Flex direction='column' mt='xs'>
              <Text data-testid={`rating-item-name-${rating.id}`}>{rating.name}</Text>
              <IndividualRating
                data-testid={`rating-item-${rating.id}`}
                id={rating.id}
                rating={rating.rating}
                onChange={handleRatingChange}
              />
            </Flex>
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
