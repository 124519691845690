import { useState } from 'react'
import { isEqual } from 'lodash'
import { Loading } from '@legacy/common/_components'
import StudentsAssessments from './components/StudentsAssessments/StudentsAssessments'
import { useTranslation } from 'react-i18next'
import { ProjectSubheader } from '@legacy/common/Project/_components/ProjectSubheader'
import {
  Container,
} from '@legacy/common/_components/HidenPrint/styled'
import { LumiarToastContainer } from '@legacy/common/_components/LumiarToast'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import * as service from './service'
import * as serviceInterface from './service/interfaces'
import { useEffect } from 'react'
import ErrorService from '@legacy/common/Tools/ErrorService'
import { ProjectContainerImage } from 'components/ProjectContainerImage'
import { ProjectContainer } from 'components/ProjectContainer'
import { ProjectHeader } from 'components/ProjectHeader'
import { IProjectHeaderTexts } from 'components/ProjectHeader/interfaces'
import { ProjectObjectives } from 'components/ProjectObjectives'
import { ProjectMoods } from 'components/ProjectMoods'
import useMixPanel from '@legacy/common/hooks/mix-panel-hook'
import { initialState } from './initialState'
import { toast } from 'react-toastify'
import { toastOptions } from '@legacy/common/_components/LumiarToast'
import { ProjectJournal } from './components/ProjectJournal'
import { FilePreview } from './components/FilePreview'
import { SaveButton } from './components/SaveButton/SaveButton'
import { IFinalAssessment } from './interfaces'

export const FinalAssessment = ({
  projectId,
}: IFinalAssessment) => {
  const { track } = useMixPanel()
  const { t } = useTranslation()

  const initialPayloadObj = initialState(projectId).payload

  const [loadingPage, setLoadingPage] = useState(true)
  const [loadingSaveButton, setLoadingSaveButton] = useState(false)
  const [project, setProject] = useState<serviceInterface.IFinalAssessment>()
  const [payload, setPayload] = useState<serviceInterface.ServerData>(initialPayloadObj)
  const [initialPayload, setInitialPayload] = useState<serviceInterface.ServerData>(initialPayloadObj)

  useEffect(() => {setPage()}, [])

  const generatePage = async () => {
    const project = await service.fetchFinalAssessment(projectId)

    return {
      project,
      loadingPage: false
    }
  }

  const setPage = async () => {
    try {
      const page = await generatePage()
      const data: serviceInterface.ServerData = {
        ...initialPayloadObj,
        moods: page.project.moods,
        project_journal: page.project.journal,
      }
      setLoadingPage(page.loadingPage)
      setProject(page.project)
      setPayload(data)
      setInitialPayload(data)
    } catch (e) {
      ErrorService.notice(e)
    }
  }

  const clickStar = (fieldName: keyof serviceInterface.Moods, value: number) => {
    if (!payload) return

    const previousMoodValue = payload.moods[fieldName]

    const updatedValue = value === previousMoodValue ? 0 : value

    const moods = {
      ...payload.moods,
      [fieldName]: updatedValue
    }

    setPayload({
      ...payload,
      moods
    })
  }

  const updateProjectJournal = (project_journal: string) => {
    if (!payload) return

    setPayload({
      ...payload,
      project_journal
    })
  }

  const bgImage = project?.cover_image && `url(${project.cover_image})`

  const projectHeaderTexts: IProjectHeaderTexts = {
    button: t('projects_back_to_project'),
    header: t('final_assessments_index_title')
  }

  const driverInfo = {
    resourceId: projectId,
    resourceClass: 'project_id',
  }

  const updateMoods = async () => {
    if (!isEqual(payload.moods, initialPayload.moods)) {
      await service.updateMoods(projectId, payload.moods)
    }
  }

  const updateJournal = async () => {
    if (!isEqual(payload.project_journal, initialPayload.project_journal)) {
      await service.updateJournal(projectId, payload.project_journal)
    }
  }

  const updateProject = async () => {
    setLoadingSaveButton(true)
    await Promise.all([updateMoods(), updateJournal()])
    setPage()
    setLoadingSaveButton(false)
  }

  const onClickSaveButton = async () => {
    updateProject()
    await toast.success(t('general_messages_saved'), toastOptions)
  }

  if (loadingPage || !project || !payload) return <Loading />

  return (
    <Container>
      <LumiarToastContainer />
      <ProjectContainerImage bgImage={bgImage} />
      <ProjectContainer>
        <ProjectHeader
          projectId={projectId}
          text={projectHeaderTexts}
        />
        <ProjectSubheader project={project} />
        <ProjectObjectives objectives={project.objectives} />
        <ProjectMoods moods={payload.moods} clickStar={clickStar} />
        <ProjectJournal updateJournal={updateProjectJournal} journal={payload.project_journal ?? ''} />
        <FilePreview projectId={projectId} />
        <GDriveWrapper value={driverInfo} />
        <SaveButton loading={loadingSaveButton} onClick={onClickSaveButton}/>
        <StudentsAssessments
          students={[]}
          mixPanelTracker={track}
          meetingId={projectId}
          setPayload={setPayload}
          setInitialPayload={setInitialPayload}
          payload={payload}
          initialPayload={initialPayload}
          setPage={setPage}
          updateProject={updateProject}
        />
      </ProjectContainer>
    </Container>
  )
}
