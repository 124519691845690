import styled from 'styled-components'

export const Wrap = styled.button<{ active: boolean }>`
  background: none repeat scroll 0 0 transparent;
  background-color: white;
  border-spacing: 0;
  list-style: none outside none;
  padding: 0;
  text-decoration: none;
  text-indent: 0;
  width: 62px;
  height: 72px;
  border: 1px solid
    ${props =>
    props.active
      ? 'var(--color-primary-ultradark)'
      : 'var(--color-gray-light)'};
  border-radius: 6px;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);

  :hover {
    opacity: 0.9;
    border: 1px solid var(--color-primary-ultradark);
  }

  &:disabled {
    border: 1px solid var(--color-gray-light);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    background: var(--color-gray-light);
    cursor: no-drop;
  }
`

export const WrapWithOutBorder = styled.button<{ active: boolean }>`
  background: none repeat scroll 0 0 transparent;
  border-spacing: 0;
  list-style: none outside none;
  padding: 0;
  text-decoration: none;
  text-indent: 0;
  width: 40px;
  height: 72px;
  border: none;
  position: relative;
  cursor: pointer;

  span {
    color: ${props =>
    props.active ? 'var(--color-black)' : 'var(--color-text-disabled)'};
  }

  path {
    stroke: ${props =>
    props.active ? 'var(--color-black)' : 'var(--color-text-disabled)'};
  }

  :hover {
    opacity: 0.9;
  }

  &:disabled {
    background: var(--color-gray-light);
    cursor: no-drop;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`
