import { api } from '../../helpers/'
import { IStudent } from '../../pages/Students/types/student-types'
import axios, { AxiosResponse } from 'axios'

export const filterStudents = async (
  status: number,
  currentPage: number,
  search: string | null,
): Promise<AxiosResponse<IStudent[]>> => {
  const urlSearchParams = new URLSearchParams({
    'q[status_eq]': status.toString(),
    page: currentPage.toString(),
    ...(search && search.length > 1 && { 'q[user_full_name_cont]': search }),
  })

  const url = `/students.json?${urlSearchParams.toString()}`

  try {
    return await api.get<IStudent[]>(url)
  } catch (error) {
    return Promise.reject('Error getting students list')
  }
}

export const deleteUser = async (path: string) => {
  try {
    const response = await axios.delete(path)
    return response
  } catch (error: any) {
    return error.response.data
  }
}
