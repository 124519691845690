import { useState, memo, useEffect, useContext } from 'react'
import { ErrorContext } from 'context'
import { useCurrentUser } from 'context/useCurrentUser'
import { DropdownMenu, DropdownMenuPortal } from 'components/DropdownMenu'
import { Profile } from '../Profile'
import { ProfileMenu } from './ProfileMenu'
import { LogoutMenu } from './LogoutMenu'
import {
  DropdownMenuTriggerStyled,
  DropdownMenuContentStyled,
  DropdownMenuArrowStyled,
  Container,
} from './NavbarMenuStyled'
import { SwitchProfileMenu } from './SwitchProfileMenu'
import { AdminMenu } from './AdminMenu'
import { SwitchLanguage } from './SwitchLanguage'
import NewsButton from '../NewsButton'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import connectProduktlyHook from '../../../hooks/connectProduktlyHook'
import { getCurrentUser } from 'api/user/service'
import { Select, Box } from 'components'
import { getMySchools, updateSchool } from 'api'
import { SelectItem } from '@mantine/core'
import { useStyles } from './styles'

const SUPPORTED_LANGUAGES: string[] = ['en', 'pt-BR']

interface IContent {
  readonly children: React.ReactNode
  readonly sideOffset?: number
}

type LocaleChangeFunction = (locale: string) => void

const getLocaleFromSearchParams = (searchParams: URLSearchParams): string | null => {
  return searchParams.get('locale')
}

const Content = ({ children, ...props }: IContent) => (
  <DropdownMenuPortal>
    <DropdownMenuContentStyled {...props}>
      {children}
      <DropdownMenuArrowStyled />
    </DropdownMenuContentStyled>
  </DropdownMenuPortal>
)

export const NavbarMenu = memo(() => {
  const { classes } = useStyles()
  const { setError } = useContext(ErrorContext)
  const [searchParams] = useSearchParams()
  const { currentUser } = useCurrentUser()
  const { i18n } = useTranslation()
  const lang = searchParams.get('locale')
  const [currentLocale, setCurrentLocale] = useState<undefined | string>(lang || currentUser?.locale || undefined)
  const isApp = searchParams.get('app') === 'true'
  const [selectedLang, setSelectedLang] = useState<string | null>(null)
  const [schoolsSelectData, setSchoolsSelectData] = useState<SelectItem[]>([])
  const [schoolsSelectValue, setSchoolsSelectValue] = useState<string | null>(null)
  const isTutorOrManager = currentUser?.profile_used === 'tutor' || currentUser?.profile_used === 'manager'
  const currentSchool = currentUser?.selected_school?.id

  const changeLocale = (
    lang: string | null,
    currentUserLocale: string,
    setCurrentLocale: LocaleChangeFunction,
    i18n: { changeLanguage: LocaleChangeFunction },
  ): void => {
    const targetLocale = lang && SUPPORTED_LANGUAGES.includes(lang) ? lang : currentUserLocale
    setCurrentLocale(targetLocale)
    i18n.changeLanguage(targetLocale)
  }

  useEffect(() => {
    const lang = getLocaleFromSearchParams(new URLSearchParams(window.location.search))

    if (currentUser?.locale) {
      changeLocale(lang, currentUser.locale, setCurrentLocale, i18n)
    }
  }, [currentUser, i18n])

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await getCurrentUser()
        connectProduktlyHook(user)
      } catch (error) {
        console.error(error)
      }
    }

    if (selectedLang !== i18n.language) {
      setSelectedLang(i18n.language)
      getUser()
    }

    setSelectedLang(i18n.language)
  }, [i18n.language])

  const handleSchoolSelectChange = (value: string) => {
    setSchoolsSelectValue(value)
    updateSchool(value)
      .then(response => {
        if (response && response.status === 200) {
          window.localStorage.removeItem('current_user')
          if (currentUser?.profile_used === 'manager') window.location.replace('/manager/dashboard')
          if (currentUser?.profile_used === 'tutor') window.location.replace('/teacher/dashboard')
        } else {
          setError(response.statusText)
          console.error(response.statusText)
        }
      })
      .catch(error => {
        setError(error)
        console.error(error)
      })
  }

  useEffect(() => {
    const getMySchoolsData = async () => {
      getMySchools()
        .then(response => {
          const schoolsSelectData = response.data.map((school: { id: string; name: string }) => ({
            value: school.id,
            label: school.name,
          }))
          if (schoolsSelectData.length === 0) return
          setSchoolsSelectData(schoolsSelectData)
          if (currentSchool) setSchoolsSelectValue(currentSchool)
        })
        .catch(error => {
          setError(error)
          console.error(error)
        })
    }
    if (isTutorOrManager) getMySchoolsData()
  }, [isTutorOrManager])

  return (
    <Container isApp={isApp}>
      {isTutorOrManager && schoolsSelectData.length > 1 ? (
        <Box className={classes.selectBox}>
          <Select
            data={schoolsSelectData}
            value={schoolsSelectValue}
            onChange={handleSchoolSelectChange}
            withinPortal
            size='md'
            searchable
            sx={{ width: 260 }}
          />
        </Box>
      ) : null}
      <NewsButton />
      <DropdownMenu modal={false}>
        <DropdownMenuTriggerStyled>
          <Profile />
        </DropdownMenuTriggerStyled>
        <Content sideOffset={5}>
          <ProfileMenu />
          <SwitchLanguage currentLocale={currentLocale} setCurrentLocale={setCurrentLocale} />
          <SwitchProfileMenu />
          <AdminMenu />
          <LogoutMenu />
        </Content>
      </DropdownMenu>
    </Container>
  )
})

NavbarMenu.displayName = 'NavbarMenu'
