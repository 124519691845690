import { TextAreaStyled, ValidationTextStyled } from './TextAreaStyled'
import { useState, useEffect } from 'react'
import { ITextAreaLumiar } from './TextAreaTypes'
import i18n from '@i18n'

const TextAreaLumiar = ({
  value,
  placeholder = 'Placeholder text',
  onChange,
  valid = true,
}: ITextAreaLumiar) => {
  const [text, setText] = useState<string>(value)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const validationText = i18n.t('required_field')

  useEffect(() => {
    if (text === value) return

    onChange(text)
  }, [text])

  return (
    <>
      <TextAreaStyled
        valid={valid}
        placeholder={placeholder}
        onChange={handleChange}
        value={text}
      />
      {valid ? <></> : <ValidationTextStyled>{validationText}</ValidationTextStyled>}
    </>
  )
}

export default TextAreaLumiar
