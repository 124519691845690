import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Modal, Text, Group, Button } from 'components'
import { RemoveConfirmModalProps } from './interfaces'

export const ConfirmAssessmentChangeModal: React.FC<RemoveConfirmModalProps> = ({ opened, onClose, action }) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const handleModalAction = () => {
    action()
    onClose()
  }

  return (
    <Modal fullScreen={isMobile} size='lg' opened={opened} onClose={onClose} title={t('assessment_data_modal_title')}>
      <Text size='md' mb='sm'>
        <Trans i18nKey='assessment_data_modal_description' />
      </Text>

      <Group spacing='sm' position='right' mt='md'>
        <Button variant='default' size='lg' onClick={onClose}>
          {t('general_buttons_cancel')}
        </Button>
        <Button variant='filled' size='lg' onClick={handleModalAction}>
          {t('confirm_kind_change_body_confirm')}
        </Button>
      </Group>
    </Modal>
  )
}
