import { useTranslation } from "react-i18next"
import { ISaveButton } from "./interfaces"
import { StyledSaveButton } from "./styled"

export const SaveButton = ({ loading, onClick }: ISaveButton) => {
    const { t } = useTranslation()

    return (
      <StyledSaveButton
        text={t('general_buttons_save')}
        onClick={onClick}
        loading={loading}
        size='large'
      />
    )
}