import type { CamelCasedPropertiesDeep } from 'type-fest'

export interface Author {
  first_name: string
  last_name: string
  avatar: string
  educator_role: string
}

export interface ObservationFile {
  name: string
  file_id: number
  file_cover: string
  file_cover_large: string
  file_type: string
  size_in_bytes: number
  download_url: string
}

export interface GoogleDriveFile {
  id: string
  name: string
  description: string
  icon_url?: string
  url: string
  owner_type: string
  owner_id: string
  created_at: Date
  updated_at: Date
  mime_type: string
}

export interface Permissions {
  edit: boolean
  change_status: boolean
  delete: boolean
}

export interface Feed {
  feedId: string
  dateDraft: string
  hourDraft: string
  datePublished: string
  hourPublished: string
  author: Author
  description: string
  students: string[]
  observationFiles: ObservationFile[]
  googleDriveFiles: GoogleDriveFile[]
  permissions: Permissions
  thereIsStudent: boolean
}

export type FeedCamelCased = CamelCasedPropertiesDeep<Feed>

export interface Vars {
  page: number
  items: number
  outset: number
  size: number[]
  page_param: string
  params: Record<string, never>
  fragment: string
  link_extra: string
  i18n_key: string
  cycle: boolean
  metadata: string[]
  count: number
}

export interface Pagination {
  scaffold_url: string
  first_url: string
  prev_url: string
  page_url: string
  next_url: string
  last_url: string
  count: number
  page: number
  items: number
  vars?: Vars
  pages: number
  last: number
  in: number
  from: number
  to: number
  prev?: number
  next: number
  series: string[]
}

export type CamelCasedPagination = CamelCasedPropertiesDeep<Pagination>

export interface IFeeds {
  feeds: Feed[]
  pagination: Pagination
}

export enum FeedStatus {
  Draft,
  Published,
}

export interface IObservationPayload {
  description: string
  studentIds: string[]
  googleDriveFiles: GoogleDriveFile[]
  status: FeedStatus
  projectId: string
}

export interface IObservationStudent {
  id: string
  name: string
}

export interface IGoogleDriveFile {
  id: string
  name: string
  description: string
  url: string
  icon_url?: string
  mime_type: string
}

export interface IObservationResponse {
  description: string
  status: string
  students: IObservationStudent[]
  observationFiles: ObservationFile[]
  googleDriveFiles: IGoogleDriveFile[]
  feedId: string
}

export type CamelCasedObservationResponse =
  CamelCasedPropertiesDeep<IObservationResponse>
