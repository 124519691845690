export const isIpadOS = (): boolean => {
  const isChromeForIpad = /CriOS/.test(navigator.userAgent) && /iPad/.test(navigator.platform)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  return Boolean(
    (isSafari && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) ||
      isChromeForIpad ||
      (!isSafari && /MacIntel/.test(navigator.platform)),
  )
}
