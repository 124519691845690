import styled from 'styled-components'

export const TagWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color};
  width: fit-content;
  padding: 4px 8px;
  border-radius: 6px;

  height: fit-content;
`

export const Label = styled.span<{ color: string }>`
  color: ${props => props.color};
  font-size: 0.75rem;
`
