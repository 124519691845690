import styled from 'styled-components'

export const Tag = styled.div<{ color: string }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px;
  background-color: ${props => props.color};
  width: fit-content;
  border-radius: 6px;
  margin-right: 8px;
`

export const Label = styled.span<{ textColor: string }>`
  color: ${props => props.textColor};
`
