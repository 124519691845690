import { api } from 'helpers'
import {
  ChangeFeedStatus,
  CleanObservations,
  CreateObservation,
  DeleteFeed,
  DestroyFeed,
  DestroyFile,
  EditObservation,
  GetFeeds,
  GetPage,
  GetStudents,
  UploadFile,
  CamelCasedFeeds,
  StudentCamelCased,
  CamelCasedFileResponse,
  CamelCasedObservationResponse,
} from './index'

export const getFeeds: GetFeeds = async query => {
  const response = await api.get<CamelCasedFeeds>(`api/feeds?q[status_eq]=${query}`)
  return response.data
}

export const deleteFeed: DeleteFeed = async id => {
  const response = await api.delete<Record<'string', never>>(`api/feeds/${id}`)
  return response.data
}

export const changeFeedStatus: ChangeFeedStatus = async (id, status) => {
  const response = await api.get<Record<'string', never>>(`api/feeds/${id}/change_status?status=${status}`)
  return response.data
}

export const getPage: GetPage = async url => {
  const response = await api.get<CamelCasedFeeds>(url)
  return response.data
}

export const getStudents: GetStudents = async () => {
  const response = await api.get<StudentCamelCased[]>('api/observations/my_students')
  return response.data
}

export const cleanObservations: CleanObservations = async () => {
  const response = await api.get<Record<'string', never>>('api/observations/clean_observations_empty')
  return response.data
}

export const destroyFile: DestroyFile = async (feedId, fileId) => {
  const response = await api.get<Record<'string', never>>(`api/observations/destroy_file/${feedId}/${fileId}`)
  return response.data
}

export const destroyFeed: DestroyFeed = async feedId => {
  const response = await api.delete<Record<'string', never>>(`api/feeds/${feedId}`)
  return response.data
}

export const uploadFile: UploadFile = async formData => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  const response = await api.post<CamelCasedFileResponse>('api/observations/upload_file', formData, config)
  return response.data
}

export const createObservation: CreateObservation = async payload => {
  const response = await api.post<CamelCasedObservationResponse>('api/observations', payload)
  return response.data
}

export const editObservation: EditObservation = async (feedId, payload) => {
  const response = await api.patch<CamelCasedObservationResponse>(`api/feeds/${feedId}`, payload)
  return response.data
}
