import { useContext, createContext, useState } from 'react'

const initialValues = {
  notification: false,
  text: '',
  handleNotification: () => {},
  kind: '',
}

interface INotification {
  notification: boolean
  text: string
  handleNotification: (value: boolean, text: string, autoClose?: boolean, kind?: string) => void
  kind?: string
}

const NotificationContext = createContext<INotification>(initialValues)

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notification, setNotification] = useState(false)
  const [text, setText] = useState('')
  const [kind, setKind] = useState('')

  const handleNotification = (value: boolean, text: string, autoClose = false, kind = 'success') => {
    setText(text)
    setNotification(value)
    setKind(kind)
    if (autoClose) {
      setTimeout(() => {
        setText('')
        setNotification(false)
      }, 4000)

      return
    }
  }

  const value = {
    notification,
    text,
    handleNotification,
    kind,
  }

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>
}

export const useNotification = () => {
  return useContext(NotificationContext)
}
