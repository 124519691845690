import { useState, useEffect } from 'react'

export const useAddressBarHeight = () => {
  const [addressBarHeight, setAddressBarHeight] = useState(0)

  const calculateAddressBarHeight = () => {
    const totalHeight = window.outerHeight
    const visibleHeight = window.innerHeight
    return totalHeight - visibleHeight
  }

  const handleResize = () => {
    setAddressBarHeight(calculateAddressBarHeight())
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [])

  return addressBarHeight
}
