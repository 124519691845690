import { useState, useMemo } from 'react'
import { rem, useMantineTheme, useMediaQuery } from 'hooks'
import { SidebarMenuProps } from './interfaces'
import { SidebarMenuItem } from './components/SidebarMenuItem'
import { ISidebarMenuItem } from 'interfaces'
import { useStyles } from './styles'
import { ActionIcon, Box, Group, Image } from 'components'
import { IconMenu2 } from '@tabler/icons-react'
import { SidebarHelpItem } from './components/SidebarHelpItem'
import Logo from 'assets/branding.png'
import { getStudentMenu } from './menus/getStudentMenu'
import { teacherMenu } from './menus/teacherMenu'
import { managerMenu } from './menus/managerMenu'
import { guardianMenu } from './menus/guardianMenu'

export const SidebarMenu = ({ pathname, profile, userID }: SidebarMenuProps) => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [collapsed, setCollapsed] = useState(true)
  const { classes } = useStyles({ collapsed, isMobile })

  const studentMenu = getStudentMenu(userID)

  const menuItems = useMemo(() => {
    switch (profile) {
      case 'student':
        return studentMenu
      case 'tutor':
        return teacherMenu
      case 'manager':
        return managerMenu
      case 'guardian':
        return guardianMenu
      default:
        return []
    }
  }, [profile])

  return (
    <div className={classes.sidebar}>
      <Group spacing={0} className={classes.toggleLogoBox}>
        <Box className={classes.toggleButtonBox}>
          <ActionIcon
            variant='transparent'
            color='dark'
            size='lg'
            onClick={() => setCollapsed(!collapsed)}
            className={classes.actionIconToggle}
          >
            <IconMenu2 className={classes.iconToggle} />
          </ActionIcon>
        </Box>
        {!collapsed && <Image src={Logo} alt={'Mosaico'} width={rem(140)} />}
      </Group>
      <div className={classes.menuItems}>
        <ul className={classes.listRoot}>
          {menuItems &&
            menuItems.map((item: ISidebarMenuItem) => (
              <SidebarMenuItem key={item.link} item={item} pathname={pathname} collapsed={collapsed} />
            ))}
        </ul>
        <SidebarHelpItem collapsed={collapsed} />
      </div>
    </div>
  )
}
