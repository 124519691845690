import i18n from '@i18n'
import { ISearchBar } from './intrefaces'
import { SearchContainer, SearchInput } from './StyledComponents'

export function SearchBar({
  onChangeFindText,
  findText,
  placeholder = i18n.t('search'),
}: ISearchBar) {
  return (
    <SearchContainer>
      <SearchInput
        type='text'
        autoFocus
        placeholder={placeholder}
        value={findText}
        onChange={onChangeFindText}
      />
    </SearchContainer>
  )
}
