import { StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    margin: '0px',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    height: 'auto',
    minHeight: '100px',
  },
  headerText: {
    marginLeft: '24px',
  },
  termDate: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#203049',
  },
  titleXL: {
    fontSize: '22px',
    fontWeight: 'bold',
    color: '#203049',
  },
  titleLG: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#203049',
  },
  titleMD: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#203049',
    marginBottom: '4px',
    marginTop: '12px',
  },
  titleSM: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#203049',
    marginBottom: '8px',
    marginTop: '12px',
  },
  selfSkillsBlock: {
    marginTop: '8px',
  },
  groupSkillsBlock: {
    marginTop: '8px',
    marginBottom: '54px',
  },
  contentBlock: {
    marginTop: '8px',
  },
  imgContainer: {
    width: '80px',
    height: '80px',
    backgroundColor: '#C371C5',
    borderRadius: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
  },
  imgContainerSmall: {
    width: '32px',
    height: '32px',
    backgroundColor: '#C371C5',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
    marginRight: '10px',
  },
  avatar: {
    width: '100%',
    borderRadius: '100%',
  },
  PeriodTextContainer: {
    marginTop: '16px',
  },
  profilleLetters: {
    backgroundColor: 'blue',
    width: '50%',
    height: '50%',
  },
  p: {
    color: '#625f6d',
    fontSize: '10px',
    maxWidth: '100%',
    marginBottom: '4px',
    textAlign: 'justify',
  },
  bold: {
    color: '#203049',
    fontWeight: 'bold',
  },
  rubricTitle: {
    fontSize: '9px',
    fontWeight: 'bold',
    color: '#212529',
  },
  rubricScore: {
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#212529',
  },
  rubric: {
    color: '#203049',
    fontSize: '8px',
    marginTop: '2px',
  },
  scoreTitle: {
    textTransform: 'uppercase',
    color: '#203049',
    fontSize: '9px',
    maxWidth: '85%',
    fontWeight: 'bold',
  },
  scoreNumber: {
    textTransform: 'uppercase',
    color: '#203049',
    fontSize: '10px',
    maxWidth: '15%',
    fontWeight: 'bold',
  },
  skillsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '4px',
  },
  ComponentScoresContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '10px',
  },
  skillsBox: {
    backgroundColor: '#69db7c',
    display: 'flex',
    height: '110px',
    width: '132px',
    marginTop: '6px',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '8px',
    color: 'black',
  },
  skillsBoxGray: {
    backgroundColor: '#8D8A99',
    display: 'flex',
    height: '110px',
    width: '132px',
    marginTop: '6px',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '8px',
  },
  end: {},
  fullTutorContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  teacherContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  educatorRole: {
    fontSize: '10px',
    fontWeight: 'normal',
    color: '#212529',
  },
  scoresBox: {
    display: 'flex',
    width: '30%',
    marginTop: '15px',
    justifyContent: 'space-between',
  },
  projectsBox: {
    display: 'flex',
    width: '100%',
  },
  assessedItemsBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  imageProject: {
    width: '100%',
    height: '90px',
  },
  curricularComponentItem: {
    marginBottom: '16px',
    width: '48%',
    height: 'auto',
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  barProgressGray: {
    backgroundColor: '#8D8A99',
    borderRadius: '10px',
    height: '8px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  barProgressGreen0: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '0%',
  },
  barProgressGreen1: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '20%',
  },
  barProgressGreen2: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '40%',
  },
  barProgressGreen3: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '60%',
  },
  barProgressGreen4: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '80%',
  },
  barProgressGreen5: {
    backgroundColor: '#69db7c',
    borderRadius: '10px',
    height: '8px',
    marginTop: '-13px',
    marginBottom: '5px',
    width: '100%',
  },
  tutorsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
})

export default styles
