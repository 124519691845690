import { Component, Fragment } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'
import i18n from '@i18n'
import { Container } from '@legacy/components/HidenPrint/styled'
import Button from '@legacy/common/_atoms/Button'
import ResponsibleAvatar from './ResponsibleAvatar'

class ProjectSubheaderModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  toggleModal() {
    this.setState(prevState => ({ showModal: !prevState.showModal }))
  }

  render() {
    const { tutors } = this.props
    const { showModal } = this.state

    return (
      <Container>
        <Button
          type="light"
          icon="sm-plus"
          label={i18n.t('projects_show_teamModal_button')}
          onClick={() => this.toggleModal()}
          className="hide-in-print"
        />

        <Modal
          show={showModal}
          onHide={() => this.toggleModal()}
          dialogClassName="center-modal"
          className="small-titles"
        >
          <Modal.Body>
            <div className="modal-header border-0 px-5 pt-5 pb-0 justify-content-end">
              <button type="button" className="new-buttons new-buttons-clear new-buttons-small" onClick={() => this.toggleModal()}>
                <i className="icon-common-close" />
              </button>
            </div>
            <div className="modal-body px-5 pb-5">
              <h1 className="text-center pb-5">{i18n.t('projects_show_teamModal_title')}</h1>

              {tutors && tutors.length > 0 && (
                <Fragment>
                  <div className="d-flex flex-wrap justify-content-center my-5">
                    {tutors.map(tutor => {
                      return (
                        <ResponsibleAvatar
                          key={tutor.id}
                          name={tutor.name}
                          subtitle={tutor.educator_role || tutor.educatorRole}
                          img={tutor.avatar}
                          link={tutor.link}
                          isMain={tutor.id === tutor.main_tutor_id}
                        />
                      )
                    })}
                  </div>
                </Fragment>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    )
  }
}

ProjectSubheaderModal.propTypes = {
  tutors: PropTypes.arrayOf(PropTypes.object),
}

ProjectSubheaderModal.defaultProps = {
  tutors: [],
}

export default ProjectSubheaderModal
