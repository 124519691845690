import { useEffect, useState, useContext } from 'react'
import {
  Title,
  Paper,
  Stack,
  Text,
  Divider,
  Select,
  SimpleGrid,
  Skeleton,
  TextRequired,
  Group,
  EmptyStateBox,
} from 'components'
import { useMantineTheme, rem, useMediaQuery } from 'hooks'
import { IconCheck, IconUsersPlus, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { notifications, useForm } from 'context'
import { StepperContext } from '../../context/StepperContext'
import { ParticipantsContext } from '../../context/ParticipantsContext'
import { ProjectStepsButtons } from '../ProjectStepsButtons'
import { ProjectButtonsContext } from '../../context/ProjectButtonsContext'
import { GroupStudentsBlock } from '../GroupStudentsBlock'
import { OtherStudentsBlock } from '../OtherStudentsBlock'
import { GroupTeachersBlock } from '../GroupTeachersBlock'
import { OtherTeachersBlock } from '../OtherTeachersBlock'
import { getSchoolTerms, getMyGroups, getGroupPeople, updateProject, getProjectTab } from '../../service/projects'
import { isEmpty } from 'lodash'
import { FormValues, GroupData, Tab2Data, TermData, initalDatatab2 } from './interfaces'
import { StudentGroup, convertStudentArray, convertTeacherArray, hasDataChanged } from './helper'
import { modals } from '@mantine/modals'

export function ProjectParticipants() {
  const navigate = useNavigate()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { t } = useTranslation()
  const { buttonHandlers } = useContext(ProjectButtonsContext)
  const { nextStep, projectId, projectStatus, setHasChanged } = useContext(StepperContext)

  const [groupsData, setGroupsData] = useState<GroupData[]>([])
  const [termsData, setTermsData] = useState<TermData[]>([])
  const [groupId, setGroupId] = useState<string | null>('')
  const [termId, setTermId] = useState<string | null>('')
  const [groupsLoading, setGroupsLoading] = useState(false)
  const [tab2Data, setTab2Data] = useState<Tab2Data | null>(null)
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [firstTabData, setFirstTabData] = useState<Tab2Data | null>(null)
  const [isNewStudentData, setIsNewStudentData] = useState<boolean>(false)

  const {
    setSchoolTerm,
    selectedGroup,
    setSelectedGroup,
    setSelectedGroupLabel,
    students,
    studentsHandler,
    teachers,
    teachersHandler,
    selectedOtherStudents,
    selectedOtherStudentsHandlers,
    selectedOtherTeachers,
    selectedOtherTeachersHandlers,
    getCheckedStudentsQuantity,
    mainTeachersList,
  } = useContext(ParticipantsContext)

  const form = useForm({
    initialValues: initalDatatab2,
    validate: {
      school_term: value => (value.length < 1 ? t('required_field') : null),
      group: (value, values) => {
        if (value.length < 1) {
          return t('required_field')
        }

        if (students && students.every(student => student.checked === false)) {
          return ''
        }
      },
    },
  })

  const handleErrors = (errors: unknown) => {
    const message =
      students && students.every(student => student.checked === false)
        ? t('new_project_page.students_not_selected')
        : t('fill_the_required_fields')

    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: message,
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const extractValidIds = (persons: any[]): string[] => {
    return persons
      .filter((person: any) => Object.keys(person).length !== 0)
      ?.map((person: any) => person.id)
      .filter((id: string): id is string => id !== null)
  }

  const handleRedirectByButton = (buttonNumber: number) => {
    switch (buttonNumber) {
      case 0:
        return (window.location.href = `/projects/${projectId}`)
      case 1:
        return navigate('/projects')
      default:
        return nextStep()
    }
  }

  const handleForm = (values: FormValues) => {
    const allStudents = extractValidIds([...values.group_students, ...selectedOtherStudents])
    const allTeachers = extractValidIds([...selectedOtherTeachers, ...values.group_teachers])

    const payload = {
      school_term_id: values.school_term,
      group_school_term_id: values.group,
      students_attributes: allStudents,
      project_teachers_attributes: allTeachers,
      main_tutor_id: values.main_teacher,
    }

    buttonHandlers.setState([
      { loading: values.submitButton === 1, disabled: true },
      { loading: values.submitButton === 2, disabled: true },
    ])

    if (projectId !== null) {
      updateProject(projectId, payload)
        .then(() => {
          notifications.show({
            message: projectStatus ? t('new_project_page.draft_saved') : t('new_project_page.project_saved'),
            color: 'green',
            icon: <IconCheck size={24} />,
          })

          handleRedirectByButton(values.submitButton)
        })
        .catch(res => {
          console.error(res)
        })
    }
  }

  const changeSchoolTerms = (value: string) => {
    if (form.values.school_term !== value) {
      form.setFieldValue('group', '')
      setGroupsData([])
      setGroupId(null)
    }

    form.setFieldValue('school_term', value)
    setTermId(value)
  }

  const initializeGroup = (schoolTermId: string) => {
    getMyGroups(schoolTermId).then(res => {
      if (res !== undefined) {
        setGroupsData(res)
        setGroupId(tab2Data?.groupSchoolTermId || '')
        form.setFieldValue('group', tab2Data?.groupSchoolTermId || '')
      }
    })
  }

  useEffect(() => {
    setSchoolTerm(form.values.school_term)
  }, [form.values.school_term])

  useEffect(() => {
    setSelectedGroup(form.values.group)
  }, [form.values.group])

  useEffect(() => {
    if (teachers.length > 0) {
      form.setFieldValue('group_teachers', teachers)
    }
  }, [teachers])

  useEffect(() => {
    if (students.length > 0) {
      const updatedStudents = students
        .filter(({ checked }) => checked)
        ?.map(({ id, name, avatar }) => ({ id, name, avatar }))
        .sort((a, b) => a.name.localeCompare(b.name, 'pt'))
      form.setFieldValue('group_students', updatedStudents)
    }
  }, [students])

  useEffect(() => {
    if (selectedOtherStudents.length > 0) {
      form.setFieldValue('other_students', selectedOtherStudents)
    }
  }, [selectedOtherStudents])

  const checkIfOtherTutorWasRemoved = (array: any, id: string): boolean => {
    if (typeof array === 'string' || id === null || id === undefined) {
      return false
    }
    return array.some((item: { value?: string; id?: string }) => item.value === id || item.id === id)
  }

  useEffect(() => {
    const isPresentOnOptions = checkIfOtherTutorWasRemoved(teachers, form.values.main_teacher)
    const wasRemoved = checkIfOtherTutorWasRemoved(selectedOtherTeachers, form.values.main_teacher)

    if (!wasRemoved && !isPresentOnOptions) {
      form.setFieldValue('main_teacher', '')
    }
  }, [selectedOtherTeachers, teachers])

  useEffect(() => {
    const addCurrentNameToLabel = (
      array: { value: string; label: string; current: boolean }[],
    ): { value: string; label: string; current: boolean }[] => {
      return array.map(obj => {
        if (obj.current) {
          return { ...obj, label: obj.label + t('new_project_page.current') }
        }
        return obj
      })
    }

    if (projectId) {
      getSchoolTerms(projectId).then(res => {
        if (res !== undefined) {
          if (res instanceof Array) {
            setTermsData(addCurrentNameToLabel(res))
            if (termId?.length === 0) {
              const filteredEntry = res.find(entry => entry.current === true)
              const currentId = filteredEntry ? filteredEntry?.value : null
              form.setFieldValue('school_term', currentId || res[0].value || '')
              setTermId(currentId || res[0].value)
              setInitialLoad(false)
            }
          } else {
            const obj = [res]
            setTermsData(addCurrentNameToLabel(obj))
          }
        }
      })
    }
  }, [projectId])

  useEffect(() => {
    if (!isEmpty(termId) && termId !== null) {
      getMyGroups(termId).then(res => {
        if (res !== undefined) {
          setGroupsData(res)
          if (form.values.submitButton === 0) return
          setIsNewStudentData(true)
        }
      })
    }
  }, [termId, form.values.submitButton])

  useEffect(() => {
    if (groupsData === null) return
    const result = groupsData.find((group: GroupData) => group.value === groupId)

    if (result) {
      setSelectedGroupLabel(result.label)
    }
  }, [groupId])

  useEffect(() => {
    if (projectId && projectId?.length > 0) {
      getProjectTab(projectId, '2').then(res => {
        if (res && res?.schoolTermId !== null && res?.schoolTermId !== undefined) {
          setTab2Data(res as Tab2Data)
          form.setFieldValue('school_term', res?.schoolTermId)
          form.setFieldValue('main_teacher', res?.mainTutorId || '')
          setTermId(res?.schoolTermId)
          initializeGroup(res.schoolTermId)
        }
      })
    }
  }, [projectId])

  useEffect(() => {
    if (!isEmpty(tab2Data) && projectId) {
      form.setFieldValue('school_term', tab2Data?.schoolTermId)
      setTermId(tab2Data?.schoolTermId)
      initializeGroup(tab2Data?.schoolTermId)
    }
  }, [tab2Data, projectId])

  const removeDuplicatedObjects = (arr: any) => {
    const uniqueValues = new Set()
    return arr.filter((obj: any) => !uniqueValues.has(obj.value) && uniqueValues.add(obj.value))
  }

  useEffect(() => {
    studentsHandler.setState([])

    if (!isEmpty(termId) && !isEmpty(groupId) && projectId && groupId !== null && termId !== null) {
      setGroupsLoading(true)

      getGroupPeople(groupId, termId)
        .then(response => {
          const isNewStudentData = response?.students.length !== students.length && students.length > 0

          if (response !== undefined) {
            let selectedStudents: string[] = []

            if (!isEmpty(tab2Data)) {
              const selectedOhterTutors = tab2Data.projectTeachers.filter((obj1: any) => {
                return !(response.tutors as Array<{ value: any }>).some(obj2 => obj1.id === obj2.value)
              })

              selectedStudents = tab2Data?.studentsAttributes
              selectedOtherStudentsHandlers.setState(
                convertStudentArray(removeDuplicatedObjects(tab2Data.anotherStudents), []),
              )
              selectedOtherTeachersHandlers.setState(convertTeacherArray(selectedOhterTutors))
            }
            studentsHandler.setState(convertStudentArray(response.students, selectedStudents))
            teachersHandler.setState(convertTeacherArray(response.tutors))
            setFirstTabData(tab2Data)
            setGroupsLoading(false)
            setInitialLoad(false)

            if (isNewStudentData) {
              cleanGroupData(response)
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [termId, groupId])

  const changeGroup = (value: string) => {
    const confirm = () => {
      form.setFieldValue('group', value)
      form.setFieldValue('other_teachers', [])
      setGroupId(value)
    }

    if (form.values.group !== value && !isEmpty(form.values.group)) {
      OpenGroupConfirmModal(confirm)
      return
    }

    confirm()
  }

  const cleanGroupData = (response: any) => {
    selectedOtherTeachersHandlers.setState([])
    selectedOtherStudentsHandlers.setState([])
    form.values.main_teacher = ''
    studentsHandler.setState(convertStudentArray(response.students, []))
  }

  const OpenGroupConfirmModal = (confirm: () => void) => {
    modals.openConfirmModal({
      title: t('new_project_page.warnning_groupt_title'),
      size: 'lg',
      children: (
        <>
          <Text size='sm'>
            {t('new_project_page.warnning_groupt_text')}
            <Text size='sm' fw={700} span>
              {t('new_project_page.warnning_groupt_lost')}
            </Text>
            {t('new_project_page.warnning_groupt_text2')}
          </Text>
        </>
      ),
      labels: {
        confirm: t('new_project_page.warnning_groupt_button2'),
        cancel: t('new_project_page.warnning_groupt_button1'),
      },
      confirmProps: { size: 'lg' },
      cancelProps: { size: 'lg' },
      onConfirm: () => confirm(),
    })
  }

  const checkMutations = () => {
    if (firstTabData === null) return form.values.group.length > 0
    return hasDataChanged(form.values as StudentGroup, firstTabData)
  }

  useEffect(() => {
    setHasChanged(checkMutations())
  }, [form.values])

  const groupDefault = [{ label: tab2Data?.groupName, value: tab2Data?.groupSchoolTermId }]

  return (
    <Paper radius='md' withBorder shadow='xs' p={isMobile ? 'md' : 'xl'} mx={isMobile ? rem(-20) : 0}>
      <form
        onSubmit={form.onSubmit(
          values => handleForm(values),
          validationErrors => {
            handleErrors(validationErrors)
          },
        )}
      >
        {initialLoad && (
          <Stack spacing='md'>
            <Skeleton width='100%' height={30} w={150}></Skeleton>
            <Skeleton width='100%' height={30} w={300}></Skeleton>
            <Group>
              <Skeleton width='100%' height={30} w={450}></Skeleton>
              <Skeleton width='100%' height={30} w={450}></Skeleton>
            </Group>
          </Stack>
        )}
        {!initialLoad && (
          <>
            <Title order={2}>{t('new_project_page.title_participants')}</Title>
            <Text span color='dimmed'>
              {t('new_project_page.description_participants')}
            </Text>
            <Divider my='md' variant='solid' />
            <TextRequired />
          </>
        )}
        <Stack spacing='xl'>
          <SimpleGrid
            breakpoints={[
              { minWidth: 'xl', cols: 2, spacing: 'md' },
              { minWidth: 'lg', cols: 2, spacing: 'md' },
              { minWidth: 'md', cols: 2, spacing: 'sm' },
              { minWidth: 'sm', cols: 1, spacing: 'sm' },
              { minWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
          >
            {!initialLoad && (
              <>
                <Select
                  size='md'
                  name='school_term'
                  description={t('new_project_page.period_description')}
                  label={t('new_project_page.school_term')}
                  {...form.getInputProps('school_term')}
                  data={termsData}
                  withAsterisk
                  required
                  disabled={tab2Data?.status === 'approved' || tab2Data?.tutorGuest}
                  onChange={changeSchoolTerms}
                />

                <Select
                  size='md'
                  name='group'
                  description={t('new_project_page.group_description')}
                  label={t('new_project_page.group')}
                  data={groupsData || groupDefault}
                  disabled={tab2Data?.status === 'approved' || tab2Data?.tutorGuest}
                  withAsterisk
                  required
                  {...form.getInputProps('group')}
                  onChange={changeGroup}
                />
              </>
            )}
          </SimpleGrid>
          {!initialLoad && !groupsLoading && !selectedGroup && (
            <EmptyStateBox
              withBorder
              text={t('new_project_page.message_select_a_school_term')}
              icon={<IconUsersPlus size={32} />}
              p='lg'
              textSize='md'
            />
          )}
          {!initialLoad && groupsLoading && (
            <Stack spacing='md'>
              <Skeleton width='100%' height={200}></Skeleton>
              <Skeleton width='100%' height={200}></Skeleton>
            </Stack>
          )}
          {!initialLoad && !groupsLoading && selectedGroup && (
            <Stack spacing='md'>
              <Stack spacing={0}>
                <Title order={3}>
                  {t('new_project_page.students')}{' '}
                  <Text span color='dimmed' fw={500}>
                    ({getCheckedStudentsQuantity() + selectedOtherStudents.length})
                  </Text>
                </Title>
                <Text color='gray.6' size='sm'>
                  {t('new_project_page.select_the_students')}
                </Text>
              </Stack>
              {groupId && <GroupStudentsBlock />}
              {termId && groupId && <OtherStudentsBlock termId={termId} groupId={groupId} />}
            </Stack>
          )}
          {!initialLoad && !groupsLoading && selectedGroup && (
            <Stack spacing='md'>
              <Stack spacing={0}>
                <Title order={3}>
                  {t('new_project_page.teachers')}{' '}
                  <Text span color='dimmed' fw={500}>
                    ({teachers.length + selectedOtherTeachers.length})
                  </Text>
                </Title>
                <Text color='gray.6' size='sm'>
                  {t('new_project_page.select_the_teachers')}
                </Text>
              </Stack>
              <GroupTeachersBlock />
              {termId && groupId && <OtherTeachersBlock termId={termId} groupId={groupId} />}
              <Select
                name='main_teacher'
                description={t('new_project_page.main_teacher_description')}
                size='md'
                data={mainTeachersList}
                label={t('new_project_page.main_teacher')}
                {...form.getInputProps('main_teacher')}
              />
            </Stack>
          )}
        </Stack>
        <ProjectStepsButtons formAction={form.setFieldValue} hasChanges={checkMutations} />
      </form>
    </Paper>
  )
}
