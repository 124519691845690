import { Text, Avatar, Card, Group, Title, Stack, Badge, Divider, Anchor } from 'components'
import { getInitials } from 'helpers'
import { GroupCardProps } from './interfaces'

export const GroupCard = ({ group, membersLabel, actions, showLink, byCycle }: GroupCardProps) => {
  const MAX_DISPLAY_MEMBERS = 5

  const totalGroupMembers = group.students ? group.students.length : 0
  const additionalGroupMembers = Math.max(totalGroupMembers - MAX_DISPLAY_MEMBERS, 0)

  const gradeComponent =
    (!byCycle && group.grade) || (!byCycle && group.gradeName !== null) ? (
      <Text size='sm' color='dimmed'>
        {group.grade ? group.grade : group.gradeName}
      </Text>
    ) : null

  const cycleComponent =
    byCycle && group.cycle ? (
      <Badge size='md' color='gray' variant='filled'>
        {group.cycle}
      </Badge>
    ) : null

  const gradeGroupComponent =
    group.grade || group.cycle ? (
      <Group spacing='xs'>
        {gradeComponent}
        {cycleComponent}
      </Group>
    ) : null

  const studentListComponent =
    group.students && group.students.length > 0 ? (
      <Avatar.Group spacing='sm'>
        {group.students.slice(0, 5).map((member, index) => (
          <Avatar size='md' src={member.img} alt={member.fullName} key={index}>
            {getInitials(member.fullName || member.name)}
          </Avatar>
        ))}
        {totalGroupMembers > 5 ? <Avatar radius='xl'>+{additionalGroupMembers}</Avatar> : null}
      </Avatar.Group>
    ) : null

  const titleComponent = showLink || showLink === undefined ? (
    <Anchor href={`/group_school_terms/${group.id}?origin=dashboard`}>
      <Title order={4}>{group.name}</Title>
    </Anchor>
  ) : (
    <Title order={4}>{group.name}</Title>
  )

  return (
    <Card shadow='md' withBorder p='sm'>
      <Stack spacing='md'>
        <Group spacing='sm'>
          <Avatar size='lg' src={group.avatar} alt={group.name}>
            {getInitials(group.name)}
          </Avatar>
          <Stack spacing={4}>
            {titleComponent}
            {gradeGroupComponent}
          </Stack>
        </Group>
        {group.students && group.students.length > 0 ? (
          <>
            <Divider />
            <Stack spacing={4}>
              <Text size='sm' color='dimmed'>
                {membersLabel} ({totalGroupMembers})
              </Text>
              {studentListComponent}
            </Stack>
          </>
        ) : null}
        {actions}
      </Stack>
    </Card>
  )
}
