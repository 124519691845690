import { useState } from 'react'
import PropTypes from 'prop-types'
import Planning from '@legacy/common/Activities/components/Planning'
import SpecificSkillAndContent from '@legacy/common/Activities/components/SpecificSkillAndContent'
import {
  Button,
  Arrow,
  TitleContainer,
  Label,
  Card,
  CardHeader,
  CardBody,
  Objective,
} from '@legacy/common/Activities/styled/Activity'
import {TagStyled, ActivityTags} from '@legacy/common/_components/Activities/styled/Activity'
import { useTranslation } from 'react-i18next'
import ShowStudentTask from '@legacy/common/_components/StudentTask/show-student-task'

const ActivityAlone = ({ data }) => {
  const { t } = useTranslation()
  const { hasAssessments, studentTask } = data
  const [expand, setExpand] = useState(false)

  return (
    <Card>
      <CardHeader>
        <TitleContainer>
          <Label className="ml-3 activityTitle">{data?.title}</Label>
          <ActivityTags>
            {hasAssessments && <TagStyled text={t('assessment')} type="medium" colors={{ background: '#6A0BD6', text: 'white' }} />}
            {studentTask && <TagStyled text={t('deliverable')} type="medium" />}
          </ActivityTags>
        </TitleContainer>
        <Button className="ml-5 arrow" type="button" onClick={() => setExpand(!expand)}>
          <Arrow open={expand} />
        </Button>
      </CardHeader>
      <CardBody open={expand}>
        <Objective>{data?.objective}</Objective>
        <SpecificSkillAndContent data={data?.specific_skills_and_contents} withBreakLine />
        <Planning text={data?.planning} files={data?.planningFiles} />
        {studentTask && (
        <ShowStudentTask
          title={studentTask.title ?? ''}
          description={studentTask.description ?? ''}
          date={studentTask.date ?? ''}
          id={studentTask.id ?? ''}
          planningFiles={studentTask.planning_files ?? []}
        />
        )}
      </CardBody>
    </Card>
  )
}

ActivityAlone.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ActivityAlone
