import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { LessonsContext } from 'components'
import { Card, Grid, Text, Badge, Stack, Anchor, Box, Group } from 'components'
import { CardActivityReadOnlyProps } from './interfaces'


export const CardActivityReadOnly = ({ activity }: CardActivityReadOnlyProps) => {
  const { t } = useTranslation()
  const { handleViewActivityDetails } = useContext(LessonsContext)

  return (
    <Card key={activity.id} shadow='md' withBorder mb='md'>
      <Grid align='center'>
        <Grid.Col span={10} md='auto'>
          <Stack spacing={4}>
            <Text>{activity.title}</Text>
            <Group spacing='xs'>
              {activity.hasAssessment && (
                <Badge color='purple' size='md' variant='filled'>
                  {t('badges.badge_assessment')}
                </Badge>
              )}
              {activity.hasTasks && (
                <Badge color='blue' size='md' variant='filled'>
                  {t('badges.badge_deliverable')}
                </Badge>
              )}
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span='auto' md={2}>
          <Box sx={{ textAlign: 'right' }}>
            {activity.activityActions?.show && (<Anchor onClick={() => handleViewActivityDetails(activity.id)}>
              {t('new_project_page.activity_details')}
            </Anchor>)}
          </Box>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
