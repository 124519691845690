import styled from 'styled-components'

export const StudentCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  align-items: center;
  height: 76px;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 32px auto;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 8px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    line-height: 1;
  }
`

export const StatusContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 19px;
  align-items: center;
  justify-items: flex-end;
`

export const LabelStatus = styled.span`
  font-size: 0.75rem;
  line-height: 1.2;
  color: #121019;
  padding-bottom: 8px;
`
