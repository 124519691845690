import { createStyles, rem } from 'hooks'

// Pass checked and disabled props to useStyles
export const useStyles = createStyles(
  (
    theme,
    {
      checked,
      disabled,
    }: {
      checked: boolean | undefined
      disabled: boolean | undefined
    },
  ) => ({
    button: {
      width: '100%',
      border: `${rem(1)} solid ${
        checked
          ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor }).border
          : theme.colorScheme === 'dark'
            ? theme.colors.dark[8]
            : theme.colors.gray[3]
      }`,
      borderRadius: theme.radius.md,
      backgroundColor: disabled
        ? theme.colorScheme === 'dark'
          ? theme.colors.gray[6]
          : theme.colors.gray[1]
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : 'white',
      '&:hover': {
        backgroundColor: disabled
          ? theme.colorScheme === 'dark'
            ? theme.colors.gray[6]
            : theme.colors.gray[1]
          : theme.colorScheme === 'dark'
            ? theme.colors.turquoise[8]
            : theme.colors.turquoise[0],
      },
      boxShadow: theme.shadows.sm,
    },
  }),
)
