import { Card, BreadCrumbCard, Divider, EmptyStateBox } from 'components'
import { AssessmentCardProps } from './interfaces'
import { AssessmentCardItem } from './components'
import { useMantineTheme } from 'hooks'
import { IconInfoCircle } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

export const AssessmentCard = ({ breadcrumb, color, assessments, disabled }: AssessmentCardProps) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()

  return (
    <Card
      withBorder
      shadow='md'
      aria-disabled={disabled}
      sx={{ backgroundColor: disabled ? theme.colors.gray[2] : '' }}
    >
      <Card.Section p='md'>
        <BreadCrumbCard breadcrumb={breadcrumb} fullBreadCrumb={true} color={color} />
      </Card.Section>
      <Divider />
      <Card.Section p='md'>
        {disabled && (
          <EmptyStateBox
            icon={<IconInfoCircle />}
            text={t('new_final_project_report.assessment_disabled_message')}
            p={0}
            spacing='xs'
            textSize='sm'
          />
        )}
        {!disabled &&
          assessments &&
          assessments.length > 0 &&
          assessments.map((assessment, index) => (
            <AssessmentCardItem key={index} assessments={assessments} assessment={assessment} index={index} />
          ))}
      </Card.Section>
    </Card>
  )
}
