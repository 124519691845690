import { JSXElementConstructor, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from 'react'
import { UnstyledButton, Checkbox, Text, Avatar, Grid, Group, Stack } from 'components'
import { useUncontrolled } from 'hooks'
import { useStyles } from './CardAvatarCheckboxStyles'
import { getInitials } from '../../helpers/getInitials'

interface CardAvatarCheckBoxProps {
  checked?: boolean
  check?: boolean
  defaultChecked?: boolean
  onChange?(checked: boolean): void
  name: string
  description?: string
  avatar: string
  id: string
  tag?: ReactElement<unknown, string | JSXElementConstructor<unknown>> | ReactFragment | ReactPortal
  disabled?: boolean
  selecteds?: any
}

export function CardAvatarCheckboxModal({
  checked,
  check,
  defaultChecked,
  onChange,
  name,
  description,
  className,
  avatar,
  id,
  tag,
  disabled,
  role,
  selecteds,
  ...others
}: CardAvatarCheckBoxProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof CardAvatarCheckBoxProps>) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  })
  const { classes, cx } = useStyles({ checked: value, disabled: disabled })
  const [initial, setInitial] = useState<boolean>(false)

  const content = tag ? (
    <Group position='apart' spacing={0}>
      <Stack spacing={0}>
        <Text>
          {name}
          {role && role?.length > 0 && (
            <Text span color='dimmed'>
              {' '}
              - {role}
            </Text>
          )}
        </Text>

        {description ? <Text color='dimmed'>{description}</Text> : null}
      </Stack>
      {tag}
    </Group>
  ) : (
    <Stack spacing={0}>
      <Text>
        {name}
        {role && role?.length > 0 && (
          <Text span color='dimmed'>
            {' '}
            - {role}
          </Text>
        )}
      </Text>
      {description ? <Text color='dimmed'>{description}</Text> : null}
    </Stack>
  )

  const returnChecked = () => {
    if (selecteds && selecteds.length > 0) {
      return !!selecteds.find((item: any) => item.id === id)
    }

    return disabled && true
  }

  useEffect(() => {
    const myvalue = disabled ? true : returnChecked()
    if (myvalue) {
      setInitial(myvalue)
    }
  }, [])

  return (
    <UnstyledButton
      disabled={disabled}
      {...others}
      onClick={() => {
        handleChange(!initial)
        setInitial(!initial)
      }}
      className={cx(classes.button, className)}
      p='sm'
    >
      <Grid align='center' gutter={0}>
        <Grid.Col span='content'>
          <Checkbox
            checked={initial}
            tabIndex={-1}
            size='sm'
            mr='sm'
            styles={{ input: { cursor: 'pointer' } }}
            value={id}
            disabled={disabled}
          />
        </Grid.Col>
        <Grid.Col span='content'>
          <Avatar src={avatar} alt={name} size='md'>
            {getInitials(name)}
          </Avatar>
        </Grid.Col>
        <Grid.Col span='auto' pl='xs'>
          {content}
        </Grid.Col>
      </Grid>
    </UnstyledButton>
  )
}
