import { postUrl, deleteUrl } from '../Tools/Service'

const addToMural = (projectId, payload, callback) => {
  postUrl(`/projects/${projectId}/mural_items`, payload,
    _response => {
      callback()
    }, _errors => {
      callback()
    })
}
const removeFromMural = (muralItemId, callback) => {
  deleteUrl(`/mural_items/${muralItemId}`, null,
    _response => {
      callback()
    }, _errors => {
      callback()
    })
}

export default ({ projectId }) => ({
  onAction: (parentResource, callback) => {
    const payload = {
      parent_resource_type: parentResource.resourceType,
      parent_resource_id: parentResource.id,
    }

    if (parentResource.muralItemId) {
      removeFromMural(parentResource.muralItemId, callback)
    } else {
      addToMural(projectId, payload, callback)
    }
  },
  action: 'addToMural',
  iconClass: 'icon-add-to-mural',
  iconClassRemoval: 'icon-remove-from-mural',
})
