import { IGrade, ICycle, IDescriptor } from 'interfaces'

export interface ILvl4Item {
  id: string
  name: string
  color: string
  type?: string
  breadcrumb?: string
  originalCodes?: string
  grades?: IGrade[]
  cycles?: ICycle[] | string[]
  statutory?: boolean
  descriptors?: IDescriptor[]
  lv1Name?: string
  lv1ID?: string
  lv2ID?: string
  lv2Name?: string
  hasAssess?: boolean
}
