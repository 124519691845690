import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Loading from '@legacy/common/_components/Loading'
import i18n from '@i18n'
import FileViewer from './FileViewer'
import FileModal from './FileModal'
import { ReactPlayContainer } from './FileViewerStyled'

class FilesList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFileIndex: undefined,
      modalVisible: true
    }
  }

  showFileByIndex(selectedFileIndex) {
    this.setState({ selectedFileIndex })
  }

  render() {
    const translateKey = 'components_molecules_filesList'
    const { filesList, loading, onDelete, hasActions, maxItems, customAction } =
      this.props
    const { selectedFileIndex } = this.state

    const filesToList =
      !maxItems || maxItems === filesList.length
        ? filesList
        : filesList.slice(0, maxItems - 1)
    const moreFilesIndex = maxItems - 1

    return (
      <div className='files-list d-flex'>
        {filesToList.map((file, index) => (
          <div className='file-container' key={index}>
            <ReactPlayContainer>
              <FileViewer
                fileData={file}
                thumbSize='small'
                color='medium'
                onClick={() => this.setState({modalVisible: true, selectedFileIndex: index})}
              />
            </ReactPlayContainer>

            {hasActions && (
              <div className='actions'>
                {customAction && (
                  <a
                    className='float-left'
                    onKeyPress={() => {
                      customAction.onAction(file, customAction.afterAction)
                    }}
                    onClick={() =>
                      customAction.onAction(file, customAction.afterAction)
                    }
                    role='button'
                    tabIndex='0'
                  >
                    <ReactTooltip
                      id={String(file.id)}
                      place='bottom'
                      type='dark'
                      effect='solid'
                    >
                      {i18n.t(
                        `${translateKey}_${
                          file.muralItemId ? 'removeFromMural' : 'addToMural'
                        }`
                      )}
                    </ReactTooltip>
                    <i
                      data-tip
                      data-for={file.id}
                      className={`m-0 ${
                        file.muralItemId
                          ? customAction.iconClassRemoval
                          : customAction.iconClass
                      }`}
                    />
                  </a>
                )}

                {file.downloadUrl && (
                  <a href={file.downloadUrl}>
                    <i className='icon-download' />
                  </a>
                )}
                {onDelete && (
                  <a
                    onKeyPress={() => onDelete(file)}
                    onClick={() => onDelete(file)}
                    role='button'
                    tabIndex='0'
                  >
                    <i className='icon-delete' />
                  </a>
                )}
              </div>
            )}
          </div>
        ))}

        {maxItems < filesList.length && (
          <div
            role='button'
            tabIndex={0}
            className='file-container more-files cursor-pointer'
            onClick={() => this.showFileByIndex(moreFilesIndex)}
            onKeyPress={() => this.showFileByIndex(moreFilesIndex)}
          >
            <FileViewer
              fileData={filesList[moreFilesIndex]}
              thumbSize='small'
              color='medium'
            />
            <div className='count-text'>
              +{filesList.length - moreFilesIndex}
            </div>
          </div>
        )}

        {loading && (
          <div>
            <Loading />
          </div>
        )}

        {selectedFileIndex !== undefined && (
          <FileModal
            filesList={filesList}
            selectedFileIndex={selectedFileIndex}
            showFileByIndex={this.showFileByIndex.bind(this)}
            modalVisible={this.state.modalVisible}
            setModalVisible={(value) => this.setState({modalVisible: value})}
          />
        )}
      </div>
    )
  }
}

FilesList.propTypes = {
  filesList: PropTypes.arrayOf(
    PropTypes.shape({
      downloadUrl: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  hasActions: PropTypes.bool,
  maxItems: PropTypes.number,
  customAction: PropTypes.shape({
    iconClass: PropTypes.string.isRequired,
    iconClassRemoval: PropTypes.string.isRequired,
    onAction: PropTypes.func.isRequired,
    afterAction: PropTypes.func.isRequired,
  }),
}

FilesList.defaultProps = {
  filesList: [],
  loading: false,
  onDelete: undefined,
  hasActions: true,
  maxItems: undefined,
  customAction: undefined,
}

export default FilesList
