import { api } from 'helpers'

const finalProjectReportPaths = {
  getStudents: (projectId: string) => `/api/projects/${projectId}/students`,
  getStudentFinalReport: (projectId: string, studentId: string) =>
    `/api/project/${projectId}/${studentId}/project_student_final_report`,
  getProjectFinalReport: (projectId: string) => `/api/project/${projectId}/project_final_report`,
}

export async function getFinalReportStudentsList(id: string) {
  const response = await api.get(finalProjectReportPaths.getStudents(id))
  return response
}

export async function getStudentFinalReport(projectId: string, studentId: string) {
  const response = await api.get(finalProjectReportPaths.getStudentFinalReport(projectId, studentId))
  return response
}

export async function getProjectFinalReport(projectId: string) {
  const response = await api.get(finalProjectReportPaths.getProjectFinalReport(projectId))
  return response
}
