import { Skeleton, Stack } from 'components'

export const SkeletonList = () => {
  return (
    <Stack spacing='md'>
      <Skeleton height={300} />
      <Skeleton height={300} />
    </Stack>
  )
}
