import { useContext, useEffect, useCallback } from 'react'
import { CoverFileContext } from '../../context/CoverFileContext'
import { FileButton, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { ProjectCoverThumbnail } from '../ProjectCoverThumbnail'
import { IconUpload } from '@tabler/icons-react'
import { loadCoverImage } from 'pages/NewProject/service/projects'
import { Flex, Skeleton } from 'components'

const fullUrl = window.location.href
const urlParts = fullUrl.split('/')
const basePath = urlParts[0] + '//' + urlParts[2]

interface ProjectCoverProps {
  downloadURL?: string | null
  id?: string
  onChange: (data: any) => void
}

export const ProjectCover = ({ downloadURL, onChange, id }: ProjectCoverProps) => {
  const { t } = useTranslation()
  const { setFileCover, fileCoverSrc, setDownloadURL, setFileCoverSrc, setLoadingCover, loadingCover } =
    useContext(CoverFileContext)

  useEffect(() => {
    if (downloadURL) {
      setDownloadURL(downloadURL)
    }
  }, [])

  useEffect(() => {
    onChange && onChange(fileCoverSrc)
  }, [fileCoverSrc])

  const fetchImage = useCallback(async (id: string) => {
    setLoadingCover(true)
    try {
      const response = await loadCoverImage(id)
      return response
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCover(false)
    }
  }, [])

  const handleImageResponse = useCallback((response: any) => {
    if (response.coverImage && response.coverImage.id && response.coverImage.id !== null) {
      const coverPath = basePath + response.coverImage.fileCover
      setFileCoverSrc(coverPath)
      setDownloadURL(coverPath)
    }
  }, [])

  useEffect(() => {
    if (id) {
      fetchImage(id).then(handleImageResponse)
    }
  }, [id, fetchImage, handleImageResponse])

  if (loadingCover) {
    return (
      <>
        <Flex pt='md' direction='column'>
          <Skeleton h={100} w='100%' />
        </Flex>
      </>
    )
  }

  return (
    <>
      {!fileCoverSrc && (
        <FileButton onChange={setFileCover} accept='image/png,image/jpeg'>
          {props => (
            <Button variant='light' leftIcon={<IconUpload />} {...props}>
              {t('new_project_page.upload_image')}
            </Button>
          )}
        </FileButton>
      )}
      {fileCoverSrc && fileCoverSrc?.length > 0 && !loadingCover && (
        <ProjectCoverThumbnail height={240} width={520} alt={t('new_project_page.project_cover')} projectId={id} />
      )}
    </>
  )
}
