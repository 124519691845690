import { IColoredTag } from './colored-tag-types'
import { Tag, Label } from './colored-tag-styles'

const ColoredTag = ({color, textColor, text}: IColoredTag) => {
  return (
    <Tag color={color}>
      <Label textColor={textColor}>{text}</Label>
    </Tag>
  )
}

export default ColoredTag