import { Layout } from 'components/Layout'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { ProjectList } from './components/ProjectList'
import { ProjectListProvider } from './context/ProjectListContext'

export const NewProjectList = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <ProjectListProvider>
          <ProjectList />
        </ProjectListProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
