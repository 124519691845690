import { createContext, useEffect, useState } from 'react'
import { useDisclosure } from 'hooks'
import _ from 'lodash'
import { ILessonProps, IGroupProps } from 'interfaces'
import { useMixPanel } from 'hooks'


interface StudentDashboardContextProps {
  groups: IGroupProps[]
  setGroups: React.Dispatch<React.SetStateAction<IGroupProps[]>>
  lessons: ILessonProps[]
  setLessons: React.Dispatch<React.SetStateAction<ILessonProps[]>>
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  assessmentModalOpened: boolean
  assessmentModalOpen: () => void
  assessmentModalClose: () => void
  modalTitle: string
  setModalTitle: React.Dispatch<React.SetStateAction<string>>
  showConfirmationModal: boolean
  setShowConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  hasChanged: boolean
  setHasChanged: React.Dispatch<React.SetStateAction<boolean>>
  editReportURL: string
  setEditReportURL: React.Dispatch<React.SetStateAction<string>>
  updateModalTitle: (title: string) => void
  setModalHasChanges: (hasChanges: boolean) => void
  assessmentModalOnClose: () => void
  UpdateShowModal: (show: boolean) => void
  assessmentID: string
  setAssessmentID: React.Dispatch<React.SetStateAction<string>>
  handleJournalClick: (editReportURL: string, projectURL: string) => void
  projectURL: string
  setProjectURL: React.Dispatch<React.SetStateAction<string>>
}

export const StudentDashboardContext = createContext<StudentDashboardContextProps>({
  error: null,
  setError: () => { },
  groups: [],
  setGroups: () => { },
  lessons: [],
  setLessons: () => { },
  loading: true,
  setLoading: () => { },
  assessmentModalOpened: false,
  assessmentModalOpen: () => { },
  assessmentModalClose: () => { },
  modalTitle: '',
  setModalTitle: () => { },
  showConfirmationModal: false,
  setShowConfirmationModal: () => { },
  hasChanged: false,
  setHasChanged: () => { },
  editReportURL: '',
  setEditReportURL: () => { },
  updateModalTitle: () => { },
  setModalHasChanges: () => { },
  assessmentModalOnClose: () => { },
  UpdateShowModal: () => { },
  assessmentID: '',
  setAssessmentID: () => { },
  handleJournalClick: () => { },
  projectURL: '',
  setProjectURL: () => { },
})

export const StudentDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<string | null>(null)
  const [groups, setGroups] = useState<IGroupProps[]>([])
  const [loading, setLoading] = useState(true)
  const [lessons, setLessons] = useState<ILessonProps[]>([])
  const [assessmentModalOpened, { open: assessmentModalOpen, close: assessmentModalClose }] = useDisclosure(false)
  const [modalTitle, setModalTitle] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [hasChanged, setHasChanged] = useState(false)
  const [editReportURL, setEditReportURL] = useState('')
  const [assessmentID, setAssessmentID] = useState('')
  const [projectURL, setProjectURL] = useState('')

  const { track } = useMixPanel()

  const handleJournalClick = (editReportURL: string, projectURL: string): void => {
    setEditReportURL(editReportURL)
    setProjectURL(projectURL)
    const assessmentId = _.get(editReportURL.match(/\/session_meetings\/(.*)\/edit_report/), '[1]') || ''
    setAssessmentID(assessmentId)
    assessmentModalOpen()
    track('Preencher diário de bordo via dashboard')
  }

  const updateModalTitle = (title: string): void => {
    setModalTitle(title)
  }

  const setModalHasChanges = (hasChanges: boolean): void => {
    setHasChanged(hasChanges)
  }

  const assessmentModalOnClose = (): void => {
    if (hasChanged) {
      setShowConfirmationModal(true)
      return
    }
    setShowConfirmationModal(false)
    close()
  }

  const UpdateShowModal = (show: boolean): void => {
    setShowConfirmationModal(show)
  }

  useEffect(() => {
    if (assessmentModalOpened === false) {
      setAssessmentID('')
    }
  }, [assessmentModalOpened])

  return (
    <StudentDashboardContext.Provider
      value={{
        groups,
        setGroups,
        lessons,
        setLessons,
        loading,
        setLoading,
        error,
        setError,
        assessmentModalOpened,
        assessmentModalOpen,
        assessmentModalClose,
        modalTitle,
        setModalTitle,
        showConfirmationModal,
        setShowConfirmationModal,
        hasChanged,
        setHasChanged,
        editReportURL,
        setEditReportURL,
        updateModalTitle,
        setModalHasChanges,
        assessmentModalOnClose,
        UpdateShowModal,
        assessmentID,
        setAssessmentID,
        handleJournalClick,
        projectURL,
        setProjectURL,
      }}
    >
      {children}
    </StudentDashboardContext.Provider>
  )
}
