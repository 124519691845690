const BYTE_BASE = 1000
export const MAX_SIZE = 99 * BYTE_BASE * BYTE_BASE

export const formatFileName = (fileName) => {
  if (fileName.length <= 28) return fileName
  return `${fileName.slice(0, 20)}...${fileName.slice(fileName.length - 5)}`
}

export const formatFileSize = (fileSize) => {
  const typeSize = ['bytes', 'KB', 'MB', 'GB', 'TB']
  let index = 0
  let size = fileSize
  while (size > BYTE_BASE) {
    size /= BYTE_BASE
    index += 1
  }
  return `${parseFloat(size.toFixed(2))} ${typeSize[index]}`
}
