import { api } from 'helpers'

const projectPaths = {
  getProject: (projectId: string) => `/api/projects/${projectId}`,
  getLessons: (projectId: string, page: number) => `/api/session_meetings/by_project/${projectId}?page=${page}`,
  getTeachers: (projectId: string) => `/api/projects/${projectId}/list_all_teachers`,
  getStartFinalAssessment: (projectId: string) => `/api/projects/${projectId}/start_final_assessment`,
  getPublish: (projectId: string) => `/api/projects/${projectId}/publish`,
  getApprove: (projectId: string) => `/api/projects/${projectId}/approve`,
  getReopen: (projectId: string) => `/api/projects/${projectId}/reopen`,
  getArchive: (projectId: string) => `/api/projects/${projectId}/archive`,
  getFinish: (projectId: string) => `/api/projects/${projectId}/finish`,
}

export async function getProject(id: string) {
  const response = await api.get(projectPaths.getProject(id))

  if (response?.status === 401) {
    window.location.href = '/error_permission'
  }

  return response
}

export async function getAllTeachers(id: string) {
  const response = await api.get(projectPaths.getTeachers(id))
  return response
}

export async function getLessons(id: string, page: number) {
  const response = await api.get(projectPaths.getLessons(id, page))
  return response
}

export async function postFinalAssessment(id: string) {
  const response = await api.post(projectPaths.getStartFinalAssessment(id))
  return response
}

export async function postPublishProject(id: string) {
  const response = await api.post(projectPaths.getPublish(id))
  return response
}

export async function postApproveProject(id: string) {
  const response = await api.post(projectPaths.getApprove(id))
  return response
}

export async function postReopenProject(id: string) {
  const response = await api.post(projectPaths.getReopen(id))
  return response
}

export async function postFinishProject(id: string) {
  const response = await api.post(projectPaths.getFinish(id))
  return response
}

export async function deleteProject(id: string) {
  const response = await api.delete(projectPaths.getProject(id))
  return response
}
