import { createContext, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { IProjectNew, ITeacher } from 'interfaces'
import { IStudent, ITeacherReport, IStudentAssessments } from '../interfaces'
import { ErrorContext } from 'context'
import { teacherReportData, studentAssessmentsData } from '../mockData'
import { getProject, getAllTeachers, getFinalReportStudentsList } from 'api'
import { useTranslation } from 'react-i18next'


interface FinalProjectReportContextProps {
  readonly project: IProjectNew | null
  setProject: React.Dispatch<React.SetStateAction<IProjectNew | null>>
  readonly students: IStudent[] | null
  setStudents: React.Dispatch<React.SetStateAction<IStudent[] | null>>
  readonly teachers: ITeacher[] | null
  setTeachers: React.Dispatch<React.SetStateAction<ITeacher[] | null>>
  readonly selectedStudent: string | null
  setSelectedStudent: React.Dispatch<React.SetStateAction<string | null>>
  readonly teacherReport: ITeacherReport | null
  setTeacherReport: React.Dispatch<React.SetStateAction<ITeacherReport | null>>
  readonly studentAssessments: IStudentAssessments | null
  setStudentAssessments: React.Dispatch<React.SetStateAction<IStudentAssessments | null>>
  readonly assessmentsLoaded: boolean
  setassessmentsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  readonly teacherReportLoaded: boolean
  setTeacherReportLoaded: React.Dispatch<React.SetStateAction<boolean>>
  fetchTeacherReport: (projectID: string) => void
  fetchStudentAssessments: (projectID: string) => void
  fetchStudents: (projectID: string) => void
  fetchTeachers: (projectID: string) => void
  fetchProject: (projectID: string) => void
  projectID?: string
}

export const FinalProjectReportContext = createContext<FinalProjectReportContextProps>({
  project: null,
  setProject: () => { },
  students: [],
  setStudents: () => { },
  teachers: [],
  setTeachers: () => { },
  selectedStudent: null,
  setSelectedStudent: () => { },
  teacherReport: null,
  setTeacherReport: () => { },
  studentAssessments: null,
  setStudentAssessments: () => { },
  assessmentsLoaded: false,
  setassessmentsLoaded: () => { },
  teacherReportLoaded: false,
  setTeacherReportLoaded: () => { },
  fetchTeacherReport: () => { },
  fetchStudentAssessments: () => { },
  fetchStudents: () => { },
  fetchTeachers: () => { },
  fetchProject: () => { },
  projectID: undefined,
})

export const FinalProjectReportProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()

  const { setError } = useContext(ErrorContext)

  const { id } = useParams()

  const projectID = id

  const [project, setProject] = useState<IProjectNew | null>(null)
  const [students, setStudents] = useState<IStudent[] | null>(null)
  const [teachers, setTeachers] = useState<ITeacher[] | null>(null)
  const [selectedStudent, setSelectedStudent] = useState<string | null>(null)
  const [teacherReport, setTeacherReport] = useState<ITeacherReport | null>(null)
  const [studentAssessments, setStudentAssessments] = useState<IStudentAssessments | null>(null)
  const [teacherReportLoaded, setTeacherReportLoaded] = useState<boolean>(false)
  const [assessmentsLoaded, setassessmentsLoaded] = useState<boolean>(false)

  const updateDocumentTitle = (projectName: string) => {
    const pageName = t('new_final_project_report.page_title') + ' - ' + projectName
    document.title = t('page_title', { page_name: pageName })
  }


  const fetchTeacherReport = async (projectID: string) => {
    // Todo usar api para retornar dados. Project ID será necessário.
    setTeacherReport(teacherReportData)
    setTeacherReportLoaded(true)
  }

  const fetchStudentAssessments = async (projectID: string) => {
    // Todo usar api para retornar dados. Project ID será necessário.
    setStudentAssessments(studentAssessmentsData)
    setassessmentsLoaded(true)
  }

  const fetchStudents = async (projectID: string) => {
    try {
      const response = await getFinalReportStudentsList(projectID)
      if (response.status === 200) {
        setStudents(response.data)
      } else {
        setStudents(null)
        setError(response.statusText)
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  const fetchTeachers = async (projectID: string) => {
    try {
      const response = await getAllTeachers(projectID)
      if (response.status === 200) {
        setTeachers(response.data)
      } else {
        setError(response.statusText)
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  const fetchProject = async (projectID: string) => {
    try {
      const response = await getProject(projectID)
      if (response.status === 200) {
        setProject(response.data)
        updateDocumentTitle(response.data.name)
      } else {
        setError(response.statusText)
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  return (
    <FinalProjectReportContext.Provider
      value={{
        project,
        setProject,
        teachers,
        setTeachers,
        students,
        setStudents,
        selectedStudent,
        setSelectedStudent,
        teacherReport,
        setTeacherReport,
        studentAssessments,
        setStudentAssessments,
        assessmentsLoaded,
        setassessmentsLoaded,
        teacherReportLoaded,
        setTeacherReportLoaded,
        fetchTeacherReport,
        fetchStudentAssessments,
        fetchStudents,
        fetchTeachers,
        fetchProject,
        projectID,
      }}
    >
      {children}
    </FinalProjectReportContext.Provider>
  )
}
