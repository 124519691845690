import { toast, ToastContent, ToastOptions } from 'react-toastify'
import i18n from '@i18n'
import { toastOptions } from './toast-options'

export function lumiarToastError(message?: ToastContent, overOptions?: ToastOptions) {
  toast.dismiss()
  return toast.error(
    message ?? (i18n.t('general_messages_save_error') as string),
    overOptions ? { ...toastOptions, ...overOptions } : toastOptions,
  )
}

export function lumiarToastSuccess(message: ToastContent, overOptions?: ToastOptions) {
  toast.dismiss()
  return toast.success(message, overOptions ? { ...toastOptions, ...overOptions } : toastOptions)
}
