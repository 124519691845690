import styled from 'styled-components'

export const Wrap = styled.div`
  margin-top: 1rem;
  display: block;
`

export const WrapLoading = styled.div`
  height: 500px;
  padding-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
