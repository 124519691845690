import { useContext, useCallback, useEffect } from 'react'
import { Image, ActionIcon, Box } from 'components'
import { CoverFileContext } from '../../context/CoverFileContext'
import { IconDownload, IconTrash } from '@tabler/icons-react'
import { useStyles } from './ProjectCoverThumbnailStyles'
import { deleteCoverImage } from 'pages/NewProject/service/projects'

export interface ProjectCoverThumbnailProps {
  alt: string | undefined
  width: number
  height: number
  projectId?: string
}

export const ProjectCoverThumbnail = ({ width, height, alt, projectId }: ProjectCoverThumbnailProps) => {
  const { classes } = useStyles()

  const { downloadURL, fileCoverSrc, setFileCover, setFileCoverSrc, setLoadingCover } = useContext(CoverFileContext)

  const deleteImage = useCallback(async (id: string) => {
    setLoadingCover(true)
    try {
      const response = await deleteCoverImage(id)
      return response
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingCover(false)
    }
  }, [])

  const handleDeletion = useCallback(() => {
    setFileCover(null)
    setFileCoverSrc('')
  }, [])

  const removeAction = () => {
    if (projectId) {
      deleteImage(projectId).then(handleDeletion)
      return
    }
    handleDeletion()
  }

  return (
    <Box className={classes.main}>
      <Box className={classes.actions}>
        {downloadURL && (
          <ActionIcon
            variant='transparent'
            className={classes.ActionIcon}
            component='a'
            href={downloadURL}
            target='_blank'
            radius='md'
            size={24}
          >
            <IconDownload />
          </ActionIcon>
        )}
        {fileCoverSrc && (
          <ActionIcon onClick={removeAction} className={classes.ActionIcon} radius='md' size={24}>
            <IconTrash />
          </ActionIcon>
        )}
      </Box>
      {fileCoverSrc && (
        <Image width={width} height={height} alt={alt} fit='cover' mt='sm' radius='md' src={fileCoverSrc} />
      )}
    </Box>
  )
}
