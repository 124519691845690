import { IHeaderInfo } from './headerInfoTypes'
import { HeaderInfoWrapper, CloseWrapper } from './headerInfoStyles'
import { UserInfo } from 'components'
import Close from './CloseBtn'

const HeaderInfo = ({ title, userName, userImage, closeFunction, loading, children }: IHeaderInfo) => {
  return (
    <HeaderInfoWrapper>
      {children ? children : <h2>{title}</h2>}
      <UserInfo userName={userName} image={userImage} loading={loading} />
      <CloseWrapper role='button' onClick={closeFunction}>
        <Close />
      </CloseWrapper>
    </HeaderInfoWrapper>
  )
}

export default HeaderInfo
