import { IReceivedGoogleDriveFile } from '@legacy/common/types/GoogleDriveFiles'
import { Card, LinkContainer } from './styled'

type GDriveFileProps = GDriveFileWithDeleteProps | GDriveFileWithoutDeleteProps
interface IGDriveFileBaseProps {
  readonly file: IReceivedGoogleDriveFile
  readonly children?: never
}
interface GDriveFileWithDeleteProps extends IGDriveFileBaseProps {
  readonly deleteFile: (id: string) => void
}
interface GDriveFileWithoutDeleteProps extends IGDriveFileBaseProps {
  readonly deleteFile?: false
}

function isFunction (value: any) {
  return typeof value === 'function'
}

function renderButton (deleteFile: any) {
  return (
    <button type="button" onClick={deleteFile}>
      <i className="icon-delete" />
    </button>
  )
}

const GDriveFile = ({ file, deleteFile }: GDriveFileProps) => (
  <Card>
    {isFunction(deleteFile) && renderButton(deleteFile)}
    <img src={file.icon_url} height={16} width={16} alt={file.name} />
    <LinkContainer>
      <a rel="noreferrer" target="_blank" href={file.url}>
        {file?.name}
      </a>
    </LinkContainer>
  </Card>
)

export default GDriveFile
