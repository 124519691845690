/* global I18n */

import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal';
import Button from '../_atoms/Button'
import FileViewer from './FileViewer'

const FileModal = (props) => {
  const { showFileByIndex, selectedFileIndex, modalVisible, setModalVisible } = props
  const filesList = props.filesList.map((fileData) => {
    const data = fileData
    data.fileCover = data.fileCoverLarge
    return data
  })

  const previousFileIndex =
    selectedFileIndex === 0 ? undefined : selectedFileIndex - 1
  const nextFileIndex =
    selectedFileIndex === filesList.length - 1
      ? undefined
      : selectedFileIndex + 1

  return (
    <Modal
      show={modalVisible}
      onHide={() => setModalVisible(false)}
      dialogClassName="large-modal file-modal"
    >
      <Modal.Body>
        <div className='modal-header mx-4 mt-4 p-0 border-0 justify-content-end'>
          <Button
            icon='common-close'
            type='clear-icon'
            onClick={() => setModalVisible(false)}
          />
        </div>

        <div className='modal-body mx-5 mb-5 p-0 text-left'>
          <div className='viewer-container'>
              {modalVisible && <FileViewer
                fileData={filesList[selectedFileIndex]}
                thumbSize='xlarge'
                color='medium'
                showPreview
                className="FileViewerHeight"
              />}

            {previousFileIndex !== undefined && (
              <Button
                type='light'
                size='icon'
                icon='common-arrow-left'
                className='float-button left'
                onClick={() => showFileByIndex(previousFileIndex)}
              />
            )}

            {nextFileIndex !== undefined && (
              <Button
                type='light'
                size='icon'
                icon='common-arrow-right'
                className='float-button right'
                onClick={() => showFileByIndex(nextFileIndex)}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

FileModal.propTypes = {
  filesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFileIndex: PropTypes.number.isRequired,
  showFileByIndex: PropTypes.func.isRequired,
}

export default FileModal
