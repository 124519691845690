import PropTypes from 'prop-types'
import classNames from 'classnames'

const Loading = (props) => {
  const { smallMargin } = props;

  return (
    <div className={classNames("spinner", { "small-margin": smallMargin })}>
      <div className="dot1" />
      <div className="dot2" />
    </div>
  );
};

Loading.propTypes = {
  smallMargin: PropTypes.bool,
};

Loading.defaultProps = {
  smallMargin: false,
};

export default Loading;
