import styled from 'styled-components'
import { Title } from 'components/Title'
import bgImage from 'assets/header-img.png'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '../Skeleton'
export interface DashboardHeaderProps {
  readonly name: string | undefined
  readonly isloading?: boolean
  readonly isMobile?: boolean
}

export const DashHead = styled.div<{ isMobile: boolean }>`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  padding: ${props => (props.isMobile ? '0px 30px' : '24px 30px')};
  height: ${props => (props.isMobile ? '82px' : '138px')};
  background: #4263eb;
  background-image: url('${bgImage}');
  background-position: center;
  background-size: cover;
`
export const StyledTitle = styled(Title)`
  color: white;
  font-weight: 700;
`
export const Container = styled.div`
  display: flex;
  align-items: end;
  margin-left: 100px;
  height: 100%;
`

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({ name, isloading = false, isMobile = false }) => {
  const { t } = useTranslation()

  return (
    <Skeleton
      visible={isloading}
      color='indigo.7'
      height={isMobile ? 82 : 138}
      width='100%'
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <DashHead isMobile={isMobile}>
        {!isMobile && (
          <Container>
            {name === undefined ? (
              <Skeleton visible h={60} w={400} mt={32} />
            ) : (
              <StyledTitle order={1}>
                {t('students_hello')} {name}
              </StyledTitle>
            )}
          </Container>
        )}
      </DashHead>
    </Skeleton>
  )
}
