import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Modal, Text, Group, Button } from 'components'
import { RemoveConfirmModalProps } from './interfaces'

export const ConfirmKindChangeModal: React.FC<RemoveConfirmModalProps> = ({
  opened,
  onClose,
  action,
  isTemplate = true,
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const handleModalAction = (confirmed: boolean) => {
    if (confirmed && action) action()
    onClose(confirmed)
  }

  return (
    <Modal
      fullScreen={isMobile}
      size='lg'
      opened={opened}
      onClose={() => handleModalAction(false)}
      title={t('confirm_kind_change_title')}
    >
      <Text size='md' mb='sm'>
        {isTemplate ? (
          <Trans i18nKey='confirm_kind_change_body_template' />
        ) : (
          <Trans i18nKey='confirm_kind_change_body_application' />
        )}
      </Text>

      <Group spacing='sm' position='right' mt='md'>
        <Button variant='default' size='lg' onClick={() => handleModalAction(false)}>
          {t('general_buttons_cancel')}
        </Button>
        <Button variant='filled' size='lg' onClick={() => handleModalAction(true)}>
          {t('confirm_kind_change_body_confirm')}
        </Button>
      </Group>
    </Modal>
  )
}
