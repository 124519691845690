import { resumeText } from '../Tools/Common'
import '../../assets/stylesheets/tags.scss'

interface IColors {
  text?: string
  background?: string
  border?: string
  opacity?: number
}

interface ITag {
  text: string
  icon?: string
  type?: 'light' | 'medium' | 'dark' | 'bordered-light' | 'success' | 'danger' | 'featured' | 'contract-draft'
  | 'contract-approved' | 'contract-active' | 'contract-inactive'
  size?: 'medium' | 'large'
  colors?: IColors
  className?: string
  maxLength?: number
  textBold?: boolean
  customIcon?: HTMLElement
}

const Tag = ({
  text,
  icon,
  type = 'medium',
  size = 'medium',
  colors,
  className = '',
  maxLength,
  textBold,
  customIcon,
}: ITag) => {
  const boldClass = textBold ? 'malva-bold' : ''
  const style = {
    backgroundColor: '',
    border: '',
    color: '',
    opacity: 1,
  }
  // @TODO: do review on UI
  if (colors?.background) style.backgroundColor = colors.background
  if (colors?.border) style.border = colors.border
  if (colors?.text) style.color = colors.text
  if (colors?.opacity) style.opacity = colors.opacity / 100

  return (
    <div className={`tag tag-type-${type} tag-size-${size} ${className} ${boldClass}`} style={style} title={text}>
      {icon && (<i className={`icon-${icon}`} />)}
      {customIcon && customIcon}
      {maxLength ? resumeText(text, maxLength) : text}
    </div>
  )
}

export default Tag

