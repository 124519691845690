/* eslint-disable react/prop-types */
import { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { GDriveGrid } from './styled'
import GDriveFile from '@legacy/common/_components/GDriveFile'
import { IReceivedGoogleDriveFiles } from '@legacy/common/types/GoogleDriveFiles'

const API_PATH = '/api/google_drive_files'
interface IFeature {
  readonly key: string
  readonly state: string
  readonly gates: boolean
}
interface IgDrive {
  readonly resourceId: string
  readonly resourceClass: string
  readonly features?: IFeature[] | null
}

const GDriveList = ({ resourceId, resourceClass, features }: IgDrive) => {
  const [files, setFilesGoogle] = useState<IReceivedGoogleDriveFiles>([])

  const urlGoogleFiles = `${API_PATH}?${resourceClass}=${resourceId}`

  const getFileUpload = () => {
    axios.get(urlGoogleFiles).then(response => {
      setFilesGoogle(response.data)
    })
  }

  useEffect(() => {
    getFileUpload()
  }, [])

  return (
    <GDriveGrid>
      {files.map(file => (
        <GDriveFile key={file?.id} file={file} />
      ))}
    </GDriveGrid>
  )
}

export default GDriveList
