import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import IconGlobe from './desiveLanguageIcons'
import { DeviseLanguageStyled } from './styled'


export interface DeviseLanguageProps {
  readonly children?: never
}

const DeviseLanguage: React.FC<DeviseLanguageProps> = () => {
  const { t, i18n } = useTranslation()

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <DeviseLanguageStyled aria-label={t('account_signIn_change_language')}>
      <IconGlobe />
      <a
        className={classNames({ active: i18n.language === 'en' })}
        onClick={() => changeLanguage('en')}
      >
        <div className="responsive">EN</div>
        <div className="default">English</div>
      </a>
      <a
        className={classNames({ active: i18n.language === 'pt-BR'})}
        onClick={() => changeLanguage('pt-BR')}
      >
        <div className="responsive">PT</div>
        <div className="default">Português</div>
      </a>
    </DeviseLanguageStyled>
  )
}

export default DeviseLanguage
