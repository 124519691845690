import { useContext, useEffect } from 'react'
import { useCurrentUser } from 'context'
import { useTranslation } from 'react-i18next'
import { GuardianDashboardContext } from '../../context/GuardianDashboardContext'
import { notifications } from 'context'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { DashboardHeader, Tabs, Avatar, StudentHeader, Heading, Group, Select, Stack, Skeleton } from 'components'
import { IconX, IconBackpack } from '@tabler/icons-react'
import { ProjectsList } from '../ProjectsList'
import { GuardianDashboardSkeleton } from '../GuardianDashboardSkeleton'
import { ITermSelect } from 'interfaces'
import {
  getGuardianStudents,
  getGuardianSchoolTermsByStudent,
  getGuardianProjectsByStudent,
  getGuardianStudentLastReport,
} from 'api'
import { getInitials } from 'helpers'
import { isEmpty } from 'lodash'
import { NewReportAlert } from '../NewReportAlert'

export const GuardianDashboard = () => {
  const { currentUser } = useCurrentUser()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { t, i18n } = useTranslation()
  const {
    error,
    setError,
    students,
    setStudents,
    currentStudent,
    setCurrentStudent,
    setProjects,
    setProjectsLoading,
    filterData,
    setFilterData,
    studentDataLoading,
    setStudentDataLoading,
    selectData,
    setSelectData,
    filterTerm,
    setFilterTerm,
    getDiscURL,
    getProfileURL,
    setShowAlert,
    setAlertReportData,
  } = useContext(GuardianDashboardContext)

  const projectKind = [
    {
      value: 'approved',
      label: t('project_progress'),
    },
    {
      value: 'finished',
      label: t('project_finished'),
    },
  ]

  const loadProjectsData = async (schoolTermID: string, studentID: string) => {
    setProjects(null)
    setProjectsLoading(true)
    if(!isEmpty(schoolTermID) && !isEmpty(studentID)) {
      getGuardianProjectsByStudent(schoolTermID, studentID).then(response => {
        if (response && response.status === 200) {
          setProjects(response.data)
          setTimeout(() => {
            setProjectsLoading(false)
          }, 1000)
        } else {
          setError(response.statusText)
        }
      })
    } else {
      setProjects([])
      setProjectsLoading(false)
    }
  }

  useEffect(() => {
    const loadStudentsData = async () => {
      getGuardianStudents().then(response => {
        if (response && response.status === 200) {
          setStudents(response.data)
          setCurrentStudent(response.data[0].id)
        } else {
          setError(response.statusText)
        }
      })
      document.title = t('page_title', { page_name: t('new_guardian_dashboard.page_title') })
    }
    loadStudentsData()
  }, [])

  useEffect(() => {
    if (currentStudent !== null) {
      setProjects(null)
      setStudentDataLoading(true)
      const loadDashBoardData = async () => {
        getGuardianStudentLastReport(currentStudent).then(response => {
          if (response && response.status === 200) {
            if (!isEmpty(response.data)) {
              setAlertReportData(response.data)
              setShowAlert(true)
            } else {
              setAlertReportData(null)
              setShowAlert(false)
            }
          } else {
            setError(response.statusText)
          }
        })
        getGuardianSchoolTermsByStudent(currentStudent).then(response => {
          if (response && response.status === 200) {
            setFilterData(response.data)
            setStudentDataLoading(false)

            const currentTerm = response.data.find((term: ITermSelect) => term.current === true) || null

            const selectItems = response.data.map((item: ITermSelect) => ({
              value: item.value,
              label: item.current ? item.label + t('general_select_current') : item.label,
            }))
            setSelectData(selectItems)

            if (currentTerm !== null && currentTerm.value !== null) {
              setFilterTerm(currentTerm.value)
              loadProjectsData(currentTerm.value, currentStudent)
            } else {
              if(response.data.length > 0) {
                setFilterTerm(response.data[0].value)
                loadProjectsData(response.data[0].value, currentStudent)
              } else {
                setFilterTerm(null)
                loadProjectsData('', currentStudent)
              }                
            }
          } else {
            setError(response.statusText)
          }
        })
      }
      loadDashBoardData()
    }
  }, [currentStudent])

  useEffect(() => {
    if (filterTerm && currentStudent) loadProjectsData(filterTerm, currentStudent)
  }, [filterTerm, currentStudent])

  useEffect(() => {
    if (filterData !== null) {
      const selectItems = filterData.map(item => ({
        value: item.value,
        label: item.current ? item.label + t('general_select_current') : item.label,
      }))
      setSelectData(selectItems)
    }
  }, [i18n.language, filterData])

  useEffect(() => {
    if (error) {
      notifications.show({
        title: t('general_error_title'),
        message: t('general_error_message_data_not_loaded') + ' ' + error,
        color: 'red',
        icon: <IconX />,
      })
    }
  }, [error])

  return (
    <>
      <DashboardHeader name={currentUser?.full_name} isMobile={isMobile} />
      {students && students.length > 0 && (
        <Tabs value={currentStudent} onTabChange={setCurrentStudent} mt={isMobile ? 0 : 80}>
          <Tabs.List mb='md' id='students-tabs'>
            {students.map(student => (
              <Tabs.Tab
                key={student.id}
                value={student.id}
                icon={
                  <Avatar size='md' src={student.avatar}>
                    {getInitials(student.name)}
                  </Avatar>
                }
              >
                {student.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          {students.map(student => (
            <Tabs.Panel value={student.id} key={student.id}>
              <Stack spacing='md'>
                {!studentDataLoading ? (
                  <>
                    <NewReportAlert student={student} />
                    <StudentHeader student={student} getDiscURL={getDiscURL} getProfileURL={getProfileURL} />
                    <Group position='apart' mt='md'>
                      <Heading
                        size='md'
                        text={t('new_guardian_dashboard.projects_title')}
                        supportText={t('new_guardian_dashboard.projects_subtitle')}
                        icon={<IconBackpack size={32} />}
                      />
                      {selectData && selectData.length > 0 && (
                        <Select
                          size='md'
                          maxDropdownHeight={400}
                          placeholder={t('new_guardian_dashboard.select_term_placeholder')}
                          name='termSelect'
                          value={filterTerm}
                          data={selectData ? selectData : []}
                          onChange={setFilterTerm}
                          withinPortal
                          clearable={false}
                          sx={{ width: isMobile ? '100%' : 300 }}
                          id='projects-filter'
                        />
                      )}
                    </Group>
                  </>
                ) : (
                  <Stack spacing='md'>
                    <Skeleton height={100} />
                    <Skeleton height={55} />
                  </Stack>
                )}
                <ProjectsList projectKind={projectKind} />
              </Stack>
            </Tabs.Panel>
          ))}
        </Tabs>
      )}
      {students === null && <GuardianDashboardSkeleton />}
    </>
  )
}
