import { useState, useEffect } from 'react'
import 'dayjs/locale/pt-br'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Paper } from '@mantine/core'
import {
  Heading,
  Button,
  Select,
  Text,
  Grid,
  TextInput,
  PasswordInput,
  Flex,
  Space,
  DateInput,
  Checkbox,
} from 'components'
import { IconCalendar, IconAt } from '@tabler/icons-react'
import service from './service'
import { PaperStyled } from './styled'
import { useCurrentUser } from 'context/useCurrentUser'
import { useForm, hasLength } from '@mantine/form'
import { useNotification } from 'context/useNotificationContext'
import { IPayload, IOptionsGetData, IInvite } from './interface'
import { useMediaQuery } from 'hooks'
import { useNavigate } from 'react-router-dom'

export const Body = () => {
  const { id } = useParams()
  const { t, i18n } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { handleNotification } = useNotification()
  const navigate = useNavigate()

  const [schoolYear, setSchoolYear] = useState<IOptionsGetData[]>([])
  const [grade, setGrade] = useState<IOptionsGetData[]>([])
  const [groupSchoolTermsOfCurrentYear, setGroupSchoolTermsOfCurrentYear] = useState<IOptionsGetData[]>([])
  const [group, setGroup] = useState<IOptionsGetData[]>([])
  const [invite, setInvite] = useState<IInvite>({
    yearInvite: [],
    groupInvite: [],
  })

  const isMobile = useMediaQuery('(max-width: 600px)')

  const fetchSchoolYear = async () => {
    const response = await service.getSchoolYear()
    setSchoolYear(response)
  }

  const fetchGrade = async () => {
    const response = await service.getGrade()
    setGrade(response)
  }

  const fetchGroupTeacher = async () => {
    if (currentUser?.profile_used !== 'tutor') {
      return
    }
    const response = await service.getGroupTeacher()
    if (response.length > 0) {
      form.setFieldValue('group_school_term_id', response[0].value)
    }
    setGroup(response)
  }

  const fetchGroup = async () => {
    if (currentUser?.profile_used !== 'manager') {
      return
    }
    const response = await service.getGroupSchoolTermsOfCurrentYear()
    setGroupSchoolTermsOfCurrentYear(response)
  }

  const fetchInvite = async () => {
    const response = await service.getInvite(id)
    setInvite(response)
  }

  useEffect(() => {
    if (id) {
      fetchInvite()
    } else {
      fetchGroup()
      fetchGroupTeacher()
    }
  }, [currentUser?.profile_used])

  useEffect(() => {
    fetchSchoolYear()
    fetchGrade()
  }, [])

  const validationPassword = (value: string) => {
    if (value.length > 0) {
      const password = hasLength({ min: 6 }, t('student_creation_screen.password_validation'))(value)

      return password
    }
  }

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      gradeId: '',
      groupSchoolTermId: '',
      lastName: '',
      password: '',
      schoolYearId: '',
      username: '',
      emailAsLogin: false,
    },
    validate: {
      password: value => validationPassword(value),
    },
  })

  const handleSubmit = async (values: typeof form.values) => {
    if (id !== undefined) {
      values.schoolYearId = invite.yearInvite[0].value
      values.groupSchoolTermId = invite.groupInvite[0].value
    }
    if (values.email.length === 0) {
      values.emailAsLogin = false
    }
    const response = await service.saveData(values as IPayload)
    if (response.errors) {
      form.setErrors(response.errors)
      handleNotification(true, t('student_creation_screen.mensage_error'), true, 'error')
    }
    if (response.studentId) {
      return navigate('/learners?showNotificationCreate=true')
    }
  }

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PaperStyled>
          <Paper shadow='md' radius='md' p='xl' styles={{ margin: '-20px' }}>
            <Text fs='italic'>{t('student_creation_screen.required_fields')}</Text>
            <Space h='md' />
            <Heading size='sm' text={t('student_creation_screen.information_student')} />
            <Space h='xs' />
            <Grid gutterXl={10} gutterXs='md' gutterMd='xl' gutter={5}>
              <Grid.Col md={6} lg={4}>
                <TextInput
                  id='input-first-name'
                  size='md'
                  aria-label={t('student_creation_screen.name')}
                  label={t('student_creation_screen.name')}
                  name='first_name'
                  {...form.getInputProps('first_name')}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <TextInput
                  id='input-last-name'
                  size='md'
                  aria-label={t('student_creation_screen.last_name')}
                  label={t('student_creation_screen.last_name')}
                  name='last_name'
                  {...form.getInputProps('last_name')}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <DateInput
                  id='input-birthday'
                  dateParser={input => {
                    if (i18n.language === 'pt-BR') {
                      const day = Number(input.split('/')[0])
                      const month = Number(input.split('/')[1])
                      const year = Number(input.split('/')[2])

                      return new Date(year, month - 1, day)
                    }
                    return new Date(input)
                  }}
                  locale={i18n.language}
                  {...form.getInputProps('birthday')}
                  valueFormat={i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'}
                  label={t('student_creation_screen.birthdate')}
                  icon={<IconCalendar size={16} />}
                  size='md'
                  firstDayOfWeek={i18n.language === 'pt-BR' ? 0 : 1}
                />
              </Grid.Col>
            </Grid>

            <Space h='lg' />
            <Heading size='sm' text={t('student_creation_screen.school_information')} />

            <Space h='xs' />
            <Grid gutterXl={10} gutterXs='md' gutterMd='xl' gutter={5}>
              <Grid.Col md={6} lg={6}>
                {id !== undefined ? (
                  <Select
                    id='input-school-year'
                    label={t('student_creation_screen.school_year')}
                    data={invite.yearInvite}
                    size='md'
                    withAsterisk
                    disabled
                    value={invite.yearInvite[0]?.value}
                  />
                ) : (
                  <Select
                    id='input-school-year'
                    label={t('student_creation_screen.school_year')}
                    data={schoolYear}
                    size='md'
                    {...form.getInputProps('school_year_id')}
                    withAsterisk
                  />
                )}
              </Grid.Col>

              <Grid.Col md={6} lg={6}>
                <Select
                  id='input-grade-id'
                  label={t('student_creation_screen.year')}
                  data={grade}
                  size='md'
                  {...form.getInputProps('grade_id')}
                  withAsterisk
                />
              </Grid.Col>
            </Grid>

            <Space h='lg' />

            <Heading size='sm' text={t('student_creation_screen.group_information')} />

            {id !== undefined && (
              <Select
                id='input-group-schol-term'
                label={t('student_creation_screen.group_request')}
                data={invite.groupInvite}
                size='md'
                value={invite.groupInvite[0]?.value}
                withAsterisk
                disabled
              />
            )}

            {currentUser?.profile_used === 'tutor' && id === undefined && (
              <>
                <Space h='xs' />
                <Grid gutterXl={10} gutterXs='md' gutterMd='xl' gutter={5}>
                  <Grid.Col md={6} lg={12}>
                    <Select
                      id='input-group-schol-term'
                      label={t('student_creation_screen.group_request')}
                      data={group}
                      size='md'
                      {...form.getInputProps('group_school_term_id')}
                      withAsterisk
                    />
                  </Grid.Col>
                </Grid>
              </>
            )}

            {currentUser?.profile_used !== 'tutor' && (
              <>
                <Space h='xs' />
                <Grid gutterXl={10} gutterXs='md' gutterMd='xl' gutter={5}>
                  <Grid.Col md={6} lg={12}>
                    <Select
                      id='input-group-schol-term'
                      label={t('student_creation_screen.group')}
                      data={groupSchoolTermsOfCurrentYear}
                      size='md'
                      {...form.getInputProps('group_school_term_id')}
                      clearable
                    />
                  </Grid.Col>
                </Grid>
              </>
            )}

            <Space h='lg' />
            <Heading
              size='sm'
              text={t('student_creation_screen.first_access_information')}
              supportText={t('student_creation_screen.first_access_information_details')}
            />
            <Grid gutterXl={10} gutterXs='md' gutterMd='xl' gutter={5}>
              <Grid.Col md={6} lg={6}>
                <TextInput
                  id='input-username'
                  size='md'
                  aria-label={t('student_creation_screen.login')}
                  label={t('student_creation_screen.login')}
                  description={t('student_creation_screen.login_example')}
                  name='username'
                  {...form.getInputProps('username')}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={6} lg={6}>
                <PasswordInput
                  id='input-temporary-password'
                  label={t('student_creation_screen.temporary_password')}
                  description={t('student_creation_screen.temporary_password_details')}
                  size='md'
                  {...form.getInputProps('password')}
                  withAsterisk
                />
              </Grid.Col>

              <Grid.Col md={6} lg={12}>
                <TextInput
                  id='input-email'
                  size='md'
                  aria-label={t('student_creation_screen.email')}
                  label={t('student_creation_screen.email')}
                  description={t('student_creation_screen.email_details')}
                  name='email'
                  {...form.getInputProps('email')}
                  icon={<IconAt />}
                />
              </Grid.Col>

              <Grid.Col md={6} lg={12}>
                <Space h='lg' />
                <Heading size='sm' text={t('student_creation_screen.options')} />
              </Grid.Col>

              <Grid.Col md={6} lg={12}>
                <Heading
                  size='xs'
                  text={t('student_creation_screen.login_method')}
                  supportText={t('student_creation_screen.login_method_details')}
                />
                <Checkbox
                  id='input-email-as-login'
                  label={t('student_creation_screen.email_as_login')}
                  size='md'
                  disabled={form.values.email.length <= 0}
                  {...form.getInputProps('email_as_login')}
                />
              </Grid.Col>
            </Grid>

            <Flex justify='right'>
              <Button radius='xl' size='lg' type='submit' mt='lg' fullWidth={isMobile}>
                {t('general_buttons_save')}
              </Button>
            </Flex>
          </Paper>
        </PaperStyled>
      </form>
    </>
  )
}
