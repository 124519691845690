import { createStyles } from 'hooks'

export const useStyles = createStyles(theme => ({
  mediaBlock: {
    [theme.fn.smallerThan('xs')]: {
      height: 'auto',
      minHeight: 200,
    },
    position: 'relative',
    marginTop: 16,
    height: 500,
    backgroundColor: '#000',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      [theme.fn.smallerThan('xs')]: {
        width: '100%',
        maxWidth: '100%',
        height: 'auto',
        objectFit: 'fill',
        top: 0,
        border: 0,
      },
    },
  },
}))
