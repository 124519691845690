export interface ICriteriaModal {
  opened: boolean
  onClose: () => void
}

export interface ICriteriaFormValues {
  [key: string]: string | undefined
  value1: string
  value2: string
  value3: string
  value4: string
  value5: string
}

export const initialValues = {
  value1: '',
  value2: '',
  value3: '',
  value4: '',
  value5: '',
}
