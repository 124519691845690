import styled from 'styled-components'

const DeviseLanguageStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2rem;
  position: static;
  height: fit-content;
  left: 66.4rem;
  top: 0.4rem;
  background: #ffffff;
  border-radius: 8rem;
  color: var(--color-black);

  a {
    margin: 0px 14px;
    color: var(--color-black);
    cursor: pointer;

    .responsive {
      display: none;
    }

    .default {
      display: flex;
    }
    &:hover {
      color: var(--color-black);
    }
  }

  .active {
    font-weight: 700;
    border-bottom: 1px solid var(--color-black);
    margin-left: 8px;
  }

  @media (max-width: 900px) {
    padding: 16px 24px;
    width: fit-content;
    height: fit-content;
    left: 18.6rem;
    top: 0.6rem;

    a {
      .responsive {
        display: flex;
      }

      .default {
        display: none;
      }
    }
  }
`

const IconStyled = styled.svg`
  overflow: inherit !important;
`

export { DeviseLanguageStyled, IconStyled }
