import styled from "styled-components"
import InputTextArea from '@legacy/common/_components/InputTextArea'

export const ProjectJournalContainer = styled.div`
  padding-top: 2rem;
`

export const ProjectJournalTextArea = styled(InputTextArea)`
  border: 0;
  border-radius: 6px;
` 