import { useState } from 'react'
import { WrapperEvaluate, ScoreContainer } from './StudentModalContentStyled'
import { ScoreCard } from 'components/ScoreComponent/ScoreCard'
import times from 'lodash/times'
import LV4ItemContainer from '@legacy/common/_molecules/Lv4ItemContainer'
import { IEvaluativeItem, IStudentAsssessmentsprops } from './EvaluativeItemTypes'
import { useTranslation } from 'react-i18next'
import { IpostScore } from '../StudentsAssessments/StudentsAssessmentsTypes'
import { IAssessmentScore } from './StudentModalContentTypes'
import { ScoreItemLabel } from 'components/ScoreComponent/ScoreItem'
import { Checkbox, Stack } from 'components'

const EvaluativeItem = ({
  item,
  mixPanelTracker,
  setData,
  setHasChanges,
}: IStudentAsssessmentsprops) => {
  const [show, setShow] = useState(!item?.doNotAssess || false)
  const [score, setScore] = useState(item.masterScore)
  const { t } = useTranslation()

  const RUBRIC_KEYS = ['rubric_1', 'rubric_2', 'rubric_3', 'rubric_4', 'rubric_5']
  const DefaultModalRubrics = RUBRIC_KEYS.map(key => t(key))
  
  const createPayload = (score: number, item: IEvaluativeItem, doNotAssess: boolean): IpostScore => {
    return {
      id: item.id,
      do_not_assess: doNotAssess,
      score,
      justification: item.masterJustification,
    }
  }

  const onClickScore = async (i: number) => {
    mixPanelTracker('Definir número avaliação')

    const scorepayload = score === i + 1 ? 0 : i + 1

    setScore(scorepayload)

    const payload = createPayload(scorepayload, item, false)

    setData(data => {
      const hasOnPayload = data.assessment_scores.some(({ id }) => item.id === id)

      if (hasOnPayload) {
        const mapAssess = (assessmentScore: IAssessmentScore) =>
          assessmentScore.id === item.id ? payload : assessmentScore

        return {
          ...data,
          assessment_scores: data.assessment_scores.map(mapAssess),
        }
      }

      return {
        ...data,
        assessment_scores: [...data.assessment_scores, payload],
      }
    })

    setHasChanges(true)
  }

  const updateDoNotAssess = (item: IEvaluativeItem) => {
    setData(data => {
      setScore(0)

      setHasChanges(true)

      const hasOnPayload = data.assessment_scores.some(({ id }) => item.id === id)

      if (hasOnPayload) {
        const mapAssess = (assessmentScore: IAssessmentScore) =>
          assessmentScore.id === item.id ? createPayload(0, item, !item.doNotAssess) : assessmentScore

        return {
          ...data,
          assessment_scores: data.assessment_scores.map(mapAssess),
        }
      }

      return {
        ...data,
        assessment_scores: [...data.assessment_scores, createPayload(0, item, !item.doNotAssess)],
      }
    })
  }

  return (
    <WrapperEvaluate>
      <Stack spacing='sm'>
        <Checkbox
          size='sm'
          checked={show}
          onChange={() => {
            updateDoNotAssess(item)
            setShow(!show)
          }}
          label={t('enable_assessment')}
        />
        <LV4ItemContainer
          name={item.name}
          lv1Name={item.lv1Name}
          id={item.id}
          curriculumKind={'content'}
          color={item.color}
          originalCodes={item.originalCodes}
          cycles={item.cycles}
          rubrics={[]}
          hideContainer
        />
      </Stack>
      {show && (
        <Stack spacing='md' mt='md' align='flex-start'>
          <ScoreContainer>
            {times(5, i => (
              <ScoreCard
                label={(i + 1) as ScoreItemLabel}
                key={i}
                active={score === i + 1}
                rubric={
                  item.rubrics && item.rubrics[i + 1] !== ''
                    ? item.rubrics[i + 1]
                    : DefaultModalRubrics[i]
                }
                onClick={() => {
                  onClickScore(i)
                }}
              />
            ))}
          </ScoreContainer>
        </Stack>
      )}
    </WrapperEvaluate>
  )
}

export default EvaluativeItem
