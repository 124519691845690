import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isUndefined, uniq } from 'lodash'
import RubricsModal from '../MeetingAssessment/RubricsModal'
import Tag from '../_atoms/Tag'

const Lv4ItemContainer = React.forwardRef((props, ref) => {
  const {
    breadcrumb,
    name,
    lv1Name,
    lv2Name,
    lv3Name,
    id,
    curriculumKind,
    color,
    originalCodes,
    cycles,
    children,
    hideContainer,
    rubrics,
    className,
    students,
  } = props

  const studentsTag = (students, color) => {
    if (isEmpty(students)) return ''

    let [developed, total] = [students.developed, students.total]
    const [developedIsUndefined, totalIsUndefined] = [isUndefined(developed), isUndefined(total)]

    if (developedIsUndefined && totalIsUndefined) return ''

    developed = developedIsUndefined ? '-' : developed
    total = totalIsUndefined ? '-' : total

    const { tagType, colors } =
      developed === 0 || developed === '-'
        ? ('light', {})
        : ('dark', { background: `${color}${developed !== total ? '80' : ''}` })

    return <Tag icon='users' text={`(${developed}/${total})`} type={tagType} colors={colors} />
  }

  return (
    <div ref={ref} key={id} className={`${!hideContainer && 'mb-5 container-area'} ${className} `}>
      <div className='mr-2'>
        <Tag type='dark' text={lv1Name} colors={{ background: color }} />
        {cycles && uniq(cycles).map(cycle => <Tag key={cycle.id} type='bordered-light' text={cycle.name} />)}
        {studentsTag(students, color)}
      </div>
      {breadcrumb && (
        <div className='pt-2'>
          <small className='mx-0 darkergray-color'>{breadcrumb}</small>
        </div>
      )}
      <div className='pt-1'>
        {!isEmpty(lv2Name) && (
          <Fragment>
            <span>{lv2Name}</span>
            <i className='icon-common-arrow-right small-arrow-icon mx-2' style={{ color }} />
          </Fragment>
        )}
        {!isEmpty(lv3Name) && (
          <Fragment>
            <span>{lv3Name}</span>
            <i className='icon-common-arrow-right small-arrow-icon mx-2' style={{ color }} />
          </Fragment>
        )}

        {originalCodes && <span className='malva-bold darkgray-color mr-2 text-small'>{originalCodes}</span>}
        <span className='m-0 mr-2 darkgray-color text-small'>{name}</span>

        {!isEmpty(rubrics) && <RubricsModal rubricType={curriculumKind} rubricScores={rubrics} />}
      </div>
      {children}
    </div>
  )
})

Lv4ItemContainer.displayName = 'Lv4ItemContainer'

Lv4ItemContainer.propTypes = {
  children: PropTypes.node,
  breadcrumb: PropTypes.string,
  name: PropTypes.string.isRequired,
  lv1Name: PropTypes.string.isRequired,
  lv2Name: PropTypes.string,
  lv3Name: PropTypes.string,
  id: PropTypes.string.isRequired,
  curriculumKind: PropTypes.string.isRequired,
  color: PropTypes.string,
  originalCodes: PropTypes.string.isRequired,
  cycles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  hideContainer: PropTypes.bool,
  rubrics: PropTypes.arrayOf(PropTypes.string.isRequired),
  className: PropTypes.string,
  students: PropTypes.shape({
    total: PropTypes.number,
    developed: PropTypes.number,
  }),
}

Lv4ItemContainer.defaultProps = {
  breadcrumb: undefined,
  lv2Name: undefined,
  lv3Name: undefined,
  color: '',
  children: null,
  cycles: [],
  hideContainer: false,
  rubrics: [],
  className: 'pt-2',
  students: undefined,
}

export default Lv4ItemContainer
