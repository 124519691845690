import { useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from './defaultTheme'
import { darkTheme } from './darkTheme'
import env from 'env.json'

export type ThemeType = typeof defaultTheme

type Themes = {
  [key: string]: ThemeType,
}

const themes: Themes = {
  darkTheme: darkTheme,
  defaultTheme: defaultTheme,
}

const Theme = ({ children }: { children: React.ReactNode }) => {
  const selectedTheme = env['process.env.REACT_APP_TEMPLATE'] || 'defaultTheme'
  const [theme] = useState(themes[selectedTheme] || defaultTheme)

  return (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  )
}

export default Theme
