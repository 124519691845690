import { createStyles } from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      type,
    }: {
      type: string | undefined
    },
  ) => ({
    cardSection: {
      position: type === 'gdrive' ? 'inherit' : 'relative',
      zIndex: 1,
      width: 'auto',
      display: type === 'gdrive' ? 'flex' : 'block',
      flexDirection: type === 'gdrive' ? 'row-reverse' : 'inherit',
      flex: type === 'gdrive' ? 1 : 'inherit',
      alignItems: type === 'gdrive' ? 'center' : 'inherit',
    },
    actions: {
      position: type === 'gdrive' ? 'inherit' : 'absolute',
      padding: type === 'gdrive' ? `0  ${theme.spacing.md} 0 0` : theme.spacing.xs,
      gap: theme.spacing.xs,
      bottom: type === 'gdrive' ? 'auto' : 0,
      right: 0,
      zIndex: 2,
      height: type === 'gdrive' ? '100%' : 'fit-content',
      width: type === 'gdrive' ? 'fit-content' : '100%',
      display: 'flex',
      alignItems: 'center',
      flex: type === 'gdrive' ? 'none' : 1,
      justifyContent: type === 'gdrive' ? 'none' : 'flex-end',
      backgroundColor:
        type === 'gdrive'
          ? theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : 'white'
          : theme.colorScheme === 'dark'
            ? 'rgba(0, 0, 0, 0.7)'
            : 'rgba(0, 0, 0, 0.7)',
      borderRadius: type === 'gdrive' ? '0 0 0 0' : `0 0  ${theme.radius.md} ${theme.radius.md}`,
    },
    DownloadActionIcon: {
      display: type === 'gdrive' ? 'none' : 'block',
      opacity: 1,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'white',
      '&:hover': {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'white',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.dark[2],
      },
    },
    RemoveActionIcon: {
      opacity: 1,
      color:
        type === 'gdrive'
          ? theme.colorScheme === 'dark'
            ? theme.colors.dark[1]
            : theme.colors.dark[2]
          : theme.colorScheme === 'dark'
            ? theme.colors.dark[1]
            : 'white',
      '&:hover': {
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'white',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.dark[2],
      },
    },
    GDriveIcon: {
      color: theme.colorScheme === 'dark' ? theme.colors.turquoise[6] : theme.colors.turquoise[4],
    },
    GDriveContent: {
      flexGrow: 1,
    },
  }),
)
