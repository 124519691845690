export const paths = {
  account: {
    signout: '/users/sign_out',
    myProfile: (profile_url: string) => profile_url,
    profileChange: (profile: string) => `/profiles/set/${profile}`,
    admin: {
      curriculums: '/curriculums',
      school: '/school',
      transfer_students: '/transfer_students',
    },
  },
}
