import { useMemo } from 'react'
import { Indicator } from '@mantine/core'
import { DatePickerProps } from '@mantine/dates'

const INDICATORCOLOR = 'red'

export const DayRender: DatePickerProps['renderDay'] = date => {
  const day = date.getDate()

  const today = useMemo(() => {
    return new Date()
  }, [])

  const isToday = isSameDay(date, today)

  return (
    <Indicator size={6} color={INDICATORCOLOR} offset={-5} disabled={!isToday}>
      <div>{day}</div>
    </Indicator>
  )
}

const isSameDay = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}
