export interface FormValues {
  school_term: string
  group: string
  group_students: object[]
  group_teachers: object[]
  other_students: object[]
  other_teachers: object[]
  submitButton: number
  main_teacher: string
}

export const initalDatatab2 = {
  school_term: '',
  group: '',
  group_students: [{}],
  group_teachers: [{}],
  other_students: [{}],
  other_teachers: [{}],
  submitButton: 0,
  main_teacher: '',
}

export interface GroupData {
  label: string
  value: string
}

export interface TermData {
  label: string
  value: string
}

export interface ProjectTeacher {
  id: string
  full_name: string
  avatar_small: string
  role: string
}

export interface AnotherStudent {
  value: string
  label: string
  avatar_middle: string
}

export interface Tab2Data {
  schoolTermId: string
  kind: string
  groupSchoolTermId: string
  groupName?: string
  mainTutorId: string
  tutorGuest: boolean
  studentsAttributes: string[]
  projectTeachersAttributes: string[]
  projectTeachers: ProjectTeacher[]
  anotherStudents: AnotherStudent[]
  status: string
}
