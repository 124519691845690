//npm i --save-dev @types/rodal does not work
import Rodal from 'rodal'
import 'rodal/lib/rodal.css'
import { directionStyles, Container } from './LumiarDrawerStyles'
import { ILumiarDrawer } from './LumiarDrawerTypes'

const LumiarDrawer = ({
  visibility,
  children,
  onChange,
  side = 'right',
  scrollable = true,
  modalRef,
}: ILumiarDrawer) => {
  const modalDirection = side === 'right' ? 'slideRight' : 'slideLeft'

  const scroll = scrollable ? 'scroll' : 'hidden'
  const styles = directionStyles[side]

  return (
    <Container>
      <Rodal
        visible={visibility}
        onClose={() => {
          onChange(false)
          document.body.style.overflow = 'scroll'
        }}
        showMask
        animation={modalDirection}
        customStyles={{ ...styles, overflow: scroll }}
        ref={modalRef}
      >
        {children}
      </Rodal>
    </Container>
  )
}
export default LumiarDrawer
