import { createStyles, rem } from 'hooks'

export const useStyles = createStyles((theme, { checked }: { checked: boolean | undefined }) => ({
  button: {
    minWidth: rem(120),
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing.sm,
    width: '100%',
    border: `${rem(1)} solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor }).border
        : theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : 'white',
    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.turquoise[8] : theme.colors.turquoise[0],
    },
    boxShadow: theme.shadows.sm,
  },
  checkbox: {
    marginTop: rem(4),
    input: {
      cursor: 'pointer',
    },
  },
  icon: {
    minWidth: rem(48),
  },
  textbox: {
    width: 'auto',
  },
}))
