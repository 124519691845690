import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context/useCurrentUser'
import { paths } from '../paths'
import { DropdownMenuItemIndicatorStyled, DropdownMenuRadioItemStyled, LinkStyled } from '../NavbarMenuStyled'

export const ManagerProfileButton = () => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  if (!currentUser?.managed_accounts) {
    return <></>
  }

  const changeProfile = () => {
    window.localStorage.removeItem('current_user')
    window.location.href = paths.account.profileChange('manager')
  }

  return (
    <>
      <DropdownMenuRadioItemStyled value='manager'>
        <DropdownMenuItemIndicatorStyled />
        <LinkStyled onClick={changeProfile}>{t('school_manager')}</LinkStyled>
      </DropdownMenuRadioItemStyled>
    </>
  )
}
