import { useCallback, useContext, useEffect, useState, useMemo } from 'react'
import { Title, Stepper, TextLink, Stack } from 'components'
import { useTranslation } from 'react-i18next'
import { useScrollIntoView } from 'hooks/useScrollIntoView'
import { useCurrentUser } from 'context'
import { StepperContext } from '../../context/StepperContext'
import { ProjectDetails } from '../ProjectDetails'
import { ProjectParticipants } from '../ProjectParticipants'
import { ProjectSkillsStandards } from '../ProjectSkillsStandards'
import { ProjectPlanning } from '../ProjectPlanning'
import { IconChevronLeft } from '@tabler/icons-react'
import { ParticipantsProvider } from '../../context/ParticipantsContext'
import { SkillsStandardsProvider } from '../../context/SkillsStandardsContext'
import { LessonsProvider } from 'components'
import { getProjectTab } from '../../service/projects'
import { initialActionsTabs } from '../../service/interfaces'

export const ProjectStepper = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { projectId, isTemplate, active, setActive, block, setBlock, projectStatus } = useContext(StepperContext)
  const [tabState, setTabState] = useState({ tabPermission: initialActionsTabs, templateTab: false })
  const standardsOnly = currentUser?.selected_school?.enable_only_bncc_content
  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({ offset: 60 })

  const fetchProjectTab = useCallback(
    async (id: string | null) => {
      if (id && !Object.values(tabState.tabPermission).every(value => value === true)) {
        const res = await getProjectTab(id, '2')
        if (res) {
          setTabState(prev => ({
            ...prev,
            tabPermission: res?.actionsTabs,
            templateTab: res?.actionsTabs ? Object.keys(res.actionsTabs).length === 3 : false,
          }))
        }
      }
    },
    [tabState.tabPermission],
  )

  useEffect(() => {
    scrollIntoView({ alignment: 'center' })
    setTimeout(() => {
      const id = projectId
      if (id) {
        fetchProjectTab(id)
      }
    }, 1000)
  }, [active, projectId])

  const labels = useMemo(
    () => ({
      backToList: t('new_project_page.back_project_list'),
      editTitle: t('new_project_page.edit_project_title'),
      newTitle: t('new_project_page.new_project_title'),
      details: t('new_project_page.title_details'),
      participants: t('new_project_page.title_participants'),
      standards: t('new_project_page.title_standards'),
      standardsAndSkills: t('new_project_page.title_standards_and_skills'),
      planning: t('new_project_page.title_planning'),
    }),
    [t],
  )

  const redirectUrl = isTemplate && projectStatus == 'reviewing' ? '/projects_library' : '/projects'
  return (
    <>
      <Stack spacing={0} mb='xl' align='flex-start'>
        <TextLink iconLeft={<IconChevronLeft />} href={redirectUrl}>
          {labels.backToList}
        </TextLink>
        <Title order={1} ref={targetRef}>
          {projectId && projectId !== '' ? labels.editTitle : labels.newTitle}
        </Title>
      </Stack>
      <Stepper color='turquoise' active={active} onStepClick={setActive} breakpoint='sm'>
        <Stepper.Step allowStepSelect={tabState?.tabPermission?.tabOne} label={labels.details}>
          <ProjectDetails />
        </Stepper.Step>
        {!isTemplate && (
          <Stepper.Step allowStepSelect={tabState?.tabPermission?.tabTwo && !block} label={labels.participants}>
            <ParticipantsProvider>
              <ProjectParticipants />
            </ParticipantsProvider>
          </Stepper.Step>
        )}
        <Stepper.Step
          allowStepSelect={
            (tabState?.templateTab ? tabState?.tabPermission?.tabTwo : tabState?.tabPermission?.tabThree) && !block
          }
          label={standardsOnly ? labels.standards : labels.standardsAndSkills}
        >
          <SkillsStandardsProvider>
            <ProjectSkillsStandards />
          </SkillsStandardsProvider>
        </Stepper.Step>
        <Stepper.Step
          allowStepSelect={
            (tabState?.templateTab ? tabState?.tabPermission?.tabThree : tabState?.tabPermission?.tabFour) && !block
          }
          label={labels.planning}
        >
          <LessonsProvider>
            <ProjectPlanning />
          </LessonsProvider>
        </Stepper.Step>
      </Stepper>
    </>
  )
}
