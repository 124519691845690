import { Flex } from '../../../../components/Flex'
import { Title } from '../../../../components/Title'
import { Text } from '../../../../components/Text'
import { Badge } from '../../../../components/Badge'
import { Space } from '@mantine/core'
import { isEmpty } from 'lodash'
import { ActivityProps } from '../../interfaces'
import { useMediaQuery } from '@mantine/hooks'

export const ActivityDetail = ({ planning, title, specificSkillsAndContents }: ActivityProps) => {
  const largeScreen = useMediaQuery('(min-width: 800px)')

  return (
    <Flex direction={'column'} mb='md'>
      <Title order={5} mb='xs'>
        {title}
      </Title>
      <Text>{planning}</Text>
      <Space mb='xs' />
      {specificSkillsAndContents.map(skill => {
        return (
          <Flex key={skill.name} mb='xs' direction={largeScreen ? 'row' : 'column'}>
            <Badge sx={{ backgroundColor: `${skill.color}` }} variant='filled'>
              {skill.name}
            </Badge>
            {!isEmpty(skill.originalCodes) && (
              <Text fw='bold' ml='xs' mt={largeScreen ? 0 : 'xs'}>{`${skill.originalCodes} -`}</Text>
            )}
            <Text span ml='xs'>
              {skill.lv1Name}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
