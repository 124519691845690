import { Layout } from 'components/Layout'
import TabProvider from './context/students-provider'
import TabNavigation from './components/TabNavigation'
import StudentList from './components/StudentList'
import WrapFixedButtons from './components/WrapFixedButtons'
import WrapPagination from './components/WrapPagination'
import WrapInputSearch from './components/WrapInputSearch'
import { LumiarToastContainer } from '@legacy/common/_components/LumiarToast'
import { Divider } from '@mantine/core'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { WrapTitle } from './components/WrapTitle'
import { NotificationProvider } from 'context'


export const Students = () => {
  return (
    <CurrentUserContextProvider>
      <NotificationProvider>
        <Layout>
          <TabProvider>
            <WrapTitle />
            <Divider my="sm" />
            <WrapInputSearch />
            <TabNavigation />
            <StudentList />
            <WrapPagination />
            <WrapFixedButtons />
            <LumiarToastContainer />
          </TabProvider>
        </Layout>
      </NotificationProvider>
    </CurrentUserContextProvider>
  )
}
