import { IOptions } from '@legacy/common/Select'
import {
  AxiosErrorResponseFunction,
  RequestSubmitResponse,
} from '@legacy/common/types/axios'
import {
  lumiarToastError,
  lumiarToastSuccess,
} from '@legacy/common/_components/LumiarToast'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'

export interface FormHookResult<T, E> {
  readonly payload: T
  readonly errors: E
  readonly loading: boolean
  readonly change: (event: React.FormEvent<HTMLInputElement>) => void
  readonly updatePayload: (value: T) => void
  readonly changeDate: (name: keyof T, value: string) => void
  readonly changeErrors: Dispatch<SetStateAction<E>>
  readonly select: (name: keyof T, value: string | IOptions) => void
  readonly check: (event: React.FormEvent<HTMLInputElement>) => void
  readonly submit: (
    onSubmit: (
      payload: T,
      onError: AxiosErrorResponseFunction<E>
    ) => Promise<RequestSubmitResponse<T>>
  ) => Promise<RequestSubmitResponse<T>>
}

interface FormHookProps<T, E> {
  readonly initial: T
  readonly initialErrors: E
}

export function useForm<T, E>({
  initial,
  initialErrors,
}: FormHookProps<T, E>): FormHookResult<T, E> {
  const [payload, changePayload] = useState<T>(initial)
  const [loading, changeLoading] = useState(false)
  const [errors, changeErrors] = useState<E>(initialErrors)

  const clean = useCallback(() => {
    changeErrors(initialErrors)
    changePayload(initial)
  }, [])

  const change = useCallback(({ target }) => {
    changePayload((old) => ({
      ...old,
      [target.name]: target.value,
    }))
  }, [])

  const changeDate = useCallback((name, value) => {
    changePayload((old) => ({
      ...old,
      [name]: value.format('YYYY-MM-DD'),
    }))
  }, [])

  const select = useCallback((name, value) => {
    changePayload((old) => ({
      ...old,
      [name]: value === null ? '' : value,
    }))
  }, [])

  const check = useCallback(({ target }) => {
    changePayload((old) => ({
      ...old,
      [target.id]: target.checked,
    }))
  }, [])

  const handleError = useCallback(
    (err: { title?: string; errors?: E; error?: string }) => {
      if (err.error) {
        lumiarToastError(err.error)
      }

      if (err.title) {
        lumiarToastError(err.title)
      }

      if (err.errors) {
        changeErrors(err.errors)
      }
    },
    []
  )

  const submit = useCallback(
    async (
      onSubmit: (
        prop: T,
        onError: AxiosErrorResponseFunction<E>
      ) => Promise<RequestSubmitResponse<T>>
    ) => {
      changeLoading(true)
      const response = await onSubmit(payload, handleError)
      changeLoading(false)
      if (response !== false && response.title) {
        lumiarToastSuccess(response.title)
        clean()
      }
      return response
    },
    [payload]
  )

  return {
    payload,
    errors,
    loading,
    change,
    updatePayload: (value) => changePayload(value),
    changeErrors,
    changeDate,
    select,
    check,
    submit,
  }
}
