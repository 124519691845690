import { useEffect, useState } from 'react'
import ErrorService from '@legacy/common/Tools/ErrorService'

const TIMEOUT_TIME_MS = 3000
const onlinePollingInterval = 10000
const PING_RESOURCE = '/robots.txt'

async function timeout(time: number, promise: Promise<unknown>) {
  const promised = await new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Request timed out.'))
    }, time)
    promise.then(resolve, reject)
  })

  return promised
}

async function checkOnlineStatus() {
  const controller = new AbortController()
  const { signal } = controller

  try {
    await timeout(
      TIMEOUT_TIME_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      }),
    )
    return true
  } catch (error) {
    ErrorService.notice(error)
    controller.abort()
  }
  return false
}

export function useOfflineEvent() {
  const [offline, setOffline] = useState(false)

  useEffect(() => {
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    const id = setInterval(() => {
      if (offline) {
        checker()
      }
    }, onlinePollingInterval)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)

      clearInterval(id)
    }
  }, [])

  const checker = () => {
    checkOnlineStatus()
      .then(online => setOffline(!online))
      .catch(ErrorService.notice)
  }

  function handleOffline() {
    setOffline(true)
  }
  function handleOnline() {
    setOffline(false)
  }

  return {
    isOffline: offline,
  }
}
