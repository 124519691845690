import Styled from 'styled-components'

export const Container = Styled.div`
  input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;

    & + label {
      cursor: pointer;
      text-indent: -9999px;
      font-size: 0;
      width: 52px;
      height: 20px;
      background: grey;
      display: block;
      border-radius: 100px;
      position: relative;
      border: 1px solid #d3d3d3;

      &:after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0px;
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 90px;
        transition: all 0.5s ease-in-out;

        &:active {
          width: 52px;
        }
      }
    }

    &:checked + label {
      background: var(--color-primary);

      &:after {
        left: calc(100% - 0px);
        transform: translateX(-100%);
      }
    }

    &:disabled + label {
      background: var(--color-gray-light);
      cursor: not-allowed;

      &:after {
        background: var(--color-gray-light);
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
      }
    }
  }
`
export default {}
