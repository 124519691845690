import { Skeleton, Grid, Stack } from 'components'

export const ProjectCardListSkeleton = () => {
  return (
    <Stack spacing='md' mt='md'>
      <Grid>
        {Array.from({ length: 6 }).map((_, i) => (
          <Grid.Col span={12} md={6} lg={4} key={i}>
            <Skeleton height={400} />
          </Grid.Col>
        ))}
      </Grid>
    </Stack>
  )
}
