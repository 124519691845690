import { useTranslation } from 'react-i18next'
import { useOfflineEvent } from './offline-banner-hook'
import { StyledOffLineBanner } from './offline-banner-styled'

export function OfflineBanner() {
  const { t } = useTranslation()
  const { isOffline } = useOfflineEvent()

  if (isOffline) {
    return (
      <StyledOffLineBanner>
        <p>{t('account_signIn_accessibility_offline')}</p>
      </StyledOffLineBanner>
    )
  }

  return <></>
}