import { createStyles, rem } from '@mantine/core'
import { RichTextAreaProps } from './interface'

export const useStyles = createStyles((theme, { minHeight, maxHeight }: RichTextAreaProps) => ({
  RichTextEditor: {
    minHeight: rem(minHeight),
  },
  toolbar: {
    height: 'auto',
    padding: `${rem(4)} ${rem(8)}`,
    justifyContent: 'space-between',
  },
  controlsGroup: {
    flexWrap: 'wrap',
    gap: rem(4),
  },
  control: {
    border: 'none',
    svg: {
      width: rem(20),
      height: rem(20),
    },
  },
  content: {
    padding: 0,
    '.ProseMirror': {
      padding: rem(8),
      minHeight: rem(minHeight ? minHeight - 40 : 140),
      overflowY: 'auto',
      maxHeight: rem(maxHeight ? maxHeight : 400),
      border: 'none',
    },
    '.ProseMirror-focused': {
      border: `1px solid ${theme.colors.turquoise[4]}`,
      padding: rem(8),
      minHeight: rem(minHeight ? minHeight - 40 : 140),
      overflowY: 'auto',
      maxHeight: rem(maxHeight ? maxHeight : 400),
    },
    fontSize: theme.fontSizes.md,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
  },
}))
