import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollArea, useMediaQuery, useMantineTheme } from 'hooks'
import { Modal, Stack, Title, Text, BreadCrumbCard, Box, Divider } from 'components'
import { LessonsContext } from '../../context/LessonsContext'
import { ActivityDetailsSkeleton } from './ActivityDetailsSkeleton'
import { getActivity } from 'pages/NewProject/service/projects'
import Planning from '../../../../@legacy/common/Activities/components/Planning'
import GDriveList from '@legacy/common/_components/gdriveList'
import parse from 'html-react-parser'
import dayjs from 'dayjs'
import { extractBreadcrumb } from 'helpers'
import { ILvl4Item } from 'interfaces'

export const ActivityDetailsModal = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { openedActivityDetailsModal, closeActivityDetailsModal, activiyId, saveActivity } = useContext(LessonsContext)
  const [detailsLoaded, setDetailsLoaded] = useState(false)
  const [activityDetails, setActivityDetails] = useState<any>({})

  useEffect(() => {
    const fetchAndSaveActivity = async (id: string) => {
      try {
        const activityData = await getActivity(id)
        setActivityDetails(activityData)
        saveActivity(activityData)
        setDetailsLoaded(true)
      } catch (error) {
        console.error(`Error fetching activity with id ${id}:`, error)
      }
    }

    if (activiyId.length > 0) {
      setDetailsLoaded(false)
      fetchAndSaveActivity(activiyId)
    }
  }, [openedActivityDetailsModal])

  return (
    <Modal
      size='xl'
      opened={openedActivityDetailsModal}
      onClose={closeActivityDetailsModal}
      title={t('new_project_page.title_activity_details')}
      scrollAreaComponent={ScrollArea.Autosize}
      fullScreen={isMobile}
    >
      <Box>
        {detailsLoaded ? (
          <>
            <Title order={3} mb='md'>
              {activityDetails.title}
            </Title>
            <Stack spacing='sm'>
              <Title order={4}>{t('new_project_page.title_skills')}</Title>
              {activityDetails.skills && activityDetails.skills.length > 0 ? (
                activityDetails.skills.map((item: ILvl4Item) => {
                  if (!item.breadcrumb) return null
                  const breadcrumb =
                    item.lv1Name +
                    ' > ' +
                    extractBreadcrumb(item.breadcrumb, 0) +
                    ' > ' +
                    extractBreadcrumb(item.breadcrumb, 1)
                  return (
                    <Box key={item.id}>
                      <BreadCrumbCard
                        breadcrumb={breadcrumb}
                        color={item.color}
                        showCyclesBadges
                        cycles={item.cycles}
                      />
                      <Box pb='xs'>
                        {item.originalCodes ? (
                          <Text span fw={500}>
                            {item.originalCodes} -{' '}
                          </Text>
                        ) : null}
                        {item.name ? <Text span>{item.name}</Text> : null}
                      </Box>
                    </Box>
                  )
                })
              ) : (
                <Text>{t('new_project_page.no_items_found')}</Text>
              )}
              <Title order={4}>{t('new_project_page.title_standards')}</Title>
              {activityDetails.contents && activityDetails.contents.length > 0 ? (
                activityDetails.contents.map((item: ILvl4Item) => {
                  if (!item.breadcrumb) return null
                  const breadcrumb =
                    item.lv1Name +
                    ' > ' +
                    extractBreadcrumb(item.breadcrumb, 0) +
                    ' > ' +
                    extractBreadcrumb(item.breadcrumb, 1)
                  return (
                    <Box key={item.id}>
                      <BreadCrumbCard
                        breadcrumb={breadcrumb}
                        color={item.color}
                        showCyclesBadges
                        cycles={item.cycles}
                      />
                      <Box pb='xs'>
                        {item.originalCodes ? (
                          <Text span fw={500}>
                            {item.originalCodes} -{' '}
                          </Text>
                        ) : null}
                        {item.name ? <Text span>{item.name}</Text> : null}
                      </Box>
                    </Box>
                  )
                })
              ) : (
                <Text>{t('new_project_page.no_items_found')}</Text>
              )}
            </Stack>
            <Divider my='md' />
            <Stack spacing='sm'>
              <Title order={4}>{t('new_project_page.title_planning')}</Title>
              <Text>{parse(activityDetails.planning)}</Text>
            </Stack>
            <Divider my='md' />

            <Box pb='md'>
              <Planning text='' files={activityDetails.planningFiles} hideSize />
              <GDriveList resourceId={activityDetails.id} resourceClass='session_meeting_activity_id' />
            </Box>

            {activityDetails?.studentTask?.title && (
              <Stack spacing='sm'>
                <Title order={4}>{t('new_project_page.deliverable_section_title')}</Title>
                <Stack spacing={0}>
                  <Title order={5}>{t('new_project_page.deliverable_title')}</Title>
                  <Text>{activityDetails?.studentTask?.title}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Title order={5}>{t('new_project_page.deliverable_date')}</Title>
                  <Text>{dayjs(activityDetails?.studentTask?.date).format('DD/MM/YYYY')}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Title order={5}>{t('new_project_page.deliverable_instructions')}</Title>
                  <Text>{parse(activityDetails?.studentTask?.description)}</Text>
                </Stack>

                {activityDetails?.studentTask?.id.length > 0 && (
                  <Box pb='md'>
                    <Planning text='' files={activityDetails?.studentTask?.planningFiles} hideSize />
                    <GDriveList resourceId={activityDetails?.studentTask?.id} resourceClass='student_task_id' />
                  </Box>
                )}
              </Stack>
            )}
          </>
        ) : (
          <ActivityDetailsSkeleton />
        )}
      </Box>
    </Modal>
  )
}
