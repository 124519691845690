import { Skeleton } from '../Skeleton'
import { Flex } from '../Flex'
import { Space } from '../Space'

export const OnLoadSkeleton = () => {
  return (
    <Flex pt='md' direction='column'>
      <Skeleton h={400} />
      <Space h='md' />
      <Skeleton h={30} w={200} />
      <Space h='md' />
      <Skeleton h={100} />
      <Space h='md' />
      <Skeleton h={30} w={200} />
      <Space h='md' />
      <Skeleton h={200} />
    </Flex>
  )
}
