import { createContext, useState, useEffect } from 'react'
import { useDisclosure, useNavigate } from 'hooks'
import {
  postFinalAssessment,
  postApproveProject,
  postPublishProject,
  postReopenProject,
  postFinishProject,
  deleteProject,
} from 'api'
import { ILesson, IPagination, ITeacher } from 'interfaces'
import { IProjectNew } from 'interfaces'
import { IconX, IconCheck } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { notifications, modals, useCurrentUser } from 'context'
import { Text } from 'components'
import { useFeatureToggle } from 'hooks'

interface ProjectViewContextProps {
  project: IProjectNew | null
  setProject: React.Dispatch<React.SetStateAction<IProjectNew | null>>
  openedRemoveConfirmationModal: boolean
  openRemoveConfirmationModal: () => void
  closeRemoveConfirmationModal: () => void
  handleEdit: (id: string) => void
  handleRemove: (id: string) => void
  handlePublish: (id: string) => void
  handleApprove: (id: string) => void
  handleReopen: (id: string) => void
  handleFinish: (id: string) => void
  finalAssessmentStarted: boolean
  setFinalAssessmentStarted: React.Dispatch<React.SetStateAction<boolean>>
  startFinalAssessmentModal: (id: string) => void
  handleTabChange: (value: string) => void
  lessons: ILesson[]
  setLessons: React.Dispatch<React.SetStateAction<ILesson[]>>
  error: string
  setError: React.Dispatch<React.SetStateAction<string>>
  teachers: ITeacher[] | null
  setTeachers: React.Dispatch<React.SetStateAction<ITeacher[] | null>>
  schoolTermStartAt: string
  schoolTermEndAt: string
  setSchoolTermEndAt: React.Dispatch<React.SetStateAction<string>>
  setSchoolTermStartAt: React.Dispatch<React.SetStateAction<string>>
  lessonsLoading: boolean
  setLessonsLoading: React.Dispatch<React.SetStateAction<boolean>>
  lessonsPage: number
  setLessonsPage: React.Dispatch<React.SetStateAction<number>>
  lessonsPagination: IPagination | null
  setLessonsPagination: React.Dispatch<React.SetStateAction<IPagination | null>>
  handleRemoveConfirmationClose: (id: string) => void
  getFinalAssessmentURL: (id: string) => string
  finalReportURL: (id: string) => string
}

export const ProjectViewContext = createContext<ProjectViewContextProps>({
  project: null,
  setProject: () => {},
  openedRemoveConfirmationModal: false,
  openRemoveConfirmationModal: () => {},
  closeRemoveConfirmationModal: () => {},
  handleEdit: () => {},
  handleRemove: () => {},
  handlePublish: () => {},
  handleApprove: () => {},
  handleReopen: () => {},
  handleFinish: () => {},
  finalAssessmentStarted: false,
  setFinalAssessmentStarted: () => {},
  startFinalAssessmentModal: () => {},
  handleTabChange: () => {},
  lessons: [],
  setLessons: () => {},
  error: '',
  setError: () => {},
  teachers: [],
  setTeachers: () => {},
  schoolTermEndAt: '',
  schoolTermStartAt: '',
  setSchoolTermEndAt: () => {},
  setSchoolTermStartAt: () => {},
  lessonsLoading: false,
  setLessonsLoading: () => {},
  lessonsPage: 1,
  setLessonsPage: () => {},
  lessonsPagination: {
    count: 0,
    firstUrl: '',
    from: 0,
    in: 0,
    items: 0,
    last: 1,
    lastUrl: '',
    next: null,
    nextUrl: '',
    page: 1,
    pageUrl: '',
    pages: 1,
    prev: null,
    prevUrl: '',
    scaffoldUrl: '',
    series: ['1'],
    to: 1,
    vars: {
      page: 1,
      items: 1,
      outset: 1,
      size: [],
      pageParam: '',
      params: [],
      fragment: '',
      linkExtra: '',
      i18nKey: '',
      cycle: false,
      metadata: [''],
      count: 1,
    },
  },
  setLessonsPagination: () => { },
  handleRemoveConfirmationClose: () => { },
  getFinalAssessmentURL: () => '',
  finalReportURL: () => '',
})

export const ProjectViewProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const navigate = useNavigate()
  const [project, setProject] = useState<IProjectNew | null>(null)
  const [lessons, setLessons] = useState<ILesson[]>([])
  const [teachers, setTeachers] = useState<ITeacher[] | null>(null)
  const [finalAssessmentStarted, setFinalAssessmentStarted] = useState(false)
  const [openedRemoveConfirmationModal, { open: openRemoveConfirmationModal, close: closeRemoveConfirmationModal }] =
    useDisclosure(false)
  const [error, setError] = useState<string>('')
  const [success, setSuccess] = useState<string>('')
  const [schoolTermEndAt, setSchoolTermEndAt] = useState<string>('')
  const [schoolTermStartAt, setSchoolTermStartAt] = useState<string>('')
  const [lessonsLoading, setLessonsLoading] = useState<boolean>(false)
  const [lessonsPage, setLessonsPage] = useState<number>(1)
  const [lessonsPagination, setLessonsPagination] = useState<IPagination | null>(null)

  const NewFinalProjectReport = useFeatureToggle('new_final_project_report')

  const finalReportURL = (id: string) => {
    return NewFinalProjectReport ? `/final_project_report/${id}` : `/projects/${id}/final_report`
  }

  const startFinalAssessment = async (id: string) => {
    postFinalAssessment(id)
      .then(response => {
        if (response.status === 200) {
          setFinalAssessmentStarted(true)
          setSuccess(t('new_project_view.message_final_assessment_started'))
        } else {
          setError(response.statusText)
        }
      })
      .catch(error => {
        if (error instanceof Error) {
          setError(error.message)
        }
      })
  }

  const startFinalAssessmentModal = (id: string) =>
    modals.openConfirmModal({
      size: 'lg',
      title: t('new_project_view.modal_title_start_final_assessment'),
      children: <Text size='sm'>{t('new_project_view.modal_description_start_final_assessment')}</Text>,
      labels: { confirm: t('general_buttons_start'), cancel: t('general_buttons_cancel') },
      onCancel: () => {},
      onConfirm: () => {
        startFinalAssessment(id)
      },
      confirmProps: { size: 'lg' },
      cancelProps: { size: 'lg' },
    })

  const getFinalAssessmentURL = (id: string) => {
    if (id && currentUser && currentUser.profile_used === 'student') {
      return `/projects/${id}/final_assessments`
    }
    if (id && currentUser && (currentUser.profile_used === 'tutor' || currentUser.profile_used === 'manager')) {
      return `/projects/final_assessments/${id}`
    }
    return ''
  }

  const handleTabChange = (value: string) => {
    if (value === 'Project wall' && project) {
      window.location.replace(project.actions.mural.url)
    }
  }

  const handleEdit = (id: string) => {
    if (id) {
      //TODO: Após novo fluxo de práticas em prod substituir por navigate
      window.location.replace(`/projects/${id}/edit`)
    }
  }

  const handleRemove = (id: string) => {
    if (id) {
      openRemoveConfirmationModal()
    }
  }

  const handleRemoveConfirmationClose = () => {
    if(project !== null)
      deleteProject(project.id)
        .then(response => {
          if (response.status === 200) {
            setSuccess(t('new_project_view.message_project_removed'))
            setTimeout(() => {
              navigate('/projects')
            }, 2000)
          } else {
            setError(response.statusText)
          }
        })
        .catch(error => {
          if (error instanceof Error) {
            setError(error.message)
          }
        })
    return
  }

  const handlePublish = (id: string) => {
    if (id) {
      modals.openConfirmModal({
        title: t('new_project_view.publish_confirmation_title'),
        children: (
          <Text size='md'>
            {t('new_project_view.publish_confirmation_description')}
          </Text>
        ),
        labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onCancel: () => {},
        onConfirm: () =>
          postPublishProject(id)
            .then(response => {
              if (response.status === 200) {
                setSuccess(t('new_project_view.message_project_published'))
                setTimeout(() => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }, 2000)
              } else {
                setError(response.statusText)
              }
            })
            .catch(error => {
              if (error instanceof Error) {
                setError(error.message)
              }
            }),
      })

    }
  }

  const handleApprove = (id: string) => {
    if (id) {
      modals.openConfirmModal({
        title: t('new_project_view.approve_confirmation_title'),
        children: <Text size='md'>{t('new_project_view.approve_confirmation_description')}</Text>,
        labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onCancel: () => {},
        onConfirm: () =>
          postApproveProject(id)
            .then(response => {
              if (response.status === 200) {
                setSuccess(t('new_project_view.message_project_approved'))
                setTimeout(() => {
                  window.location.reload()
                }, 2000)
              } else {
                setError(response.statusText)
              }
            })
            .catch(error => {
              if (error instanceof Error) {
                setError(error.message)
              }
            }),
      })
    }
  }

  const handleReopen = (id: string) => {
    if (id) {
      modals.openConfirmModal({
        title: t('new_project_view.reopen_confirmation_title'),
        children: <Text size='md'>{t('new_project_view.reopen_confirmation_description')}</Text>,
        labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onCancel: () => {},
        onConfirm: () =>
          postReopenProject(id)
            .then(response => {
              if (response.status === 200) {
                setSuccess(t('new_project_view.message_project_reopened'))
                setTimeout(() => {
                  window.location.reload()
                }, 2000)
              } else {
                setError(response.statusText)
              }
            })
            .catch(error => {
              if (error instanceof Error) {
                setError(error.message)
              }
            }),
      })
    }
  }

  const handleFinish = (id: string) => {
    if (id) {
      modals.openConfirmModal({
        title: t('new_project_view.finish_confirmation_title'),
        children: <Text size='md'>{t('new_project_view.finish_confirmation_description')}</Text>,
        labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onCancel: () => {},
        onConfirm: () =>
          postFinishProject(id)
            .then(response => {
              if (response.status === 200) {
                setSuccess(t('new_project_view.message_project_finished'))
                setTimeout(() => {
                  setTimeout(() => {
                    window.location.reload()
                  }, 2000)
                }, 2000)
              } else {
                setError(response.statusText)
              }
            })
            .catch(error => {
              if (error instanceof Error) {
                setError(error.message)
              }
            }),
      })

    }
  }

  const handleErrors = (error: string) => {
    if (error.includes('Cannot read properties of undefined')) {
      return t('general_error_message_data_not_loaded')
    }
    if (error.includes('Unauthorized')) {
      return t('general_error_message_unauthorized')
    }
    if (error.includes('Unprocessable Entity')) {
      return t('new_project_view.message_project_has_assessments')
    }
    return error
  }

  useEffect(() => {
    if (success && success !== '') {
      notifications.show({
        message: success,
        color: 'green',
        icon: <IconCheck />,
      })
      setSuccess('')
    }
  }, [success])

  useEffect(() => {
    if (error && error !== '') {
      notifications.show({
        title: t('general_error_title'),
        message: handleErrors(error),
        color: 'red',
        icon: <IconX />,
      })
      setError('')
    }
  }, [error])

  return (
    <ProjectViewContext.Provider
      value={{
        project,
        setProject,
        openedRemoveConfirmationModal,
        openRemoveConfirmationModal,
        closeRemoveConfirmationModal,
        handleEdit,
        handleRemove,
        handlePublish,
        handleApprove,
        handleReopen,
        handleFinish,
        finalAssessmentStarted,
        setFinalAssessmentStarted,
        startFinalAssessmentModal,
        handleTabChange,
        lessons,
        setLessons,
        error,
        setError,
        teachers,
        setTeachers,
        schoolTermEndAt,
        setSchoolTermEndAt,
        schoolTermStartAt,
        setSchoolTermStartAt,
        lessonsLoading,
        setLessonsLoading,
        lessonsPage,
        setLessonsPage,
        lessonsPagination,
        setLessonsPagination,
        handleRemoveConfirmationClose,
        getFinalAssessmentURL,
        finalReportURL,
      }}
    >
      {children}
    </ProjectViewContext.Provider>
  )
}
