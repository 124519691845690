import { useMemo } from 'react'
import { v4 as UuidV4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { Container, ToggleWrap, LabelOn, LabelOff } from './toggleStyled'
import { IToggle } from './tpggleTypes'

const Toggle = ({ value, onToggle, disabled, showLabels = false }: IToggle) => {
  const toogleId = useMemo(() => `toogle-checkbox-${UuidV4()}`, [])
  const { t } = useTranslation()

  return (
    <Container>
      {showLabels && <LabelOff checked={value}>{t('toggle_off')}</LabelOff>}
      <ToggleWrap showLabels={showLabels}>
        <input
          type='checkbox'
          id={toogleId}
          checked={value}
          onChange={onToggle}
          disabled={disabled}
        />
        <label htmlFor={toogleId}>{toogleId}</label>
      </ToggleWrap>
      {showLabels && <LabelOn checked={value}>{t('toggle_on')}</LabelOn>}
    </Container>
  )
}

export default Toggle
