import { useCallback } from 'react'
import { encode } from '../utilities/jwt'
import { today } from "../formaters/date-formatter";
import env from '../../../env.json'

export function useMetabase() {
  const url = useCallback((payload: object) => {
    const dataEnv: any = env

    const metabaseSecretKey: any = dataEnv['process.env.REACT_APP_METABASE_SECRET_KEY']
    const metabaseUrl: any = dataEnv['process.env.REACT_APP_METABASE_URL']

    const secret = metabaseSecretKey.substring(1, metabaseSecretKey.length-1)
    const url = metabaseUrl.substring(1, metabaseUrl.length-1)

    if (secret === undefined && secret === null && secret === '') return ''

    const token = encode({ ...payload, exp: today.valueOf() + 60 * 10 }, secret)

    return `${url}/embed/question/${token}#bordered=true&titled=false`

  }, [])

  return { url }
}
