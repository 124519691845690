export const guardianMenu = [
  {
    link: '/guardian_room/dashboard',
    key: 'dashboard',
    icon: 'icon-home',
  },
  {
    link: '/projects',
    key: 'projects',
    icon: 'icon-activities',
  },
  {
    link: '/end_of_term_reports',
    key: 'end_of_term_reports',
    icon: 'icon-reports',
  },
]