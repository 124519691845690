import classnames from 'classnames'
import PropTypes from 'prop-types'
import Textarea from 'react-textarea-autosize'
// import Picker, { SKIN_TONE_NEUTRAL } from 'emoji-picker-react'
import MinLength from './MinLength'

const InputTextArea = props => {
  const {
    autoFocus,
    id,
    errors,
    label,
    value,
    onChange,
    onEnter,
    onShiftEnter,
    disabled,
    placeholder,
    maxLength,
    minLength,
    rowCount,
    formFieldClassName,
    styles,
    hint,
    dataTestId,
    withEmojis
  } = props

  const checkEnter = event => {
    if (event.key === 'Enter' && event.shiftKey && onShiftEnter) {
      event.preventDefault()
      return onShiftEnter()
    }

    if (event.key === 'Enter' && !event.shiftKey && onEnter) {
      event.preventDefault()
      return onEnter(event.target.value)
    }

    return undefined
  }

  const checkMaxLength = event => {
    const customEvent = event
    const eventValue = customEvent.target.value
    if (maxLength !== 0 && eventValue.length > maxLength) customEvent.target.value = eventValue.substr(0, maxLength)

    onChange(customEvent)
  }

  const handleOnEmojiClick = (event, { emoji }) => {
    onChange(value ? `${value}${emoji}` : emoji)
  }

  const charLeft = maxLength - value.length

  const textAreaClasses = classnames('adjustable-height', styles.textArea)
  const hintClasses = classnames('hint', styles.hint)

  return (
    <div className="register-form">
      <div className={`form-field ${formFieldClassName}`}>
        {label && (
          <label className={`__label text-area ${value === '' ? 'inside' : 'top'}`} htmlFor={id}>{label}</label>
        )}
        <Textarea
          autoFocus={autoFocus}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          value={value}
          minRows={rowCount}
          className={textAreaClasses}
          onChange={checkMaxLength}
          onKeyPress={checkEnter}
          data-hj-whitelist
          data-testid={dataTestId}
        />
        {/* {withEmojis && ( */}
        {/*   <Picker */}
        {/*     onEmojiClick={handleOnEmojiClick} */}
        {/*     disableAutoFocus */}
        {/*     skinTone={SKIN_TONE_NEUTRAL} */}
        {/*     groupNames={{ smileys_people: 'PEOPLE' }} */}
        {/*     native */}
        {/*   /> */}
        {/* )} */}
        {hint && <p className={hintClasses}>{hint}</p>}
        {errors && <span className="validation-error">{errors}</span>}
        {maxLength > 0 && (
          <div className={`countdown-message ${charLeft <= 0 ? 'darkred-color' : ''}`}>
            {I18n.t(`char_limit.${charLeft === 1 ? 'one' : 'other'}`, { count: charLeft })}
          </div>
        )}
        {minLength ? <MinLength size={minLength} length={value.length} /> : <div />}
      </div>
    </div>
  )
}

InputTextArea.propTypes = {
  autoFocus: PropTypes.bool,
  id: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onShiftEnter: PropTypes.func,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  rowCount: PropTypes.number,
  formFieldClassName: PropTypes.string,
  styles: PropTypes.object,
  hint: PropTypes.string,
  dataTestId: PropTypes.string,
  withEmojis: PropTypes.bool
}

InputTextArea.defaultProps = {
  autoFocus: false,
  errors: [],
  label: undefined,
  placeholder: undefined,
  disabled: false,
  onShiftEnter: undefined,
  onEnter: undefined,
  maxLength: 0,
  minLength: 0,
  rowCount: 3,
  formFieldClassName: '',
  styles: {
    textArea: '',
    hint: '',
  },
  hint: '',
  dataTestId: '',
  withEmojis: false
}

export default InputTextArea
