import React from 'react'
import { Anchor } from 'components'
import { useStyles } from './TextLinkStyles'

interface TextLinkProps {
  children: React.ReactNode
  iconLeft?: React.ReactElement
  iconRight?: React.ReactElement
  href?: string
  fullWidth?: boolean
  disabled?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onClick?: () => void
  inline?: boolean
  style?: React.CSSProperties
}

export const TextLink = ({
  href,
  children,
  iconLeft,
  iconRight,
  fullWidth,
  disabled,
  size,
  onClick,
  inline,
  ...props
}: TextLinkProps) => {
  const { classes } = useStyles({ fullWidth, disabled, inline })

  const component = onClick ? 'button' : 'a'

  return (
    <Anchor {...props} size={size} component={component} href={href} onClick={onClick} className={classes.anchor}>
      {iconLeft && React.cloneElement(iconLeft, { className: classes.iconLeft })}
      {children}
      {iconRight && React.cloneElement(iconRight, { className: classes.iconRight })}
    </Anchor>
  )
}
