import { useMantineTheme, useMediaQuery, hasPermission } from 'hooks'
import { useTranslation } from 'react-i18next'
import { NewProjectActionMenuProps } from './interfaces'
import { Menu, Button, Tooltip } from 'components'
import {
  IconChevronDown,
  IconEdit,
  IconBookUpload,
  IconPlayerPlay,
  IconTrash,
  IconRotateRectangle,
  IconCheck,
} from '@tabler/icons-react'

export const NewProjectActionMenu = ({
  project,
  handleEdit,
  handleRemove,
  handlePublish,
  handleApprove,
  handleReopen,
  handleFinish,
}: NewProjectActionMenuProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const hasProject = project !== null && project.id !== ''
  const hasOneSkillOrContent =
    (project !== null && project.projectItems.specificSkills.length > 0) ||
    (project !== null && project.projectItems.contents.length > 0)
  const hasGroup = project !== null && project.groupName !== null && project.groupName !== ''
  const isTutor = hasPermission(['tutor'])
  const isManager = hasPermission(['manager'])
  const isTutorOrManager = hasPermission(['manager', 'tutor'])

  const isDraft = project.status === 'draft'
  const isApproved = project.status === 'approved'
  const isFinished = project.status === 'finished'

  const isApplication = project.kind === 'application'
  const isTemplate = project.kind === 'template'

  const canEdit = hasProject && isTutorOrManager && !isFinished
  const canRemove =
    (hasProject && isTutorOrManager && !isFinished && isApplication) ||
    (hasProject && isManager && isFinished && isApplication) ||
    (hasProject && isTutor && isDraft && isTemplate)
  const canApprove = hasProject && hasOneSkillOrContent && hasGroup && isTutorOrManager
  const canPublish = hasProject && hasOneSkillOrContent && isTutorOrManager
  const canFinish = hasProject && isManager && isApplication && isApproved
  const canReopen = hasProject && isFinished && isApplication && isManager

  if (project === null || !isTutorOrManager || (isTutor && isFinished)) return null

  return (
    <Menu shadow='md' width={200}>
      <Menu.Target>
        <Button fullWidth={isMobile} variant='outline' size='md' rightIcon={<IconChevronDown />}>
          {t('general_buttons_actions')}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        {canEdit && (
          <Menu.Item component='button' onClick={() => handleEdit(project.id)} icon={<IconEdit />}>
            {t('general_buttons_edit')}
          </Menu.Item>
        )}
        {isTemplate && isDraft ? (
          canPublish ? (
            <Menu.Item component='button' onClick={() => handlePublish(project.id)} icon={<IconBookUpload />}>
              {t('general_buttons_publish')}
            </Menu.Item>
          ) : (
            <Tooltip label={t('general_publish_draft_info')} withinPortal withArrow position='top'>
              <Menu.Item
                component='button'
                icon={<IconBookUpload />}
                onClick={event => event.preventDefault()}
                data-disabled
                disabled={true}
                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              >
                {t('general_buttons_publish')}
              </Menu.Item>
            </Tooltip>
          )
        ) : null}
        {isApplication && isDraft ? (
          canApprove ? (
            <Menu.Item component='button' onClick={() => handleApprove(project.id)} icon={<IconPlayerPlay />}>
              {t('general_buttons_start')}
            </Menu.Item>
          ) : (
            <Tooltip label={t('general_start_project_info')} withinPortal withArrow position='top'>
              <Menu.Item
                component='button'
                icon={<IconPlayerPlay />}
                onClick={event => event.preventDefault()}
                data-disabled
                disabled={true}
                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              >
                {t('general_buttons_start')}
              </Menu.Item>
            </Tooltip>
          )
        ) : null}
        {canReopen && (
          <Menu.Item component='button' onClick={() => handleReopen(project.id)} icon={<IconRotateRectangle />}>
            {t('general_buttons_reopen')}
          </Menu.Item>
        )}
        {isApproved ? (
          canFinish ? (
            <Menu.Item component='button' onClick={() => handleFinish(project.id)} icon={<IconCheck />}>
              {t('general_buttons_finish')}
            </Menu.Item>
          ) : (
            <Tooltip label={t('general_finish_project_info')} withinPortal withArrow position='top'>
              <Menu.Item
                component='button'
                icon={<IconCheck />}
                onClick={event => event.preventDefault()}
                data-disabled
                disabled={true}
                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
              >
                {t('general_buttons_finish')}
              </Menu.Item>
            </Tooltip>
          )
        ) : null}
        {canRemove && <Menu.Divider />}
        {canRemove && <Menu.Label>{t('general_careful')}</Menu.Label>}
        {canRemove && (
          <Menu.Item component='button' color='red' onClick={() => handleRemove(project.id)} icon={<IconTrash />}>
            {t('general_buttons_remove')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
