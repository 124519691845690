import { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'context/userAuthContext'
import GoogleIcon from '@legacy/common/Icons/Google'
import { Button } from '@mantine/core'
import {
  associateWithGoogleAccount,
  initFirebaseApp,
} from '@legacy/common/Tools/Firebase'

interface GoogleButtonProps {
  readonly disabled?: boolean
  readonly children?: never
}

export const GoogleButton = memo(({ disabled }: GoogleButtonProps) => {
  const { t } = useTranslation()
  const { login } = useAuth()

  useEffect(() => {
    initFirebaseApp()
  }, [])

  return (
    <Button
      fullWidth
      mt='md'
      size='lg'
      variant='default'
      leftIcon={<GoogleIcon />}
      disabled={disabled}
      onClick={() =>
        associateWithGoogleAccount({
          skipUpdateUserRegistrationStep: true,
          callback: login,
        })
      }
      aria-label={t('account_signIn_login_with_google')}
    >
      {t('account_signIn_login_with_google')}
    </Button>
  )
})
