import { IReceivedCurrentUser, IReceivedUsers } from '@legacy/common/models/user'

import baseRequest from './base-request'
import Paths from './service-paths'

export async function getCurrentUser () {
  const response = await baseRequest.get<{
    readonly status: 'found'
    readonly data: IReceivedCurrentUser
  }>(Paths.users.current)
  return response
}

export async function getUserBySelectedSchoolAndName (value: string) {
  const response = await baseRequest.get<IReceivedUsers>(Paths.users.search_by_selected_school_and_name(value))
  return response
}
