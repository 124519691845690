import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Group, Button, Text, Divider, Grid, Title, CopyButton, Stack } from 'components'
import { IconClipboard, IconClipboardCheck } from '@tabler/icons-react'
import { MainTeacherBlock } from 'components'
import { FinalProjectReportHeaderProps } from './interfaces'

export const FinalProjectReportHeader = ({
  project,
  teachers,
}: FinalProjectReportHeaderProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const meetingsCount = project && project.totalMeetings ? project.totalMeetings : 0
  const meetingText =
    meetingsCount && meetingsCount === 1 ? t('new_project_view.lesson') : t('new_project_view.lessons')

  return (
    <>
      {project !== null && project.id !== '' && (
        <>
          <Grid>
            <Grid.Col span={12} xs={12} sm={7}>
              <Stack spacing={0}>
                <Stack spacing={0}>
                  <Title order={2}>{t('new_final_project_report.title_final_project_report')}</Title>
                  <Title order={1}>{project.name}</Title>
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col span={12} xs={12} sm={5}>
              <Group spacing='sm' position='right'>
                <CopyButton value={window.location.href}>
                  {({ copied, copy }) => (
                    <Button
                      size='md'
                      variant='outline'
                      color={copied ? 'green' : ''}
                      onClick={copy}
                      fullWidth={isMobile}
                      leftIcon={copied ? <IconClipboardCheck /> : <IconClipboard />}
                    >
                      {copied ? t('general_buttons_copied_url') : t('general_buttons_copy_url')}
                    </Button>
                  )}
                </CopyButton>
              </Group>
            </Grid.Col>
          </Grid>
          <Divider mt='md' mb='md' />
          <Grid columns={12}>
            <Grid.Col span={12} sm={6} md={2}>
              <Text size='sm' color='dimmed'>
                {t('new_project_view.label_project_type')}
              </Text>
              <Text>{t(`modality.${project.modality}`)}</Text>
            </Grid.Col>
            <Grid.Col span={12} sm={6} md={project.kind !== 'template' ? 2 : 'auto'}>
              <Text size='sm' color='dimmed'>
                {t('new_project_view.label_project_lessons')}
              </Text>
              <Text>
                {meetingsCount} {meetingText}
              </Text>
            </Grid.Col>
            {project.kind !== 'template' && (
              <Grid.Col span={12} sm={6} md='auto'>
                <Text size='sm' color='dimmed'>
                  {t('new_project_view.label_group')}
                </Text>
                <Text>{project.groupName !== null ? project.groupName : t('new_project_view.no_group_added')}</Text>
              </Grid.Col>
            )}
            <Grid.Col span={12} sm={6} md='content'>
              {project.tutorMain && <MainTeacherBlock teachers={teachers} mainTeacher={project.tutorMain} />}
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  )
}
