/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from '../../../helpers'
import {
  ProjectProps,
  DevelopmentStageProps,
  ModalitiesAndFinalProductsProps,
  CoverImageProps,
  ProjectCreateProps,
  GetSchoolTermsProps,
  GetMyGroupsProps,
  Tab2Data,
  NewPosition,
  responseGetGroupPeople,
  MeetingCreateRequest,
  MeetingEditRequest,
  CreateBlankActivityRequest,
  MeetingRepetitionRequest,
  CreateBlankActivityTaskRequest,
} from './interfaces'

const projectPaths = {
  createProject: () => '/api/projects',
  showCoverImage: (projectId: string) => `/projects/${projectId}/show_cover_image.json`,
  deleteCoverImage: (projectId: string) => `/projects/${projectId}/destroy_cover_image`,
  getProject: (projectId: string) => `/api/projects/${projectId}`,
  getProjectTab: (projectId: string, tab: string) => `/api/projects/${projectId}/edit?tab=${tab}`,
  getDevelopmentStages: (schoolId: string) =>
    `/api/projects/development_stages?q[courses_school_id_eq]=${encodeURIComponent(schoolId)}`,
  getMyGroups: (schoolTermId: string) => `/api/group_school_terms/${schoolTermId}/my_groups`,
  getTutors: (groupSchoolTermId: string) => `/api/tutor/${groupSchoolTermId}/others_tutors`,
  getMyGroupPeople: (groupSchoolTermId: string, schoolTermId: string) =>
    `/api/group_school_terms/${groupSchoolTermId}/${schoolTermId}/people`,
  getStudentsAnotherGroups: (groupSchoolTermId: string, schoolTermId: string) =>
    `/api/group_school_terms/${groupSchoolTermId}/${schoolTermId}/students_another_groups`,
  getModalitiesAndFinalProducts: '/api/projects/modalities_and_final_products',
  getActivities: (projectIdId: string, page: number) => `/api/session_meetings/by_project/${projectIdId}?page=${page}`,
  getSchoolTerms: (projectId: string) => `/api/school_terms/${projectId}`,
  getActivity: (activityId: string) => `/api/session_meeting_activities/${activityId}`,
  changeActivityPosition: (activityId: string) =>
    `/api/session_meeting_activities/${activityId}/change_activity_position`,
  calculateDays: () => '/api/session_meetings/calculate_days',
  createMeeting: () => '/api/session_meetings',
  deleteMeeting: (meetingId: string) => `/api/session_meetings/${meetingId}`,
  removeBlankActivity: (meetingId: string) => `/api/session_meetings/${meetingId}/remove_blank_activities`,
  editMeeting: (meetingId: string) => `/api/session_meetings/${meetingId}`,
  createBlankActivity: () => '/api/session_meeting_activities/create_blank',
  createBlankActivityTask: () => '/api/session_meeting_activities/create_blank_student_task',
  showSelectedItems: (projectId: string) => `/api/projects/${projectId}/show_selected_items`,
  sessionMeetingActivityPath: (activityId: string) => `/api/session_meeting_activities/${activityId}`,
  approveProject: (projectId: string) => `/api/projects/${projectId}/approve`,
  publishProject: (projectId: string) => `/api/projects/${projectId}/publish`,
  newProject: () => '/api/projects/new',
  getEvaluationTools: (query: string) => `/api/evaluation_tools?q=${encodeURIComponent(query)}`,
  createEvaluationTool: () => '/api/evaluation_tools',
}

export const createProject = async (projectData: ProjectProps): Promise<ProjectCreateProps | undefined> => {
  try {
    const response = await api.post<ProjectCreateProps>(projectPaths.createProject(), projectData)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const updateProject = async (
  projectId: string,
  projectData: Partial<ProjectProps>,
): Promise<ProjectProps | undefined> => {
  try {
    const response = await api.patch<ProjectProps>(projectPaths.getProject(projectId), projectData)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getProject = async (projectId: string): Promise<ProjectProps | undefined> => {
  try {
    const response = await api.get<ProjectProps>(projectPaths.getProject(projectId))

    if (response.status === 401) {
      window.location.href = '/error_permission'
    }

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getProjectTab = async (projectId: string, tab: string): Promise<Tab2Data | null | undefined> => {
  try {
    const response = await api.get<Tab2Data>(projectPaths.getProjectTab(projectId, tab))
    return response.data
  } catch (error) {
    console.error(error)
  }
}

export const getDevelopmentStages = async (schoolId: string): Promise<DevelopmentStageProps[] | undefined> => {
  try {
    const response = await api.get<DevelopmentStageProps[]>(projectPaths.getDevelopmentStages(schoolId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getModalitiesAndFinalProducts = async (): Promise<ModalitiesAndFinalProductsProps | undefined> => {
  try {
    const response = await api.get<ModalitiesAndFinalProductsProps>(projectPaths.getModalitiesAndFinalProducts)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const loadCoverImage = async (projectId: string): Promise<{ coverImage: CoverImageProps } | undefined> => {
  try {
    const response = await api.get<{ coverImage: any }>(projectPaths.showCoverImage(projectId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const deleteCoverImage = async (projectId: string): Promise<{ coverImage: any } | undefined> => {
  try {
    const response = await api.delete<{ coverImage: any }>(projectPaths.deleteCoverImage(projectId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getSchoolTerms = async (projectId: string): Promise<GetSchoolTermsProps[] | undefined> => {
  try {
    const response = await api.get<GetSchoolTermsProps[]>(projectPaths.getSchoolTerms(projectId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getMyGroups = async (schoolTermId: string): Promise<GetMyGroupsProps[] | undefined> => {
  try {
    const response = await api.get<GetMyGroupsProps[]>(projectPaths.getMyGroups(schoolTermId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getGroupPeople = async (
  groupSchoolTermId: string,
  schoolTermId: string,
): Promise<responseGetGroupPeople | undefined> => {
  try {
    const response = await api.get<responseGetGroupPeople>(
      projectPaths.getMyGroupPeople(groupSchoolTermId, schoolTermId),
    )
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getStudentsAnotherGroups = async (
  groupSchoolTermId: string,
  schoolTermId: string,
): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.getStudentsAnotherGroups(groupSchoolTermId, schoolTermId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getActivities = async (projectIdId: string, page: number): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.getActivities(projectIdId, page))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getTutors = async (groupSchoolTermId: string): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.getTutors(groupSchoolTermId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const calculateDays = async (payload: MeetingRepetitionRequest): Promise<any> => {
  try {
    const response = await api.post<any>(projectPaths.calculateDays(), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const createMeeting = async (payload: MeetingCreateRequest): Promise<any> => {
  try {
    const response = await api.post<any>(projectPaths.createMeeting(), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const deleteMeeting = async (meetingId: string): Promise<any> => {
  try {
    const response = await api.delete<any>(projectPaths.deleteMeeting(meetingId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const editMeeting = async (meetingId: string, payload: MeetingEditRequest): Promise<any> => {
  try {
    const response = await api.put<any>(projectPaths.editMeeting(meetingId), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const changeActivityPosition = async (activityId: string, payload: NewPosition): Promise<any | undefined> => {
  try {
    const response = await api.put<any>(projectPaths.changeActivityPosition(activityId), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const createBlankActivity = async (payload: CreateBlankActivityRequest): Promise<any> => {
  try {
    const response = await api.post<any>(projectPaths.createBlankActivity(), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const createBlankActivityTask = async (payload: CreateBlankActivityTaskRequest): Promise<any> => {
  try {
    const response = await api.post<any>(projectPaths.createBlankActivityTask(), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const updateActivity = async (activityId: string, payload: any): Promise<any> => {
  try {
    const response = await api.patch<any>(projectPaths.sessionMeetingActivityPath(activityId), payload)
    return response
  } catch (error: unknown) {
    console.error(error)
  }
}

export const deleteActivity = async (activityId: string): Promise<any> => {
  try {
    const response = await api.delete<any>(projectPaths.sessionMeetingActivityPath(activityId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const removeBlankActivity = async (meetingId: string): Promise<any> => {
  try {
    const response = await api.delete<any>(projectPaths.removeBlankActivity(meetingId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const getActivity = async (activityId: string): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.getActivity(activityId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const showSelectedItems = async (activityId: string): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.showSelectedItems(activityId))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const approveProject = async (projectId: string): Promise<any | undefined> => {
  try {
    const response = await api.post<any>(projectPaths.approveProject(projectId))
    return response
  } catch (error: unknown) {
    console.error(error)
  }
}

export const publishProject = async (projectId: string): Promise<any | undefined> => {
  try {
    const response = await api.post<any>(projectPaths.publishProject(projectId))
    return response
  } catch (error: unknown) {
    console.error(error)
  }
}

export const newProject = async (): Promise<any | undefined> => {
  try {
    const response = await api.get<any>(projectPaths.newProject())

    if (response.status === 401) {
      window.location.href = '/error_permission'
    }

    return response.data
  } catch (error: any) {
    window.location.href = '/error_permission'
  }
}

export const getEvaluationTools = async (query: string): Promise<any[] | undefined> => {
  try {
    const response = await api.get<any[]>(projectPaths.getEvaluationTools(query))
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}

export const createEvaluationTool = async (name: string): Promise<any | undefined> => {
  try {
    const payload = { evaluation_tool: { name } }
    const response = await api.post<any>(projectPaths.createEvaluationTool(), payload)
    return response.data
  } catch (error: unknown) {
    console.error(error)
  }
}
