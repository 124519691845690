import { useContext, memo, FC } from 'react'
import { useTranslation } from 'react-i18next'
import TabContext from '../context/students-context'
import CircularLoader from 'components/CircularLoader/CircularLoader'
import { ManageStudentCard } from '../../../components/ManageStudentCard/ManageStudentCard'
import { EmptyStateBox } from 'components'
import { Wrap, WrapLoading } from './styled'
import { IStudent } from '../types/student-types'
import { ITabContext } from '../types/student-types'
import { IconArticleOff } from '@tabler/icons-react'

const MemoizedStudentCard = memo(ManageStudentCard)

const StudentList: FC = () => {
  const { t } = useTranslation()
  const { students, loading } = useContext<ITabContext>(TabContext)

  return loading ? (
    <WrapLoading>
      <CircularLoader size={20} color='var(--color-primary)' thickness={2} time={1} />
    </WrapLoading>
  ) : !students.length ? (
    <EmptyStateBox withBorder text={t('feed_no_items')} icon={<IconArticleOff />} p='lg' textSize='md' />
  ) : (
    <Wrap>
      {students.map((student: IStudent) => (
        <MemoizedStudentCard key={student.id} student={student} />
      ))}
    </Wrap>
  )
}

export default StudentList
