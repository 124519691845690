import { useCallback } from 'react'

import useMixPanel from '@legacy/common/hooks/mix-panel-hook'

function withTracking(Component) {
  function wrapper(props) {
    const mixpanel = useMixPanel()

    const handleTrack = useCallback((eventName, eventProps) => {
      try {
        mixpanel.track(eventName, eventProps)
      } catch (err) {}
    }, [mixpanel])

    return <Component {...props} mixPanelTracker={handleTrack} />
  }

  return wrapper
}

export default withTracking
