import { Badge, Tooltip } from 'components'
import { useTranslation } from 'react-i18next'
import { IBadgeDeveloped } from './interfaces'
import { IconUsers } from '@tabler/icons-react'


export const BadgeDeveloped = ({ color, developed, totalStudents }: IBadgeDeveloped) => {
  const { t } = useTranslation()

  return (
    <Tooltip
      label={t('new_project_page.student_developed_tooltip')}
      zIndex={10}
      withinPortal
      withArrow
      multiline
      width={240}
    >
      <Badge
        radius='xl'
        component='button'
        size='lg'
        variant='filled'
        leftSection={<IconUsers size={16} />}
        styles={{
          leftSection: {
            display: 'flex',
            marginRight: '0.5rem',
          },
        }}
        sx={{
          backgroundColor: color,
        }}
      >
        ({developed}/{totalStudents})
      </Badge>
    </Tooltip>
  )
}
