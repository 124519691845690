/* global I18n */
/* eslint consistent-return: off */
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { formatFileSize } from "../_shared/FileUploadHelper";
import Button from "../_atoms/Button";
import { useTranslation } from "react-i18next";

const FileViewer = (props) => {
  const { fileData, thumbSize, color, className, onClick, showPreview } = props;

  const { t } = useTranslation();

  return (
    <div
      className={`file-thumb ${className} ${thumbSize} color-${color} ${
        onClick && "cursor-pointer"
      }`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      {fileData.fileType === "image" && (
        <img src={fileData.fileCover} alt="cover_image" width="100%"/>
      )}
      {[
        "image/gif",
        "image/webp",
        "image/jpeg",
        "image/png",
        "image/bmp",
      ].includes(fileData.type) && (
        <img src={URL.createObjectURL(fileData)} alt="cover_image" width="100%"/>
      )}

      {fileData.fileType === "video" && (
        <div className="video-player">
          <ReactPlayer
            url={fileData.downloadUrl}
            width="100%"
            height="100%"
            controls={showPreview}
          />
          {!showPreview && <div className="icon-play" />}
        </div>
      )}

      {(fileData.fileType === "audio" || fileData.fileType === "file") && (
        <div className="file-icon">
          <i
            className={`icon-${
              fileData.fileType === "audio" ? "mic" : "files"
            }`}
          />
          <div className="file-name">{fileData.name}</div>
          {fileData.sizeInBytes && (
            <div className="file-size">
              {formatFileSize(fileData.sizeInBytes)}
            </div>
          )}

          {fileData.fileType === "audio" && showPreview && (
            <ReactPlayer
              url={fileData.downloadUrl}
              width="80%"
              height="40px"
              controls
            />
          )}

          {fileData.fileType === "file" && showPreview && (
            <Button
              type="light"
              label={t("general_buttons_download")}
              icon="download"
              onClick={fileData.downloadUrl}
              className="mt-4"
            />
          )}
        </div>
      )}
    </div>
  );
};

const conditionalRequired = (props, propName, _componentName) => {
  if (
    props.fileType === "image" &&
    (props[propName] === undefined || typeof props[propName] !== "string")
  ) {
    return new Error("Please provide a fileCover!");
  }
};

FileViewer.propTypes = {
  fileData: PropTypes.shape({
    fileCover: conditionalRequired,
    fileType: PropTypes.oneOf(["file", "image", "video", "audio"]).isRequired,
    name: PropTypes.string,
    sizeInBytes: PropTypes.number,
    downloadUrl: PropTypes.string,
    type: PropTypes.oneOf([
      "image/gif",
      "image/webp",
      "image/jpeg",
      "image/png",
      "image/bmp",
    ]),
  }).isRequired,
  thumbSize: PropTypes.oneOf([
    "small",
    "medium",
    "large",
    "xlarge",
    "responsible",
  ]),
  color: PropTypes.oneOf(["light", "medium"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  showPreview: PropTypes.bool,
};

FileViewer.defaultProps = {
  thumbSize: "medium",
  color: "light",
  className: "",
  onClick: null,
  showPreview: false,
};

export default FileViewer;
