import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { DropdownMenuRadioGroup } from 'components/DropdownMenu'
import { IconLanguage } from 'components/Icons'
import { DropdownMenuSeparatorStyled, DropdownMenuRadioItemStyled, LinkStyled } from '../NavbarMenuStyled'

import { DropdownMenuItemLanguageIndicatorStyled } from './SwitchLanguageStyled'

interface ISwitchLanguage {
  currentLocale: string | undefined
  setCurrentLocale: (lng: string | undefined) => void | null
}

export const SwitchLanguage = ({ currentLocale, setCurrentLocale }: ISwitchLanguage) => {
  const { t, i18n } = useTranslation()

  const changeLanguage = async (lng: string) => {
    await axios.get(`/api/menu?locale=${lng}`)
    i18n.changeLanguage(lng)
    setCurrentLocale(lng)
  }

  return (
    <>
      <DropdownMenuRadioGroup value={currentLocale} onValueChange={changeLanguage}>
        <DropdownMenuRadioItemStyled value='en'>
          <DropdownMenuItemLanguageIndicatorStyled>
            <IconLanguage />
          </DropdownMenuItemLanguageIndicatorStyled>
          <LinkStyled>{t('models_languages.en')}</LinkStyled>
        </DropdownMenuRadioItemStyled>
        <DropdownMenuRadioItemStyled value='pt-BR'>
          <DropdownMenuItemLanguageIndicatorStyled>
            <IconLanguage />
          </DropdownMenuItemLanguageIndicatorStyled>
          <LinkStyled>{t('models_languages.pt-BR')}</LinkStyled>
        </DropdownMenuRadioItemStyled>
      </DropdownMenuRadioGroup>
      <DropdownMenuSeparatorStyled />
    </>
  )
}
