import { useCallback, useEffect, useMemo, useState } from 'react'
import _, { isEmpty } from 'lodash'
import useApiData from "../../../hooks/api-data-hook";
import { useMetabase } from "../../../hooks/metabase-hook";
import useMixPanel from "../../../hooks/mix-panel-hook";
import { getCyclesAndCurriculumsByStudent, getStudentsByName, ICyclesAndCurriculumsByStudent } from '../../../services/students-service'
import ErrorService from "../../../Tools/ErrorService";
import { IInputSelectOption, IPayload, QuestionNumbers } from './reports-student-interfaces'
import { getPayloadParams } from './reports-student-utilities'
import {
  fromCycleToOption,
  fromGradeToOption,
} from './reports-student-component'
import { useTranslation } from 'react-i18next';

export interface ISelectedPayload {
  readonly studentId: string
  readonly cycleId?: string
  readonly gradeId?: string
  readonly level1: string
}

const inititalSelected: ISelectedPayload = {
  studentId: '',
  cycleId: undefined,
  gradeId: undefined,
  level1: 'all'
}

export function useReportsStudent(isLumiarSchool: boolean) {
  const { t } = useTranslation();
  const students = useApiData(getStudentsByName());
  const mixpanel = useMixPanel();
  const [selected, setSelected] = useState<ISelectedPayload>(inititalSelected);
  const [data, setData] = useState<ICyclesAndCurriculumsByStudent>();

  const loadData = async (studentId: string) => {
    getCyclesAndCurriculumsByStudent(studentId)
      .then((response) => {
        if (response) {
          setData(response);
          setSelected((old) => {
            if (response.items.length && response.items[0].cycles) {
              return {
                ...old,
                cycleId:
                  response.current_cycle ?? response.items[0].cycles[0].id,
                level1: "all",
              };
            }
            if (response.items.length && response.items[0].grades) {
              return {
                ...old,
                gradeId:
                  response.current_grade ?? response.items[0].grades[0].id,
                level1: "all",
              };
            }

            return old;
          });
        }
      })
      .catch(ErrorService.notice);
  };

  useEffect(() => {
    setSelected((old) => ({ ...old, level1: "all" }));
  }, [selected.cycleId]);

  const cycles: IInputSelectOption[] = useMemo(() => {
    if (isLumiarSchool && data?.items.length && data.items[0].cycles) {
      return _.flatten(data?.items.map((d) => d.cycles)).map(fromCycleToOption);
    }

    return [];
  }, [data]);

  const grades: IInputSelectOption[] = useMemo(() => {
    if (!isLumiarSchool && data?.items.length && data.items[0].grades) {
      return _.flatten(data.items.map((d) => d.grades)).map(fromGradeToOption);
    }

    return [];
  }, [data]);

  const allLv1: IInputSelectOption = {
    label: t("schools_deleted_status_all"),
    id: "all",
  };
  const lv1: IInputSelectOption[] = useMemo(() => {
    if (isLumiarSchool) {
      return [
        allLv1,
        ...(data?.items
          .find((d) => d.cycles?.find((c) => c.id === selected.cycleId))
          ?.lv1.map(fromCycleToOption) ?? []),
      ];
    }

    return [
      allLv1,
      ...(data?.items
        .find((d) => d.grades?.find((c) => c.id === selected.gradeId))
        ?.lv1.map(fromCycleToOption) ?? []),
    ];
  }, [data, selected]);

  const curriculum = useMemo(
    () => {
      if(!isLumiarSchool) return data?.items.find((d) => d.grades?.find((c) => c.id === selected.gradeId))?.curriculum

      return data?.items.find((d) => d.cycles?.find((c) => c.id === selected.cycleId))
        ?.curriculum
    },
    [data, selected]
  );

  const curriculumSchoolId = useMemo(
    () => {
      if(!isLumiarSchool) return data?.items.find((d) => d.grades?.find((c) => c.id === selected.gradeId))?.curriculum_school_id
      
      return data?.items.find((d) => d.cycles?.find((c) => c.id === selected.cycleId))
        ?.curriculum_school_id
    },
    [data, selected]
  );


  const payload = useMemo((): IPayload => {
    const isNotSelectedLevel1 =
      !selected.level1 || ["all", ""].includes(selected.level1);
    const getQuestionNumber = (): QuestionNumbers => {
      if (isNotSelectedLevel1) {
        return isLumiarSchool ? 122 : 126;
      }

      return !isLumiarSchool ? 127 : 125;
    };

    return getPayloadParams(
      getQuestionNumber(),
      selected,
      curriculumSchoolId,
      curriculum
    );
  }, [selected]);

  return {
    url: useMetabase().url(payload),
    students,
    cycles,
    grades,
    selected,
    selectStudent: useCallback(
      ({ value }: { value: string }) => {
        if (!isEmpty(value) && value !== selected.studentId) {
          setSelected((old) => ({ ...old, studentId: value }));
          loadData(value).catch(ErrorService.notice);
          mixpanel.track("Alterar filtro estudante - Relatório do estudante");
        } else {
          mixpanel.track("Limpa filtro estudante - Relatório do estudante");
        }
      },
      [setSelected]
    ),
    resetStudent: useCallback(() => {
      setSelected(inititalSelected);
    }, [setSelected]),
    lv1,
    changeSelectedCycleId: useCallback(
      (cycleId: string) => {
        setSelected((old) => ({
          ...old,
          cycleId,
          level1: cycleId !== old.cycleId ? "all" : old.level1,
        }));
        mixpanel.track("Alterar ciclo/ano - Relatório do estudante");
      },
      [setSelected]
    ),
    changeSelectedGradeId: useCallback(
      (gradeId: string) => {
        setSelected((old) => ({
          ...old,
          gradeId,
          level1: gradeId !== old.gradeId ? "all" : old.level1,
        }));
        mixpanel.track("Alterar ciclo/ano - Relatório do estudante");
      },
      [setSelected]
    ),
    changeSelectedLevel1: useCallback(
      (level1: string) => {
        setSelected((old) => ({ ...old, level1 }));
        mixpanel.track("Alterar area - Relatório do estudante");
      },
      [setSelected]
    ),
  };
}
