import { lumiarToastError } from '@legacy/common/_components/LumiarToast'

import firebase from 'firebase/app'
import axios from 'axios'
import i18n from '../../../i18n'

import 'firebase/analytics'
import 'firebase/auth'

const userExists = async email => {
  const response = await axios.get(`/api/users/find?email=${email}`)
  return response.data.status === 'found'
}

const internalLogin = async () => {
  await axios.post('/api/auth/sign_in', { firebaseUser: window.firebaseUser })
  window.location.href = '/'
}

const firebaseSignOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {})
    .catch(_error => {})
}

const updateUserLoginPreferences = async email => axios.get(`/api/users/update_login_to_firebase?email=${email}`)

const updateUserRegistrationStep = async (userId, skipUpdateUserRegistrationStep = false) => {
  if (skipUpdateUserRegistrationStep) {
    return
  }
  axios.get(`/api/users/update_step?email=${userId}&step=1`)
}

const deleteFirebaseUser = user => {
  user
    .delete()
    .then(() => {})
    .catch(_error => {})
}

const firebaseGoogleSignUp = async (
  event = undefined,
  skipUpdateUserRegistrationStep = false,
  callback = user => {},
) => {
  const provider = new firebase.auth.GoogleAuthProvider()
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async result => {
      const { user } = result
      if (await userExists(user.email)) {
        const response = await updateUserLoginPreferences(user.email)
        await updateUserRegistrationStep(user.email, skipUpdateUserRegistrationStep)
        await internalLogin()
        callback(user)
      } else {
        deleteFirebaseUser(user)
        lumiarToastError(i18n.t('swal_error_user_does_not_exist_on_platform'))
      }
    })
    .catch(error => {
      lumiarToastError(error)
    })
}

export const associateWithGoogleAccount = ({ skipUpdateUserRegistrationStep = false, callback = user => {} }) => {
  firebaseGoogleSignUp('create', skipUpdateUserRegistrationStep, callback)
}

const initFirebaseAuth = () => {
  firebase.auth().onAuthStateChanged(user => {
    window.firebaseUser = user
  })
}

export const initFirebaseApp = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyDmARs3bs4pATx-8PZuoFQ35PA4zzFlx-A',
    authDomain: 'plataforma-educacional-lumiar.firebaseapp.com',
    projectId: 'plataforma-educacional-lumiar',
    storageBucket: 'plataforma-educacional-lumiar.appspot.com',
    messagingSenderId: '694989785408',
    appId: '1:694989785408:web:340e5d29de13f83e6492a5',
    measurementId: 'G-RZ51JH4ZS6',
  }
  if (firebase.apps.length <= 0) {
    firebase.initializeApp(firebaseConfig)
  }
  firebase.analytics()
  initFirebaseAuth()
}
