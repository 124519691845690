import styled from 'styled-components'

export const StudentsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin-top: 1rem;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    line-height: 1.2;
  }

  .rodal-close:before,
  .rodal-close:after {
    background-color: var(--color-black);
  }

  .rodal-close:before,
  .rodal-close:before {
    background-color: var(--color-black);
  }
`

export const ButtonMask = styled.button`
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-indent: 0;
  display: flex;
  cursor: pointer;
`
export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 2rem;
`
