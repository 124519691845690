import { SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, notifications, UseFormReturnType } from 'context'

import { LessonsContext } from '../../context/LessonsContext'
import { useMantineTheme, useMediaQuery, useToggle } from 'hooks'
import {
  Modal,
  Stack,
  Box,
  Grid,
  Button,
  TextInput,
  Checkbox,
  Title,
  Text,
  Anchor,
  RichTextArea,
  Divider,
  DateInput,
  Transition,
  Flex,
  DayRender,
} from 'components'
import { CardSkillsStandardsActivity } from '../CardSkillsStandardsActivity'
import { IActivityModal } from './interfaces'
import { IconX, IconCheck } from '@tabler/icons-react'
import { useStyles } from './styles'
import { ILvl4Item } from 'interfaces'
import { CriteriaModal } from '../CriteriaModal'
import {
  createBlankActivity,
  getActivity,
  updateActivity,
  createBlankActivityTask,
  removeBlankActivity,
} from '../../../../pages/NewProject/service/projects'
import { v4 as Uuidv4 } from 'uuid'
import { IconBooks, IconMoodCog } from '@tabler/icons-react'
import WrappFiles from './FileUploader/WrappFiles'
import WrappTaskFiles from './FileUploader/WrappTaskFiles'
import dayjs from 'dayjs'
import { modals } from '@mantine/modals'
import { FocusTrap } from '@mantine/core'
import { ConfirmAssessmentChangeModal } from 'components/ConfirmAssessmentChangeModal'
import { Loading } from '@legacy/common/_components'

export interface IActivityFormValues {
  title: string
  planning?: string
  has_assessments?: boolean
  student_task_title?: string
  student_task_description?: string
  student_task_date?: string | number | Date
}

const initialValues = {
  title: '',
  planning: '',
  has_assessments: false,
  student_task_title: '',
  student_task_description: '',
  student_task_date: '',
}

export const ActivityModal = ({ projectID, activity, skills, contents, onSaveActivity }: IActivityModal) => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [selectSkillsAction, toggleSelectSkillsAction] = useToggle([true, false])
  const [selectStandardsAction, toggleSelectStandardsAction] = useToggle([true, false])
  const [assessedActivities, setAssessedActivities] = useState<boolean>(false)
  const [deliverableChecked, setDeliverableChecked] = useState<boolean>(false)
  const [temporaryId, setTemporaryId] = useState<string>('')
  const [initialSkills, setInitialSkills] = useState<ILvl4Item[]>([])
  const [initialContents, setInitialContents] = useState<ILvl4Item[]>([])
  const [lv4Items, setActivityLv4Items] = useState<any[]>([])
  const [editedCriteria, setEditedCriteria] = useState<any[]>([])
  const [temporaryTaskId, setTemporaryTaskId] = useState<string>('')
  const dateValueFormat = i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  const [initalStateFromServer, setInitalStateFromServer] = useState<any>(null)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isNewActivity, setIsNewActivity] = useState<boolean>(false)
  const [planningError, setPlanningError] = useState<boolean>(false)
  const [showAssessmentModal, setShowAssessmentModal] = useState<boolean>(false)

  const successMessage = t('new_project_page.activity_added_message')
  const editedSuccessMessage = t('new_project_page.activity_edited_message')
  const successColor = 'green'
  const successIcon = <IconCheck size={24} />
  const errorMessage = t('new_project_page.activity_added_error_message')
  const errorColor = 'red'
  const errorIcon = <IconX size={24} />
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const {
    openedActivityModal,
    closeActivityModal,
    activitySkills,
    setActivitySkills,
    activityStandards,
    setActivityStandards,
    selectedSkillsHandler,
    selectedStandardsHandler,
    selectedSkills,
    selectedStandards,
    parentLessonId,
    editActivity,
    setEditActivity,
  } = useContext(LessonsContext)

  const handleChangeAllSkills = useCallback(() => {
    if (selectSkillsAction) {
      selectedSkillsHandler.setState(activitySkills)
    } else {
      selectedSkillsHandler.setState([])
    }
  }, [selectSkillsAction, activitySkills, selectedSkills])

  const handleChangeAllStandards = useCallback(() => {
    if (selectStandardsAction) {
      selectedStandardsHandler.setState(activityStandards)
    } else {
      selectedStandardsHandler.setState([])
    }
  }, [selectStandardsAction, activityStandards, selectedStandards])

  const handleToggleAllSkills = useCallback(() => {
    handleChangeAllSkills()
    toggleSelectSkillsAction()
  }, [selectSkillsAction, handleChangeAllSkills])

  const handleToggleAllStandards = useCallback(() => {
    handleChangeAllStandards()
    toggleSelectStandardsAction()
  }, [selectStandardsAction, handleChangeAllStandards])

  useEffect(() => {
    setActivitySkills(skills)
    setActivityStandards(contents)
  }, [skills, contents])

  useEffect(() => {
    setActivitySkills(skills)
    setActivityStandards(contents)
  }, [])

  const handleEvaluativeCriteria = (value: any) => {
    const index = editedCriteria.findIndex((item: any) => item._id === value._id)

    setEditedCriteria(
      index > -1 ? editedCriteria.map((item, i) => (i === index ? value : item)) : [...editedCriteria, value],
    )
  }

  const setFormFields = (
    res: { title: any; planning: any },
    form: UseFormReturnType<IActivityFormValues, (values: IActivityFormValues) => IActivityFormValues>,
  ) => {
    form.setFieldValue('title', res.title)
    form.setFieldValue('planning', res.planning)
  }

  const handleAssessments = (
    res: { hasAssessments: boolean | ((prevState: boolean) => boolean) },
    form: UseFormReturnType<IActivityFormValues, (values: IActivityFormValues) => IActivityFormValues>,
  ) => {
    if (typeof res.hasAssessments === 'boolean') {
      form.setFieldValue('has_assessments', res?.hasAssessments)
      setAssessedActivities(res.hasAssessments)
    }
  }

  const handleTasks = (
    res: {
      hasTasks: any
      studentTask: { id: SetStateAction<string>; title: any; description: any; date: string | number | Date }
    },
    form: UseFormReturnType<IActivityFormValues, (values: IActivityFormValues) => IActivityFormValues>,
  ) => {
    if (res.hasTasks) {
      setDeliverableChecked(true)
      setTemporaryTaskId(res.studentTask?.id)

      form.setFieldValue('student_task_title', res.studentTask?.title)
      form.setFieldValue('student_task_description', res.studentTask?.description)
      if (res.studentTask?.date) form.setFieldValue('student_task_date', new Date(res.studentTask?.date))
    } else {
      setDeliverableChecked(false)
      setTemporaryTaskId('')
    }
  }

  const setInitialStates = (res: {
    contents: SetStateAction<ILvl4Item[]>
    skills: SetStateAction<ILvl4Item[]>
    activityLv4Items: SetStateAction<any[]>
  }) => {
    if (activitySkills.length === res.skills.length) {
      toggleSelectSkillsAction()
    }

    if (activityStandards.length === res.contents.length) {
      toggleSelectStandardsAction()
    }

    selectedStandardsHandler.setState(res?.contents)
    selectedSkillsHandler.setState(res?.skills)
    setInitialSkills(res?.skills)
    setInitialContents(res?.contents)
    setActivityLv4Items(res.activityLv4Items)
  }

  useEffect(() => {
    if (openedActivityModal) {
      const id = projectID
      const hasEditActivity = editActivity && editActivity.id

      if (hasEditActivity) setIsEditing(true)

      if (hasEditActivity) {
        setTemporaryId(editActivity.id)
        getActivity(editActivity.id).then(res => {
          setInitalStateFromServer(res)
          setFormFields(res, form)
          handleAssessments(res, form)
          handleTasks(res, form)
          setInitialStates(res)
          setIsLoading(false)
        })
      } else {
        form.reset()
      }

      if (id && !hasEditActivity) {
        createBlankActivity({
          session_meeting_id: parentLessonId,
          project_id: id,
        }).then(res => {
          setTemporaryId(res.id)
          setIsNewActivity(true)
          setIsLoading(false)
        })
      }
    }

    if (!openedActivityModal) {
      if (!selectSkillsAction) {
        toggleSelectSkillsAction()
      }

      if (!selectStandardsAction) {
        toggleSelectStandardsAction()
      }
    }
  }, [openedActivityModal])

  const form = useForm<IActivityFormValues>({
    initialValues,
    validate: {
      title: value => (!value && value.length < 3 ? t('required_field') : null),
      planning: value => {
        const strippedValue = value ? value.replace(/<[^>]*>/g, '') : ''
        if (!strippedValue || strippedValue.length < 3) {
          setPlanningError(true)
          return t('required_field')
        }
        setPlanningError(false)
        return null
      },
      has_assessments: () => {
        if (!assessedActivities) return null

        const isEmptySkillsAndStandards = selectedSkills.length === 0 && selectedStandards.length === 0
        const lv4NewItems = [...transformToLv4Items(selectedSkills), ...transformToLv4Items(selectedStandards)]
        const lv4NewIds = lv4NewItems.map(item => item.lv4_item_id)
        const removedItems = [...initialContents, ...initialSkills].filter(item => !lv4NewIds.includes(item.id))
        const itemsToRemove = findItemsToRemove(lv4Items, removedItems)

        if (isEmptySkillsAndStandards && itemsToRemove.length === 0) {
          return t('missing_skills')
        }

        if (
          itemsToRemove.length === initalStateFromServer?.skills?.length + initalStateFromServer?.contents?.length &&
          isEmptySkillsAndStandards
        ) {
          return t('missing_skills')
        }
      },
      ...(deliverableChecked && {
        student_task_title: value => (!value || value.length < 3 ? t('required_field') : null),
        student_task_date: value => (!value || String(value).length < 3 ? t('required_field') : null),
        student_task_description: value => {
          const strippedValue = value ? value.replace(/<[^>]*>/g, '') : ''
          return !strippedValue || strippedValue.length < 3 ? t('required_field') : null
        },
      }),
    },
  })

  const handleForm = async (values: IActivityFormValues) => {
    const errors = form.validate()

    if (errors.hasErrors) {
      handleErrors(errors)
    } else {
      onSaveActivity()
      handleSuccess(values)
    }
  }

  const handleErrors = (errors: unknown) => {
    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: t('fill_the_required_fields'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const transformToLv4Items = (inputItems: any) => {
    const transformedItems: any[] = inputItems.map((item: any) => {
      return {
        lv4_item_id: item.id,
      }
    })

    return transformedItems
  }

  const findItemsToRemove = (
    initialData: { id: string; lv4ItemId: string }[] | null | undefined,
    dataToRemove: { id: string }[] | null | undefined,
  ): { id: string; _destroy: number }[] => {
    if (!Array.isArray(initialData) || !Array.isArray(dataToRemove)) return []

    const result = initialData
      .filter(
        item =>
          item &&
          typeof item === 'object' &&
          dataToRemove.some(
            removeItem => removeItem && typeof removeItem === 'object' && removeItem.id === item.lv4ItemId,
          ),
      )
      .map(item => ({
        id: item.id,
        _destroy: 1,
      }))

    return result
  }

  const lv4ItemsAtributesPayload = () => {
    const lv4NewItems = [...transformToLv4Items(selectedSkills), ...transformToLv4Items(selectedStandards)]
    const idsToFilter = [...initialContents, ...initialSkills].map(item => item.id)
    const filteredLv4NewItems = lv4NewItems.filter(item => !idsToFilter.includes(item.lv4_item_id))

    const lv4NewIds = lv4NewItems.map(item => item.lv4_item_id)
    const removedItems = [...initialContents, ...initialSkills].filter(item => !lv4NewIds.includes(item.id))
    const itemsToRemove = findItemsToRemove(lv4Items, removedItems)

    const mergedPayloadWithLv4ItemsChangedAndToRemove = [...filteredLv4NewItems, ...itemsToRemove]

    const addsEvaluateCriteriaToPayload = (p: any, curricular: any[]) => {
      return curricular.reduce(
        (acc, curricularItem) => {
          const index = acc.findIndex((item: { lv4_item_id: any }) => item.lv4_item_id === curricularItem._id)

          if (index > -1) {
            acc[index] = {
              ...acc[index],
              assessment_instrument_id: null,
              activity_lv4_item_how_to_assesses_attributes: curricularItem.activity_lv4_item_how_to_assesses_attributes,
            }
          } else {
            const obj = lv4Items?.find((objeto: { lv4ItemId: any }) => objeto.lv4ItemId === curricularItem._id)
            acc.push({
              id: obj?.id,
              assessment_instrument_id: null,
              activity_lv4_item_how_to_assesses_attributes: curricularItem.activity_lv4_item_how_to_assesses_attributes,
            })
          }

          return acc
        },
        [...p],
      )
    }

    return addsEvaluateCriteriaToPayload(mergedPayloadWithLv4ItemsChangedAndToRemove, editedCriteria)
  }

  const handleSuccess = async (values: any) => {
    const payload = {
      id: temporaryId,
      project_id: projectID,
      title: values.title,
      planning: values.planning,
      has_assessments: assessedActivities,
      activity_lv4_items_attributes: lv4ItemsAtributesPayload(),
      ...(deliverableChecked === true
        ? {
            has_tasks: true,
            student_task_attributes: {
              id: temporaryTaskId,
              title: values?.student_task_title,
              description: values?.student_task_description,
              date: dayjs(values?.student_task_date).format('YYYY-MM-DD'),
            },
          }
        : { has_tasks: false }),
    }

    const response = await updateActivity(temporaryId, payload)

    if (response.status !== 200) {
      notifications.show({
        message: errorMessage,
        color: errorColor,
        icon: errorIcon,
      })

      return
    }

    setEditActivity(null)
    setIsNewActivity(false)
    closeActivityModal()

    const notifyMessage = isEditing ? editedSuccessMessage : successMessage

    notifications.show({
      message: notifyMessage,
      color: successColor,
      icon: successIcon,
    })
  }

  useEffect(() => {
    if (!isNewActivity) {
      resetValues()
    }
  }, [isNewActivity])

  const updateField = (name: string) => (value: string) => {
    form.setFieldValue(name, value)
    if (name === 'planning' && planningError) {
      setPlanningError(false)
    }
  }

  const resetValues = () => {
    setTemporaryId('')
    setInitialSkills([])
    setInitialContents([])
    setDeliverableChecked(false)
    setAssessedActivities(false)
    closeActivityModal()
    setPlanningError(false)
    form.reset()
    form.resetDirty()
    setIsLoading(true)

    if (isNewActivity) {
      removeBlankActivity(parentLessonId)
      setIsNewActivity(false)
    }
  }

  const formDidChanges = () => {
    const changelv4 = lv4ItemsAtributesPayload() || []
    if (changelv4.length > 0) return true

    const initialFormData = initialValues

    if (!isEditing) {
      const emptyPString = '<p></p>'
      if (form.values.has_assessments !== initialFormData.has_assessments) return true

      if (form.values.planning !== initialFormData.planning && form.values.planning !== emptyPString) return true

      return (
        form.values.title !== initialFormData.title ||
        (deliverableChecked &&
          (form.values.student_task_title !== initialFormData.student_task_title ||
            form.values.student_task_description !== initialFormData.student_task_description))
      )
    } else {
      if (form.values.has_assessments !== initialFormData.has_assessments) return true
      return (
        form.values.title !== initalStateFromServer.title ||
        form.values.planning !== initalStateFromServer.planning ||
        form.values.has_assessments !== initalStateFromServer.hasAssessments ||
        (deliverableChecked &&
          (form.values.student_task_title !== initalStateFromServer.studentTask?.title ||
            form.values.student_task_description !== initalStateFromServer.studentTask?.description))
      )
    }

    return false
  }

  const onClose = () => {
    const hasChanges = formDidChanges()

    if (hasChanges) {
      modals.openConfirmModal({
        title: t('general_changed_confirmation_modal_title'),
        size: 'lg',
        children: <Text size='sm'>{t('general_changed_confirmation_modal_message')}</Text>,
        labels: {
          confirm: t('new_project_page.confirm'),
          cancel: t('new_project_page.warnning_groupt_button1'),
        },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onConfirm: resetValues,
      })
    } else {
      resetValues()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (deliverableChecked && projectID && projectID !== '' && projectID !== null) {
        const id = await createBlankActivityTask({
          id: temporaryId,
          project_id: projectID,
        })
        return id
      }
    }
    if (deliverableChecked && temporaryTaskId === '') {
      fetchData().then(res => {
        setTemporaryTaskId(res.id)
      })
    }
  }, [deliverableChecked])

  const searchItemIfItExistsbyEditingCriteriaId = (editingCriteria: string) => {
    if (!Array.isArray(editedCriteria) || !Array.isArray(lv4Items)) {
      return null
    }

    return (
      editedCriteria.find((item: any) => item._id === editingCriteria) ||
      lv4Items.find((item: any) => item.lv4ItemId === editingCriteria) ||
      null
    )
  }

  useEffect(() => {
    if (activityStandards.length === selectedStandards.length) {
      toggleSelectStandardsAction(false)
    } else {
      toggleSelectStandardsAction(true)
    }
  }, [selectedStandards])

  useEffect(() => {
    if (activitySkills.length === selectedSkills.length) {
      toggleSelectSkillsAction(false)
    } else {
      toggleSelectSkillsAction(true)
    }
  }, [selectedSkills])

  const handleAssessmentChange = () => {
    const hasScores = initalStateFromServer?.hasScores || false

    if (assessedActivities && hasScores) {
      setShowAssessmentModal(true)
      return
    }

    setAssessedActivities(prevState => !prevState)
  }

  return (
    <Modal
      key={activity ? activity.id : temporaryId}
      zIndex={150}
      size='lg'
      opened={openedActivityModal}
      onClose={onClose}
      title={activity ? t('new_project_page.edit_activity') : t('new_project_page.add_activity')}
      fullScreen={true}
      classNames={classes}
    >
      {isLoading && <Loading />}

      {!isLoading && (
        <>
          <CriteriaModal
            handleEvaluativeCriteria={handleEvaluativeCriteria}
            lv4Items={lv4Items}
            editedCriteria={editedCriteria}
            searchItem={searchItemIfItExistsbyEditingCriteriaId}
          />
          <ConfirmAssessmentChangeModal
            opened={showAssessmentModal}
            onClose={() => setShowAssessmentModal(false)}
            action={() => setAssessedActivities(prevstate => !prevstate)}
          />
          <div>
            <FocusTrap active>
              <Stack className={classes.stack}>
                <TextInput
                  data-autofocus
                  name='title'
                  size='md'
                  label={t('new_project_page.activity_title_label')}
                  withAsterisk
                  {...form.getInputProps('title')}
                />
                <Stack spacing={0}>
                  <div
                    style={
                      planningError
                        ? {
                            border: '1px solid red',
                            borderRadius: '10px',
                          }
                        : {}
                    }
                  >
                    <RichTextArea
                      name='planning'
                      required
                      size='md'
                      label={t('new_project_page.activity_planning_label')}
                      description={t('new_project_page.activity_planning_description')}
                      withAsterisk
                      {...form.getInputProps('planning')}
                      onChange={updateField('planning')}
                    />
                  </div>
                  {planningError && (
                    <Text color='red' size='sm'>
                      {t('required_field')}
                    </Text>
                  )}
                </Stack>
                <Divider my='xs' />
                {temporaryId && temporaryId.length > 0 && projectID && projectID !== '' && (
                  <WrappFiles activityId={temporaryId} projectId={projectID} />
                )}
                {(activitySkills && activitySkills.length > 0) ||
                (activityStandards && activityStandards.length > 0) ? (
                  <Stack spacing={0}>
                    <Title order={4}>{t('new_project_page.activity_assessment_title')}</Title>
                    <Text color='dimmed'>{t('new_project_page.activity_assessment_description')}</Text>
                    <Checkbox
                      mt={4}
                      size='lg'
                      label={t('new_project_page.activity_assessment_checkbox_label')}
                      checked={assessedActivities}
                      {...form.getInputProps('has_assessments')}
                      onChange={handleAssessmentChange}
                    />
                  </Stack>
                ) : null}
                {activitySkills && activitySkills.length > 0 && (
                  <>
                    <Stack spacing={0} key={temporaryTaskId}>
                      <Title order={4}>{t('new_project_page.title_curriculum_items')}</Title>
                      <Text color='dimmed'>{t('new_project_page.activity_curriculum_items_description')}</Text>
                    </Stack>
                    <Stack align='flex-start' spacing='sm'>
                      <Flex>
                        <IconMoodCog size='1.5rem' />
                        <Title order={5} ml='xs'>
                          {t('new_project_page.title_skills')}
                        </Title>
                      </Flex>
                      <Anchor align='left' component='button' onClick={() => handleToggleAllSkills()}>
                        {selectSkillsAction
                          ? t('new_project_page.activity_add_all_items')
                          : t('new_project_page.activity_remove_all_items')}
                      </Anchor>
                    </Stack>
                  </>
                )}

                <Grid>
                  {activitySkills &&
                    activitySkills.length > 0 &&
                    activitySkills.map(item => {
                      return (
                        <Grid.Col key={Uuidv4()} span={12} xs={12} sm={6} md={6} lg={4}>
                          <CardSkillsStandardsActivity
                            item={item}
                            assessed={assessedActivities}
                            editedCriteria={editedCriteria}
                            type='skill'
                            isEditing={searchItemIfItExistsbyEditingCriteriaId(item.id)}
                          />
                        </Grid.Col>
                      )
                    })}
                </Grid>

                {activityStandards && activityStandards.length > 0 && (
                  <>
                    <Stack align='flex-start' spacing='sm' key={temporaryTaskId + '2'}>
                      <Flex>
                        <IconBooks size='1.5rem' />
                        <Title order={5} ml='xs'>
                          {t('new_project_page.title_standards')}
                        </Title>
                      </Flex>
                      <Anchor align='left' component='button' onClick={() => handleToggleAllStandards()}>
                        {selectStandardsAction
                          ? t('new_project_page.activity_add_all_items')
                          : t('new_project_page.activity_remove_all_items')}
                      </Anchor>
                    </Stack>
                  </>
                )}

                <Grid>
                  {activityStandards &&
                    activityStandards.length > 0 &&
                    activityStandards.map(item => {
                      return (
                        <Grid.Col key={Uuidv4()} span={12} xs={12} sm={6} md={6} lg={4}>
                          <CardSkillsStandardsActivity
                            item={item}
                            assessed={assessedActivities}
                            editedCriteria={editedCriteria}
                            type='content'
                            isEditing={searchItemIfItExistsbyEditingCriteriaId(item.id)}
                          />
                        </Grid.Col>
                      )
                    })}
                </Grid>
                <Divider my='xs' />
                <Stack spacing={0}>
                  <Title order={4}>{t('new_project_page.activity_deliverable_title')}</Title>
                  <Text color='dimmed'>{t('new_project_page.activity_deliverable_description')}</Text>
                  <Checkbox
                    checked={deliverableChecked}
                    onChange={() => setDeliverableChecked(!deliverableChecked)}
                    mt={4}
                    size='lg'
                    label={t('new_project_page.activity_deliverable_checkbox_label')}
                  />
                </Stack>
                <Transition mounted={deliverableChecked} transition='fade' duration={300} timingFunction='ease'>
                  {styles => (
                    <Stack spacing='md' style={styles}>
                      <TextInput
                        name='student_task_title'
                        size='md'
                        label={t('new_project_page.activity_deliverable_title_label')}
                        withAsterisk
                        required
                        {...form.getInputProps('student_task_title')}
                      />
                      <DateInput
                        name='student_task_date'
                        size='md'
                        label={t('new_project_page.activity_deliverable_date_label')}
                        withAsterisk
                        locale={i18n.language}
                        required
                        valueFormat={dateValueFormat}
                        minDate={dayjs().toDate()}
                        renderDay={DayRender}
                        {...form.getInputProps('student_task_date')}
                      />
                      <RichTextArea
                        name='student_task_description'
                        required
                        size='md'
                        label={t('new_project_page.activity_deliverable_instructions_label')}
                        withAsterisk
                        {...form.getInputProps('student_task_description')}
                      ></RichTextArea>
                      {temporaryTaskId.length > 0 && projectID !== null && (
                        <>
                          <WrappTaskFiles key={temporaryTaskId} taskId={temporaryTaskId} projectId={projectID} />
                        </>
                      )}
                    </Stack>
                  )}
                </Transition>
              </Stack>

              <Box className={classes.footer}>
                <Grid gutter={isMobile ? 'sm' : 'md'} justify='right' p={0} m={0}>
                  <Grid.Col span={isMobile ? 12 : 'content'}>
                    <Button fullWidth={isMobile} variant='default' size='lg' onClick={onClose}>
                      {t('general_buttons_cancel')}
                    </Button>
                  </Grid.Col>
                  <Grid.Col span={isMobile ? 12 : 'content'}>
                    <Button
                      type='button'
                      fullWidth={isMobile}
                      variant='filled'
                      size='lg'
                      onClick={() => handleForm(form.values)}
                    >
                      {t('general_buttons_save')}
                    </Button>
                  </Grid.Col>
                </Grid>
              </Box>
            </FocusTrap>
          </div>
        </>
      )}
    </Modal>
  )
}
