import { useTranslation } from 'react-i18next'
import { AssessmentInfoProps } from './interfaces'
import { useStyles } from './styles'
import { Stack, Group, Text, Tooltip, Grid, Avatar } from 'components'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import { getInitials } from 'helpers'
import { IconHands } from 'components/Icons'
import { isEmpty } from 'lodash'

export const AssessmentInfo = ({
  title,
  score,
  info,
  userName,
  avatarURL,
  report,
  rubrics,
}: AssessmentInfoProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()


  return (
    <Stack spacing='xs' className={classes.stack}>
      <Group spacing='xs'>
        <Text size='sm' color='dimmed'>
          {title}
        </Text>
        {info && (
          <Tooltip
            withArrow
            withinPortal
            multiline
            width={300}
            label={info}
            position='top'
            events={{ hover: true, focus: false, touch: true }}
          >
            <IconHelpCircleFilled className={classes.icon} />
          </Tooltip>
        )}
      </Group>
      <Grid gutter='xs' align='center'>
        <Grid.Col span='content'>
          <IconHands width={36} height={36} color='black' value={score} />
        </Grid.Col>
        <Grid.Col span='auto'>
          <Text fw='bold' size='md'>
            {!isEmpty(rubrics) ? rubrics[score] || t(`rubric_${score}`) : t(`rubric_${score}`)}
          </Text>
        </Grid.Col>
        {userName && userName !== '' && (
          <Grid.Col span='content'>
            <Tooltip label={userName} withArrow position='top'>
              <Avatar size='md' src={avatarURL} alt={userName}>
                {getInitials(userName)}
              </Avatar>
            </Tooltip>
          </Grid.Col>
        )}
      </Grid>
      {report && report !== '' && (
        <Stack spacing={0}>
          <Text size='sm' color='dimmed'>
            {t('partial_assessments_report_title')}
          </Text>
          <Text>{report}</Text>
        </Stack>
      )}
    </Stack>
  )
}
