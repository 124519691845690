import { api } from '../../helpers'
import { IGroupProps, ILessonProps } from 'interfaces'

export const getMyGroups = async (): Promise<IGroupProps[]> => {
  try {
    const response = await api.get<IGroupProps[]>('/api/students/my_groups')
    return response.data
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.href = '/error_permission'
    }
    return Promise.reject('Error getting students list')
  }
}

export const getMySessionMeetings = async (): Promise<ILessonProps[]> => {
  try {
    const response = await api.get<any[]>('/api/students/my_session_meetings')
    return response.data
  } catch (error: any) {
    if (error.response.status === 401) {
      window.location.href = '/error_permission'
    }
    return Promise.reject('Error getting students list')
  }
}
