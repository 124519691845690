interface IGates {
  key: string
  name: string
  value: boolean
}

export interface IFeature {
  key: string
  state: string
  gates: IGates[]
}

