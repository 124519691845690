import { Paper } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { PaperStyled } from './styled'
import { Flex } from 'components/Flex'
import { useMediaQuery } from '@mantine/hooks'
import { Button } from 'components/Button'
import { PasswordInput } from 'components/PasswordInput'
import { Space } from 'components/Space'
import { useEffect, useState, useMemo } from 'react'
import { validateToken, updatePassword } from './service'

export const BodyChangePasswordScreen = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string | null>(null)
  const [confirmationError, setConfirmationError] = useState<string | null>(null)
  const token = window.location.search.match(/reset_password_token=([^&]+)/)?.[1] || ''

  useEffect(() => {
    async function checkTokenValidity() {
      const isValidToken: any = await validateToken(token)

      if (typeof isValidToken === 'object' && isValidToken?.errors === 'not_found') {
        window.location.href = '/forgot-password?error=not_found'
      }
    }

    checkTokenValidity()
  }, [])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const data = {
      reset_password_token: token,
      password,
      password_confirmation: passwordConfirmation,
    }

    try {
      await updatePassword(data)
      window.location.href = '/account/signin?success=change_sucess'
    } catch (error) {
      window.location.href = '/account/signin?success=not_changed'
    }
  }

  const validatePassword = () => {
    setPasswordError(
      password.length > 0 && password.length < 6 ? t('forgot_password.password_minimum_length_error') : null,
    )
  }

  const validateConfirmation = () => {
    setConfirmationError(passwordConfirmation !== password ? t('forgot_password.passwords_do_not_match') : null)
  }

  const isSubmitDisabled = useMemo(() => {
    return password.length < 6 || passwordConfirmation !== password
  }, [password, passwordConfirmation])

  return (
    <Flex justify='center'>
      <PaperStyled>
        <Paper shadow='md' radius='md' p='xl'>
          <form onSubmit={handleSubmit}>
            <PasswordInput
              id='input-temporary-password'
              label={t('forgot_password.new_password')}
              size='md'
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
              onBlur={validatePassword}
              error={passwordError}
              withAsterisk
            />
            <Space h='sm' />
            <PasswordInput
              id='input-temporary-password-confirm'
              label={t('forgot_password.new_password_confirm')}
              size='md'
              value={passwordConfirmation}
              onChange={e => setPasswordConfirmation(e.currentTarget.value)}
              onBlur={validateConfirmation}
              error={confirmationError}
              withAsterisk
            />
            <Flex justify='right'>
              <Button radius='xl' size='lg' type='submit' mt='lg' fullWidth={isMobile} disabled={isSubmitDisabled}>
                {t('general_buttons_save')}
              </Button>
            </Flex>
          </form>
        </Paper>
      </PaperStyled>
    </Flex>
  )
}
