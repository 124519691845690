import React, { useContext } from 'react'
import { IconCalendarPlus } from '@tabler/icons-react'
import { Flex, Title, Text, Button } from 'components'
import { useTranslation } from 'react-i18next'
import { LessonsContext } from 'components'

const LessonHeader = (): JSX.Element => {
  const { t } = useTranslation()
  const { handleAddLesson, lessons } = useContext(LessonsContext)

  return (
    <Flex justify='space-between' align='center' mb='lg'>
      <Flex direction='column'>
        <Title order={2}>{t('new_project_page.title_planning')}</Title>
        <Text color='dimmed'>{t('new_project_page.description_planning')}</Text>
      </Flex>

      {lessons.length > 0 && (
        <Button leftIcon={<IconCalendarPlus />} size='lg' variant='light' onClick={handleAddLesson}>
          {t('new_project_page.add_lesson')}
        </Button>
      )}
    </Flex>
  )
}

export default LessonHeader
