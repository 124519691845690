import { Stack, Text } from 'components'
import { IEmptyStateBox } from './interfaces'
import { useStyles } from './styles'

export function EmptyStateBox({
  icon,
  text,
  spacing,
  textSize,
  withBorder,
  actionButton,
  ...props
}: IEmptyStateBox) {
  const { classes } = useStyles({ withBorder })

  return (
    <Stack align='center' justify='center' spacing={spacing || 'xs'} className={classes.emptystatebox} {...props}>
      {icon}
      <Text size={textSize || 'sm'} className={classes.text} ta='center'>
        {text}
      </Text>
      {actionButton}
    </Stack>
  )
}
