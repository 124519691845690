/* eslint-disable */
const Google = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M17.6428 9.20669C17.6428 8.59489 17.5932 7.97979 17.4874 7.37791H8.99829V10.8437H13.8596C13.6579 11.9614 13.0097 12.9502 12.0606 13.5785V15.8273H14.9608C16.6639 14.2598 17.6428 11.9449 17.6428 9.20669Z"
          fill="#4285F4"
        />
        <path
          d="M8.99843 18C11.4258 18 13.4728 17.203 14.9643 15.8273L12.064 13.5786C11.2571 14.1275 10.2154 14.4384 9.00174 14.4384C6.65377 14.4384 4.66296 12.8543 3.94865 10.7246H0.955811V13.0428C2.48364 16.0819 5.59553 18 8.99843 18Z"
          fill="#34A853"
        />
        <path
          d="M3.9452 10.7246C3.5682 9.60683 3.5682 8.39647 3.9452 7.2787V4.96049H0.955667C-0.320834 7.50358 -0.320834 10.4997 0.955667 13.0428L3.9452 10.7246Z"
          fill="#FBBC04"
        />
        <path
          d="M8.99843 3.56163C10.2815 3.54179 11.5217 4.02461 12.4509 4.91089L15.0205 2.34135C13.3934 0.813518 11.234 -0.0264599 8.99843 -3.85207e-06C5.59553 -3.85207e-06 2.48364 1.91806 0.955811 4.9605L3.94534 7.2787C4.65634 5.14569 6.65046 3.56163 8.99843 3.56163Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="17.6428" height={18} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Google;
