import { useContext, useEffect } from 'react'
import { useCurrentUser } from 'context/useCurrentUser'
import { useTranslation } from 'react-i18next'
import FeedCard from 'pages/Feed/components/FeedCard'
import { Heading, Stack, EmptyStateBox, Skeleton, Tabs, Group, Box } from 'components'
import { FeedCamelCased } from 'pages/Feed/serviceFeed'
import { FeedListContext } from 'pages/Feed/contexts/FeedListContext'
import { useMemo } from 'react'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { PaginationWarning } from './components/PaginationWarning'
import { IconArticleOff, IconLockSquare, IconMessageCircle } from '@tabler/icons-react'
import { NewObservationButton } from './components/NewObservationButton'
import { SkeletonList } from './components/SkeletonList'

export const FeedList = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const {
    tabState,
    publicFeed,
    loadMore,
    activeTab,
    setActiveTab,
    visibility,
    setVisibility,
    privateFeed,
    loading,
    pagination,
  } = useContext(FeedListContext)
  const privateFeedMemo = memoList(privateFeed)
  const publicFeedMemo = memoList(publicFeed)
  const paginationEnd = pagination[tabState]?.next === null

  const loadNextPage = () => {
    if (pagination[tabState]?.next !== null) {
      loadMore()
    }
    return
  }

  useBottomScrollListener(loadNextPage, {
    offset: 500,
  })

  const getEmpty = (state: string) => {
    if (state === 'private') {
      return privateFeed.length !== 0
    }
    return publicFeed.length !== 0
  }

  function memoList(feedList: FeedCamelCased[]) {
    const mapFeedList = () =>
      feedList.map((feed: FeedCamelCased) => {
        return <FeedCard feed={feed} key={feed.feedId} />
      })

    return useMemo(
      () =>
        feedList.length ? (
          mapFeedList()
        ) : (
          <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('feed_no_items')} />
        ),
      [feedList],
    )
  }

  useEffect(() => {
    if (currentUser?.profile_used !== 'tutor' && currentUser?.profile_used !== 'manager') {
      setVisibility(false)
      setActiveTab('public')
    } else {
      setVisibility(true)
      setActiveTab('private')
    }
  }, [currentUser])

  return (
    <Box mt='md'>
      <Group position='apart'>
        <Heading size='xl' text={t('feed_title')}></Heading>
        {visibility ? <NewObservationButton /> : null}
      </Group>
      <Tabs value={activeTab} onTabChange={setActiveTab} mt='md'>
        <Tabs.List mb='md'>
          <Tabs.Tab sx={{ display: visibility ? '' : 'none' }} value='private' icon={<IconLockSquare size='1.4rem' />}>
            {loading ? <Skeleton height={20} width={70} /> : t('feed_private')}
          </Tabs.Tab>
          <Tabs.Tab value='public' icon={<IconMessageCircle size='1.4rem' />}>
            {loading ? <Skeleton height={20} width={70} /> : t('feed_public')}
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel sx={{ display: visibility ? '' : 'none' }} value='private'>
          <Stack spacing='md'>{loading ? <SkeletonList /> : privateFeedMemo}</Stack>
          {!loading && privateFeed.length !== 0 && paginationEnd && getEmpty(tabState) && <PaginationWarning />}
        </Tabs.Panel>
        <Tabs.Panel value='public'>
          <Stack spacing='md'>{loading ? <SkeletonList /> : publicFeedMemo}</Stack>
          {!loading && publicFeed.length !== 0 && paginationEnd && getEmpty(tabState) && <PaginationWarning />}
        </Tabs.Panel>
      </Tabs>
    </Box>
  )
}
