import { Paper, Avatar, Button, Grid, Stack, Group, Text, Badge, BadgeCycle, Anchor } from 'components'
import { getInitials } from 'helpers'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { useTranslation } from 'react-i18next'
import { IconUser, IconChartDonut3 } from '@tabler/icons-react'
import { StudentHeaderProps } from './interface'

export const StudentHeader = ({ student, getDiscURL, getProfileURL }: StudentHeaderProps) => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { t } = useTranslation()

  return (
    <Paper shadow='md' withBorder p='md' id='student-header'>
      <Grid align='center'>
        <Grid.Col span={12} sm={6}>
          <Group spacing='md'>
            <Avatar size='lg' src={student.avatar} alt={student.name}>
              {getInitials(student.name)}
            </Avatar>

            <Stack spacing={0}>
              <Anchor href={getProfileURL(student.id)}>
                <Text size='lg' weight='bold'>
                  {student.name}
                </Text>
              </Anchor>
              {student.groupNames && student.groupNames.length > 0 ? (
                <Group spacing='xs'>
                  {student.groupNames.map((group, index) => (
                    <Badge
                      key={index}
                      size='md'
                      variant='outline'
                      color={theme.colorScheme === 'dark' ? 'turquoise' : 'dark'}
                    >
                      {group}
                    </Badge>
                  ))}
                  {student.tag && student.tag !== '' && <BadgeCycle>{student.tag}</BadgeCycle>}
                </Group>
              ) : (
                <Text color='dimmed' size='sm'>
                  {t('new_guardian_dashboard.student_no_group')}
                </Text>
              )}
            </Stack>
          </Group>
        </Grid.Col>
        <Grid.Col span={12} sm={6}>
          <Group position='right'>
            <Button
              leftIcon={<IconUser />}
              fullWidth={isMobile}
              variant='light'
              size='md'
              component='a'
              href={getProfileURL(student.id)}
            >
              {t('students_actions_show')}
            </Button>
            <Button
              leftIcon={<IconChartDonut3 />}
              fullWidth={isMobile}
              variant='light'
              size='md'
              component='a'
              href={getDiscURL(student.id)}
            >
              {t('students_actions_mosaic')}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Paper>
  )
}
