import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { ReactMic } from 'react-mic'
import { msToFormattedTime } from '../Tools/Common'
import ButtonCard from '../_components/ButtonCard'
import i18n from '@i18n'

const HALF_SEC = 500

class Recorder extends Component {
  constructor(props) {
    super(props)

    this.state = {
      blobData: {},
      recording: false,
      renderMic: false,
      playing: false,
      startedAt: undefined,
      currentTime: 0,
      totalTime: 0,
    }
  }

  onStop = recordedBlob => {
    this.setState({
      blobData: recordedBlob,
      renderMic: false,
    })
  }

  onEnded = () => {
    this.setState(
      {
        currentTime: 0,
        playing: false,
      },
      () => this.stopTimer('playing'),
    )
  }

  onProgress = record => {
    this.setState({ startedAt: Date.now() - record.playedSeconds * 1000 })
  }

  startTimer = field => {
    this.setState(prevState => ({
      [field]: true,
      startedAt: Date.now() - prevState.currentTime,
    }))

    this.timer = setInterval(() => {
      this.setState(prevState => {
        const newTime = Date.now() - prevState.startedAt
        return {
          currentTime: newTime > prevState.totalTime && prevState.playing ? prevState.totalTime : newTime,
        }
      })
    }, 250)
  }

  stopTimer = field => {
    this.setState(prevState => {
      const state = { ...prevState }
      if (field === 'recording') {
        state.totalTime = Date.now() - state.startedAt
        state.currentTime = 0
      } else if (state.currentTime > 0) {
        state.currentTime = Date.now() - state.startedAt
      }
      state[field] = false
      state.startedAt = undefined
      return state
    })
    clearInterval(this.timer)
  }

  startRecording = () => {
    this.setState({ renderMic: true }, () => {
      this.startTimer('recording')
    })
  }

  stopRecording = () => {
    this.stopTimer('recording')
  }

  playRecord = () => {
    this.setState(prevState => {
      const { playing } = prevState
      if (!playing) {
        this.startTimer('playing')
      } else {
        this.stopTimer('playing')
      }
      return { playing: !playing }
    })
  }

  clearRecord = () => {
    this.setState({
      blobData: {},
      currentTime: 0,
      totalTime: 0,
    })
  }

  save = () => {
    const { blobData } = this.state
    const file = new File([blobData.blob], `audio_${Date.now()}.webm`, {
      type: blobData.blob.type,
    })
    this.props.submitFile(file)
    this.clearRecord()
  }

  recordButton = (icon, className, callback) => {
    const prefix = icon === 'check' ? 'icon-common-' : 'icon-'

    return (
      <i
        role='button'
        tabIndex={0}
        className={`${prefix}${icon} ${className} cursor-pointer`}
        onClick={callback}
        onKeyPress={callback}
      />
    )
  }

  render() {
    const { currentTime, totalTime, recording, playing, blobData, renderMic } = this.state
    const blobDataPresent = Object.keys(blobData).length > 0
    const translateKey = 'components_molecules_recorder'

    if (blobDataPresent && !recording) {
      return (
        <div className='recorder'>
          <button
            className={`new-buttons new-buttons-light new-buttons-small recorded ${playing ? 'playing' : ''}`}
            type='button'
            onClick={this.playRecord}
            tabIndex={0}
          >
            <i className='icon-sm-mic' />
            <span className='time-text'>
              {msToFormattedTime(currentTime + HALF_SEC)} / {msToFormattedTime(totalTime)}
            </span>
            <i className={`icon-${playing ? 'pause' : 'play'}`} />
          </button>

          <ReactPlayer
            url={blobData && blobData.blobURL ? blobData.blobURL : ''}
            className='d-none'
            playing={playing}
            onEnded={this.onEnded}
            onProgress={this.onProgress}
          />

          <span className='save-record'>{i18n.t(`${translateKey}_save`)}</span>
          {this.recordButton('check', 'yellow-color', this.save)}
          {this.recordButton('close-full', 'mediumgray-color', this.clearRecord)}
        </div>
      )
    }

    return (
      <div className='recorder'>
        {recording ? (
          <button
            className='new-buttons new-buttons-light new-buttons-small recording'
            type='button'
            onClick={this.stopRecording}
            tabIndex={0}
          >
            <i className='icon-sm-mic' />
            <span className='time-text'>{msToFormattedTime(currentTime)}</span>
            <i className='icon-stop' />
          </button>
        ) : (
          <ButtonCard
            callback={this.startRecording}
            description={i18n.t(`${translateKey}_record_audio`)}
            iconClass='icon-sm-mic'
            className='mr-5'
          />
        )}

        {renderMic && <ReactMic className='d-none' record={recording} onStop={this.onStop} onData={() => false} />}
      </div>
    )
  }
}

Recorder.propTypes = {
  submitFile: PropTypes.func.isRequired,
}

export default Recorder
