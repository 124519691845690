import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { TeacherDashboardContext } from 'pages/TeacherDashboard/context'
import { Carousel, ProjectCard } from 'components'
import { ProjectMuralActions } from '../ProjectMuralActions'
import { ProjectEditActions } from '../ProjectEditActions'
import { ProjectsSlideProps } from './interfaces'

export const ProjectsSlide = ({ project }: ProjectsSlideProps) => {
  const { t } = useTranslation()

  const { getWallURL, getAddWallURL, getEditURL } = useContext(TeacherDashboardContext)
  const badgeList = [
    {
      label: t(`modality.${project.modality || 'project'}`),
      variant: 'filled',
      color: 'turquoise',
      size: 'lg',
    },
  ]

  if (project.isTemplate) {
    badgeList.push({
      label: t('new_projects_list.badge_template'),
      variant: 'filled',
      color: 'orange',
      size: 'lg',
    })
  }

  const actionsComponent =
    project.status === 'draft' ? (
      <ProjectEditActions editURL={getEditURL(project.id)} />
    ) : (
      <ProjectMuralActions viewWallURL={getWallURL(project.id)} addWallURL={getAddWallURL(project.id)} />
    )

  return (
    <Carousel.Slide key={project.id}>
      <ProjectCard
        project={project}
        badges={badgeList}
        projectList={true}
        actions={actionsComponent}
      />
    </Carousel.Slide>
  )
}
