import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UnstyledButton, Group, Tooltip, Text, Transition, Box } from 'components'
import { IconHelpCircle } from '@tabler/icons-react'
import { useStyles } from '../SidebarMenuItem/styles'
import { SidebarHelpItemProps } from './interfaces'

export const SidebarHelpItem = ({ collapsed }: SidebarHelpItemProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ active: false })

  const [isZendeskLoaded, setZendeskLoaded] = useState<boolean>(false)
  const [isZendeskOnScreen, setZendeskOnScreen] = useState<boolean>(false)

  const handleButtonClick = () => {
    if (isZendeskOnScreen) {
      window.zE('webWidget', 'close')
    } else {
      window.zE('webWidget', 'open')
    }

    setTimeout(() => {
      setZendeskOnScreen(!isZendeskOnScreen)
    }, 500)
  }

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const webWidget = document.querySelector('#webWidget')
      setZendeskOnScreen(!!webWidget)
    })

    observer.observe(document.body, { childList: true, subtree: true })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    const intervalId: ReturnType<typeof setTimeout> = setInterval(() => {
      if (window.zE) {
        setZendeskLoaded(true)
        clearInterval(intervalId)
      }
    }, 100)
    return () => clearInterval(intervalId)
  }, [])

  if (!isZendeskLoaded) return null

  return (
    <Box
      className={classes.listItem}
    >
      <Tooltip disabled={!collapsed} label={t('sidebar_menu.help')} withArrow withinPortal position='right'>
        <UnstyledButton component='a' onClick={handleButtonClick} className={classes.menuItem}>
          <Group spacing={4} className={classes.menuItemContent}>
            <IconHelpCircle size='1.625rem' />
            <Transition mounted={!collapsed} transition='fade' duration={400} timingFunction='ease'>
              {styles => (
                <Text fw='bold' size='md' style={styles} className={classes.menuItemText}>
                  {t('sidebar_menu.help')}
                </Text>
              )}
            </Transition>
          </Group>
        </UnstyledButton>
      </Tooltip>
    </Box>
  )
}
