import { Partial } from '@legacy/common/types/partial'
import { AxiosErrorResponseFunction } from '@legacy/common/types/axios'
import axios, { Method } from 'axios'
import { setupCache } from 'axios-cache-adapter'

const cacheInstance = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: ['put', 'delete', 'get'],
})

const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common.Accept = 'application/json'

const axiosInstance = axios.create({
  adapter: cacheInstance.adapter,
  headers: {
    'Content-Type': 'application/json',
  },
})

async function Request<T, E> (
  path: string,
  requestMethod?: Method,
  payload?: T,
  errorCallback?: AxiosErrorResponseFunction<E>,
) {
  const method = requestMethod ?? 'GET'
  try {
    const response = await axiosInstance.request({ url: path, method, data: payload })
    return response.data
  } catch (error) {
    if (typeof errorCallback !== 'function') {
      return false
    }

    if (error.response) {
      errorCallback(error.response.data)
    } else if (error.request) {
      errorCallback(error.request.data)
    }

    return false
  }
}

async function get<T> (path: string, onError?: AxiosErrorResponseFunction<false>): Promise<T> {
  const response = await Request(path, undefined, null, onError)
  return response
}

async function update<T, E> (
  path: string,
  payload: Partial<T>,
  onError?: AxiosErrorResponseFunction<E>,
) {
  const response = await Request(path, 'PUT', payload, onError)
  return response
}

async function create<T, E> (
  path: string,
  payload: Partial<T>,
  onError?: AxiosErrorResponseFunction<E>,
) {
  const response = await Request(path, 'POST', payload, onError)
  return response
}

async function remove<E> (path: string, onError?: AxiosErrorResponseFunction<E>) {
  const response = await Request(path, 'DELETE', null, onError)
  return response
}

export default {
  get,
  update,
  create,
  remove,
}
