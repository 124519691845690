import { useTranslation } from 'react-i18next'
import { TagWrapper, Label } from './tag-styles'
import { ITag, TagColor } from './tag-types'
import { isEmpty } from 'lodash'

const Tag = ({ kind }: ITag) => {
  const { t } = useTranslation()
  const emptyKind = isEmpty(kind)

  return (
    <TagWrapper color={TagColor[kind] || TagColor.not_filled}>
      <Label color={kind === 'late' ? '#fff' : '#000'}>
        {emptyKind
          ? t('final_assessment_kind_not_filled')
          : t(`final_assessment_kind_${kind}`)}
      </Label>
    </TagWrapper>
  )
}

export default Tag
