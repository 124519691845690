import Paper from '../Paper/paper'
import { Wrap, Container, Label, IconContainer } from './scoreCardStyled'
import { IScoreCard } from './scoreCardTypes'
import Check from '@legacy/common/Icons/Check'

const ScoreCard = ({ label, onClick, active }: IScoreCard) => {
 
  return (
    <Wrap onClick={onClick} role='button' active={active}>
      <Paper elevation={1}>
        <Container>
          {active && (
            <IconContainer>
              <Check fill='var(--color-primary-ultradark)' width={16} />
            </IconContainer>
          )}
          <Label active={active}>{label}</Label>
        </Container>
      </Paper>
    </Wrap>
  )
}

export default ScoreCard
