import styled from 'styled-components'

const GDriveContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin-top: 1rem;

  div {
    max-width: fit-content;
    width: fit-content;
  }
`
const GDriveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding-top: 1rem;

  div {
    width: 100% !important;
    max-width: 100% !important;
  }
`
export { GDriveContainer, GDriveGrid }
