import { IGoogleDriveFile, IPostGoogleDriveFile } from '@legacy/common/types/GoogleDriveFiles'

function toApi ({ name, description, url, iconUrl, mimeType }: IGoogleDriveFile): IPostGoogleDriveFile {
  return {
    file_attributes: {
      name,
      description,
      url,
      icon_url: iconUrl,
      mime_type: mimeType
    }
  }
}

export default { toApi }
