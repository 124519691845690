import axios from 'axios'
import { AxiosError } from 'axios'

import {
  IFinalAssessment,
  IFinalAssessmentScore,
  IUpdateFinalAssessmentScoresResponse,
  IUpdateStudentJournal,
  Moods,
} from './interfaces'

export const fetchFinalAssessment = async (projectId: string) => {
  try {
    const { data } = await axios.get<IFinalAssessment>(`/projects/${projectId}/final_assessments.json`)
    return data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError
      if (axiosError.response && axiosError.response.status === 401) {
        window.location.href = '/error_permission'
      }
    }
    throw error
  }
}

export const fetchAssessmentsAverageHistory = async (projectID: string, studentID: string, lvl4ItemID: string) => {
  const { data } = await axios.post(`/api/projects/${projectID}/partial_evaluations`, {
    student_id: studentID,
    lv4_item_id: lvl4ItemID,
  })
  return data
}

export const updateMoods = async (projectId: string, moods: Moods) => {
  const payload = { project: moods }
  const url = `/projects/${projectId}/moods`
  const { data } = await axios.patch<void>(url, payload)
  return data
}

export const updateJournal = async (projectId: string, journal?: string) => {
  const payload = { project: { journal } }
  const url = `/projects/${projectId}/journal`
  const { data } = await axios.patch<void>(url, payload)
  return data
}

export const updateFinalAssessmentScores = async (finalAssessmentScores: IFinalAssessmentScore[]) => {
  const payload = { final_assessment_scores: finalAssessmentScores }
  const url = '/update_final_assessment_scores'
  const { data } = await axios.patch<IUpdateFinalAssessmentScoresResponse>(url, payload)
  return data
}

export const updateIndividualJournal = async (journalId: string, description: string) => {
  const payload = { description }
  const url = `/journals/${journalId}`
  const { data } = await axios.patch<IUpdateStudentJournal>(url, payload)
  return data
}
