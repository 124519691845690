/* eslint-disable */

export interface Props {
  readonly id: string
  readonly toggleConfirmationModal: boolean
  readonly onClose: () => void
  readonly updateModaTitle: (arg0: string) => void
  readonly setModalHasChanges: (arg0: boolean) => void
  readonly UpdateShowModal: (arg0: boolean) => void
  readonly projectUrl: string
  readonly loadDashBoardData: () => void
}

export interface Payload {
  journal_description: string
  assessment_scores: AssessmentScore[]
  assessment_id: string
  mood_master_evaluation_by_student: number
  mood_student_developed: number
  mood_student_liked: number
  mood_student_took_part: number
  mood_group_developed: number
}

export interface AssessmentScore {
  assessment_score_id: string
  student_score: number
}

export const initialPayload = {
  assessment_id: '',
  journal_description: '',
  mood_master_evaluation_by_student: 0,
  mood_student_developed: 0,
  mood_student_liked: 0,
  mood_student_took_part: 0,
  mood_group_developed: 0,
  assessment_scores: [],
}

export interface SkilsProps {
  readonly name: string
  readonly lv1Name: string
  readonly color: string
  readonly originalCodes: string
}

export interface ActivityProps {
  readonly planning: string
  readonly id?: string
  readonly specificSkillsAndContents: SkilsProps[]
  readonly cicles?: string[]
  readonly title: string
}

export interface Activity {
  readonly id: string
  readonly title: string
  readonly planning: string
  readonly kind: string
  readonly hasAssessments: boolean
  readonly hasPlanningFiles: boolean
  readonly planningFiles: ReadonlyArray<any>
  readonly specificSkillsAndContents: ReadonlyArray<{
    readonly id: string
    readonly name: string
    readonly originalCodes: string
    readonly cycles: ReadonlyArray<any>
    readonly lv1Name: string
    readonly color: string
    readonly curriculumKind: string
  }>
}

export interface SummaryCardProps {
  readonly projectName: string
  readonly title: string
  readonly projectUrl: string
  readonly tutors: ReadonlyArray<{
    readonly id: string
    readonly fullName: string
    readonly name: string
    readonly imageUrl: string
    readonly avatar: string
    readonly link: string
    readonly educatorRole: string
  }>

  readonly sessionMeetingActivities: ReadonlyArray<{
    readonly id: string
    readonly title: string
    readonly planning: string
    readonly kind: string
    readonly hasAssessments: boolean
    readonly hasPlanningFiles: boolean
    readonly planningFiles: ReadonlyArray<any>
    readonly specificSkillsAndContents: ReadonlyArray<{
      readonly id: string
      readonly name: string
      readonly originalCodes: string
      readonly cycles: ReadonlyArray<any>
      readonly lv1Name: string
      readonly color: string
      readonly curriculumKind: string
    }>
  }>
}

export interface RatingItem {
  readonly id: string
  readonly name: string
  readonly rating: number
}

export interface RatingProp {
  id: string
  name: string
  rating: number
}
