interface EnvsHookResult {
  readonly gClientId: string
  readonly gDeveloperKey: string
  readonly gScope: string
}
function useEnvs (): EnvsHookResult {
  const gClientId = '635042629277-em4tegjfnbpq3e23qt7l1ju17gcok57h.apps.googleusercontent.com'
  const gDeveloperKey = 'AIzaSyCFWGuIZe1IK8vdjcTR0ptuQEHMeHO6WSw'
  const gScope = 'https://www.googleapis.com/auth/drive.file'

  return {
    gClientId: gClientId.replaceAll('"', ''),
    gDeveloperKey: gDeveloperKey.replaceAll('"', ''),
    gScope: gScope.replaceAll('"', '')
  }
}

export default useEnvs
