import HeaderInfo from 'components/HeaderInfo/HeaderInfo'
import { isEmpty, isEqual } from 'lodash'
import {
  WrapperModal,
  TitleJournal,
  TitleFile,
  ContentContainer,
  TitleIndividualAssessment,
} from './StudentModalContentStyled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IStudentAsssessments } from './StudentModalContentTypes'
import TextAreaLumiar from 'components/TextAreaLumiar/TextAreaLumiar'
import EvaluativeItem from './EvaluativeItem'
import FileUpload from '@legacy/common/_components/FileUpload'
import { notifications } from 'context'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import { Box, Stack, AssessmentHistoryModal, Button, Group, Paper, Title } from 'components'
import * as service from '../../service'
import { useAssessmentScoreListHook } from 'common/hooks/useAssessmentScoreListHook'
import CircularLoader from 'components/CircularLoader/CircularLoader'
import { defaultTheme } from 'themes/defaultTheme'
import { useDisclosure } from 'hooks'
import { IconMoodCog, IconBooks, IconCheck, IconX } from '@tabler/icons-react'
import { ILessonAssessmentHistory, ILessonAssessmentHistoryInitalValues } from 'interfaces'

const StudentModalContent = ({
  fullName,
  imageUrl,
  masterjustification,
  journalId,
  assessment_id,
  goNext,
  mixPanelTracker,
  fetchStudents,
  saveAndGoNext,
  handleCloseDrawer,
  journalStudent,
  setPayload,
  setInitialPayload,
  payload,
  initialPayload,
  updateProject,
  projectId,
  selectedStudent,
}: IStudentAsssessments) => {
  const { t } = useTranslation()
  const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false)
  const [saveAndNextLoading, setSaveAndNextLoading] = useState(false)
  const [loadingNext, setLoadingNext] = useState<boolean>(false)
  const { contentAssessmentScores, scrollRef, skillAssessmentScores, loadingAssess } = useAssessmentScoreListHook(
    selectedStudent.id,
    projectId,
    'finalAssessment',
  )
  const [openedHistoryModal, { open: openHistoryModal, close: closeHistoryModal }] = useDisclosure(false)
  const [historyModalContent, setHistoryModalContent] = useState<ILessonAssessmentHistory[]>(
    ILessonAssessmentHistoryInitalValues,
  )
  const [historyLoading, setHistoryLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  const updateJournal = (value: string) => {
    const journal = {
      id: journalId,
      value,
    }
    setPayload(payload => {
      return {
        ...payload,
        journal,
      }
    })
  }

  const onSave = async (kind: 'go-next' | 'save-close') => {
    mixPanelTracker('Salvar avaliação')
    if (!isEqual(payload, initialPayload)) {
      kind === 'go-next' ? setSaveAndNextLoading(true) : setSaveAndCloseLoading(true)
      const { final_assessment_scores } = payload
      await Promise.all([
        updateProject(),
        service.updateFinalAssessmentScores(final_assessment_scores),
        service.updateIndividualJournal(payload.journal.id, payload.journal.value),
      ])
      setSaveAndNextLoading(false)
      setSaveAndCloseLoading(false)
    }
    notifications.show({
      message: t('save_final_assessment'),
      color: 'green',
      icon: <IconCheck size={24} />,
    })
    fetchStudents()

    if (kind === 'go-next') {
      setLoadingNext(true)
      saveAndGoNext()
      setLoadingNext(false)
      scrollTop()
      return
    }

    handleCloseDrawer(false, true)
    document.body.style.overflow = 'scroll'
  }

  const scrollTop = () => {
    const rodal = document.getElementsByClassName('rodal-dialog')
    rodal[0].scrollTo(0, 0)
  }

  const handleHistory = async (projectID: string, studentID: string, lvl4ItemID: string) => {
    setHistoryModalContent([])
    setHistoryLoading(true)
    mixPanelTracker('Visualizar histórico de avaliações em encontros')
    openHistoryModal()
    try {
      const historyContent = await service.fetchAssessmentsAverageHistory(projectID, studentID, lvl4ItemID)
      setHistoryModalContent(historyContent.session_meetings)
      setHistoryLoading(false)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  const handleErrors = (error: string) => {
    if (error.includes('Cannot read properties of undefined')) {
      return t('general_error_message_data_not_loaded')
    }
    return error
  }

  const Loading = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <CircularLoader size={20} color={defaultTheme.colors.green} thickness={3} time={1} />
    </div>
  )

  useEffect(() => {
    if (error && error !== '') {
      notifications.show({
        title: t('general_error_title'),
        message: handleErrors(error),
        color: 'red',
        icon: <IconX size={24} />,
      })
      setError('')
    }
  }, [error])

  return (
    <WrapperModal key={assessment_id} ref={scrollRef}>
      <AssessmentHistoryModal
        openedHistoryModal={openedHistoryModal}
        closeHistoryModal={closeHistoryModal}
        historyModalContent={historyModalContent}
        userName={fullName}
        avatarURL={imageUrl}
        loading={historyLoading}
      />
      <HeaderInfo
        title={t('students_assessment_final_title')}
        userName={fullName}
        userImage={imageUrl}
        closeFunction={() => {
          handleCloseDrawer(false, false)
        }}
        active={!loadingNext}
      />
      <ContentContainer active={!loadingNext}>
        {!isEmpty(journalStudent) && (
          <TitleIndividualAssessment>
            {t('students_assessment_individual_report', { fullName: fullName })}
          </TitleIndividualAssessment>
        )}
        {!isEmpty(journalStudent) && <span>{journalStudent}</span>}
        {!isEmpty(contentAssessmentScores?.result) ? (
          <Stack spacing='md' mt='md'>
            <Group spacing={4}>
              <IconMoodCog size='1.5rem' />
              <Title order={3}>{t('students_assessment_standart')}</Title>
            </Group>
            {contentAssessmentScores?.result.map((item: any) => {
              return (
                <Paper p='md' shadow='md' withBorder key={item.lv4Item.id}>
                  <EvaluativeItem
                    item={item}
                    fetchStudents={fetchStudents}
                    setPayload={setPayload}
                    setInitialPayload={setInitialPayload}
                    payload={payload}
                    initialPayload={initialPayload}
                    openHistoryModal={() => handleHistory(projectId, selectedStudent.id, item.lv4Item.id)}
                    fullName={fullName}
                  />
                </Paper>
              )
            })}
          </Stack>
        ) : null}
        {!isEmpty(skillAssessmentScores?.result) ? (
          <Stack spacing='md' mt='lg'>
            <Group spacing={4}>
              <IconBooks size='1.5rem' />
              <Title order={3}>{t('students_assessment_skills')}</Title>
            </Group>
            {skillAssessmentScores?.result.map((item: any) => {
              return (
                <Paper p='md' shadow='md' withBorder key={item.lv4Item.id}>
                  <EvaluativeItem
                    item={item}
                    fetchStudents={fetchStudents}
                    setPayload={setPayload}
                    setInitialPayload={setInitialPayload}
                    payload={payload}
                    initialPayload={initialPayload}
                    openHistoryModal={() => handleHistory(projectId, selectedStudent.id, item.lv4Item.id)}
                    fullName={fullName}
                  />
                </Paper>
              )
            })}
          </Stack>
        ) : null}
        {loadingAssess && <Loading />}
        {skillAssessmentScores?.pagination.isLastPage && (
          <div>
            <TitleJournal extraspace={true}>{t('students_assessment_notes')}</TitleJournal>
            <TextAreaLumiar
              value={masterjustification ? masterjustification : ''}
              placeholder={t('students_assessment_placeholder')}
              onChange={updateJournal}
              key={journalId}
            />
            <TitleFile extraspace={false}>{t('students_assessment_files')}</TitleFile>
            <div className='fileContainer' key={assessment_id}>
              <FileUpload
                uploadUrl={`/final_assessments/${assessment_id}/final_assessment_file_items`}
                fetchFilesUrl={`final_assessments/${assessment_id}/final_assessment_file_items.json`}
                deleteFilesUrl={`/final_assessments/${assessment_id}/final_assessment_file_items`}
                typeFile='file'
                fileUploadId='student'
              />
            </div>

            <Box mt='sm'>
              <GDriveWrapper
                key={assessment_id + 'gdrive'}
                value={{
                  resourceId: assessment_id,
                  resourceClass: 'final_assessment_id',
                }}
              />
            </Box>
          </div>
        )}
        <Group spacing='md' position='right'>
          <Button
            size='lg'
            onClick={() => onSave('save-close')}
            variant={goNext ? 'default' : 'filled'}
            loading={saveAndCloseLoading}
            disabled={saveAndNextLoading}
          >
            {t('students_assessment_save_close')}
          </Button>
          {goNext && (
            <Button
              variant='filled'
              size='lg'
              onClick={() => onSave('go-next')}
              loading={saveAndNextLoading}
              disabled={saveAndCloseLoading}
            >
              {t('students_assessment_save_go_next')}
            </Button>
          )}
        </Group>
      </ContentContainer>
    </WrapperModal>
  )
}

export default StudentModalContent
