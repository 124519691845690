import { NextTaskCard } from 'components/NextTaskCard'
import { carouselDashboard } from 'components/Carousel/config'
import { v4 as UuidV4 } from 'uuid'
import { Flex, Space, Skeleton, DashboardHeader, Carousel } from 'components'

export const skeletonSlide = (
  <Skeleton visible={true}>
    <NextTaskCard
      projectName='loading'
      lessonName='loading'
      date='loading'
      time='loading'
      canFillEditReport={false}
      activities={[]}
      status='not_started'
      handleJournalClick={() => {}}
    />
  </Skeleton>
)

export const skeletonCarousel = (
  <Carousel {...carouselDashboard} withIndicators={false} withControls={false}>
    {[...Array(3)].map(() => (
      <Carousel.Slide key={UuidV4()}>{skeletonSlide}</Carousel.Slide>
    ))}
  </Carousel>
)

export const StudentDashBoardOnLoad = () => {
  return (
    <>
      <DashboardHeader name='loading' isloading={true} />
      <Flex>
        <Skeleton visible h={30} w={200} mt={80} />
      </Flex>
      <Space h='md' />
      {skeletonCarousel}
      <Flex>
        <Skeleton visible h={30} w={200} mt={32} />
      </Flex>
      <Space h='md' />
      {skeletonCarousel}
    </>
  )
}
