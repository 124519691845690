import { Input, Group, WeekDayButton } from 'components'
import { useTranslation } from 'react-i18next'
import { WeekDaySelectorProps, daysOfWeek } from './interfaces'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export const WeekDaySelector = ({
  name,
  label,
  disabled,
  withAsterisk,
  handleWeekDays,
  startDate,
  selectedDays = [],
}: WeekDaySelectorProps) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(selectedDays)

  const handleDayClicked = (value: number) => {
    if (selected.length === 1 && selected[0] === value) return

    if (selected.includes(value)) {
      setSelected(selected.filter((day: number) => day !== value))
      handleWeekDays(selected.filter((day: number) => day !== value))
    } else {
      setSelected([...selected, value])
      handleWeekDays([...selected, value])
    }
  }

  const getDayOfWeekFromDateSting = (date: string) => {
    const day = dayjs(date).day()
    return day
  }

  useEffect(() => {
    if (disabled === false && startDate) {
      const day = getDayOfWeekFromDateSting(startDate)
      handleDayClicked(day)
    }
  }, [disabled])

  return (
    <Input.Wrapper id={name} size='md' withAsterisk={withAsterisk} label={label}>
      <Group spacing='xs' pt={4} align='flex-start'>
        {daysOfWeek.map((day, index) => (
          <WeekDayButton
            key={index}
            title={t(day)}
            disabled={disabled}
            handleDayClicked={handleDayClicked}
            index={index}
            checked={selected.includes(index)}
          />
        ))}
      </Group>
    </Input.Wrapper>
  )
}
