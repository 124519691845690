import {
  IconHome2,
  IconBackpack,
  IconListCheck,
  IconChartDonut3,
  IconCalendarEvent,
  IconClipboardText,
  IconNews,
  IconUsers,
  IconBook,
  IconReportAnalytics,
  IconSettings,
} from '@tabler/icons-react'
import { SidebarMenuIconProps } from './interfaces'

export const SidebarMenuIcon = ({ icon }: SidebarMenuIconProps) => {
  const iconMapping = {
    'icon-home': <IconHome2 />,
    'icon-activities': <IconBackpack />,
    'icon-mosaic': <IconChartDonut3 />,
    'icon-pendencies': <IconListCheck />,
    'icon-timetable-manager': <IconCalendarEvent />,
    'icon-reports': <IconClipboardText />,
    'icon-observations': <IconNews />,
    'icon-students': <IconUsers />,
    'icon-pen-ruler': <IconBook />,
    'icon-students-report': <IconReportAnalytics />,
    'icon-md-settings': <IconSettings />,
  }

  const iconComponent = iconMapping[icon as keyof typeof iconMapping] || <IconHome2 />

  return iconComponent
}

