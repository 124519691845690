import { useCallback } from 'react'
import { useMutation } from 'react-query'

import Cookies from 'js-cookie'
import axios from 'axios'

import { useForm } from '@legacy/common/hooks/form-hook'
import {
  INewSession,
  INewSessionErrors,
  initialNewSession as initial,
  initialNewSessionErrors as initialErrors,
} from '@legacy/common/models/session'
import { lumiarToastError } from '@legacy/common/_components/LumiarToast'
import { isValidEmail } from '@legacy/common/Tools/Validations/Email'

import { useAuth } from 'context/userAuthContext'

import '../../../assets/stylesheets/form.scss'

interface ILogin {
  email?: string
  password: string
  username?: string
}

type ErrorData = {
  errors: string[]
  success: boolean
}

type ResponseType = {
  data: ErrorData
}

interface ErrorAuth {
  response: ResponseType
}

type AuthData = {
  email: string
  firstName: string
  lastName: string
  id: string
  locale: string
  selected_school_id: string
  username: string
  uid: string
}

type ResponseData = {
  data: AuthData
}

type ResponseHeaders = {
  'access-token': string
  client: string
  'token-type': string
  uid: string
  expiry: string
}

interface AuthResponse {
  data: ResponseData
  headers: ResponseHeaders
}

export function useSessionNew() {
  const { payload, errors, change, check } = useForm<INewSession, INewSessionErrors>({
    initial,
    initialErrors,
  })

  const { login } = useAuth()

  const mutation = useMutation<AuthResponse, ErrorAuth, ILogin>(login => axios.post('/api/auth/sign_in', login))

  const trimAndLowerCase = (value: string) => value.trim().toLowerCase()

  const logIn = useCallback(async () => {
    let customPayload: ILogin = {
      email: trimAndLowerCase(payload.login),
      password: payload.password,
    }
    if (!isValidEmail(payload.login)) {
      customPayload = {
        username: trimAndLowerCase(payload.login),
        password: payload.password,
      }
    }
    mutation.mutate(customPayload)
  }, [payload])

  if (mutation.isError) {
    const error = mutation.error.response?.data?.errors[0] || 'Error on login'
    lumiarToastError(error)
    mutation.reset()
  } else {
    if (mutation.data) {
      const data = mutation.data.data.data
      const user = {
        email: data?.email,
        firstName: data?.firstName,
        lastName: data?.lastName,
        id: data?.id,
        locale: data?.locale,
        selectedSchoolId: data?.selected_school_id,
        username: data?.username,
        uid: data?.uid,
      }
      login(user)

      Cookies.set('access_token', mutation.data.headers['access-token'])
      Cookies.set('client', mutation.data.headers.client)
      Cookies.set('uid', mutation.data.headers.uid)
      Cookies.set('token-type', mutation.data.headers['token-type'])
      window.location.href = payload.urlToRedirect !== '' ? payload.urlToRedirect : '/'
      return
    }
  }
  const loading = mutation.isLoading

  return {
    loading,
    payload,
    errors,
    change,
    check,
    logIn,
  }
}
