import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScoreCard } from '../ScoreCard'
import { ScoreItemLabel, ScoreItemVariant } from '../ScoreItem'


export type IScoreList = {
  values: ScoreItemLabel[];
  callback: (value: ScoreItemLabel) => void;
  active: ScoreItemLabel;
  disabled: boolean;
  variant?: ScoreItemVariant;
};

export const ScoreList = ({ values = [], callback, active, disabled, variant }: IScoreList) => {
  const { t } = useTranslation()

  const RUBRIC_KEYS = ['rubric_1', 'rubric_2', 'rubric_3', 'rubric_4', 'rubric_5']
  const DefaultModalRubrics = RUBRIC_KEYS.map(key => t(key))
  
  const [selectedScoreCard, setSelectedScoreCard] =
    useState<undefined | ScoreItemLabel>(active)

  if (values.length === 0) {
    return <></>
  }

  const handleClick = (value: ScoreItemLabel) => {
    setSelectedScoreCard(value)
    callback(value)
  }

  return (
    <>
      {values.map((value: ScoreItemLabel) => (
        <ScoreCard
          variant={variant}
          key={value}
          label={value}
          rubric={DefaultModalRubrics[value]}
          onClick={() => handleClick(value)}
          active={selectedScoreCard === value}
          disabled={disabled}
          kind='default'
        />
      ))}
    </>
  )
}

export default ScoreList
