import styled from 'styled-components'
import { DropdownMenuItemIndicator } from 'components/DropdownMenu'

export const DropdownMenuRadioItemStyled = styled.div`
  cursor: pointer;
`
export const DropdownMenuItemLanguageIndicatorStyled = styled(
  DropdownMenuItemIndicator,
)`
  display: flex;
  position: absolute;
`
