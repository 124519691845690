import { Layout } from 'components'
import { ReportsDashboard as LegacyReportsDashboard } from '@legacy/common/components/apps/reports_dashboard'
import { CurrentUserContextProvider } from 'context'

export function ReportsDashboard() {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <LegacyReportsDashboard />
      </Layout>
    </CurrentUserContextProvider>
  )
}