import { Layout } from 'components'
import { CurrentUserContextProvider } from 'context'
import { GuardianDashboard } from './components/GuardianDashboard'
import { GuardianDashboardProvider } from './context/GuardianDashboardContext'

export const GuardianDashboardPage = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <GuardianDashboardProvider>
          <GuardianDashboard />
        </GuardianDashboardProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
