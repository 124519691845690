import { createContext, useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectButtonsContext } from './ProjectButtonsContext'
import { useDisclosure } from 'hooks'
import { Beforeunload } from 'react-beforeunload'

// Por algum motivo que eu desconheço o beforeUnload foi adicionado a mais de um
// contexto uso essas variáveis para desabilitar onde não é necessário e está duplicado.
const PLAN_STEP = 3
const SKILL_STEP = 2
interface StepperContextType {
  isTemplate: boolean
  setIsTemplate: (value: boolean) => void
  active: number
  setActive: (value: number) => void
  nextStep: () => void
  prevStep: (changed?: boolean) => void
  shouldAllowSelectStep: (step: number) => boolean
  projectId: string
  setProjectId: (id: string) => void
  confirmModalClose: () => void
  confirmModalOpen: () => void
  confirmModalOpened: boolean
  hasChanged: boolean
  setHasChanged: (value: boolean) => void
  projectStatus: string | undefined
  setProjectStatus: (status: string | undefined) => void
  block: boolean
  setBlock: (value: boolean) => void
}

export const StepperContext = createContext<StepperContextType>({
  isTemplate: false,
  setIsTemplate: () => {},
  active: 0,
  setActive: () => {},
  nextStep: () => {},
  prevStep: () => {},
  shouldAllowSelectStep: () => true,
  projectId: '',
  setProjectId: () => {},
  confirmModalClose: () => {},
  confirmModalOpen: () => {},
  confirmModalOpened: false,
  hasChanged: false,
  setHasChanged: () => {},
  projectStatus: 'draft',
  setProjectStatus: () => {},
  block: false,
  setBlock: () => {},
})

export const StepperProvider = ({ children }: { children: React.ReactNode }) => {
  const [isTemplate, setIsTemplate] = useState<boolean>(false)
  const [active, setActive] = useState<number>(0)
  const [projectId, setProjectId] = useState<string>('')
  const [confirmModalOpened, { open: confirmModalOpen, close: confirmModalClose }] = useDisclosure(false)
  const { buttonHandlers } = useContext(ProjectButtonsContext)
  const [hasChanged, setHasChanged] = useState<boolean>(false)
  const [projectStatus, setProjectStatus] = useState<string | undefined>('draft')
  const [block, setBlock] = useState<boolean>(false)

  const { id } = useParams()

  const nextStep = () => {
    const nextStep = active + 1
    const isOutOfBounds = nextStep > 3 || nextStep < 0

    if (isOutOfBounds) {
      return
    }

    setActive(nextStep)
    setHighestStepVisited(hSC => Math.max(hSC, nextStep))
    buttonHandlers.setState([
      { loading: false, disabled: false },
      { loading: false, disabled: false },
    ])
  }
  const prevStep = (changed?: boolean) => {
    if (changed && changed === true) {
      confirmModalOpen()
    } else {
      setActive(current => (current > 0 ? current - 1 : current))
    }
  }
  const [highestStepVisited, setHighestStepVisited] = useState(active)

  const shouldAllowSelectStep = (step: number) => {
    if (step === 1 && projectId) return true

    return highestStepVisited >= step && active !== step
  }

  const handlePageExit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (id) {
      setProjectId(id)
    } else {
      setProjectId('')
    }
  }, [id])

  return (
    <StepperContext.Provider
      value={{
        projectId,
        setProjectId,
        isTemplate,
        active,
        setIsTemplate,
        setActive,
        nextStep,
        prevStep,
        shouldAllowSelectStep,
        confirmModalClose,
        confirmModalOpen,
        confirmModalOpened,
        hasChanged,
        setHasChanged,
        projectStatus,
        setProjectStatus,
        block,
        setBlock,
      }}
    >
      {hasChanged && active !== PLAN_STEP && active !== SKILL_STEP ? (
        <>
          <Beforeunload onBeforeunload={event => handlePageExit(event)} />
        </>
      ) : null}
      {children}
    </StepperContext.Provider>
  )
}
