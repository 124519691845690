import { Paper, Avatar, Stack, Group, Text } from 'components'
import { getInitials } from '../../helpers/getInitials'
import { PaperProps } from '@mantine/core'

export interface ICardAvatar extends PaperProps {
  name: string
  avatar: string | null
  role?: string
  vertical?: boolean
  minw?: string
}

export const CardAvatar = ({ name, role, avatar, vertical, minw, ...props }: ICardAvatar) => {
  return (
    <Paper miw={minw} {...props}>
      {vertical ? (
        <Stack spacing={2} align='center'>
          <Avatar size='lg' src={avatar} alt={name}>
            {getInitials(name)}
          </Avatar>
          <Text align='center'>{name}</Text>
          <Text fz='sm' fw={500} color='dimmed'>
            {role}
          </Text>
        </Stack>
      ) : (
        <Group spacing='xs'>
          <Avatar size='md' src={avatar} alt={name}>
            {getInitials(name)}
          </Avatar>
          <Text>{name}</Text>
        </Group>
      )}
    </Paper>
  )
}
