/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import { StyledIconPathStroke } from './styled'

const AddCircle = ({ width, disabled = false }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 26C20.4036 26 26 20.4036 26 13.5C26 6.59644 20.4036 1 13.5 1C6.59644 1 1 6.59644 1 13.5C1 20.4036 6.59644 26 13.5 26Z"
        stroke="#D3D2D7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <StyledIconPathStroke
        d="M13.5 8.5V18.5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        disabled={disabled}
      />
      <StyledIconPathStroke
        d="M8.5 13.5H18.5"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        disabled={disabled}
      />
    </svg>
  )
}

AddCircle.propTypes = {
  width: PropTypes.number,
  disabled: PropTypes.bool
}

AddCircle.defaultProps = {
  width: 27,
}

export default AddCircle
