import { SchoolScoreType } from '@legacy/common/models/school'
import { useCurrentUser } from 'context/useCurrentUser'
import { useMemo } from 'react'
import { ScoreItemVariant } from './ScoreItem'

export function useScoreItemVariant (variant?: ScoreItemVariant) {
  const { currentUser } = useCurrentUser()
  const value = useMemo(() => {
    if (variant) {
      return variant
    }
    if (currentUser?.selected_school?.score_type) {
      return fromScoreTypeToScoreVariant(currentUser.selected_school.score_type)
    }
    return 'hand'
  }, [variant, currentUser])
  return value
}

function fromScoreTypeToScoreVariant(type: SchoolScoreType): ScoreItemVariant {
  switch(type) {
    case SchoolScoreType.Hand:
      return 'hand'
    case SchoolScoreType.Number:
      return 'number'
  }
}