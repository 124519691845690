import { Avatar, Text, Stack, Group } from 'components'
import { IUserInfo } from './interfaces'
import { getInitials } from 'helpers'

export const UserData = ({ userName, updatedAt, image }: IUserInfo) => {
  return (
    <Group spacing='sm'>
      <Avatar size='md' src={image} radius='xl' alt={getInitials(userName)}>
        {getInitials(userName)}
      </Avatar>
      <Stack spacing={0}>
        <Text size='md' fw='bold'>{userName}</Text>
        <Text size='sm' color='dimmed'>{updatedAt}</Text>
      </Stack>
    </Group>
  )
}