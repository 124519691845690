import Paper from '../Paper/paper'
import Avatar from '../Avatar/Avatar'
import Tag from '../Tag/Tag'
import { StudentCardWrapper, InfoContainer } from './studantCardStyled'
import { IStudentCard } from './studentCardTypes'

const StudentCard = ({ name, status, image }: IStudentCard) => {
  return (
    <Paper elevation={1}>
      <StudentCardWrapper>
        <InfoContainer>
          <Avatar name={name} size='xxsmall' image={image} />
          <p>{name}</p>
        </InfoContainer>
        <Tag kind={status} />
      </StudentCardWrapper>
    </Paper>
  )
}

export default StudentCard
