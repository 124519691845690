import { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import Parser from 'html-react-parser'

const Paragraph = props => {
  const { text } = props

  if (isEmpty(text)) return ''

  return (
    <div className="rich-text">
      {text.split('\n').map((line, index) => {
        if (line.search('<p>') !== -1) return <Fragment key={index}>{Parser(line)}</Fragment>

        return <p key={index}>{Parser(line)}</p>
      })}
    </div>
  )
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Paragraph
