import { Box, Button, Tooltip } from 'components'
import { IconCircleCheckFilled } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

interface ActivityActionsProps {
  canFillEditReport: boolean
  status: string
  handleJournalClick?: () => void
}

export const ActivityActions = ({ status, canFillEditReport, handleJournalClick }: ActivityActionsProps) => {
  const { t } = useTranslation()

  return (
    <Box mt='md'>
      {canFillEditReport ? (
        <Button
          size='md'
          onClick={handleJournalClick}
          data-testid='fill_report'
          fullWidth
          variant={status === 'started' ? 'outline' : 'filled'}
          leftIcon={status === 'started' ? <IconCircleCheckFilled size='1rem' /> : null}
        >
          {status === 'started' ? t('general_buttons_edit_report') : t('general_buttons_fill_journal')}
        </Button>
      ) : (
        <Tooltip label={t('fill_report_disabled')} withinPortal withArrow position='top'>
          <Button
            fullWidth
            data-disabled
            size='md'
            sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
            data-testid='fill_report-disabled'
            onClick={event => event.preventDefault()}
          >
            {t('general_buttons_fill_journal')}
          </Button>
        </Tooltip>
      )}
    </Box>
  )
}
