export type URL = string

export interface IActions {
  readonly show: URL
  readonly edit: URL
  readonly delete: URL
  readonly mosaic: URL
  readonly guardians: URL
}

export interface IStudent {
  readonly id: string
  readonly name: string
  readonly photoUrl: string
  readonly cycleName: string | null
  readonly actions: IActions
}

export interface TabProviderProps {
  readonly children: React.ReactNode
}

export interface InitialState {
  readonly activePage: number
  readonly students: IStudent[]
  readonly pagination: object
  readonly meta: object
  readonly actions: IActions | object
  readonly loading: boolean
  readonly activeTab: number | null
  readonly search: string
}

export interface IPagination {
  readonly total: number
}

export type Statuses = {
  readonly active: number
  readonly inactive: number
}

export type Path = string

export interface StudentActions {
  readonly delete?: Path
  readonly edit?: Path
  readonly guardians?: Path
  readonly mosaic?: Path
  readonly show?: Path
}

export interface IActionButton {
  readonly actions: StudentActions
  readonly isMobile: boolean
  readonly anchorColor?: string
}

export type IStudentAction = 'delete' | 'edit' | 'guardians' | 'mosaic' | 'show' | 'report'

export interface IActionIcon {
  readonly name: IStudentAction
}

export const initialState: InitialState = {
  activePage: 1,
  students: [],
  pagination: {},
  meta: {},
  actions: {},
  loading: false,
  activeTab: null,
  search: '',
}

export const STATUSES: Statuses = {
  active: 1,
  inactive: 0,
}

export interface ITabContext {
  readonly changeTab: (tab: number) => void
  readonly changePage: (page: number) => void
  readonly searchStudentByName: (search: string) => void
  readonly activeTab: number | null
  readonly activePage: number
  readonly students: IStudent[]
  readonly loading: boolean
  readonly pagination: {
    total: number
  }
  readonly meta: {
    activeStudentsCount: number
    inactiveStudentsCount: number
  }
  readonly actions: {
    new: string
  }
}
