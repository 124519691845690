import styled from 'styled-components'
import Colors from '@legacy/common/Constants/Colors'

export const ObjectivesContainer = styled.div`
  padding: 2rem 0;
  *:last-child {
    margin-bottom: 0;
    padding-bottom: 0; 
  }
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid ${Colors.primary.lightGray};
`