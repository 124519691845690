import { useAuth } from 'context/userAuthContext'
import { Loading } from '@legacy/common/_components'
import { useEffect } from 'react'

export const SignOut = () => {
  const { logout } = useAuth()

  useEffect(() => {
    logout()
  }, [])

  return <Loading />
}
