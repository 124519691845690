export const shared = {
  common: {
    borderRadius: {
      medium: '6px',
      large: '10px',
    },
    mobileBreakpoint: '800px',
  },
  typography: {
    heading: {
      xlarge: '42px',
      xlargeMobile: '28px',
      large: '28px',
      largeMobile: '24px',
      medium: '24px',
      mediumMobile: '20px',
      small: '20px',
      smallMobile: '18px',
      xsmall: '18px',
      xsmallMobile: '16px',
      xxsmall: '16px',
      xxsmallMobile: '14px',
    },
    supportText: {
      default: '16px',
      mobile: '14px',
    },
    body: {
      default: '16px',
      mobile: '14px',
    },
  },
}
