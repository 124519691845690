import { useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import classNames from "classnames";
import { isEmpty } from "lodash";

const InputText = (props) => {
  const {
    id,
    name,
    errors,
    label,
    value,
    onChange,
    disabled,
    type,
    onEnter,
    maxLength,
    styles,
    onKeyUp,
    hasMask,
    mask,
    dataTestId,
    placeholder,
    emptyValid,
  } = props;
  const [isInvalid, changeIsInvalid] = useState(false);
  const [labelOnTop, changeLabelOnTop] = useState(false);

  const checkEnter = (event) => {
    if (onEnter === undefined) {
      return true;
    }

    if (event.key === "Enter") {
      event.preventDefault();
      return onEnter(event.target.value);
    }

    return false;
  };

  const handleValidation = async (value) => {
    if (props.validationRule) {
      changeIsInvalid(
        typeof props.validationRule(value) === "string"
          ? await props.validationRule(value)
          : false
      );
    }
  };

  const handleOnBlur = (event) => {
    if (emptyValid && isEmpty(event.target.value)) {
      return;
    }

    if (isEmpty(event.target.value)) {
      changeLabelOnTop(false);
    }

    handleValidation(event.target.value);

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  const handleOnChange = (event) => {
    if (maxLength !== 0 && event.target.value.length > maxLength) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.substr(0, maxLength);
    }

    if (isInvalid) {
      handleValidation(event.target.value);
    }

    return onChange(event);
  };

  const handleOnFocus = () => {
    changeLabelOnTop(true);
  };

  const charLeft = maxLength - value.length;

  const inputClasses = classNames("__input", styles.input, {
    "__with-error": isInvalid || errors.length,
    "__input-without-label": hasMask || !label,
  });
  const labelClasses = classNames("__label", styles.label, {
    top: labelOnTop || !isEmpty(value),
    inside: isEmpty(value) && !labelOnTop,
  });
  const hintClasses = classNames("hint", styles.hint);

  const renderError = () => {
    if (typeof isInvalid === "string") {
      return isInvalid;
    }
    return errors.length > 0 && errors[0];
  };

  return (
    <div className="register-form">
      <div className="form-field">
        {!hasMask && label && (
          <label className={labelClasses} htmlFor={id}>
            {label}
          </label>
        )}
        {hasMask ? (
          <InputMask
            mask={mask}
            maskChar={null}
            name={name}
            value={value}
            onChange={handleOnChange}
            onKeyPress={(event) => checkEnter(event)}
            placeholder={label}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                data-testid={dataTestId}
                id={id}
                type={type}
                className={classNames(inputClasses, "__with-mask")}
                data-hj-whitelist
              />
            )}
          </InputMask>
        ) : (
          <input
            id={id}
            name={name}
            autoComplete="off"
            type={type}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onKeyPress={(event) => checkEnter(event)}
            className={inputClasses}
            onKeyUp={onKeyUp}
            data-hj-whitelist
            data-testid={dataTestId}
            aria-invalid={isInvalid || !isEmpty(errors)}
          />
        )}
        {props.hint && <p className={hintClasses}>{props.hint}</p>}
        <span
          className="validation-error"
          data-testid={`message-error-${dataTestId}`}
        >
          {renderError()}
        </span>
        {maxLength > 0 && (
          <div
            className={`countdown-message ${
              charLeft <= 0 ? "darkred-color" : ""
            }`}
          >
            {t(`char_limit.${charLeft === 1 ? "one" : "other"}`, {
              count: charLeft,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  validationRule: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  hint: PropTypes.string,
  maxLength: PropTypes.number,
  styles: PropTypes.object,
  hasMask: PropTypes.bool,
  mask: PropTypes.string,
  dataTestId: PropTypes.string,
  ref: PropTypes.func,
  emptyValid: PropTypes.bool,
};

InputText.defaultProps = {
  name: undefined,
  errors: [],
  label: undefined,
  value: "",
  disabled: false,
  placeholder: undefined,
  type: "text",
  hint: null,
  onEnter: undefined,
  onBlur: undefined,
  validationRule: undefined,
  onKeyUp: () => null,
  maxLength: 0,
  styles: {
    input: "",
    label: "",
    hint: "",
  },
  hasMask: false,
  mask: "99/99/9999",
  dataTestId: "",
  ref: undefined,
  emptyValid: false,
};

export default InputText;
