import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Modal, Text, Checkbox, Group, Button } from 'components'
import { RemoveConfirmModalProps } from './interfaces'

export const RemoveConfirmModal: React.FC<RemoveConfirmModalProps> = ({
  type,
  opened,
  onClose,
  actionCancelConfirm,
}) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [removeDisabled, setRemoveDisabled] = useState(true)
  const [checked, setChecked] = useState(false)

  const typeKeys = {
    one: t(`remove_confirm_modal.${type}`),
    this: t(`remove_confirm_modal.this_${type}`),
    toThe: t(`remove_confirm_modal.to_the_${type}`),
    items: t(`remove_confirm_modal.${type}_lost_items`),
  }

  const handleModalAction = (confirmed: boolean) => {
    if (confirmed && checked && actionCancelConfirm) actionCancelConfirm()
    onClose(confirmed)
    setChecked(false)
    setRemoveDisabled(true)
  }

  useEffect(() => setRemoveDisabled(!checked), [checked])

  return (
    <Modal
      fullScreen={isMobile}
      size='lg'
      opened={opened}
      onClose={() => handleModalAction(false)}
      title={t('remove_confirm_modal.remove_confirmation_modal_title', { type: typeKeys.one })}
    >
      <Text size='md' mb='sm'>
        {t('remove_confirm_modal.remove_confirmation_modal_description', { type: typeKeys.this })}
      </Text>
      <Checkbox
        size='md'
        label={
          <Trans
            i18nKey='remove_confirm_modal.checkbox_remove_confirmation'
            values={{ type: typeKeys.toThe, items: typeKeys.items }}
          />
        }
        checked={checked}
        onChange={event => setChecked(event.currentTarget.checked)}
      />
      <Group spacing='sm' position='right' mt='md'>
        <Button variant='default' size='lg' onClick={() => handleModalAction(false)}>
          {t('general_buttons_cancel')}
        </Button>
        <Button
          variant='filled'
          color='red'
          size='lg'
          onClick={() => handleModalAction(true)}
          disabled={removeDisabled}
        >
          {t('general_buttons_remove')}
        </Button>
      </Group>
    </Modal>
  )
}
