import { useContext } from 'react'
import { Card, Text, Button, Group, Box, TextLink } from 'components'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { LessonsContext } from '../../context/LessonsContext'
import { useTranslation } from 'react-i18next'
import { IconCircleCheckFilled, IconPlus, IconTrash } from '@tabler/icons-react'
import { useStyles } from './styles'
import { ICardSkillsStandardsActivity } from './interfaces'
import { BreadCrumbCard } from 'components'
import { extractBreadcrumb } from 'helpers'

export const CardSkillsStandardsActivity = ({
  item,
  assessed,
  type,
  isEditing,
  editedCriteria,
}: ICardSkillsStandardsActivity) => {
  const { t } = useTranslation()
  const { classes } = useStyles({ color: item.color })
  const { handleAddSkillsStandardItem, handleRemoveSkillsStandardItem, checkIfSelected, handleCriteriaModal } =
    useContext(LessonsContext)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const canEdit = isEditing !== null ? isEditing?.activityLv4ItemHowToAssesses?.length > 0 || false : false
  const existsInPayLoadToAdd = editedCriteria.some((i: { _id: string }) => i._id === item.id)

  const breadcrumb =
    item.lv1Name +
    ' > ' +
    extractBreadcrumb(item.breadcrumb, 0) +
    ' > ' +
    extractBreadcrumb(item.breadcrumb, 1)
  
  return (
    <Card className={classes.card} shadow='md' withBorder>
      <Card.Section pt='md' px='md'>
        <BreadCrumbCard breadcrumb={breadcrumb} color={item.color} showCyclesBadges cycles={item.cycles} />
      </Card.Section>
      <Card.Section mt='md' px='md' sx={{ flex: '1' }}>
        <Box>
          {item.originalCodes ? (
            <Text span fw='bold' size='md'>
              {item.originalCodes} -{' '}
            </Text>
          ) : null}
          <Text span size='md'>
            {item.name}
          </Text>
        </Box>
      </Card.Section>
      <Card.Section p='md'>
        <Group spacing='none' position={assessed && checkIfSelected(item.id, type) ? 'apart' : 'right'}>
          {assessed && checkIfSelected(item.id, type) ? (
            canEdit || existsInPayLoadToAdd ? (
              <TextLink size='sm' onClick={() => handleCriteriaModal(item)} iconLeft={<IconCircleCheckFilled />}>
                {t('new_project_page.edit_criteria_button')}
              </TextLink>
            ) : (
              <>
                {checkIfSelected(item.id, type) ? (
                  <TextLink size='sm' onClick={() => handleCriteriaModal(item)}>
                    {t('new_project_page.create_criteria_button')}
                  </TextLink>
                ) : null}
              </>
            )
          ) : null}
          {checkIfSelected(item.id, type) ? (
            <Button
              fullWidth={isMobile}
              variant='light'
              size='md'
              color='red'
              leftIcon={<IconTrash size='1rem' />}
              onClick={() => handleRemoveSkillsStandardItem(item.id, type)}
            >
              {t('general_buttons_remove')}
            </Button>
          ) : (
            <Button
              fullWidth={isMobile}
              variant='light'
              size='md'
              leftIcon={<IconPlus size='1rem' />}
              onClick={() => handleAddSkillsStandardItem(item, type)}
            >
              {t('general_buttons_add')}
            </Button>
          )}
        </Group>
      </Card.Section>
      <Card.Section className={classes.cardBottom}></Card.Section>
    </Card>
  )
}
