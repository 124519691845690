import styled from 'styled-components'

export const StyledStudentReport = styled.svg`
  cursor: pointer;

  & path {
    fill: transparent;
    stroke: var(--color-black);
    transition: 0.15s all ease-in-out;
  }
`
