import ActionIcon from './ActionIcon'
import { Menu } from '@mantine/core'
import { IconCheck, IconChevronDown, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { v4 as UuidV4 } from 'uuid'
import { IActionButton } from '../../pages/Students/types/student-types'
import { Modal } from '../ModalLegacy'
import { IKind } from '../ModalLegacy'
import { deleteUser } from '../../api/students/service'
import { notifications } from 'context'
import { IStudentAction } from '../../pages/Students/types/student-types'
import { Button } from '../../components/Button'
import TabContext from '../../pages/Students/context/students-context'
import { useContext } from 'react'
import { ITabContext } from '../../pages/Students/types/student-types'
import { isEmpty } from 'lodash'

export interface IActionUrl {
  name: string
  url: string
}

export const ActionButton = ({ actions, isMobile }: IActionButton) => {
  const { t } = useTranslation()
  const { searchStudentByName } = useContext<ITabContext>(TabContext)
  const SUCCESSCODE = 200

  const userDelete = (path: string) => {
    Modal({
      title: t('students_remove_title'),
      text: t('students_remove'),
      kind: IKind.deleteFeed,
    }).then(async ({ isConfirmed }) => {
      if (!isConfirmed) return

      const response = await deleteUser(path)

      if (response.status === SUCCESSCODE) {
        notifications.show({
          message: response.text,
          color: 'green',
          icon: <IconCheck />,
        })
        window.setTimeout(() => {
          searchStudentByName(' ')
        }, 400)
        return
      } else {
        notifications.show({
          message: response.text,
          color: 'red',
          icon: <IconX />,
        })
      }
    })
  }

  const reorderObject = (obj: Record<IStudentAction, string>) => {
    const { show, mosaic, report, guardians, edit, delete: del } = obj

    const result: Array<IActionUrl | object> = [
      { name: 'show', url: show },
      { name: 'mosaic', url: mosaic },
      report ? { name: 'report', url: report } : {},
      { name: 'guardians', url: guardians },
      { name: 'edit', url: edit },
      { name: 'delete', url: del },
    ]

    return result
  }

  const getMenuItems = (actions: Record<IStudentAction, string>) => {
    const items: JSX.Element[] = []
    const reorderedActions = reorderObject(actions)

    reorderedActions.map(action => {
      const { name, url } = action as IActionUrl

      if (isEmpty(action)) return
      if (typeof url !== 'string' || !url.startsWith('/')) return

      const menuItemProps = name === 'delete' ? { onClick: () => userDelete(url) } : { href: url }

      items.push(
        <Menu.Item component='a' key={UuidV4()} icon={<ActionIcon name={name as IStudentAction} />} {...menuItemProps}>
          {t(`students_actions_${name}`)}
        </Menu.Item>,
      )
    })

    return items
  }

  return (
    <Menu data-testid='actions' position='bottom' withinPortal width={isMobile ? '90%' : 'fit-content'}>
      <Menu.Target>
        <Button fullWidth={isMobile} variant='light' rightIcon={<IconChevronDown size={18} stroke={1.5} />} pr={12}>
          {t('students_actions')}
        </Button>
      </Menu.Target>
      <Menu.Dropdown>{getMenuItems(actions as Record<IStudentAction, string>)}</Menu.Dropdown>
    </Menu>
  )
}
