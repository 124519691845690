import { useMemo } from 'react'
import { Loading } from '../../../_components'
import useCurrentUser from '../../../hooks/current-user-hook'
import BlankSlate from '../../../_atoms/BlankSlate'
import { useFeatureToggle }  from 'hooks'
import ConfigCard from '../../../../pages/manageSchools/components/config-card'
import { DashBoardContainer } from '../../../../pages/manageSchools/styles/dashboard-styles'
import { PeriodReportsIcon } from '../../../Icons/PeriodReports'
import { StudentReportsIcon } from '../../../Icons/StudentReports'
import { IconStudentReport } from '../../../Icons/StudentReport'
import { useTranslation } from 'react-i18next'
import { Heading } from 'components'

function ReportsDashboardCardItem({
  title,
  subtitle,
  link,
  icon,
}: {
  readonly title: string
  readonly subtitle: string
  readonly link: string
  readonly icon: JSX.Element
}) {
  return (
    <div>
      <ConfigCard title={title} description={subtitle} icon={icon} link={link} />
    </div>
  )
}

export function ReportsDashboard() {
  const { t } = useTranslation()
  const featureToggleMetabaseReports = useFeatureToggle('metabase_reports')
  const current = useCurrentUser()
  const showReports = useMemo(() => current?.profileIsTutor || current?.profileIsManager, [current])

  if (current.data?.profile_used === 'tutor'  && current?.data?.group_school_terms_count === 0) {
    window.location.replace('/teacher/dashboard')
  }

  if (current.loading) {
    return <Loading />
  }

  if (!showReports) {
    return <BlankSlate message={t('access.not_authorized')} />
  }

  return (
    <>
      <Heading
        size='xl'
        text={t('reports_dashboard.index.title')}
        supportText={t('reports_dashboard.index.subtitle')}
      ></Heading>
      <DashBoardContainer>
        <ReportsDashboardCardItem
          icon={<PeriodReportsIcon />}
          link='/end_of_term_reports'
          title={t('school_term_assessments_index_title')}
          subtitle={t('school_term_assessments_index_subtitle')}
        />
        <ReportsDashboardCardItem
          icon={<StudentReportsIcon />}
          link='/students/reports'
          title={t('students_report.index.title')}
          subtitle={t('students_report.index.subtitle')}
        />
        {featureToggleMetabaseReports && (
          <>
            <ReportsDashboardCardItem
              icon={<IconStudentReport />}
              link='/reports/student'
              title={t('reports_dashboard.index.students_title')}
              subtitle={t('reports_dashboard.index.students_subtitle')}
            />
            <ReportsDashboardCardItem
              icon={<IconStudentReport />}
              link='/reports/group'
              title={t('reports_dashboard.index.students_group_title')}
              subtitle={t('reports_dashboard.index.students_group_subtitle')}
            />
          </>
        )}
      </DashBoardContainer>
    </>
  )
}
