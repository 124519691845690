import { createStyles, rem } from 'hooks'

interface MenuItemStyleProps {
  active: boolean
}

export const useStyles = createStyles((theme, { active }: MenuItemStyleProps) => ({
  listItem: {
    padding: 0,
    margin: 0,
    textIndent: 0,
    listStyleType: 'none',
  },
  menuItem: {
    borderLeft: active ? `4px solid ${theme.colors.dark[9]}` : 'none',
    display: 'block',
  },
  menuItemContent: {
    width: rem(240),
    padding: rem(12) + ' ' + 0,
    marginLeft: active ? rem(21) : rem(25),
  },
  menuItemText: {
    paddingLeft: rem(8),
  },
}))
