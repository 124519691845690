import styled from 'styled-components'
import LumiarLogin from '@legacy/assets/images/lumiar_login.png'

export const StyledButtonGroup = styled.div`
  & button {
    width: 100%;
    margin-top: 3.4rem;
  }
`

export const PageArea = styled.div`
  margin-top: 2.4rem;
  box-shadow: none;
  width: 100%;
  max-width: 49rem;
  margin-bottom: 2.4rem;

  @media (max-width: 425px) {
    width: 30rem;
  }
`

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  position: inherit;
  right: 0;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.4rem;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  & > div {
    & > label {
      & > span {
        margin-top: 2px;
      }
    }
  }

  @media (max-width: 425px) {
    flex-direction: column;

    & > div {
      margin-bottom: 20px;
    }
  }
`

export const Mosaico = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding-top: 2.4rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  z-index: 1;

  background-image: url('/${LumiarLogin}');
  background-size: cover;
  background-position: bottom center;
  height: 16rem;
  position: relative;
  @media (min-width: 1920px) {
    height: 18rem;
  }
  @media (max-width: 425px) {
    height: 16rem;
    flex-direction: column;
    align-items: center;

    & > img {
      margin-bottom: 2.2rem;
    }

    & > div {
      @media (max-width: 425px) {
        margin-bottom: 20px;
      }
    }
  }
`

export const GoogleButton = styled.button`
  background: var(--color-gray-lighter);
  border: 1px solid var(--color-gray-light);
  border-radius: 50px;
  cursor: pointer;
  height: 6rem;
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid var(--color-text-disabled);
  }
`

export const TextButton = styled.span`
  font-weight: 400;
  line-height: 1.9rem;
  text-align: center;
  margin-left: 1rem;
`

export const Link = styled.a`
  margin-bottom: 0.4rem;
`

export const Divider = styled.div`
  display: flex;
  width: 100%;
  background: var(--color-gray-lighter);
  justify-content: center;
  margin-top: 41px;
  border-top: solid 1px var(--color-text-disabled);
`

export const Span = styled.span`
  display: flex;
  background: var(--color-gray-lighter);
  width: 25px;
  margin-top: -12px;
  justify-content: center;
`

export const Form = styled.form`
  max-width: 49rem;
`

export const PaperStyled = styled.div`
  width: 34rem;
  margin-bottom: 2.4rem;

  @media only screen and (max-width: 800px) {
    width: auto;
  }

  .mantine-Paper-root {
    @media only screen and (max-width: 800px) {
      padding: 20px;
    }
  }
`
