import { Grid, Skeleton, Stack } from 'components'


export function ListSkillsStandardsPage2Skeleton() {
  return (
    <Stack spacing='md'>
      <Skeleton height={50}></Skeleton>
      <Skeleton height={50}></Skeleton>
      <Grid>
        <Grid.Col xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={140}></Skeleton>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={140}></Skeleton>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={140}></Skeleton>
        </Grid.Col>
        <Grid.Col xs={12} sm={6} md={4} lg={3}>
          <Skeleton height={140}></Skeleton>
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
