import { Title, Flex, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { useMediaQuery } from '@mantine/hooks'
import { useNotification } from 'context/useNotificationContext'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useContext } from 'react'
import TabContext from '../context/students-context'
import { ITabContext } from '../types/student-types'
import { isEmpty } from 'lodash'
import { DisabledTooltipButton } from 'components/DisabledTooltipButton'
import { IconPlus } from '@tabler/icons-react'

export const WrapTitle = () => {
  const { t } = useTranslation()
  const { actions } = useContext<ITabContext>(TabContext)
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { handleNotification } = useNotification()

  const [searchParams] = useSearchParams()
  const notification = async () => {
    handleNotification(true, t('student_creation_screen.mensage_sucess'), true)
  }

  useEffect(() => {
    if (searchParams.get('showNotificationCreate')) {
      notification()
    }
  }, [])

  return (
    <>
      <Flex direction='row' justify='space-between' align='center'>
        <Flex direction='column' w='fit-content'>
          <Title align='left' order={1}>
            {t('students_index_title')}
          </Title>
          <Text color='gray.6' size='sm'>
            {t('students_sub')}
          </Text>
        </Flex>
        {!isMobile && isEmpty(actions.new) && (
          <DisabledTooltipButton label={t('students_new_button')} tooltipLabel={t('students_add_blocked')} />
        )}
        {!isMobile && !isEmpty(actions.new) && (
          <Button leftIcon={<IconPlus />} component='a' href='/learners/new' size='md'>
            {t('students_new_button')}
          </Button>
        )}
      </Flex>
    </>
  )
}
