import { useParams } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { FinalAssessment } from '@legacy/pages/FinalAssessment'
import { CurrentUserContextProvider } from 'context'

export const FinalAssessmentPage = () => {
  const { lessonId } = useParams()
  return (
    <CurrentUserContextProvider>
      <Layout>
        <FinalAssessment projectId={lessonId || ''} />
      </Layout>
    </CurrentUserContextProvider>
  )
}
