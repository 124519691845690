import { createStyles, rem } from 'hooks'

export const useStyles = createStyles(theme => ({
  thumbContainer: {
    position: 'relative',
    display: 'block',
    width: rem(267),
    height: rem(165),
    overflow: 'hidden',
    borderRadius: rem(6),
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    zIndex: 1,
  },
  video: {
    position: 'absolute',
    height: '100%',
    borderRadius: '6px',
    objectFit: 'cover',
    width: '100%',
    zIndex: 1,
  },
  actionIcon: {
    color: 'white',
    position: 'absolute',
    right: rem(8),
    bottom: rem(8),
    zIndex: 3,
  },
  icon: {
    color: 'white',
  },
}))
