import _ from 'lodash'

export const compareDateStringAndDateObject = (dateString: string, dateObjectString: string): boolean => {
  const date1 = new Date(dateString)
  const date2 = new Date(dateObjectString)

  return (
    date1.getUTCDate() === date2.getDate() &&
    date1.getUTCMonth() + 1 === date2.getMonth() + 1 &&
    date1.getUTCFullYear() === date2.getFullYear()
  )
}

export const getMinutesBetweenTimes = (start: string, end: string): number =>
  (new Date(`1970-01-01T${end}:00`).getTime() - new Date(`1970-01-01T${start}:00`).getTime()) / 60000

export const formatTime = (time: string) => new Date(time).toLocaleTimeString('en-GB', { hour12: false })
export const getEndTime = (time: string, duration: string) =>
  new Date(new Date(time).getTime() + Number(duration) * 60 * 1000).toLocaleTimeString('en-GB', { hour12: false })

export const hasChanges = (editLesson: any, form: any, initialValues: any, isEditing: boolean) => {
  const time = formatTime(editLesson?.time)
  const endTime = getEndTime(editLesson?.time, editLesson?.duration)

  const hasChangesOnEdit =
    editLesson?.title !== form.values.title ||
    editLesson?.enablePendencyJournal !== form.values.generateTasks ||
    !compareDateStringAndDateObject(editLesson?.date, form.values.lessonDate) ||
    time !== form.values.startTime ||
    endTime !== form.values.endTime

  return (!isEditing && !_.isEqual(form.values, initialValues)) || (isEditing && hasChangesOnEdit)
}
