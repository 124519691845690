import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Paper,
  TextLink,
  Image,
  Skeleton,
  Box,
  Title,
  SelectAvatar,
  Divider,
  Stack,
  EmptyStateBox,
  UserInfo,
} from 'components'
import { IconChevronLeft, IconInfoCircle } from '@tabler/icons-react'
import { FinalProjectReportHeader } from '../FinalProjectReportHeader'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { FinalProjectReportContext } from '../../context/FinalProjectReportContext'
import { useStyles } from './styles'
import { FinalProjectReportSkeleton } from '../FinalProjectReportSkeleton'
import { ProjectEvaluationSummary } from '../ProjectEvaluationSummary'
import { StudentProjectReport } from '../StudentProjectReport'
import { StudentAssessmentsReport } from '../StudentAssessmentsReport'
import { hasPermission } from 'hooks'

export const FinalProjectReport = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const isTutorOrManager = hasPermission(['manager', 'tutor'])
  const isStudentOrGuardian = hasPermission(['student', 'guardian'])
  const isTutorManagerOrGuardian = hasPermission(['tutor', 'manager', 'guardian'])

  const { classes } = useStyles()

  const {
    projectID,
    project,
    students,
    teachers,
    selectedStudent,
    setSelectedStudent,
    fetchTeacherReport,
    fetchStudentAssessments,
    fetchStudents,
    fetchTeachers,
    fetchProject,
  } = useContext(FinalProjectReportContext)

  useEffect(() => {
    if (!projectID) return
    const loadData = async () => {
      fetchProject(projectID)
    }
    loadData()
  }, [projectID])

  useEffect(() => {
    if (!project) return
    const loadData = async () => {
      fetchStudents(project.id)
      fetchTeacherReport(project.id)
      if (project && project.kind !== 'template') {
        fetchTeachers(project.id)
      }
    }
    loadData()
  }, [project])

  useEffect(() => {
    if (!students) return
    const loadData = async () => {
      if (students.length === 1) {
        setSelectedStudent(students[0].value)
      }
    }
    loadData()
  }, [students])

  useEffect(() => {
    if (!project) return
    const loadData = async () => {
      fetchStudentAssessments(project.id)
    }
    loadData()
  }, [selectedStudent])

  return (
    <>
      {project !== null ? (
        <TextLink href={`/projects/${projectID}`} size='md' iconLeft={<IconChevronLeft />}>
          {t('new_final_project_report.back_link')}
        </TextLink>
      ) : (
        <Skeleton height={20} width={140} />
      )}
      <Paper radius='md' withBorder shadow='xs' p={0} mt='xs'>
        <Image
          withPlaceholder
          width='100%'
          height={240}
          src={project !== null ? project.coverImage : ''}
          className={classes.root}
        />
        <Box p={isMobile ? 'md' : 'xl'}>
          {project === null && <FinalProjectReportSkeleton />}
          {project !== null && <FinalProjectReportHeader project={project} teachers={teachers} />}
          <Divider my='md' />
          <Stack spacing='md'>
            {isTutorOrManager && <ProjectEvaluationSummary />}
            {students !== null ? (
              <Stack spacing='sm'>
                <Title order={3}>{t('new_final_project_report.title_student_project_report')}</Title>
                {isTutorManagerOrGuardian && students.length > 1 && (
                  <SelectAvatar
                    value={selectedStudent}
                    label={t('new_final_project_report.select_label_student')}
                    placeholder={t('new_final_project_report.select_placeholder_student')}
                    nothingFound={t('new_final_project_report.select_no_items_found_student')}
                    size='lg'
                    data={students}
                    onChange={setSelectedStudent}
                  />
                )}
                {isStudentOrGuardian && students.length === 1 && (
                  <UserInfo userName={students[0].label} image={students[0].image} />
                )}
              </Stack>
            ) : (
              <Stack spacing='sm'>
                <Skeleton height={40} width={240} />
                <Skeleton height={20} width={140} />
              </Stack>
            )}
            {isTutorManagerOrGuardian && students !== null && students.length > 1 && selectedStudent === null && (
              <EmptyStateBox
                withBorder
                text={t('new_final_project_report.empty_state_title_student')}
                icon={<IconInfoCircle />}
              />
            )}
            <StudentProjectReport />
            <StudentAssessmentsReport />
          </Stack>
        </Box>
      </Paper>
    </>
  )
}
