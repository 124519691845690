import { IStudent, IGroupActions } from 'interfaces'

export interface IGroup {
  id: string
  name: string
  cycle: string
  grade: string
  students: IStudent[]
  actions: IGroupActions[]
  detailsLink: string
  gradeName: string
  avatar: string
}
