import { Layout } from 'components/Layout'
import { useEffect } from 'react'
import { NotificationProvider } from 'context'
import { NotEnabled } from './NotEnabled'

export const ErrorPermission = () => {
  useEffect(() => {
    window.setTimeout(() => {
      window.location.href = '/'
    }, 1300)
  }, [])

  return (
    <NotificationProvider>
      <Layout>
        <NotEnabled />
      </Layout>
    </NotificationProvider>
  )
}
