import React from 'react'
import GoogleDrive from '@legacy/common/Icons/GoogleDrive'
import { GButton, GWrapper } from './styled'
import { useTranslation } from 'react-i18next'

const UploadGoogle: React.FC = () => {
  const { t } = useTranslation()

  return (
    <GButton>
      <GWrapper>
        <GoogleDrive />
        <span> {t('general_buttons_google_drive')}</span>
      </GWrapper>
    </GButton>
  )
}

export default UploadGoogle
