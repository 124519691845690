/* eslint-disable react/no-danger */
import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Title } from '@mantine/core'
import BlankSlate from '../../../_atoms/BlankSlate'
import { InputSelect, Loading } from '../../../_components'
import { IStudentsGroup } from '../../../services/students-types'
import { useReportsGroup } from './reports-group-hook'
import {
  StyledFiltersBlock,
  StyledStudentReportFrame,
} from '../reports_student/reports-student-styled'
import { IGrade } from './reports-group-interfaces'
import { IInputSelectOption } from '../reports_student/reports-student-interfaces'
import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context'
import { SchoolPeriodType } from '@legacy/common/models/school'
import { Heading } from 'components'

export function ReportsGroup() {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const isLumiarSchool = useMemo(
    () => currentUser?.selected_school?.period_type === SchoolPeriodType.Cycle,
    [currentUser]
  )

  const hook = useReportsGroup(isLumiarSchool)

  const gradesValues = useMemo(() => {
    const allValue = {
      label: t('activerecord_models_grade_all'),
      id: 'all',
    }
    if (hook.grades) {
      return [allValue, ...fromGradesToOptions(hook.grades.items)]
    }

    return [allValue]
  }, [hook.grades])

  if (!currentUser || hook.groups.loading) {
    return <Loading />
  }

  if (currentUser.profile_used === 'tutor' && currentUser?.group_school_terms_count === 0) {
    return window.location.replace('/teacher/dashboard')
  }

  if (hook.groups.data.length === 0) {
    return <BlankSlate message={t('timetables.groups_by_school_term.empty')} />
  }

  return (
    <>
      <div className='mb-5'>
        <Heading
          size='xl'
          text={t('reports_dashboard.index.students_group_title')}
          supportText={t('reports_dashboard.index.students_group_description')}
        ></Heading>
      </div>
      <div>
        <StyledFiltersBlock>
          <Title order={5} mb='md'>
            {t('reports_dashboard.index.filters_title')}
          </Title>
          <Grid gutter='sm'>
            <Grid.Col span={6}>
              <InputSelect
                clearable={false}
                label={t('school_management_students_class_information_label1')}
                id='select-group-report-group'
                value={hook.selected.groupId}
                name='groupReportGroup'
                placeholder='Selecione'
                onChange={({ value }: { value: string }) =>
                  hook.changeGroup(value)
                }
                values={fromApiGroupOptions(hook.groups.data)}
              />
            </Grid.Col>
            {isLumiarSchool && (
              <Grid.Col span={6}>
                <InputSelect
                  clearable={false}
                  id='select-group-report-year'
                  value={hook.selected.year}
                  name='groupReportYear'
                  label={t('activerecord_models_grade_one')}
                  onChange={({ value }: { value: string }) =>
                    hook.changeYear(value)
                  }
                  values={gradesValues}
                  disabled={isEmpty(hook.selected.groupId)}
                />
              </Grid.Col>
            )}
          </Grid>
        </StyledFiltersBlock>
        {isEmpty(hook.selected.groupId) ? (
          <BlankSlate message={t('swal_student_report_blank_slate')} />
        ) : (
          <>
            <StyledStudentReportFrame
              src={hook.url}
              frameBorder={0}
              scrolling='no'
            />
            <StyledFiltersBlock>
              <h5>{t('reports_dashboard.index.students_legend_title')}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('reports_dashboard.index.students_group_legend'),
                }}
              />
            </StyledFiltersBlock>
          </>
        )}
      </div>
    </>
  )
}

function fromApiGroupOptions(raw: IStudentsGroup[]): IInputSelectOption[] {
  return raw.map((o) => ({ label: o.name, id: o.groupSchoolTermId }))
}

function fromGradesToOptions(raw: IGrade[]): IInputSelectOption[] {
  return raw.sort((o) => o.sequence).map((o) => ({ label: o.name, id: o.id }))
}
