import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Gift from './giftIcon'
import { Tooltip, Box, UnstyledButton } from 'components'
import styled from 'styled-components'
import { useMixPanel } from 'hooks'

const ProduktlyTrigger = styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  top: -16px;
  left: 0px;
`

const NewsButton = () => {
  const { i18n, t } = useTranslation()
  const [id, setId] = useState('news-en' as string)
  const mixpanel = useMixPanel()

  const checkLanguage = () => {
    if (i18n.language === 'pt-BR') {
      setId('news-pt')
    } else {
      setId('news-en')
    }
  }

  useEffect(() => {
    checkLanguage()
  }, [])

  useEffect(() => {
    checkLanguage()
  }, [i18n.language])

  return (
    <Box mr='xs' h={32} w={32}>
      <Tooltip label={t('mosaic_news')} position='bottom' withArrow withinPortal>
        <UnstyledButton
          component='a'
          target='_blank'
          rel='noreferrer'
          sx={{ position: 'relative' }}
          onClick={() => {
            mixpanel.track('Acessar novidades')
          }}
        >
          <ProduktlyTrigger id={id} />
          <Gift />
        </UnstyledButton>
      </Tooltip>
    </Box>
  )
}

export default NewsButton
