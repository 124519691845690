import styled from 'styled-components'

export const PaperWrapper = styled.div<{
  elevation: number
  absolute: boolean
  disabled: boolean
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-radius: 6px;
  height: 100%;
  min-height: 100%;
  width: ${props => (props.absolute ? 'max-content' : '100%')};
  max-width: ${props => (props.absolute ? 'max-content' : '100%')};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'auto')};

  box-shadow: ${props =>
    props.elevation === 1 ? '0px 0px 5px rgba(0, 0, 0, 0.2);' : ''};
  box-shadow: ${props =>
    props.elevation === 2 ? '0px 0px 6px rgba(0, 0, 0, 0.4);' : ''};
  box-shadow: ${props =>
    props.elevation === 3 ? '0px 0px 7px rgba(0, 0, 0, 0.6);' : ''};
`
