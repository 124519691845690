import { Grid, Stack, Skeleton } from 'components'

export const LessonListSkeleton = () => {
  return (
    <Grid mt='md'>
      <Grid.Col span='content'>
        <Skeleton height={400} width={30} />
      </Grid.Col>
      <Grid.Col span='auto'>
        <Stack spacing='md'>
          <Stack spacing='xs'>
            <Skeleton height={30} />
            <Skeleton height={100} />
          </Stack>
          <Stack spacing='xs'>
            <Skeleton height={30} />
            <Skeleton height={100} />
          </Stack>
          <Stack spacing='xs'>
            <Skeleton height={30} />
            <Skeleton height={100} />
          </Stack>
        </Stack>
      </Grid.Col>
    </Grid>
  )
}
