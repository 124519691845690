import { useContext } from 'react'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { TeacherDashboardContext } from 'pages/TeacherDashboard/context'
import { Tabs, Carousel, EmptyStateBox } from 'components'
import { carouselDashboard, useCaroselStyles } from 'components/Carousel'
import { ProjectsSkeleton } from '../ProjectsSkeleton'
import { ProjectsPanelProps } from './interfaces'
import { useTranslation } from 'react-i18next'
import { IconArticleOff } from '@tabler/icons-react'
import { ProjectsSlide } from '../ProjectsSlide'

export const ProjectsPanel = ({ activeTab }: ProjectsPanelProps) => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useCaroselStyles()
  const { t } = useTranslation()

  const { projects } = useContext(TeacherDashboardContext)

  const filteredProjects = projects !== null && projects.filter(project => project.status === activeTab)
  const showProjectsIndicator =
    (isMobile && filteredProjects && filteredProjects.length > 2) || (filteredProjects && filteredProjects.length > 3)

  return (
    <Tabs.Panel value={activeTab} key={activeTab}>
      {projects === null ? (
        <ProjectsSkeleton />
      ) : (
        filteredProjects &&
        filteredProjects.length > 0 && (
          <Carousel
            {...carouselDashboard}
            withIndicators={false}
            withControls={showProjectsIndicator}
            draggable={filteredProjects && filteredProjects.length > 1}
            classNames={{
              root: classes.carousel,
              controls: classes.carouselControls,
            }}
          >
            {filteredProjects.map(project => (
              <ProjectsSlide key={project.id} project={project} />
            ))}
          </Carousel>
        )
      )}
      {filteredProjects && filteredProjects.length === 0 && (
        <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('new_teacher_dashboard.no_projects')} />
      )}
    </Tabs.Panel>
  )
}
