import React, { Component } from "react";
import PropTypes from "prop-types";
import InputText from "./InputText";
import "../../assets/stylesheets/search.scss";

class InputTextSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.value,
      typingTimer: null,
    };
  }

  onEnter() {
    this.props.fetchSearch(this.state.search);
  }

  search(target) {
    const customTarget = target;
    const typingInterval = 500;
    const { fetchOnEnter, fetchSearch } = this.props;

    if (!fetchOnEnter) {
      customTarget.onkeydown = () => {
        clearTimeout(this.state.typingTimer);
        this.setState({
          typingTimer: setTimeout(
            () => fetchSearch(customTarget.value),
            typingInterval
          ),
        });
      };
    }

    this.setState({ search: customTarget.value });
  }

  render() {
    const { label, errors } = this.props;

    return (
      <div className="form-field">
        <div className="input-search">
          <InputText
            id="input-search"
            value={this.state.search}
            label={label}
            onChange={(event) => this.search(event.target)}
            onEnter={() => this.onEnter()}
          />

          <i
            tabIndex={0}
            role="button"
            className="icon-search"
            onClick={() => this.onEnter()}
            onKeyPress={() => this.onEnter()}
          />
        </div>
        <span className="validation-error">{errors}</span>
      </div>
    );
  }
}

InputTextSearch.propTypes = {
  label: PropTypes.string.isRequired,
  fetchSearch: PropTypes.func.isRequired,
  value: PropTypes.string,
  fetchOnEnter: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.string),
};

InputTextSearch.defaultProps = {
  value: "",
  fetchOnEnter: false,
  errors: [],
};

export default InputTextSearch;
