import { api } from 'helpers'

export interface IResponseSaveData {
  readonly errors?: any
}

export interface IPayload {
  readonly email?: string
}

export default {
  saveEmail: async (payload: any) => {
    const response = await api.post<any>('/api/users/reset_password', payload)
    return response.data
  },
}

export const validateToken = async (token: string): Promise<any[] | boolean> => {
  try {
    const response = await api.get<any[]>(`/api/users/validate_token?reset_password_token=${token.toString()}`)
    return response.data
  } catch (error: any) {
    throw new Error(`Error validating token: ${error.message}`)
  }
}

export const updatePassword = async (data: any): Promise<any[]> => {
  try {
    const response = await api.post<any[]>('/api/users/update_password', data)
    return response.data
  } catch (error) {
    return Promise.reject('Error on update password')
  }
}
