import { ISessionMeetingActivityActions } from 'interfaces'
export interface IActivity {
  readonly id: string
  sortPosition: number
  order: number
  title: string
  hasAssessment: boolean | null
  hasTasks: boolean | null
  activityActions?: ISessionMeetingActivityActions
}
