import { useTranslation } from 'react-i18next'
import { Grid, Button } from 'components'
import { IconEdit } from '@tabler/icons-react'

interface ProjectEditActionsProps {
  editURL: string
}

export const ProjectEditActions = ({ editURL }: ProjectEditActionsProps) => {
  const { t } = useTranslation()

  return (
    <Grid justify='flex-start' gutter='sm'>
      <Grid.Col span={12} sm='auto'>
        <Button component='a' fullWidth leftIcon={<IconEdit />} variant='light' size='md' radius='xl' href={editURL}>
          {t('general_buttons_edit')}
        </Button>
      </Grid.Col>
    </Grid>
  )
}
