import { Group, Text } from 'components'
import { useTranslation } from 'react-i18next'

export const TextRequired = () => {
  const { t } = useTranslation()
  return (
    <Group spacing={4} mb='md'>
      <Text size='sm' color='red'>
    *
      </Text>
      <Text fs='italic' size='sm'>
        {t('new_project_page.fields_with_asterisk')}
      </Text>
    </Group>
  )
}