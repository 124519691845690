import React from 'react'
import { StyledStudentReport } from './StudentReport.styled'

export function IconStudentReport () {
  return (
    <StyledStudentReport
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14C3 13.4477 3.44772 13 4 13H6C6.55228 13 7 13.4477 7 14V21H3V14Z"
        strokeWidth="1.5"
      />
      <path
        d="M10 5C10 4.44772 10.4477 4 11 4H13C13.5523 4 14 4.44772 14 5V21H10V5Z"
        strokeWidth="1.5"
      />
      <path
        d="M17 9C17 8.44772 17.4477 8 18 8H20C20.5523 8 21 8.44772 21 9V21H17V9Z"
        strokeWidth="1.5"
      />
    </StyledStudentReport>
  )
}
