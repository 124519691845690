import { ScrollArea } from 'hooks/ScrollArea'
import { Text } from 'components/Text'
import { useTranslation } from 'react-i18next'

export const ProjectHelpModals = () => {
  const { t } = useTranslation()

  const NameModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_project_name'),
    children: (
      <>
        <Text sx={{ whiteSpace: 'pre-line' }}>{t('new_project_page.help_project_name_1')}</Text>
        <br />
        <Text sx={{ whiteSpace: 'pre-line' }}>{t('new_project_page.help_project_name_2')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example')}</Text>
        <Text sx={{ whiteSpace: 'pre-line' }}>{t('new_project_page.help_project_name_3')}</Text>
      </>
    ),
  }

  const GuidingQuestionModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_guiding_question'),
    children: (
      <>
        <Text>{t('new_project_page.help_guiding_question_1')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '1' })}</Text>
        <Text sx={{ whiteSpace: 'pre-line' }}>{t('new_project_page.help_guiding_question_2')}</Text>
      </>
    ),
  }

  const SummaryModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_summary'),
    children: (
      <>
        <Text>{t('new_project_page.help_summary_1')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '1' })}</Text>
        <Text>{t('new_project_page.help_summary_2')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '2' })}</Text>
        <Text>{t('new_project_page.help_summary_3')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '3' })}</Text>
        <Text>{t('new_project_page.help_summary_4')}</Text>
      </>
    ),
  }
  const ObjectivesModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_objectives'),
    children: (
      <>
        <Text>{t('new_project_page.help_objectives_1')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '1' })}</Text>
        <Text>{t('new_project_page.help_objectives_2')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '2' })}</Text>
        <Text>{t('new_project_page.help_objectives_3')}</Text>
      </>
    ),
  }
  const FinalProductModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_final_product'),
    children: (
      <>
        <Text>{t('new_project_page.help_final_product_1')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '1' })}</Text>
        <Text>{t('new_project_page.help_final_product_2')}</Text>
        <br />
        <Text fw='bold'>{t('new_project_page.help_example_number', { number: '2' })}</Text>
        <Text>{t('new_project_page.help_final_product_3')}</Text>
      </>
    ),
  }

  const EvaluationModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.label_evaluation'),
    children: (
      <>
        <Text>{t('new_project_page.help_evaluation_1')}</Text>
        <br />
        <Text>{t('new_project_page.help_evaluation_2')}</Text>
        <br />
      </>
    ),
  }

  const StagesModal = {
    size: 'lg',
    scrollAreaComponent: ScrollArea.Autosize,
    title: t('new_project_page.stages_modal'),
    children: (
      <>
        <Text>{t('new_project_page.stages_modal_1')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_2')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_3')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_4')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_5')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_6')}</Text>
        <br />
        <Text>{t('new_project_page.stages_modal_7')}</Text>
        <br />
      </>
    ),
  }

  return {
    NameModal,
    GuidingQuestionModal,
    SummaryModal,
    ObjectivesModal,
    FinalProductModal,
    EvaluationModal,
    StagesModal,
  }
}
export default ProjectHelpModals
