export enum SchoolLabel {
  Default = 'default',
  EscolaHub = 'escolahub',
  Branding = 'branding',
}

export enum SchoolLogoColor {
  White = 'white',
  Black = 'black',
  Primary = 'primary',
  Text = 'text',
}
