import { UnstyledButton, Checkbox, Text } from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import { useStyles } from './styles'
import { WeekDayButtonProps } from './interfaces'

export function WeekDayButton({
  index,
  handleDayClicked,
  checked,
  defaultChecked,
  onChange,
  title,
  disabled,
  ...others
}: WeekDayButtonProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof WeekDayButtonProps>) {
  const { classes, cx } = useStyles()

  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked || checked,
    finalValue: false,
    onChange,
  })

  return (
    <UnstyledButton
      {...others}
      disabled={disabled}
      onClick={() => {
        handleChange(!value)
        handleDayClicked(index)
      }}
      className={cx(classes.button, value ? classes.checked : null)}
    >
      <Checkbox hidden disabled={disabled} checked={value} tabIndex={-1} size='sm' className={classes.checkbox} />
      <Text>{title}</Text>
    </UnstyledButton>
  )
}
