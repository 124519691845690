import { Layout } from 'components'
import { ReportsStudent as LegacyReportsStudent } from '@legacy/common/components/apps/reports_student'
import { CurrentUserContextProvider } from 'context'

export function ReportsStudent() {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <LegacyReportsStudent />
      </Layout>
    </CurrentUserContextProvider>
  )
}
