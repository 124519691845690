import { ISelectedPayload } from './reports-student-hook'
import { IPayload, QuestionNumbers } from './reports-student-interfaces'

export function getPayloadParams (
  question: QuestionNumbers,
  payload: ISelectedPayload,
  curriculumSchoolId?: string,
  curriculumId?: string
): IPayload {
  switch (question) {
    case 122:
      return {
        resource: { question: 122 },
        params: {
          curriculum_school_id: curriculumSchoolId ?? '',
          curriculum_id: curriculumId ?? '',
          cycle_id: payload.cycleId ?? '',
          student_id: payload.studentId
        }
      }
    case 125:
      return {
        resource: { question: 125 },
        params: {
          curriculum_school_id: curriculumSchoolId ?? '',
          student_id: payload.studentId,
          lv1_item_id: payload.level1,
          cycle_id: payload.cycleId ?? '',
        },
      }
    case 126:
      return {
        resource: { question: 126 },
        params: {
          curriculum_school_id: curriculumSchoolId ?? '',
          student_id: payload.studentId,
          curriculum_id: curriculumId ?? '',
          grade_id: payload.gradeId ?? '',
        },
      }
    case 127:
      return {
        resource: { question: 127 },
        params: {
          curriculum_school_id: curriculumSchoolId ?? '',
          student_id: payload.studentId,
          lv1_item_id: payload.level1,
          grade_id: payload.gradeId ?? '',
        },
      }
    default:
      throw new Error('Not implemented yet.')
  }
}
