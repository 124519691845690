import { useTranslation } from 'react-i18next'
import { IconProfile } from 'components/Icons'
import { useCurrentUser } from 'context/useCurrentUser'
import { paths } from '../paths'
import { DropdownMenuSeparatorStyled } from '../NavbarMenuStyled'

import {
  NameStyled,
  DropdownMenuItemStyled,
  LinkStyled,
  NameContainerStyled,
} from './ProfileMenuStyled'

export const ProfileMenu = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  return (
    <>
      <DropdownMenuItemStyled>
        <IconProfile />
        <NameContainerStyled>
          <NameStyled>{currentUser?.full_name}</NameStyled>
          <LinkStyled href={paths.account.myProfile(currentUser?.profile_url || '')}>
            {t('general_profiles_my')}
          </LinkStyled>
        </NameContainerStyled>
      </DropdownMenuItemStyled>
      <DropdownMenuSeparatorStyled />
    </>
  )
}
