import { useEditor } from '@tiptap/react'
import { RichTextEditor } from '@mantine/tiptap'
import { Box, Input, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useEffect, useCallback } from 'react'
import { RichTextAreaProps } from './interface'
import { useStyles } from './styles'
import Focus from '@tiptap/extension-focus'
import TextStyle from '@tiptap/extension-text-style'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'

export function RichTextArea({
  label,
  description,
  required,
  withAsterisk,
  size,
  minHeight,
  maxHeight,
  rightSection,
  disabled,
  onChange,
  value,
}: RichTextAreaProps) {
  const { classes } = useStyles({ minHeight, maxHeight })
  const theme = useMantineTheme()
  const { t } = useTranslation()

  const labels = {
    linkControlLabel: t('richtextarea.link'),
    boldControlLabel: t('richtextarea.bold'),
    italicControlLabel: t('richtextarea.italic'),
    underlineControlLabel: t('richtextarea.underline'),
    clearFormattingControlLabel: t('richtextarea.clear_formatting'),
    unlinkControlLabel: t('richtextarea.remove_link'),
    bulletListControlLabel: t('richtextarea.bullet_list'),
    orderedListControlLabel: t('richtextarea.ordered_list'),
    subscriptControlLabel: t('richtextarea.subscript'),
    superscriptControlLabel: t('richtextarea.superscript'),
    linkEditorInputLabel: t('richtextarea.enter_url'),
    linkEditorInputPlaceholder: 'https://website.com/',
    linkEditorExternalLink: t('richtextarea.open_link_in_new_tab'),
    linkEditorInternalLink: t('richtextarea.open_link_in_the_same_tab'),
    linkEditorSave: t('richtextarea.save'),
  }

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Superscript, SubScript, TextStyle, Focus],
    content: value || '',
    editable: !disabled,
    onUpdate: useCallback(
      ({ editor }: { editor: any }) => {
        onChange && onChange(editor.getHTML())
      },
      [onChange],
    ),
  })

  useEffect(() => {
    if (editor !== null && value !== editor.getHTML()) {
      editor
        .chain()
        .setContent(value || '')
        .run()
    }
  }, [value, editor])

  useEffect(() => {
    if (editor) {
      editor.setEditable(!disabled)
    }
  }, [disabled, editor])

  return (
    <Input.Wrapper
      label={label}
      description={description}
      required={!!required}
      withAsterisk={withAsterisk}
      size={size}
    >
      <RichTextEditor mt={4} classNames={classes} labels={labels} editor={editor}>
        <RichTextEditor.Toolbar>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.ClearFormatting />
            <RichTextEditor.Blockquote />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
            <RichTextEditor.Subscript />
            <RichTextEditor.Superscript />
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>
          <RichTextEditor.ControlsGroup>
            <Box>{rightSection}</Box>
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content sx={{ backgroundColor: disabled && disabled === true ? theme.colors.gray[0] : '' }} />
      </RichTextEditor>
    </Input.Wrapper>
  )
}
