import Swal from 'sweetalert2'
import i18n from '@i18n'
import './customSwal.scss'

export enum IKind {
  Default = 'default',
  Confirmation = 'confirmation',
  WihoutSubText = 'withoutSubText',
  NoClose = 'noClose',
  ConfirmationExit = 'confirmationExit',
  deleteFeed = 'deleteFeed',
  publishFeed = 'publishFeed',
  privateFeed = 'privateFeed',
  pendentInformationFeed = 'pendentInformationFeed',
}

export interface IDefaultModal {
  kind: IKind
  title: string
  text?: string
}

export const Modal = ({ title, kind, text }: IDefaultModal) => {
  const swalWithCustomCss = Swal.mixin({
    customClass: {
      title: 'custom-swal-title',
      htmlContainer: 'custom-swal-html-container',
      confirmButton: 'new-buttons new-buttons-primary',
      cancelButton: 'new-buttons new-buttons-light mr-3',
    },
    buttonsStyling: false,
  })
  switch (kind) {
    case 'confirmation':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('general_buttons_confirm'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        cancelButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    case 'confirmationExit':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('close_no_save'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        cancelButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    case 'deleteFeed':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('general_buttons_remove'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    case 'publishFeed':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('feed_publish'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    case 'privateFeed':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('feed_make_private'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    case 'pendentInformationFeed':
      return swalWithCustomCss.fire({
        title: title,
        text: text,
        showCancelButton: true,
        confirmButtonText: i18n.t('feed_edit'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
        width: 400,
        padding: '32px',
      })

    default:
      return Swal.fire({
        title: title,
        text: text,
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: i18n.t('general_buttons_confirm'),
        denyButtonText: i18n.t('general_buttons_cancel'),
        cancelButtonText: i18n.t('general_buttons_cancel'),
        reverseButtons: true,
      })
  }
}
