import {
  Modal,
  Stack,
  Box,
  Button,
  TextInput,
  CardAvatarCheckboxModal,
  Badge,
  Flex,
  Text,
  Skeleton,
  ActionIcon,
} from 'components'
import { ScrollArea, useListState, useMediaQuery, useMantineTheme } from 'hooks'
import { useTranslation } from 'react-i18next'
import { useContext, useState, useEffect, useRef } from 'react'
import { ParticipantsContext, ITeacher } from '../../context/ParticipantsContext'
import { notifications } from 'context'
import { IconCheck, IconSearch, IconX } from '@tabler/icons-react'
import { useStyles } from './OtherTeachersModalStyles'
import { deburr } from 'lodash'
import { v4 as uuid } from 'uuid'
import { ChangedDataConfirmModal } from 'components/ChangedDataConfirmModal'

interface TeacherValueProps {
  id: string
}

export function OtherTeachersModal({ loading }: { loading: boolean }) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [showLostadaModal, setShowLostadaModal] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState<any[]>([])
  const [itemsCount, setItemsCount] = useState<number>(10)
  const sentinelRef = useRef<HTMLDivElement>(null)

  const fetchMoreData = () => {
    setItemsCount(prevCount => prevCount + 10)
  }

  const [teachersValues, teachersHandlers] = useListState<TeacherValueProps>()

  const {
    teachersModalOpened,
    closeTeachersModal,
    otherTeachers,
    selectedOtherTeachers,
    selectedOtherTeachersHandlers,
  } = useContext(ParticipantsContext)

  const handleCheckChange = (checked: boolean, id: string) => {
    if (checked === true) {
      teachersHandlers.append({ id: id })
    }
    if (checked === false) {
      const teachersPosition = teachersValues.findIndex(teacher => teacher.id === id)
      teachersHandlers.remove(teachersPosition)
    }
  }

  const checkTeacherAdded = (id: string) => {
    const teacherAdded = selectedOtherTeachers.find(teacher => teacher.id === id)
    if (teacherAdded) {
      return true
    } else {
      return false
    }
  }

  const filterTeachers = (teachers: any[], term: string) => {
    setItemsCount(10)

    if (!Array.isArray(teachers)) {
      return []
    }

    const lowerCaseTerm = deburr(term.toLowerCase())

    return teachers.filter(
      teacher =>
        (typeof teacher.name === 'string' && teacher.name.toLowerCase().includes(lowerCaseTerm)) ||
        teacher.email.toLowerCase().includes(lowerCaseTerm),
    )
  }

  useEffect(() => {
    if (filterTeachers !== undefined && Array.isArray(otherTeachers)) {
      const filteredTeachers = filterTeachers(otherTeachers, searchTerm) as { id: string }[]

      if (Array.isArray(filteredTeachers)) {
        const sortedTeachers = filteredTeachers.sort((a, b) => +checkTeacherAdded(b.id) - +checkTeacherAdded(a.id))

        const updatedTeachers = sortedTeachers.map(teacher => {
          return {
            ...teacher,
            checked: !!teachersValues.find(item => item.id === teacher.id),
          }
        })

        setFilteredData(updatedTeachers)
      }
    }
  }, [otherTeachers, searchTerm])

  useEffect(() => {
    if (Array.isArray(otherTeachers)) {
      setFilteredData([...otherTeachers].sort((a, b) => +checkTeacherAdded(b.id) - +checkTeacherAdded(a.id)))
    }
  }, [otherTeachers, teachersModalOpened])

  const addTeachers = () => {
    if (teachersValues.length > 0) {
      const newTeachers = teachersValues.map(teacher =>
        selectedOtherTeachers.find(otherTeacher => otherTeacher.id === teacher.id)
          ? null
          : otherTeachers.find(otherTeacher => otherTeacher.id === teacher.id),
      ) as ITeacher[]
      const filteredNewTeachers = newTeachers.filter(teacher => teacher !== null)
      const addTeachers = selectedOtherTeachers.concat(filteredNewTeachers)

      const uniqueTeachers = new Set()
      const unique = addTeachers.filter(teacher => {
        if (!uniqueTeachers.has(teacher.id)) {
          uniqueTeachers.add(teacher.id)
          return true
        }
        return false
      })

      selectedOtherTeachersHandlers.setState(unique)
      teachersHandlers.setState([])

      notifications.show({
        message: t('new_project_page.message_teachers_added'),
        color: 'green',
        icon: <IconCheck size={24} />,
      })
      closeTeachersModal()
    }
    if (teachersValues.length === 0) {
      notifications.show({
        message: t('new_project_page.message_no_teachers_to_add'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    }
  }

  const sentinelHandler: IntersectionObserverCallback = entries => {
    const sentinel = entries[0]

    if (sentinel.isIntersecting) {
      fetchMoreData()
    }
  }

  useEffect(() => {
    if (teachersModalOpened === false) {
      setSearchTerm('')
      setShowLostadaModal(false)
      setItemsCount(10)
    }

    const observer = new IntersectionObserver(sentinelHandler)

    setTimeout(() => {
      const sentinel = sentinelRef.current
      if (sentinel) {
        observer.observe(sentinel)
      }
    }, 1000)

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [sentinelRef, teachersModalOpened])

  const handleCloseModal = () => {
    const hasStudentValues = teachersValues && teachersValues.length > 0

    if (hasStudentValues) {
      setShowLostadaModal(true)
      return
    }

    closeTeachersModal()
  }

  const handleAlertDataModal = () => {
    setShowLostadaModal(false)
  }

  const handleAlertDataModalDiscart = () => {
    setShowLostadaModal(false)
    teachersHandlers.setState([])
    closeTeachersModal()
  }

  return (
    <Modal
      zIndex={100}
      size='lg'
      opened={teachersModalOpened}
      onClose={handleCloseModal}
      scrollAreaComponent={ScrollArea.Autosize}
      title={t('new_project_page.add_other_teachers')}
      fullScreen={isMobile}
    >
      <ChangedDataConfirmModal
        opened={showLostadaModal}
        close={handleAlertDataModal}
        discard={handleAlertDataModalDiscart}
      />

      <Box className={classes.search}>
        <TextInput
          mt={0}
          w={'100%'}
          icon={<IconSearch size='1.1rem' stroke={1.5} />}
          radius='xl'
          size='md'
          placeholder={t('new_project_page.search_teachers_by_name')}
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          rightSection={
            <ActionIcon
              onClick={() => {
                setSearchTerm('')
              }}
            >
              <IconX size='1.1rem' stroke={1.5} />
            </ActionIcon>
          }
        />
      </Box>

      {loading && (
        <Stack spacing='sm' mt='90px' className={classes.content}>
          {Array.from({ length: 10 }).map(() => (
            <Skeleton key={uuid()} width='100%' height={72}></Skeleton>
          ))}
        </Stack>
      )}

      <Stack spacing='sm' mt='90px' className={classes.content}>
        {filteredData.length > 0 ? (
          filteredData.slice(0, itemsCount).map(teacher => {
            return (
              <CardAvatarCheckboxModal
                selecteds={teachersValues}
                key={teacher.id}
                id={teacher.id}
                role={teacher.role}
                avatar={teacher.avatar}
                name={teacher.name}
                description={teacher.email}
                value={teacher.id}
                onChange={value => handleCheckChange(value, teacher.id)}
                tag={checkTeacherAdded(teacher.id) ? <Badge>{t('new_project_page.added')}</Badge> : undefined}
                disabled={checkTeacherAdded(teacher.id)}
              />
            )
          })
        ) : (
          <Flex>
            <Text>{!loading && t('new_project_page.empty_data')} </Text>
          </Flex>
        )}
        <div ref={sentinelRef} style={{ height: '10px', background: 'white', width: '100%' }} />
      </Stack>
      <Box className={classes.footer}>
        <Button variant='default' size='lg' onClick={handleCloseModal}>
          {t('general_buttons_cancel')}
        </Button>
        <Button variant='filled' size='lg' onClick={addTeachers}>
          {t('general_buttons_add')}
        </Button>
      </Box>
    </Modal>
  )
}
