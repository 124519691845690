import { createStyles, rem, em, getBreakpointValue } from 'hooks'

export const useStyles = createStyles(theme => ({
  root: {
    position: 'relative',
  },
  body: {
    minHeight: '81vh',
    height: 'fit-content',
  },
  footer: {
    zIndex: 100,
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      paddingTop: theme.spacing.sm,
    },
    [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      margin: `-${theme.spacing.md} !important`,
      position: 'sticky',
      bottom: 0,
      padding: `${theme.spacing.sm} ${theme.spacing.xs}`,
    },
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
    borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.gray[2]}`,
  },
  stack: {
    minHeight: '81vh',
    height: 'fit-content',
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      paddingBottom: theme.spacing.sm,
    },
    [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      paddingBottom: rem(70),
    },
  },
}))
