import { CarouselProps } from '@mantine/carousel'
import { createStyles, getStylesRef, rem } from '@mantine/core'

export const useCaroselStyles = createStyles({
  carousel: {
    '&:hover': {
      [`& .${getStylesRef('carouselControls')}`]: {
        opacity: 1,
      },
    },
  },

  carouselControls: {
    ref: getStylesRef('carouselControls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
  },
})

export const carouselDashboard: CarouselProps = {
  withIndicators: true,
  loop: false,
  withKeyboardEvents: true,
  height: 'fit-content',
  slideSize: '33.333333%',
  slideGap: 'md',
  slidesToScroll: 'auto',
  align: 'start',
  breakpoints: [
    { maxWidth: 'md', slideSize: '50%' },
    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
  ],

  styles: {
    root: {
      paddingBottom: rem(32),
    },
    indicators: {
      position: 'relative',
      bottom: '-20px',
    },
    indicator: {
      border: '1px solid gray',
      width: 12,
      height: 12,
      transition: 'width 250ms ease',
      '&[data-active]': {
        backgroundColor: 'turquoise',
        border: '1px solid turquoise',
      },
    },
  },
}
