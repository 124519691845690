const Close = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill="var(--color-gray-dark)"
        fillRule="evenodd"
        d="M15.727 3.593a.933.933 0 10-1.32-1.32L9 7.68 3.593 2.273a.933.933 0 
        10-1.32 1.32L7.68 9l-5.407 5.407a.933.933 0 101.32 1.32L9 10.32l5.407 
        5.407a.933.933 0 101.32-1.32L10.32 9l5.407-5.407z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Close
