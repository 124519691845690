import { ProfilePic } from "@legacy/common/_components/ProfilePic"
import { ITutor } from "../../interfaces"
import { Avatar, AvatarText, Link } from "./styled"

export const AvatarCard = ({ tutor }: {tutor: ITutor}) => {
    return (
        <Link href={tutor.link}>
            <Avatar className="mb-2">
                <ProfilePic img={tutor.avatar} fullName={tutor.name} />
                <AvatarText>
                    <span>{tutor.name}</span><br />
                    <small>{tutor.educator_role}</small>
                </AvatarText>
            </Avatar>
        </Link>
    )
}