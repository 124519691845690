import PropTypes from 'prop-types'

const ButtonCard = props => {
  const {
    callback, className, description, htmlFor, iconClass,
  } = props

  return (
    typeof callback === 'function' ? (
      <span
        className={`new-buttons new-buttons-light new-buttons-small cursor-pointer mb-0 ${className}`}
        type="button"
        role="button"
        onClick={callback}
        onKeyPress={callback}
        tabIndex={0}
      >
        <i className={`${iconClass} `} />
        {description}
      </span>
    ) : (
      <label
        className={`new-buttons new-buttons-light new-buttons-small cursor-pointer mb-0 ${className}`}
        htmlFor={htmlFor}
      >
        <i className={`${iconClass} `} />
        {description}
      </label>
    )
  )
}

ButtonCard.propTypes = {
  callback: PropTypes.func,
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
}

ButtonCard.defaultProps = {
  className: '',
  callback: null,
  htmlFor: '',
}

export default ButtonCard
