/* global I18n */
/* eslint-disable */
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-bootstrap/Modal'

class RubricsModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }
  }

  toggleModal() {
    this.setState((prevState) => {
      prevState.showModal = !prevState.showModal
      return prevState
    })
  }

  render() {
    const { showModal } = this.state
    const { rubricType, rubricScores } = this.props
    const translateKey = rubricType == 'skill' ? 'specific_skill' : 'content'

    return (
      <Fragment>
        <i
          role='button'
          tabIndex={0}
          className='icon-sm-question help-hint-icon'
          onClick={() => this.toggleModal()}
          onKeyPress={() => this.toggleModal()}
        />

        <div>
          <Modal
            show={showModal}
            onHide={() => this.toggleModal()}
            dialogClassName='animated fast'
          >
            <Modal.Body>
              <div className='modal-body'>
                <div className='right-text'>
                  <button
                    type='button'
                    className='close'
                    onClick={() => this.toggleModal()}
                  >
                    <span>×</span>
                  </button>
                </div>

                <h2 className='text-center malva-bold'>
                  {I18n.t('rubrics.title')}
                </h2>

                <div className='p-5'>
                  {[1, 2, 3, 4, 5].map((score) => (
                    <div
                      key={score}
                      className='d-flex align-items-center mediumgray-color px-5 py-4'
                    >
                      <i className={`icon-hand-${score} large-icon mr-5`} />

                      <div>
                        <div className='malva-bold'>
                          {I18n.t(
                            `rubrics.${translateKey}.score${score}.title`
                          )}
                        </div>
                        <div className='subtitle m-0 mr-2'>
                          {rubricScores && rubricScores[score - 1]}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </Fragment>
    )
  }
}

RubricsModal.propTypes = {
  rubricType: PropTypes.oneOf(['skill', 'content']).isRequired,
  rubricScores: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
}

export default RubricsModal
