import { createStyles } from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      withBorder,
    }: {
      withBorder: boolean | undefined
    },
  ) => ({
    emptystatebox: {
      border: withBorder
        ? `1px dashed ${theme.colorScheme === 'dark'
          ? theme.colors.gray[8]
          : theme.colors.gray[4]}`
        : 'none',
      padding: theme.spacing.md,
      borderRadius: theme.radius.md,
      '& > svg': {
        color: theme.colorScheme === 'dark'
          ? theme.colors.gray[5]
          : theme.colors.gray[6],
      },
    },
    text: {
      color: theme.colorScheme === 'dark'
        ? theme.colors.gray[5]
        : theme.colors.gray[6],
    },
  }),
)
