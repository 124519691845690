import { Badge, Group } from 'components'
import { useStyles } from '../styles'
import { ProjectCardBadgesProps } from '../interfaces'

export const ProjectCardBadges = ({ badgeList }: ProjectCardBadgesProps) => {
  const { classes } = useStyles()

  if (badgeList === null) return null

  return (
    <Group spacing='xs' className={classes.headerBadgeList}>
      {badgeList.map((badge, index) => (
        <Badge key={index} variant={badge.variant} size={badge.size} color={badge.color}>
          {badge.label}
        </Badge>
      ))}
    </Group>
  )
}
