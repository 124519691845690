import React, { createContext } from 'react'
import { useListState, UseListStateHandlers } from 'hooks'

interface ProjectButtonsContextType {
  buttonValues: { loading: boolean; disabled: boolean }[]
  buttonHandlers: UseListStateHandlers<{ loading: boolean; disabled: boolean }>
}

export const ProjectButtonsContext = createContext<ProjectButtonsContextType>({
  buttonValues: [
    { loading: false, disabled: false },
    { loading: false, disabled: false },
  ],
  buttonHandlers: {} as UseListStateHandlers<{ loading: boolean; disabled: boolean }>,
})

export const ProjectButtonsProvider = ({ children }: { children: React.ReactNode }) => {
  const [buttonValues, buttonHandlers] = useListState([
    { loading: false, disabled: false },
    { loading: false, disabled: false },
  ]) 

  return (
    <ProjectButtonsContext.Provider value={{ buttonValues, buttonHandlers }}>
      {children}
    </ProjectButtonsContext.Provider>
  )
}
