import { FileUpload } from "@legacy/common/_components"
import { useTranslation } from "react-i18next"
import { FileUploadContainer } from "./styled"

export const FilePreview = ({ projectId }: { projectId: string }) => {
    const { t } = useTranslation()
  
    return (
      <>
        <span>{t('meetings_report_group_records')}</span>
        <FileUploadContainer>
          <FileUpload
            uploadUrl={`/projects/${projectId}/project_file_items`}
            fetchFilesUrl={`projects/${projectId}/project_file_items.json`}
            deleteFilesUrl={`/projects/${projectId}/project_file_items`}
            typeFile='file'
          />
        </FileUploadContainer>
      </>
    )
}