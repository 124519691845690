import styled from 'styled-components'
import {
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuArrow,
  DropdownMenuSeparator,
  DropdownMenuItemIndicator,
  DropdownMenuRadioItem,
  DropdownMenuItem,
  DropdownMenuLabel,
} from 'components/DropdownMenu'

export const DropdownMenuContentStyled = styled(DropdownMenuContent)`
  background-color: var(--color-white);
  width: 234px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(148, 147, 147, 0.65);
  color: #625f6d;
  font-size: 1em;
  min-width: 200px;
  z-index: 5;
  margin-right: 40px;
  &:focus-visible {
    outline: 0px;
    border: none;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0;
    min-width: 45rem;
    height: 100vh;
  }
`

export const DropdownMenuTriggerStyled = styled(DropdownMenuTrigger)`
  cursor: pointer;
  background-color: transparent;
  border-width: 0px;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  a {
    &:focus-visible {
      outline: 0px;
      border: none;
    }
  }

  &:focus {
    outline: 0px;
    border: none;
  }
`

export const DropdownMenuArrowStyled = styled(DropdownMenuArrow)`
  fill: var(--color-white);
  height: 8px;
  width: 16px;
`

export const DropdownMenuSeparatorStyled = styled(DropdownMenuSeparator)`
  margin: 10px 0;
  border-top: ${({ theme }) => `1px solid ${theme.colors.separator}`};
  overflow: hidden;
`

export const DropdownMenuRadioItemStyled = styled(DropdownMenuRadioItem)`
  display: flex;
  align-items: center;
  padding: 4px 0px 0px 20px;
  &:focus-visible {
    outline: 0px;
    border: none;
  }

  a {
    font-size: 0.875rem;

    padding-left: 25px;
    color: var(--color-black);
    cursor: pointer;

    &:hover {
      color: var(--color-black);
      text-decoration: none;
    }
  }
`

export const DropdownMenuItemIndicatorStyled = styled(DropdownMenuItemIndicator)`
  width: 8px;
  height: 8px;
  background-color: var(--color-primary);
  border-radius: 50%;
  position: absolute;
  display: 'inline-flex';
  align-items: 'center';
  justify-content: 'center';
  &:focus-visible {
    outline: 0px;
    border: none;
  }
`

export const LinkStyled = styled.a`
  font-size: 0.875rem;

  color: var(--color-black);
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  &:hover {
    color: var(--color-black);
    text-decoration: none;
  }
`
export const DropdownMenuItemStyled = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  padding: 4px 0px 0px 20px;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  i {
    font-size: 1.3em;
  }
`

export const DropdownMenuLabelStyled = styled(DropdownMenuLabel)`
  color: var(--color-medium-gray);
  font-size: 0.875rem;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  margin-left: 16px;
`

export const ContainerWithoutIconStyled = styled.div`
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  a {
    padding-left: 25px;
  }
`

export const ContainerWithIconStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 15.4px;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
`

export const Container = styled.div<{ isApp: boolean }>`
  display: ${({ isApp }) => (isApp ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
`
