import { useState, useContext } from 'react'
import { TeacherDashboardContext } from '../../context'
import { Tabs, Skeleton, Stack, Group, Heading, Button, DisabledTooltipButton } from 'components'
import { ProjectsPanel } from '../ProjectsPanel'
import { ProjectListProps } from './interfaces'
import { useTranslation } from 'react-i18next'
import { IconBackpack } from '@tabler/icons-react'
import { useCurrentUser } from 'context'

export const ProjectsList = ({ projectKind }: ProjectListProps) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const { projects } = useContext(TeacherDashboardContext)

  const [activeTab, setActiveTab] = useState<string | null>(projectKind[1].value)

  const countProjectsByStatus = (status: string) => {
    return `(${projects?.filter(project => project.status === status).length})`
  }

  return (
    <Stack spacing='xs'>
      <Group position='apart'>
        <Heading
          size='md'
          text={t('new_teacher_dashboard.projects_title')}
          supportText={t('new_teacher_dashboard.projects_description')}
          icon={<IconBackpack size={32} />}
        />
        { currentUser && currentUser?.group_school_terms_count > 0 ?
          <Button size='md' variant='filled' href='/projects/new' component='a'>
            {t('new_project')}
          </Button> :
          <DisabledTooltipButton
            fullWidth
            label={t('new_projects_list.new_project')}
            tooltipLabel={t('new_project_tooltip')}
          />
        }
      </Group>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List mb='md'>
          {projectKind.length > 0 &&
            projectKind.map(kind => (
              <Tabs.Tab key={kind.value} value={kind.value}>
                {projects === null ? (
                  <Skeleton width={100} height={24} />
                ) : (
                  kind.label + ' ' + countProjectsByStatus(kind.value)
                )}
              </Tabs.Tab>
            ))}
        </Tabs.List>
        <ProjectsPanel activeTab={activeTab || projectKind[0].value} />
      </Tabs>
    </Stack>
  )
}
