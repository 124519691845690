import { api } from 'helpers'
import { AxiosResponse } from 'axios'

export async function getMySchools(): Promise<AxiosResponse> {
  try {
    const response = await api.get('/api/users/my_schools')
    return response
  } catch (error: any) {
    return error.response.statusText
  }
}

export async function updateSchool(id: string): Promise<AxiosResponse> {
  try {
    const response = await api.put(`/api/users/change_school/${id}`)
    return response
  } catch (error: any) {
    return error.response.statusText
  }
}
