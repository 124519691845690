import { useContext } from 'react'
import { Modal, Stack, Card, Text, Group, Button } from 'components'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { useTranslation } from 'react-i18next'
import { IWorkedProjects } from '../ProjectSkillsStandards/interfaces'

export const WorkedProjectsSkillsStandardsModal = () => {
  const { t } = useTranslation()
  const { projectsModalOpened, projectsModalClose, currentWorkedProjects } = useContext(SkillsStandardsContext)

  return (
    <Modal
      opened={projectsModalOpened}
      onClose={projectsModalClose}
      title={t('new_project_page.project_modal_title')}
      centered
      size='lg'
    >
      <Stack spacing='xs'>
        {currentWorkedProjects && currentWorkedProjects.length > 0
          ? currentWorkedProjects.map((project: IWorkedProjects) => {
            const url = `/projects/${project.id}`
            return (
              <Card key={project.id} shadow='sm' withBorder>
                <Card.Section p='sm'>
                  <Group position='apart' spacing='md'>
                    <Text fw={500} align='left'>
                      {project.name}
                    </Text>
                    <Button variant='light' size='sm' component='a' target='_blank' href={url}>
                      {t('new_project_page.view_project')}
                    </Button>
                  </Group>
                </Card.Section>
              </Card>
            )
          })
          : null}
      </Stack>
    </Modal>
  )
}
