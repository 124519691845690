import { IconCalendar } from '@tabler/icons-react'
import { Group, Stack, Text, Box } from 'components'

interface CardHeadProps {
  readonly title: string
  readonly lesson: string
}

export const CardHead = ({ title, lesson }: CardHeadProps) => {

  return (
    <Group spacing='sm' p='sm'>
      <Box>
        <IconCalendar color={'white'} size={28} />
      </Box>
      <Stack spacing={0}>
        <Text size='xs' color='white' style={{ lineHeight: 1.2 }} lineClamp={1}>
          {title}
        </Text>
        <Text size='lg' fw={700} color='white' style={{ lineHeight: 1.2 }} lineClamp={1}>
          {lesson}
        </Text>
      </Stack>
    </Group>
  )
}
