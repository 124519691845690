import { useMemo } from 'react'
import { SchoolLabel } from './whitelabel'

interface ThemeHookResult {
  readonly theme: SchoolLabel
}

const useTheme = (): ThemeHookResult => {
  const theme: SchoolLabel = useMemo(() => {
    switch (document.querySelector('html')?.getAttribute('data-theme')) {
      case SchoolLabel.EscolaHub:
        return SchoolLabel.EscolaHub
      case SchoolLabel.Branding:
        return SchoolLabel.Branding
      default:
        return SchoolLabel.Default
    }
  }, [])

  return {
    theme,
  }
}

export default useTheme
