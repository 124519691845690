import { IHeading } from './HeadingTypes'

import { Title, Text, Flex, Stack } from 'components'

export const Heading = ({ icon, text, supportText, size }: IHeading) => {
  const supportTextContent = supportText ? (
    <Text size='sm' color='dimmed' span>
      {supportText}
    </Text>
  ) : null


  const getSize = (size: string) => {
    switch (size) {
      case 'xl':
        return 1
      case 'lg':
        return 2
      case 'md':
        return 3
      case 'sm':
        return 4
      case 'xs':
        return 5
      case 'xxs':
        return 6
      default:
        return 3
    }
  }

  if (icon) {
    return (
      <Flex align={supportText === '' || !supportText ? 'center': 'flex-start'} gap='xs' wrap='nowrap'>
        {icon && (icon as React.ReactElement)}
        <Stack spacing={0}>
          <Title order={getSize(size)}>{text}</Title>
          {supportTextContent}
        </Stack>
      </Flex>
    )
  }

  return (
    <Stack spacing={0}>
      <Title order={getSize(size)}>{text}</Title>
      {supportTextContent}
    </Stack>
  )
}
export default Heading
