import { useTranslation } from 'react-i18next'
import { ProjectActionProps } from './interfaces'
import { Grid, Button } from 'components'
import { IconEdit } from '@tabler/icons-react'


export const ProjectActions = ({ project, projectViewURL, projectEditURL }: ProjectActionProps) => {
  const { t } = useTranslation()

  return (
    <Grid justify='flex-start'>
      <Grid.Col span={12} sm={project.status === 'draft' ? 'auto' : 'auto'}>
        <Button component='a' fullWidth variant='light' size='md' radius='xl' href={projectViewURL}>
          {t('new_projects_list.view_project')}
        </Button>
      </Grid.Col>
      {project.status === 'draft' && (
        <Grid.Col span={12} sm='auto'>
          <Button
            component='a'
            fullWidth
            leftIcon={<IconEdit />}
            variant='light'
            size='md'
            radius='xl'
            href={projectEditURL}
          >
            {t('general_buttons_edit')}
          </Button>
        </Grid.Col>
      )}
    </Grid>
  )
}
