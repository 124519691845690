import { ProjectCardSkillsContentsBadgesProps } from '../interfaces'
import { Group, Tooltip, Anchor, List } from 'components'
import { useTranslation } from 'react-i18next'
import { ProjectCardSkillContentBadgeItem } from './ProjectCardSkillContentBadgeItem'
import { useMantineColorScheme } from '@mantine/core'


export const ProjectCardSkillsContentsBadges = ({ skillsAndContents }: ProjectCardSkillsContentsBadgesProps) => {
  const { colorScheme } = useMantineColorScheme()
  const { t } = useTranslation()
  const maxQtd = 2
  const moreCount = skillsAndContents && skillsAndContents.length - maxQtd
  const moreSkillsContentsName = skillsAndContents && skillsAndContents.slice(maxQtd).map(item => item.name)
  const moreSkillsContentTooltip = (<List size='sm' sx={{ color: colorScheme === 'dark' ? 'dark' : 'white' }}>
    {moreSkillsContentsName && moreSkillsContentsName.map((item, index) => (
      <List.Item key={index}>{item}</List.Item>
    ))}
  </List>)

  return (
    <Group spacing='xs'>
      {skillsAndContents &&
        skillsAndContents.length > 0 &&
        skillsAndContents.slice(0, maxQtd).map((item, index) => (
          <ProjectCardSkillContentBadgeItem item={item} key={index} />
        ))}
      {moreCount > 0 && (
        <Tooltip label={moreSkillsContentTooltip} withArrow position='top' withinPortal>
          <Anchor size='sm'>{t('new_guardian_dashboard.card_and_more', { count: moreCount })}</Anchor>
        </Tooltip>
      )}
    </Group>
  )
}
