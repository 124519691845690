import { createContext, useState } from 'react'
import { IProject, IGroup, ILesson, ITask } from 'interfaces'

interface TeacherDashboardContextProps {
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  groupsLoading: boolean
  setGroupsLoading: React.Dispatch<React.SetStateAction<boolean>>
  groups: IGroup[] | null
  setGroups: React.Dispatch<React.SetStateAction<IGroup[] | null>>
  projects: IProject[] | null
  setProjects: React.Dispatch<React.SetStateAction<IProject[] | null>>
  lessons: ILesson[] | null
  setLessons: React.Dispatch<React.SetStateAction<ILesson[] | null>>
  tasks: ITask[] | null
  setTasks: React.Dispatch<React.SetStateAction<ITask[] | null>>
  activitiesLoading: boolean
  setActivitiesLoading: React.Dispatch<React.SetStateAction<boolean>>
  handleClickGroupDetails: (id: string) => void
  getWallURL: (id: string) => string
  getAddWallURL: (id: string) => string
  getEditURL: (id: string) => string
  handleJournalClick: (url: string) => void
}

export const TeacherDashboardContext = createContext<TeacherDashboardContextProps>({
  error: null,
  setError: () => {},
  groupsLoading: true,
  setGroupsLoading: () => {},
  groups: null,
  setGroups: () => {},
  projects: null,
  setProjects: () => {},
  lessons: null,
  setLessons: () => { },
  tasks: null,
  setTasks: () => { },
  activitiesLoading: true,
  setActivitiesLoading: () => {},
  handleClickGroupDetails: () => {},
  getWallURL: () => '',
  getAddWallURL: () => '',
  getEditURL: () => '',
  handleJournalClick: () => {},
})

export const TeacherDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<string | null>(null)
  const [groupsLoading, setGroupsLoading] = useState<boolean>(true)
  const [groups, setGroups] = useState<IGroup[] | null>(null)
  const [lessons, setLessons] = useState<ILesson[] | null>(null)
  const [tasks, setTasks] = useState<ITask[] | null>(null)
  const [projects, setProjects] = useState<IProject[] | null>(null)
  const [activitiesLoading, setActivitiesLoading] = useState<boolean>(true)


  const handleClickGroupDetails = (id: string) => {
    if (id) {
      const url = `/students/${id}`
      window.location.replace(url)
    }
  }

  const getWallURL = (id: string) => {
    return `/projects/${id}/mural_items`
  }
  
  const getAddWallURL = (id: string) => {
    return `/projects/${id}/mural_items/new`
  }

  const getEditURL = (id: string) => {
    return `/projects/${id}/edit`
  }

  const handleJournalClick = (url: string) => {
    if (url) {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
  
  return (
    <TeacherDashboardContext.Provider
      value={{
        error,
        setError,
        groupsLoading,
        setGroupsLoading,
        groups,
        setGroups,
        projects,
        setProjects,
        activitiesLoading,
        setActivitiesLoading,
        lessons,
        setLessons,
        tasks,
        setTasks,
        handleClickGroupDetails,
        getWallURL,
        getAddWallURL,
        getEditURL,
        handleJournalClick,
      }}
    >
      {children}
    </TeacherDashboardContext.Provider>
  )
}
