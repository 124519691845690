import { createContext, useState } from 'react'
import { useDisclosure, useListState } from 'hooks'
import { ILvl4Item, IActivity, ILesson } from 'interfaces'
import { notifications, useCurrentUser } from 'context'
import { IconCheck } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

interface LessonsContextProps {
  specificSkills: ILvl4Item[]
  setSpecificSkills: (skills: ILvl4Item[]) => void
  lessonIdToRemove: string
  setActivityIdToRemove: (id: string) => void
  activityIdToRemove: string
  activiyId: string
  parentLessonId: string
  saveActivity: (activity: IActivity) => void
  activityArray: IActivity[]
  openedLessonModal: boolean
  openedActivityModal: boolean
  openedCriteriaModal: boolean
  openedActivityDetailsModal: boolean
  openedRemoveConfirmationModal: boolean
  openLessonModal: () => void
  openActivityModal: () => void
  openCriteriaModal: () => void
  openActivityDetailsModal: () => void
  openRemoveConfirmationModal: () => void
  closeLessonModal: () => void
  closeActivityModal: () => void
  closeCriteriaModal: () => void
  closeActivityDetailsModal: () => void
  closeRemoveConfirmationModal: () => void
  lessons: ILesson[]
  setLessons: (lessons: ILesson[]) => void
  editLesson: ILesson | null
  setEditLesson: (lesson: ILesson | null) => void
  editActivity: IActivity | null
  setEditActivity: (activity: IActivity | null) => void
  selectedStandards: ILvl4Item[]
  selectedStandardsHandler: {
    append: (value: ILvl4Item) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ILvl4Item[]>>
  }
  selectedSkills: ILvl4Item[]
  selectedSkillsHandler: {
    append: (value: ILvl4Item) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ILvl4Item[]>>
  }
  selectedChanged: boolean
  setSelectedChanged: (value: boolean) => void
  handleAddSkillsStandardItem: (item: ILvl4Item, type: string) => void
  handleRemoveSkillsStandardItem: (id: string, type: string) => void
  checkIfSelected: (id: string, type: string) => boolean
  activitySkills: ILvl4Item[]
  setActivitySkills: (items: ILvl4Item[]) => void
  activityStandards: ILvl4Item[]
  setActivityStandards: (items: ILvl4Item[]) => void
  handleViewActivityDetails: (activityId: string) => void
  handleAddActivity: (id: string) => void
  handleEditActivity: (activityId: string) => void
  handleRemoveActivity: (activityId: string) => void
  handleAddLesson: () => void
  handleEditLesson: (lessonId: string) => void
  handleRemoveLesson: (lessonId: string) => void
  removeType: string
  setRemoveType: (type: string) => void
  handleCriteriaModal: (value: any) => void
  editingCriteria: string
  getJournalURL: (id: string) => string
  getReportURL: (id: string) => string
}

export const LessonsContext = createContext<LessonsContextProps>({} as LessonsContextProps)

export const LessonsProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  
  const [lessons, setLessons] = useState<ILesson[]>([])
  const [editLesson, setEditLesson] = useState<ILesson | null>(null)
  const [editActivity, setEditActivity] = useState<IActivity | null>(null)
  const [openedLessonModal, { open: openLessonModal, close: closeLessonModal }] = useDisclosure(false)
  const [openedActivityModal, { open: openActivityModal, close: closeActivityModal }] = useDisclosure(false)
  const [openedCriteriaModal, { open: openCriteriaModal, close: closeCriteriaModal }] = useDisclosure(false)
  const [openedActivityDetailsModal, { open: openActivityDetailsModal, close: closeActivityDetailsModal }] =
    useDisclosure(false)
  const [openedRemoveConfirmationModal, { open: openRemoveConfirmationModal, close: closeRemoveConfirmationModal }] =
    useDisclosure(false)
  const [selectedStandards, selectedStandardsHandler] = useListState<ILvl4Item>([])
  const [selectedSkills, selectedSkillsHandler] = useListState<ILvl4Item>([])
  const [selectedChanged, setSelectedChanged] = useState<boolean>(false)
  const [activitySkills, setActivitySkills] = useState<ILvl4Item[]>([])
  const [activityStandards, setActivityStandards] = useState<ILvl4Item[]>([])
  const [activiyId, setActivityId] = useState<string>('')
  const [removeType, setRemoveType] = useState<string>('lesson')
  const [activityArray, setActivityArray] = useState<IActivity[]>([])
  const [lessonIdToRemove, setLessonIdToRemove] = useState<string>('')
  const [activityIdToRemove, setActivityIdToRemove] = useState<string>('')
  const [parentLessonId, setParentLessonId] = useState<string>('')
  const [specificSkills, setSpecificSkills] = useState<ILvl4Item[]>([])
  const [editingCriteria, setEditingCriteria] = useState<string>('')

  const handleAddSkillsStandardItem = (item: ILvl4Item, type: string) => {
    const findSkill = selectedSkills.find(skill => skill.id === item.id)
    const findStandard = selectedStandards.find(standard => standard.id === item.id)

    if (!findSkill && !findStandard) {
      type === 'skill' ? selectedSkillsHandler.append(item) : selectedStandardsHandler.append(item)
      setSelectedChanged(true)
    } else {
      notifications.show({
        message: t('new_project_page.message_item_already_selected'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    }
  }

  const handleRemoveSkillsStandardItem = (id: string, type: string) => {
    type === 'skill'
      ? selectedSkillsHandler.remove(selectedSkills.findIndex(item => item.id === id))
      : selectedStandardsHandler.remove(selectedStandards.findIndex(item => item.id === id))
    setSelectedChanged(true)
  }

  const handleViewActivityDetails = (activityId: string) => {
    if (activityId) {
      setActivityId(activityId)
      openActivityDetailsModal()
    }
  }

  const saveActivity = (activity: IActivity) => {
    setActivityArray([...activityArray, activity])
  }

  const handleEditActivity = (activityId: string) => {
    if (lessons) {
      const lesson = lessons.find(l => l.sessionMeetingActivities.find((a: IActivity) => a.id === activityId))
      const filteredActivities = lesson?.sessionMeetingActivities.filter((a: IActivity) => a.id === activityId)

      if (!lesson || !filteredActivities) {
        return
      } else {
        setEditActivity(filteredActivities[0])
        openActivityModal()
      }
    }
  }

  const handleRemoveActivity = (activityId: string) => {
    setActivityIdToRemove(activityId)

    if (lessons) {
      const activity = lessons
        .find(l => l.sessionMeetingActivities.find(a => a.id === activityId))
        ?.sessionMeetingActivities.find(a => a.id === activityId)

      if (!activity) {
        return
      } else {
        setRemoveType('activity')
        openRemoveConfirmationModal()
      }
    }
  }

  const handleAddLesson = () => {
    setEditLesson(null)
    openLessonModal()
  }

  const handleEditLesson = (lessonId: string) => {
    if (lessons) {
      const lesson = lessons.find(l => l.id === lessonId)
      if (!lesson) {
        return
      } else {
        setEditLesson(lesson)
        openLessonModal()
      }
    }
  }

  const handleRemoveLesson = (lessonId: string) => {
    setLessonIdToRemove(lessonId)

    if (lessons) {
      const lesson = lessons.find(l => l.id === lessonId)
      if (!lesson) {
        return
      } else {
        setRemoveType('lesson')
        openRemoveConfirmationModal()
      }
    }
  }

  const handleAddActivity = (id: string) => {
    selectedStandardsHandler.setState([])
    selectedSkillsHandler.setState([])
    setEditActivity(null)
    setParentLessonId(id)
    openActivityModal()
  }

  const checkIfSelected = (id: string, type: string) => {
    if (id && type === 'skill' && selectedSkills && selectedSkills.find(item => item.id === id)) return true
    if (id && type === 'content' && selectedStandards && selectedStandards.find(item => item.id === id)) return true
    return false
  }

  const handleCriteriaModal = (value: any) => {
    setEditingCriteria(value.id)
    openCriteriaModal()
  }

  const getJournalURL = (id: string) => {
    if (id && currentUser && (currentUser.profile_used === 'tutor' || currentUser.profile_used === 'manager')) {
      return `/projects/lessons/${id}/edit_report`
    }
    if (id && currentUser && currentUser.profile_used === 'student') {
      return `/session_meetings/${id}/edit_report`
    }
    return ''
  }

  const getReportURL = (id: string) => {
    if (id && currentUser && (currentUser.profile_used === 'tutor' || currentUser.profile_used === 'manager')) {
      return `/session_meetings/${id}/show_all_students_report`
    }
    if (id && currentUser && currentUser.profile_used === 'student') {
      return `/session_meetings/${id}/show_report`
    }
    return ''
  }

  return (
    <LessonsContext.Provider
      value={{
        specificSkills,
        setSpecificSkills,
        lessonIdToRemove,
        saveActivity,
        activityArray,
        openedLessonModal,
        openedActivityModal,
        openedCriteriaModal,
        openedActivityDetailsModal,
        openedRemoveConfirmationModal,
        activiyId,
        openLessonModal,
        openActivityModal,
        openCriteriaModal,
        openActivityDetailsModal,
        openRemoveConfirmationModal,
        closeLessonModal,
        closeActivityModal,
        closeCriteriaModal,
        closeActivityDetailsModal,
        closeRemoveConfirmationModal,
        lessons,
        setLessons,
        editLesson,
        setEditLesson,
        editActivity,
        setEditActivity,
        selectedStandards,
        selectedStandardsHandler,
        selectedSkills,
        selectedSkillsHandler,
        selectedChanged,
        setSelectedChanged,
        handleAddSkillsStandardItem,
        handleRemoveSkillsStandardItem,
        checkIfSelected,
        activitySkills,
        setActivitySkills,
        activityStandards,
        setActivityStandards,
        handleViewActivityDetails,
        handleAddActivity,
        parentLessonId,
        handleEditActivity,
        handleRemoveActivity,
        handleAddLesson,
        handleEditLesson,
        handleRemoveLesson,
        removeType,
        setRemoveType,
        setActivityIdToRemove,
        activityIdToRemove,
        handleCriteriaModal,
        editingCriteria,
        getJournalURL,
        getReportURL,
      }}
    >
      {children}
    </LessonsContext.Provider>
  )
}
