// Todo: modificar com estrutura correta do backend
export interface IStudent {
  id: string
  name: string
  avatar: string
  img?: string
  fullName?: string
  groupNames: string[]
  tag: string
}
