import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Modal, Grid, Avatar, Text, Stack, Anchor } from 'components'
import { ITeacher } from 'interfaces'
import { TeachersModalProps } from './interfaces'
import { getInitials } from 'helpers'
import { useStyles } from './styles'

export const TeachersModal = ({ teachers, mainTeacherID, opened, onClose }: TeachersModalProps) => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useStyles()
  const { t } = useTranslation()

  const moveMainTeacherToFront = (teachersList: ITeacher[], mainTeacherId: string) => {
    const mainTeacherIndex = teachersList.findIndex(teacher => teacher.id === mainTeacherId)
    if (mainTeacherIndex > -1) {
      const [mainTeacher] = teachersList.splice(mainTeacherIndex, 1)
      teachersList.unshift(mainTeacher)
    }
    return teachersList
  }

  const sortedTeachers = useMemo(() => moveMainTeacherToFront(teachers, mainTeacherID), [teachers, mainTeacherID])

  return (
    <Modal fullScreen={isMobile} title={t('projects_show_teamModal_title')} size='xl' opened={opened} onClose={onClose}>
      <Grid align='center' justify='stretch'>
        {sortedTeachers &&
          sortedTeachers.length > 0 &&
          sortedTeachers.map(teacher => (
            <Grid.Col key={teacher.id} span={6} xs={4} sm={3}>
              <Anchor href={teacher.link}>
                <Stack spacing={0} align='center' justify='center'>
                  <Avatar
                    className={teacher.id === mainTeacherID ? classes.mainTeacherAvatar : classes.defaultAvatar}
                    src={teacher.avatar}
                    alt={teacher.name}
                    size='lg'
                    radius='xl'
                    variant='filled'
                    color='gray'
                  >
                    {getInitials(teacher.name)}
                  </Avatar>
                  <Text align='center' mt='xs' size='md'>
                    {teacher.name}
                  </Text>
                  <Text align='center' size='sm' color='dimmed'>
                    {teacher.educatorRole}
                  </Text>
                </Stack>
              </Anchor>
            </Grid.Col>
          ))}
      </Grid>
    </Modal>
  )
}
