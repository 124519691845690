import { useContext, FC } from 'react'
import TabContext from '../context/students-context'
import { Pagination } from 'components'
import { ITabContext } from '../types/student-types'
import { useMediaQuery } from '@mantine/hooks'

const WrapPagination: FC = () => {
  const { pagination, changePage, activePage, loading } = useContext<ITabContext>(TabContext)
  const isMobile = useMediaQuery('(max-width: 600px)')

  return !loading && pagination && pagination.total !== 1 ? (
    <Pagination
      value={activePage}
      onChange={currentPage => changePage(currentPage)}
      total={pagination.total}
      withEdges={!isMobile}
    />
  ) : null
}

export default WrapPagination
