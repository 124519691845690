import { useState, useContext } from 'react'
import { GuardianDashboardContext } from '../../context/GuardianDashboardContext'
import { Tabs, Skeleton } from 'components'
import { ProjectsPanel } from '../ProjectsPanel/ProjectsPanel'
import { ProjectListProps } from './interfaces'

export const ProjectsList = ({
  projectKind,
}: ProjectListProps) => {

  const { projects } = useContext(GuardianDashboardContext)

  const [activeTab, setActiveTab] = useState<string | null>(projectKind[0].value)

  const countProjectsByStatus = (status: string) => {
    return `(${projects?.filter(project => project.status === status).length})`
  }

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab} id='projects-tabs'>
      <Tabs.List mb='md'>
        {projectKind.length > 0 &&
          projectKind.map(kind => (
            <Tabs.Tab key={kind.value} value={kind.value}>
              {projects === null ? (
                <Skeleton width={100} height={24} />
              ) : (
                kind.label + ' ' + countProjectsByStatus(kind.value)
              )}
            </Tabs.Tab>
          ))}
      </Tabs.List>
      <ProjectsPanel activeTab={activeTab || projectKind[0].value} />
    </Tabs>
  )
}
