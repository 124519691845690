import { useContext } from 'react'
import { GuardianDashboardContext } from '../../context/GuardianDashboardContext'
import { useTranslation } from 'react-i18next'
import { Tabs, Carousel, EmptyStateBox } from 'components'
import { carouselDashboard, useCaroselStyles } from 'components/Carousel'
import { ProjectsSkeleton } from '../ProjectsSkeleton'
import { ProjectsSlide } from '../ProjectsSlide'
import { ProjectsPanelProps } from './interfaces'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { IconArticleOff } from '@tabler/icons-react'

export const ProjectsPanel = ({ activeTab }: ProjectsPanelProps) => {
  const { t } = useTranslation()
  const { projects, projectsLoading } = useContext(GuardianDashboardContext)
  const { classes } = useCaroselStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const filteredProjects = projects?.filter(project => project.status === activeTab)

  const showIndicator =
    (filteredProjects && isMobile && filteredProjects.length > 2) || (filteredProjects && filteredProjects.length > 3)

  return (
    <Tabs.Panel value={activeTab} key={activeTab}>
      {!projectsLoading && filteredProjects && filteredProjects.length === 0 && (
        <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('new_guardian_dashboard.no_items_found')} />
      )}
      {projectsLoading ? (
        <ProjectsSkeleton />
      ) : (
        <Carousel
          {...carouselDashboard}
          withIndicators={showIndicator || false}
          withControls={showIndicator || false}
          draggable={filteredProjects && filteredProjects.length > 1}
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
          }}
        >
          {filteredProjects?.map(project => (
            <ProjectsSlide key={project.id} project={project} />
          ))}
        </Carousel>
      )}
    </Tabs.Panel>
  )
}
