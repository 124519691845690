export const managerMenu = [
  {
    link: '/manager/dashboard',
    key: 'dashboard',
    icon: 'icon-home',
  },
  {
    link: '/manage_school',
    key: 'manage_school',
    icon: 'icon-md-settings',
  },
  {
    link: '/projects',
    key: 'projects',
    icon: 'icon-activities',
  },
  {
    link: '/tasks',
    key: 'tasks',
    icon: 'icon-pendencies',
  },
  {
    link: '/projects_library',
    key: 'projects_hub',
    icon: 'icon-pen-ruler',
  },
  {
    link: '/reports/dashboard',
    key: 'reports',
    icon: 'icon-students-report',
  },
]