import { Flex, Title } from '@mantine/core'
import FileUpload from '@legacy/common/_components/FileUpload'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: block;

  .files-list {
    margin-top: 1rem;
  }
`

const WrappFiles = ({ projectId, taskId }: { taskId: string; projectId: string }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Flex direction='column'>
        <Title order={4} mb='md'>
          {t('students_edit_files')}
        </Title>

        <FileUpload
          uploadUrl={`/projects/${projectId}/student_tasks/${taskId}/planning_files`}
          fetchFilesUrl={`/projects/${projectId}/student_tasks/${taskId}/planning_files.json`}
          deleteFilesUrl={`/projects/${projectId}/student_tasks/${taskId}/planning_files`}
          typeFile='planning_files'
          fileUploadId='student_tasks'
          appliedFile={false}
        />

        {taskId && taskId !== '' && <GDriveWrapper value={{ resourceId: taskId, resourceClass: 'student_task_id' }} />}
      </Flex>
    </Wrapper>
  )
}

export default WrappFiles
