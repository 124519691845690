import styled from 'styled-components'

export const ProjectContainerImage = styled.div<{bgImage?: string}>`
    background: ${({bgImage}) => bgImage || 'var(--color-gray)'};
    height: 320px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-position-y: center;
`