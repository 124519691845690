import { ITeacher, ILvl4Item, IProjectActions, IProjectKind } from 'interfaces'
export interface IEvaluationTool {
  name: string
  id: string
}
export interface IProjectNew {
  readonly id: string
  readonly name: string
  readonly stages: string
  readonly kind: IProjectKind
  readonly finalProductKind: string
  readonly modality: string
  readonly purpose: string
  readonly objectives: string
  readonly finalProductDescription: string
  readonly coverImage: string
  readonly synopsis: string
  readonly shareCommunity: boolean
  readonly grades: []
  readonly updatedAt: string
  readonly status: string
  readonly groupName: string
  readonly totalMeetings: number
  readonly startFinalAssessment: boolean
  readonly finalAssessmentPath: string
  readonly muralPath: string
  readonly tutorMain: ITeacher
  readonly evaluationTools: IEvaluationTool[]
  readonly projectItems: {
    readonly contents: ILvl4Item[]
    readonly specificSkills: ILvl4Item[]
  }
  readonly actions: IProjectActions
}
