export type TagKind = 'done' | 'late' | 'in_progress' | 'not_filled'

export const TagColor = {
  done: 'var(--color-alert-green)',
  late: 'var(--color-red)',
  in_progress: 'var(--color-primary-yellow)',
  not_filled: 'var(--color-gray-light)',
}

export interface ITag {
  readonly kind: TagKind
}
