import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

export const DropdownMenu = DropdownMenuPrimitive.Root
export const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
export const DropdownMenuPortal = DropdownMenuPrimitive.Portal
export const DropdownMenuArrow = DropdownMenuPrimitive.Arrow
export const DropdownMenuContent = DropdownMenuPrimitive.Content
export const DropdownMenuItem = DropdownMenuPrimitive.Item
export const DropdownMenuSeparator = DropdownMenuPrimitive.Separator
export const DropdownMenuLabel = DropdownMenuPrimitive.Label
export const DropdownMenuRadioGroup = DropdownMenuPrimitive.DropdownMenuRadioGroup
export const DropdownMenuRadioItem = DropdownMenuPrimitive.DropdownMenuRadioItem
export const DropdownMenuItemIndicator = DropdownMenuPrimitive.ItemIndicator

export const DropdownMenuDemo = () => {
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div>Simple Dropdown Menu Demo</div>
        </DropdownMenuTrigger>
        <DropdownMenuPortal>
          <DropdownMenuContent sideOffset={5}>
            <DropdownMenuItem>
              Eduardo Junior
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              School manager area
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuLabel>
              Admin Area
            </DropdownMenuLabel>
            <DropdownMenuItem>
              Curriculum
            </DropdownMenuItem>
            <DropdownMenuItem>
              School
            </DropdownMenuItem>
            <DropdownMenuSeparator />
          </DropdownMenuContent>
        </DropdownMenuPortal>
      </DropdownMenu>
    </>
  )
}
