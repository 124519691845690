import { Moods } from '@legacy/pages/FinalAssessment/service/interfaces'
import { StarsSelector } from 'components/StarsSelector'
import { useTranslation } from 'react-i18next'
import { IProjectMoods } from './interfaces'
import { StarsContainer } from './styled'

export const ProjectMoods = ({clickStar, moods}: IProjectMoods) => {
  const { t } = useTranslation()

  if (!moods) return <></>

  return (
    <StarsContainer>
      <h2>{t('meetings_report_moods_title')}</h2>
      {Object.keys(moods).map(key => {
        const fieldName = key as keyof Moods
        const value = moods[fieldName]
        return (
          <StarsSelector
            key={key}
            fieldName={fieldName}
            value={value ? value : 0}
            onClick={clickStar}
            label={t(`meetings_report_moods_${key}`)}
            errors={[]}
            total={5}
          />
        )
      })}
    </StarsContainer>
  )
}