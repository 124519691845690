import { createContext, useState, useEffect, useContext, SetStateAction } from 'react'
import { api } from 'helpers'
import { TabType, IPaginationState, IFile, IStudentToSelect } from 'pages/Feed/interfaces'
import * as serviceFeed from 'pages/Feed/serviceFeed'
import { initialGoogleDriveFiles } from 'pages/Feed/initialStates'
import { IEditFeed } from 'pages/Feed/components/FeedCard/interfaces'
import { initialPagination } from 'pages/Feed/initialStates'
import { useTranslation } from 'react-i18next'
import { notifications } from 'context'
import { IconX, IconCheck } from '@tabler/icons-react'
import { FeedCamelCased } from 'pages/Feed/serviceFeed'
import { modals } from 'context'
import { Text } from 'components'
import { useMixPanel } from 'hooks'
import { FeedDrawerContext } from '../FeedDrawerContext'
import { FeedStatus } from 'pages/Feed/serviceFeed'
import { isEmpty } from 'lodash'

interface FeedListContextProps {
  tabState: TabType
  setTabState: React.Dispatch<React.SetStateAction<TabType>>
  changeFeedStatus: (id: string, status: number, thereIsStudent: boolean, edit: (id: string) => Promise<void>) => void
  deleteObservation: (id: string) => void
  loadMore: () => void
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  pagination: IPaginationState
  setPagination: React.Dispatch<React.SetStateAction<IPaginationState>>
  publicFeed: serviceFeed.FeedCamelCased[]
  setPublicFeed: React.Dispatch<React.SetStateAction<serviceFeed.FeedCamelCased[]>>
  privateFeed: serviceFeed.FeedCamelCased[]
  setPrivateFeed: React.Dispatch<React.SetStateAction<serviceFeed.FeedCamelCased[]>>
  activeTab: string | null
  setActiveTab: React.Dispatch<React.SetStateAction<string | null>>
  visibility: boolean
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>
  getFeed: () => void
  onEditFeedCard: (id: string) => Promise<void>
}

export const FeedListContext = createContext<FeedListContextProps>({
  tabState: 'private',
  setTabState: () => {},
  changeFeedStatus: () => {},
  deleteObservation: () => {},
  loadMore: () => {},
  loading: false,
  setLoading: () => {},
  pagination: initialPagination,
  setPagination: () => {},
  publicFeed: [],
  setPublicFeed: () => {},
  privateFeed: [],
  setPrivateFeed: () => {},
  activeTab: null,
  setActiveTab: () => {},
  visibility: true,
  setVisibility: () => {},
  getFeed: () => {},
  onEditFeedCard: () => {
    return new Promise(() => {})
  },
})

export const FeedListProvider = ({ children }: { children: React.ReactNode }) => {
  const [tabState, setTabState] = useState<TabType>('private')
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState<IPaginationState>(initialPagination)
  const [publicFeed, setPublicFeed] = useState<serviceFeed.FeedCamelCased[]>([])
  const [privateFeed, setPrivateFeed] = useState<serviceFeed.FeedCamelCased[]>([])
  const [activeTab, setActiveTab] = useState<string | null>('private')
  const [visibility, setVisibility] = useState<boolean>(true)

  const {
    setHeaderText,
    setUserIsCreating,
    setFeedId,
    setServerData,
    setTextAreaValue,
    setFeedStatus,
    setFiles,
    setStudents,
    openDrawer,
  } = useContext(FeedDrawerContext)

  const { t } = useTranslation()
  const mixPanel = useMixPanel()

  const getFeed = async () => {
    setLoading(true)
    const publicFeed = await serviceFeed.getFeeds(1)
    const privateFeed = await serviceFeed.getFeeds(0)

    setPagination({
      private: privateFeed.pagination,
      public: publicFeed.pagination,
    })

    setPublicFeed(publicFeed.feeds)
    setPrivateFeed(privateFeed.feeds)
    setLoading(false)
  }

  useEffect(() => {
    getFeed()
  }, [])

  const loadMore = async () => {
    const addPaginationPrivateFeed = (page: FeedCamelCased[]) => {
      setPrivateFeed([...privateFeed, ...page])
    }

    const addPaginationPublicFeed = (page: FeedCamelCased[]) => {
      setPublicFeed([...publicFeed, ...page])
    }
    const pag = pagination[tabState]
    const nextUrl = pag?.nextUrl
    if (!nextUrl) return
    try {
      const page = await serviceFeed.getPage(nextUrl)
      const { feeds } = page
      setPagination({ ...pagination, [tabState]: page.pagination })
      tabState === 'private' ? addPaginationPrivateFeed(feeds) : addPaginationPublicFeed(feeds)
    } catch {
      notifications.show({
        title: t('general_error_title'),
        message: 'Error at load more data',
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }
  const onEditFeedCard = async (id: string) => {
    try {
      setHeaderText(t('edit_post'))
      setUserIsCreating(false)
      const { data } = await api.get<IEditFeed>(`/api/feeds/${id}/edit`)
      setFeedId(data.feedId)

      const status = data.status === 'draft' ? FeedStatus.Draft : FeedStatus.Published

      setServerData({
        description: data.description,
        studentIds: data.students.map(({ id }) => id),
        googleDriveFiles: initialGoogleDriveFiles,
        status: status,
        projectId: data.projectId,
      })
      setTextAreaValue(data.description)
      setFeedStatus(status)

      let dataFiles: SetStateAction<IFile[]> = []
      if (!isEmpty(data.observationFiles)) {
        dataFiles = [
          {
            preview: data.observationFiles[0].fileCover,
            type: 'image',
            name: 'teste',
            file_id: data.observationFiles[0].fileId,
          },
        ]
      }
      setFiles(dataFiles)

      const getSelectedStudents = (data: IEditFeed, students: IStudentToSelect[]) => {
        const dataStudentIds = data.students.map(({ id }) => id)
        return students.map(student => {
          if (dataStudentIds.includes(student.id)) {
            return {
              ...student,
              selected: true,
            }
          }

          return student
        })
      }

      setStudents(students => getSelectedStudents(data, students))
      openDrawer()
    } catch (error) {
      console.error(error)
    }
  }

  const changeFeedStatus = async (
    id: string,
    status: number,
    thereIsStudent: boolean,
    edit: (id: string) => Promise<void>,
  ) => {
    if (!thereIsStudent) {
      modals.openConfirmModal({
        title: t('feed_pendent'),
        children: <Text size='md'>{t('feed_pendent_description')}</Text>,
        labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
        onCancel: () => {
          mixPanel.track('Cancelar edição de observação')
          close()
        },
        onConfirm: async () => {
          await edit(id)
          mixPanel.track('Editar observação')
        },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
      })
    } else {
      modals.openConfirmModal({
        title: status === 0 ? t('feed_make_private') : t('feed_publish_title'),
        children: <Text size='md'>{status === 0 ? t('feed_private_description') : t('feed_publish_description')}</Text>,
        labels: {
          confirm: status === 0 ? t('general_buttons_confirm') : t('general_buttons_publish'),
          cancel: t('general_buttons_cancel'),
        },
        onCancel: () => {
          mixPanel.track('Cancelar edição de observação')
          close()
        },
        onConfirm: async () => {
          try {
            await serviceFeed.changeFeedStatus(id, status)
            setTabState(status === 0 ? 'private' : 'public')
            notifications.show({
              message: status === 0 ? t('feed_private_success') : t('feed_publish_success'),
              color: 'green',
              icon: <IconCheck size={24} />,
            })
            mixPanel.track(status === 0 ? 'Tornar observação privada' : 'Publicar observação')
          } catch {
            notifications.show({
              title: t('general_error_title'),
              message: t('feed_publish_error'),
              color: 'red',
              icon: <IconX size={24} />,
            })
          }
          getFeed()
        },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
      })
    }
  }

  const deleteObservation = async (id: string) => {
    modals.openConfirmModal({
      title: t('feed_remove_title'),
      labels: { confirm: t('general_buttons_remove'), cancel: t('general_buttons_cancel') },
      onCancel: () => {
        mixPanel.track('Cancelar remoção de post')
        close()
      },
      onConfirm: async () => {
        try {
          await serviceFeed.deleteFeed(id)
          notifications.show({
            message: t('feed_remove_success'),
            color: 'green',
            icon: <IconCheck size={24} />,
          })
        } catch {
          notifications.show({
            title: t('general_error_title'),
            message: t('feed_remove_error'),
            color: 'red',
            icon: <IconX size={24} />,
          })
        }

        getFeed()
      },
      confirmProps: { size: 'lg', color: 'red' },
      cancelProps: { size: 'lg' },
    })
  }

  return (
    <FeedListContext.Provider
      value={{
        tabState,
        setTabState,
        changeFeedStatus,
        deleteObservation,
        loadMore,
        loading,
        setLoading,
        pagination,
        setPagination,
        publicFeed,
        setPublicFeed,
        privateFeed,
        setPrivateFeed,
        activeTab,
        setActiveTab,
        visibility,
        setVisibility,
        getFeed,
        onEditFeedCard,
      }}
    >
      {children}
    </FeedListContext.Provider>
  )
}
