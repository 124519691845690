import styled from 'styled-components'

export const ReactPlayContainer = styled.div `
  height: 125px;

  .files-list li.more-files .count-text, .files-list .file-container.more-files .count-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #625F6D;
    opacity: 0.8;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .file-thumb.small .video-player {
    width: 230px;
  }

  .file-thumb.small {
    height: 125px;
  }

  .file-thumb .video-player {
    width: 100%;
    height: 100%;
    background: #8D8A99;
    position: relative;
  }

  .file-thumb .video-player .icon-play {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    font-size: 80px;
    color: #625F6D;
  }

  .file-thumb {
    max-width: 100%;
    text-align: center;
    overflow: hidden;
    height: 170px;
    border-radius: 6px;
    color: #625F6D;
    display: inline-block;
    background: white;
    min-width: 100px;
  }
`
