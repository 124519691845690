import { api } from '../../../helpers'
import { AxiosResponse } from 'axios'
const teacherDashboardPaths = {
  getTasks: () => '/api/tutors/my_tasks',
  getGroups: () => '/api/tutors/my_groups',
  getProjects: () => '/api/tutors/my_projects',
}

export const getCurrentTasks = async (): Promise<AxiosResponse> => {
  try {
    const response = await api.get<AxiosResponse>(teacherDashboardPaths.getTasks())
    return response
  } catch (error: any) {
    return error
  }
}

export const getCurrentGroups = async (): Promise<AxiosResponse> => {
  try {
    const response = await api.get<AxiosResponse>(teacherDashboardPaths.getGroups())
    return response
  } catch (error: any) {
    return error
  }
}

export const getCurrentProjects = async (): Promise<AxiosResponse> => {
  try {
    const response = await api.get<AxiosResponse>(teacherDashboardPaths.getProjects())
    return response
  } catch (error: any) {
    return error
  }
}

