import { Layout } from 'components/Layout'
import { FinalProjectReport } from './components/FinalProjectReport'
import { FinalProjectReportProvider } from './context/FinalProjectReportContext'
import { CurrentUserContextProvider } from 'context'

export const FinalProjectReportPage = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <FinalProjectReportProvider>
          <FinalProjectReport />
        </FinalProjectReportProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
