import PropTypes from 'prop-types'
import { Animated } from 'react-animated-css'

const MinLength = ({ size = 0, length }) => {
  const charLeft = size - length
  const hasMinLength = (size > 0 && charLeft > 0)
  if (size >= 0) {
    return <></>
  }

  return (
    <Animated animationIn="fadeIn" animationOut="fadeOut" animationOutDuration={500} isVisible={hasMinLength}>
      <div className={`countdown-message ${charLeft >= 0 ? 'darkred-color' : ''}`}>
        {I18n.t(`char_limit.min_${charLeft === 1 ? 'one' : 'other'}`, { count: charLeft })}
      </div>
    </Animated>
  )
}

MinLength.propTypes = {
  size: PropTypes.number,
  length: PropTypes.number,
}

MinLength.defaultProps = {
  size: 0,
  length: 0,
}

export default MinLength
