import { useContext } from 'react'
import { Alert, Paper, Title, SimpleGrid, Group, Text } from 'components'
import { CardAvatar } from 'components'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { ParticipantsContext } from '../../context/ParticipantsContext'
import { IconInfoCircle } from '@tabler/icons-react'

export const GroupTeachersBlock = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { teachers, selectedGroupLabel } = useContext(ParticipantsContext)

  return (
    <Paper shadow='xs' withBorder radius='md' px={isMobile ? 'sm' : 'md'} py='sm'>
      {teachers.length > 0 ? (
        <>
          <Group spacing={0}>
            <Title order={5}>
              {t('new_project_page.teachers')} {selectedGroupLabel}{' '}
              <Text span color='dimmed'>
                ({teachers.length})
              </Text>
            </Title>
          </Group>
          <SimpleGrid
            mt='sm'
            cols={2}
            breakpoints={[
              { minWidth: 'xl', cols: 5, spacing: 'md' },
              { minWidth: 'lg', cols: 4, spacing: 'md' },
              { minWidth: 'md', cols: 3, spacing: 'sm' },
              { minWidth: 'sm', cols: 2, spacing: 'sm' },
              { minWidth: 'xs', cols: 2, spacing: 'sm' },
            ]}
          >
            {teachers.map(teacher => (
              <CardAvatar
                key={teacher.id}
                avatar={teacher.avatar}
                name={teacher.name}
                role={teacher.role}
                withBorder={false}
                vertical={true}
                p={0}
              />
            ))}
          </SimpleGrid>
          <Alert mt='md' icon={<IconInfoCircle />} variant='light' color='blue'>
            {t('new_project_page.message_group_teachers')}
          </Alert>
        </>
      ) : (
        t('new_project_page.no_teachers_in_group')
      )}
    </Paper>
  )
}
