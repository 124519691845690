import styled from 'styled-components'

const defaultLumiarStyles = {
  height: '100vh',
  width: 'fitcontent',
  minWidth: '300px',
  overflow: 'scroll',
  backgroundColor: 'white',
  paddingTop: 0,
}

export const rigthStyles = {
  ...defaultLumiarStyles,
  right: 0,
  left: 'auto',
}

export const leftStyles = {
  ...defaultLumiarStyles,
  right: 'auto',
  left: 0,
}

export const directionStyles = {
  left: leftStyles,
  right: rigthStyles,
}

export const Container = styled.div`
  .rodal-close {
    display: none;
  }
  .rodal-true-enter {
    .rodal-close {
      display: block;
    }
  }
`
