import React from 'react'
import { useTranslation } from 'react-i18next'
import Parser from 'html-react-parser'
import { isNil } from 'ramda'

import FilesList from '@legacy/common/_molecules/FilesList'
import ReadOnlyArray from '@legacy/common/types/readonly-array'
import { MaterialIcon } from '@legacy/common/Activities/ActivitiesIcons'
import { PlanningField, PropTitle, MaterialTitle } from '../styled/Activity'

const convertFileListToCamelCase = (
  data: ReadOnlyArray<{
    readonly file_id: string
    readonly download_url: string
    readonly file_cover: string
    readonly file_cover_large: string
    readonly file_type: string
    readonly name: string
    readonly size_in_bytes: string
  }>,
) =>
  data.map(item => ({
    id: item.file_id,
    downloadUrl: item.download_url,
    fileCover: item.file_cover,
    fileCoverLarge: item.file_cover_large,
    fileType: item.file_type,
    name: item.name,
    sizeInBytes: item.size_in_bytes,
  }))

const PlanningFiles = ({ files = [], hideSize = false }) => {
  const { t } = useTranslation()
  const filesSize = files.length

  if (filesSize <= 0 ?? files[0] === undefined) {
    return <div />
  }

  const dataFiles = 'downloadUrl' in files[0] ? files : convertFileListToCamelCase(files)

  return (
    <div className="mt-5">
      {!hideSize && (
        <>
          <hr />
          <MaterialTitle>
            <MaterialIcon /> {t('activities_materials')}
            <span>{`(${filesSize})`}</span>
          </MaterialTitle>
        </>
      )}
      <FilesList filesList={dataFiles} />
    </div>
  )
}

const Planning = ({ text = '', hideSize = false, ...props }) => {
  const { t } = useTranslation()
  if (isNil(text)) {
    return null
  }

  return (
    <React.Fragment>
      {text !== '' && (
        <div>
          <hr />
          <PlanningField>
            <PropTitle>{t('activities_messagePlanning')}</PropTitle>
            {Parser(text)}
          </PlanningField>
        </div>
      )}
      <PlanningFiles {...props} hideSize />
    </React.Fragment>
  )
}

export default Planning
