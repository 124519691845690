import { useCallback, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { IReceivedCurrentUser } from '@legacy/common/models/user'
import { getCurrentUser } from '../services/users-service'
import ErrorService from '@legacy/common/Tools/ErrorService'

import useLocalStorage from './local-storage-hook'
import { SchoolScoreType } from '../models/school'

function useCurrentUser() {
  const { stored, change } = useLocalStorage({
    key: 'current_user',
    initial: '',
  })

  const clean = useCallback(() => {
    change('')
  }, [])

  const update = useCallback(async () => {
    const response = await getCurrentUser()
    if (response.status === 'found') {
      change(JSON.stringify(response.data))
    }
  }, [])

  const updateFromExternal = useCallback((data: IReceivedCurrentUser) => {
    change(JSON.stringify(data))
  }, [])

  useEffect(() => {
    if (isEmpty(stored)) {
      update().catch(err => ErrorService.notice(err))
    }
  }, [stored])

  if (isEmpty(stored)) {
    return {
      loading: true,
      profileIsMaster: false,
      profileIsGuardian: false,
      selectedSchoolId: '',
      selectedScoreType: SchoolScoreType.Hand,
      update,
    }
  }

  const data = fromStorageCurrentUser(stored)
  return {
    loading: false,
    profileIsMaster: data.profile_used === 'master',
    profileIsGuardian: data.profile_used === 'guardian',
    profileIsTutor: data.profile_used === 'tutor',
    profileIsManager: data.profile_used === 'manager',
    selectedSchoolId: data.selected_school?.id ?? '',
    selectedScoreType: data.selected_school?.score_type ?? SchoolScoreType.Hand,
    data,
    clean,
    update,
    updateFromExternal,
  }
}

function fromStorageCurrentUser(raw: string): IReceivedCurrentUser {
  return JSON.parse(raw) as IReceivedCurrentUser
}

export default useCurrentUser
