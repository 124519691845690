import { useContext } from 'react'
import { GuardianDashboardContext } from '../../context/GuardianDashboardContext'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { useTranslation } from 'react-i18next'
import { Transition, Alert, Stack, Text, Button, Group } from 'components'
import { IconReport, IconFileTypePdf } from '@tabler/icons-react'
import { NewsReportAlertProps } from './interfaces'
import parse from 'html-react-parser'

export const NewReportAlert = ({
  student,
}: NewsReportAlertProps) => {

  const { t } = useTranslation()

  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const {
    showAlert,
    handleCloseAlert,
    alertReportData,
    getEndOfTermReportURL,
    handleExportReportToPDF,
    reportLoading,
  } = useContext(GuardianDashboardContext)

  if (!alertReportData) return null
  
  return (
    <Transition mounted={showAlert} transition='fade' duration={400} timingFunction='ease'>
      {styles => (
        <Alert
          title={t('new_guardian_dashboard.new_report_published_title')}
          color='green'
          withCloseButton
          style={styles}
          onClose={() => handleCloseAlert(alertReportData.id)}
        >
          <Stack spacing='sm'>
            <Text>
              {parse(t('new_guardian_dashboard.new_report_published_description', {
                name: student.name,
                term: alertReportData?.name,
              }))}
            </Text>
            <Group spacing='sm'>
              <Button
                leftIcon={<IconReport />}
                size='md'
                component='a'
                variant='filled'
                color='green'
                href={getEndOfTermReportURL(alertReportData.id)}
                fullWidth={isMobile}
              >
                {t('new_guardian_dashboard.button_view_report')}
              </Button>
              <Button
                leftIcon={<IconFileTypePdf />}
                size='md'
                component='button'
                variant='outline'
                color='green'
                loading={reportLoading}
                onClick={() => handleExportReportToPDF(alertReportData.id, student.name)}
                fullWidth={isMobile}
              >
                {t('new_guardian_dashboard.button_export_report')}
              </Button>
            </Group>
          </Stack>
        </Alert>
      )}
    </Transition>
  )
}
