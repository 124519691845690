import styled from 'styled-components'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export const LumiarToastContainer = styled(ToastContainer)`
  .Toastify__toast--error {
    background-color: var(--color-red-lighter);
    border: 1px solid var(--color-red-light);
    .Toastify__toast-body {
      color: var(--color-red-dark);
    }
    .Toastify__close-button > svg {
      fill: var(--color-red-dark);
    }
  }

  .Toastify__toast--success {
    background-color: var(--color-green-lighter);
    border: 1px solid var(--color-green-light);
    .Toastify__toast-body {
      color: var(--color-green-dark);
    }
    .Toastify__close-button > svg {
      fill: var(--color-green-dark);
    }
  }

  .Toastify__progress-bar {
    opacity: 0;
  }
`
