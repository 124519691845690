import { ObservationFile } from './sharedTypes'
import type { CamelCasedPropertiesDeep } from 'type-fest'

export interface ObservationFiles {
  observation_files: ObservationFile
}

export interface IUploadFileResponse {
  feed_id: string
  observation_files: ObservationFiles[]
}

export type CamelCasedFileResponse =
  CamelCasedPropertiesDeep<IUploadFileResponse>

export type UploadFile = (formData: FormData) => Promise<CamelCasedFileResponse>
