import { useTranslation } from 'react-i18next'
import { Grid, Button } from 'components'
import { IconLayoutGrid, IconPlus } from '@tabler/icons-react'

interface ProjectMuralActionsProps {
  viewWallURL: string
  addWallURL: string
}

export const ProjectMuralActions = ({ viewWallURL, addWallURL }: ProjectMuralActionsProps) => {
  const { t } = useTranslation()

  return (
    <Grid justify='flex-start' gutter='sm'>
      <Grid.Col span={12} sm='auto'>
        <Button
          component='a'
          fullWidth
          variant='light'
          size='md'
          radius='xl'
          leftIcon={<IconLayoutGrid />}
          href={viewWallURL}
        >
          {t('new_teacher_dashboard.button_view_project_wall')}
        </Button>
      </Grid.Col>
      <Grid.Col span={12} sm='auto'>
        <Button
          component='a'
          fullWidth
          leftIcon={<IconPlus />}
          variant='light'
          size='md'
          radius='xl'
          href={addWallURL}
        >
          {t('new_teacher_dashboard.button_add_project_wall')}
        </Button>
      </Grid.Col>
    </Grid>
  )
}
