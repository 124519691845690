import { IconX } from '@tabler/icons-react'
import { Card, Avatar, Text, ActionIcon, Grid } from 'components'
import { getInitials } from 'helpers'

export interface ICardAvatarAction {
  name: string
  role?: string
  avatar: string
  showRemove?: boolean
  onClick: () => void
}

export const CardAvatarAction = ({ name, avatar, role = '', showRemove = true, onClick }: ICardAvatarAction) => {
  return (
    <Card p='sm' withBorder shadow='sm' padding='xs'>
      <Grid align='center' gutter={0}>
        <Grid.Col span='content'>
          <Avatar size='md' radius='xl' src={avatar} alt={name}>
            {getInitials(name)}
          </Avatar>
        </Grid.Col>
        <Grid.Col pl='xs' span='auto'>
          <Text>
            {name}{' '}
            {role?.length > 0 && (
              <Text span fw={500} color='dimmed'>
                {'- '}
                {role}
              </Text>
            )}
          </Text>
        </Grid.Col>
        {showRemove && (<Grid.Col span='content'>
          <ActionIcon onClick={onClick}>
            <IconX color='red' />
          </ActionIcon>
        </Grid.Col>)}
      </Grid>
    </Card>
  )
}
