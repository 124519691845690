/* global I18n */
import { useCallback, useMemo, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Select, { Creatable } from 'react-select-old'
import { useTranslation } from 'react-i18next'
import { InputSelectStyled } from './styled'

function InputSelect({
  id,
  errors,
  label,
  name,
  value,
  values,
  disabled,
  clearable,
  labelField,
  valueField,
  valueRenderer,
  optionRenderer,
  onChange,
  onBlur,
  onReset,
  isSearchable,
  isMulti,
  hideLabelWithValues,
  selectClassName,
  multiLine,
  creatable,
  hint,
  styles,
  placeholder,
}) {
  const [focused, changeFocused] = useState(false)
  const { t } = useTranslation()

  const options = useMemo(
    () =>
      values.map((localValue) => ({
        value: localValue[valueField],
        label: localValue[labelField],
        class: localValue.class,
      })),
    [values, labelField, valueField]
  )
  const showLabel = useMemo(
    () => !hideLabelWithValues || (hideLabelWithValues && isEmpty(value)),
    [value, hideLabelWithValues]
  )

  const handleOnFocus = useCallback(() => {
    changeFocused(true)
  }, [])

  const handleOnBlur = useCallback(() => {
    changeFocused(false)
    onBlur()
  }, [])

  const handleResetValue = useCallback(() => {
    onReset()
    if (isMulti) {
      return []
    }
    return { value: '' }
  }, [isMulti])

  const SelectComponent = creatable ? Creatable : Select
  const isInside = isEmpty(value) && !focused
  return (
    <InputSelectStyled>
      <div className='register-form not-animate'>
        <div onFocusCapture={handleOnFocus} onBlurCapture={handleOnBlur}>
          {label && (
            <label
              htmlFor={id}
              className={classnames('__label', {
                inside: isInside,
                top: !isInside,
                'is-multi-select-label': isMulti,
                'multi-line-select-label': multiLine,
              })}
            >
              {showLabel && label}
            </label>
          )}
          <SelectComponent
            name={name}
            id={id}
            placeholder={placeholder}
            noResultsText={t('commons_blankSlateText')}
            value={value}
            options={options}
            onChange={onChange}
            onBlur={onBlur}
            resetValue={handleResetValue}
            clearable={clearable}
            disabled={disabled}
            valueRenderer={valueRenderer}
            optionRenderer={optionRenderer}
            searchable={isSearchable}
            inputProps={{ autoComplete: 'off' }}
            multi={isMulti}
            className={classnames(selectClassName, styles.select, {
              'multi-line-select': multiLine,
              '__with-error': errors.length,
            })}
          />
          {hint && <p className={classnames('hint', styles.hint)}>{hint}</p>}
          <span className='validation-error'>{errors}</span>
        </div>
      </div>
    </InputSelectStyled>
  )
}

InputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  labelField: PropTypes.string,
  valueField: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onReset: PropTypes.func,
  valueRenderer: PropTypes.func,
  optionRenderer: PropTypes.func,
  isMulti: PropTypes.bool,
  hideLabelWithValues: PropTypes.bool,
  selectClassName: PropTypes.string,
  multiLine: PropTypes.bool,
  creatable: PropTypes.bool,
  hint: PropTypes.string,
  styles: PropTypes.object,
}

InputSelect.defaultProps = {
  placeholder: '',
  errors: [],
  name: '',
  clearable: true,
  disabled: false,
  labelField: 'label',
  valueField: 'id',
  onReset: () => null,
  values: [],
  valueRenderer: undefined,
  optionRenderer: undefined,
  isSearchable: true,
  label: undefined,
  isMulti: false,
  hideLabelWithValues: false,
  selectClassName: '',
  multiLine: false,
  creatable: false,
  hint: '',
  styles: {
    hint: '',
  },
  onBlur: () => {},
}

export default InputSelect
