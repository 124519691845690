export const initialValues = {
  name: '',
  projectType: '1',
  kind: '',
  purpose: '',
  guidingQuestion: '',
  finalProduct: '',
  finalProductKind: '',
  modality: 'project',
  schoolId: '',
  synopsis: '',
  objectives: '',
  stages: '',
  finalProductDescription: '',
  ageGroup: '',
  schoolYear: '',
  submitButton: 0,
  shareCommunity: false,
  fileCover: '',
  grades: [],
  coverImage: '',
  evaluationToolsAttributes: [],
}

export interface FormValues {
  name: string
  kind: string
  stages: string
  modality: string
  guidingQuestion?: string
  finalProduct?: string
  finalProductKind?: string
  objectives?: string
  ageGroup?: string
  schoolYear?: string
  submitButton: number
  shareCommunity: boolean
  schoolId?: string
  grades?: any
  coverImage?: string
  projectType?: string
  fileCover?: string | null
  purpose?: string
  finalProductDescription?: string | undefined
  FormValues?: string[]
  evaluationToolsAttributes?: any[]
  synopsis?: string
}

export interface Grade {
  id: string
  name: string
  value?: string
}

export interface MappedObject {
  value: string
  label: string
  name?: string
  id?: string
  grades: Grade[]
}

export type ProjectType = 'project' | 'template' | 'application'
