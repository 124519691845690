import { IStudentsAssessmentsProps, IStudent } from './StudentsAssessmentsTypes'
import { StudentsGrid, Wrapper, ButtonMask, LoaderContainer } from './StudentAssessmentsStyled'
import { isEmpty, isEqual } from 'lodash'
import LumiarDrawer from 'components/LumiarDrawer/LumiarDrawer'
import { useEffect, useState } from 'react'
import { Modal } from 'components/ModalLegacy'
import { useTranslation } from 'react-i18next'
import StudentCard from 'components/StudentCardPlus/StudentCard'
import StudentModalContent from '../StudentModalContent/StudentModalContent'
import service from './assessmentService'
import { IKind } from 'components/ModalLegacy'
import { Beforeunload } from 'react-beforeunload'
import CircularLoader from 'components/CircularLoader/CircularLoader'
import { IFinalAssessmentScore, ServerData } from '../../service/interfaces'
import { StudentsAssessmentsContainer } from './styled'

const StudentsAssessments = ({
  students,
  mixPanelTracker,
  meetingId,
  setPayload,
  setInitialPayload,
  payload,
  initialPayload,
  setPage,
  updateProject,
}: IStudentsAssessmentsProps) => {
  const { t } = useTranslation()
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>(students[0])
  const [allStudents, setAllStudents] = useState<IStudent[]>([])
  const [loadingStudents, setLoadingStudents] = useState(false)
  const [studentIndex, setStudentIndex] = useState(0)

  useEffect(() => {
    fetchStudents()
  }, [])

  const fetchStudents = async () => {
    setLoadingStudents(true)
    const response = await service.getStudents(meetingId)
    setAllStudents(response)
    setLoadingStudents(false)
  }

  const saveAndGoNext = async () => {
    handleClickOnStudent(allStudents[studentIndex + 1], studentIndex + 1)
    fetchStudents()
  }

  const handleCloseDrawer = (value: boolean, skipConfirmation: boolean = false) => {
    if (loadingStudents) return
    const hasChanges = !isEqual(payload, initialPayload)
    if (hasChanges && !skipConfirmation) {
      Modal({
        title: t('students_assessment_title_modal'),
        text: t('students_assessment_text_modal'),
        kind: IKind.ConfirmationExit,
      }).then(result => {
        if (result.isConfirmed) {
          setPage()
          setShowDrawer(value)
          document.body.style.overflow = 'scroll'
        }
      })
      return
    }

    setShowDrawer(value)
    document.body.style.overflow = 'scroll'
  }

  const handleClickOnStudent = async (student: IStudent, index: number) => {
    await mixPanelTracker('Avaliar estudante')
    setLoadingStudents(true)

    const selected = await service.getStudent(meetingId, student.id)
    const generateFinalAssessmentScorePayload = (score: any) => {
      return {
        do_not_assess: score.doNotAssess,
        id: score.id,
        score: score.masterScore,
      }
    }
    const contentScores: IFinalAssessmentScore[] = selected.contentFinalAssessmentScores.map(
      generateFinalAssessmentScorePayload,
    )
    const skillScores: IFinalAssessmentScore[] = selected.specificSkillFinalAssessmentScores.map(
      generateFinalAssessmentScorePayload,
    )
    const generatePayload = (payload: ServerData) => {
      return {
        ...payload,
        final_assessment_scores: [...contentScores, ...skillScores],
        journal: { id: selected.masterJournalId, value: selected.masterJournal },
      }
    }
    setInitialPayload(generatePayload)
    setPayload(generatePayload)

    setLoadingStudents(false)
    setSelectedStudent(selected)
    setShowDrawer(true)
    setStudentIndex(index)
  }

  const goNext = allStudents.length !== studentIndex + 1

  return (
    <StudentsAssessmentsContainer>
      {isEmpty(allStudents) ? (
        <LoaderContainer>
          <CircularLoader size={32} color='var(--color-primary-dark)' thickness={3} time={1} />
        </LoaderContainer>
      ) : (
        <Wrapper>
          {!isEqual(payload, initialPayload) && <Beforeunload onBeforeunload={event => event.preventDefault()} />}

          <h2>{t('final_assessments_title')}</h2>
          <StudentsGrid>
            {allStudents?.map((student, index) => {
              return (
                <ButtonMask
                  key={student.id}
                  onClick={() => {
                    handleClickOnStudent(student, index)
                    document.body.style.overflow = 'hidden'
                  }}
                  role='button'
                >
                  <StudentCard
                    name={student.fullName}
                    image={student.imageUrl}
                    status={student.assessmentStatus}
                    studentStatus={student.studentAssessmentStatus}
                    hasScore
                  />
                </ButtonMask>
              )
            })}
          </StudentsGrid>

          <LumiarDrawer visibility={showDrawer} onChange={handleCloseDrawer} side='right' scrollable={false}>
            {showDrawer && selectedStudent !== null && (
              <StudentModalContent
                assessment_id={selectedStudent.finalAssessmentId}
                fullName={selectedStudent.fullName}
                imageUrl={selectedStudent?.imageUrl || ''}
                contentsAssessments={selectedStudent.contentFinalAssessmentScores}
                skills={selectedStudent.specificSkillFinalAssessmentScores}
                masterjustification={selectedStudent.masterJournal}
                journalId={selectedStudent.masterJournalId}
                selectedStudent={selectedStudent}
                goNext={goNext}
                handleCloseDrawer={handleCloseDrawer}
                mixPanelTracker={mixPanelTracker}
                fetchStudents={fetchStudents}
                saveAndGoNext={saveAndGoNext}
                journalStudent={selectedStudent.studentJournal}
                setPayload={setPayload}
                setInitialPayload={setInitialPayload}
                payload={payload}
                initialPayload={initialPayload}
                projectId={meetingId}
                updateProject={updateProject}
              />
            )}
          </LumiarDrawer>
        </Wrapper>
      )}
    </StudentsAssessmentsContainer>
  )
}
export default StudentsAssessments
