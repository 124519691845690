import { useContext, FC } from 'react'
import TabContext from '../context/students-context'
import { Box } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Button } from 'components/Button'
import { IconPlus } from '@tabler/icons-react'
import { ITabContext } from '../types/student-types'
import { useMediaQuery } from '@mantine/hooks'
import { isEmpty } from 'lodash'

interface WrapFixedButtonsProps {
  style?: React.CSSProperties
}

const WrapFixedButtons: FC<WrapFixedButtonsProps> = ({ style }) => {
  const { loading, actions } = useContext<ITabContext>(TabContext)
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return loading || !isMobile ? null : (
    <Box
      component='div'
      display='flex'
      style={{
        position: 'fixed',
        bottom: '24px',
        zIndex: 999,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        left: 0,
        ...style,
      }}
    >
      <Button leftIcon={<IconPlus />} component='a' href='/learners/new' size='lg' disabled={isEmpty(actions.new)}>
        {t('students_new_button')}
      </Button>
    </Box>
  )
}

export default WrapFixedButtons
