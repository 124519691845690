import styled from 'styled-components'
import { DropdownMenuItem } from 'components/DropdownMenu'

export const NameStyled = styled.p`
  font-weight: 700;
  padding: 0;
  margin: 0;
`

export const DropdownMenuItemStyled = styled(DropdownMenuItem)`
  display: flex;

  padding: 12px 0px 0px 20px;
  color: var(--color-black);
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  i {
    font-size: 1.3em;
  }
`

export const LinkStyled = styled.a`
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: var(--mantine-color-purple-5);
  text-decoration: none;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  &:hover {
    color: var(--mantine-color-purple-3);
    text-decoration: none;
  }
`

export const NameContainerStyled = styled.div`
  padding-left: 8px;
`
