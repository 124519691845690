import { Alert, List } from 'components'
import { IconCheck, IconAlertCircle } from '@tabler/icons-react'

type StatusType = 'danger' | 'success'

type Props = {
  lesson: any
  hasPermission: boolean
  t: (key: string) => string
}

const LessonAlert: React.FC<Props> = ({ lesson, hasPermission, t }) => {
  const status = lesson.sessionMeetingStatus.status as StatusType

  const statusSettings: { [key in StatusType]: { color: string; icon: JSX.Element } } = {
    danger: { color: 'yellow', icon: <IconAlertCircle size='1.4rem' /> },
    success: { color: 'green', icon: <IconCheck size='1.4rem' /> },
  }

  const { color, icon } = statusSettings[status] || statusSettings['danger']

  return (
    <Alert
      icon={icon}
      title={status !== 'success' ? t('generalAlertLesson') : t('task_ok')}
      color={color}
      mb='md'
      mt='md'
    >
      <>
        <p>
          {status === 'success'
            ? t('task_ok_message')
            : `${lesson.sessionMeetingStatus.message
              .charAt(0)
              .toUpperCase()}${lesson.sessionMeetingStatus.message.slice(1)}`}
        </p>
        <List mt='xs' spacing='xs' size='sm'>
          {lesson?.sessionMeetingStatus.pendencies?.map((pendency: string, index: number) => (
            <List.Item key={index}>{pendency}</List.Item> // Use um identificador mais estável se disponível
          ))}
        </List>
      </>
    </Alert>
  )
}

export default LessonAlert
