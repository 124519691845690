import Styled from 'styled-components'

export const Card = Styled.div`
    position: relative;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;


    a {
        font-size: 0.75rem;
        margin: 0;
        padding-left: 16px;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        position: absolute;
        top: 8px;
        right: 8px;

        i {
            color: #d3d3d3;
        }

        &:hover {
            i {
                color: #000;
            }
        }
    }
`
export const LinkContainer = Styled.div`
    white-space: nowrap; 
    width: 80%; 
    overflow: hidden;
    text-overflow: ellipsis; 

    &:hover {
        text-decoration: underline;
    }
`
export default {}
