// TODO remover arquivo
import branding from 'assets/branding.png'
import { shared } from './shared'

export const darkTheme = {
  colors: {
    black: '#121019',
    primary: '#7FE3D0',
    dark: '#43d6ba',
    yellow: '#FFC132',
    ultradark: '#197D6A',
    light: '#c0f1e8',
    ultralight: '#eafaf7',
    green: '#14B858',
    textPrimary: '#FAFAFA',
    textDisabled: '#8D8A99',
    textSecondary: '#625F6D',
    link: '#6A0BD6',
    linkHover: '#A160EC',
    linkActive: '#410189',
    pink: '#F285A7',
    pinkDark: '#E84D7D',
    red: '#E5251A',
    redLight: '#F5A8A3',
    redLighter: '#FAD3D1',
    redDark: '#7F201A',
    greenLight: '#A3F5C5',
    greenLighter: '#D1FAE2',
    greenDark: '#1A733F',
    greenDarker: '#668e44',
    white: '#FFFFFF',
    gray: '#D6D6D6',
    grayLight: '#D3D2D7',
    grayLighter: '#F4F6F9',
    grayDark: '#625F6D',
    grayDarker: '#39383D',
    lightOrange: '#F9B274',
    orange: '#F57E17',
    darkOrange: '#C46512',
    darkPurple: '#203049',
    darkerpurple: '#131D2C',
    alertGreen: '#1AE56F',
    blue: '##53B7E7',
    darkBlue: '#2A99CE',
  },
  imageLogo: branding,
  background: '#282A36',
  ...shared,
}
