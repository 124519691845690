import { api } from 'helpers'
import { AxiosResponse } from 'axios'

export async function getSchoolTerms(): Promise<AxiosResponse> {
  try {
    const response = await api.get('/api/school_terms?list=all')
    return response
  } catch (error: any) {
    return error
  }
}

export async function getOldSchoolTerms(): Promise<AxiosResponse> {
  try {
    const response = await api.get('/school_terms.json')
    return response
  } catch (error: any) {
    return error
  }
}
