import { useTranslation } from 'react-i18next'
import { ScrollArea } from 'hooks'
import dayjs from 'dayjs'
import { Modal, Stack, Skeleton, Title, Text, EmptyStateBox } from 'components'
import { AssessmentInfo } from '../AssessmentInfo/AssessmentInfo'
import { AssessmentHistoryModalProps } from './interfaces'
import { IconArticleOff } from '@tabler/icons-react'

export const AssessmentHistoryModal = ({
  openedHistoryModal,
  closeHistoryModal,
  historyModalContent,
  userName,
  avatarURL,
  loading,
}: AssessmentHistoryModalProps) => {
  const { t, i18n } = useTranslation()

  return (
    <Modal
      size='xl'
      title={t('partial_assessments_history_modal_title')}
      opened={openedHistoryModal}
      onClose={closeHistoryModal}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <Stack spacing='md'>
        {loading && (
          <Stack spacing='md'>
            <Skeleton h={20} />
            <Skeleton h={100} />
          </Stack>
        )}
        {historyModalContent && historyModalContent.length === 0 && loading === false && (
          <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('partial_assessments_no_asseessed_meetings')} />
        )}
        {historyModalContent && historyModalContent.length > 0
          ? historyModalContent.map((item, index) => {
            const date =
                i18n.language === 'pt-BR'
                  ? dayjs(item.date).format('DD/MM/YYYY')
                  : dayjs(item.date).format('MM/DD/YYYY')

            return (
              <Stack key={index} spacing='sm'>
                <Title order={5}>
                  {date} - {item.title}
                </Title>
                <Stack spacing='sm'>
                  {item.assessments.educator_score > 0 && (
                    <AssessmentInfo
                      title={t('partial_assessment_teacher_title')}
                      score={item.assessments.educator_score}
                      report={item.assessments.educator_journal}
                      userName={item.assessments.evaluator_user.name}
                      avatarURL={item.assessments.evaluator_user.avatar}
                    />
                  )}
                  {item.assessments.student_score > 0 && (
                    <AssessmentInfo
                      title={t('partial_assessment_student_title', { fullName: userName })}
                      score={item.assessments.student_score}
                      report={item.assessments.student_journal}
                      userName={userName}
                      avatarURL={avatarURL}
                    />
                  )}
                  {item.assessments.educator_score === 0 && item.assessments.student_score === 0 && (
                    <Text color='dimmed'>{t('partial_assessments_no_assessments')}</Text>
                  )}
                </Stack>
              </Stack>
            )
          })
          : null}
      </Stack>
    </Modal>
  )
}
