import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { camelizeKeys, decamelizeKeys } from 'humps'

const api = axios.create()
const token = document.getElementsByName('csrf-token')[0]?.getAttribute('content')
api.defaults.headers.common['X-CSRF-Token'] = token
api.defaults.headers.common.Accept = 'application/json'

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data && response.headers['content-type'].includes('application/json')) {
      response.data = camelizeKeys(response.data)
    }

    return response
  },

  error => {
    if (error.response.status === 401) {
      return error.response
    }
    if (error.response.status === 422) {
      return error.response
    }
  },
)

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const newConfig = { ...config }
  if (newConfig.headers['Content-Type'] === 'multipart/form-data') {
    return newConfig
  }

  if (config.params) {
    newConfig.params = decamelizeKeys(config.params)
  }

  if (config.data) {
    newConfig.data = decamelizeKeys(config.data)
  }

  return newConfig
})

export default api
