import { rem } from 'hooks'
import { Badge, Text, Tooltip } from 'components'
import { ILvl4Item } from 'interfaces' 

interface ProjectCardSkillContentBadgeItemProps {
  item: ILvl4Item
}

export const ProjectCardSkillContentBadgeItem = ({ item }: ProjectCardSkillContentBadgeItemProps) => {
  const TRUNCATE_CHARS = 40

  const charsQtd = item.name.length
  if (charsQtd >= TRUNCATE_CHARS) {
    return (
      <Tooltip withArrow withinPortal label={item.name} position='top'>
        <Badge variant='filled' sx={{ backgroundColor: item.color, maxWidth: rem(275) }}>
          <Text truncate>{item.name}</Text>
        </Badge>
      </Tooltip>
    )
  }

  return (
    <Badge variant='filled' sx={{ backgroundColor: item.color }}>
      <Text>{item.name}</Text>
    </Badge>
  )
}
