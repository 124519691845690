import { InputSelectValue } from '@legacy/common/types/input'
import { IModelErrors } from '../base-model'

export enum SubScriptionMonthCycles {
  One = '1',
  Three = '3',
  Six = '6',
  OneYear = '12'
}

export interface ISchoolSubscription {
  readonly school_id: string
  readonly school_plan_id: string
  readonly is_pilot: boolean
  readonly activated_at: string // TODO move to Date model
  readonly month_cycles: SubScriptionMonthCycles
  readonly students_min: string
}

export type ISchoolSubscriptionErrors = IModelErrors<ISchoolSubscription>

export const initialSchoolSubscription: ISchoolSubscription = {
  school_id: '',
  school_plan_id: '',
  is_pilot: false,
  activated_at: '',
  month_cycles: SubScriptionMonthCycles.One,
  students_min: ''
}

export const initialSchoolSubscriptionErrors: ISchoolSubscriptionErrors = {
  school_id: [],
  school_plan_id: [],
  is_pilot: [],
  activated_at: [],
  month_cycles: [],
  students_min: []
}

export function getMontCyclesValues (): InputSelectValue[] {
  return Object.values(SubScriptionMonthCycles).map((m, i) => ({ label: `${m} ${m === '1' ? 'mês' : 'meses'}`, id: m }))
}
