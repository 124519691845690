import { useContext } from 'react'
import { Menu, Tooltip, ActionIcon } from 'components'
import { isEmpty } from 'lodash'
// import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { FeedListContext } from 'pages/Feed/contexts/FeedListContext'
import { useMixPanel } from 'hooks'
import { IconCheck, IconDots, IconLock, IconTrash, IconEdit } from '@tabler/icons-react'
import { FeedCamelCased } from 'pages/Feed/serviceFeed'

export const PostActions = ({ feed }: { feed: FeedCamelCased }) => {
  const mixpanel = useMixPanel()

  const { deleteObservation, changeFeedStatus, onEditFeedCard } = useContext(FeedListContext)
  const { t } = useTranslation()

  const isPublic = !isEmpty(feed.hourPublished)
  const sendStatus = isPublic ? 0 : 1
  const { permissions, thereIsStudent } = feed

  if (!permissions) return null

  if (!permissions.changeStatus && !permissions.delete && !permissions.edit) return null

  return (
    <Menu shadow='md'>
      <Menu.Target>
        <Tooltip position='top' label={t('feed_more')} withArrow withinPortal>
          <ActionIcon size='md' color='turquoise' radius='xl' variant='default'>
            <IconDots />
          </ActionIcon>
        </Tooltip>
      </Menu.Target>
      <Menu.Dropdown>
        {permissions.changeStatus && (
          <Menu.Item
            icon={isPublic ? <IconLock /> : <IconCheck />}
            component='button'
            onClick={() => {
              changeFeedStatus(feed.feedId, sendStatus, thereIsStudent, onEditFeedCard)
            }}
          >
            {isPublic ? t('feed_make_private') : t('general_buttons_publish')}
          </Menu.Item>
        )}
        {permissions.edit && (
          <Menu.Item
            icon={<IconEdit />}
            component='button'
            onClick={() => {
              mixpanel.track('Editar post')
              onEditFeedCard(feed.feedId)
            }}
          >
            {t('general_buttons_edit')}
          </Menu.Item>
        )}
        {permissions.delete && (
          <Menu.Item
            color='red'
            icon={<IconTrash />}
            component='button'
            onClick={() => {
              mixpanel.track('Remover post')
              deleteObservation(feed.feedId)
            }}
          >
            {t('general_buttons_remove')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
