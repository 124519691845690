import { Tooltip, Group, Text, UnstyledButton, Transition } from 'components'
import { SidebarMenuIcon } from '../SidebarMenuIcon/SidebarMenuIcon'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'
import { SidebarMenuItemProps } from './interfaces'

export const SidebarMenuItem = ({ item, pathname, collapsed }: SidebarMenuItemProps) => {
  const isCurrentPath = pathname === item.link
  const { classes } = useStyles({ active: isCurrentPath })
  const { t } = useTranslation()
  const label = t(`sidebar_menu.${item.key}`)
  const link = item.link
  const icon = item.icon

  if(link === null || label === null || icon === null) return null

  return (
    <li className={classes.listItem}>
      <Tooltip disabled={!collapsed} label={label} withArrow withinPortal position='right'>
        <UnstyledButton component='a' href={link} className={classes.menuItem}>
          <Group spacing={4} className={classes.menuItemContent}>
            <SidebarMenuIcon icon={icon} />
            <Transition mounted={!collapsed} transition='fade' duration={400} timingFunction='ease'>
              {styles => (
                <Text fw='bold' size='md' style={styles} className={classes.menuItemText}>
                  {label}
                </Text>
              )}
            </Transition>
          </Group>
        </UnstyledButton>
      </Tooltip>
    </li>
  )
}
