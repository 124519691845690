import DeviseLanguage from '@legacy/common/components/DeviseLanguage'
import { SchoolLogoColor } from '@legacy/common/types/whitelabel'
import Logo from '@legacy/components/shared/Logo/logo-component'
import { Mosaico } from '@legacy/pages/Account/SignIn/session-new-styles'
import { NotificationProvider } from 'context'
import { BodyChangePasswordScreen } from './BodyChangePasswordScreen'
import { Space, Heading } from 'components'
import { useTranslation } from 'react-i18next'
import { Container } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'

export const ChangePasswordScreen = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <NotificationProvider>
      <Mosaico>
        <Logo color={SchoolLogoColor.White} />
        <DeviseLanguage />
      </Mosaico>
      <Space h='xl' />
      <Container size='54rem' px={isMobile ? 20 : 0}>
        <Heading
          size='xl'
          text={t('forgot_password.change_password')}
          supportText={t('forgot_password.change_password_details')}
        />
      </Container>
      <BodyChangePasswordScreen />
    </NotificationProvider>
  )
}
