import { useMemo } from 'react'
import { Wrap, Container, IconContainer, WrapWithOutBorder } from './scoreCardStyled'
import Check from '@legacy/common/Icons/Check'
import { ScoreItem, ScoreItemProps, ScoreItemVariant } from '../ScoreItem'
import { Tooltip } from 'components'

export type ScoreCardProps = Omit<ScoreItemProps, 'variant'> & {
  readonly variant?: ScoreItemVariant
  readonly onClick: React.MouseEventHandler<HTMLButtonElement>
  readonly kind?: string
  readonly rubric: string
}

export const ScoreCard = ({ onClick, kind, rubric, ...item }: ScoreCardProps) => {
  const WrapperComponent = useMemo(() => (kind === 'default' ? WrapWithOutBorder : Wrap), [kind])
  const checkIsVisible = useMemo(() => kind !== 'default' && !item.disabled && item.active, [kind, item])

  return (
    <WrapperComponent onClick={onClick} role='button' active={!!item.active} disabled={!!item.disabled}>
      <Tooltip withinPortal label={rubric} withArrow position='top' events={{ hover: true, focus: false, touch: true }}>
        <Container>
          {checkIsVisible && (
            <IconContainer>
              <Check fill='var(--color-primary-ultradark)' width={16} />
            </IconContainer>
          )}
          <ScoreItem {...item} variant={item.variant} />
        </Container>
      </Tooltip>
    </WrapperComponent>
  )
}
