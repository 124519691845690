import { createContext, useState } from 'react'
import { IProject, IStudent, ITermSelect, ISelectData, IReportData } from 'interfaces'
import { patchDisableGuardianStudentReport, getFinalReportJSON } from 'api'
import { pdf } from '@react-pdf/renderer'
import { Pdf } from 'components/PDF'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { useFeatureToggle } from 'hooks'

interface GuardianDashboardContextProps {
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
  students: IStudent[] | null
  setStudents: React.Dispatch<React.SetStateAction<IStudent[] | null>>
  currentStudent: string | null
  setCurrentStudent: React.Dispatch<React.SetStateAction<string | null>>
  projects: IProject[] | null
  setProjects: React.Dispatch<React.SetStateAction<IProject[] | null>>
  projectsLoading: boolean
  setProjectsLoading: React.Dispatch<React.SetStateAction<boolean>>
  filterData: ITermSelect[] | null
  setFilterData: React.Dispatch<React.SetStateAction<ITermSelect[] | null>>
  studentDataLoading: boolean
  setStudentDataLoading: React.Dispatch<React.SetStateAction<boolean>>
  selectData: ISelectData[] | null
  setSelectData: React.Dispatch<React.SetStateAction<ISelectData[] | null>>
  filterTerm: string | null
  setFilterTerm: React.Dispatch<React.SetStateAction<string | null>>
  getDiscURL: (id: string) => string
  getProfileURL: (id: string) => string
  getMuralURL: (id: string) => string
  getReportURL: (id: string) => string
  showAlert: boolean
  setShowAlert: React.Dispatch<React.SetStateAction<boolean>>
  handleCloseAlert: (id: string) => void
  alertReportData: IReportData | null
  setAlertReportData: React.Dispatch<React.SetStateAction<IReportData | null>>
  getEndOfTermReportURL: (id: string) => string
  handleExportReportToPDF: (id: string, name: string) => void
  reportLoading: boolean
  setReportLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const GuardianDashboardContext = createContext<GuardianDashboardContextProps>({
  error: null,
  setError: () => {},
  students: null,
  setStudents: () => {},
  currentStudent: null,
  setCurrentStudent: () => {},
  projects: null,
  setProjects: () => { },
  projectsLoading: false,
  setProjectsLoading: () => { },
  filterData: null,
  setFilterData: () => { },
  studentDataLoading: false,
  setStudentDataLoading: () => { },
  selectData: null,
  setSelectData: () => {},
  filterTerm: '1',
  setFilterTerm: () => {},
  getDiscURL: () => '',
  getProfileURL: () => '',
  getMuralURL: () => '',
  getReportURL: () => '',
  showAlert: false,
  setShowAlert: () => {},
  handleCloseAlert: () => {},
  alertReportData: null,
  setAlertReportData: () => {},
  getEndOfTermReportURL: () => '',
  handleExportReportToPDF: () => { },
  reportLoading: false,
  setReportLoading: () => { },
})

export const GuardianDashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()

  const [error, setError] = useState<string | null>(null)
  const [students, setStudents] = useState<IStudent[] | null>(null)
  const [currentStudent, setCurrentStudent] = useState<string | null>(null)
  const [projects, setProjects] = useState<IProject[] | null>(null)
  const [projectsLoading, setProjectsLoading] = useState<boolean>(true)
  const [filterData, setFilterData] = useState<ITermSelect[] | null>(null)
  const [studentDataLoading, setStudentDataLoading] = useState<boolean>(true)
  const [selectData, setSelectData] = useState<ISelectData[] | null>(null)
  const [filterTerm, setFilterTerm] = useState<string | null>(null)
  const [showAlert, setShowAlert] = useState(false)
  const [alertReportData, setAlertReportData] = useState<IReportData | null>(null)
  const [reportLoading, setReportLoading] = useState<boolean>(false)

  const NewFinalProjectReport = useFeatureToggle('new_final_project_report')


  const getDiscURL = (id: string) => {
    if (id) {
      const url = `/students/${id}/student_mosaics`
      return url
    }
    return ''
  }

  const getProfileURL = (id: string) => {
    if (id) {
      const url = `/students/${id}`
      return url
    }
    return ''
  }


  const getMuralURL = (id: string) => {
    if (id) {
      const url = `/projects/${id}/mural_items`
      return url
    }
    return ''
  }

  const getReportURL = (id: string) => {
    if (id) {
      const url = NewFinalProjectReport ? `/final_project_report/${id}` : `/projects/${id}/final_report`
      return url
    }
    return ''
  }

  const getEndOfTermReportURL = (id: string) => {
    if (id) {
      const url = `/end_of_term_reports/${id}`
      return url
    }
    return ''
  }

  const handleExportReportToPDF = async (id: string, fullName: string) => {
    setReportLoading(true)
    const fileName = t('end_of_term_reports.final_period_report')
    getFinalReportJSON(id).then(async response => {
      if (response && response.status === 200) {
        const blob = await pdf(<Pdf data={response.data} />).toBlob()
        saveAs(blob, `${fileName} - ${fullName}.pdf`)
        setReportLoading(false)
      } else {
        setError(response.statusText)
      }
    })
  }

  const handleCloseAlert = (id: string) => {
    patchDisableGuardianStudentReport(id).then(response => {
      if (response && response.status === 200) {
        setAlertReportData(null)
        setShowAlert(false)
      } else {
        setError(response.statusText)
      }
    })
  }

  return (
    <GuardianDashboardContext.Provider
      value={{
        error,
        setError,
        students,
        setStudents,
        currentStudent,
        setCurrentStudent,
        projects,
        setProjects,
        projectsLoading,
        setProjectsLoading,
        filterData,
        setFilterData,
        studentDataLoading,
        setStudentDataLoading,
        selectData,
        setSelectData,
        filterTerm,
        setFilterTerm,
        getDiscURL,
        getProfileURL,
        getMuralURL,
        getReportURL,
        showAlert,
        setShowAlert,
        handleCloseAlert,
        alertReportData,
        setAlertReportData,
        getEndOfTermReportURL,
        handleExportReportToPDF,
        reportLoading,
        setReportLoading,
      }}
    >
      {children}
    </GuardianDashboardContext.Provider>
  )
}
