import { useContext, useEffect, useState } from 'react'
import { Button, Grid, Tooltip, Box, Divider } from 'components'
import { useMantineTheme } from 'hooks/useMantineTheme'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'hooks'
import { ProjectButtonsContext } from '../../context/ProjectButtonsContext'
import { StepperContext } from '../../context/StepperContext'
import { IconCheck, IconChevronLeft, IconX } from '@tabler/icons-react'
import { ChangedDataConfirmModal } from 'components/ChangedDataConfirmModal'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { useStyles } from './styles'
import { approveProject, publishProject } from 'pages/NewProject/service/projects'
import { notifications } from 'context'
import { projectKind } from 'pages/NewProject/service/interfaces'

interface ProjectStepsButtonsProps {
  formAction?: (arg0: string, arg1: number) => void
  hasChanges?: () => boolean
  isSkillsStandardsStep?: boolean
  finalTabInfo?: any
  schoolTermEndAt?: string
  kind?: projectKind
}

export const ProjectStepsButtons = ({
  formAction,
  hasChanges,
  isSkillsStandardsStep,
  finalTabInfo,
  schoolTermEndAt,
  kind,
}: ProjectStepsButtonsProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { buttonValues } = useContext(ProjectButtonsContext)
  const { active, prevStep, projectStatus, projectId, setHasChanged, setBlock } = useContext(StepperContext)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const { countSelected, reviewModalOpen } = useContext(SkillsStandardsContext)
  const { classes } = useStyles({ isSkillsStandardsStep: isSkillsStandardsStep, isMobile: isMobile })

  const publishButton =
    finalTabInfo && (finalTabInfo?.status === 'draft' || finalTabInfo?.status === 'reviewing') && kind === 'template'

  const handlePrevStep = () => {
    if (!hasChanges) {
      prevStep()
      setBlock(false)

      return
    }

    if (hasChanges()) {
      setShowAlert(true)
      setBlock(false)
    } else {
      prevStep()
    }
  }

  const onPublishTemplate = () => {
    setHasChanged(false)

    publishProject(finalTabInfo.id).then(res => {
      if (res.status === 200) {
        notifications.show({
          message: t('new_project_page.publish_success'),
          color: 'green',
          icon: <IconCheck size={24} />,
        })

        setTimeout(() => {
          window.location.href = `/projects_library/${projectId}`
        }, 1300)
      } else {
        notifications.show({
          message: t('new_project_page.publish_error'),
          color: 'red',
          icon: <IconX size={24} />,
        })
      }
    })
  }

  const onStartPratice = () => {
    setHasChanged(false)

    approveProject(finalTabInfo.id).then(res => {
      if (res?.status === 401) {
        notifications.show({
          message: t(res.data.error),
          color: 'red',
          icon: <IconX size={24} />,
        })

        return
      }

      if (res.data === 'Unauthorized') {
        notifications.show({
          message: t('new_project_page.start_error'),
          color: 'red',
          icon: <IconX size={24} />,
        })

        return
      }

      if (res === undefined) {
        notifications.show({
          message: t('new_project_page.start_error'),
          color: 'red',
          icon: <IconX size={24} />,
        })
      } else {
        notifications.show({
          message: t('new_project_page.start_success'),
          color: 'green',
          icon: <IconCheck size={24} />,
        })

        setTimeout(() => {
          handleShowProject()
        }, 2000)
      }
    })
  }

  // [Attention!] This function only works on tab 4, after placing tabs 1 and 2 inside the forms
  // the submit function takes priority
  const handleShowProject = () => {
    setHasChanged(false)

    const isTemplate = kind === 'template'
    const isReviewing = projectStatus === 'reviewing'
    const url = isTemplate && isReviewing ? `/projects_library/${projectId}` : `/projects/${projectId}`

    const isFinaltab = (isTemplate && active === 2) || active === 3

    if (!isFinaltab) return

    if (finalTabInfo || projectId !== '') {
      window.location.href = url
    }
  }

  return (
    <>
      <ChangedDataConfirmModal opened={showAlert} close={() => setShowAlert(false)} discard={prevStep} />

      <Box className={classes.buttonsBox}>
        <Divider mb='xs' />
        <Grid gutter='md' justify={active === 0 ? 'flex-end' : 'space-between'} m={0} p={0}>
          {active !== 0 && (
            <Grid.Col span={isMobile ? 12 : 'auto'}>
              <Button
                fullWidth={isMobile}
                size='lg'
                variant='default'
                leftIcon={<IconChevronLeft />}
                onClick={() => handlePrevStep()}
              >
                {t('general_buttons_back')}
              </Button>
            </Grid.Col>
          )}
          {!isSkillsStandardsStep && (
            <Grid.Col span={isMobile ? 12 : 'content'}>
              <Tooltip
                withArrow
                position='top'
                label={
                  projectStatus === 'draft'
                    ? t('new_project_page.save_draft_button_tooltip')
                    : t('new_project_page.save_button_tooltip')
                }
              >
                <Button
                  fullWidth={isMobile}
                  loading={buttonValues[0].loading}
                  disabled={buttonValues[0].disabled}
                  type='submit'
                  size='lg'
                  variant='default'
                  onClick={handleShowProject}
                >
                  {projectStatus === 'draft'
                    ? t('new_project_page.save_draft_button')
                    : t('new_project_page.save_button')}
                </Button>
              </Tooltip>
            </Grid.Col>
          )}
          {!isSkillsStandardsStep && !finalTabInfo && (
            <Grid.Col xl='content' lg='content' md='content' sm='content' xs={12}>
              <Tooltip withArrow position='top' label={t('new_project_page.save_and_continue_button_tooltip')}>
                <Button
                  fullWidth={isMobile}
                  loading={buttonValues[1].loading}
                  disabled={buttonValues[1].disabled}
                  type='submit'
                  size='lg'
                  variant='filled'
                  onClick={() => {
                    formAction ? formAction('submitButton', 2) : null
                    setBlock(false)
                  }}
                >
                  {t('new_project_page.save_and_continue_button')}
                </Button>
              </Tooltip>
            </Grid.Col>
          )}

          {finalTabInfo && finalTabInfo?.status === 'draft' && schoolTermEndAt?.length && kind !== 'template' && (
            <Grid.Col xl='content' lg='content' md='content' sm='content' xs={12}>
              <Tooltip withArrow position='top' label={t('new_project_page.start')}>
                <Button
                  fullWidth={isMobile}
                  loading={buttonValues[1].loading}
                  disabled={buttonValues[1].disabled}
                  type='submit'
                  size='lg'
                  variant='filled'
                  onClick={onStartPratice}
                >
                  {t('new_project_page.start')}
                </Button>
              </Tooltip>
            </Grid.Col>
          )}

          {publishButton && (
            <Grid.Col xl='content' lg='content' md='content' sm='content' xs={12}>
              <Tooltip withArrow position='top' label={t('new_project_page.start_publish')}>
                <Button
                  fullWidth={isMobile}
                  loading={buttonValues[1].loading}
                  disabled={buttonValues[1].disabled}
                  type='submit'
                  size='lg'
                  variant='filled'
                  onClick={onPublishTemplate}
                >
                  {t('new_project_page.start_publish')}
                </Button>
              </Tooltip>
            </Grid.Col>
          )}

          {isSkillsStandardsStep && (
            <Grid.Col xl='content' lg='content' md='content' sm='content' xs={12}>
              <Tooltip withArrow position='top' label={t('new_project_page.review_added_button_tooltip')}>
                <Button
                  fullWidth={isMobile}
                  loading={buttonValues[1].loading}
                  disabled={buttonValues[1].disabled}
                  type='submit'
                  size='lg'
                  variant='filled'
                  onClick={() => reviewModalOpen()}
                >
                  {t('new_project_page.review_button')} ({countSelected()})
                </Button>
              </Tooltip>
            </Grid.Col>
          )}
        </Grid>
      </Box>
    </>
  )
}
