import React, { useState, useEffect, useRef, StrictMode } from 'react'
import { MultiSelect, Button, Loader, Flex, Group, Text, Badge, ActionIcon } from '@mantine/core'
import { getEvaluationTools, createEvaluationTool } from '../../service/projects'
import { useTranslation } from 'react-i18next'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import { debounce } from 'lodash'

interface EvaluationTool {
  id: number
  name: string
}

interface IEvaluation {
  setEvaluationToolsAttributes: (tools: number[]) => void
  initialValues?: EvaluationTool[]
  refToSelect?: React.RefObject<HTMLDivElement>
  openModalEvaluationTool: () => void
}

const EvaluationToolSelect = ({
  setEvaluationToolsAttributes,
  initialValues,
  refToSelect,
  openModalEvaluationTool,
}: IEvaluation) => {
  const [selectedTools, setSelectedTools] = useState<string[]>([])
  const [tools, setTools] = useState<EvaluationTool[]>([])
  const [toolsFromApi, setToolsFromApi] = useState<EvaluationTool[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    fetchTools()
  }, [])

  useEffect(() => {
    if (initialValues) {
      const initialSelectedTools = initialValues.map(tool => tool.name)
      setSelectedTools(initialSelectedTools)
    }
  }, [initialValues])

  useEffect(() => {
    const selectedToolsIds = toolsFromApi.filter(tool => selectedTools.includes(tool.name)).map(tool => tool.id)
    setEvaluationToolsAttributes(selectedToolsIds)
  }, [selectedTools, toolsFromApi])

  const fetchTools = async (query = '') => {
    setIsLoading(true)
    if (tools.length > 0) return tools
    try {
      const fetchedTools = await getEvaluationTools(query)
      setTools(fetchedTools || [])
    } catch (error) {
      console.error('Falha ao buscar ferramentas:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const debouncedFetchTools = useRef(debounce(query => fetchTools(query), 300)).current

  const handleSearchChange = (query: string) => {
    debouncedFetchTools(query)
    setSearchValue(query)
  }

  const handleChange = (value: string[] | null) => {
    setSelectedTools(value || [])
  }

  const handleCreateTool = async () => {
    if (!searchValue || selectedTools.includes(searchValue)) return
    setIsLoading(true)
    try {
      await createEvaluationTool(searchValue)
      returnToolsFromApi('').then(t => {
        setToolsFromApi(t)
      })
      setSelectedTools(prev => [...prev, searchValue])
      setSearchValue('')
    } catch (error) {
      console.error('Erro ao criar ferramenta:', error)
    } finally {
      refToSelect?.current?.focus()
      setIsLoading(false)
    }
  }

  const returnToolsFromApi = async (query: string) => {
    const fetchedTools = await getEvaluationTools(query)
    return fetchedTools || []
  }

  useEffect(() => {
    returnToolsFromApi('').then(t => {
      setToolsFromApi(t)
    })
  }, [])

  const nothingFoundJSX = () => {
    if (tools.length === 0 && searchValue.length < 3) return null
    if (isLoading) return <Loader size='xs' />
    return !tools.some(tool => tool.name === searchValue) && searchValue.length >= 3 ? (
      <Flex key='nothingFoundJSX'>
        <Badge size='lg' mr='xs'>
          {searchValue}
        </Badge>
        <Button size='xs' onClick={handleCreateTool}>
          {t('new_project_page.create')}
        </Button>
      </Flex>
    ) : null
  }

  return (
    <StrictMode>
      <Group spacing='md' mt='md'>
        <MultiSelect
          style={{ minWidth: '100%' }}
          label={
            <Group spacing='xs'>
              <Text>{t('new_project_page.evaluation_tools')}</Text>
              <ActionIcon onClick={openModalEvaluationTool}>
                <IconHelpCircleFilled />
              </ActionIcon>
            </Group>
          }
          value={selectedTools}
          size='md'
          clearable
          searchValue={searchValue}
          onChange={handleChange}
          onSearchChange={handleSearchChange}
          data={toolsFromApi.map(tool => tool.name)}
          placeholder={t('new_project_page.search_tool')}
          searchable
          nothingFound={nothingFoundJSX()}
          multiple
          rightSection={isLoading ? <Loader size='xs' /> : null}
        />
      </Group>
    </StrictMode>
  )
}

export default EvaluationToolSelect
