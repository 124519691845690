import { Skeleton, Grid } from 'components'
export const ProjectsSkeleton = () => {
  return (
    <Grid>
      {Array.from({ length: 3 }, (_, i) => (
        <Grid.Col span={12} md={4} key={i}>
          <Skeleton height={400} />
        </Grid.Col>
      ))}
    </Grid>
  )
}
