import Colors from "@legacy/common/Constants/Colors";
import styled from "styled-components"

export const ProjectSubheaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid ${Colors.primary.lightGray};
`

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`
