import { Breadcrumbs, Badge, Text, Box, BadgeCycle } from 'components'
import { IconChevronRight } from '@tabler/icons-react'
import { IBreadCrumbCard } from './interfaces'
import { useStyles } from './styles'
import { extractBreadcrumb } from 'helpers'

export const BreadCrumbCard = ({ showCyclesBadges, cycles, breadcrumb, color, fullBreadCrumb }: IBreadCrumbCard) => {
  const { classes } = useStyles({ color })

  return (
    <div>
      {showCyclesBadges && cycles &&
        cycles.length > 0 &&
        cycles.map((cycle, index) => (
          <Box key={index} sx={{ display: 'inline' }} mr='xs'>
            <BadgeCycle>{typeof cycle === 'string' ? (cycle as string) : cycle.name}</BadgeCycle>
          </Box>
        ))}
      <Breadcrumbs classNames={classes} separator={<IconChevronRight size='1rem' />}>
        <Badge variant='filled' className={classes.badge}>
          {extractBreadcrumb(breadcrumb, 0)}
        </Badge>
        <Text fw='bold' size='sm' className={classes.text}>
          {extractBreadcrumb(breadcrumb, 1)}
        </Text>
        <Text size='sm' className={classes.text}>
          {extractBreadcrumb(breadcrumb, 2)}
        </Text>
        {fullBreadCrumb && (
          <Text size='sm' className={classes.text}>
            {extractBreadcrumb(breadcrumb, 3)}
          </Text>
        )}
      </Breadcrumbs>
    </div>
  )
}
