import { IStudent } from 'pages/NewProject/context/ParticipantsContext'

type InputObj = {
  value: string
  label: string
  avatarMiddle?: string
  avatarSmall?: string
  email?: string
}
export interface ITeacher {
  id: string
  value?: string
  fullName?: string
  name?: string
  label?: string
  avatarSmall: string | null
  role: string
  email?: string
}

export function convertStudentArray(inputArray: InputObj[], selectedStudents: string[]): IStudent[] {
  return inputArray.map(item => {
    return {
      name: item.label,
      id: item.value,
      checked: selectedStudents.length > 0 ? selectedStudents.includes(item.value) : true,
      avatar: item.avatarMiddle || item.avatarSmall || '',
      email: '',
      role: '',
    }
  })
}

export function getCheckedStudents(students: IStudent[]): IStudent[] {
  return students.filter(student => student.checked === true)
}

export function convertTeacherArray(inputArray: any): any[] {
  return inputArray.map((item: any) => ({
    name: item?.fullName || item?.name || item.label,
    id: item.id || item.value,
    avatar: item.avatarMiddle || item.avatarSmall || '',
    checked: false,
    role: item.role,
    email: item.email || '',
  }))
}

type Attributes = {
  groupSchoolTermId: string
  schoolTermId: string
  mainTutorId: string
  studentsAttributes: string[]
  projectTeachersAttributes: string[]
}

export type StudentGroup = {
  group: string
  school_term: string
  main_teacher: string
  group_students: { id: string }[]
  other_students: { id: string }[]
  group_teachers: { id: string }[]
  other_teachers: { id: string }[]
  id: string
  submitButton: number
}

export const hasDataChanged = (studentGroup: StudentGroup, attributes: Attributes): boolean => {
  if (studentGroup.group !== attributes.groupSchoolTermId) {
    return true
  }

  if (studentGroup.school_term !== attributes.schoolTermId) {
    return true
  }

  if (
    studentGroup.main_teacher !== attributes.mainTutorId &&
    attributes.mainTutorId !== null &&
    attributes.mainTutorId.length !== 0
  ) {
    return true
  }

  let studentsIds = [
    ...studentGroup.group_students.map(student => student.id),
    ...studentGroup.other_students.map(student => student.id),
  ].filter(item => item !== undefined)

  studentsIds = studentsIds.filter(item => item !== undefined)

  if (studentsIds.length !== attributes.studentsAttributes.length) {
    return true
  }

  for (const id of studentsIds) {
    if (!attributes.studentsAttributes.includes(id)) {
      return true
    }
  }

  let teachersIds = [
    ...studentGroup.group_teachers.map(teacher => teacher.id),
    ...studentGroup.other_teachers.map(teacher => teacher.id),
  ]

  teachersIds = teachersIds.filter(item => item !== undefined)

  if (teachersIds.length !== attributes.projectTeachersAttributes.length) {
    return true
  }

  for (const id of teachersIds) {
    if (!attributes.projectTeachersAttributes.includes(id)) {
      return true
    }
  }

  if (studentGroup.main_teacher !== attributes.mainTutorId) {
    if (studentGroup.main_teacher === '' && attributes.mainTutorId === null) {
      return false
    }

    return true
  }

  return false
}
