import Tracker from '@openreplay/tracker'
import { IReceivedCurrentUser } from '@legacy/common/models/user'
import env from '../env.json'

export default function connectOpenReplayHook(currentUser: IReceivedCurrentUser | null | undefined): void {
  const dataEnv: any = env
  const token = dataEnv['process.env.REACT_APP_OPENREPLAY_TOKEN']
  const thereIsUser = currentUser !== undefined && currentUser !== null
  const thereIsToken = token !== undefined && token !== null && token !== ''

  if (thereIsToken && thereIsUser) {
    const tracker = new Tracker({
      projectKey: token.substring(1, token.length - 1),
      ingestPoint: 'https://openreplay.mosaicoed.com/ingest',
    })

    tracker.start()
    const userId = `${currentUser.id}` || 'no_id_in_the_backend'
    tracker.setUserID(userId)
    tracker.setMetadata('school', currentUser?.selected_school?.name ?? 'No school')
    tracker.setMetadata('profile', currentUser.profile_used || 'No profile')
    tracker.setMetadata('email', currentUser?.email ?? 'No email')
    tracker.setMetadata('env', currentUser.environment || 'No env')
    tracker.setMetadata('username', currentUser.username || 'No username')
    tracker.setMetadata('fullName', currentUser.full_name || 'No full name')
  }
}
