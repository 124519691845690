import { Skeleton, Stack } from 'components'
import { IListSkillsStandardsPage1Skeleton } from './interfaces'


export function ListSkillsStandardsPage1Skeleton({ length }: IListSkillsStandardsPage1Skeleton) {
  // Create a constant that repeats the skeleton component length times
  const skeleton = Array.from({ length }).map((_, index) => <Skeleton key={index} height={50}></Skeleton>)

  return <Stack spacing='sm'>{skeleton}</Stack>
}
