import AddCircle from '@legacy/common/Icons/AddCircle'
import { IButtonCircle } from './interfaces'
import { ContainerButtonStyled } from './styled'


const ButtonAddCircle = ({
  size = 24,
  disabled = false,
  onClick = () => {}
}: IButtonCircle) => (
  <ContainerButtonStyled onClick={onClick}>
    <AddCircle width={size} disabled={disabled} />
  </ContainerButtonStyled>
)

export default ButtonAddCircle
