import { useContext, useEffect, useState } from 'react'
import { Paper, Title, SimpleGrid, Group, Button, Text, CardAvatarAction } from 'components'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { useCurrentUser } from 'context'
import { ParticipantsContext } from '../../context/ParticipantsContext'
import { IconPlus } from '@tabler/icons-react'
import { OtherTeachersModal } from '../OtherTeachersModal'
import { getTutors } from 'pages/NewProject/service/projects'
import { convertTeacherArray } from '../ProjectParticipants/helper'
import { isEmpty, filter, uniqBy } from 'lodash'

interface IOtherTeachersBlock {
  readonly termId: string
  readonly groupId: string
}

interface Item {
  id: string
  fullName: string
  avatarSmall: string | null
  role: string
}

function removeItemsWithDuplicateIds(arr: Item[]): Item[] {
  const uniqueItems = uniqBy(arr, 'id')
  return filter(arr, item => uniqueItems.includes(item))
}

export const OtherTeachersBlock = ({ groupId }: IOtherTeachersBlock) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [loading, setLoading] = useState(false)
  const { currentUser } = useCurrentUser()
  const currentTutorId = currentUser?.profile_url?.split('/')[2]

  const {
    openTeachersModal,
    selectedOtherTeachers,
    otherTeachersHandler,
    handleRemoveTeacher,
    teachersModalOpened,
    otherTeachers,
    tutorGuest,
  } = useContext(ParticipantsContext)

  useEffect(() => {
    if (!isEmpty(groupId) && teachersModalOpened) {
      if (otherTeachers.length > 0) return

      setLoading(true)
      getTutors(groupId).then(res => {
        const removeDuplicates = removeItemsWithDuplicateIds(res)
        otherTeachersHandler.setState(convertTeacherArray(removeDuplicates))
        setLoading(false)
      })
    }
  }, [groupId, teachersModalOpened])

  return (
    <>
      <OtherTeachersModal loading={loading} />
      <Paper shadow='xs' withBorder radius='md' px={isMobile ? 'sm' : 'md'} py='sm'>
        <Group position='apart'>
          <Title order={5}>
            {t('new_project_page.other_teachers')}{' '}
            <Text span color='dimmed'>
              ({selectedOtherTeachers.length})
            </Text>
          </Title>
          <Button onClick={() => openTeachersModal()} leftIcon={<IconPlus />} size='sm' variant='light'>
            {t('new_project_page.add_other_teachers')}
          </Button>
        </Group>
        {selectedOtherTeachers.length > 0 && (
          <SimpleGrid
            mt='sm'
            breakpoints={[
              { minWidth: 'xl', cols: 3, spacing: 'md' },
              { minWidth: 'lg', cols: 3, spacing: 'md' },
              { minWidth: 'md', cols: 2, spacing: 'sm' },
              { minWidth: 'sm', cols: 1, spacing: 'sm' },
              { minWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
          >
            {selectedOtherTeachers.map((otherTeacher, index) => (
              <CardAvatarAction
                key={otherTeacher.id}
                avatar={otherTeacher.avatar}
                name={otherTeacher.name}
                role={otherTeacher.role}
                showRemove={otherTeacher.id !== currentTutorId}
                onClick={() => handleRemoveTeacher(index)}
              />
            ))}
          </SimpleGrid>
        )}
      </Paper>
    </>
  )
}
