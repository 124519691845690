import { Loader } from './CircularLoaderStyled'

export interface ICircularLoaderprops {
  readonly size: number
  readonly color: string
  readonly thickness: number
  readonly time: number
}

const CircularLoader = ({
  size = 15,
  color = '#d3d3d3',
  thickness = 2,
  time = 1,
}: ICircularLoaderprops) => {
  return <Loader size={size} color={color} thickness={thickness} time={time} />
}

export default CircularLoader
