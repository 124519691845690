export type sizeType =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'

export interface IAvatar {
  readonly name: string
  readonly image: string | null
  readonly size: sizeType
}

export const avatarType = {
  xxsmall: 24,
  xsmall: 32,
  small: 48,
  medium: 64,
  large: 80,
  xlarge: 144,
}

export const avatarFont = {
  xxsmall: 10,
  xsmall: 10,
  small: 16,
  medium: 20,
  large: 24,
  xlarge: 28,
}
