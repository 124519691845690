import { useTranslation } from 'react-i18next'
import { Badge } from '../../../Badge'
import { Grid } from '../../../Grid'
import { Text } from '../../../Text'
import { useMantineTheme } from '@mantine/core'

export interface CardDateTumeProps {
  readonly date?: string
  readonly time?: string
}

export const CardDateTime = ({ date, time }: CardDateTumeProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()

  return (
    <Grid justify='center' align='center' mt='md' gutter={0}>
      <Grid.Col span={6}>
        <Text size='sm' color='dimmed'>
          {t('students_date')}
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Text size='sm' color='dimmed'>
          {t('students_hour')}
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Badge size='lg' color={`${theme.colorScheme === 'dark' ? 'gray' : 'gray.7'}`}>
          {date || '-'}
        </Badge>
      </Grid.Col>
      <Grid.Col span={6}>
        <Badge size='lg' color={`${theme.colorScheme === 'dark' ? 'gray' : 'gray.7'}`}>
          {time || '-'}
        </Badge>
      </Grid.Col>
    </Grid>
  )
}
