export interface IProjectFilterModalProps {
  opened: boolean
  onClose: () => void
}

export interface IProjectFilterFormValues {
  schoolTerm: string | null
  finalProduct: string[]
  cycle: string | null
  group: string | null
}
export interface IOldGroupSchoolTerm {
  id: string
  name: string
  cycle: {
    id: string
    name: string
  }
  curriculums: {
    skill: {
      id: string
    }
    content: {
      id: string
    }
  }
}
export interface IOldSchoolTerm {
  id: string
  name: string
  projectsCount: number
  groupSchoolTerms: IOldGroupSchoolTerm[]
}

export const initialValues = {
  schoolTerm: null,
  cycle: null,
  group: null,
  finalProduct: [],
}
