import styled from 'styled-components'

export const HeaderInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 42px 24px 0;
  border-bottom: 2px solid #d3d2d7;
  width: 100%;
  position: sticky;
  top: -16px;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;

  h2,
  h3,
  h4 {
    font-weight: 700;
    padding: 0;
    margin: 0;
    color: var(--color-black);
  }

  h2 {
    font-size: 1.75rem;
  }
`
export const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 16px;
  right: 0px;
  z-index: 10000;
  padding-top: 8px;
  cursor: pointer;
`
