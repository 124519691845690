import styled, { css } from 'styled-components'

export const Section = styled.div<{ show: boolean }>`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 1s;

  .validation-error {
    position: relative;
  }

  ${props =>
    props.show &&
    css`
      max-height: 100%;
      opacity: 1;
      margin-bottom: 47px;
      min-height: fit-content;
    `}
`
export const TaskInfo = styled.div`
  margin-top: 48px;
  padding: 46px 0 0;
  border-top: 1px solid var(--color-gray-light);
  display: flex;
  flex-direction: column;

  h2,
  h3,
  h4,
  h5 {
    margin: 0;
    padding: 0;
  }

  h2 {
    margin-bottom: 17px;
  }

  h3 {
    margin-bottom: 24px;
    font-size: 1rem !important;
  }

  h4 {
    font-size: 1rem;
  }

  p {
    color: #000000;
  }
`
export const Unavailable = styled.div`
  display: none;
`
export const StudentTaskError = styled.p`
  color: #f57e17 !important;
`

export const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 34px;
  color: #121019;
  margin-right: 22px;
`

export const SubTitle = styled.h3``

export const Details = styled.div`
  display: block;
  padding-top: 40px;
  border-top: 1px solid var(--color-gray-light);
  margin-top: 16px;

  span,
  p,
  h1,
  h2 {
    //para desabilitar os estilos adicionados pelo editor de texto rico
    background: transparent !important;
  }
`

export const GridDetails = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 16px;
  align-items: baseline;
`

export const DetailItem = styled.div<{ overdue?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => (props.overdue ? 'var(--color-red)' : '#000000')};

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 19px;
    color: #625f6d;
  }

  h4 {
    line-height: 19px;
    color: ${props => (props.overdue ? 'var(--color-red)' : '#000000')};
    margin: 0;
    padding: 0;
  }
`
export const HeadTask = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
