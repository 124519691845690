import { useTranslation } from 'react-i18next'
import Parser from 'html-react-parser'
import { IProjectObjectives } from './interfaces'
import { ObjectivesContainer } from './styled'

export const ProjectObjectives = ({objectives}: IProjectObjectives) => {
  const { t } = useTranslation()
  
  if (!objectives) return <></>

  return (
    <ObjectivesContainer>
      <h2>{t('final_assessments_index_objectives')}</h2>
      <div className='rich-text'>
        {Parser(objectives)}
      </div>
    </ObjectivesContainer>
  )
}