/* global ReactImages */
// RESOLVER REACTIMAGES
import React from 'react'
import classNames from 'classnames'

interface IBlankSlate {
  readonly message: string
  readonly className?: string
  readonly children?: never
}

const BlankSlate = ({ message, className }: IBlankSlate) => (
  <div className={classNames('blank-slate-container', className)}>
    <div className="item-description">
      {/* <img src={ReactImages.blank_slate_circle} alt="" /><br /> */}
      {message}
    </div>
  </div>
)

export default BlankSlate
