import { ILessonCardActivity } from './ILessonCardActivity'

enum enumStatus {
  started = 'started',
  not_started = 'not_started',
}
export interface ILessonProps {
  readonly hasAssessmentActivity: boolean
  readonly hasDeliverableActivity: boolean
  readonly projectName: string
  readonly lessonName: string
  readonly date: string
  readonly hour: string
  readonly timeStart: string
  readonly timeEnd: string
  readonly canFillEditReport: boolean
  readonly editReportUrl: string
  readonly activities: ILessonCardActivity[] & { id: string; title: string }[]
  readonly status: enumStatus
  readonly projectUrl: string
  readonly loadDashBoardData: () => void
}
