import { useEffect } from 'react'
import HeaderInfo from 'components/HeaderInfo/HeaderInfo'
import { isEmpty } from 'lodash'
import {
  WrapperModal,
  TitleJournal,
  TitleFile,
  ContentContainer,
  TitleIndividualAssessment,
  LoaderContainer,
} from './StudentModalContentStyled'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IStudentAsssessments, IAssessmentScoresAndJournal } from './StudentModalContentTypes'
import TextAreaLumiar from 'components/TextAreaLumiar/TextAreaLumiar'
import EvaluativeItem from './EvaluativeItem'
import FileUpload from '@legacy/common/_components/FileUpload'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import service from '../StudentsAssessments/assessmentService'
import { notifications } from 'context'
import ErrorService from '@legacy/common/Tools/ErrorService'
import useCurrentUser from '@legacy/common/hooks/current-user-hook'
import { useAssessmentScoreListHook } from 'common/hooks/useAssessmentScoreListHook'
import { Button, Group, Paper, Box, Loader, Stack, Title, AssessmentInfo } from 'components'
import { IconMoodCog, IconBooks, IconCheck, IconX } from '@tabler/icons-react'
import { useDisclosure } from 'hooks'

const StudentModalContent = ({
  sessionMeetingSetState,
  sessionMeetingState,
  meetingAssessmentPayload,
  hasChangesNotSavedOnMeetingAssessment,
  hasChanges,
  goNext,
  mixPanelTracker,
  saveAndGoNext,
  setHasChanges,
  setStudents,
  setSelectedStudent,
  handleCloseDrawer,
  loadingDrawer,
  selectedStudent,
  sessionMeetingId,
}: IStudentAsssessments) => {
  if (!selectedStudent) return <></>

  const {
    contentsAssessmentScores: contentsAssessments,
    fullName,
    id,
    assessmentId: assessment_id,
    imageUrl,
    masterJournal: masterjustification,
    masterJournalId: journalId,
    specificSkillAssessmentScores: skills,
    studentJournal: studentJournal,
  } = selectedStudent

  const initialData = {
    assessment_id,
    student_id: id,
    assessment_scores: [],
    journal: { id: journalId, description: masterjustification },
  }

  const { t } = useTranslation()
  const currentUser = useCurrentUser()
  const [data, setData] = useState<IAssessmentScoresAndJournal>(initialData)
  const [loadingNext, setLoadingNext] = useState<boolean>(false)
  const { scrollRef, contentAssessmentScores, skillAssessmentScores, loadingAssess } = useAssessmentScoreListHook(
    id,
    sessionMeetingId,
    'assessment',
  )
  const [saveAndCloseLoading, setSaveAndCloseLoading] = useState(false)
  const [saveAndNextLoading, setSaveAndNextLoading] = useState(false)

  const updateJournal = (description: string) => {
    setHasChanges(true)
    setData({ ...data, journal: { ...data.journal, description } })

    setSelectedStudent((student: any) => {
      return { ...student, masterJournal: description }
    })
  }

  const onSave = async (kind: 'go-next' | 'save-close') => {
    try {
      if (hasChanges) {
        kind === 'go-next' ? setSaveAndNextLoading(true) : setSaveAndCloseLoading(true)
        const payload = {
          ...data,
          student_id: id,
          assessment_id,
          journal: { ...data.journal, id: journalId },
          evaluator_user_id: currentUser.data?.id,
        }
        await service.patchScoresAndJournal(payload)
        const updatedStudents = await service.getStudents(sessionMeetingState.session_meeting.id)
        setStudents(updatedStudents)
        setHasChanges(false)
        setData(initialData)
      }

      if (hasChangesNotSavedOnMeetingAssessment) {
        await service.updateSessionMeeting(sessionMeetingState.session_meeting.id, meetingAssessmentPayload)
      }
      mixPanelTracker('Salvar avaliação')
      const { loading } = sessionMeetingState
      sessionMeetingSetState({
        hasChangesNotSaved: false,
        loading: { ...loading, saveButton: false },
      })
      setSaveAndNextLoading(false)
      setSaveAndCloseLoading(false)
      notifications.show({
        message: t('save_partial_assessment'),
        color: 'green',
        icon: <IconCheck size={24} />,
      })

      if (kind == 'go-next') {
        setLoadingNext(true)
        saveAndGoNext()
        setTimeout(() => {
          setLoadingNext(false)
          scrollTop()
        }, 1000)
        return
      }
    } catch (e) {
      notifications.show({
        message: t('error_on_save_assessment_informations'),
        color: 'red',
        icon: <IconX size={24} />,
      })
      ErrorService.notice(e)
    }

    handleCloseDrawer(false, true)
    document.body.style.overflow = 'scroll'
  }

  const scrollTop = () => {
    const rodal = document.getElementsByClassName('rodal-dialog')
    rodal[0].scrollTo(0, 0)
  }

  const Loading = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <Loader />
    </div>
  )

  useEffect(() => {
    if (hasChanges) {
      mixPanelTracker('Preencher anotações de avaliação')
    }
  }, [hasChanges])

  if (loadingDrawer) {
    return (
      <WrapperModal id={id}>
        <Box mb='lg'>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </Box>
      </WrapperModal>
    )
  }

  return (
    <WrapperModal id={id} ref={scrollRef}>
      <HeaderInfo
        title={t('students_assessment_partial_title')}
        userName={fullName}
        userImage={imageUrl}
        closeFunction={() => {
          handleCloseDrawer(false, false)
          document.body.style.overflow = 'scroll'
        }}
        active={!loadingNext}
      />

      {!isEmpty(studentJournal) && (
        <TitleIndividualAssessment>
          {t('students_assessment_individual_report', { fullName: fullName })}
        </TitleIndividualAssessment>
      )}

      {!isEmpty(studentJournal) && <span>{studentJournal}</span>}

      <ContentContainer active={!loadingNext}>
        {!isEmpty(contentAssessmentScores?.result) ? (
          <Stack spacing='md' mt='md'>
            <Group spacing={4}>
              <IconBooks size='1.5rem' />
              <Title order={3}>{t('students_assessment_standart')}</Title>
            </Group>
            {contentAssessmentScores?.result.map((item: any) => (
              <Paper p='md' shadow='md' withBorder key={item.id}>
                <Stack spacing='md'>
                  <EvaluativeItem
                    setHasChanges={setHasChanges}
                    item={item}
                    setData={setData}
                    mixPanelTracker={mixPanelTracker}
                    setSelectedStudent={setSelectedStudent}
                  />

                  {item.studentScore !== 0 && (
                    <AssessmentInfo
                      title={t('students_partial_self_assessment', { fullName: fullName })}
                      score={item.studentScore}
                      rubrics={item.rubrics}
                    />
                  )}
                </Stack>
              </Paper>
            ))}
          </Stack>
        ) : null}

        {!isEmpty(skillAssessmentScores?.result) ? (
          <Stack spacing='md' mt='lg'>
            <Group spacing={4}>
              <IconMoodCog size='1.5rem' />
              <Title order={3}>{t('students_assessment_skills')}</Title>
            </Group>
            {skillAssessmentScores?.result.map((item: any) => (
              <Paper p='md' shadow='md' withBorder key={item.id}>
                <Stack spacing='md'>
                  <EvaluativeItem
                    setHasChanges={setHasChanges}
                    item={item}
                    setData={setData}
                    mixPanelTracker={mixPanelTracker}
                    setSelectedStudent={setSelectedStudent}
                  />
                  {item.studentScore !== 0 && (
                    <AssessmentInfo
                      title={t('students_partial_self_assessment', { fullName: fullName })}
                      score={item.studentScore}
                      rubrics={item.rubrics}
                    />
                  )}
                </Stack>
              </Paper>
            ))}
          </Stack>
        ) : null}

        {loadingAssess && <Loading />}

        {skillAssessmentScores?.pagination.isLastPage && (
          <div>
            <TitleJournal extraspace={true}>{t('students_assessment_notes')}</TitleJournal>

            <TextAreaLumiar
              value={masterjustification}
              placeholder={t('students_assessment_placeholder')}
              onChange={updateJournal}
              key={journalId}
            />

            <TitleFile extraspace={false}>{t('students_assessment_files')}</TitleFile>

            <div className='fileContainer' key={assessment_id}>
              <FileUpload
                uploadUrl={`/assessments/${assessment_id}/assessment_file_items`}
                fetchFilesUrl={`assessments/${assessment_id}/assessment_file_items.json`}
                deleteFilesUrl={`/assessments/${assessment_id}/assessment_file_items`}
                typeFile='file'
                fileUploadId='student'
              />
            </div>
            <Box mt='sm'>
              <GDriveWrapper
                key={assessment_id + 'gdrive'}
                value={{
                  resourceId: assessment_id,
                  resourceClass: 'assessment_id',
                }}
              />
            </Box>
          </div>
        )}

        <Group spacing='md' position='right'>
          <Button
            size='lg'
            onClick={() => onSave('save-close')}
            variant={goNext ? 'default' : 'filled'}
            loading={saveAndCloseLoading}
            disabled={saveAndNextLoading}
          >
            {t('students_assessment_save_close')}
          </Button>
          {goNext && (
            <Button
              variant='filled'
              size='lg'
              onClick={() => onSave('go-next')}
              loading={saveAndNextLoading}
              disabled={saveAndCloseLoading}
            >
              {!hasChanges ? t('students_assessment_go_next') : t('students_assessment_save_go_next')}
            </Button>
          )}
        </Group>
      </ContentContainer>
    </WrapperModal>
  )
}

export default StudentModalContent
