import { ActionButton } from '../ActionButton/ActionButton'
import { useMediaQuery } from '@mantine/hooks'
import { getInitials } from '../../helpers'
import { IStudent } from '../../pages/Students/types/student-types'
import { Avatar, BadgeCycle } from 'components'
import { useMantineTheme } from '@mantine/core'
import { Text } from '../../components/Text'
import { Group } from '../../components/Group'
import { Card } from '../../components/Card'
import { Flex } from '../../components/Flex'

export const ManageStudentCard = ({ student }: { student: IStudent }) => {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const theme = useMantineTheme()
  const anchorColor = theme.colorScheme === 'dark' ? '#fff' : '#000'

  return !student ? null : (
    <Card p='sm' mb={10}>
      <Flex justify='space-between' direction={isMobile ? 'column' : 'row'}>
        <Group spacing='xs' mb={isMobile ? '24px' : 0}>
          <Avatar src={student.photoUrl} alt={student.name} size='md' radius='xl' variant='filled' color='gray'>
            {getInitials(student.name)}
          </Avatar>
          <Text size='md'>{student.name}</Text>
          {student.cycleName && <BadgeCycle>{student.cycleName}</BadgeCycle>}
        </Group>
        <ActionButton anchorColor={anchorColor} actions={student.actions} isMobile={isMobile} />
      </Flex>
    </Card>
  )
}
