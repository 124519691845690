import { api } from '../../helpers'

export interface Activity {
  id: string
  title: string
  planning: string
  hasPlanningFiles: boolean
  planningFiles: any[]
  specificSkillsAndContents: SkillAndContent[]
  hasAssessments: boolean
  studentTask: {
    id: string
    title: string
    description: string
    date: string
    planningFiles: any[]
  }
  kind: string
  sortPosition: number
}

export interface SkillAndContent {
  color: string
  name: string
  lv1Name: string
  originalCodes: string
  cycles: any[]
  id: string
  curriculumKind: string
}

export interface MeetingResponseObject {
  readonly id: string
  readonly title: string
  readonly journal: null
  readonly date: string
  readonly showHour: string
  readonly nextMeeting: string
  readonly previousMeeting: string
  readonly projectName: string
  readonly meetingsCount: number
  readonly mainTutorId: string
  readonly tutors: ReadonlyArray<{
    readonly id: string
    readonly fullName: string
    readonly name: string
    readonly imageUrl: string
    readonly avatar: string
    readonly link: string
    readonly educatorRole: string
  }>
  readonly assessmentId: string
  readonly journalStudentId: string
  readonly journalStudent: string
  readonly skillsAssessmentScores: ReadonlyArray<{
    readonly id: string
    readonly studentScore: number
    readonly assessmentInstrument: null
    readonly rubrics: {
      readonly [key: string]: string
    }
    readonly name: string
    readonly originalCodes: string
    readonly cycles: ReadonlyArray<unknown>
    readonly lv1Name: string
    readonly color: string
    readonly curriculumKind: string
  }>
  readonly contentsAssessmentScores: ReadonlyArray<{
    readonly id: string
    readonly studentScore: number
    readonly assessmentInstrument: null
    readonly rubrics: {
      readonly [key: string]: string
    }
    readonly name: string
    readonly originalCodes: string
    readonly cycles: ReadonlyArray<unknown>
    readonly lv1Name: string
    readonly color: string
    readonly curriculumKind: string
  }>
  readonly moods: {
    readonly moodMasterEvaluationByStudent: number
    readonly moodStudentDeveloped: number
    readonly moodStudentLiked: number
    readonly moodStudentTookPart: number
    readonly moodGroupDeveloped: number
  }
  readonly sessionMeetingActivities: ReadonlyArray<{
    readonly id: string
    readonly title: string
    readonly planning: string
    readonly kind: string
    readonly hasAssessments: boolean
    readonly hasPlanningFiles: boolean
    readonly planningFiles: ReadonlyArray<any>
    readonly specificSkillsAndContents: ReadonlyArray<{
      readonly id: string
      readonly name: string
      readonly originalCodes: string
      readonly cycles: ReadonlyArray<any>
      readonly lv1Name: string
      readonly color: string
      readonly curriculumKind: string
    }>
    readonly studentTask: {
      readonly id: string
      readonly title: string
      readonly description: string
      readonly date: string
      readonly planningFiles: ReadonlyArray<any>
    }
  }>
}

export const getMeeting = async (id: string): Promise<MeetingResponseObject> => {
  try {
    const response = await api.get<MeetingResponseObject>(`/api/students/my_assessments/${id}`)
    return response.data
  } catch (error) {
    return Promise.reject('Error getting students list')
  }
}

export const updateReportStudent = async (payload: any): Promise<any> => {
  try {
    const response = await api.patch<any>('/api/students/update_report_student', payload)
    return response.status
  } catch (error) {
    return Promise.reject('Error getting students list')
  }
}
