import { Navigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useAuth } from 'context/userAuthContext'
import { USerKind, IProfiles } from 'routes'
import { useEffect, useState } from 'react'
import { getCurrentUser } from '@legacy/common/services/users-service'
import { newProject } from 'pages/NewProject/service/projects'

type ExtraProtectionType = 'new-project'

export const ProtectedRoute = ({
  children,
  enabledProfiles,
  extraProtection,
}: {
  children: React.ReactElement
  enabledProfiles: USerKind
  extraProtection?: ExtraProtectionType
}) => {
  const { user } = useAuth()
  const [areTestsCompleted, setAreTestsCompleted] = useState(false)

  if (!user) {
    Cookies.set('url_to_redirect', window.location.href)
    return <Navigate to='/account/signin' />
  }

  const getUser = async () => {
    const data = await getCurrentUser()

    if (!data || !data.data) {
      localStorage.removeItem('current_user')
      window.location.href = '/'
    }

    if (!enabledProfiles.includes(data.data.profile_used as IProfiles)) {
      window.location.href = '/error_permission'
      return
    }
    setAreTestsCompleted(true)
  }

  const handleExtraProtection = async () => {
    switch (extraProtection) {
      case 'new-project':
        await newProject()
        break
      default:
        break
    }
    getUser()
  }

  useEffect(() => {
    if (extraProtection) {
      handleExtraProtection()
    } else {
      getUser()
    }
  }, [extraProtection])

  return areTestsCompleted ? children : null
}
