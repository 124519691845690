import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ProjectSubheader } from '@legacy/common/Project/_components/ProjectSubheader'
import Paragraph from '../_atoms/Paragraph'

const SessionMeetingHeader = props => {
  const { t } = useTranslation()
  const {
    projectId,
    projectName,
    previousMeeting,
    nextMeeting,
    date,
    title,
    description,
    groupSchoolTermName,
    meetingsCount,
    tutors,
  } = props
  const baseUrl = 'projects/lessons' 

  const projectSubheaderProp = {
    name: projectName,
    group_school_term_name: groupSchoolTermName,
    sessions_count: meetingsCount,
    tutors: tutors
  }

  return (
    <Fragment>
      <div className="project-header d-flex">
        <div className="project-header-title border-bottom pb-5">
          <a href={`/projects/${projectId}`} className="new-buttons new-buttons-clear mb-5">
            <i className="icon-common-arrow-left" />
            {t('projects_back')}
          </a>
          <div className="d-flex">
            <a
              className={`new-buttons new-buttons-light new-buttons-icon ${
                previousMeeting ? '' : 'disabled'
              }`}
              href={previousMeeting ? `/${baseUrl}/${previousMeeting}/edit_report` : '#'}
            >
              <i className="icon-common-arrow-left" />
            </a>
            <h2 className="py-2 px-4">
              {date} - {title}
            </h2>
            <a
              className={`new-buttons new-buttons-light new-buttons-icon ${
                nextMeeting ? '' : 'disabled'
              }`}
              href={nextMeeting ? `/${baseUrl}/${nextMeeting}/edit_report` : '#'}
            >
              <i className="icon-common-arrow-right ml-1" />
            </a>
          </div>
        </div>
      </div>

      <div className="border-bottom">
        <ProjectSubheader project={projectSubheaderProp} />
      </div>

      {description && (
        <div className="py-5 border-bottom">
          <h2>{t('meetings_report_meetingDescription')}</h2>
          <div className="rich-text">
            <Paragraph text={description} />
          </div>
        </div>
      )}
    </Fragment>
  )
}

SessionMeetingHeader.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectName: PropTypes.string.isRequired,
  projectStatus: PropTypes.string.isRequired,
  previousMeeting: PropTypes.string,
  nextMeeting: PropTypes.string,
  date: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  groupSchoolTermName: PropTypes.string.isRequired,
  meetingsCount: PropTypes.number.isRequired,
  tutors: PropTypes.arrayOf(PropTypes.object),
  masters: PropTypes.arrayOf(PropTypes.object.isRequired),
  mainTutorId: PropTypes.string,
  mainMasterId: PropTypes.string,
}

SessionMeetingHeader.defaultProps = {
  title: '',
  description: '',
  previousMeeting: undefined,
  nextMeeting: undefined,
  tutors: [],
  masters: [],
  mainTutorId: undefined,
  mainMasterId: undefined,
}

export default SessionMeetingHeader
