import { useContext } from 'react'
import { FinalProjectReportContext } from '../../context/FinalProjectReportContext'
import { Title, Stack } from 'components'
import { useTranslation } from 'react-i18next'
import { AssessmentCard } from '../AssessmentCard'

export const StudentAssessmentsReport = () => {
  const { t } = useTranslation()
  const { studentAssessments } = useContext(FinalProjectReportContext)

  if(!studentAssessments) return null
  return (
    <Stack spacing='md'>
      <Stack spacing='sm'>
        <Title order={4}>{t('new_final_project_report.title_skills_assessed')}</Title>
        {studentAssessments.finalAssessmentsSkills &&
          studentAssessments.finalAssessmentsSkills.length > 0 &&
          studentAssessments.finalAssessmentsSkills.map((assessment, index) => (
            <AssessmentCard
              key={index}
              breadcrumb={assessment.breadcrumb}
              color={assessment.color}
              assessments={assessment.assessments}
              disabled={assessment.doNotAssess}
            />
          ))}
      </Stack>
      <Stack spacing='sm'>
        <Title order={4}>{t('new_final_project_report.title_standards_assessed')}</Title>
        {studentAssessments.finalAssessmentsContent &&
          studentAssessments.finalAssessmentsContent.length > 0 &&
          studentAssessments.finalAssessmentsContent.map((assessment, index) => (
            <AssessmentCard
              key={index}
              breadcrumb={assessment.breadcrumb}
              color={assessment.color}
              assessments={assessment.assessments}
              disabled={assessment.doNotAssess}
            />
          ))}
      </Stack>
    </Stack>
  )
}
