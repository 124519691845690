import { useContext } from 'react'
import { TextAreaStyled, ValidationTextStyled } from 'components/TextAreaLumiar/TextAreaStyled'
import { ITextAreaLumiar } from 'components/TextAreaLumiar/TextAreaTypes'
import { Text, Group, Box } from 'components'
import { FeedDrawerContext } from 'pages/Feed/contexts/FeedDrawerContext'

export const FeedTextArea = ({ value, placeholder, onChange, error = false, validationText }: ITextAreaLumiar) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  const { textAreaValue, descriptionsize } = useContext(FeedDrawerContext)

  return (
    <>
      <TextAreaStyled valid={!error} placeholder={placeholder} onChange={handleChange} value={value} />
      <Group position='apart'>
        <Box>{!error ? <></> : <ValidationTextStyled>{validationText}</ValidationTextStyled>}</Box>
        <Text>{descriptionsize || textAreaValue.length}/300</Text>
      </Group>
    </>
  )
}
