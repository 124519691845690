import { memo } from 'react'
import { Grid, ScoreInfo, UserInfo } from 'components'
import { AssessmentItem } from '../../../interfaces'


interface AssessmentCardItemProps {
  assessments: AssessmentItem[]
  assessment: AssessmentItem
  index: number
}

const AssessmentCardItem = memo(({assessments, assessment, index}: AssessmentCardItemProps) => {
  return (
    <Grid
      justify='space-between'
      align='center'
      pb={assessments.length > 1 && index < assessments.length - 1 ? 'sm' : 0}
    >
      <Grid.Col span={12} sm='auto'>
        <ScoreInfo score={assessment.score} rubric={assessment.rubric} />
      </Grid.Col>
      <Grid.Col span='content'>
        <UserInfo userName={assessment.evaluatorUser.fullName} image={assessment.evaluatorUser.avatar || ''} />
      </Grid.Col>
    </Grid>
  )
})

AssessmentCardItem.displayName = 'AssessmentCardItem'

export { AssessmentCardItem }
