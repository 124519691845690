import { useTranslation } from "react-i18next"
import { IProjectJournalProps } from "./interfaces"
import { ProjectJournalContainer, ProjectJournalTextArea } from "./styled"

export const ProjectJournal = ({journal, updateJournal}: IProjectJournalProps) => {
  const { t } = useTranslation()
  return (
    <ProjectJournalContainer>
      <ProjectJournalTextArea
        id='input-motivating-interests'
        value={journal}
        placeholder={t('journals_group_title')}
        onChange={(event: { target: { value: string } }) => {
          updateJournal(event.target.value)
        }}
        errors={[]}
      />
    </ProjectJournalContainer>
  )
}