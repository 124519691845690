import { useContext, useEffect, useRef, useState } from 'react'
import Parser from 'html-react-parser'
import { useTranslation } from 'react-i18next'
import { hasPermission } from 'hooks'
import { getLessons } from 'api'
import { useMixPanel } from 'hooks'
import { ProjectViewContext } from '../../context/ProjectViewContext'
import { useCurrentUser } from 'context'
import {
  Title,
  Text,
  Group,
  Badge,
  Button,
  Divider,
  Stack,
  BreadCrumbCard,
  LessonsProvider,
  LessonsList,
  Tooltip,
  EmptyStateBox,
  Flex,
} from 'components'
import { IconArticleOff, IconPlayerPlayFilled } from '@tabler/icons-react'
import { IEvaluationTool, IPagination } from 'interfaces'

export const ProjectViewContent = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const observerRef = useRef(null)

  const {
    project,
    lessons,
    finalAssessmentStarted,
    startFinalAssessmentModal,
    getFinalAssessmentURL,
    setLessons,
    setSchoolTermEndAt,
    setSchoolTermStartAt,
    schoolTermStartAt,
    schoolTermEndAt,
    lessonsLoading,
    finalReportURL,
    setLessonsPagination,
  } = useContext(ProjectViewContext)

  const [pagination, setPagination] = useState<IPagination>({} as IPagination)
  const [page, setPage] = useState<number>(1)

  const mixpanel = useMixPanel()

  const extractBreadcrumb = (breadcrumb: string, position: number): string => {
    if (!breadcrumb) return ''

    const [firstBreadcrumb = '', secondBreadcrumb = ''] = breadcrumb.split('>')
    return [firstBreadcrumb.trim(), secondBreadcrumb.trim()][position]
  }

  const getActivitiesforFirstPageAndResetPagetoOne = () => {
    setLessons([])
    setPage(1)

    const id = project?.id
    if (!id) return null

    getLessons(id, 1).then(({ data }) => {
      setLessons(data.sessionMeetings)
      setSchoolTermEndAt(data.schoolTermEndAt)
      setSchoolTermStartAt(data.schoolTermStartAt)
      setPagination(data.pagination)
      setLessonsPagination(data.pagination)
    })
  }

  useEffect(() => {
    getActivitiesforFirstPageAndResetPagetoOne()
  }, [])

  const incrementPage = () => {
    setPage(prevPage => prevPage + 1)
  }

  const [isLoading, setIsLoading] = useState(false)

  const loadMoreItems = () => {
    if (isLoading || !project?.id || (pagination && page >= pagination.pages + 1) || !pagination || !pagination.pages) {
      return
    }

    setIsLoading(true)
    getLessons(project.id, page)
      .then(({ data }) => {
        if (page <= data?.pagination?.pages) {
          const uniqueLessons = [...lessons, ...data.sessionMeetings].reduce((acc, lesson) => {
            acc[lesson.id] = lesson
            return acc
          }, {})

          setLessons(Object.values(uniqueLessons))
        } else {
          setIsLoading(false)
          incrementPage()
          return
        }

        incrementPage()
        setPagination(data.pagination)
        setLessonsPagination(data.pagination)
        setIsLoading(false)
      })
      .catch(error => {
        setIsLoading(false)
        console.error(error)
      })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && !isLoading) {
          loadMoreItems()
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    )

    if (observerRef.current) {
      observer.observe(observerRef.current)
    }

    return () => observer.disconnect()
  }, [loadMoreItems])

  if (project === null) return null

  const trimHTML = (html: string) => {
    if (typeof html === 'string' && html.replace) {
      return html?.replace(/<\/?[^>]+(>|$)/g, '')
    }
    return ''
  }

  return (
    <>
      <Stack spacing='md'>
        {project.purpose && trimHTML(project.purpose) !== '' && (
          <Stack spacing={4}>
            <Title order={2}>{t('new_project_view.title_guiding_question')}</Title>
            <Text>{project.purpose !== '' && project.purpose}</Text>
          </Stack>
        )}
        {project.synopsis && trimHTML(project.synopsis) !== '' && (
          <Stack spacing={4}>
            <Title order={2}>{t('new_project_view.title_summary')}</Title>
            <Text>{Parser(project.synopsis)}</Text>
          </Stack>
        )}
        {project.objectives && trimHTML(project.objectives) && (
          <Stack spacing={4}>
            <Title order={2}>{t('new_project_view.title_objectives')}</Title>
            <Text>{Parser(project.objectives)}</Text>
          </Stack>
        )}
        {trimHTML(project.stages) !== '' && (
          <Stack spacing={4} mt='md'>
            <Title order={2}>{t('new_project_page.stages')}</Title>
            <Text>{Parser(project.stages)}</Text>
          </Stack>
        )}
        {project?.evaluationTools.length > 0 && (
          <Stack spacing={4} mt='md'>
            <Title order={2}>{t('new_project_page.evaluation_tools')}</Title>
            <Flex direction='row' wrap='wrap'>
              {project.evaluationTools.map((tool: IEvaluationTool) => (
                <Badge key={tool.id} color='indigo.6' size='md' variant='filled' mr='sm' mt='sm'>
                  {tool.name}
                </Badge>
              ))}
            </Flex>
          </Stack>
        )}
        {project.finalProductKind && project.finalProductKind !== '' && (
          <Stack spacing={4}>
            <Group spacing='sm' position='left' align='center'>
              <Title order={2}>{t('new_project_view.title_final_product')}</Title>
            </Group>
            <Flex direction='row'>
              <Badge color='indigo.6' size='md' variant='filled' mr='sm'>
                {t(`final_product_kind.${project.finalProductKind}`)}
              </Badge>
            </Flex>
            <Text>{Parser(project.finalProductDescription)}</Text>
          </Stack>
        )}
        <Stack spacing={4}>
          <Title order={2}>
            {t('new_project_view.title_worked_skills_standards', {
              count: project.projectItems.specificSkills.length + project.projectItems.contents.length,
            })}
          </Title>
          <Stack spacing='sm'>
            {project.projectItems.specificSkills.length === 0 && project.projectItems.contents.length === 0 && (
              <EmptyStateBox withBorder text={t('new_project_view.no_skills_standards')} icon={<IconArticleOff />} />
            )}
            {project.projectItems.specificSkills.length > 0 &&
              project.projectItems.specificSkills.map(item => {
                if (!item.breadcrumb) return null
                const breadcrumb =
                  item.lv1Name +
                  ' > ' +
                  extractBreadcrumb(item.breadcrumb, 0) +
                  ' > ' +
                  extractBreadcrumb(item.breadcrumb, 1) +
                  ' > ' +
                  item.name
                return (
                  <Group key={item.id} spacing={4} align='flex-start'>
                    <BreadCrumbCard
                      showCyclesBadges={currentUser?.by_cycle}
                      cycles={item.cycles}
                      breadcrumb={breadcrumb}
                      color={item.color}
                      fullBreadCrumb
                    />
                  </Group>
                )
              })}
            {project.projectItems.contents.length > 0 &&
              project.projectItems.contents.map(item => {
                if (!item.breadcrumb) return null
                const breadcrumb =
                  item.lv1Name +
                  ' > ' +
                  extractBreadcrumb(item.breadcrumb, 0) +
                  ' > ' +
                  extractBreadcrumb(item.breadcrumb, 1) +
                  ' > ' +
                  item.name
                return (
                  <Group key={item.id} spacing={4} align='flex-start'>
                    <BreadCrumbCard
                      showCyclesBadges={currentUser?.by_cycle}
                      cycles={item.cycles}
                      breadcrumb={breadcrumb}
                      color={item.color}
                      fullBreadCrumb
                    />
                  </Group>
                )
              })}
          </Stack>
        </Stack>
        <Stack spacing={4}>
          <LessonsProvider>
            <LessonsList
              kind={project.kind}
              showLessonTitle={true}
              projectID={project.id}
              projectStatus={project.status}
              editable={hasPermission(['tutor', 'manager']) && project.status !== 'finished'}
              lessonsList={lessons}
              journal={project.status === 'approved' && hasPermission(['tutor', 'manager', 'student'])}
              loading={lessonsLoading}
              schoolTermEndAt={schoolTermEndAt}
              schoolTermStartAt={schoolTermStartAt}
              fetchAndSetActivities={getActivitiesforFirstPageAndResetPagetoOne}
              selectedSkills={project.projectItems.specificSkills}
              selectedContents={project.projectItems.contents}
              lessonsPagination={pagination}
              isView
            />
          </LessonsProvider>
        </Stack>
        {(project.status === 'finished' && hasPermission(['guardian'])) ||
          (project.status !== 'draft' && hasPermission(['manager', 'student', 'tutor']) && <Divider />)}
        {project.kind === 'application' && project.status !== 'draft' && (
          <Stack spacing={4} align='flex-start'>
            {project.status === 'finished' && hasPermission(['guardian', 'manager', 'student', 'tutor']) && (
              <Title mb='xs' order={2}>
                {t('new_project_view.title_final_report')}
              </Title>
            )}
            {project.status === 'approved' && hasPermission(['manager', 'student', 'tutor']) && (
              <Title mb='xs' order={2}>
                {t('new_project_view.title_final_assessment')}
              </Title>
            )}
            {project.status === 'finished' && (
              <Button
                variant='filled'
                size='lg'
                component='a'
                href={finalReportURL(project.id)}
                onClick={() => mixpanel.track('Ver relatório final do projeto')}
              >
                {t('new_project_view.button_final_report')}
              </Button>
            )}
            {project.status === 'approved' && hasPermission(['tutor', 'manager']) ? (
              finalAssessmentStarted ? (
                <Button component='a' variant='filled' size='lg' href={getFinalAssessmentURL(project.id)}>
                  {t('new_project_view.button_submit_final_assessment')}
                </Button>
              ) : (
                <Button
                  variant='filled'
                  size='lg'
                  leftIcon={<IconPlayerPlayFilled />}
                  onClick={() => startFinalAssessmentModal(project.id)}
                >
                  {t('new_project_view.button_start_final_assessment')}
                </Button>
              )
            ) : null}
            {project.status === 'approved' && hasPermission(['student']) ? (
              finalAssessmentStarted ? (
                <Button variant='filled' size='lg' component='a' href={getFinalAssessmentURL(project.id)}>
                  {t('new_project_view.button_submit_final_assessment')}
                </Button>
              ) : (
                <Tooltip label={t('new_project_view.tooltip_final_assessment_not_started')}>
                  <Button
                    variant='filled'
                    size='lg'
                    component='a'
                    onClick={event => event.preventDefault()}
                    data-disabled
                    sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                  >
                    {t('new_project_view.button_submit_final_assessment')}
                  </Button>
                </Tooltip>
              )
            ) : null}
          </Stack>
        )}
        <div ref={observerRef} />
      </Stack>
    </>
  )
}
