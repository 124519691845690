import { useCallback, useState, useEffect } from 'react'
import Service from '@legacy/common/services/googleDriveUploadService'
import {
  GoogleDriveUploadProps,
  IGoogleDriveFile,
  IReceivedGoogleDriveFiles,
} from '@legacy/common/types/GoogleDriveFiles'
import { toast } from 'react-toastify'
import { toastOptions } from '@legacy/common/_components/LumiarToast'
import ErrorService from '@legacy/common/Tools/ErrorService'
import { useTranslation } from 'react-i18next'

interface GoogleDriveUploadHookResult {
  readonly files: IReceivedGoogleDriveFiles | null
  readonly onDelete: (id: string) => void
  readonly onPost: (file: IGoogleDriveFile, track: Function) => void
}

const useGoogleDriveUpload = (
  props: GoogleDriveUploadProps
): GoogleDriveUploadHookResult => {
  const [files, setFiles] = useState<IReceivedGoogleDriveFiles>([])
  const { t } = useTranslation()

  useEffect(() => {
    const request = async () => {
      const response = await get()
      return response
    }

    request().catch((err) => ErrorService.notice(err))

    return () => {}
  }, [])

  const get = useCallback(async () => {
    let data
    try {
      data = await Service.activity.getListFiles(props.value)
      return setFiles(data)
    } catch {
      return toast.error('getFilesFromGoogleDrive:', toastOptions)
    }
  }, [])

  const onDelete = useCallback(async (fileId: string) => {
    try {
      await Service.activity.deleteFile({ fileId })
      await get()
      return toast.success(t('gdrive_success_delete'), toastOptions)
    } catch {
      return toast.error(t('gdrive_error_delete'), toastOptions)
    }
  }, [])

  const onPost = async (file: IGoogleDriveFile, track: any) => {
    try {
      await Service.activity.postFile({ ...props.value, file })
      await get()
      await track(file)
      return toast.success(t('gdrive_success'), toastOptions)
    } catch {
      return toast.error(t('gdrive_error'), toastOptions)
    }
  }

  return { files, onDelete, onPost }
}

export default useGoogleDriveUpload
