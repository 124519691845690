import { useEffect } from 'react'
import { SidebarMenu } from 'components/SidebarMenu/SidebarMenu'
import { Navbar } from 'components/Navbar'
import { useTranslation } from 'react-i18next'
import { ContainerGlobal, ContainerNotification } from './styled'
import { useLocation } from 'react-router-dom'
import { useSearchParams } from 'react-router-dom'
import { hideZenhubButton } from './util'
import { useNotification } from 'context/useNotificationContext'
import { Notification } from '@mantine/core'
import { IconX, IconCheck } from '@tabler/icons-react'
import { useCurrentUser } from 'context'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { pathname } = location
  const isApp = searchParams.get('app') === 'true'
  const { notification, text, handleNotification, kind } = useNotification()

  useEffect(() => {
    if (isApp) {
      hideZenhubButton()
    }
  }, [])

  const thereIsUser = currentUser && currentUser.id
  const isStudent = thereIsUser && currentUser?.profile_used === 'student'
  const userIdStr = isStudent ? currentUser.profile_url.split('/')[2] : currentUser?.id
  return (
    <>
      <Navbar />
      {!isApp && (
        <SidebarMenu
          profile={currentUser && currentUser.profile_used}
          pathname={pathname}
          userID={userIdStr}
        />
      )}
      <ContainerNotification>
        {notification && (
          <Notification
            onClose={() => handleNotification(false, '')}
            icon={kind === 'success' ? <IconCheck size='1.1rem' /> : <IconX size='1.1rem' />}
            color={kind === 'success' ? 'green' : 'red'}
            closeButtonProps={{ title: t('student_creation_screen.accessibility') }}
            styles={() => ({
              root: {
                maxWidth: '570px',
                padding: '16px',
              },
            })}
          >
            {text}
          </Notification>
        )}
      </ContainerNotification>

      <ContainerGlobal isApp={isApp}>{children}</ContainerGlobal>
    </>
  )
}

const LayoutLogin = ({ children }: { children: React.ReactNode }) => {
  const { notification, text, handleNotification, kind } = useNotification()
  const { t } = useTranslation()

  return (
    <>
      <ContainerNotification>
        {notification && (
          <Notification
            onClose={() => handleNotification(false, '')}
            icon={kind === 'success' ? <IconCheck size='1.1rem' /> : <IconX size='1.1rem' />}
            color={kind === 'success' ? 'green' : 'red'}
            closeButtonProps={{ title: t('student_creation_screen.accessibility') }}
            styles={() => ({
              root: {
                maxWidth: '570px',
                padding: '16px',
              },
            })}
          >
            {text}
          </Notification>
        )}
      </ContainerNotification>

      {children}
    </>
  )
}

Layout.Login = LayoutLogin

export { Layout, LayoutLogin }
