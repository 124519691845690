import Paper from '../Paper/paper'
import Avatar from '../Avatar/Avatar'
import Tag from '../Tag/Tag'
import {
  StudentCardWrapper,
  InfoContainer,
  StatusContainer,
  LabelStatus,
} from './studantCardStyled'
import { IStudentCard } from './studentCardTypes'
import { useTranslation } from 'react-i18next'
const StudentCard = ({
  name,
  status,
  image,
  studentStatus,
  hasScore,
}: IStudentCard) => {
  const { t } = useTranslation()

  return (
    <Paper elevation={1}>
      <StudentCardWrapper>
        <InfoContainer>
          <Avatar name={name} size='xsmall' image={image} />
          <p>{name}</p>
        </InfoContainer>

        {hasScore && (
          <StatusContainer>
            <LabelStatus>{`${t('general_profiles_tutor')}:`}</LabelStatus>
            <LabelStatus>{`${t('general_profiles_student')}:`}</LabelStatus>
            <Tag kind={status} />
            <Tag kind={studentStatus} />
          </StatusContainer>
        )}
      </StudentCardWrapper>
    </Paper>
  )
}

export default StudentCard
