import { useTranslation } from 'react-i18next'
import { IconExit } from 'components/Icons'
import {
  DropdownMenuItemStyled,
  ContainerWithIconStyled,
  LinkStyled,
} from './LogoutMenuStyled'

export const LogoutMenu = () => {
  const { t } = useTranslation()

  const logOut = async () => {
    window.location.replace('/account/signout')
  }

  return (
    <DropdownMenuItemStyled>
      <ContainerWithIconStyled>
        <IconExit />
        <LinkStyled rel='nofollow' onClick={logOut}>
          {t('logout')}
        </LinkStyled>
      </ContainerWithIconStyled>
    </DropdownMenuItemStyled>
  )
}
