import { ProjectButton } from 'components/ProjectButton'
import { IProjectHeader } from './interfaces'
import { ProjectHeaderContainer } from './styled'

export const ProjectHeader = ({ projectId, text }: IProjectHeader) => {
  return (
    <ProjectHeaderContainer>
      <ProjectButton projectId={projectId} text={text.button} />
      <h2>{text.header}</h2>
    </ProjectHeaderContainer>
  )
}