import type { CamelCasedPropertiesDeep } from 'type-fest'

export interface AssossiatedProject {
  id: string
  name: string
}

export interface IStudent {
  id: string
  first_name: string
  last_name: string
  filtered: boolean
  selected: boolean
  assossiated_projects: AssossiatedProject[]
}

export type StudentCamelCased = CamelCasedPropertiesDeep<IStudent>

export type GetStudents = () => Promise<StudentCamelCased[]>
