import { List, Tooltip, Anchor, Box } from 'components'
import { ILessonCardActivity } from 'interfaces'
import { Activity } from '../ActivityText'
import { useTranslation } from 'react-i18next'
import { useStyles } from '../ActivityText/styles'
import { useMantineColorScheme } from '@mantine/core'

interface ActivitiesTextListProps {
  activities: ILessonCardActivity[] & { id: string; title: string }[]
}

export const ActivitiesTextList = ({ activities }: ActivitiesTextListProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { colorScheme } = useMantineColorScheme()

  const ACTIVITIES_LIMIT = 2

  const activitiesRest = activities?.slice(ACTIVITIES_LIMIT, activities.length) as ILessonCardActivity[] & string[]

  const restLabelObject = activitiesRest?.map(activity => {
    const activityText = typeof activity === 'string' ? activity : activity.title
    return activityText
  })

  const restLabel = restLabelObject?.map((activity, index) => {
    return <List.Item key={index}>{activity}</List.Item>
  })

  const restContent = <List size='sm' sx={{ color: colorScheme === 'dark' ? 'dark' : 'white' }}>
    {restLabel}
  </List>

  if (!activities) return null
  if (activities.length === 0) return null

  const moreComponent = (
    <List.Item>
      <Tooltip label={restContent} withArrow withinPortal position='top'>
        <Anchor href='#'>
          {activities.length - ACTIVITIES_LIMIT === 1
            ? t('and_another_activity')
            : t('and_another_activities', { count: activities.length - ACTIVITIES_LIMIT })}
        </Anchor>
      </Tooltip>
    </List.Item>
  )

  if (activities.length === 1)
    return (
      <>
        {activities?.slice(0, ACTIVITIES_LIMIT).map((activity, index) => (
          <Box key={index}>
            <Activity activity={activity} />
          </Box>
        ))}
        {activities.length > ACTIVITIES_LIMIT && moreComponent}
      </>
    )

  return (
    <List size='md' spacing={4} classNames={classes}>
      {activities?.slice(0, ACTIVITIES_LIMIT).map((activity, index) => (
        <List.Item key={index}>
          <Activity activity={activity} />
        </List.Item>
      ))}
      {activities.length > ACTIVITIES_LIMIT && moreComponent}
    </List>
  )
}
