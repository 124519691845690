import { useTranslation } from 'react-i18next'
import { useMantineTheme } from 'hooks'
import { CardHead } from './components/CardHead'
import { CardDateTime } from './components/CardDateTime'
import { NextTaskCardProps } from './interfaces'
import { Card, Stack, Text, Box, Group, Badge } from 'components'
import { ActivitiesTextList } from './components/ActivitiesTextList'
import { ActivityActions } from './components/ActivityActions'
import { useStyles } from './styles'

export const NextTaskCard = ({
  projectName,
  lessonName,
  date,
  time,
  activities,
  canFillEditReport,
  status,
  hasAssessmentActivity,
  hasDeliverableActivity,
  handleJournalClick,
}: NextTaskCardProps) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const { classes } = useStyles()

  const activityTxt = t('activity_card_label')
  const activitiesTxt = t('activities_card_label')

  const activity = `${activities?.length > 1 ? activitiesTxt : activityTxt} ${
    activities?.length > 1 ? `(${activities?.length})` : ''
  }`

  return (
    <>
      <Card withBorder className={classes.root}>
        <Card.Section className={classes.header} bg={theme.colorScheme === 'dark' ? 'indigo.9' : 'indigo.7'}>
          <CardHead title={projectName} lesson={lessonName} />
        </Card.Section>
        <Box className={classes.content}>
          <Box className={classes.contentBox}>
            <Stack spacing='sm' className={classes.contentStack}>
              <CardDateTime date={date} time={time} />
              <Stack spacing={4}>
                <Group spacing='xs'>
                  <Text size='sm' color='dimmed'>
                    {activity}
                  </Text>
                  {hasAssessmentActivity && (
                    <Badge color='purple' variant='filled'>
                      {t('badges.badge_assessment')}
                    </Badge>
                  )}
                  {hasDeliverableActivity && (
                    <Badge color='blue' variant='filled'>
                      {t('badges.badge_deliverable')}
                    </Badge>
                  )}
                </Group>
                <ActivitiesTextList activities={activities} />
              </Stack>
            </Stack>
          </Box>
          <ActivityActions
            canFillEditReport={canFillEditReport}
            status={status}
            handleJournalClick={handleJournalClick}
          />
        </Box>
      </Card>
    </>
  )
}
