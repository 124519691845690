import { useContext } from 'react'
import { FeedDrawerContext } from '../../contexts/FeedDrawerContext'
import { useMediaQuery, useMantineTheme, ScrollArea } from 'hooks'
import { Modal } from 'components'
import { StepPageOne } from './components/StepPageOne'
import { StepPageTwo } from './components/StepPageTwo'

export const FeedDrawer = () => {
  const { openedDrawer, onCloseDrawer, headerText } = useContext(FeedDrawerContext)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return (
    <Modal
      fullScreen={isMobile}
      opened={openedDrawer}
      onClose={() => onCloseDrawer(false)}
      size='xl'
      title={headerText}
      zIndex={100}
      scrollAreaComponent={ScrollArea.Autosize}
    >
      <StepPageOne />
      <StepPageTwo />
    </Modal>
  )
}
