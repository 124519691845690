import { api } from 'helpers'
import { 
  IOptionsGetData, 
  IGetData,
  IResponseSaveData, 
  IPayload, 
  IGetInvite,
  IInvite,
} from './interface'


export default {
  getSchoolYear: async (): Promise<IOptionsGetData[]> => {
    const response = await api.get<IGetData[]>('/api/school_years')
    return response.data.map((schoolYear: IGetData) => ({
      label: schoolYear.name,
      value: schoolYear.id,
    }))
  },
  getGrade: async (): Promise<IOptionsGetData[]> => {
    const response = await api.get<IGetData[]>('/api/grades')
    return response.data.map((grade: IGetData) => ({
      label: grade.name,
      value: grade.id,
    }))
  },
  getGroupTeacher: async (): Promise<IOptionsGetData[]> => {
    const response = await api.get<IGetData[]>('/api/tutor/current_groups')
    return response.data.map((currentGroup: IGetData) => ({
      label: currentGroup.name,
      value: currentGroup.id,
    }))
  },
  getGroupSchoolTermsOfCurrentYear: async (): Promise<IOptionsGetData[]> => {
    const response = await api.get<IGetData[]>('/manager/group_school_terms/group_school_terms_of_current_year')
    return response.data.map((groupSchoolTermsOfCurrentYear: IGetData) => ({
      label: groupSchoolTermsOfCurrentYear.name,
      value: groupSchoolTermsOfCurrentYear.id,
    }))
  },
  getInvite: async (id: string | undefined): Promise<IInvite> => {
    const response = await api.get<IGetInvite>(`/api/tutor/get_invite_information/${id}`)
    const yearInvite = [
      {
        label: response.data.schoolYearName,
        value: response.data.schoolYearId,
      },
    ]
    const groupInvite = [
      {
        label: response.data.groupSchoolTermName,
        value: response.data.groupSchoolTermId,
      },
    ]
    return {
      yearInvite,
      groupInvite,
    }
  },
  saveData:async (payload: IPayload) => {
    const response = await api.post<IResponseSaveData>('/manager/students', payload)
    return response.data
  },
}
