import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { IShowStudentTaskActivity } from '@legacy/common/types/StudentTask'
import parse from 'html-react-parser'
import { toDateString } from '../../formaters/date-formatter'
import GDriveList from '../gdriveList'

import { TaskInfo, Unavailable } from './styles'
import Planning from '../Activities/components/Planning/Planning'

const FEATURETOGGLE = 'student_task'

const ShowStudentTask = ({
  title,
  date,
  description,
  id = '',
  planningFiles,
}: IShowStudentTaskActivity) => {
  const { t } = useTranslation()

  if (id === '') return <Unavailable />

  return (
    <TaskInfo>
      <h2>{t('student_task_delivery')}</h2>
      <h4>{t('student_task_delivery_name')}</h4>
      <h3>{title}</h3>
      <h4>{t('student_task_deadline')}</h4>
      <h3>{toDateString(date)}</h3>
      <h4>{t('student_task_delivery_instructions')}</h4>
      {parse(description)}
      {id !== '' && <Planning text="" files={planningFiles} hideSize />}
      {id !== '' && <GDriveList resourceId={id} resourceClass="student_task_id" />}
    </TaskInfo>
  )
}

export default ShowStudentTask
