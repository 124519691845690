import { createContext, useState, useEffect } from 'react'
import { IFeature } from 'interfaces'
import { api } from 'helpers'

interface FeatureToggleContextType {
  features: IFeature[]
  setFeatures: (features: IFeature[]) => void
}

export const FeatureToggleContext = createContext<FeatureToggleContextType>({
  features: [],
  setFeatures: () => {},
})

export const FeatureToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [features, setFeatures] = useState<IFeature[]>([])

  useEffect(() => {
    if(features.length === 0) {
      const fetchFeatures = async () => {
        try {
          const response = await api.get('/flipper/api/features')
          setFeatures(response.data.features)
        } catch (error) {
          console.error(error)
        }
      }
      fetchFeatures()
    } 
  }, [features])

  return (
    <FeatureToggleContext.Provider
      value={{
        features,
        setFeatures,
      }}
    >
      {children}
    </FeatureToggleContext.Provider>
  )
}
