import { useTranslation } from 'react-i18next'
import { Language } from '@legacy/common/models/language'
import { InputSelectValue } from '@legacy/common/types/input'
import { ITimestamps } from '../timestamps-model'
import { IModelErrors } from '../base-model'

export interface ICvGroup {
  readonly id: string
  readonly name: string
  readonly label?: string
}

export type SchoolProductType =
  | 'full_lumiar'
  | 'lumiar_solutions'
  | 'homeschooling'
  | 'non_lumiar'
export type SchoolType = 'public' | 'private'
export type SchoolYearInitialMonth = '8' | '1'

export enum SchoolPeriodType {
  Year = 1,
  Cycle = 2
}

export enum SchoolScoreType {
  Number = 1,
  Hand = 2,
}

export interface IBaseSchool {
  readonly name: string;
  readonly city_id: string;
  readonly product_type: SchoolProductType | "";
  readonly school_type: SchoolType | "";
  readonly language: Language | "";
  readonly school_year_initial_month: SchoolYearInitialMonth | "";
  readonly period_type: SchoolPeriodType;
  readonly score_type: SchoolScoreType;
  readonly school_group_id: string;
  readonly curriculum_group_id: string;
  readonly enable_only_bncc_content: boolean;
}
export interface IReceivedSchool extends ITimestamps {
  readonly id: string
  readonly account_id: string
  readonly name: string
  readonly city_id: string
  readonly enable_only_bncc_content: boolean
  readonly enabled_automatic_filling: boolean
  readonly is_demonstration: boolean
  readonly language: Language | null
  readonly product_type: SchoolProductType | null
  readonly school_type: SchoolType | null
  readonly school_group_id: string | null
  readonly curriculum_group_id: string | null
  readonly school_year_initial_month:8 | 1 | null
}

export interface ISchool extends IBaseSchool {
  readonly id: string
}

export interface INewSchool extends IBaseSchool {}

export type ISchoolErrors = IModelErrors<ISchool>

export const initialSchool: INewSchool = {
  name: '',
  city_id: '',
  product_type: '',
  score_type: SchoolScoreType.Hand,
  school_type: '',
  language: '',
  period_type: SchoolPeriodType.Cycle,
  school_year_initial_month: '',
  school_group_id: '',
  curriculum_group_id: '',
  enable_only_bncc_content: false,
}

export const initialSchoolErrors: ISchoolErrors = {
  name: [],
  city_id: [],
  product_type: [],
  school_type: [],
  language: [],
  school_year_initial_month: [],
  school_group_id: [],
  enable_only_bncc_content: [],
}

export function getMonthNumber (month: string) {
  switch (month) {
    case '1':
      return 'january'
    case '8':
      return 'august'
    default:
      throw new Error('this month is Not implemented')
  }
}

export function getInitialMonthValues (): InputSelectValue[] {
  const { t } = useTranslation()
  const months: { [key: string]: string } = t(
    'school_model.school_year_initial_months'
  )
  return Object.keys(months).map((id: string) => ({
    label: months[id],
    id,
  }))
}

export function getSchoolTypeValues (): InputSelectValue[] {
  const { t } = useTranslation()
  const types: { [key: string]: string } = t('school_model_school_type_collection')
  return Object.keys(types).map((id: string) => ({
    label: types[id],
    id,
  }))
}

export function getSchoolProductTypeValues (): InputSelectValue[] {
  const { t } = useTranslation()
  const types: { [key: string]: string } = t('school_model_product_type_collection')
  return Object.keys(types).map((id: string) => ({
    label: types[id],
    id,
  }))
}
