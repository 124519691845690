import { createStyles } from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      color,
    }: {
      color: string | undefined
    },
  ) => ({
    card: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    maxHeight: {
      flex: 1,
    },
    borderBottom: {
      height: theme.spacing.xs,
      backgroundColor: color,
    },
  }),
)
