export const teacherReportData = {
  projectJournal: 'relato do prof sobre a turma',
  projectMoods: {
    groupMoods: {
      studentCount: 8,
      moods: {
        moodMasterEvaluationByStudent: {
          value: 1,
          answerCount: 3,
        },
        moodGroupDeveloped: {
          value: 2,
          answerCount: 4,
        },
        moodStudentTookPart: {
          value: 3,
          answerCount: 5,
        },
        moodStudentDeveloped: {
          value: 4,
          answerCount: 2,
        },
        moodStudentLiked: {
          value: 5,
          answerCount: 4,
        },
      },
    },
    moods: {
      moodPlanningWorked: 3,
      moodGroupGotInvolved: 3,
      moodGroupTookPart: 2,
      moodGroupDeveloped: 5,
    },
  },
  evidencies: {
    items: [
      {
        id: 'asd342-wdrn0-ser9-sdf40-sjkd',
        resourceType: '',
        fileData: {
          name: 'Imagem 1',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
      {
        id: '09yu-ws3gy-4er9-srt40-sj45',
        resourceType: '',
        fileData: {
          name: 'Imagem 2',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
    ],
  },
}

export const studentAssessmentsData = {
  studentId: 'idasoi0430',
  studentReport: 'Relato do estudante',
  studentEvidences: {
    items: [
      {
        id: 'asd342-wdrn0-ser9-sdf40-sjkd',
        resourceType: 'image',
        fileData: {
          name: 'Imagem 1',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
      {
        id: '09yu-ws3gy-4er9-srt40-sj45',
        resourceType: 'image',
        fileData: {
          name: 'Imagem 1',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
    ],
  },
  teacherReport: 'Relato do prof sobre estudante',
  teacherEvidences: {
    items: [
      {
        id: 'asd342-wdrn0-ser9-sdf40-sjkd',
        resourceType: 'image',
        fileData: {
          name: 'Imagem 1',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
      {
        id: '09yu-ws3gy-4er9-srt40-sj45',
        resourceType: 'image',
        fileData: {
          name: 'Imagem 1',
          fileCover: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileCoverLarge: 'https://avatars.dicebear.com/api/avataaars/1.svg',
          fileType: 'image/png',
          sizeInBytes: '123456',
          downloadUrl: 'https://avatars.dicebear.com/api/avataaars/1.svg',
        },
      },
    ],
  },
  moods: {
    moodMasterEvaluationByStudent: {
      value: 3,
    },
    moodGroupDeveloped: {
      value: 5,
    },
    moodStudentTookPart: {
      value: 3,
    },
    moodStudentDeveloped: {
      value: 2,
    },
    moodStudentLiked: {
      value: 1,
    },
  },
  finalAssessmentsSkills: [
    {
      id: '123as-234s-1324q-asdf3',
      cycles: ['F1', 'F2'],
      doNotAssess: false,
      color: '#FF0000',
      breadcrumb: 'lv1_itemname > lv2_itemname > lv3_itemname > lv4_itemname',
      assessments: [
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
      ],
    },
    {
      id: '1hj8s-it98-4yr6-dh89',
      cycles: ['F1', 'F3'],
      doNotAssess: true,
      color: '#FF0000',
      breadcrumb: 'lv1_itemname > lv2_itemname > lv3_itemname > lv4_itemname',
      assessments: [
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
      ],
    },
  ],
  finalAssessmentsContent: [
    {
      id: 'dcn8-sj89-1dm0-aw9h',
      cycles: ['F1', 'F2'],
      doNotAssess: false,
      color: '#FF0000',
      breadcrumb: 'lv1_itemname > lv2_itemname > lv3_itemname > lv4_itemname',
      assessments: [
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
      ],
    },
    {
      id: 'dihj-cni3-dopjd-sn89as',
      cycles: ['F1', 'F3'],
      doNotAssess: true,
      color: '#FF0000',
      breadcrumb: 'lv1_itemname > lv2_itemname > lv3_itemname > lv4_itemname',
      assessments: [
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
        {
          evaluatorUser: {
            id: '234-o23i-234jk-294j-2m4e',
            fullName: 'Name User',
            avatar: null,
          },
          score: 2,
          rubric: 'Custom rubric',
        },
      ],
    },
  ],
}
