import { Component } from 'react'
import PropTypes from 'prop-types'
import { MAX_SIZE } from '@legacy/common/_shared/FileUploadHelper'

export default class InputFile extends Component {
  handleChange = e => {
    if (this.props.onChange) {
      const { files } = e.target

      if (this.props.onExceededFileSize) {
        for (let index = 0; index < files.length; index += 1) {
          const file = files[index]
          if (file.size > MAX_SIZE) {
            this.props.onExceededFileSize(file.name, file.size)
            e.target.value = ''
            return
          }
        }
      }

      this.props.onChange(files)
    }
  }

  render() {
    return (
      <input
        id={this.props.id}
        className={this.props.className}
        type="file"
        onChange={this.handleChange}
        accept={this.props.extensions}
        multiple={this.props.multiple}
      />
    )
  }
}

InputFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  onExceededFileSize: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  extensions: PropTypes.string,
  multiple: PropTypes.bool,
}

InputFile.defaultProps = {
  onExceededFileSize: undefined,
  extensions: '.gif,.jpg,.jpeg,.png,.doc,.docx',
  multiple: false,
}
