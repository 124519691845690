/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext } from 'react'
import { ITabContext } from '../types/student-types'

const TabContext = createContext<ITabContext>({
  changeTab: (tab: number) => {},
  changePage: (page: number) => {},
  searchStudentByName: (search: string) => {},
  activeTab: 1,
  activePage: 1,
  students: [],
  pagination: {
    total: 1,
  },
  meta: {
    activeStudentsCount: 0,
    inactiveStudentsCount: 0,
  },
  loading: true,
  actions: {
    new: '',
  },
})

export default TabContext
