/* eslint-disable max-len */
import React from 'react'
import { StyledIconCircleStroke, StyledIconPathFill } from './styled'

export function StudentReportsIcon () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyledIconPathFill d="M4 20C4 20.5523 4.44772 21 5 21C5.55228 21 6 20.5523 6 20H4ZM18 20C18 20.5523 18.4477 21 19 21C19.5523 21 20 20.5523 20 20H18ZM6 20V19H4V20H6ZM9 16H15V14H9V16ZM18 19V20H20V19H18ZM15 16C16.6569 16 18 17.3431 18 19H20C20 16.2386 17.7614 14 15 14V16ZM6 19C6 17.3431 7.34315 16 9 16V14C6.23858 14 4 16.2386 4 19H6Z" />
      <StyledIconCircleStroke
        cx="12"
        cy="7.5"
        r="3.5"
        strokeWidth="2"
      />
    </svg>
  )
}
