import { forwardRef, memo, useMemo } from 'react'
import { Group, Avatar, Text, Select } from 'components'
import { SelectItem } from '@mantine/core'
import { ItemProps, SelectAvatarProps } from './interfaces'
import { getInitials } from 'helpers'

const MemoizedSelectItem = memo(
  forwardRef<HTMLDivElement, ItemProps>(({ image, label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Avatar size='md' src={image}>{getInitials(label)}</Avatar>
        <div>
          <Text size='md'>{label}</Text>
        </div>
      </Group>
    </div>
  )),
)

MemoizedSelectItem.displayName = 'SelectItem'

const SelectAvatar = memo(({ data, label, placeholder, nothingFound, ...props }: SelectAvatarProps) => {
  const filter = (value: string, item: SelectItem) =>
    item.label?.toLowerCase().includes(value.toLowerCase().trim()) || false
  
  const filteredData = useMemo(() => data, [data])
  
  return (
    <Select
      label={label}
      placeholder={placeholder}
      itemComponent={MemoizedSelectItem}
      data={filteredData}
      searchable
      maxDropdownHeight={400}
      nothingFound={nothingFound}
      filter={(value, item) => filter(value, item)}
      {...props}
    />
  )
})

SelectAvatar.displayName = 'SelectAvatar'

export { SelectAvatar }
