import { createContext, useState, useEffect } from 'react'
import { useDisclosure } from 'hooks'
import { IFile, IStudentToSelect } from 'pages/Feed/interfaces'
import { initialPayload } from 'pages/Feed/initialStates'
import { useTranslation } from 'react-i18next'
import { notifications, modals } from 'context'
import { IconX } from '@tabler/icons-react'
import { Text } from 'components'
import * as serviceFeed from 'pages/Feed/serviceFeed'
import { FeedStatus, IObservationPayload, cleanObservations } from '../../serviceFeed'
import { isEqual } from 'lodash'
import { useBeforeunload } from 'react-beforeunload'
import { searchStudents } from './searchStudents'

interface FeedDrawerContextProps {
  stepPage: number
  setStepPage: React.Dispatch<React.SetStateAction<number>>
  seachString: string
  setSearchString: React.Dispatch<React.SetStateAction<string>>
  selectedProject: string
  setSelectedProject: React.Dispatch<React.SetStateAction<string>>
  saveFeedLoading: boolean
  setSaveFeedLoading: React.Dispatch<React.SetStateAction<boolean>>
  sendFileLoader: boolean
  setSendFileLoader: React.Dispatch<React.SetStateAction<boolean>>
  textAreaError: boolean
  setTextAreaError: React.Dispatch<React.SetStateAction<boolean>>
  openedDrawer: boolean
  openDrawer: () => void
  closeDrawer: () => void
  onCloseDrawer: (confirmed: boolean) => void
  feedStatus: FeedStatus
  setFeedStatus: React.Dispatch<React.SetStateAction<FeedStatus>>
  errorLength: boolean
  setErrorLength: React.Dispatch<React.SetStateAction<boolean>>
  feedId: string
  setFeedId: React.Dispatch<React.SetStateAction<string>>
  userIsCreating: boolean
  setUserIsCreating: React.Dispatch<React.SetStateAction<boolean>>
  payload: IObservationPayload
  setPayload: React.Dispatch<React.SetStateAction<IObservationPayload>>
  hasChanges: boolean
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>
  textAreaValue: string
  setTextAreaValue: React.Dispatch<React.SetStateAction<string>>
  descriptionsize: number
  setDescriptionSize: React.Dispatch<React.SetStateAction<number>>
  files: IFile[]
  setFiles: React.Dispatch<React.SetStateAction<IFile[]>>
  headerText: string
  setHeaderText: React.Dispatch<React.SetStateAction<string>>
  serverData: IObservationPayload | null
  setServerData: React.Dispatch<React.SetStateAction<IObservationPayload | null>>
  students: IStudentToSelect[]
  setStudents: React.Dispatch<React.SetStateAction<IStudentToSelect[]>>
  setTextDescription: (value: string) => void
  handleSearchOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FeedDrawerContext = createContext<FeedDrawerContextProps>({
  stepPage: 1,
  setStepPage: () => {},
  seachString: '',
  setSearchString: () => {},
  selectedProject: '',
  setSelectedProject: () => {},
  saveFeedLoading: false,
  setSaveFeedLoading: () => {},
  sendFileLoader: false,
  setSendFileLoader: () => {},
  textAreaError: false,
  setTextAreaError: () => {},
  openedDrawer: false,
  openDrawer: () => {},
  closeDrawer: () => {},
  onCloseDrawer: () => {},
  feedStatus: FeedStatus.Draft,
  setFeedStatus: () => {},
  errorLength: false,
  setErrorLength: () => {},
  feedId: '',
  setFeedId: () => {},
  userIsCreating: true,
  setUserIsCreating: () => {},
  payload: initialPayload,
  setPayload: () => {},
  hasChanges: false,
  setHasChanges: () => {},
  textAreaValue: '',
  setTextAreaValue: () => {},
  descriptionsize: 0,
  setDescriptionSize: () => {},
  files: [],
  setFiles: () => {},
  headerText: '',
  setHeaderText: () => {},
  serverData: null,
  setServerData: () => {},
  students: [],
  setStudents: () => {},
  setTextDescription: () => {},
  handleSearchOnChange: () => {},
})

export const FeedDrawerProvider = ({ children }: { children: React.ReactNode }) => {

  const { t } = useTranslation()

  const [stepPage, setStepPage] = useState(1)
  const [seachString, setSearchString] = useState('')
  const [selectedProject, setSelectedProject] = useState('')
  const [saveFeedLoading, setSaveFeedLoading] = useState<boolean>(false)
  const [sendFileLoader, setSendFileLoader] = useState(false)
  const [textAreaError, setTextAreaError] = useState(false)
  const [openedDrawer, { open: openDrawer, close: closeDrawer }] = useDisclosure(false)
  const [serverData, setServerData] = useState<null | IObservationPayload>(null)
  const [students, setStudents] = useState<IStudentToSelect[]>([])
  const [payload, setPayload] = useState(initialPayload)
  const [hasChanges, setHasChanges] = useState(false)
  const [textAreaValue, setTextAreaValue] = useState('')
  const [descriptionsize, setDescriptionSize] = useState(0)
  const [files, setFiles] = useState<IFile[]>([])
  const [headerText, setHeaderText] = useState('')
  const [feedStatus, setFeedStatus] = useState(FeedStatus.Draft)
  const [errorLength, setErrorLength] = useState(false)
  const [feedId, setFeedId] = useState('')
  const [userIsCreating, setUserIsCreating] = useState(true)

  
  const changeStatesToDefault = () => {
    setTextAreaValue('')
    setStepPage(1)
    setSearchString('')
    setSelectedProject('')
    setFeedId('')
    setFeedStatus(FeedStatus.Draft)
    setHasChanges(false)
    setSaveFeedLoading(false)
    uncheckStudents()
    setFiles([])
    setErrorLength(false)
    setDescriptionSize(0)
    setTextAreaError(false)
    setServerData(null)
  }
  const cleanChanges = async () => {
    changeStatesToDefault()
    try {
      await cleanObservations()
    } catch (error) {
      notifications.show({
        title: t('general_error_title'),
        message: 'Error on clean observations',
        color: 'red',
        icon: <IconX size={24} />,
      })
    }

  }

  const onCloseDrawer = (confirmed: boolean) => {
    if (!hasChanges) {
      setStepPage(1)
      closeDrawer()
    } else {
      if (confirmed) { 
        setStepPage(1)
        closeDrawer()
        cleanChanges()
      } else {
        modals.openConfirmModal({
          title: t('students_assessment_title_modal'),
          children: <Text size='sm'>{t('students_assessment_text_modal')}</Text>,
          labels: { confirm: t('general_buttons_confirm'), cancel: t('general_buttons_cancel') },
          cancelProps: { size: 'lg' },
          confirmProps: { size: 'lg' },
          onCancel: () => {},
          onConfirm: () => {
            setStepPage(1)
            cleanChanges()
            closeDrawer()
          },
        })
      }
    }
  }

  const getStudents = async () => {
    const result = await serviceFeed.getStudents()
    setStudents(result)
  }
    
  
  const isChanged = (
    payloadValue: string[] | string | undefined | FeedStatus,
    serverValue: string[] | string | undefined | FeedStatus,
    defaultValue: string[] | string | undefined | FeedStatus,
  ) => (serverData ? !isEqual(payloadValue, serverValue) : !isEqual(payloadValue, defaultValue))

  const setTextDescription = (value: string) => {
    const { length } = value

    setDescriptionSize(length)
    setTextAreaValue(value)
    setErrorLength(length > 300)
  }

  const uncheckStudents = () => {
    setStudents(students => students.map(student => ({ ...student, selected: false })))
  }

  const handleSearchOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const [firstName, ...lastNameArray] = value.split(' ')
    const lastName = lastNameArray.join(' ')
    setSearchString(value)
    searchStudents(firstName, lastName, setStudents, selectedProject)
  }

 


  useEffect(() => {
    setPayload(payload => {
      const updatedPayload = {
        ...payload,
        studentIds: students.filter(({ selected }) => selected).map(({ id }) => id),
        projectId: selectedProject,
      }
      setHasChanges(isChanged(updatedPayload.studentIds, serverData?.studentIds, []))

      return updatedPayload
    })
  }, [students, selectedProject])

  useBeforeunload(event => {
    if (hasChanges) event.preventDefault()
  })

  useEffect(() => {
    setPayload(payload => {
      const updatedPayload = {
        ...payload,
        description: textAreaValue,
      }

      setHasChanges(isChanged(updatedPayload.description, serverData?.description, ''))

      return updatedPayload
    })
  }, [textAreaValue])

  useEffect(() => {
    setPayload(payload => {
      const updatedPayload = {
        ...payload,
        status: feedStatus,
      }

      setHasChanges(isChanged(updatedPayload.status, serverData?.status, FeedStatus.Draft))

      return updatedPayload
    })
  }, [feedStatus])

  useEffect(() => {
    getStudents()
  }, [])

  return (
    <FeedDrawerContext.Provider
      value={{
        stepPage,
        setStepPage,
        seachString,
        setSearchString,
        selectedProject,
        setSelectedProject,
        saveFeedLoading,
        setSaveFeedLoading,
        sendFileLoader,
        setSendFileLoader,
        textAreaError,
        setTextAreaError,
        openedDrawer,
        openDrawer,
        closeDrawer,
        onCloseDrawer,
        feedStatus,
        setFeedStatus,
        errorLength,
        setErrorLength,
        feedId,
        setFeedId,
        userIsCreating,
        setUserIsCreating,
        payload,
        setPayload,
        hasChanges,
        setHasChanges,
        textAreaValue,
        setTextAreaValue,
        descriptionsize,
        setDescriptionSize,
        files,
        setFiles,
        headerText,
        setHeaderText,
        serverData,
        setServerData,
        students,
        setStudents,
        setTextDescription,
        handleSearchOnChange,
      }}
    >
      {children}
    </FeedDrawerContext.Provider>
  )
}
