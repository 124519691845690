import { useEffect, useState, useCallback, StrictMode } from 'react'
import { useTranslation } from 'react-i18next'
import { getMeeting, MeetingResponseObject, updateReportStudent } from '../../api/meetingAssessmentForStudent/service'
import { useMediaQuery, useMixPanel, useDisclosure } from 'hooks'
import { isEqual } from 'lodash'
import { isIpadOS } from 'common/utilities/iOsHelpers'
import { Button, Textarea, Title, Modal, Divider, Flex, Text } from 'components'
import { SummaryCard } from './components/SummaryCard'
import { MeetingRating } from './components/MeetingRating'
import { OnLoadSkeleton } from './OnLoadSkeleton'
import { Props, Payload, AssessmentScore, initialPayload } from './interfaces'
import EvaluativeStudentItem from './components/Evaluation/EvaluativeStudentItem'
import WrappFiles from './components/FileUploader/WrappFiles'
import { notifications } from 'context'
import { IconCheck, IconX } from '@tabler/icons-react'

export const MeetingAssessmentForStudent = ({
  id,
  onClose,
  updateModaTitle,
  setModalHasChanges,
  toggleConfirmationModal,
  UpdateShowModal,
  projectUrl,
  loadDashBoardData,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [meeting, setMeeting] = useState<MeetingResponseObject | null>(null)
  const [description, setDescription] = useState<string>('')
  const [payload, setPayload] = useState<Payload>(initialPayload)
  const [initialPayLoad, setInitialPayLoad] = useState<Payload>(initialPayload)
  const [openedModal, { toggle: toggleModal }] = useDisclosure(false)
  const [sending, setSending] = useState<boolean>(false)
  const largeScreen = useMediaQuery('(min-width: 700px)')
  const mixpanel = useMixPanel()

  const { t } = useTranslation()

  const fetchMeeting = async () => {
    try {
      const data = await getMeeting(id)

      setMeeting(data)
      initializePayloadWhitMeeting(data)
      setDescription(data?.journalStudent)

      const modalTitle = `${t('students_title_')} ${data?.date} (${data?.showHour})`
      setIsLoading(false)
      updateModaTitle(modalTitle)
    } catch (error) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchMeeting()
  }, [])

  const initializePayloadWhitMeeting = (meeting: any) => {
    const { assessmentId, skillsAssessmentScores, contentsAssessmentScores, moods, journalStudent } = meeting
    const skills = skillsAssessmentScores.map((item: any) => {
      return {
        assessment_score_id: item.id,
        student_score: item.studentScore,
      }
    })

    const content = contentsAssessmentScores.map((item: any) => {
      return {
        assessment_score_id: item.id,
        student_score: item.studentScore,
      }
    })

    const payload = {
      assessment_id: assessmentId,
      assessment_scores: [...skills, ...content],
      mood_master_evaluation_by_student: moods.moodMasterEvaluationByStudent,
      mood_student_developed: moods.moodStudentDeveloped,
      mood_student_liked: moods.moodStudentLiked,
      mood_student_took_part: moods.moodStudentTookPart,
      mood_group_developed: moods.moodGroupDeveloped,
      journal_description: journalStudent,
    }

    setPayload(payload)
    setInitialPayLoad(payload)
  }

  const updatePayload = useCallback(
    (newDescription: string) => {
      setPayload(prevPayload => ({
        ...prevPayload,
        journal_description: newDescription,
      }))
    },
    [setPayload],
  )

  const updatePayloadRatting = (ratting: any) => {
    setPayload(prevPayload => ({
      ...prevPayload,
      mood_master_evaluation_by_student: ratting.moodMasterEvaluationByStudent,
      mood_student_developed: ratting.moodStudentDeveloped,
      mood_student_liked: ratting.moodStudentLiked,
      mood_student_took_part: ratting.moodStudentTookPart,
      mood_group_developed: ratting.moodGroupDeveloped,
    }))
  }

  const updateScores = (score: AssessmentScore) => {
    setPayload(prevPayload => {
      const existingScoreIndex = prevPayload.assessment_scores.findIndex(
        existingScore => existingScore.assessment_score_id === score.assessment_score_id,
      )

      if (existingScoreIndex !== -1) {
        const updatedScores = [...prevPayload.assessment_scores]
        updatedScores[existingScoreIndex] = score

        return {
          ...prevPayload,
          assessment_scores: updatedScores,
        }
      }

      const updatedScores = [...prevPayload.assessment_scores, score]

      return {
        ...prevPayload,
        assessment_scores: updatedScores,
      }
    })
  }

  const saveMeeting = async () => {
    setSending(true)

    const finalpayload = {
      assessment_id: payload.assessment_id,
      assessment: payload,
    }

    if (!navigator.onLine) {
      setSending(false)
      onClose()
      notifications.show({
        message: t('students_title_error_network'),
        color: 'red',
        icon: <IconX size={24} />,
      })
      return
    }

    try {
      const response = await updateReportStudent(finalpayload)

      if (response === 200) {
        window.setTimeout(() => {
          setSending(false)
          onClose()
          notifications.show({
            message: t('students_title_success'),
            color: 'green',
            icon: <IconCheck size={24} />,
          })
          mixpanel.track('Salvar diario de bordo via modal')
        }, 1000)
        loadDashBoardData()
        return
      }
      notifications.show({
        message: t('students_title_erro'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    } catch (error) {
      setSending(false)
      mixpanel.track('Error ao salvar diario de bordo via modal')
      notifications.show({
        message: t('students_title_erro'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const closeModal = () => {
    if (isEqual(initialPayLoad, payload)) {
      onClose()
      return
    }

    toggleModal()
  }

  useEffect(() => {
    setModalHasChanges(!isEqual(initialPayLoad, payload))
  }, [payload])

  useEffect(() => {
    updatePayload(description)
  }, [description, updatePayload])

  useEffect(() => {
    if (toggleConfirmationModal) {
      toggleModal()
    }
  }, [toggleConfirmationModal])

  if (isLoading) {
    return <OnLoadSkeleton />
  }
  const getId = (inputString: string) => inputString.substring(inputString.indexOf('/projects/') + '/projects/'.length)

  return (
    <StrictMode>
      <Flex direction='column'>
        {meeting?.sessionMeetingActivities !== undefined && (
          <SummaryCard
            sessionMeetingActivities={meeting?.sessionMeetingActivities}
            projectName={meeting?.projectName}
            title={meeting?.title}
            tutors={meeting?.tutors}
            projectUrl={projectUrl}
          />
        )}
        <MeetingRating updatePayloadRatting={updatePayloadRatting as any} moods={meeting?.moods} />
        <Divider my='sm' />
        <Textarea
          label={t('students_report_')}
          mt='md'
          mb='md'
          value={description}
          onChange={event => setDescription(event.currentTarget.value)}
          autosize
          minRows={6}
        />
        <WrappFiles id={payload.assessment_id} projectId={getId(projectUrl)} />
        <Divider my='sm' />
        {meeting?.skillsAssessmentScores && meeting?.skillsAssessmentScores?.length > 0 && (
          <Title order={3} mt='md' mb='xs'>
            {t('students_skills')}
          </Title>
        )}
        {meeting?.skillsAssessmentScores?.map((item: any) => (
          <EvaluativeStudentItem key={item.id} item={item} updateScores={updateScores} />
        ))}
        {meeting?.contentsAssessmentScores && meeting?.contentsAssessmentScores?.length > 0 && (
          <Title order={3} mt='md' mb='xs'>
            {t('students_contents')}
          </Title>
        )}
        {meeting?.contentsAssessmentScores?.map((item: any) => (
          <EvaluativeStudentItem key={item.id} item={item} updateScores={updateScores} />
        ))}

        <Flex
          direction={largeScreen ? 'row' : 'column-reverse'}
          gap='sm'
          justify='end'
          mt='lg'
          pb={!largeScreen || isIpadOS() ? '80px' : 0}
        >
          <Button
            variant='outline'
            onClick={closeModal}
            size='lg'
            fullWidth={!largeScreen}
            mt={largeScreen ? '0' : 'sm'}
          >
            {t('students_cancel')}
          </Button>

          <Button
            onClick={saveMeeting}
            size='lg'
            mt={largeScreen ? '0' : 'sm'}
            loading={sending}
            disabled={isEqual(initialPayLoad, payload)}
            fullWidth={!largeScreen}
          >
            {t('students_save')}
          </Button>
        </Flex>
      </Flex>

      <Modal.Root
        opened={openedModal}
        onClose={() => {
          UpdateShowModal(false)
          toggleModal()
        }}
        zIndex={99999999999}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Title order={3}>{t('students_close_warnning_title')}</Title>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Text>{t('students_close_warnning')}</Text>
            <Flex direction='row' mt='md' align='end' justify='end'>
              <Button
                variant='outline'
                onClick={() => {
                  UpdateShowModal(false)
                  toggleModal()
                }}
                size='md'
              >
                {t('students_cancel')}
              </Button>
              <Button onClick={onClose} size='md' ml='xs'>
                {t('students_save_close')}
              </Button>
            </Flex>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </StrictMode>
  )
}
