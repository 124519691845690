import { Layout } from 'components/Layout'
import { FeedList } from './components/FeedList'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { FeedListProvider } from './contexts/FeedListContext'
import { FeedDrawerProvider } from './contexts/FeedDrawerContext'
import { FeedDrawer } from './components/FeedDrawer/FeedDrawer'

export const FeedPage = () => {
  return (
    <CurrentUserContextProvider>
      <FeedDrawerProvider>
        <FeedListProvider>
          <Layout>
            <FeedDrawer />
            <FeedList />
          </Layout>
        </FeedListProvider>
      </FeedDrawerProvider>
    </CurrentUserContextProvider>
  )
}
