import React, { useState } from 'react'
import classNames from 'classnames'
import { getInitials } from '@legacy/common/Tools/Common'

import ProfilePicFallbackImage from '@legacy/assets/images/perfil-pic.png'

interface ProfilePicProps {
  readonly fullName: string
  readonly img?: string
  readonly className?: string
  readonly children?: never
}

export const ProfilePic = ({ fullName, img, className }: ProfilePicProps) => {
  const [status, setStatus] = useState<'loading' | 'loaded' | 'failed'>('loading')
  const background = `background${fullName.length % 11}`
  const defaultClassNames = 'profile-tag-name img-profile-small'

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    if (status !== 'failed') {
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.src = ProfilePicFallbackImage
      setStatus('failed')
    }
  }

  const isLoaded = status === 'loaded'
  if (img) {
    return (
      <>
        <img
          src={img}
          onLoad={() => setStatus('loaded')}
          className={classNames(defaultClassNames, background, className)}
          alt={fullName}
          title={fullName}
          data-tip={fullName}
          onError={imageOnErrorHandler}
          style={isLoaded ? {} : { display: 'none' }}
        />
        {!isLoaded && (
          <div
            className={classNames(defaultClassNames, background, className)}
            data-tip={fullName}
          >
            <span>{getInitials(fullName)}</span>
          </div>
        )}
      </>
    )
  }

  return (
    <div
      className={classNames(defaultClassNames, background, className)}
      data-tip={fullName}
      title={fullName}
    >
      <span>{getInitials(fullName)}</span>
    </div>
  )
}
