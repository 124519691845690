export const getInitials = str => {
  if (str.length === 0) {
    return ''
  }
  const splitString = str.split(' ')

  if (/\d/.test(splitString[0]) && splitString[0].length <= 3) return splitString[0]

  const stringsNotEmpty = splitString.filter(s => s !== '')
  return `${stringsNotEmpty[0][0].toUpperCase()}${stringsNotEmpty[stringsNotEmpty.length - 1][0].toUpperCase()}`
}

export const removeAccents = str => str ? str.normalize("NFD").replace(/[\u0300-\u036f]/g, "") : ``

export const fullTextSearch = (text, search) => {
  search = removeAccents(search).toLowerCase()
  text = removeAccents(text).toLowerCase()

  const regex = search.split(' ').reduce((result, word) => `${result}.*?\\b${removeSpecialCharacters(word)}[.+\\b.*]?`, '')

  return text.search(new RegExp(`${regex}`, 'm'))
}

export const normalizeText = text => removeAccents(text.toLowerCase())

const specialCharactersRegex = /[[\]{}(),*+?.\\^$|#]/g

export const escapeRegExp = text => text.replace(specialCharactersRegex, '\\$&')

export const modifyKeys = (obj, func) => Object.keys(obj).reduce((group, key) => {
  group[func(key)] = obj[key]
  return group
}, {})

export const removeSpecialCharacters = text => text.replace(specialCharactersRegex, '')

export function isEmpty(field) {
  if (checkField(field)) {
    return t('errors.messages.blank')
  }
}

export function checkField(field) {
  return field == undefined || field == '' || field == [] || field == {} || field == '<p><br></p>'
}

export function isGreaterThanOrEqualTo(field, date) {
  if (!field) return null
  if (!date) return null
  if (field < date) {
    return t('errors.messages.greater_than_or_equal_to', { count: formatDate(date) })
  }
}

export function isGreaterThan(field, date) {
  if (!field) return null
  if (!date) return null
  if (field <= date) {
    return t('errors.messages.greater_than', { count: formatDate(date) })
  }
}

export function isLessThanOrEqualTo(field, date) {
  if (!field) return null
  if (!date) return null
  if (field > date) {
    return t('errors.messages.less_than_or_equal_to', { count: formatDate(date) })
  }
}

export function setError(errors, field, error) {
  if (!error) return errors

  if (errors[field] == undefined) errors[field] = [error]
  else errors[field].push(error)
  return errors
}

export function formatDate(date) {
  const dateFormated = new Date(date)
  if (I18n.locale == 'pt-BR') {
    // return moment.utc(dateFormated).format('DD/MM/YYYY')
  }
  // return moment.utc(dateFormated).format('MM/DD/YYYY')
}

export function formatDateTime(date) {
  const dateFormated = new Date(date)
  if (I18n.locale == 'pt-BR') {
    // return moment(dateFormated).format('DD/MM/YYYY HH:mm')
  }
  // return moment(dateFormated).format('MM/DD/YYYY hh:mmA')
}

export function formatTime(date) {
  const dateFormated = new Date(date)
  if (I18n.locale == 'pt-BR') {
    // return moment(dateFormated).format('HH:mm')
  }
  // return moment(dateFormated).format('hh:mmA')
}

export function msToFormattedTime(duration) {
  const milliseconds = parseInt((duration % 1000) / 100)
  let seconds = parseInt((duration / 1000) % 60)
  let minutes = parseInt((duration / (1000 * 60)) % 60)
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? `0${hours}` : hours
  minutes = (minutes < 10) ? `0${minutes}` : minutes
  seconds = (seconds < 10) ? `0${seconds}` : seconds

  return `${minutes}:${seconds}`
}

export function deletionWarning(data = {}) {
  const {
    prevValue, newValue, deleteWarning, changeWarning, callback,
  } = data
  if (Array.isArray(prevValue) ? prevValue.length === 0 || newValue.length > 0 : prevValue === '') {
    callback()
    return
  }
  if (!Array.isArray(newValue) && newValue === prevValue) return
  const warning = (Array.isArray(newValue) && newValue.length === 0) || newValue === '' ? deleteWarning : changeWarning

  confirmationSwal({
    title: t('swal.user.avatar.delete.title'),
    text: warning,
  })
    .then(confirm => {
      if (confirm) {
        callback()
      }
    })
}

export function resumeText(text, maxLength) {
  if (text.length <= maxLength) return text

  let string = ''
  const textArray = text.split(' ')
  for (const word of textArray) {
    const newString = `${string} ${word}`
    if (newString.length > maxLength) break
    string = newString
  }
  return `${string}...`
}
