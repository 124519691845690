import { createStyles } from 'hooks'

export const useStyles = createStyles(theme => ({
  main: {
    position: 'relative',
    zIndex: 1,
    width: 'fit-content',
  },
  actions: {
    position: 'absolute',
    padding: theme.spacing.xs,
    gap: theme.spacing.xs,
    bottom: 0,
    zIndex: 2,
    width: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    backgroundColor: theme.colorScheme === 'dark' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.7)',
    borderRadius: `0 0  ${theme.radius.md} ${theme.radius.md}`,
  },
  ActionIcon: {
    opacity: 1,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : 'white',
    '&:hover': {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : 'white',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.dark[2],
    },
  },
}))
