import { Image, ActionIcon, Box, Card, Text, Group } from 'components'
import { useStyles } from './styles'
import { IconDownload, IconTrash, IconBrandGoogleDrive } from '@tabler/icons-react'

interface CardFileCoverProps {
  onRemove?: () => void
  src?: string
  name?: string
  type?: string
}

export const CardFileUploaded = ({ onRemove, src, name, type }: CardFileCoverProps) => {
  const { classes } = useStyles({ type })

  return (
    <Card shadow='md' withBorder sx={{ width: '100%' }}>
      <Card.Section className={classes.cardSection}>
        <Box className={classes.actions}>
          {src ? (<ActionIcon
            variant='transparent'
            className={classes.DownloadActionIcon}
            component='a'
            href={src}
            target='_blank'
            radius='md'
            size={24}
          >
            <IconDownload />
          </ActionIcon>): null}
          {onRemove ? (<ActionIcon onClick={onRemove} className={classes.RemoveActionIcon} radius='md' size={24}>
            <IconTrash />
          </ActionIcon>) : null}

        </Box>
        {type === 'gdrive' ? (
          <Group p='md' spacing='sm' className={classes.GDriveContent}>
            <IconBrandGoogleDrive className={classes.GDriveIcon} />
            <Text>Filename</Text>
          </Group>
        ) : (
          <Image alt={name} fit='cover' mt='sm' radius='md' src={src} />
        )}
      </Card.Section>
    </Card>
  )
}
