import { IProjectButton } from './interface'
import { Link } from './styled'

export const ProjectButton = ({ projectId, text }: IProjectButton ) => {

  const url = `/projects/${projectId}`

  return (
    <Link href={url}>
      <i className='icon-common-arrow-left mr-2'/>
      {text}
    </Link>
  )
}