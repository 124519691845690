import { ServerData } from "./service/interfaces";

export const initialState = (projectId: string): {payload: ServerData} => {
  return {
    payload: { 
      moods: {
        mood_group_developed: 0,
        mood_group_got_involved: 0,
        mood_group_took_part: 0,
        mood_planning_worked: 0
      },
      project_journal: '',
      project_id: projectId,
      final_assessment_scores: [],
      journal: {id: '', value: ''},
    }
  }
}