import styled from 'styled-components'

export const PaperStyled = styled.div`
  .mantine-Paper-root {
    @media only screen and (max-width: 800px) {
      margin: -20px;
      padding: 20px;
    }
  }
`
