import styled from 'styled-components'

export const InputSelectStyled = styled.div`
  .register-form {
    .form-field {
      .Select-control {
        .Select-value {
          padding: 8px 16px 8px;
          display: flex;
        }

        .Select-input input[role='combobox'] {
          width: 100% !important;
          padding-left: 8px;
          padding-top: 16px;
        }
      }
    }
  }
`
