import { Divider, Grid, Title } from '@mantine/core'
import { Avatar, Anchor, Modal } from '@mantine/core'
import { Card } from '../../../../components/Card'
import { Flex } from '../../../../components/Flex'
import { Button } from '../../../../components/Button'
import { Text } from '../../../../components/Text'
import { Group, Collapse, Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { IconPlus, IconMinus } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { ActivityDetail } from './ActivityDetail'
import { v4 as UuidV4 } from 'uuid'
import { getInitials } from 'helpers'
import { SummaryCardProps } from '../../interfaces'
import { useMediaQuery } from '@mantine/hooks'
import Close from 'components/HeaderInfo/CloseBtn'

export const SummaryCard = ({ sessionMeetingActivities, projectName, title, tutors, projectUrl }: SummaryCardProps) => {
  const [opened, { toggle }] = useDisclosure(false)
  const [openedModal, { toggle: toggleModal }] = useDisclosure(false)
  const firstItem = sessionMeetingActivities[0]
  const collapsedContent = [...sessionMeetingActivities.slice(1)]
  const firstTutor = tutors[0]
  const otherTutors = [...tutors.slice(1)]
  const hasMoreThanOneTutor = otherTutors.length > 0
  const largeScreen = useMediaQuery('(min-width: 700px)')
  const { t } = useTranslation()

  return (
    <Card shadow='sm' padding='md' radius='md' mb='md' withBorder>
      <Flex direction='column' mt='0' mb='md'>
        <Flex mb='xs' justify='space-between' direction={largeScreen ? 'row' : 'column'}>
          <Flex direction='column'>
            <Title order={5}>{t('students_pratice')}</Title>
            <Flex direction='row'>
              <Text>{projectName} - </Text>
              <Anchor href={projectUrl} target='_blank' pl='xs'>
                {t('students_show_pratice')}
              </Anchor>
            </Flex>
          </Flex>
          <Flex
            direction='column'
            align={largeScreen ? 'end' : 'start'}
            mt={largeScreen ? '0' : 'md'}
            mb={largeScreen ? '0' : 'md'}
          >
            <Flex direction='row' align='center' justify={largeScreen ? 'end' : 'start'}>
              <Avatar radius='xl' src={firstTutor.imageUrl}>
                {getInitials(firstTutor.name)}
              </Avatar>
              <Title ml='xs' order={6}>
                {firstTutor?.name}
              </Title>
            </Flex>
            {hasMoreThanOneTutor && (
              <Anchor mt='xs' component='button' type='button' onClick={toggleModal} w='fit-content'>
                {t('students_show_team')}
              </Anchor>
            )}
          </Flex>
        </Flex>
        <Flex>
          <Flex direction='column'>
            <Title order={5}>{t('students_acticity')}</Title>
            <Text>{title}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Divider />
      <Flex direction='column' mt='md'>
        <Title order={4} mb='md'>
          {t('students_activities_')}
        </Title>
        {firstItem !== null && (
          <ActivityDetail
            planning={firstItem?.planning?.replace(/<[^>]+>/g, '')}
            title={firstItem?.title}
            specificSkillsAndContents={firstItem?.specificSkillsAndContents as []}
          />
        )}
        <Divider mb='md' mt='md' />
        {collapsedContent.length > 0 && (
          <Box mx='auto' w={'100%'}>
            <Collapse in={opened}>
              {collapsedContent?.map(activity => (
                <ActivityDetail
                  key={UuidV4()}
                  planning={activity?.planning?.replace(/<[^>]+>/g, '')}
                  title={activity.title}
                  specificSkillsAndContents={activity.specificSkillsAndContents as []}
                />
              ))}
            </Collapse>
            <Group position='center' mb={5}>
              <Button
                fullWidth={!largeScreen}
                variant='light'
                onClick={toggle}
                leftIcon={opened ? <IconMinus size='1rem' /> : <IconPlus size='1rem' />}
              >
                {opened ? t('students_show_less') : t('students_show_more')}
              </Button>
            </Group>
          </Box>
        )}
      </Flex>
      <Modal.Root
        opened={openedModal}
        onClose={toggleModal}
        zIndex={99999999999}
        size={largeScreen ? '60vw' : '80vw'}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Title order={3}>{t('students_show_team_title')}</Title>
            </Modal.Title>
            <Modal.CloseButton>
              <Close />
            </Modal.CloseButton>
          </Modal.Header>
          <Modal.Body>
            <Grid grow gutter='md'>
              {tutors?.map(tutor => (
                <Grid.Col span={largeScreen ? 4 : 6} key={UuidV4()}>
                  <Flex direction='column' align='center' justify='center'>
                    <Avatar radius='xl' src={tutor.imageUrl} size={largeScreen ? 'lg' : 'md'}>
                      {getInitials(tutor.name)}
                    </Avatar>
                    <Flex direction='column' align='center' justify='center' mt='xs'>
                      <Text fz='sm' color='#000' fw={700}>
                        {tutor?.name}
                      </Text>
                      <Text fz='sm' color='#868E96'>
                        {tutor?.educatorRole}
                      </Text>
                    </Flex>
                  </Flex>
                </Grid.Col>
              ))}
            </Grid>
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </Card>
  )
}
