import { useContext, useEffect, useRef, useState } from 'react'
import { isEqual, set } from 'lodash'
import { useTranslation } from 'react-i18next'
import { IconTemplate, IconBookUpload, IconX, IconHelpCircleFilled, IconCheck } from '@tabler/icons-react'
import {
  Group,
  Title,
  Paper,
  Stack,
  Text,
  Divider,
  TextInput,
  Select,
  Input,
  Checkbox,
  SimpleGrid,
  ActionIcon,
  Box,
  MultiSelect,
  CardCheckBox,
  RichTextArea,
  TextRequired,
} from 'components'
import { useMantineTheme, rem, useMediaQuery } from 'hooks'
import { useForm, notifications, useModals } from 'context'
import { StepperContext } from '../../context/StepperContext'
import { CoverFileProvider } from '../../context/CoverFileContext'
import { ProjectCover } from '../ProjectCover/ProjectCover'
import { ProjectHelpModals } from '../ProjectHelpModals'
import { ProjectStepsButtons } from '../ProjectStepsButtons'
import { ProjectButtonsContext } from '../../context/ProjectButtonsContext'
import { useCurrentUser } from 'context'
import {
  getProject,
  getModalitiesAndFinalProducts,
  getDevelopmentStages,
  updateProject,
  createProject,
} from 'pages/NewProject/service/projects'
import { FormValues } from './interfaces'
import { OnLoadSkeleton } from './OnLoadSkeleton'
import { isBase64, mapArrayToObject, mapGrades } from './helper'
import { MappedObject, initialValues, ProjectType } from './interfaces'
import { ProjectProps } from '../../service/interfaces'
import { ConfirmKindChangeModal } from 'components/ConfirmKindChangeModal'
import { ConfirmDevelopmentStageChangeModal } from 'components/ConfirmDevelopmentStageChangeModal'
import EvaluationToolSelect from './EvaluationToolSelect'

export function ProjectDetails() {
  const { t, i18n } = useTranslation()
  const { buttonHandlers } = useContext(ProjectButtonsContext)
  const { currentUser } = useCurrentUser()
  const { setIsTemplate, nextStep, projectId, setProjectId, setHasChanged, projectStatus, setProjectStatus, setBlock } =
    useContext(StepperContext)
  const {
    NameModal,
    GuidingQuestionModal,
    SummaryModal,
    ObjectivesModal,
    FinalProductModal,
    EvaluationModal,
    StagesModal,
  } = ProjectHelpModals()

  const [schoolId, setSchoolId] = useState<string>('')
  const [projectTypeData, setProjectTypeData] = useState<any>([])
  const [productTypeData, setProductTypeData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [ageGroupsData, setAgeGroupsData] = useState<MappedObject[]>([])
  const [grades, setGrades] = useState<any[]>([])
  const [selectedGrade, setSelectedGrade] = useState<string>('')
  const [formInitials, setFormInitials] = useState<FormValues>(initialValues)
  const statusNotShowCheck = ['approved', 'reviewing', 'published']
  const [changeKindModal, setChangeKindModal] = useState<boolean>(false)
  const [changeDevelopmentStageModal, setChangeDevelopmentStagedModal] = useState<boolean>(false)
  const [changeKind, setChangeKind] = useState<ProjectType>('application')
  const [chageGradeId, setChangeGradeId] = useState<string>('')
  const [tutorGuest, setTutorGuest] = useState<boolean>(false)
  const refToSlect = useRef(null)

  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const modals = useModals()

  const updateField = (name: string) => (value: string) => {
    form.setFieldValue(name, value)
  }

  const form = useForm<FormValues>({
    initialValues,
    validate: {
      name: value => (value.length < 3 ? t('required_field') : null),
      modality: value => (value === null ? t('required_field') : null),
      ageGroup: (value, values) =>
        (values.projectType === '2' && value?.length === 0) || (values.projectType === '2' && value === undefined)
          ? t('required_field')
          : null,
      grades: (value, values) => (values.projectType === '2' && value.length === 0 ? t('required_field') : null),
    },
  })

  const handleErrors = (errors: unknown) => {
    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: t('fill_the_required_fields'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const handleSuccess = (values: FormValues) => {
    const successMessage = projectStatus ? t('new_project_page.draft_saved') : t('new_project_page.project_saved')
    const successColor = 'green'
    const successIcon = <IconCheck size={24} />

    buttonHandlers.setState([
      { loading: values.submitButton === 1, disabled: false },
      { loading: values.submitButton === 2, disabled: true },
    ])

    notifications.show({
      message: successMessage,
      color: successColor,
      icon: successIcon,
    })

    if (values.submitButton === 2) {
      nextStep()
      return
    }

    const isTemplate = values.projectType === '2'
    const isReviewing = projectStatus === 'reviewing'

    const url = isTemplate && isReviewing ? `/projects_library/${projectId}` : `/projects/${projectId}`
    window.location.href = url
  }

  const handleForm = async (values: FormValues) => {
    const id = projectId
    setHasChanged(false)

    const shareCommunity = form.values.projectType === '2' ? true : form.values.shareCommunity
    const payload = {
      name: form.values.name,
      coverImage: form.values?.fileCover && isBase64(form.values?.fileCover) ? form.values.fileCover : null,
      kind: form.values.projectType === '1' ? 'application' : 'template',
      purpose: form.values.purpose,
      modality: form.values.modality ? form.values.modality : '',
      objectives: form.values.objectives,
      stages: form.values.stages,
      final_product_kind: form.values.finalProductKind,
      final_product_description: form.values.finalProductDescription,
      grades: form.values.grades.map((item: { id: string }) => item.id),
      share_community: shareCommunity,
      synopsis: form.values.synopsis,
      evaluation_tools_attributes: form.values.evaluationToolsAttributes,
    } as ProjectProps

    buttonHandlers.setState([
      { loading: false, disabled: true },
      { loading: true, disabled: true },
    ])

    if (id && id?.length > 0) {
      await updateProject(id, { ...payload })

      const isTemplate = form.values.projectType === '2'
      const isReviewing = projectStatus === 'reviewing'
      const url = isTemplate && isReviewing ? `/projects_library/${id}` : `/projects/${id}`

      if (form.values.submitButton === 0) return (window.location.href = url)
    } else {
      const res = await createProject(payload)
      if (res !== undefined) {
        const isTemplate = form.values.projectType === '2'
        const isReviewing = projectStatus === 'reviewing'
        const url = isTemplate && isReviewing ? `/projects_library/${res.id}` : `/projects/${res.id}`
        if (form.values.submitButton === 0) return (window.location.href = url)
        setProjectId(res.id)
      } else {
        setLoading(false)
        notifications.show({
          message: t('error'),
          color: 'red',
          icon: <IconX size={24} />,
        })
        return
      }
    }

    handleSuccess(values)
  }

  const handleTypeChange = (type: ProjectType): void => {
    if (projectId && formInitials.kind !== type) {
      setChangeKind(type)
      setChangeKindModal(true)
      return
    }
    setBlock(false)
    setChangeKind(type)
    confirmChangeType(type)
  }

  const closeModalKind = () => {
    setChangeKindModal(false)
  }

  const closeModalDevelopmentStage = () => {
    setChangeDevelopmentStagedModal(false)
  }

  const confirmChangeType = (type: ProjectType) => {
    if (projectId && formInitials.kind !== type) {
      setBlock(true)
    }

    if (type === 'application') {
      setIsTemplate(false)
      form.setFieldValue('projectType', '1')
    } else if (type === 'template') {
      setIsTemplate(true)
      form.setFieldValue('projectType', '2')
    }
  }

  const populateSelects = async () => {
    const data = await getModalitiesAndFinalProducts()

    if (data !== undefined) {
      const { modalities, finalProductKinds } = data

      if (modalities.length > 0 || modalities !== undefined) {
        setProjectTypeData(modalities)
      }
      setProductTypeData(finalProductKinds)
    }

    return data
  }

  useEffect(() => {
    if (currentUser?.selected_school?.id !== undefined && currentUser?.selected_school?.id.length > 0) {
      setSchoolId(currentUser?.selected_school?.id)
    }
  }, [currentUser])

  useEffect(() => {
    populateSelects()
  }, [i18n.language])

  const getGradesById = (id: any): any => {
    const foundElement = ageGroupsData.find((obj: any) => obj.value === id)

    if (formInitials.ageGroup && id !== formInitials.ageGroup && formInitials.ageGroup?.length > 0) {
      setChangeGradeId(id)
      setChangeDevelopmentStagedModal(true)
      return
    }

    if (id !== null) {
      form.setFieldValue('ageGroup', id)
      form.resetDirty()
      form.resetTouched()
    }

    if (foundElement !== undefined) {
      setGrades(mapGrades(foundElement.grades))
      return foundElement
    }

    return []
  }

  const showAgeWarningMessage = (id: string) => {
    const foundElement = ageGroupsData.find((obj: any) => obj.value === id)
    if (id !== null) {
      form.setFieldValue('ageGroup', id)
      form.setFieldValue('grades', [])
      form.resetDirty()
      form.resetTouched()
    }

    if (foundElement !== undefined) {
      setGrades(mapGrades(foundElement.grades))
      return foundElement
    }

    return []
  }

  useEffect(() => {
    if (ageGroupsData.length > 0) {
      getGradesById(selectedGrade)
    }
  }, [ageGroupsData, selectedGrade])

  useEffect(() => {
    const id = projectId

    if (currentUser && currentUser?.profile_used !== 'tutor' && id === null) {
      window.location.href = '/error_permission'
    }

    if (!schoolId.length) {
      return
    }

    const fetchSchoolData = async () => {
      const data = await getDevelopmentStages(schoolId)
      if (data !== undefined) {
        setAgeGroupsData(mapArrayToObject(data))
      }
    }

    const fetchAndSetProject = async (id: string) => {
      const data = await getProject(id)
      if (data) {
        form.setFieldValue('projectType', data?.kind === 'template' ? '2' : '1')
        form.setValues(data)
        form.resetDirty()
        form.resetTouched()
        setProjectId(id)
        setIsTemplate(data?.kind === 'template')
        await populateSelects()
        setSelectedGrade(data.grades[0]?.developmentStageId)
        setLoading(false)
        setProjectStatus(data.status)

        form.setFieldValue('projectType', data?.kind === 'template' ? '2' : '1')
        form.setValues(data)
        setSelectedGrade(data.grades[0]?.developmentStageId)
        const shareCommunity = data.kind === 'template' ? true : data.shareCommunity || false
        const tutorGuestStatus = data && data.tutorGuest ? data.tutorGuest : false

        setTutorGuest(tutorGuestStatus)

        if (id !== '') {
          setFormInitials({
            name: data.name || '',
            kind: data.kind || '',
            modality: data.modality || '',
            purpose: data.purpose,
            objectives: data.objectives,
            stages: data?.stages,
            projectType: data.kind === 'template' ? '2' : '1',
            finalProduct: data.finalProduct || '',
            finalProductKind: data.finalProductKind,
            finalProductDescription: data.finalProductDescription,
            grades: data.grades.map((item: { id: string }) => item.id),
            guidingQuestion: data.guidingQuestion || '',
            shareCommunity: shareCommunity,
            synopsis: data.synopsis,
            submitButton: 0,
            schoolId: '',
            schoolYear: '',
            ageGroup: data.grades[0]?.developmentStageId,
            evaluationToolsAttributes: data.evaluationTools || [],
            fileCover: data.fileCover || null,
            coverImage: data?.coverImage,
          } as FormValues)
        }
      } else {
        setFormInitials(initialValues)
      }
    }

    fetchSchoolData().then(() => {
      setLoading(true)
      const urlid = projectId
      if (projectId && urlid !== projectId) {
        window.history.replaceState(null, '', `${window.location.href}?id=${projectId}`)
      }
      id ? fetchAndSetProject(id) : populateSelects().finally(() => setLoading(false))
    })
  }, [schoolId])

  useEffect(() => {
    if (form.isDirty() === true && form.isTouched() === true && !isEqual(form.values, formInitials)) {
      setHasChanged(true)
    } else {
      setHasChanged(false)
    }
  }, [form.values])

  if (loading) {
    return <OnLoadSkeleton isMobile={isMobile} />
  }

  const setEvaluationToolsAttributes = (data: any) => {
    form.setFieldValue('evaluationToolsAttributes', data)
  }

  const openModalEvaluationTool = () => {
    modals.openModal(EvaluationModal)
  }

  return (
    <Paper radius={isMobile ? 0 : 'md'} withBorder shadow='xs' p={isMobile ? 'md' : 'xl'} mx={isMobile ? rem(-20) : 0}>
      <ConfirmKindChangeModal
        isTemplate={form.values.projectType === '2'}
        opened={changeKindModal}
        onClose={closeModalKind}
        action={() => {
          confirmChangeType(changeKind)
        }}
      />
      <ConfirmDevelopmentStageChangeModal
        isTemplate={form.values.projectType === '2'}
        opened={changeDevelopmentStageModal}
        onClose={() => setChangeDevelopmentStagedModal(false)}
        action={() => {
          showAgeWarningMessage(chageGradeId)
        }}
      />

      <form onSubmit={form.onSubmit(handleForm, handleErrors)}>
        <Title order={2}>{t('new_project_page.title_details')}</Title>
        <Text color='dimmed'>{t('new_project_page.description_details')}</Text>
        <Divider my='md' variant='solid' />
        <TextRequired />

        {!statusNotShowCheck.includes(projectStatus ?? '') && !tutorGuest && (
          <Stack mt='md' mb='md'>
            <Input.Wrapper size='md' withAsterisk label={t('new_project_page.label_what_do_you_want_to_do')}>
              <SimpleGrid mt='xs' cols={2} breakpoints={[{ maxWidth: 'lg', cols: 1, spacing: 'md' }]}>
                <CardCheckBox
                  title={t('new_project_page.title_start_project_immediately')}
                  description={t('new_project_page.description_start_project_immediately')}
                  onChange={() => handleTypeChange('application')}
                  checked={form.values.projectType === '1'}
                  icon={<IconBookUpload size={40} />}
                />
                <CardCheckBox
                  title={t('new_project_page.title_create_a_template')}
                  description={t('new_project_page.description_create_a_template')}
                  checked={form.values.projectType === '2'}
                  onChange={() => handleTypeChange('template')}
                  icon={<IconTemplate size={40} />}
                />
              </SimpleGrid>
            </Input.Wrapper>
          </Stack>
        )}

        <Stack spacing='lg'>
          <TextInput
            size='md'
            name='name'
            label={
              <Group spacing='xs'>
                <Text>
                  {t('new_project_page.label_project_name')}{' '}
                  <Text color='red' display='inline'>
                    *
                  </Text>
                </Text>
                <Group>
                  <ActionIcon onClick={() => modals.openModal(NameModal)}>
                    <IconHelpCircleFilled />
                  </ActionIcon>
                </Group>
              </Group>
            }
            {...form.getInputProps('name')}
          />

          <Select
            size='md'
            name='modality'
            clearable
            withAsterisk
            label={t('new_project_page.label_project_type')}
            data={projectTypeData.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label))}
            {...form.getInputProps('modality')}
            value={form.values.modality}
          />
          <TextInput
            size='md'
            name='purpose'
            label={
              <Group spacing='xs'>
                <Text>{t('new_project_page.label_guiding_question')}</Text>
                <Group>
                  <ActionIcon onClick={() => modals.openModal(GuidingQuestionModal)}>
                    <IconHelpCircleFilled />
                  </ActionIcon>
                </Group>
              </Group>
            }
            {...form.getInputProps('purpose')}
          />
          <RichTextArea
            size='md'
            name='synopsis'
            label={
              <Group spacing='xs'>
                <Text>{t('new_project_page.label_summary')}</Text>
                <Group>
                  <ActionIcon onClick={() => modals.openModal(SummaryModal)}>
                    <IconHelpCircleFilled />
                  </ActionIcon>
                </Group>
              </Group>
            }
            minHeight={160}
            maxHeight={400}
            {...form.getInputProps('synopsis')}
            onChange={updateField('synopsis')}
          />
          <RichTextArea
            size='md'
            name='objectives'
            label={
              <Group spacing='xs'>
                <Text>{t('new_project_page.label_objectives')}</Text>
                <Group>
                  <ActionIcon onClick={() => modals.openModal(ObjectivesModal)}>
                    <IconHelpCircleFilled />
                  </ActionIcon>
                </Group>
              </Group>
            }
            minHeight={160}
            maxHeight={400}
            {...form.getInputProps('objectives')}
            onChange={updateField('objectives')}
          />

          <RichTextArea
            size='md'
            name='stages'
            label={
              <Group spacing='xs'>
                <Text>{t('new_project_page.stages')}</Text>
                <Group>
                  <ActionIcon onClick={() => modals.openModal(StagesModal)}>
                    <IconHelpCircleFilled />
                  </ActionIcon>
                </Group>
              </Group>
            }
            minHeight={160}
            maxHeight={400}
            {...form.getInputProps('stages')}
            onChange={updateField('stages')}
          />

          <EvaluationToolSelect
            openModalEvaluationTool={openModalEvaluationTool}
            refToSelect={refToSlect}
            setEvaluationToolsAttributes={setEvaluationToolsAttributes}
            initialValues={formInitials.evaluationToolsAttributes || []}
          />

          {form.values.projectType === '1' && (
            <>
              <Select
                ref={refToSlect}
                clearable
                size='md'
                name='finalProductKind'
                label={
                  <Group spacing='xs'>
                    <Text>{t('new_project_page.label_final_product')}</Text>
                    <Group>
                      <ActionIcon onClick={() => modals.openModal(FinalProductModal)}>
                        <IconHelpCircleFilled />
                      </ActionIcon>
                    </Group>
                  </Group>
                }
                data={productTypeData.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label))}
                {...form.getInputProps('finalProductKind')}
                rightSectionWidth={80}
              />
              <RichTextArea
                size='md'
                name='finalProductDescription'
                label={t('new_project_page.label_final_product_description')}
                minHeight={160}
                maxHeight={400}
                disabled={form.values?.finalProductKind?.length === 0 || !form.values?.finalProductKind}
                {...form.getInputProps('finalProductDescription')}
                value={form.values?.finalProductKind !== null ? form.values.finalProductDescription : ''}
              />
            </>
          )}

          <Stack>
            <Input.Wrapper
              size='md'
              label={t('new_project_page.label_cover')}
              description={t('new_project_page.description_cover')}
            >
              <Box mt='xs'>
                <CoverFileProvider>
                  <ProjectCover id={projectId || ''} onChange={updateField('fileCover')} />
                </CoverFileProvider>
              </Box>
            </Input.Wrapper>
          </Stack>
          {form.values.projectType === '1' && (
            <Input.Wrapper
              size='md'
              label={t('new_project_page.label_share_project')}
              description={t('new_project_page.description_share_project')}
            >
              <Checkbox
                name='shareCommunity'
                mt='xs'
                size='md'
                label={t('new_project_page.share_project_checkbox')}
                {...form.getInputProps('shareCommunity')}
                checked={form?.values.shareCommunity}
              />
            </Input.Wrapper>
          )}
          {form.values.projectType === '2' && (
            <Stack>
              <Select
                required
                withAsterisk
                name='ageGroup'
                size='md'
                label={t('new_project_page.label_suggested_age_group')}
                description={t('new_project_page.description_suggested_age_group')}
                data={ageGroupsData.sort((a, b) => a.label.localeCompare(b.label))}
                {...form.getInputProps('ageGroup')}
                onChange={getGradesById}
              />
              <MultiSelect
                required
                withAsterisk
                name='grades'
                size='md'
                label={t('new_project_page.label_suggested_school_year')}
                description={t('new_project_page.description_suggested_school_year')}
                disabled={form.values?.ageGroup?.length === 0 || !form.values?.ageGroup}
                data={grades.sort((a, b) => a.label.localeCompare(b.label))}
                {...form.getInputProps('grades')}
                value={form.values.grades.map((item: { id: string }) => item.id)}
                onChange={(selectedItems: string[]) => {
                  form.setFieldValue(
                    'grades',
                    selectedItems.map((item: string) => ({ id: item })),
                  )
                }}
              />
            </Stack>
          )}
        </Stack>
        <ProjectStepsButtons formAction={form.setFieldValue} />
      </form>
    </Paper>
  )
}
