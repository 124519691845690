import styled from 'styled-components'

export const PaperStyled = styled.div`
  width: 54rem;

  @media only screen and (max-width: 800px) {
    max-width: auto;
  }

  .mantine-Paper-root {
    @media only screen and (max-width: 800px) {
      padding: 20px;
    }
  }
`
