import Colors from '@legacy/common/Constants/Colors'
import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${Colors.primary.white};
  border: 1px solid ${Colors.primary.lightGray};
  box-sizing: border-box;
  border-radius: 60px;
  padding: 11px 24px;
  margin-top: 14px;
  align-items: center;
`

export const SearchInput = styled.input`
  border: 0;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;
  &:focus {
    outline: 0;
  }
`
