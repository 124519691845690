import { api } from 'helpers'
import { AssessmentScoreList } from '../interfaces'
import { IGetAssessmentOptions } from './interfaces'

export const finalAssessmentUrl = (urlId: string) => `/api/final_assessments/${urlId}/list_final_assessment_scores`
export const assessmentUrl = (urlId: string) => `/api/session_meetings/${urlId}/list_assessment_scores`

export const getListAssessmentScores = async ({ urlId, params, type }: IGetAssessmentOptions) => {
  const reqUrl = type === 'assessment' ? assessmentUrl(urlId) : finalAssessmentUrl(urlId)
  return api.get<AssessmentScoreList>(reqUrl, { params })
}
