export default {
  primary: {
    darkYellow: '#DAA52C',
    yellowMedium: '#F7BC3B',
    yellow: '#FFC132',
    lightyellow: '#FFDA84',
    lighterYellow: '#FFE6AD',
    darkenPurple: '#131D2C',
    darkPurple: '#203049',
    lightPurple: '#4D596D',
    black: '#121019',
    darkerGray: '#625F6D',
    mediumGray: 'var(--color-gray)',
    gray: 'var(--color-gray)',
    lightGray: 'var(--color-gray-light)',
    lighterGray: '#F4F6F9',
    white: '#FFFFFF',
    darkerYellow: '#C39222',
    blue: '#4f63f1',
    primary: 'var(--color-primary)',
    primaryDark: 'var(--color-primary-dark)',
    primaryUltraDark: 'var(--color-primary-ultradark)',
  },
  secondary: {
    darkRed: '#B81E14',
    red: '#E5251A',
    lightRed: '#F5A8A3',
    lighterRed: '#FAD4D1',
    alertDarkGreen: '#14B858',
    alertGreen: '#1AE56F',
    alertLightGreen: '#A3F5C5',
    alertLighterGreen: '#D1FAE2',
  },
}
