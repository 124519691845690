import styled from 'styled-components'
import { Avatar } from 'components/Avatar'

export const ProfileContainerStyled = styled.div`
  margin-right: 0;
  border: 3px solid var(--color-white);
  border-radius: 50%;
  &:focus-visible {
    outline: 0px;
    border: none;
  }
  img {
    max-width: 50px;
  }
`
export const AvatarStyled = styled(Avatar)``
