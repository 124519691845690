import { Label } from './scoreItemStyled'
import { useMemo } from 'react'
import { ScoreItemHandAsset } from './ScoreItemHandAsset'
import { useScoreItemVariant } from './ScoreItemHook'

export type ScoreItemLabel = 0 | 1 | 2 | 3 | 4 | 5;
export type ScoreItemVariant = 'number' | 'hand';
export type ScoreItemStates = {
  readonly disabled?: boolean;
  readonly active?: boolean;
};

export type ScoreItemProps = ScoreItemStates & {
  readonly label: ScoreItemLabel;
  readonly variant?: ScoreItemVariant;
};

export const ScoreItem = ({
  disabled,
  label,
  active,
  ...rest
}: ScoreItemProps) => {
  const variant = useScoreItemVariant(rest.variant)
  const Component = useMemo(
    () => (variant === 'hand' ? ScoreItemHandAsset : Label),
    [variant],
  )

  return (
    <Component active={!!active} disabled={!!disabled}>
      {label}
    </Component>
  )
}
