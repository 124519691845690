/* eslint-disable react/no-danger */
import React, { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Grid, Title } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { IStudentCycle } from '../../../models/student'
import { InputSelect, InputSelectAsync, Loading } from '../../../_components'
import servicePaths from '../../../services/service-paths'
import BlankSlate from '../../../_atoms/BlankSlate'

import {
  StyledFiltersBlock,
  StyledStudentReportFrame,
} from './reports-student-styled'
import { useReportsStudent } from './reports-student-hook'
import { IInputSelectOption } from './reports-student-interfaces'
import { IGrade } from '../reports_group/reports-group-interfaces'
import { useCurrentUser } from 'context'
import { SchoolPeriodType } from '@legacy/common/models/school'
import { Heading } from 'components'

export function ReportsStudent() {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const isLumiarSchool = useMemo(
    () => currentUser?.selected_school?.period_type === SchoolPeriodType.Cycle,
    [currentUser]
  )
  const hook = useReportsStudent(isLumiarSchool)

  if (!currentUser || hook.students.loading) {
    return <Loading />
  }

  if (currentUser?.profile_used === 'tutor' && currentUser.group_school_terms_count === 0) {
    return window.location.replace('/teacher/dashboard')
  }

  return (
    <>
      <div className='mb-5'>
        <Heading
          size='xl'
          text={t('reports_dashboard.index.students_title')}
          supportText={t('reports_dashboard.index.students_description')}
        ></Heading>
      </div>
      <div>
        <StyledFiltersBlock>
          <Title order={5} mb='md'>
            {t('reports_dashboard.index.filters_title')}
          </Title>
          <Grid gutter='sm'>
            <Grid.Col span={6}>
              <InputSelectAsync
                isSearchable
                label={t('activerecord_models_student_one')}
                id='students_report_student'
                name='student'
                value={hook.selected.studentId}
                options={hook.students.data}
                url={servicePaths.students.filters.byName}
                labelField='full_name'
                valueField='student_id'
                onChange={hook.selectStudent}
                onReset={hook.resetStudent}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              {isLumiarSchool ? (
                <InputSelect
                  clearable={false}
                  id='select-student-report-cycle'
                  value={hook.selected.cycleId ?? ''}
                  name='studentReportCycle'
                  label={t('activerecord_models_cycle_one')}
                  onChange={({ value }: { value: string }) =>
                    hook.changeSelectedCycleId(value)
                  }
                  values={hook.cycles}
                  disabled={isEmpty(hook.selected.studentId)}
                />
              ) : (
                <InputSelect
                  clearable={false}
                  id='select-student-report-grade'
                  value={hook.selected.gradeId ?? ''}
                  name='studentReportGrade'
                  label={t('activerecord_models_grade_one')}
                  onChange={({ value }: { value: string }) =>
                    hook.changeSelectedGradeId(value)
                  }
                  values={hook.grades}
                  disabled={isEmpty(hook.selected.studentId)}
                />
              )}
            </Grid.Col>
            <Grid.Col span={6}>
              <InputSelect
                clearable={false}
                id='select-student-report-lvl1'
                value={hook.selected.level1}
                name='studentReportLevel1'
                label={t('activerecord_models_kinds_content_area_one_tiny')}
                onChange={({ value }: { value: string }) =>
                  hook.changeSelectedLevel1(value)
                }
                values={hook.lv1}
                disabled={isEmpty(hook.selected.studentId)}
              />
            </Grid.Col>
          </Grid>
        </StyledFiltersBlock>
        {isEmpty(hook.selected.studentId) ? (
          <BlankSlate message={t('swal_student_report_blank_slate')} />
        ) : (
          <>
            <StyledStudentReportFrame
              src={hook.url}
              frameBorder={0}
              scrolling='no'
            />
            <StyledFiltersBlock>
              <h5>{t('reports_dashboard.index.students_legend_title')}</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('reports_dashboard.index.students_legend'),
                }}
              />
            </StyledFiltersBlock>
          </>
        )}
      </div>
    </>
  )
}

export function fromCycleToOption(cycle: IStudentCycle): IInputSelectOption {
  return {
    label: cycle.name,
    id: cycle.id,
  }
}

export function fromGradeToOption(grade: IGrade): IInputSelectOption {
  return {
    label: grade.name,
    id: grade.id,
  }
}
