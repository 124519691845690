import { IPagination } from './IPagination'
import { ILesson } from './ILesson'
export interface ILessons {
  readonly schoolTermStartAt: string
  readonly schoolTermEndAt: string
  readonly sessionMeetings: ILesson[]
  readonly pagination: IPagination
}
export const initialLessons = {
  schoolTermStartAt: '',
  schoolTermEndAt: '',
  sessionMeetings: [] as ILesson[],
  pagination: {} as IPagination,
}
