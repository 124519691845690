import { useTranslation } from 'react-i18next'
import { Card, Text, Box, BackgroundImage, Badge, Stack, Anchor } from 'components'
import { useStyles } from './styles'
import { ProjectCardProps } from './interfaces'
import { useLocalizedDate } from 'hooks'
import { ProjectCardBadges } from './components'
import { ProjectCardSkillsContentsBadges } from './components/ProjectCardSkillsContentsBadges'

export const ProjectCard = ({ project, badges, actions, showPurpose, projectList }: ProjectCardProps) => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()
  const formatLocalizedDate = useLocalizedDate(i18n.language || 'pt-BR')

  const dateEn = project.lastUpdate.split('/').reverse().join('-')

  const getGroupName = (groupName: string) => {
    if (groupName === '(prática sem turma)' || groupName === '(project without group)' || groupName === '') {
      return t('new_projects_list.no_group')
    }
    return groupName
  }

  const projectURL = `/projects/${project.id}`

  const canShowPurpose = showPurpose || showPurpose === undefined

  return (
    <Card shadow='md' withBorder p='sm' className={classes.root}>
      <Card.Section p={0} className={classes.header}>
        <BackgroundImage h={140} src={project.coverImage}>
          {badges && badges.length > 0 && <ProjectCardBadges badgeList={badges} />}
        </BackgroundImage>
      </Card.Section>
      <Box className={classes.content}>
        <Box mb='md' className={classes.contentBox}>
          <Stack spacing='sm' pt='sm' className={classes.contentStack}>
            <Stack spacing={0}>
              <Anchor href={projectURL}>
                <Text size='lg'>{project.name}</Text>
              </Anchor>
            </Stack>
            <Stack spacing={0}>
              <Text tt='uppercase' size='xs' color='dimmed'>
                {t('new_projects_list.label_group')}
              </Text>
              <Text size='md'>{getGroupName(project.groupName)}</Text>
            </Stack>

            {canShowPurpose && project.purpose && project.purpose !== '' && project.purpose !== null && (
              <Stack spacing={0}>
                <Text tt='uppercase' size='xs' color='dimmed'>
                  {t('new_projects_list.label_purpose')}
                </Text>
                <Text size='md'>{project.purpose}</Text>
              </Stack>
            )}
            {project.lv1Items && project.lv1Items.length > 0 ? (
              <Stack spacing={0}>
                <Text tt='uppercase' size='xs' color='dimmed'>
                  {t('new_projects_list.label_specific_skills_and_contents')}
                </Text>
                <ProjectCardSkillsContentsBadges skillsAndContents={project.lv1Items} />
              </Stack>
            ) : null}
            {project.status === 'draft' && project.lastUpdate !== '' && project.lastUpdate !== null && (
              <Stack spacing={0}>
                <Text tt='uppercase' size='xs' color='dimmed'>
                  {t('new_projects_list.label_last_update')}
                </Text>
                <Text size='md'>{formatLocalizedDate(dateEn)}</Text>
              </Stack>
            )}
          </Stack>
        </Box>
        {actions}
      </Box>
    </Card>
  )
}
