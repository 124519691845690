import { useEffect, useContext } from 'react'
import { useCurrentUser } from 'context'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { useTranslation } from 'react-i18next'
import { carouselDashboard, useCaroselStyles } from 'components/Carousel'
import {
  Space,
  Flex,
  Text,
  Carousel,
  GroupCard,
  AssessmentModal,
  NextTaskCard,
  DashboardHeader,
} from 'components'
import { StudentDashboardContext } from '../../context/StudentDashboardContext'
import { IconChecklist, IconUsers } from '@tabler/icons-react'
import { StudentDashBoardOnLoad } from '../StudentDashboardSkeleton'
import { getMyGroups, getMySessionMeetings } from 'api/studentsDashboard/service'
import { v4 as UuidV4 } from 'uuid'
import { MeetingAssessmentForStudent } from 'components/MeetingAssessmentForStudent'
import { IGroup } from 'interfaces'

export const StudentDashboard = () => {
  const {
    groups,
    setGroups,
    lessons,
    setLessons,
    loading,
    setLoading,
    handleJournalClick,
    assessmentID,
    assessmentModalOpened,
    assessmentModalClose,
    modalTitle,
    setShowConfirmationModal,
    updateModalTitle,
    setModalHasChanges,
    showConfirmationModal,
    UpdateShowModal,
    projectURL,
  } = useContext(StudentDashboardContext)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const { classes } = useCaroselStyles()

  const loadDashBoardData = async () => {
    const [groupsResponse, meetingsResponse] = await Promise.all([getMyGroups(), getMySessionMeetings()])
    setGroups(groupsResponse)
    setLessons(meetingsResponse)
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    loadDashBoardData()
  }, [])

  if (loading) {
    return <StudentDashBoardOnLoad />
  }

  const showActivitiesIndicator: boolean = (isMobile && lessons.length > 2) || lessons.length > 3
  const showGroupsIndicator: boolean = (isMobile && groups.length > 2) || groups.length > 3

  if (lessons === null) return null

  return (
    <>
      <AssessmentModal
        assessmentModalOpened={assessmentModalOpened}
        assessmentModalClose={assessmentModalClose}
        modalTitle={modalTitle}
        content={
          <MeetingAssessmentForStudent
            id={assessmentID}
            onClose={() => {
              setShowConfirmationModal(false)
              assessmentModalClose()
            }}
            updateModaTitle={updateModalTitle}
            setModalHasChanges={setModalHasChanges}
            toggleConfirmationModal={showConfirmationModal}
            UpdateShowModal={UpdateShowModal}
            projectUrl={projectURL ? projectURL : ''}
            loadDashBoardData={loadDashBoardData}
          />
        }
      />
      <DashboardHeader name={currentUser?.full_name} isMobile={isMobile} />
      {lessons.length > 0 ? (
        <>
          <Flex mt={isMobile ? 0 : 80}>
            <IconChecklist />
            <Space w='xs' />
            <Text fz='lg' fw={700}>
              {t('students_next_activities')}
            </Text>
          </Flex>
          <Space h='xs' />
        </>
      ) : (
        <></>
      )}

      {lessons.length > 0 && (
        <Carousel
          {...carouselDashboard}
          withIndicators={showActivitiesIndicator}
          withControls={showActivitiesIndicator}
          draggable={lessons.length > 1}
          classNames={{
            root: classes.carousel,
            controls: classes.carouselControls,
          }}
        >
          {lessons?.map(lesson => (
            <Carousel.Slide key={UuidV4()}>
              <NextTaskCard
                projectName={lesson.projectName}
                lessonName={lesson.lessonName}
                date={lesson.date}
                time={lesson.hour}
                canFillEditReport={lesson.canFillEditReport || false}
                activities={lesson.activities}
                status={lesson.status}
                handleJournalClick={() => handleJournalClick(lesson.editReportUrl, lesson.projectUrl)}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      )}

      {groups.length > 0 && (
        <>
          {lessons.length > 0 && <Space h='lg' />}
          <Flex mt={lessons.length === 0 ? 80 : 0}>
            <IconUsers />
            <Space w='xs' />
            <Text fz='lg' fw={700}>
              {t('students_my_classes')}
            </Text>
          </Flex>
          <Space h='xs' />
        </>
      )}

      <Carousel
        {...carouselDashboard}
        draggable={groups.length > 1}
        classNames={{
          root: classes.carousel,
          controls: classes.carouselControls,
        }}
        withControls={showGroupsIndicator}
        withIndicators={showGroupsIndicator}
      >
        {groups?.map(item => {
          const group = {
            id: item.group,
            name: item.group,
            grade: item.grade,
            cycle: item.cycle,
          } as IGroup

          return (
            <Carousel.Slide key={UuidV4()}>
              <GroupCard group={group} showLink={false} byCycle={currentUser?.by_cycle || false} />
            </Carousel.Slide>
          )
        })}
      </Carousel>
    </>
  )
}
