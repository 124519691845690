import { useReducer, useRef } from 'react'
import TabContext from './students-context'
import { filterStudents } from '../../../api/students/service'
import { reducer } from './reducer'
import { TabProviderProps, initialState } from '../types/student-types'

const TabProvider = (props: TabProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const prevSearch = useRef<string | null>(state.search)
  const prevActivePage = useRef<number>(state.activePage)
  const prevActiveTab = useRef<number | null>(state.activeTab)

  const changeTab = (tab: number) => {
    if (tab === prevActiveTab.current) return

    dispatch({ type: 'CHANGE_PAGE', payload: 1 })
    dispatch({ type: 'CHANGE_TAB', payload: tab })
    prevActiveTab.current = tab
    getStudents(tab, 1, state.search)
  }

  const changePage = (page: number) => {
    if (page !== prevActivePage.current && state.activeTab !== null) {
      dispatch({ type: 'CHANGE_PAGE', payload: page })
      prevActivePage.current = page
      getStudents(state.activeTab, page, state.search)
    }
  }

  const searchStudentByName = (search: string) => {
    if (search !== prevSearch.current && state.activeTab !== null) {
      const query = search.length !== 1 ? search : null

      dispatch({ type: 'SEARCH', payload: query })
      prevSearch.current = search
      getStudents(state.activeTab, 1, query)
    }
  }

  const getStudents = async (status: number, currentPage: number, query: string | null) => {
    const { data } = await filterStudents(status, currentPage, query)

    dispatch({ type: 'LOAD_STUDENTS', payload: data })
  }

  return (
    <TabContext.Provider
      value={{
        changeTab,
        searchStudentByName,
        changePage,
        activeTab: state.activeTab,
        activePage: state.activePage,
        students: state.students,
        pagination: state.pagination,
        meta: state.meta,
        loading: state.loading,
        actions: state.actions,
      }}
    >
      {props.children}
    </TabContext.Provider>
  )
}

export default TabProvider
