import { createStyles } from 'hooks'

export const useStyles = createStyles(theme => ({
  stack: {
    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[4]}`,
    padding: `${theme.spacing.sm} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
  },
  icon: {
    color: theme.colors.gray[6],
  },
}))
