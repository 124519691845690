import styled from 'styled-components'

export const StudentCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  max-height: 56px;
  box-sizing: border-box;
  cursor: pointer;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: 24px auto;
  flex-direction: row;
  align-items: center;

  p {
    margin-left: 8px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    line-height: 1;
  }
`
