import { isEmpty } from 'lodash'
import ReactPlayer from 'react-player'
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Paper, Group, Box, Text } from 'components'
import { UserData } from './components/UserData'
import { useStyles } from './styles'
import { PostActions } from './components/PostActions'
import StudentList from './components/StudentList/StudentList'
import { useTranslation } from 'react-i18next'
import { IFeedCard } from './interfaces'

export default trackWindowScroll<IFeedCard>(function FeedCard({ feed, scrollPosition }) {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const kind = feed.observationFiles[0]?.fileType
  const description = feed.description
  const userImage = feed.author.avatar
  const mediaSrc = feed.observationFiles[0]?.downloadUrl
  const userName = `${feed.author.firstName} ${feed.author.lastName}`
  const students = feed.students
  const calcFeedDate = isEmpty(feed.datePublished)
    ? `${feed.dateDraft} ${t('feed_at')} ${feed.hourDraft}`
    : `${feed.datePublished} ${t('feed_at')} ${feed.hourPublished}`
  const updateAt = calcFeedDate

  return (
    <Paper shadow='md' radius='md' p='md'>
      <Group position='apart'>
        <UserData userName={userName} image={userImage} updatedAt={updateAt} />
        <PostActions feed={feed} />
      </Group>
      {!isEmpty(mediaSrc) && (
        <Box className={classes.mediaBlock}>
          {kind === 'image' && (
            <LazyLoadImage
              src={mediaSrc}
              id={mediaSrc}
              height={'100%'}
              width={'100%'}
              effect='blur'
              // scrollPosition={scrollPosition}
              alt={description}
            />
          )}
          {kind === 'video' && (
            <ReactPlayer url={mediaSrc} playing={false} height='100%' width='100%' controls alt={description} />
          )}
        </Box>
      )}

      {students.length > 0 && <StudentList students={students} />}
      <Text size='md' mt='md'>{description}</Text>
    </Paper>
  )
})
