import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { NotFound } from './components/NotFound'

export const NotFoundPage = () => {
  return (
    <CurrentUserContextProvider>
      <NotFound />
    </CurrentUserContextProvider>
  )
}
