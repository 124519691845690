export interface IPagination {
  readonly scaffoldUrl: string
  readonly firstUrl: string
  readonly prevUrl: string
  readonly pageUrl: string
  readonly nextUrl: string
  readonly lastUrl: string
  readonly count: number
  readonly page: number
  readonly items: number
  readonly vars: {
    readonly page: number
    readonly items: number
    readonly outset: number
    readonly size: number[]
    readonly pageParam: string
    readonly params: string[]
    readonly fragment: string
    readonly linkExtra: string
    readonly i18nKey: string
    readonly cycle: false
    readonly metadata: string[]
    readonly count: number
  }
  readonly pages: number
  readonly last: number
  readonly in: number
  readonly from: number
  readonly to: number
  readonly prev: null
  readonly next: null
  readonly series: string[]
}
