import { useContext } from 'react'
import { Button, Checkbox, Box, Stack, Tooltip, Group, Input } from 'components'
import { isEmpty } from 'lodash'
import { FileUploader } from './components/FileUploader'
import { FeedTextArea } from './components/FeedTextArea'
import { useTranslation } from 'react-i18next'
import { IconHelpCircleFilled } from '@tabler/icons-react'
import { FeedStatus } from 'pages/Feed/serviceFeed'
import { FeedDrawerContext } from 'pages/Feed/contexts/FeedDrawerContext'
import { notifications } from 'context'
import { IconX } from '@tabler/icons-react'
import { useStyles } from './styles'


export const StepPageOne = () => {
  const { t } = useTranslation()
  
  const { onCloseDrawer } = useContext(FeedDrawerContext)

  const {
    setTextDescription,
    setFeedStatus,
    textAreaValue,
    errorLength,
    feedStatus,
    setHeaderText,
  } = useContext(FeedDrawerContext)

  const { classes } = useStyles({ errorLength })


  const { sendFileLoader, setStepPage, setTextAreaError, stepPage, textAreaError } = useContext(FeedDrawerContext)

  const onClickButton = () => {
    const errorText = t('fill_the_required_fields')

    if (isEmpty(textAreaValue) || textAreaValue.length > 300) {
      setTextAreaError(true)
      notifications.show({
        title: t('general_error_title'),
        message: errorText,
        color: 'red',
        icon: <IconX size={24} />,
      })
    } else {
      setTextAreaError(false)
      setStepPage(2)
      setHeaderText(t('select_students'))
    }
  }

  if (stepPage === 1) {
    return (
      <Stack spacing='sm' className={classes.content}>
        <Box>
          <FeedTextArea
            value={textAreaValue}
            onChange={(value: string) => {
              setTextDescription(value)
            }}
            placeholder={t('post_description')}
            error={textAreaError}
            validationText={errorLength ? '' : t('required_field')}
          />
        </Box>
        <Input.Wrapper size='md' label={t('feed_file')} description={t('feed_file_description')}>
          <FileUploader />
        </Input.Wrapper>
        <Checkbox
          labelPosition='right'
          checked={!!feedStatus}
          id='feedStatus'
          size='md'
          label={
            <Group spacing='xs'>
              {t('feed_automatically_publish')}
              <Tooltip position='top' label={t('feed_doubt')} withArrow withinPortal>
                <IconHelpCircleFilled />
              </Tooltip>
            </Group>
          }
          onChange={() => {
            const status = feedStatus === FeedStatus.Draft ? FeedStatus.Published : FeedStatus.Draft
            setFeedStatus(status)
          }}
        />
        <Box className={classes.footer}>
          <Button variant='default' size='lg' onClick={() => onCloseDrawer(false)}>
            {t('general_buttons_cancel')}
          </Button>
          <Button size='lg' onClick={onClickButton} disabled={errorLength || sendFileLoader}>
            {t('select_students')}
          </Button>
        </Box>
      </Stack>
    )
  }

  return <></>
}
