import { useContext } from 'react'
import { Modal, Stack, Box, Text } from 'components'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { IDescriptor } from '../ProjectSkillsStandards/interfaces'
import { useTranslation } from 'react-i18next'

export const MoreInfoSkillsStandardsModal = () => {
  const { t } = useTranslation()
  const { infoModalopened, infoModalClose, currentInfo } = useContext(SkillsStandardsContext)

  return (
    <Modal opened={infoModalopened} onClose={infoModalClose} title={t('new_project_page.more_info')} centered>
      {currentInfo?.descriptors && currentInfo.descriptors.length > 0 ? (
        <Stack spacing='xs'>
          <Box>
            {currentInfo.originalCodes ? (
              <Text span fw={500}>
                {currentInfo.originalCodes} -{' '}
              </Text>
            ) : (
              ''
            )}
            {currentInfo.name ? <Text span>{currentInfo.name}</Text> : null}
          </Box>
          {currentInfo.descriptors && currentInfo.descriptors.length > 0
            ? currentInfo.descriptors.map((descriptor: IDescriptor) =>
              descriptor.description != '' ? <Text key={descriptor.id}>{descriptor.description}</Text> : null,
            )
            : null}
        </Stack>
      ) : null}
    </Modal>
  )
}