import fetch from 'isomorphic-fetch'
import _ from 'lodash'

import ErrorService from './ErrorService'

export function fetchUrl(url, callback, error = undefined) {
  if (!_.startsWith(url, '/')) url = `/${url}`
  fetch(url, {
    credentials: 'same-origin',
  })
    .then(response => response.text())
    .then(callback)
    .catch(error => {
      ErrorService.notice(error)
      return error
    })
}

export function submitUrl(url, id, data, success, errors) {
  return request(`/${url}${id ? `/${id}` : ''}`, id ? 'PATCH' : 'POST', success, errors, data)
}

export function deleteUrl(url, id, success, errors) {
  if (id) url = `${url}/${id}`
  return request(url, 'DELETE', success, errors)
}

export function postUrl(url, data, success, errors) {
  return request(url, 'POST', success, errors, data)
}

export async function postFileUrl(url, data, success, errors, onProgress, method = 'POST') {
  const xhr = new window.XMLHttpRequest()

  xhr.upload.addEventListener(
    'progress',
    evt => {
      if (evt.lengthComputable) {
        const percentComplete = Math.round((evt.loaded / evt.total) * 100)

        onProgress(percentComplete)
      }
    },
    false,
  )

  xhr.onreadystatechange = function () {
    if (xhr.readyState !== 4) return

    if (xhr.status >= 200 && xhr.status < 300) {
      success(xhr)
    } else {
      errors(xhr)
    }
  }

  xhr.open(method, url)
  // xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
  xhr.send(data)
}

export function patchUrl(url, data, success, errors) {
  return request(url, 'PATCH', success, errors, data)
}

export function range(start, end) {
  const ans = []
  for (let i = start; i <= end; i++) {
    ans.push(i)
  }
  return ans
}

export function buildUrl(url, params = {}) {
  return (url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(params))
}

function queryParams(params) {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
}

export function postFileHeaders() {
  return {
    // 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
  }
}

function request(url, method, success = () => {}, errors = () => {}, data = {}, headers = {}, asJson = true) {
  let response = null
  if (Object.entries(headers).length == 0) {
    headers = {
      'Content-Type': 'application/json',
      'Content-Disposition': 'form-data; name="photo"',
      'X-Requested-With': 'XMLHttpRequest',
      // 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
    }
  }

  return fetch(url, {
    method,
    headers,
    body: asJson ? JSON.stringify(data) : data,
    credentials: 'same-origin',
  })
    .then(res => {
      response = res
      if (res.status < 400) return res
      const contentType = res.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return res.json()
      }
      return res.text()
    })
    .then(param => {
      if (response.status < 400) success(param)
      else if (param.message === undefined) errors(param.errors || param)
      else errors(param.errors, param.message)
    })
    .catch(error => {
      ErrorService.notice(error)
      switch (error.message) {
        case 'Failed to fetch':
        case 'Timeout':
        case 'Network request failed':
          defaultSwal({ title: I18n.t('swal.error.title'), text: I18n.t('swal.error.network_error') })
      }

      throw error
    })
}

export function updateOnTimeout({ timeout, updateFunction, miliseconds = 500 }) {
  if (timeout) {
    clearTimeout(timeout)
  }

  return setTimeout(() => {
    updateFunction()
  }, miliseconds)
}

export const deleteWithConfirmation = ({ title, text, deleteUrl, confirmCallback, successCallback, errorCallback }) => {
  confirmationSwal({
    title,
    text,
    dangerMode: true,
  }).then(willDelete => {
    if (willDelete) {
      if (confirmCallback) confirmCallback()

      request(
        deleteUrl,
        'DELETE',
        success => {
          switch (success.status) {
            case 204: // No content
              if (successCallback) return successCallback()

              break
            default:
              success.json().then(body => {
                defaultSwal({ title: body.title, text: body.text }).then(() => {
                  if (successCallback) return successCallback()

                  location.reload()
                })
              })
          }
        },
        error => {
          if (errorCallback) {
            return errorCallback(error)
          }
        },
      )
    }
  })
}

window.fetchUrl = fetchUrl
window.buildUrl = buildUrl

export function loadJson(jsonResponse) {
  if (typeof jsonResponse == 'string' && jsonResponse.includes('(erro')) return []

  const parsedObject = JSON.parse(jsonResponse)
  if (!_.isArray(parsedObject)) return convertObjectToCamel(parsedObject)
  return parsedObject.map(object => convertObjectToCamel(object))
}

export function convertObjectToCamel(object) {
  const newObject = {}
  Object.keys(object).map(key => {
    let value = object[key]
    if (_.isObject(value) && !_.isArray(value)) value = convertObjectToCamel(value)
    if (_.isArray(value) && value.length > 0 && _.isObject(value[0])) {
      value = value.map(valueItem => convertObjectToCamel(valueItem))
    }

    newObject[snakeToCamel(key)] = value
  })
  return newObject
}

function snakeToCamel(string) {
  if (string[0] === '_') return string

  return string.replace(/(_\w)/g, word => word[1].toUpperCase())
}
