import { useMediaQuery } from 'hooks'
import { Modal, Title } from 'components'
import Close from 'components/HeaderInfo/CloseBtn'
import { fullScreenConfig } from 'components/Modal/config'
import { AssessmentModalProps } from './interfaces'

export const AssessmentModal = ({
  assessmentModalOpened,
  assessmentModalClose,
  modalTitle,
  content,
}: AssessmentModalProps) => {

  const largeScreen = useMediaQuery('(min-width: 700px)')

  return (
    <Modal.Root
      {...fullScreenConfig}
      opened={assessmentModalOpened}
      onClose={assessmentModalClose}
      data-testid='modal-edit-report'
      title={modalTitle}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            <Title order={largeScreen ? 3 : 5}>{modalTitle}</Title>
          </Modal.Title>
          <Modal.CloseButton>
            <Close />
          </Modal.CloseButton>
        </Modal.Header>
        <Modal.Body>
          {content}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
