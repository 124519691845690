import { useContext } from 'react'
import { FinalProjectReportContext } from '../../context/FinalProjectReportContext'
import { Stack, Title, Grid, Text, Image, RatingItem, Group, Card, Skeleton } from 'components'
import { useTranslation } from 'react-i18next'


export const StudentProjectReport = () => {
  const { t } = useTranslation()
  const { studentAssessments, assessmentsLoaded } = useContext(FinalProjectReportContext)

  if(!studentAssessments) return null

  return (
    <>
      {assessmentsLoaded ? (
        <Stack spacing='md'>
          <Stack spacing='xs'>
            <Title order={4}>{t('new_final_project_report.title_student_individual_evaluation')}</Title>
            <Grid>
              <Grid.Col span={12} sm={12} md={6} lg={3}>
                <RatingItem
                  label={t('new_final_project_report.label_rating_teacher')}
                  value={studentAssessments.moods.moodMasterEvaluationByStudent.value}
                />
              </Grid.Col>
              <Grid.Col span={12} sm={12} md={6} lg={3}>
                <RatingItem
                  label={t('new_final_project_report.label_rating_group_involved')}
                  value={studentAssessments.moods.moodGroupDeveloped.value}
                />
              </Grid.Col>
              <Grid.Col span={12} sm={12} md={6} lg={3}>
                <RatingItem
                  label={t('new_final_project_report.label_rating_participaded')}
                  value={studentAssessments.moods.moodStudentTookPart.value}
                />
              </Grid.Col>
              <Grid.Col span={12} sm={12} md={6} lg={3}>
                <RatingItem
                  label={t('new_final_project_report.label_rating_learned')}
                  value={studentAssessments.moods.moodStudentDeveloped.value}
                />
              </Grid.Col>
              <Grid.Col span={12} sm={12} md={6} lg={3}>
                <RatingItem
                  label={t('new_final_project_report.label_rating_liked')}
                  value={studentAssessments.moods.moodStudentLiked.value}
                />
              </Grid.Col>
            </Grid>
          </Stack>
          <Stack spacing='xs'>
            <Title order={4}>{t('new_final_project_report.title_student_individual_report')}</Title>
            <Text>{studentAssessments.studentReport}</Text>
            <Group spacing='md'>
              {
                // TODO: Implementar lista novo componente upload
                studentAssessments.studentEvidences.items &&
                  studentAssessments.studentEvidences.items.length > 0 &&
                  studentAssessments.studentEvidences.items.map((evidence, index) => (
                    <Card w={100} h={100} key={index} withBorder shadow='md' p={0}>
                      <Image height={100} src={evidence.fileData.fileCover || ''} />
                    </Card>
                  ))
              }
            </Group>
          </Stack>
          <Stack spacing='xs'>
            <Title order={4}>{t('new_final_project_report.title_teacher_individual_report')}</Title>
            <Text>{studentAssessments.teacherReport}</Text>
            <Group spacing='md'>
              {
                // TODO: Implementar lista novo componente upload
                studentAssessments.teacherEvidences.items &&
                  studentAssessments.teacherEvidences.items.length > 0 &&
                  studentAssessments.teacherEvidences.items.map((evidence, index) => (
                    <Card w={100} h={100} key={index} withBorder shadow='md' p={0}>
                      <Image height={100} src={evidence.fileData.fileCover || ''} />
                    </Card>
                  ))
              }
            </Group>
          </Stack>
        </Stack>
      ) : (
        <Stack spacing='md'>
          <Skeleton height={20} />
        </Stack>
      )}
    </>
  )
}
