import { createStyles } from 'hooks'
import HeaderImage from 'assets/DashboardHeader.png'

export const useStyles = createStyles(theme => ({
  navbarMenu: {
    position: 'fixed',
    padding: '1rem 1rem',
    zIndex: 50,
    right: 15,
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'right',
      right: 0,
      padding: '1rem 0.5rem',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundImage: `url('/${HeaderImage}')`,
      backgroundColor: theme.colors.yellow[6],
    },
  },
}))


