import { ICycle, ILvl4Item } from 'interfaces'

export interface IProject {
  id: string
  name: string
  status: string
  coverImage: string
  author: string
  modality: string
  groupName: string
  cycles: ICycle[]
  keywords: string[]
  abstract: string
  purpose: string
  finalProduct: string
  total_pendencies: number
  lastUpdate: string
  isTemplate: boolean
  featured: boolean
  category: string
  specificSkills?: ILvl4Item[]
  contents?: ILvl4Item[]
  lv1Items?: ILvl4Item[]
  actions: {
    show: string
    edit: string
  }
}
