import styled from 'styled-components'

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 700px;
  height: 100%;
  padding-left: 17px;
  padding-right: 17px;
  overflow-y: scroll;

  @media (max-width: 700px) {
    width: 100%;
  }
`

export const WrapperEvaluate = styled.div`
  display: flex;
  flex-direction: column;

  .tag {
    margin-bottom: 0;
  }
`


export const TagWrapper = styled.div`
  display: flex;
  flex-direction: table-row;
`
export const ScoreContainer = styled.div`
  display: grid;
  grid-template-columns: 58px 58px 58px 58px 58px;
  grid-gap: 1rem;

  transform-origin: top;
  transition: transform 0.5s ease-in-out;
  transition: visibility 0.5s, opacity 0.5s linear;
`

export const InfoWrapper = styled.div`
  margin-bottom: 18px;

  p {
    margin: 0;
  }

  span {
    font-weight: 700;
    color: var(--color-black);
  }
`

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 74px;
`
export const TitleJournal = styled.label<{ extraspace: boolean }>`
  font-weight: 500;
  line-height: 1.2;
  color: #121019;
  margin-bottom: 8px;
  margin-top: ${props => (props.extraspace ? '20px' : '0px')};
`
export const TitleFile = styled(TitleJournal)`
  margin-bottom: 18px;
`

export const RubricsBtn = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  width: fit-content;

  span {
    margin-left: 8px;
    color: var(--color-link);

    padding: 0;
  }
`

export const ContentContainer = styled.div<{ active: boolean }>`
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
`

export const TitleIndividualAssessment = styled.h3`
  font-size: 1.375rem;
  line-height: 29px;
  color: #000000;
  margin-top: 24px;
  font-weight: 700;
`
