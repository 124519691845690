import { useCallback, useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isNil } from 'ramda'
import { Async, AsyncCreatable } from 'react-select-old'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

function InputSelectAsync({
  id,
  name,
  errors,
  label,
  value,
  disabled,
  multi,
  options,
  initialOption,
  onChange,
  onReset,
  isSearchable,
  styles,
  hint,
  creatable,
  loadOptions,
  url,
  labelField,
  valueField,
}) {
  const { t } = useTranslation()
  const [onFocus, changeOnFocus] = useState(false)
  const [selected, changeSelected] = useState(value)

  useEffect(() => {
    if (initialOption !== null) {
      changeSelected(initialOption)
    }
  }, [initialOption])

  const handleOnChange = useCallback(
    (changed) => {
      changeSelected(changed)
      onChange(changed)
    },
    [onChange]
  )

  const handleOnFocus = useCallback(() => changeOnFocus(true), [])
  const handleOnBlur = useCallback(() => changeOnFocus(false), [])

  const localLoadOptions = useCallback((input, callback) => {
    axios
      .get(`/${url}${input}`)
      .then((response) => {
        callback(null, {
          options: response.data.map((item) => ({
            label: item[labelField],
            value: item[valueField],
          })),
        })
      })
      .catch(() => {})
  }, [])

  const resetValue = useCallback(() => {
    onReset()
    if (multi) {
      return []
    }

    return { value: '' }
  }, [])

  const Component = useMemo(
    () => (creatable ? AsyncCreatable : Async),
    [creatable]
  )
  const isInside = selected === '' && !onFocus
  return (
    <div className='register-form'>
      <div onFocusCapture={handleOnFocus} onBlurCapture={handleOnBlur}>
        <label
          htmlFor={id}
          className={classNames('__label', {
            inside: isInside,
            top: !isInside,
          })}
        >
          {label}
        </label>
        <Component
          name={name}
          id={id}
          placeholder=''
          noResultsText={t('commons_blankSlateText')}
          value={selected}
          options={options}
          resetValue={resetValue}
          disabled={disabled}
          onChange={handleOnChange}
          loadOptions={isNil(loadOptions) ? localLoadOptions : loadOptions}
          multi={multi}
          searchable={isSearchable}
          inputProps={{ autoComplete: 'off' }}
          className={classNames(styles.select)}
          promptTextCreator={(label) =>
            `${t('master_registration.registrations.new.create')} ${label}`
          }
        />
        {hint && <p className={classNames('hint', styles.hint)}>{hint}</p>}
        <span className='validation-error'>{errors}</span>
      </div>
    </div>
  )
}

InputSelectAsync.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.object),
  ]),
  initialOption: PropTypes.object,
  labelField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  multi: PropTypes.bool,
  isSearchable: PropTypes.bool,
  styles: PropTypes.object,
  hint: PropTypes.string,
  creatable: PropTypes.bool,
  loadOptions: PropTypes.func,
}

InputSelectAsync.defaultProps = {
  name: '',
  errors: [],
  value: '',
  options: [],
  onReset: () => {},
  initialOption: null,
  disabled: false,
  multi: false,
  isSearchable: true,
  hint: null,
  styles: {
    select: '',
    hint: '',
  },
  creatable: false,
  loadOptions: undefined,
}

export default InputSelectAsync
