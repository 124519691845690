import { useContext } from 'react'
import { FinalProjectReportContext } from '../../context/FinalProjectReportContext'
import { Stack, Title, Grid, Text, Group, Image, RatingItem, EmptyStateBox, Card, Divider, Tooltip } from 'components'
import { useTranslation } from 'react-i18next'
import { IconArticleOff, IconInfoCircle } from '@tabler/icons-react'

export const ProjectEvaluationSummary = () => {
  const { t } = useTranslation()
  const { teacherReport } = useContext(FinalProjectReportContext)

  if (!teacherReport) return null

  const teacherReportItemsList = [
    {
      label: t('new_final_project_report.label_rating_planning'),
      value: teacherReport.projectMoods.moods.moodPlanningWorked,
    },
    {
      label: t('new_final_project_report.label_rating_group_involved'),
      value: teacherReport.projectMoods.moods.moodGroupGotInvolved,
    },
    {
      label: t('new_final_project_report.label_rating_group_participated'),
      value: teacherReport.projectMoods.moods.moodGroupTookPart,
    },
    {
      label: t('new_final_project_report.label_rating_group_developed'),
      value: teacherReport.projectMoods.moods.moodGroupDeveloped,
    },
  ]

  const groupReportItemsList = [
    {
      label: t('new_final_project_report.label_rating_teacher'),
      value: teacherReport.projectMoods.groupMoods.moods.moodMasterEvaluationByStudent.value,
      studentCount: teacherReport.projectMoods.groupMoods.studentCount,
      answerCount: teacherReport.projectMoods.groupMoods.moods.moodMasterEvaluationByStudent.answerCount,
    },
    {
      label: t('new_final_project_report.label_rating_group_involved'),
      value: teacherReport.projectMoods.groupMoods.moods.moodGroupDeveloped.value,
      answerCount: teacherReport.projectMoods.groupMoods.moods.moodGroupDeveloped.answerCount,
    },
    {
      label: t('new_final_project_report.label_rating_participaded'),
      value: teacherReport.projectMoods.groupMoods.moods.moodStudentTookPart.value,
      answerCount: teacherReport.projectMoods.groupMoods.moods.moodStudentTookPart.answerCount,
    },
    {
      label: t('new_final_project_report.label_rating_learned'),
      value: teacherReport.projectMoods.groupMoods.moods.moodStudentDeveloped.value,
      answerCount: teacherReport.projectMoods.groupMoods.moods.moodStudentDeveloped.answerCount,
    },
    {
      label: t('new_final_project_report.label_rating_liked'),
      value: teacherReport.projectMoods.groupMoods.moods.moodStudentLiked.value,
      answerCount: teacherReport.projectMoods.groupMoods.moods.moodStudentLiked.answerCount,
    },
  ]

  return (
    <Stack spacing='md'>
      <Title order={3}>{t('new_final_project_report.title_project_evaluation_summary')}</Title>
      <Stack spacing='lg'>
        <Stack spacing='sm'>
          <Title order={4}>{t('new_final_project_report.title_teacher_evaluation')}</Title>
          <Grid>
            {teacherReportItemsList &&
              teacherReportItemsList.length > 0 &&
              teacherReportItemsList.map((item, index) => (
                <Grid.Col span={12} sm={12} md={6} lg={3} key={index}>
                  <RatingItem label={item.label} value={item.value} />
                </Grid.Col>
              ))}
          </Grid>
        </Stack>
        <Stack spacing='sm'>
          <Group spacing='xs'>
            <Title order={4}>{t('new_final_project_report.title_students_group_evaluation')}</Title>
            <Tooltip
              label={t('new_final_project_report.group_evaluation_tooltip')}
              withArrow
              withinPortal
              position='top'
            >
              <IconInfoCircle size='1.4rem' />
            </Tooltip>
          </Group>
          <Grid>
            {groupReportItemsList &&
              groupReportItemsList.length > 0 &&
              groupReportItemsList.map((item, index) => (
                <Grid.Col span={12} sm={12} md={6} lg={3} key={index}>
                  <RatingItem
                    label={item.label}
                    value={item.value}
                    studentCount={teacherReport.projectMoods.groupMoods.studentCount}
                    answerCount={item.answerCount}
                  />
                </Grid.Col>
              ))}
          </Grid>
        </Stack>
        <Stack spacing='xs'>
          <Title order={4}>{t('new_final_project_report.title_teacher_report_about_group')}</Title>
          {teacherReport.projectJournal && teacherReport.projectJournal.length > 0 ? (
            <Text>{teacherReport.projectJournal}</Text>
          ) : (
            <EmptyStateBox
              withBorder
              icon={<IconArticleOff />}
              text={t('new_final_project_report.no_report_available')}
            />
          )}
          <Group spacing='md'>
            {
              // TODO: Implementar lista novo componente upload
              teacherReport.evidencies.items &&
                teacherReport.evidencies.items.length > 0 &&
                teacherReport.evidencies.items.map((evidence, index) => (
                  <Card h={100} key={index} withBorder shadow='md' p={0}>
                    <Image w={100} h={100} key={index} src={evidence.fileData.fileCover} />
                  </Card>
                ))
            }
          </Group>
        </Stack>
      </Stack>
      <Divider />
    </Stack>
  )
}
