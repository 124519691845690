import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, EmptyStateBox } from 'components'
import { IconCalendarPlus, IconArticleOff } from '@tabler/icons-react'
import { useStyles } from './styles'

interface EmptyLessonProps {
  handleAddLesson: () => void
  editable: boolean
  isTemplate?: boolean
  projectStatus?: string
}

export const EmptyLesson: React.FC<EmptyLessonProps> = React.memo(
  ({ handleAddLesson, editable, isTemplate, projectStatus }) => {
    const { t } = useTranslation()

    const noLessonText = React.useMemo(() => {
      if (isTemplate) {
        return t('new_project_page.no_lesson')
      }
      return editable ? t('new_project_page.no_lesson') : t('new_project_page.no_lesson_no_group')
    }, [isTemplate, editable, t])

    const handleButtonClick = React.useCallback(() => {
      handleAddLesson()
    }, [handleAddLesson])

    const showLessonButton = editable || (isTemplate && projectStatus === 'draft')

    const buttonComponent = showLessonButton ? (
      <Button leftIcon={<IconCalendarPlus />} size='md' variant='light' onClick={handleButtonClick}>
        {t('new_project_page.add_lesson')}
      </Button>
    ) : null

    return (
      <EmptyStateBox withBorder text={noLessonText} icon={<IconArticleOff />} actionButton={buttonComponent} />
    )
  },
)
EmptyLesson.displayName = 'EmptyLesson'
