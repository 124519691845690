import { useContext } from 'react'
import { Card, Text, Group, Box, TextLink, Button, Alert, Tooltip, BadgeCycle, BadgeDeveloped } from 'components'
import { ICycle } from 'interfaces'
import { ICardItem } from '../ProjectSkillsStandards/interfaces'
import { IconInfoCircle, IconTrash, IconPlus } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { SkillsStandardsContext } from 'pages/NewProject/context/SkillsStandardsContext'
import { useStyles } from './styles'

export function CardSkillsStandardsLvl4({ item, color, lv1ID, lv2ID, developedStudents, ongoingProjects }: ICardItem) {
  const { t } = useTranslation()
  const { classes } = useStyles({ color })

  const {
    handleAddItem,
    checkIfSelected,
    checkIfHasAssessment,
    handleShowWorkedProjects,
    handleShowInfo,
    handleRemoveItem,
    skillsStandards,
  } = useContext(SkillsStandardsContext)

  const developed = developedStudents ? developedStudents : 0
  const totalStudents = skillsStandards?.totalStudents ? skillsStandards.totalStudents : 0
  const ongoing = ongoingProjects ? ongoingProjects : []
  const showCycleBadge = item.cycles && item.cycles.length > 0 && skillsStandards.byCycle === true

  return (
    <>
      <Card className={classes.root} shadow='sm' withBorder>
        <Card.Section pt='md' px='md'>
          <Group spacing='xs'>
            <BadgeDeveloped color={color} developed={developed} totalStudents={totalStudents} />{' '}
            {showCycleBadge
              ? item.cycles.map((cycle: ICycle) => <BadgeCycle key={cycle.id}>{cycle.name}</BadgeCycle>)
              : null}
          </Group>
        </Card.Section>
        <Card.Section p='md' className={classes.maxHeight}>
          <Box pb='xs'>
            {item.originalCodes && item.originalCodes !== '' ? (
              <Text span fw={500}>
                {item.originalCodes} -{' '}
              </Text>
            ) : (
              ''
            )}
            {item.name ? <Text span>{item.name}</Text> : null}
          </Box>
          {ongoing && ongoing.length > 0 ? (
            <Alert icon={<IconInfoCircle size='1.4rem' />} color='blue'>
              <Text size='xs' span>
                {t('new_project_page.alert_item_already_worked')}
              </Text>{' '}
              <TextLink inline size='xs' onClick={() => handleShowWorkedProjects(ongoing)}>
                {t('new_project_page.view_projects')}
              </TextLink>
            </Alert>
          ) : null}
        </Card.Section>
        <Card.Section p='md'>
          <Group position={item.descriptors.length > 0 ? 'apart' : 'right'}>
            {item.descriptors && item.descriptors.length > 0 ? (
              <TextLink size='md' onClick={() => handleShowInfo(item)}>
                {t('new_project_page.more_info')}
              </TextLink>
            ) : null}
            {checkIfSelected(item.id, item.type) ? (
              checkIfHasAssessment(item.id, item.type) ? (
                <Tooltip label={t('new_project_page.has_assessment')} withArrow withinPortal multiline position='top'>
                  <Button
                    variant='light'
                    color='red'
                    size='md'
                    data-disabled
                    sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                    onClick={event => event.preventDefault()}
                    leftIcon={<IconTrash size='1rem' />}
                  >
                    {t('general_buttons_remove')}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  variant='light'
                  color='red'
                  size='md'
                  leftIcon={<IconTrash size='1rem' />}
                  onClick={() => handleRemoveItem(item.id, item.type)}
                >
                  {t('general_buttons_remove')}
                </Button>
              )
            ) : (
              <Button
                variant='light'
                size='md'
                leftIcon={<IconPlus size='1rem' />}
                onClick={() => handleAddItem(item, lv1ID, lv2ID)}
              >
                {t('general_buttons_add')}
              </Button>
            )}
          </Group>
        </Card.Section>
        <Card.Section className={classes.borderBottom}></Card.Section>
      </Card>
    </>
  )
}
