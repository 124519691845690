/* global I18n */
import PropTypes from "prop-types";
import Toggle from "@legacy/common/_components/Toggle";
import { useTranslation } from "react-i18next";

const ToggleButtonContainer = (props) => {
  const { id, classNameContainer, hideTexts, disabled } = props;
  let { value, onToggle } = props;
  const { t } = useTranslation();

  if (disabled) {
    value = false;
    onToggle = () => false;
  }

  return (
    <div className={classNameContainer}>
      {!hideTexts && (
        <span className={`px-3 ${value ? "mediumgray-color" : ""}`}>
          {t("general_buttons_toggle_inactive")}
        </span>
      )}
      <Toggle id={id} value={value} onToggle={onToggle} disabled={disabled} />
      {!hideTexts && (
        <span className={`px-3 ${!value ? "mediumgray-color" : ""}`}>
          {t("general_buttons_toggle_active")}
        </span>
      )}
    </div>
  );
};

ToggleButtonContainer.propTypes = {
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  classNameContainer: PropTypes.string,
  value: PropTypes.bool.isRequired,
  hideTexts: PropTypes.bool,
  disabled: PropTypes.bool,
};

ToggleButtonContainer.defaultProps = {
  classNameContainer: "",
  hideTexts: false,
  disabled: false,
};

export default ToggleButtonContainer;
