import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNotification } from 'context/useNotificationContext'

export const NotEnabled = () => {
  const { t } = useTranslation()
  const { handleNotification } = useNotification()

  useEffect(() => {
    window.setTimeout(() => {
      handleNotification(true, t('error_permission'), true, 'error')
    }, 300)
  }, [])

  return (
    <>
      <div />
    </>
  )
}
