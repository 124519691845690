import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import { Trans, useTranslation } from 'react-i18next'
import { DatesProvider } from 'context'
import {
  Modal,
  Stack,
  Box,
  Grid,
  Button,
  TextInput,
  DateInput,
  CustomTimeInput,
  Checkbox,
  Select,
  Radio,
  Input,
  WeekDaySelector,
  Group,
  Text,
  DayRender,
} from 'components'
import { rem, useMantineTheme, useMediaQuery, useSetState } from 'hooks'
import { ILessonModal, ILessonFormValues, initialValues } from './interfaces'
import { useForm, notifications } from 'context'
import { LessonsContext } from '../../context/LessonsContext'
import { IconX, IconCheck } from '@tabler/icons-react'
import { useStyles } from './styles'
import { addMinutes } from 'helpers'
import { MeetingRepetitionRequest } from '../../../../pages/NewProject/service/interfaces'
import { createMeeting, editMeeting, calculateDays } from '../../../../pages/NewProject/service/projects'
import { modals } from '@mantine/modals'
import _ from 'lodash'
import { compareDateStringAndDateObject } from './helper'
import { FocusTrap } from '@mantine/core'
import { useLocalizedDate } from 'hooks'

export const LessonModal = ({
  projectID,
  lesson,
  schoolTermStartAt,
  schoolTermEndAt,
  fetchAndSetActivities,
  isApplication,
}: ILessonModal) => {
  const { t, i18n } = useTranslation()
  const { classes } = useStyles({ schoolTermEndAt: schoolTermEndAt })
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const isLarge = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)
  const [repeatEndDate, setRepeatEndDate] = useSetState({ disabled: false, required: false })
  const [repeatOptionDate, setRepeatOptionDate] = useSetState({ disabled: true, required: false, hidden: true })
  const [repeatWeekDays, setRepeatWeekDays] = useSetState({ disabled: true, required: false, hidden: true })
  const { openedLessonModal, closeLessonModal, editLesson, setEditLesson } = useContext(LessonsContext)
  const [weekDays, setWeekDays] = useState<number[]>([])
  const [generatedDates, setGeneratedDates] = useState<string[]>([])
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const currentLocale = i18n.language === 'pt-BR' ? 'pt-br' : 'en'
  const dateValueFormat = i18n.language === 'pt-BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  const firstDayOfWeek = i18n.language === 'pt-BR' ? 0 : 1
  const localizeDate = useLocalizedDate(i18n.language || 'pt-BR')

  const formatedTermEndDate =
    i18n.language === 'pt-BR'
      ? dayjs(schoolTermEndAt).format('DD/MM/YYYY')
      : dayjs(schoolTermEndAt).format('MM/DD/YYYY')

  const form = useForm<ILessonFormValues>({
    initialValues,
    validate: {
      title: value => (value.trim().length < 3 ? t('required_field') : null),
      lessonDate: value => isApplication && (value.toString().length < 3 ? t('required_field') : null),
      startTime: (value, allValues) =>
        isApplication &&
        (value.length < 3
          ? t('required_field')
          : allValues.endTime && getMinutesBetweenTimes(value, allValues.endTime) < 0
          ? t('invalid_time_range')
          : null),
      endTime: (value, allValues) =>
        isApplication &&
        (value.length < 3
          ? t('required_field')
          : allValues.startTime && getMinutesBetweenTimes(allValues.startTime, value) < 0
          ? t('invalid_time_range')
          : null),
      repeatEndDate: (value, allValues) =>
        isApplication && (allValues.repeatEnd === '2' && value.length < 3 ? t('required_field') : null),
    },
  })

  const generateMessage = (dates: string[]) => {
    const localizedDates = dates.map(date => localizeDate(date))
    return `${t('lesson_creation_Success_but_error')} ${localizedDates.join(', ')}.`
  }

  const getMinutesBetweenTimes = (start: string, end: string): number => {
    const startTime = start.includes(':') ? start : `${start}:00`
    const endTime = end.includes(':') ? end : `${end}:00`

    return (new Date(`1970-01-01T${endTime}`).getTime() - new Date(`1970-01-01T${startTime}`).getTime()) / 60000
  }
  const createLesson = async (values: ILessonFormValues, dates: string[]) => {
    const id = projectID
    if (!id) return console.error('ID is missing')

    const payload = {
      project_id: id as string,
      date: dayjs(values.lessonDate).format('YYYY-MM-DD'),
      duration: getMinutesBetweenTimes(values.startTime, values.endTime),
      time: values.startTime as string,
      title: values.title as string,
      enable_pendency_journal: form.values.generateTasks,
      ...(dates.length > 0 && { dates_repeat_on: dates }),
    }

    try {
      const res = await createMeeting(payload)

      if (res.alreadyMeetings.length > 0 && dates.length === 0) {
        notifications.show({
          message: t('lesson_creation_Error1'),
          color: 'red',
          icon: <IconX size={24} />,
        })
        return
      }

      if (res.alreadyMeetings.length > 0 && dates.length > 0) {
        const alreadyMeeting = res.alreadyMeetings
        const countMeetingsNotCreated = alreadyMeeting.length

        if (generatedDates.length === countMeetingsNotCreated) {
          notifications.show({
            message: t('lesson_creation_Error2'),
            color: 'red',
            icon: <IconX size={24} />,
          })
          return
        }

        notifications.show({
          title: t('lesson_creation_Success'),
          message: generateMessage(alreadyMeeting),
          color: 'red',
          icon: <IconX size={24} />,
        })

        closeLessonModal()
        fetchAndSetActivities()
        return
      }

      handleSuccess()
    } catch (error) {
      notifications.show({
        message: 'An error occurred while creating the meeting',
        color: 'red',
        icon: <IconX size={24} />,
      })
      console.error('An error occurred while creating the meeting', error)
    }
  }

  const handleeditMeeting = async (values: ILessonFormValues) => {
    const id = projectID

    if (!id) return console.error('ID is missing')

    const duration = getMinutesBetweenTimes(values.startTime, values.endTime)

    const payload = {
      project_id: id as string,
      date: dayjs(values.lessonDate).format('YYYY-MM-DD'),
      duration: duration,
      time: values.startTime as string,
      title: values.title as string,
      enable_pendency_journal: form.values.generateTasks,
    }

    try {
      if (lesson) {
        const res = await editMeeting(lesson.id, payload)

        if (res.length > 0 && res[0]) {
          notifications.show({
            message: res,
            color: 'red',
            icon: <IconX size={24} />,
          })
          return
        }
      }

      handleSuccess()
    } catch (error) {
      console.error('An error occurred while editing the meeting', error)
    }
  }

  const handleForm = async (values: ILessonFormValues) => {
    if (isEditing) {
      handleeditMeeting(values)
      return
    }

    if (values.repeatLessons === '0') {
      createLesson(values, [])
      return
    }

    const dates = await generateRepeatDates()
    setGeneratedDates(dates.dates)
  }

  useEffect(() => {
    if (generatedDates.length && generatedDates.length > 0) {
      openRepeatLessonModal(() => createLesson(form.values, generatedDates))
    }
  }, [generatedDates])

  const handleErrors = (errors: unknown) => {
    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: t('fill_the_required_fields'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const openRepeatLessonModal = (confirm: () => void) => {
    const finishDate = form.values.repeatEndDate.toString().length !== 0 ? form.values.repeatEndDate : schoolTermEndAt

    const confirmMessage =
      form.values.repeatLessons === '2'
        ? 'new_project_page.repeat_lesson_message_week'
        : 'new_project_page.repeat_lesson_message'

    modals.openConfirmModal({
      title: t('new_project_page.repeat_lesson', { number: generatedDates.length }),
      size: 'lg',
      children: (
        <Text size='md'>
          <Trans
            i18nKey={confirmMessage}
            values={{
              number: generatedDates.length,
              date1: localizeDate(form.values.lessonDate),
              date2: localizeDate(finishDate ? finishDate : ''),
            }}
          ></Trans>
        </Text>
      ),
      labels: {
        confirm: t('create'),
        cancel: t('new_project_page.warnning_groupt_button1'),
      },
      confirmProps: { size: 'lg' },
      cancelProps: { size: 'lg' },
      onConfirm: () => confirm(),
    })
  }

  const handleSuccess = () => {
    closeLessonModal()
    fetchAndSetActivities()

    const successMessage = isEditing
      ? t('new_project_page.lesson_edited_message')
      : t('new_project_page.lesson_added_message')
    const successColor = 'green'
    const successIcon = <IconCheck size={24} />

    notifications.show({
      message: successMessage,
      color: successColor,
      icon: successIcon,
    })
  }

  useEffect(() => {
    const { repeatEnd, repeatLessons } = form.values

    const repeatWeekDaysConfig =
      repeatLessons === '2'
        ? { required: true, disabled: false, hidden: false }
        : { required: false, disabled: true, hidden: true }

    const repeatOptionDateConfig =
      repeatLessons !== '0'
        ? { required: true, disabled: false, hidden: false }
        : { required: false, disabled: true, hidden: true }

    if (repeatEnd === '2') {
      setRepeatEndDate({ required: true, disabled: false })
    }

    setRepeatWeekDays(repeatWeekDaysConfig)
    setRepeatOptionDate(repeatOptionDateConfig)
  }, [form.values.repeatLessons, form.values.repeatEnd])

  const handleWeekDays = (value: number[]) => {
    setWeekDays(value)
  }

  const generateRepeatDates = async () => {
    const { repeatLessons, lessonDate, repeatEndDate } = form.values

    if (!lessonDate) throw new Error('Lesson date is missing')
    if (repeatLessons === '0') return

    const weekD = repeatLessons === '1' ? [1, 2, 3, 4, 5] : weekDays
    const finishDate =
      repeatEndDate.toString().length !== 0
        ? dayjs(repeatEndDate).format('YYYY-MM-DD')
        : dayjs(schoolTermEndAt).format('YYYY-MM-DD')

    const payload = {
      start_date: dayjs(lessonDate).format('YYYY-MM-DD'),
      finish_date: finishDate,
      days_of_week: weekD,
    }

    return await calculateDays(payload as MeetingRepetitionRequest)
  }

  useEffect(() => {
    if (form.values.repeatEndDate) {
      form.setFieldValue('repeatEndDate', '')
      setRepeatEndDate({ required: false, disabled: true })
    }
  }, [repeatOptionDate])

  useEffect(() => {
    const { repeatLessons } = form.values

    if (repeatLessons === '1') {
      setWeekDays([0, 1, 2, 3, 4])
    } else {
      setWeekDays([])
    }
  }, [form.values.repeatLessons])

  useEffect(() => {
    if (editLesson !== null) {
      const lessonDateValue = dayjs(editLesson.date).toDate()

      form.setFieldValue('title', editLesson.title)
      form.setFieldValue('lessonDate', lessonDateValue)
      if (editLesson.time !== null) {
        const startTime = getTimeFromDateStr(editLesson.time)
        if (startTime !== null) {
          form.setFieldValue('startTime', startTime)
        }
      }
      form.setFieldValue('endTime', addMinutesToDateStr(editLesson.time, Number(editLesson.duration)))
      form.setFieldValue('generateTasks', editLesson.enablePendencyJournal)
      setIsEditing(true)

      return
    }
    setIsEditing(false)
  }, [editLesson])

  useEffect(() => {
    if (!openedLessonModal) {
      setIsEditing(false)
      setWeekDays([])
      setEditLesson(null)
      form.setValues({ ...initialValues })
    }
  }, [openedLessonModal])

  const formatDateToLocalString = (dateString: string) => {
    const [year, month, day] = dateString.split('-').map(Number)
    const localDate = new Date(year, month - 1, day)

    return localDate
  }

  const getTimeFromDateStr = (dateString: string | null): string | null => {
    if (dateString === null || typeof dateString !== 'string') {
      return null
    }
    const [, time] = dateString.split('T')
    return time.split('.')[0]
  }

  const addMinutesToDateStr = (dateString: string | null, minutes: number): string => {
    if (!dateString) {
      return ''
    }

    const parts = dateString.split('T')
    if (parts.length < 2) {
      return ''
    }

    const timeParts = parts[1].split(':')
    if (timeParts.length < 3) {
      return ''
    }

    let hours = parseInt(timeParts[0], 10)
    let mins = parseInt(timeParts[1], 10)

    if (isNaN(hours) || isNaN(mins)) {
      return ''
    }

    mins += minutes
    hours += Math.floor(mins / 60)
    mins %= 60
    hours %= 24

    return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${timeParts[2].split('.')[0]}`
  }

  const handleCloseLessonModal = () => {
    const time = editLesson ? new Date(editLesson.time).toLocaleTimeString('en-GB', { hour12: false }) : ''
    const endTime = new Date(
      new Date(editLesson?.time as string).getTime() +
        (editLesson?.duration ? Number(editLesson.duration) : 0) * 60 * 1000,
    ).toLocaleTimeString('en-GB', { hour12: false })

    const hasChangesOnEdit =
      editLesson?.title !== form.values.title ||
      editLesson?.enablePendencyJournal !== form.values.generateTasks ||
      !compareDateStringAndDateObject(editLesson?.date, form.values.lessonDate.toString()) ||
      time !== form.values.startTime ||
      endTime !== form.values.endTime

    if ((!isEditing && !_.isEqual(form.values, initialValues)) || (isEditing && hasChangesOnEdit)) {
      modals.openConfirmModal({
        title: t('general_changed_confirmation_modal_title'),
        size: 'lg',
        children: <Text size='sm'>{t('general_changed_confirmation_modal_message')}</Text>,
        labels: {
          confirm: t('new_project_page.confirm'),
          cancel: t('new_project_page.warnning_groupt_button1'),
        },
        confirmProps: { size: 'lg' },
        cancelProps: { size: 'lg' },
        onConfirm: () => {
          closeLessonModal()
          setWeekDays([])
          setEditLesson(null)
          form.setValues({ ...initialValues })
        },
      })
    } else {
      closeLessonModal()
    }
  }
  return (
    <Modal
      zIndex={150}
      opened={openedLessonModal}
      onClose={handleCloseLessonModal}
      title={lesson ? t('new_project_page.edit_lesson') : t('new_project_page.add_lesson')}
      size='lg'
      fullScreen={schoolTermEndAt !== null}
      classNames={classes}
    >
      <form onSubmit={form.onSubmit(handleForm, handleErrors)}>
        <FocusTrap active>
          <Stack className={classes.stack}>
            <DatesProvider settings={{ locale: currentLocale, firstDayOfWeek: firstDayOfWeek }}>
              <Grid>
                <Grid.Col span={12}>
                  <TextInput
                    data-autofocus
                    name='title'
                    size='md'
                    label={t('new_project_page.lesson_title')}
                    withAsterisk
                    {...form.getInputProps('title')}
                  />
                  {isApplication && (
                    <Grid gutter='lg' mt='md'>
                      <Grid.Col span={isMobile ? 12 : 4}>
                        <DateInput
                          name='lessonDate'
                          size='md'
                          label={t('new_project_page.lesson_date')}
                          withAsterisk
                          valueFormat={dateValueFormat}
                          renderDay={DayRender}
                          minDate={dayjs(schoolTermStartAt).toDate()}
                          maxDate={dayjs(schoolTermEndAt).add(2, 'year').toDate()}
                          {...form.getInputProps('lessonDate')}
                        />
                      </Grid.Col>
                      <Grid.Col span={isMobile ? 6 : 4}>
                        <CustomTimeInput
                          name='startTime'
                          size='md'
                          label={t('new_project_page.lesson_start_time')}
                          withAsterisk
                          min='06:00'
                          max='22:00'
                          {...form.getInputProps('startTime')}
                        />
                      </Grid.Col>
                      <Grid.Col span={isMobile ? 6 : 4}>
                        <CustomTimeInput
                          name='endTime'
                          size='md'
                          label={t('new_project_page.lesson_end_time')}
                          withAsterisk
                          min={form.values.startTime ? addMinutes(form.values.startTime, 1) : '06:00'}
                          max='22:00'
                          {...form.getInputProps('endTime')}
                        />
                      </Grid.Col>
                      {!isEditing && (
                        <>
                          <Grid.Col span={isMobile ? 12 : 4}>
                            <Select
                              name='repeatLessons'
                              size='md'
                              label={t('new_project_page.lesson_repeat_lessons')}
                              data={[
                                { value: '0', label: t('new_project_page.lesson_repetition_options.dont_repeat') },
                                { value: '1', label: t('new_project_page.lesson_repetition_options.daily') },
                                { value: '2', label: t('new_project_page.lesson_repetition_options.weekly') },
                              ]}
                              disabled={
                                form.values.lessonDate === '' ||
                                form.values.startTime === '' ||
                                form.values.endTime === ''
                              }
                              {...form.getInputProps('repeatLessons')}
                            />
                          </Grid.Col>
                          <Grid.Col
                            span={isMobile ? 12 : 'content'}
                            xl={4}
                            style={{ display: repeatWeekDays.hidden ? 'none' : '-webkit-box' }}
                          >
                            <WeekDaySelector
                              name='repeatDays'
                              label={t('new_project_page.lesson_repeat_days')}
                              disabled={repeatWeekDays.disabled}
                              withAsterisk={repeatWeekDays.required}
                              handleWeekDays={handleWeekDays}
                              startDate={form.values.lessonDate.toString()}
                            />
                          </Grid.Col>
                          <Grid.Col
                            span='auto'
                            xs={12}
                            sm={12}
                            md={12}
                            lg='auto'
                            xl='auto'
                            sx={{ display: repeatOptionDate.hidden ? 'none' : '-webkit-box' }}
                          >
                            <Input.Wrapper
                              size='md'
                              withAsterisk={repeatOptionDate.required}
                              label={t('new_project_page.lesson_repeat_until')}
                            >
                              <Radio.Group name='repeatEnd' defaultValue='1' {...form.getInputProps('repeatEnd')}>
                                <Grid
                                  m={0}
                                  pt={isLarge ? 0 : 8}
                                  gutter='xs'
                                  gutterXs='xs'
                                  gutterSm='xs'
                                  gutterMd='xs'
                                  gutterLg='xs'
                                  gutterXl='xs'
                                  align='center'
                                  justify='flex-start'
                                >
                                  <Grid.Col span={12} xs={12} sm={12} md={12} lg={5} xl='content'>
                                    <Radio
                                      name='repeatEnd'
                                      size='md'
                                      label={t('new_project_page.lesson_end_of_term', { date: formatedTermEndDate })}
                                      value='1'
                                      disabled={repeatOptionDate.disabled}
                                    />
                                  </Grid.Col>
                                  <Grid.Col
                                    pt={isMobile ? 'xs' : 0}
                                    pr={0}
                                    span={12}
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={7}
                                    xl='auto'
                                  >
                                    <Group spacing='sm'>
                                      <Radio
                                        name='repeatEnd'
                                        size='md'
                                        label={t('new_project_page.lesson_final_date')}
                                        value='2'
                                        disabled={repeatOptionDate.disabled}
                                      />

                                      <DateInput
                                        name='repeatEndDate'
                                        size='md'
                                        aria-label={t('new_project_page.lesson_final_repetition_date')}
                                        disabled={repeatEndDate.disabled || form.values.repeatEnd === '1'}
                                        withAsterisk={form.values.repeatEnd === '2'}
                                        sx={{ flex: 1 }}
                                        valueFormat={dateValueFormat}
                                        minDate={dayjs(form.values.lessonDate).add(1, 'day').toDate()}
                                        maw={isLarge ? '100%' : rem(200)}
                                        renderDay={DayRender}
                                        {...form.getInputProps('repeatEndDate')}
                                      />
                                    </Group>
                                  </Grid.Col>
                                </Grid>
                              </Radio.Group>
                            </Input.Wrapper>
                          </Grid.Col>
                        </>
                      )}
                    </Grid>
                  )}
                </Grid.Col>
              </Grid>
              {isApplication && (
                <Grid gutter='lg'>
                  <Grid.Col span={12}>
                    <Input.Wrapper size='md' label={t('new_project_page.lesson_options')}>
                      <Checkbox
                        size='md'
                        mt='xs'
                        label={t('new_project_page.lesson_generate_task')}
                        checked={form.values.generateTasks}
                        {...form.getInputProps('generateTasks')}
                      />
                    </Input.Wrapper>
                  </Grid.Col>
                </Grid>
              )}
            </DatesProvider>
          </Stack>
          <Box className={classes.footer}>
            <Grid gutter={isMobile ? 'sm' : 'md'} justify='right' p={0} m={0}>
              <Grid.Col span={isMobile ? 12 : 'content'}>
                <Button fullWidth={isMobile} variant='default' size='lg' onClick={handleCloseLessonModal}>
                  {t('general_buttons_cancel')}
                </Button>
              </Grid.Col>
              <Grid.Col span={isMobile ? 12 : 'content'}>
                <Button type='submit' fullWidth={isMobile} variant='filled' size='lg'>
                  {t('general_buttons_save')}
                </Button>
              </Grid.Col>
            </Grid>
          </Box>
        </FocusTrap>
      </form>
    </Modal>
  )
}
