import { createStyles, rem } from 'hooks'

export const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 'column',
    flexBasis: '0',
  },
  header: {
    flex: '0 1 auto',
    position: 'relative',
  },
  headerBgImage: {
    backgroundColor: 'gray',
    position: 'relative',
  },
  headerBadgeList: {
    position: 'absolute',
    bottom: rem(8),
    left: rem(16),
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
    flexDirection: 'column',
    flexFlow: 'column',
  },
  contentBox: {
    flex: '1 1 auto',
    height: '100%',
  },
  contentStack: {
    flex: '1 1 auto',
  },
}))
