import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { notifications, useCurrentUser } from 'context'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { ProjectListContext } from '../../context/ProjectListContext'
import { Title, Stack, Grid, Tabs, Button, TextSearch, Group, DisabledTooltipButton } from 'components'
import { useEffect } from 'react'
import { IconFilter, IconCheck, IconX } from '@tabler/icons-react'
import { ProjectFilterModal } from '../ProjectFilterModal'
import { ProjectCardList } from '../ProjectCardList'
import { getProjects } from '../../services'
import { ProjectCardListSkeleton } from '../ProjectCardListSkeleton'
import { getStatusNumber, canDraft, canViewDraft } from '../../helpers/projectHelpers'

export const ProjectList = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { currentUser } = useCurrentUser()
  const {
    activeTab,
    setActiveTab,
    prevTab,
    setPrevTab,
    projects,
    setProjects,
    activePage,
    setActivePage,
    searchValue,
    setTotalPages,
    openProjectFilterModal,
    setSearchValue,
    filterSchoolTermID,
    filterFinalProduct,
    filterCycle,
    filterGroup,
    filterActive,
    newProjectURL,
    error,
    setError,
  } = useContext(ProjectListContext)

  const currentProfile = currentUser?.profile_used
  const hasGroups: boolean | undefined = currentUser && currentUser.group_school_terms_count > 0

  const getPage = (prevTab: string | null, activeTab: string | null, activePage: number) => {
    if (prevTab === activeTab) return activePage
    return 1
  }

  useEffect(() => {
    if (error !== null) {
      notifications.show({
        title: t('general_error_title'),
        message: t('general_error_message_data_not_loaded'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }, [error])

  useEffect(() => {
    if (!activeTab || !activePage) return
    if (prevTab !== activeTab) setActivePage(1)
    const getData = () => {
      setProjects(null)
      getProjects(
        getStatusNumber(activeTab),
        getPage(prevTab, activeTab, activePage),
        searchValue,
        filterSchoolTermID,
        filterFinalProduct,
        filterCycle,
        filterGroup,
      )
        .then(res => {
          if (res.status === 200) {
            const projects = res.data.data
            setProjects(projects)
            const totalPages = res.data.pagination.total
            if (totalPages !== undefined && totalPages >= 1) setTotalPages(totalPages)
            setPrevTab(activeTab)
          } else {
            setError(res.statusText)
          }
        })
        .catch(err => {
          setError(err.message)
        })
    }
    getData()
  }, [activeTab, activePage, searchValue, filterSchoolTermID, filterFinalProduct, filterCycle, filterGroup])

  const NewProjectButton = () => {
    const shouldShowButton = hasGroups && currentProfile && canDraft(currentProfile)

    if (currentProfile !== 'tutor') return null

    return (
      <>
        {shouldShowButton ? (
          <Button component='a' fullWidth={isMobile} variant='filled' size='lg' href={newProjectURL}>
            {t('new_projects_list.new_project')}
          </Button>
        ) : (
          <DisabledTooltipButton
            fullWidth
            label={t('new_projects_list.new_project')}
            tooltipLabel={t('new_project_tooltip')}
          />
        )}
      </>
    )
  }

  // const shouldShowButton = hasGroups && currentProfile
  const shouldShowButton = true
  const disabledFilterButton = currentProfile === 'manager' ? false : !shouldShowButton

  return (
    <>
      <ProjectFilterModal />
      <Stack spacing='md'>
        <Group position='apart' mt='lg'>
          <Title>{t('projects_title')}</Title>
          <NewProjectButton />
        </Group>
        <Grid>
          <Grid.Col span='auto'>
            <TextSearch placeholder={t('projects_search')} size='lg' onChange={value => setSearchValue(value)} />
          </Grid.Col>
          <Grid.Col span='content'>
            <Button
              variant='default'
              size='lg'
              leftIcon={<IconFilter />}
              rightIcon={filterActive ? <IconCheck color='green' /> : null}
              onClick={openProjectFilterModal}
              disabled={disabledFilterButton}
            >
              {t('projects_filter')}
            </Button>
          </Grid.Col>
        </Grid>
        <Tabs value={activeTab} onTabChange={setActiveTab} defaultValue='approved'>
          <Tabs.List>
            {currentProfile && canViewDraft(currentProfile) ? (
              <Tabs.Tab value='draft'>{t('project_draft')}</Tabs.Tab>
            ) : null}
            <Tabs.Tab value='approved'>{t('project_progress')}</Tabs.Tab>
            <Tabs.Tab value='finished'>{t('project_finished')}</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value={activeTab ? activeTab : 'approved'}>
            {projects && projects !== null ? <ProjectCardList projects={projects} /> : <ProjectCardListSkeleton />}
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </>
  )
}
