import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context/useCurrentUser'
import { paths } from '../paths'
import { DropdownMenuItemIndicatorStyled, DropdownMenuRadioItemStyled, LinkStyled } from '../NavbarMenuStyled'

export const TutorProfileButton = () => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  if (!currentUser?.tutor_account) {
    return <></>
  }

  const changeProfile = () => {
    window.localStorage.removeItem('current_user')
    window.location.href = paths.account.profileChange('tutor')
  }

  return (
    <>
      <DropdownMenuRadioItemStyled value='tutor'>
        <DropdownMenuItemIndicatorStyled />
        <LinkStyled onClick={changeProfile}>{t('general_profiles_tutor')}</LinkStyled>
      </DropdownMenuRadioItemStyled>
    </>
  )
}
