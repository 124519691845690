import { Grid, CardFileUploaded } from 'components'

interface GoogleDriveFile {
  id: string
  name: string
}

interface FileListGoogleDriveProps {
  files: GoogleDriveFile[]
  onRemove?: () => void
}

export const FileListGoogleDrive = ({ files, onRemove}: FileListGoogleDriveProps) => {
  return (
    <Grid>
      {files && files.length > 0 ? files.map(file => (
        <Grid.Col key={file.id} span={12} xs={12} sm={12} md={6} lg={6} xl={4}>
          <CardFileUploaded name={file.name} type='gdrive' onRemove={onRemove} />
        </Grid.Col>
      )) : null}
    </Grid>
  )
}
