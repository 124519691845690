import { useEffect, useContext, FC } from 'react'
import TabContext from '../context/students-context'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { Tabs } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Wrap } from './styled'
import { ITabContext } from '../types/student-types'

const TabNavigation: FC = () => {
  const { changeTab, meta } = useContext<ITabContext>(TabContext)
  const [activeTab, setActiveTab] = useState<string | null>('active')
  const { t } = useTranslation()

  useEffect(() => {
    const tab = activeTab === 'active' ? 1 : 0
    changeTab(tab)
  }, [activeTab])

  return isEmpty(meta) ? null : (
    <Wrap>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value='active'>{`${t('students_active')} (${meta.activeStudentsCount})`}</Tabs.Tab>
          <Tabs.Tab value='inactive'>{`${t('students_inactive')} (${meta.inactiveStudentsCount})`}</Tabs.Tab>
        </Tabs.List>
      </Tabs>
    </Wrap>
  )
}

export default TabNavigation
