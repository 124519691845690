import { Component } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { Beforeunload } from 'react-beforeunload'
import { withTranslation } from 'react-i18next'
import withTracking from '@legacy/common/hocs/with-tracking'
import { Loading } from '@legacy/common/_components'
import updateMural from '@legacy/common/_shared/MuralItemsHelper'
import FileUpload from '@legacy/common/_components/FileUpload'
import { StarsSelector } from 'components/StarsSelector'
import MeetingHeader from '@legacy/common/SessionMeeting/SessionMeetingHeader'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import Button from '@legacy/common/_components/Button'
import service from '@legacy/pages/SessionMeetingAssessment/components/StudentsAssessments/assessmentService'
import { Textarea } from '@mantine/core'

import { LumiarToastContainer, toastOptions } from '@legacy/common/_components/LumiarToast'

import StudentsAssessments from './components/StudentsAssessments/StudentsAssessments'
import ActivityAlone from './components/ActivityAlone'
import ErrorService from '@legacy/common/Tools/ErrorService'

class MeetingAssessment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      payload: {
        journal: '',
        mood_planning_worked: 0,
        mood_group_got_involved: 0,
        mood_group_took_part: 0,
        mood_group_developed: 0,
      },
      loading: {
        page: true,
        saveButton: false,
        activities: true,
      },
      session_meeting: {},
      hasChangesNotSaved: false,
      errors: {
        journal: [],
        mood_planning_worked: [],
        mood_group_got_involved: [],
        mood_group_took_part: [],
        mood_group_developed: [],
      },
      activities: [],
    }

    this.clickStar = this.clickStar.bind(this)
    this.sessionMeetingSetState = this.sessionMeetingSetState.bind(this)
  }

  componentDidMount() {
    this.fetchMeeting()
    this.fetchActivities()
  }

  async fetchActivities() {
    const { i18n } = this.props
    const id = this.props.meetingId
    const url = `/session_meetings/${id}/find_session_meeting_activities`
    try {
      const { data } = await axios.get(url)

      const { loading } = this.state
      this.setState({
        activities: data,
        loading: { ...loading, activities: false },
      })
    } catch (e) {
      toast.error(i18n.t('loading_activities_error'), toastOptions)
      ErrorService.notice(e)
    }
  }

  async fetchMeeting() {
    const { i18n } = this.props
    const url = `/session_meetings/${this.props.meetingId}/edit_report.json`
    try {
      const { data } = await axios.get(url)
      const { loading, payload } = this.state

      this.setState({
        session_meeting: data,
        payload: { ...payload, ...data.moods, journal: data.journal },
        loading: { ...loading, page: false },
      })
    } catch (error) {
      if (error.response && error.response.status === 401) {
        window.location.href = '/error_permission'
      } else {
        toast.error(i18n.t('loading_meeting_error'), toastOptions)
      }
    }
  }

  submitData = async () => {
    const { i18n } = this.props
    const { loading } = this.state
    this.setState({ loading: { ...loading, saveButton: true } })
    try {
      await service.updateSessionMeeting(this.state.session_meeting.id, this.state.payload)
      toast.success(i18n.t('general_messages_saved'), toastOptions)
      this.setState({
        hasChangesNotSaved: false,
        loading: { ...loading, saveButton: false },
      })
    } catch (e) {
      toast.error(i18n.t('error_on_save_assessment_informations'), toastOptions)
      ErrorService.notice(e)
    }
  }

  updateMeetingJournal(value) {
    const { session_meeting, payload } = this.state
    this.setState({
      session_meeting: { ...session_meeting, journal: value },
      payload: { ...payload, journal: value },
      hasChangesNotSaved: true,
    })
  }

  async clickStar(fieldName, value) {
    const previousMood = this.state.session_meeting.moods[fieldName]
    const calculatedValue = value === previousMood ? 0 : value

    this.props.mixPanelTracker('Diário de  bordo - Fim', {
      content_type: 'stars',
    })

    const { session_meeting, payload } = this.state
    const moods = { ...session_meeting.moods, [fieldName]: calculatedValue }
    this.setState({
      session_meeting: { ...session_meeting, moods },
      payload: {
        ...payload,
        [fieldName]: calculatedValue,
      },
      hasChangesNotSaved: true,
    })
  }

  sessionMeetingSetState(value) {
    this.setState(value)
  }

  render() {
    const { i18n } = this.props
    if (this.state.loading.page) {
      return <Loading />
    }
    const { session_meeting, hasChangesNotSaved, activities, loading, errors } = this.state
    const background =
      session_meeting.cover_image !== null ? { backgroundImage: `url(${session_meeting.cover_image})` } : {}
    const showHour = session_meeting?.showHour !== '' ? ` (${session_meeting.showHour})` : ''

    return (
      <>
        <LumiarToastContainer />
        {hasChangesNotSaved && <Beforeunload onBeforeunload={event => event.preventDefault()} />}
        <div className='project-container-image' style={background} />
        <div className='project-container'>
          <MeetingHeader
            projectId={session_meeting.project_id}
            projectName={session_meeting.project_name}
            projectStatus={session_meeting.project_status}
            previousMeeting={session_meeting.previous_meeting}
            nextMeeting={session_meeting.next_meeting}
            date={`${session_meeting.date}${showHour}`}
            title={session_meeting.title}
            description={session_meeting.description}
            groupSchoolTermName={session_meeting.group_school_term_name}
            meetingsCount={session_meeting.meetings_count}
            tutors={session_meeting.tutors}
            masters={session_meeting.masters}
            mainTutorId={session_meeting.main_tutor_id}
            mainMasterId={session_meeting.main_master_id}
          />

          <h2 className='pt-5'>
            {i18n.t('activities_activities')} ({activities.length})
          </h2>

          <div className='mt-5 mb-5'>
            {loading.activities ? (
              <Loading />
            ) : (
              activities.map(activity => <ActivityAlone key={activity.id} data={activity} />)
            )}
          </div>

          <hr />

          <div className='border-bottom pb-4'>
            <h2 className='pb-3'>{i18n.t('meetings_report_moods_title')}</h2>
            {Object.keys(session_meeting.moods).map(key => (
              <StarsSelector
                key={key}
                fieldName={key}
                value={session_meeting.moods[key] ? session_meeting.moods[key] : 0}
                onClick={this.clickStar}
                label={i18n.t(`meetings_report_moods_${key}`)}
                errors={errors[key]}
                total={5}
              />
            ))}
          </div>
          <div className='mb-4'>
            <div className='register-form'>
              <div className='form-field'>
                <Textarea
                  mt='md'
                  id='input-motivating-interests'
                  value={session_meeting.journal || ''}
                  label={i18n.t('journals_group_titleEdit')}
                  onChange={event => {
                    this.updateMeetingJournal(event.target.value)
                  }}
                  error={errors.journal}
                  autosize
                  minRows={2}
                />
              </div>
            </div>
          </div>
          <div className='mb-4' style={true ? { marginTop: '30px' } : {}}>
            {i18n.t('meetings_report_groupRecords')}
          </div>
          <FileUpload
            uploadUrl={`/session_meetings/${session_meeting.id}/session_meeting_file_items`}
            fetchFilesUrl={`session_meetings/${session_meeting.id}/session_meeting_file_items.json`}
            deleteFilesUrl={`/session_meetings/${session_meeting.id}/session_meeting_file_items`}
            customAction={updateMural({
              projectId: session_meeting.project_id,
            })}
            typeFile='file'
            beforeUploadCallback={async () => {
              await this.props.mixPanelTracker('Diário de  bordo - Fim', {
                content_type: 'upload',
              })
            }}
          />
          <div className='mt-4'>
            <GDriveWrapper
              value={{
                resourceId: session_meeting.id,
                resourceClass: 'session_meeting_id',
              }}
            />
          </div>
          <Button className='mt-4' size='large' onClick={this.submitData} loading={loading.saveButton}>
            {i18n.t('general_buttons_save')}
          </Button>
          <hr className='mt-5' />
          <StudentsAssessments
            mixPanelTracker={this.props.mixPanelTracker}
            meetingId={this.props.meetingId}
            meetingAssessmentPayload={this.state.payload}
            hasChangesNotSavedOnMeetingAssessment={hasChangesNotSaved}
            sessionMeetingState={this.state}
            sessionMeetingSetState={this.sessionMeetingSetState}
          />
        </div>
      </>
    )
  }
}

MeetingAssessment.propTypes = {
  meetingId: PropTypes.string.isRequired,
  mixPanelTracker: PropTypes.func.isRequired,
}

export default withTranslation()(withTracking(MeetingAssessment))
