import { createContext, useEffect, useState } from 'react'
import { notifications } from 'context'
import { IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

interface ErrorContextProps {
  error: string | null
  setError: React.Dispatch<React.SetStateAction<string | null>>
}

export const ErrorContext = createContext<ErrorContextProps>({
  error: null,
  setError: () => {},
})

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<string | null>(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (error) {
      notifications.show({
        title: t('general_error_title'),
        message: t('general_error_message_data_not_loaded') + ' ' + error,
        color: 'red',
        icon: <IconX />,
      })
    }
  }, [error])

  return (
    <ErrorContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
