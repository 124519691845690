import { Layout } from 'components'
import { ReportsGroup as LegacyReportsGroup } from '@legacy/common/components/apps/reports_group'
import { CurrentUserContextProvider } from '../../context/useCurrentUser/useCurrentUser'

export function ReportsGroup() {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <LegacyReportsGroup />
      </Layout>
    </CurrentUserContextProvider>
  )
}
