import { IStudentsGroup } from '../../../services/students-types'
import { IPayload, QuestionNumbers } from './reports-group-interfaces'

export function getPayloadParams(
  question: QuestionNumbers,
  payload?: IStudentsGroup,
  gradeId?: string,
  currentYear?: string,
): IPayload {
  if (!payload) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {} as IPayload
  }
  switch (question) {
    case 128:
      return {
        resource: { question: 128 },
        params: {
          curriculum_school_id: payload.curriculumSchoolId,
          curriculum_id: payload.curriculumId,
          cycle_id: payload.cycleId,
          group_school_term_id: payload.groupSchoolTermId,
        },
      }
    case 129:
      return {
        resource: { question: 129 },
        params: {
          curriculum_school_id: payload.curriculumSchoolId,
          curriculum_id: payload.curriculumId,
          cycle_id: payload.cycleId,
          group_school_term_id: payload.groupSchoolTermId,
          grade_id: gradeId ?? '',
          school_year_id: currentYear ?? '',
        },
      }
    case 130:
      return {
        resource: { question: 130 },
        params: {
          curriculum_id: payload.curriculumId,
          curriculum_school_id: payload.curriculumSchoolId,
          group_school_term_id: payload.groupSchoolTermId,
        },
      }
    default:
      throw new Error('Not implemented yet.')
  }
}
