export const getStudentMenu = (userID: string | undefined) => {
  const studentMenu = [
    {
      link: '/student_room/dashboard',
      key: 'dashboard',
      icon: 'icon-home',
    },
    {
      link: '/projects',
      key: 'projects',
      icon: 'icon-activities',
    },
    {
      link: userID ? `/students/${userID}/student_mosaics` : null,
      key: 'student_mosaics',
      icon: 'icon-mosaic',
    },
    {
      link: '/tasks',
      key: 'tasks',
      icon: 'icon-pendencies',
    },
    {
      link: '/school_term_assessments',
      key: 'school_term_assessments',
      icon: 'icon-reports',
    },
  ]
  return studentMenu
}
