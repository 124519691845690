import { useContext } from 'react'
import { ProjectListContext } from '../../context/ProjectListContext'
import { Grid, ProjectCard, Button, Stack, Pagination, Center, EmptyStateBox, Box } from 'components'
import { IProject } from '../../interfaces'
import { useTranslation } from 'react-i18next'
import { IconArticleOff } from '@tabler/icons-react'
import { ProjectActions } from '../ProjectActions'

interface ProjectCardListProps {
  projects: IProject[]
}

export const ProjectCardList = ({ projects }: ProjectCardListProps) => {
  const { t } = useTranslation()
  const { activePage, setActivePage, totalPages } = useContext(ProjectListContext)

  return (
    <>
      {projects && projects.length > 0 ? (
        <Stack spacing='md' mt='md'>
          <Grid align='stretch'>
            {projects.map((project: IProject) => {
              const projectEditURL = `/projects/${project.id}/edit`
              const projectViewURL = `/projects/${project.id}`

              const actions =
                project.actions && project.actions.edit !== '' ? (
                  <ProjectActions project={project} projectViewURL={projectViewURL} projectEditURL={projectEditURL} />
                ) : (
                  <Button variant='outline' size='md' radius='xl'>
                    {t('new_projects_list.view_project')}
                  </Button>
                )

              const badgesList = [
                {
                  label: t(`modality.${project.modality}`),
                  variant: 'filled',
                  color: 'turquoise',
                  size: 'lg',
                },
              ]

              if (project.isTemplate === true) {
                badgesList.push({
                  label: t('new_projects_list.badge_template'),
                  variant: 'filled',
                  color: 'orange',
                  size: 'lg',
                })
              }

              return (
                <Grid.Col span={12} md={6} lg={4} key={project.id}>
                  <ProjectCard
                    project={project}
                    actions={actions}
                    key={project.id}
                    badges={badgesList}
                    projectList={true}
                  />
                </Grid.Col>
              )
            })}
          </Grid>
          <Center>
            <Pagination value={activePage} onChange={setActivePage} total={totalPages} />
          </Center>
        </Stack>
      ) : (
        <Box mt='md'>
          <EmptyStateBox withBorder icon={<IconArticleOff />} text={t('new_projects_list.no_items_found')} />
        </Box>
      )}
    </>
  )
}
