import { useTranslation } from 'react-i18next'
import { useContext, useState, useEffect, useRef } from 'react'
import {
  Modal,
  Stack,
  Button,
  TextInput,
  Box,
  CardAvatarCheckboxModal,
  Badge,
  Skeleton,
  ActionIcon,
  Text,
  Flex,
} from 'components'
import { ScrollArea, useListState, useMantineTheme, useMediaQuery } from 'hooks'
import { notifications } from 'context'
import { ParticipantsContext, IStudent } from '../../context/ParticipantsContext'
import { IconCheck, IconSearch, IconX } from '@tabler/icons-react'
import { useStyles } from './OtherStudentsModalStyles'
import { v4 as uuid } from 'uuid'
import { ChangedDataConfirmModal } from 'components/ChangedDataConfirmModal'
const DELAY = 300

interface StudentValuesProps {
  id: string
}

export function OtherStudentsModal({ loading }: { loading: boolean }) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [studentValues, studentHandlers] = useListState<StudentValuesProps>()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState<IStudent[]>([])
  const [showLostadaModal, setShowLostadaModal] = useState<boolean>(false)
  const [itemsCount, setItemsCount] = useState<number>(10)
  const sentinelRef = useRef<HTMLDivElement>(null)

  const fetchMoreData = () => {
    setItemsCount(prevCount => prevCount + 10)
  }

  const {
    studentsModalOpened,
    closeStudentsModal,
    otherStudents,
    selectedOtherStudents,
    selectedOtherStudentsHandlers,
  } = useContext(ParticipantsContext)

  const handleCheckChange = (checked: boolean, id: string) => {
    if (checked === true) {
      studentHandlers.append({ id: id })
    }

    if (checked === false) {
      const studentPosition = studentValues.findIndex(student => student.id === id)
      studentHandlers.remove(studentPosition)
    }
  }

  const checkStudentAdded = (id: string) => {
    const studentAdded = selectedOtherStudents.find(student => student.id === id)
    if (studentAdded) {
      return true
    } else {
      return false
    }
  }

  const filterStudents = (students: IStudent[], term: string) => {
    if (!Array.isArray(students)) {
      return []
    }

    const lowerCaseTerm = term.toLowerCase()

    return students.filter(
      student => typeof student.name === 'string' && student.name.toLowerCase().includes(lowerCaseTerm),
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const filteredArray = filterStudents(otherStudents, searchTerm).sort(
        (a, b) => +checkStudentAdded(b.id) - +checkStudentAdded(a.id),
      )
      const filteredData = [...filteredArray]
      setFilteredData(filteredData)
    }, DELAY)

    return () => clearTimeout(timer)
  }, [otherStudents, searchTerm, setFilteredData, studentsModalOpened])

  useEffect(() => {
    const filteredArray = filterStudents(otherStudents, searchTerm).sort(
      (a, b) => +checkStudentAdded(b.id) - +checkStudentAdded(a.id),
    )
    setFilteredData(filteredArray)
  }, [otherStudents, studentsModalOpened])

  const addStudents = () => {
    if (studentValues.length > 0) {
      const newStudents = studentValues.map(student =>
        selectedOtherStudents.find(otherStudent => otherStudent.id === student.id)
          ? null
          : otherStudents.find(otherStudent => otherStudent.id === student.id),
      ) as IStudent[]
      const filteredNewStudents = newStudents.filter(student => student !== null)
      const addStudents = selectedOtherStudents.concat(filteredNewStudents)
      const uniqueStudents = new Set()
      const uniqueAddStudents = addStudents.filter(student => {
        if (!uniqueStudents.has(student.id)) {
          uniqueStudents.add(student.id)
          return true
        }
        return false
      })

      selectedOtherStudentsHandlers.setState(uniqueAddStudents)
      studentHandlers.setState([])

      notifications.show({
        message: t('new_project_page.message_students_added'),
        color: 'green',
        icon: <IconCheck size={24} />,
      })
      closeStudentsModal()
    }
    if (studentValues.length === 0) {
      notifications.show({
        message: t('new_project_page.message_no_students_to_add'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    }
  }

  const sentinelHandler: IntersectionObserverCallback = entries => {
    const sentinel = entries[0]

    if (sentinel.isIntersecting) {
      fetchMoreData()
    }
  }

  useEffect(() => {
    if (studentsModalOpened === false) {
      setSearchTerm('')
      setShowLostadaModal(false)
      setItemsCount(10)
    }
    const observer = new IntersectionObserver(sentinelHandler)

    setTimeout(() => {
      const sentinel = sentinelRef.current
      if (sentinel) {
        observer.observe(sentinel)
      }
    }, 1000)

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [sentinelRef, studentsModalOpened])

  const handleCloseModal = () => {
    const hasStudentValues = studentValues && studentValues.length > 0

    if (hasStudentValues) {
      setShowLostadaModal(true)
      return
    }

    studentHandlers.setState([])
    closeStudentsModal()
  }

  const handleAlertDataModal = () => {
    setShowLostadaModal(false)
  }

  const handleAlertDataModalDiscart = () => {
    setShowLostadaModal(false)
    studentHandlers.setState([])
    closeStudentsModal()
  }

  return (
    <Modal
      zIndex={100}
      size='lg'
      opened={studentsModalOpened}
      onClose={handleCloseModal}
      scrollAreaComponent={ScrollArea.Autosize}
      title={t('new_project_page.add_students_other_groups')}
      fullScreen={isMobile}
    >
      <ChangedDataConfirmModal
        opened={showLostadaModal}
        close={handleAlertDataModal}
        discard={handleAlertDataModalDiscart}
      />

      <Box className={classes.search}>
        <TextInput
          mt={0}
          w={'100%'}
          icon={<IconSearch size='1.1rem' stroke={1.5} />}
          radius='xl'
          size='md'
          placeholder={t('new_project_page.search_students_by_name')}
          onChange={e => setSearchTerm(e.target.value)}
          value={searchTerm}
          rightSection={
            <ActionIcon
              onClick={() => {
                setSearchTerm('')
              }}
            >
              <IconX size='1.1rem' stroke={1.5} />
            </ActionIcon>
          }
        />
      </Box>

      {loading && (
        <Stack spacing='sm' mt='90px' className={classes.content}>
          {Array.from({ length: 10 }).map(() => (
            <Skeleton key={uuid()} width='100%' height={72}></Skeleton>
          ))}
        </Stack>
      )}

      <Stack spacing='sm' mt='90px' className={classes.content}>
        {filteredData.length > 0 ? (
          filteredData.slice(0, itemsCount).map(student => {
            return (
              <CardAvatarCheckboxModal
                selecteds={studentValues}
                key={student.id}
                id={student.id}
                avatar={student.avatar}
                name={student.name}
                value={student.id}
                onChange={value => handleCheckChange(value, student.id)}
                tag={checkStudentAdded(student.id) ? <Badge>{t('new_project_page.added')}</Badge> : undefined}
                disabled={checkStudentAdded(student.id)}
              />
            )
          })
        ) : (
          <Flex>
            <Text>{!loading && t('new_project_page.empty_data')} </Text>
          </Flex>
        )}
        <div ref={sentinelRef} style={{ height: '10px', background: 'white', width: '100%' }} />
      </Stack>

      <Box className={classes.footer}>
        <Button variant='default' size='lg' onClick={handleCloseModal}>
          {t('general_buttons_cancel')}
        </Button>
        <Button variant='filled' size='lg' onClick={() => addStudents()}>
          {t('general_buttons_add')}
        </Button>
      </Box>
    </Modal>
  )
}
