/* global I18n */
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const FilesUploadProgressContainer = (props) => {
  const translateKey = "components_molecules_files_upload_progress_container";
  const { uploadingFiles, abortUpload } = props;
  const fileNames = Object.keys(uploadingFiles);
  const { t } = useTranslation();

  if (fileNames.length === 0) return false;

  return (
    <div className="file-upload-progress-container">
      {fileNames.map((fileName) => {
        const uploadFile = uploadingFiles[fileName];
        const completed = uploadFile.progressPercent === 100;

        return (
          <div key={fileName} className="progress-box">
            {uploadFile.error && (
              <Fragment>
                <i className="icon-common-alert error" />
                <div className="error-message">
                  {t(`${translateKey}_error`)}{" "}
                  <span className="label">{uploadFile.label}</span>(
                  {uploadFile.error})
                </div>
              </Fragment>
            )}

            {!uploadFile.error && (
              <Fragment>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${uploadFile.progressPercent}%` }}
                    aria-valuenow={uploadFile.progressPercent}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {completed
                      ? t(`${translateKey}_processing`)
                      : `${uploadFile.progressPercent}%`}
                  </div>
                </div>
                <div className="label">{uploadFile.label}</div>
                <div className="file-size">{uploadFile.size}</div>
              </Fragment>
            )}
            {!completed && (
              <i
                role="button"
                tabIndex={0}
                className="icon-close-full right"
                onClick={() => abortUpload(fileName)}
                onKeyPress={() => abortUpload(fileName)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

FilesUploadProgressContainer.propTypes = {
  uploadingFiles: PropTypes.object.isRequired,
  abortUpload: PropTypes.func.isRequired,
};

export default FilesUploadProgressContainer;
