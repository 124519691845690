import { Modal, Stack, Text, Group, Button } from 'components'
import { useTranslation } from 'react-i18next'

interface ChangedDataConfirmModalProps {
  opened: boolean
  close: () => void
  discard: () => void
}

export const ChangedDataConfirmModal = ({ opened, close, discard }: ChangedDataConfirmModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal size='lg' opened={opened} onClose={close} title={t('general_changed_confirmation_modal_title')} centered>
      <Stack spacing='xs'>
        <Text>{t('general_changed_confirmation_modal_message')}</Text>
        <Group mt='md' position='right'>
          <Button size='lg' variant='default' onClick={close}>
            {t('general_changed_confirmation_modal_cancel')}
          </Button>
          <Button size='lg' variant='filled' onClick={discard}>
            {t('general_changed_confirmation_modal_discard')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  )
}
