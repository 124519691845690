import { useContext, useEffect } from 'react'
import { useCurrentUser } from 'context'
import { TeacherDashboardContext } from '../../context/TeacherDashboardContext'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { DashboardHeader, Stack } from 'components'
import { TasksList } from '../TasksList'
import { GroupsList } from '../GroupsList'
import { ProjectsList } from '../ProjectsList'
import { getCurrentTasks, getCurrentGroups, getCurrentProjects } from '../../service/teacherDashboard'
import { ErrorContext } from 'context'

export const TeacherDashboard = () => {
  const { currentUser } = useCurrentUser()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { t } = useTranslation()
  const { setError } = useContext(ErrorContext)
  const { setTasks, setProjects, setGroups } = useContext(TeacherDashboardContext)

  const projectKind = [
    {
      value: 'draft',
      label: t('project_draft'),
    },
    {
      value: 'approved',
      label: t('project_progress'),
    },
  ]

  useEffect(() => {
    const loadDashBoardData = async () => {
      try {
        const tasksData = await getCurrentTasks()
        if (tasksData.status === 200) {
          setTasks(tasksData.data)
        } else {
          setError(tasksData.statusText)
        }

        const projectsData = await getCurrentProjects()
        if (projectsData.status === 200) {
          setProjects(projectsData.data)
        } else {
          setError(projectsData.statusText)
        }

        const groupsData = await getCurrentGroups()
        if (groupsData.status === 200) {
          const groups = groupsData.data
          setGroups(groups)
        } else {
          setError(groupsData.statusText)
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
        }
      }
    }
    loadDashBoardData()
    document.title = t('page_title', { page_name: t('new_teacher_dashboard.page_title') })
  }, [])

  return (
    <>
      <DashboardHeader name={currentUser?.full_name} isMobile={isMobile} />
      <Stack spacing='md' mt={isMobile ? 0 : 80}>
        <TasksList />
        <ProjectsList projectKind={projectKind} />
        <GroupsList />
      </Stack>
    </>
  )
}
