import { createStyles } from 'hooks'

export const useStyles = createStyles(theme => ({
  backgroundBox: {
    width: '100%',
    height: '100vh',
    backgroundPositionY: 'bottom',
    backgroundSize: 'auto 40%', 
    backgroundRepeat: 'no-repeat',
  },
}))
