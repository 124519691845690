import { useTranslation } from 'react-i18next'
import {
  DropdownMenuRadioGroup,
} from 'components/DropdownMenu'

import { useCurrentUser } from 'context/useCurrentUser'

import {
  DropdownMenuSeparatorStyled,
  DropdownMenuLabelStyled,
} from '../NavbarMenuStyled'

import { ManagerProfileButton } from './ManagerProfileButton'
import { TutorProfileButton } from './TutorProfileButton'
import { GuardianProfileButton } from './GuardianProfileButton'
import { StudentProfileButton } from './StudentProfileButton'


export const SwitchProfileMenu = () => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  return (
    <>
      <DropdownMenuLabelStyled>
        {t('switch_profile')}
      </DropdownMenuLabelStyled>
      <DropdownMenuRadioGroup value={currentUser?.profile_used}>
        <ManagerProfileButton />
        <TutorProfileButton />
        <StudentProfileButton />
        <GuardianProfileButton />
      </DropdownMenuRadioGroup>
      <DropdownMenuSeparatorStyled />
    </>
  )
}
