import { Component } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import Recorder from "recorder-js";
import { msToFormattedTime } from "../Tools/Common";
import ButtonCard from "../_components/ButtonCard";
import ErrorService from "../Tools/ErrorService";
import i18n from "../../../i18n";

const HALF_SEC = 500;

const AudioContext = global.AudioContext || global.webkitAudioContext;
let globalAudioContext = null;

export class SafariRecorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      blobData: {},
      blob: undefined,
      recording: false,
      renderMic: false,
      playing: false,
      startedAt: undefined,
      currentTime: 0,
      totalTime: 0,
      recorder: null,
      audioContext: null,
      stream: null,
      contexton: false,
    };

    this.initContext = this.initContext.bind(this);
    this.stop = this.stop.bind(this);
  }

  onEnded() {
    this.setState(
      {
        currentTime: 0,
      },
      () => this.stopTimer("playing")
    );
  }

  onProgress(record) {
    this.setState({ startedAt: Date.now() - record.playedSeconds * 1000 });
  }

  initContext() {
    globalAudioContext = globalAudioContext || new AudioContext();
    const audioContext = globalAudioContext;
    const recorder = new Recorder(audioContext);

    this.setState({
      contexton: true,
      recorder,
      audioContext,
    });
  }

  startTimer(field) {
    this.setState((prevState) => {
      const state = prevState;
      if (field === "recording") state.renderMic = true;
      state[field] = true;
      state.startedAt = Date.now() - state.currentTime;
      return state;
    });
    this.timer = setInterval(
      () =>
        this.setState((prevState) => {
          const newTime = Date.now() - prevState.startedAt;
          return {
            currentTime:
              newTime > prevState.totalTime && prevState.playing
                ? prevState.totalTime
                : newTime,
          };
        }),
      250
    );
  }

  stopTimer(field) {
    this.setState((prevState) => {
      const state = prevState;
      if (field === "recording") {
        state.totalTime = Date.now() - state.startedAt;
        state.currentTime = 0;
      } else if (state.currentTime > 0) {
        state.currentTime = Date.now() - state.startedAt;
      }
      state[field] = false;
      state.startedAt = undefined;
      return state;
    });
    clearInterval(this.timer);
  }

  startRecording() {
    this.startTimer("recording");
    this.rec();
  }

  rec() {
    if (!this.state.contexton) this.initContext();
    navigator.mediaDevices
      .getUserMedia({ audio: true })

      .then((stream) => {
        this.setState({ stream, enableRec: false, enableStop: true });

        this.state.recorder.init(stream);
        this.state.recorder.start();
      })
      .catch((err) => ErrorService.notice(err));
  }

  stop() {
    this.state.stream.getAudioTracks()[0].stop();
    this.state.recorder
      .stop()

      .then(({ blob, buffer }) => {
        const url = URL.createObjectURL(blob);

        this.setState({ blobData: url, blob });
      });
  }

  stopRecording() {
    this.stopTimer("recording");
    this.stop();
  }

  playRecord(playing) {
    if (playing) {
      return this.startTimer("playing");
    }
    return this.stopTimer("playing");
  }

  clearRecord() {
    this.setState({
      blobData: {},
      currentTime: 0,
      totalTime: 0,
    });
  }

  save() {
    const file = new File([this.state.blob], `audio_${Date.now()}.wav`, {
      type: this.state.blob.type,
    });
    this.props.submitFile(file);
    this.clearRecord();
  }

  recordButton(icon, className, callback) {
    const prefix = icon === "check" ? "icon-common-" : "icon-";

    return (
      <i
        role="button"
        tabIndex={0}
        className={`${prefix}${icon} ${className} cursor-pointer`}
        onClick={callback}
        onKeyPress={callback}
      />
    );
  }

  render() {
    const { currentTime, totalTime, recording, playing, blobData, renderMic } =
      this.state;
    const blobDataPresent = Object.keys(blobData).length > 0;
    const translateKey = "components_molecules_recorder";

    if (blobDataPresent && !recording) {
      return (
        <div className="recorder mt-4">
          <button
            className={`new-buttons new-buttons-light new-buttons-small recorded ${
              playing ? "playing" : ""
            }`}
            type="button"
            onClick={() => this.playRecord(!playing)}
            tabIndex={0}
          >
            <i className="icon-sm-mic" />
            <span className="time-text">
              {msToFormattedTime(currentTime + HALF_SEC)} /{" "}
              {msToFormattedTime(totalTime)}
            </span>
            <i className={`icon-${playing ? "pause" : "play"}`} />
          </button>

          <ReactPlayer
            url={blobData}
            className="d-none"
            playing={playing}
            onEnded={() => this.onEnded()}
            onProgress={(record) => this.onProgress(record)}
          />

          <span className="save-record">{i18n.t(`${translateKey}_save`)}</span>
          {this.recordButton("check", "yellow-color", () => this.save())}
          {this.recordButton("close-full", "mediumgray-color", () =>
            this.clearRecord()
          )}
        </div>
      );
    }

    return (
      <div className="recorder mt-4">
        {this.state.recording ? (
          <button
            className="new-buttons new-buttons-light new-buttons-small recording"
            type="button"
            onClick={() => this.stopRecording()}
            onKeyPress={() => this.stopRecording()}
            tabIndex={0}
          >
            <i className="icon-sm-mic" />
            <span className="time-text">{msToFormattedTime(currentTime)}</span>
            <i className="icon-stop" />
          </button>
        ) : (
          <ButtonCard
            callback={() => this.startRecording()}
            description={i18n.t(`${translateKey}.record_audio`)}
            iconClass="icon-sm-mic"
            className="mr-5"
          />
        )}
      </div>
    );
  }
}

SafariRecorder.propTypes = {
  submitFile: PropTypes.func.isRequired,
};

export default SafariRecorder;
