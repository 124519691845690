import { Global } from '@mantine/core'

export default function MosaicoGlobalStyles() {
  return (
    <Global
      styles={theme => ({
        '*, *::before, *::after': { boxSizing: 'border-box' },
        body: {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#F5F5F5',
          color:
            theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
          lineHeight: theme.lineHeight,
        },
        // ...other global styles
      })}
    />
  )
}
