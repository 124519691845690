import { Suspense, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { MantineProvider, ColorScheme, ColorSchemeProvider } from '@mantine/core'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { ErrorProvider } from 'context'
import { AuthProvider, ModalsProvider, FeatureToggleProvider } from 'context'
import { OfflineBanner } from 'components/OfflineBanner'
import { Routes } from './routes'
import Theme from './themes'
import MosaicoGlobalStyles from './themes/MosaicoGlobalStyles'
import { MosaicoTheme } from './themes/MosaicoTheme'
import connectProduktlyHook from './hooks/connectProduktlyHook'
import connectOpenReplayHook from './hooks/connectOpenReplayHook'

import './i18n'
import './@legacy/assets/stylesheets/bootstrap-v4.0.0.css'
import './app.css'
import './@legacy/assets/stylesheets/all.scss'
import useCurrentUser from '@legacy/common/hooks/current-user-hook'

const queryClient = new QueryClient()
const App = () => {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  })
  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))
  useHotkeys([['mod+J', () => toggleColorScheme()]])

  const currentUrl = window.location.pathname
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (!currentUrl.split('/').includes('signin') && !currentUrl.split('/').includes('signout')) {
      connectOpenReplayHook(currentUser?.data)
      connectProduktlyHook(currentUser?.data)
    }
  }, [currentUser])

  return (
    <>
      <OfflineBanner />
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <>
            <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
              <MantineProvider
                theme={{
                  colorScheme,
                  ...MosaicoTheme(colorScheme),
                }}
                withCSSVariables
                withGlobalStyles
                withNormalizeCSS
              >
                <ModalsProvider>
                  <MosaicoGlobalStyles />
                  <Notifications position='top-right' zIndex={110000} />
                  <Theme>
                    <QueryClientProvider client={queryClient}>
                      <AuthProvider>
                        <ErrorProvider>
                          <FeatureToggleProvider>
                            <Routes />
                          </FeatureToggleProvider>
                        </ErrorProvider>
                      </AuthProvider>
                    </QueryClientProvider>
                  </Theme>
                </ModalsProvider>
              </MantineProvider>
            </ColorSchemeProvider>
          </>
        </BrowserRouter>
      </Suspense>
    </>
  )
}

const container = document.getElementById('root')

if (container) {
  const root = createRoot(container as HTMLElement)
  root.render(<App />)
} else {
  console.error('Elemento com id "root" não encontrado')
  location.reload()
}
