import styled from 'styled-components'

export const StyledOffLineBanner = styled.div`
  width: 100vw;
  min-height: 38px;
  padding: 12px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
  background: var(--color-red-lighter);
  color: var(--color-red-dark);
  text-align: center;

  & p {
    font-weight: normal;
    line-height: 2.1rem;
    margin: 0;
  }
`
