import { useState, useMemo } from 'react'
import { getInitials } from '@legacy/common/Tools/Common'
import { AvatarWrapper, Initials } from './avatarStyled'
import { IAvatar, avatarType, avatarFont } from './avatarTypes'
import { isEmpty } from 'lodash'
import ProfilePicFallbackImage from '@legacy/assets/images/perfil-pic.png'

export const Avatar = ({ name, image, size }: IAvatar) => {
  const [status, setStatus] =
    useState<'loading' | 'loaded' | 'failed'>('loading')
  const hasImage = useMemo(
    () => image !== undefined && image !== null && !isEmpty(image),
    [image],
  )

  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    if (status !== 'failed') {
      event.currentTarget.src = ProfilePicFallbackImage

      setStatus('failed')
    }
  }

  return (
    <AvatarWrapper size={avatarType[size]}>
      {hasImage && image !== null ? (
        <img
          src={image}
          width={avatarType[size]}
          height={avatarType[size]}
          alt={`${name} avatar`}
          title={name}
          onError={imageOnErrorHandler}
        />
      ) : (
        <Initials size={avatarFont[size]}>{getInitials(name)}</Initials>
      )}
    </AvatarWrapper>
  )
}

export default Avatar
