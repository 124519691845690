import { useState } from 'react'
import { WrapperEvaluate, ScoreContainer } from './StudentModalContentStyled'
import { ScoreCard } from 'components/ScoreComponent/ScoreCard'
import times from 'lodash/times'
import LV4ItemContainer from '@legacy/common/_molecules/Lv4ItemContainer'
import { IStudentAsssessmentsprops } from './EvaluativeItemTypes'
import { useTranslation } from 'react-i18next'
import { IpostScore } from '../StudentsAssessments/StudentsAssessmentsTypes'
import { IconHistory } from '@tabler/icons-react'
import { ScoreItemLabel } from 'components/ScoreComponent/ScoreItem'
import { ServerData } from '../../service/interfaces'
import { Button, Group, Stack, Checkbox, AssessmentInfo } from 'components'

const EvaluativeItem = ({ item, setPayload, openHistoryModal, fullName }: IStudentAsssessmentsprops) => {
  const [show, setShow] = useState(!item?.doNotAssess || false)
  const [score, setScore] = useState(item.masterScore)
  const { t } = useTranslation()

  const RUBRIC_KEYS = ['rubric_1', 'rubric_2', 'rubric_3', 'rubric_4', 'rubric_5']
  const DefaultModalRubrics = RUBRIC_KEYS.map(key => t(key))

  const getPayloadScore = (scoreValue: number, doNotAssess: boolean): IpostScore => {
    return {
      do_not_assess: doNotAssess,
      score: scoreValue,
      id: item.id,
    }
  }

  const updatePayload = (newScore: number, doNotAssess: boolean) => (payload: ServerData) => {
    const assessmentScore = getPayloadScore(newScore, doNotAssess)

    const finalAssessmentScores = payload.final_assessment_scores.map(score =>
      score.id !== assessmentScore.id ? score : assessmentScore,
    )
    return {
      ...payload,
      final_assessment_scores: finalAssessmentScores,
    }
  }

  const onClickScore = async (i: number, score: number) => {
    const selectedScore = i + 1
    const newScore = selectedScore === score ? 0 : selectedScore
    setScore(newScore)

    setPayload(updatePayload(newScore, false))
  }

  const onClickToggle = async () => {
    setPayload(updatePayload(0, show))
    setScore(0)
    setShow(!show)
  }

  return (
    <WrapperEvaluate>
      <Stack spacing='sm'>
        <Checkbox size='sm' checked={show} onChange={onClickToggle} label={t('enable_assessment')} />
        <LV4ItemContainer
          name={item.lv4Item.name}
          lv1Name={item.lv4Item.lv1Name}
          id={item.lv4Item.id}
          curriculumKind={'content'}
          color={item.lv4Item.color}
          originalCodes={item.lv4Item.originalCodes}
          cycles={item.lv4Item.cycles}
          rubrics={[]}
          hideContainer
        />
      </Stack>
      {show && (
        <Stack spacing='md' mt='md'>
          <ScoreContainer>
            {times(5, i => (
              <ScoreCard
                label={(i + 1) as ScoreItemLabel}
                key={i}
                active={score === i + 1}
                rubric={DefaultModalRubrics[i]}
                onClick={() => {
                  onClickScore(i, score)
                }}
              />
            ))}
          </ScoreContainer>
          <Group spacing='sm'>
            <Button variant='light' size='sm' leftIcon={<IconHistory />} onClick={openHistoryModal}>
              {t('partial_history_button')}
            </Button>
          </Group>

          {item.averagePartialEvaluations && item.averagePartialEvaluations > 0 ? (
            <AssessmentInfo
              title={t('partial_assessments_average_title')}
              info={t('partial_assessments_average_tooltip')}
              score={item.averagePartialEvaluations}
            />
          ) : null}
          {item.studentScore !== 0 && (
            <AssessmentInfo
              title={t('students_final_self_assessment', { fullName: fullName })}
              score={item.studentScore}
            />
          )}
        </Stack>
      )}
    </WrapperEvaluate>
  )
}

export default EvaluativeItem
