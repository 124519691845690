import { IReceivedStudentCurriculum, IStudentCurriculum } from '@legacy/common/models/student'
import {
  IReceivedStudentReportData,
  IReceivedStudentReportDataValue,
  IReceivedStudentReportResponse,
  IReceivedStudentsGroup,
  IReceivedStudentReportLvl1,
  IReceivedStudentReportLvl1Data,
  IReceivedStudentGroupReportResponse,
  IReceivedStudentGroupReportData,
  IReceivedStudentGroupReportDataValue
} from './students-received-types'
import {
  IStudentReportData,
  IStudentReportDataValue,
  IStudentReportResponse,
  IStudentsGroup,
  IStudentReportLvl1,
  IStudentReportLvl1Data,
  IStudentGroupReportResponse,
  IStudentGroupReportData,
  IStudentGroupReportDataValue
} from './students-types'

function curriculumsMapper (raw: IReceivedStudentCurriculum[]): IStudentCurriculum[] {
  return raw.map(item => ({
    id: item.id,
    kind: item.kind,
    name: item.name,
    contentCurriculumId: item.content_curriculum_id
  }))
}

function studentReportDataMapper (raw: IReceivedStudentReportData): IStudentReportData {
  function localMapper (localRaw: IReceivedStudentReportDataValue[]): IStudentReportDataValue[] {
    return localRaw.map(localItem => ({
      score: localItem.score,
      groupStudentsAssess: localItem.group_students_assess,
      averageItem: localItem.average_item,
      totalItems: localItem.total_items,
      totalItemsAsseses: localItem.total_items_asseses,
      lv1Item: localItem.lv1_item,
      lv4Item: localItem.lv4_item,
      lv4ItemId: localItem.lv4_item_id,
      codes: localItem.codes
    }))
  }

  const result: IStudentReportData = {}
  Object.keys(raw).map(key => {
    (result as any)[key] = localMapper((raw as any)[key])
    return key
  })

  return result
}

function studentGroupReportDataMapper (raw: IReceivedStudentGroupReportData): IStudentGroupReportData {
  function localMapper (localRaw: IReceivedStudentGroupReportDataValue[]): IStudentGroupReportDataValue[] {
    return localRaw.map(localItem => ({
      score: localItem.score,
      groupStudentsAssess: localItem.group_students_assess,
      averageItem: localItem.average_item,
      totalItems: localItem.total_items,
      totalItemsAsseses: localItem.total_items_asseses,
      lv1Item: localItem.lv1_item,
      lv4Item: localItem.lv4_item,
      lv4ItemId: localItem.lv4_item_id,
      codes: localItem.codes
    }))
  }

  const result: IStudentGroupReportData = {}
  Object.keys(raw).map(key => {
    (result as any)[key] = localMapper((raw as any)[key])
    return key
  })

  return result
}

function studentReportLevel1Mapper (raw: IReceivedStudentReportLvl1): IStudentReportLvl1 {
  function localMapper (localRaw: IReceivedStudentReportLvl1Data): IStudentReportLvl1Data {
    return {
      averageTotal: localRaw.average_total,
      percentageArea: localRaw.percentage_area
    }
  }

  const result: IStudentReportLvl1 = {}
  Object.keys(raw).map(key => {
    (result as any)[key] = localMapper((raw as any)[key])
    return key
  })

  return result
}

function level4ItemsReportMapper (raw: IReceivedStudentReportResponse): IStudentReportResponse {
  return {
    data: studentReportDataMapper(raw.data),
    dataLv1: studentReportLevel1Mapper(raw.data_lv1)
  }
}

function groupLevel4ItemsReportMapper (raw: IReceivedStudentGroupReportResponse): IStudentGroupReportResponse {
  return {
    data: studentGroupReportDataMapper(raw.data)
  }
}

function groupsMapper (raw: IReceivedStudentsGroup[]): IStudentsGroup[] {
  return raw.map((item: IReceivedStudentsGroup) => ({
    cycleId: item.cycle_id,
    curriculumId: item.curriculum_id,
    curriculumSchoolId: item.curriculum_school_id,
    name: item.name,
    groupSchoolTermId: item.group_school_term_id
  }))
}

export default {
  level4ItemsReportMapper,
  groupLevel4ItemsReportMapper,
  groupsMapper,
  curriculumsMapper
}
