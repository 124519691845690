import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { ProjectViewContext } from '../../context/ProjectViewContext'
import { Box, Paper, Text, Image, Tabs, RemoveConfirmModal, ProjectHeader, TextLink, Skeleton } from 'components'
import { useStyles } from './styles'
import { ProjectViewContent } from '../ProjectViewContent'
import { useEffect } from 'react'
import { ProjectViewSkeleton } from '../ProjectViewSkeleton'
import { getProject, getLessons, getAllTeachers } from 'api'
import { IconChevronLeft } from '@tabler/icons-react'

export const ProjectView = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const { id } = useParams()
  const projectID = id

  const {
    project,
    setProject,
    setLessons,
    teachers,
    setTeachers,
    setError,
    openedRemoveConfirmationModal,
    closeRemoveConfirmationModal,
    handleRemoveConfirmationClose,
    handleTabChange,
    handleEdit,
    handleRemove,
    handlePublish,
    handleApprove,
    handleReopen,
    handleFinish,
    setLessonsPage,
    setLessonsLoading,
    setSchoolTermEndAt,
    setLessonsPagination,
    setFinalAssessmentStarted,
  } = useContext(ProjectViewContext)

  useEffect(() => {
    if (projectID && projectID !== '') {
      const getData = () => {
        setLessonsLoading(true)
        getProject(projectID)
          .then(response => {
            if (response.status === 200) {
              setProject(response.data)
              document.title = t('page_title', { page_name: response.data.name })
              setFinalAssessmentStarted(response.data.startFinalAssessment)
              if (response.data.kind !== 'template') {
                getAllTeachers(projectID)
                  .then(response => {
                    if (response.status === 200) {
                      setTeachers(response.data)
                    } else {
                      setError(response.statusText)
                    }
                  })
                  .catch(err => {
                    setError(err.message)
                  })
              }
            } else {
              setError(response.statusText)
            }
          })
          .catch(err => {
            setError(err.message)
          })

        setLessonsPage(1)
        getLessons(projectID, 1)
          .then(response => {
            if (response.status === 200) {
              setLessons(response.data.sessionMeetings)
              setSchoolTermEndAt(response.data.schoolTermEndAt)
              setLessonsPagination(response.data.pagination)
              setLessonsLoading(false)
            } else {
              setError(response.statusText)
            }
          })
          .catch(err => {
            setError(err.message)
          })
      }
      getData()
    }
  }, [projectID])

  const backlink = useMemo(() => {
    const statusLibrary = project?.status == 'published' || project?.status == 'reviewing'
    return project?.kind === 'template' && statusLibrary ? '/projects_library' : '/projects'
  }, [project?.kind])

  return (
    <>
      {project !== null && (
        <RemoveConfirmModal
          type={project.kind === 'template' ? 'project_template' : 'project'}
          opened={openedRemoveConfirmationModal}
          onClose={closeRemoveConfirmationModal}
          actionCancelConfirm={() => handleRemoveConfirmationClose(project.id)}
        />
      )}
      {project !== null ? (
        <TextLink href={backlink} size='md' iconLeft={<IconChevronLeft />}>
          {t('new_project_view.view_all')}
        </TextLink>
      ) : (
        <Skeleton height={20} width={140} />
      )}
      <Paper radius='md' withBorder shadow='xs' p={0} mt='xs'>
        <Image
          withPlaceholder
          width='100%'
          height={240}
          src={project !== null ? project.coverImage : ''}
          className={classes.root}
        />
        <Box p={isMobile ? 'md' : 'xl'}>
          {project === null && <ProjectViewSkeleton />}
          {project !== null && (
            <>
              <ProjectHeader
                project={project}
                teachers={teachers}
                handleEdit={handleEdit}
                handleRemove={handleRemove}
                handlePublish={handlePublish}
                handleApprove={handleApprove}
                handleReopen={handleReopen}
                handleFinish={handleFinish}
              />
              <Tabs mt='lg' defaultValue='General view' onTabChange={handleTabChange}>
                <Tabs.List>
                  <Tabs.Tab value='General view'>
                    <Text size='md'>{t('new_project_view.tab_general_view')}</Text>
                  </Tabs.Tab>
                  {project.status !== 'draft' && (
                    <Tabs.Tab value='Project wall'>
                      <Text size='md'>{t('new_project_view.tab_project_wall')}</Text>
                    </Tabs.Tab>
                  )}
                </Tabs.List>
                <Tabs.Panel value='General view' pt='md'>
                  <ProjectViewContent />
                </Tabs.Panel>
                <Tabs.Panel value='Project wall' pt='md'>
                  <Text>{t('new_project_view.tab_project_wall_content')}</Text>
                </Tabs.Panel>
              </Tabs>
            </>
          )}
        </Box>
      </Paper>
    </>
  )
}
