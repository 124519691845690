import { useParams } from 'react-router-dom'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { Layout } from 'components/Layout'
import MeetingAssessment from '@legacy/pages/SessionMeetingAssessment/MeetingAssessment' 

export const EditReport = () => {
  const { lessonId } = useParams()
  const meettingId = lessonId
  return (
    <CurrentUserContextProvider>
      <Layout>
        <MeetingAssessment meetingId={meettingId} />
      </Layout>
    </CurrentUserContextProvider>
  )
}
