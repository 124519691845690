import styled from 'styled-components'

export const Container = styled.div` 
  .flex {
    display: flex;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media print {
    .hide-in-print,
    .project-container-image  {
      display: none;
    } 
  }
`
