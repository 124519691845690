import { api } from 'helpers'
import { AxiosResponse } from 'axios'

export async function getGuardianStudents(): Promise<AxiosResponse> {
  try {
    const response = await api.get('/api/guardians/students')
    return response
  } catch (error: any) {
    return error
  }
}

export async function getGuardianSchoolTermsByStudent(id: string): Promise<AxiosResponse> {
  try {
    const response = await api.get(`/api/guardians/terms_by_student/${id}`)
    return response
  } catch (error: any) {
    return error
  }
}

export async function getGuardianProjectsByStudent(schoolTermID: string, studentID: string): Promise<AxiosResponse> {
  try {
    const response = await api.get(`/api/guardians/projects_by_term_student/${schoolTermID}/${studentID}`)
    return response
  } catch (error: any) {
    return error
  }
}

export async function getGuardianStudentLastReport(id: string): Promise<AxiosResponse> {
  try {
    const response = await api.get(`/api/guardians/student_last_report/${id}`)
    return response
  } catch (error: any) {
    return error
  }
}

export async function patchDisableGuardianStudentReport(id: string): Promise<AxiosResponse> {
  const payload = {
    school_term_assessment_id: id,
  }

  try {
    const response = await api.patch('/api/guardians/desable_warning_report', payload)
    return response
  } catch (error: any) {
    return error
  }
}

export async function getFinalReportJSON(id: string): Promise<AxiosResponse> {
  try {
    const response = await api.get(`/end_of_term_reports/${id}/final.json`)
    return response
  } catch (error: any) {
    return error
  }
}
