export interface ILessonAssessmentHistory {
  date: string
  title: string
  assessments: {
    educator_score: number
    educator_journal: string
    evaluator_user: {
      name: string
      avatar: string
    }
    student_score: number
    student_journal: string
  }
}
export const ILessonAssessmentHistoryInitalValues = [
  {
    date: '',
    title: '',
    assessments: {
      educator_score: 0,
      educator_journal: '',
      evaluator_user: {
        name: '',
        avatar: '',
      },
      student_score: 0,
      student_journal: '',
    },
  },
]
