import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useLocalizedTime, useLocalizedDate, addMinutesToDateStr } from 'hooks'
import { LessonsContext } from 'components'
import { Stack, Group, Box, Text, ActionIcon, Button } from 'components'
import { IconEdit, IconTrash, IconClipboardPlus } from '@tabler/icons-react'
import { LessonHeaderProps } from './interfaces'

export const LessonHeader = ({ lesson, editable, kind }: LessonHeaderProps) => {
  const theme = useMantineTheme()
  const { t, i18n } = useTranslation()

  const { handleEditLesson, handleRemoveLesson, handleAddActivity } = useContext(LessonsContext)
  const localizeTime = useLocalizedTime()
  const localizeDate = useLocalizedDate(i18n.language || 'pt-BR')
  const isApplication = kind === 'application'

  return (
    <Stack mb='md' mt='md' spacing='xs' align='flex-start'>
      <Group spacing='sm' align='start'>
        <Box>
          {isApplication && lesson && lesson?.date !== null && lesson?.time && lesson?.duration && (
            <Text size='lg' span fw='bold'>
              {`${localizeDate(lesson?.date)} (${localizeTime(lesson?.time)} - ${addMinutesToDateStr(
                lesson?.time,
                Number(lesson?.duration),
                i18n.language || 'pt-BR',
              )}) - `}
            </Text>
          )}
          <Text size='lg' span>
            {lesson.title}
          </Text>
        </Box>
        {editable && (
          <Group spacing='sm' align='center'>
            {lesson.sessionMeetingActions.edit && (
              <ActionIcon color={theme.colors.turquoise[6]} onClick={() => handleEditLesson(lesson.id)}>
                <IconEdit size={24} />
              </ActionIcon>
            )}
            {lesson.sessionMeetingActions.delete && (
              <ActionIcon color={theme.colors.turquoise[6]} onClick={() => handleRemoveLesson(lesson.id)}>
                <IconTrash size={24} />
              </ActionIcon>
            )}
            {lesson.sessionMeetingActions.new && (
              <Button
                leftIcon={<IconClipboardPlus />}
                variant='light'
                size='sm'
                onClick={() => handleAddActivity(lesson.id)}
              >
                {t('new_project_page.add_activity')}
              </Button>
            )}
          </Group>
        )}
      </Group>
    </Stack>
  )
}
