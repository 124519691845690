/* eslint-disable max-len */
import PropTypes from "prop-types";

const GoogleDrive = ({ width, height, fill }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fill}
      stroke={fill}
      strokeWidth="0.5"
      d="M14.825 13.849l1.995-3.69a.467.467 0 00-.006-.45L11.474.48a.465.465 0 00-.4-.23H6.926a.463.463 0 00-.4.23s0 0 0 0 0 0 0 0v.001l-5.34 9.227a.467.467 0 00-.006.45l1.995 3.69c.08.15.238.244.406.244h10.837c.168 0 .325-.09.407-.243zm-10.445-.68l1.599-2.768h9.661l-1.496 2.768H4.38zM6.925 1.633l1.526 2.64-4.857 8.412-1.48-2.738 4.811-8.314zm2.059 3.563l2.473 4.282H6.511l2.473-4.282zm1.824-4.023l4.806 8.305h-3.091L9.384 4.043s0 0 0 0l-1.659-2.87h3.083z"
    />
  </svg>
);

GoogleDrive.defaultProps = {
  fill: "var(--color-black)",
  width: 18,
  height: 15,
};

GoogleDrive.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

export default GoogleDrive;
