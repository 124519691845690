import _ from 'lodash'
import { AvatarCard } from './components/AvatarCard'
import { ProjectShowDetail } from './components/ProjectShowDetail'
import { ShowEntireTeamButton } from './components/ShowEntireTeamButton'
import { IProjectSubheader } from './interfaces'
import { AvatarContainer, ProjectSubheaderContainer } from './styled'

export const ProjectSubheader = ({ project }: { project?: IProjectSubheader }) => {

  if (!project) return <></>

  const {
    tutors,
  } = project

  const tutorsWithMainFirst = tutors.sort((a, b) => b.main_tutor - a.main_tutor)

  return (
    <ProjectSubheaderContainer>
      <ProjectShowDetail project={project}/>
        <AvatarContainer>
          <AvatarCard tutor={tutorsWithMainFirst[0]}/>
          <ShowEntireTeamButton tutors={tutorsWithMainFirst}/>
        </AvatarContainer>
    </ProjectSubheaderContainer>
  )
}
