import {
  ILessonCardActivity,
  IActivity,
  ISessionMeetingAction,
  ISessionMeetingStatus,
  ISessionMeetingActivityActions,
} from 'interfaces'

export interface ILesson {
  readonly id: string
  readonly title: string
  readonly projectName: string
  readonly lessonName: string
  readonly time: string
  readonly date: string
  readonly hour: string
  readonly timeStart: string
  readonly timeEnd: string
  readonly duration: string
  readonly datetime: string
  readonly enablePendencyJournal: boolean
  readonly journal: string
  readonly canFillEditReport: boolean
  readonly editReportUrl: string
  readonly activities: string[] & ILessonCardActivity[]
  readonly status?: string
  readonly projectUrl?: string
  readonly loadDashBoardData?: () => void
  readonly sessionMeetingActions: ISessionMeetingAction
  readonly sessionMeetingStatus: ISessionMeetingStatus
  readonly sessionMeetingActivities: IActivity[]
  readonly sessionMeetingActivityActions: ISessionMeetingActivityActions
}
