export interface ProjectProps {
  name?: string
  stages?: string
  kind?: string
  purpose?: string
  modality?: string
  synopsis?: string
  objectives?: string
  finalProduct?: string
  finalProductKind?: string
  finalProductDescription?: string
  guidingQuestion?: string
  grades: any
  shareCommunity?: boolean
  fileCover?: string | null
  school_term_id?: string
  group_school_term_id?: string
  students_attributes?: string[]
  project_teachers_attributes?: string[]
  evaluationTools?: any[]
  main_tutor_id?: string
  schoolTermId?: string
  mainTutorId?: string
  coverImage: string
  status?: string
  tutorGuest?: boolean
}

interface ProjectTeacher {
  id: string
  full_name: string
  avatar_small: string
  role: string
}

interface AnotherStudent {
  value: string
  label: string
  avatar_middle: string
}

export interface ActionsTabsInterface {
  tabOne: boolean
  tabTwo: boolean
  tabThree: boolean
  tabFour: boolean
}

export const initialActionsTabs: ActionsTabsInterface = { tabOne: true, tabTwo: false, tabThree: false, tabFour: false }

export type projectKind = 'application' | 'template' | null

export interface Tab2Data {
  kind: projectKind
  status: any
  schoolTermId: string
  groupSchoolTermId: string
  mainTutorId: string
  studentsAttributes: string[]
  projectTeachersAttributes: string[]
  projectTeachers: ProjectTeacher[]
  anotherStudents: AnotherStudent[]
  actionsTabs: ActionsTabsInterface
}

export interface ProjectCreateProps {
  readonly id: string
}

export interface GradeProps {
  readonly id: string
  readonly name: string
}

export interface DevelopmentStageProps {
  readonly id: string
  readonly name: string
  readonly grades: GradeProps[]
}

export interface ModalityProps {
  readonly id: string
  readonly name: string
}

export interface FinalProductKindProps {
  readonly id: string
  readonly label: string
}

export interface ModalitiesAndFinalProductsProps {
  readonly modalities: ModalityProps[]
  readonly finalProductKinds: FinalProductKindProps[]
}

export interface CoverImageProps {
  readonly id: number
  readonly name: string
  readonly fileId: number
  readonly fileCover: string
  readonly fileCoverLarge: string
  readonly fileType: string
  readonly sizeInBytes: number
  readonly downloadUrl: string
}

export interface GetSchoolTermsProps {
  readonly value: string
  readonly label: string
  readonly current: boolean
}

export interface GetMyGroupsProps {
  readonly value: string
  readonly label: string
}

export type StudentFromServer = {
  readonly value: string
  readonly label: string
}
export interface NewPosition {
  readonly session_meeting_id?: string
  readonly sort_position: number
}
export interface responseGetGroupPeople {
  readonly students: StudentFromServer[]
  readonly othersStudents: object[]
  readonly tutors: object[]
  readonly anotherStudents: object[]
}

export interface MeetingCreateRequest {
  readonly project_id: string
  readonly date: string
  readonly duration: number
  readonly time: string
  readonly title: string
  readonly enable_pendency_journal: boolean
  readonly dates_repeat_on?: string[]
}

export interface MeetingEditRequest {
  readonly project_id: string
  readonly date: string
  readonly duration: number
  readonly time: string
  readonly title: string
  readonly enable_pendency_journal: boolean
}
export interface CreateBlankActivityRequest {
  readonly session_meeting_id: string
  readonly project_id: string
}

export interface CreateBlankActivityTaskRequest {
  readonly id: string
  readonly project_id: string
}

export interface SessionMeetingActivityLv4ItemAttributes {
  readonly id: string
  readonly _destroy: number
}

export interface SessionMeetingStudentTaskAttributes {
  readonly title: string
  readonly date: string
  readonly description: string
}

export interface SessionMeetingActivity {
  readonly id: string
  readonly project_id: string
  readonly title: string
  readonly planning: string
  readonly has_assessments: boolean
  readonly has_tasks: boolean
  readonly activity_lv4_items_attributes: SessionMeetingActivityLv4ItemAttributes[]
  readonly student_task_attributes: SessionMeetingStudentTaskAttributes
}

export interface MeetingRepetitionRequest {
  readonly start_date: string
  readonly finish_date: string
  readonly days_of_week: number[]
}
