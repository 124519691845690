import { useContext } from 'react'
import { Anchor, Paper, Title, Text, SimpleGrid } from 'components'
import { CardAvatarCheckbox } from 'components'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { ParticipantsContext } from '../../context/ParticipantsContext'

export const GroupStudentsBlock = () => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const {
    selectedGroupLabel,
    students,
    studentsAction,
    handleToggleStudentsAction,
    handleChangeStudent,
    getCheckedStudentsQuantity,
  } = useContext(ParticipantsContext)

  return (
    <Paper withBorder shadow='xs' radius='md' px={isMobile ? 'sm' : 'md'} py='sm'>
      <Title order={5}>
        {t('new_project_page.students')} {selectedGroupLabel}{' '}
        <Text span color='dimmed'>
          ({getCheckedStudentsQuantity()})
        </Text>
      </Title>
      {students.length > 0 ? (
        <>
          <Anchor align='left' component='button' onClick={() => handleToggleStudentsAction()}>
            {studentsAction ? t('new_project_page.deselect_all') : t('new_project_page.select_all')}
          </Anchor>
          <SimpleGrid
            mt='sm'
            breakpoints={[
              { minWidth: 'xl', cols: 3, spacing: 'md' },
              { minWidth: 'lg', cols: 3, spacing: 'md' },
              { minWidth: 'md', cols: 2, spacing: 'sm' },
              { minWidth: 'sm', cols: 1, spacing: 'sm' },
              { minWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
          >
            {students.map(student => (
              <CardAvatarCheckbox
                key={student.id}
                id={student.id}
                avatar={student.avatar}
                name={student.name}
                onChange={() => handleChangeStudent(!student.checked, student.id)}
                checked={student.checked}
              />
            ))}
          </SimpleGrid>
        </>
      ) : (
        t('new_project_page.no_students_in_group')
      )}
    </Paper>
  )
}
