import React from 'react'
import { PaperWrapper } from './styles'

interface IPaper {
  readonly children?: React.ReactNode
  readonly elevation?: number
  readonly absolute?: boolean
  readonly disabled?: boolean
}

export default function Paper({ children, elevation = 0, absolute = false, disabled = false }: IPaper) {
  return (
    <PaperWrapper elevation={elevation} absolute={absolute} disabled={disabled}>
      {children}
    </PaperWrapper>
  )
}
