export const getStatusNumber = (status: string) => {
  switch (status) {
    case 'draft':
      return 0
    case 'approved':
      return 1
    case 'finished':
      return 2
    default:
      return 1
  }
}

export const canDraft = (profile: string) => {
  const allowedProfiles = ['tutor']
  return allowedProfiles.includes(profile)
}

export const canViewDraft = (profile: string) => {
  const allowedProfiles = ['tutor', 'manager']
  return allowedProfiles.includes(profile)
}