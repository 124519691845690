import styled from 'styled-components'
import Colors from '../../../common/Constants/Colors'

export const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 23px;

  p {
    color: ${Colors.primary.darkerGray};
    margin-bottom: 0;
  }
`

export const Container = styled.div`
  display: flex;
  padding: 18px 24px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`
