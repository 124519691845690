import { createStyles, rem } from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      color,
    }: {
      color: string | undefined
    },
  ) => ({
    root: {
      display: 'inline',
    },
    separator: {
      display: 'inline',
      marginLeft: rem(4),
      marginRight: rem(4),
      color: color,
      svg: {
        position: 'relative',
        top: rem(4),
      },
    },
    text: {
      display: 'inline',
      whiteSpace: 'normal',
    },
    badge: {
      backgroundColor: color,
    },
  }),
)
