import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Paper, Text, Divider, TextSearch, Tabs } from 'components'
import { useMantineTheme, useMediaQuery, rem } from 'hooks'
import { useCurrentUser } from 'context'
import { ProjectStepsButtons } from '../ProjectStepsButtons'
import { IconBooks, IconMoodCog } from '@tabler/icons-react'
import { ListSkillsStandardsPage1 } from '../ListSkillsStandardsPage1'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { ListSkillsStandardsPage2 } from '../ListSkillsStandardsPage2'
import { ReviewSkillsStandardsModal } from '../ReviewSkillsStandardsModal'
import { ListSearchResults } from '../ListSearchResults'
import { MoreInfoSkillsStandardsModal } from '../MoreInfoSkillsStandardsModal'
import { WorkedProjectsSkillsStandardsModal } from '../WorkedProjectsSkillsStandardsModal'
import { ChangedDataConfirmModal } from 'components'
import { StepperContext } from '../../context/StepperContext'

export function ProjectSkillsStandards() {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const user = useCurrentUser()
  const standardsOnly = user.currentUser?.selected_school?.enable_only_bncc_content
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const {
    skillsStandards,
    targetRef,
    page,
    currentTab,
    setCurrentTab,
    handleChangeTab,
    handleChangeSearch,
    searchTerm,
    searchResults,
    selectedSkills,
    selectedStandards,
    handleDiscard,
    selectedChanged,
  } = useContext(SkillsStandardsContext)

  const { confirmModalOpened, confirmModalClose } = useContext(StepperContext)

  const checkMutations = () => {
    return selectedChanged
  }

  useEffect(() => {
    if (standardsOnly) {
      setCurrentTab('content')
    } else {
      setCurrentTab('skill')
    }
  }, [])

  return (
    <>
      <ChangedDataConfirmModal discard={handleDiscard} opened={confirmModalOpened} close={confirmModalClose} />
      <MoreInfoSkillsStandardsModal />
      <WorkedProjectsSkillsStandardsModal />
      <ReviewSkillsStandardsModal />
      <Paper radius='md' withBorder shadow='xs' p={isMobile ? 'md' : 'xl'} mx={isMobile ? rem(-20) : 0}>
        <Title order={2}>
          {standardsOnly ? t('new_project_page.title_standards') : t('new_project_page.title_standards_and_skills')}
        </Title>
        <Text color='dimmed'>
          {standardsOnly
            ? t('new_project_page.description_standards')
            : t('new_project_page.description_standards_and_skills')}
        </Text>
        <Divider my='md' variant='solid' ref={targetRef} />
        <TextSearch
          onChange={handleChangeSearch}
          placeholder={
            skillsStandards.byCycle
              ? t('new_project_page.search_standards_and_skills_cycle')
              : t('new_project_page.search_standards_and_skills')
          }
        />
        {searchTerm && searchResults ? (
          <ListSearchResults />
        ) : (
          <Tabs
            keepMounted
            defaultValue={standardsOnly ? 'content' : 'skill'}
            value={currentTab}
            onTabChange={value => handleChangeTab(value)}
            mb='md'
          >
            <Tabs.List>
              {standardsOnly ? null : (
                <Tabs.Tab value='skill' icon={<IconMoodCog size='1.5rem' />}>
                  {t('new_project_page.skills_tab')}{' '}
                  {selectedSkills.length > 0 ? (
                    <Text color='dimmed' span={!isMobile} fw={400} size='sm'>
                      {!isMobile ? ' - ' : null}
                      {selectedSkills.length} {t('new_project_page.skills_selected')}
                    </Text>
                  ) : null}
                </Tabs.Tab>
              )}
              <Tabs.Tab value='content' icon={<IconBooks size='1.5rem' />}>
                {t('new_project_page.standards_tab')}{' '}
                {selectedStandards.length > 0 ? (
                  <Text color='dimmed' span={!isMobile} fw={400} size='sm'>
                    {!isMobile ? ' - ' : null}
                    {selectedStandards.length} {t('new_project_page.standards_selected')}
                  </Text>
                ) : null}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value='skill' pt='md'>
              {page === 2 ? <ListSkillsStandardsPage2 /> : <ListSkillsStandardsPage1 />}
            </Tabs.Panel>
            <Tabs.Panel value='content' pt='md'>
              {page === 2 ? <ListSkillsStandardsPage2 /> : <ListSkillsStandardsPage1 />}
            </Tabs.Panel>
          </Tabs>
        )}
        <ProjectStepsButtons hasChanges={checkMutations} isSkillsStandardsStep={true} />
      </Paper>
    </>
  )
}
