import { MantineThemeOverride, rem } from '@mantine/core'
import { IconAlertCircle } from '@tabler/icons-react'

export const MosaicoTheme = (colorScheme: string) => {
  const customTheme: MantineThemeOverride = {
    colors: {
      turquoise: [
        '#EEFFFC',
        '#bbf2e8',
        '#94e8d9',
        '#6ddfc9',
        '#47d6ba',
        '#2ebca0',
        '#20927d',
        '#136959',
        '#034035',
        '#001712',
      ],
      purple: [
        '#C79AFA',
        '#C79AFA',
        '#A160EC',
        '#A160EC',
        '#6A0BD6',
        '#6A0BD6',
        '#410189',
        '#410189',
        '#29084F',
        '#29084F',
      ],
    },
    defaultRadius: 'md',
    primaryShade: { light: 5, dark: 7 },
    primaryColor: 'turquoise',
    fontFamily: 'Malva',
    headings: {
      fontFamily: 'Malva',
      sizes: {
        h1: { fontSize: '2.625rem', fontWeight: 800 },
        h2: { fontSize: '1.75rem', fontWeight: 700 },
        h3: { fontSize: '1.5rem', fontWeight: 700 },
        h4: { fontSize: '1.25rem', fontWeight: 700 },
        h5: { fontSize: '1.125rem', fontWeight: 700 },
      },
    },
    breakpoints: {
      xs: '36em',
      sm: '48em',
      md: '64em',
      lg: '74em',
      xl: '90em',
    },
    spacing: {
      xs: rem(8),
      sm: rem(16),
      md: rem(24),
      lg: rem(32),
      xl: rem(48),
    },
    components: {
      Accordion: {
        styles: theme => ({
          item: {
            '&[data-active]': {
              backgroundColor: colorScheme === 'dark' ? theme.colors.turquoise[8] : theme.colors.turquoise[0],
            },
          },
          control: {
            '&:hover': {
              backgroundColor: colorScheme === 'dark' ? theme.colors.turquoise[8] : theme.colors.turquoise[0],
            },
          },
        }),
      },
      Avatar: {
        defaultProps: {
          radius: 'xl',
          variant: 'filled',
          color: colorScheme === 'dark' ? 'gray' : 'gray',
        },
      },
      Alert: {
        defaultProps: {
          icon: <IconAlertCircle size='1.6rem' />,
        },
        styles: theme => ({
          title: {
            fontSize: '1.125rem',
          },
          closeButton: {
            width: rem(32),
            height: rem(32),
            color: colorScheme === 'dark' ? 'white' : theme.colors.dark[8],
          },
        }),
      },
      Button: {
        defaultProps: {
          radius: 'xl',
        },
      },
      InputWrapper: {
        styles: () => ({
          label: {
            fontWeight: 700,
          },
        }),
      },
      Title: {
        defaultProps: {
          color: colorScheme === 'dark' ? 'white' : 'black',
        },
      },
      Chip: {
        defaultProps: {
          radius: 'md',
        },
        styles: theme => ({
          iconWrapper: {
            paddingRight: theme.spacing.xs,
          },
        }),
      },
      Modal: {
        styles: theme => ({
          header: {
            padding: theme.spacing.lg + ' ' + theme.spacing.md,
            borderBottom: `1px solid ${colorScheme === 'dark' ? theme.colors.gray[6] : theme.colors.gray[2]}`,
          },
          body: {
            '> :first-of-type': {
              paddingTop: theme.spacing.md,
            },
          },
          title: {
            fontSize: theme.headings.sizes.h3.fontSize,
            fontWeight: 700,
            color: colorScheme === 'dark' ? 'white' : 'black',
          },
          close: {
            svg: {
              width: 36,
              height: 36,
              color: colorScheme === 'dark' ? 'white' : 'black',
            },
          },
        }),
      },
      Table: {
        defaultProps: {
          bg: colorScheme === 'dark' ? 'gray.9' : 'white',
        },
      },
      Tabs: {
        styles: () => ({
          tab: {
            fontWeight: 700,
            padding: '1rem 1rem',
            fontSize: '1rem',
          },
        }),
      },
      Anchor: {
        defaultProps: {
          color: colorScheme === 'dark' ? 'grape.5' : 'purple.5',
        },
        styles: theme => ({
          root: {
            '&:hover': {
              textDecoration: 'underline',
              color: colorScheme === 'dark' ? theme.colors.grape[3] : theme.colors.purple[3],
            },
          },
        }),
      },
      Badge: {
        defaultProps: {
          radius: 'sm',
        },
        styles: () => ({
          root: {
            padding: '0.25rem 0.5rem',
            lineHeight: '1.25rem',
          },
        }),
      },
      Select: {
        styles: theme => ({
          item: {
            padding: '0.5rem 1rem',
            '&:hover': {
              backgroundColor: colorScheme === 'dark' ? theme.colors.turquoise[8] : theme.colors.turquoise[1],
            },
          },
        }),
      },
      MultiSelect: {
        styles: theme => ({
          item: {
            padding: '0.5rem 1rem',
            '&:hover': {
              backgroundColor: colorScheme === 'dark' ? theme.colors.turquoise[8] : theme.colors.turquoise[1],
            },
          },
        }),
      },
      Menu: {
        styles: () => ({
          item: {
            padding: '0.5rem 1rem',
          },
        }),
      },
      Checkbox: {
        styles: () => ({
          label: {
            ':hover': {
              cursor: 'pointer',
            },
          },
          input: {
            ':hover': {
              cursor: 'pointer',
            },
          },
        }),
      },
      Stepper: {
        styles: theme => ({
          stepIcon: {
            color: colorScheme === 'dark' ? 'turquoise' : 'black',
            backgroundColor: colorScheme === 'dark' ? 'gray.6' : 'white',
          },
          stepCompletedIcon: {
            color: colorScheme === 'dark' ? 'turquoise.6' : 'turquoise.6',
            backgroundColor: colorScheme === 'dark' ? 'turquoise' : 'turquoise',
            borderRadius: '100%',
          },
          separator: {
            [theme.fn.smallerThan('sm')]: {
              minHeight: rem(16),
            },
          },
        }),
      },
      Carousel: {
        styles: theme => ({
          control: {
            width: theme.spacing.xl,
            height: theme.spacing.xl,
            svg: {
              width: rem(32),
              height: rem(32),
            },
          },
        }),
      },
    },
  }
  return customTheme
}
