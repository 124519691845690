/* global I18n */
/* eslint consistent-return: off */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import "../../assets/stylesheets/pagination.scss";

function first(props, current) {
  if (current > 1) {
    return (
      <span className="arrows">
        <a
          role="button"
          onClick={() => props.clickPagination(1)}
          onKeyPress={() => props.clickPagination(1)}
          tabIndex={current}
        >
          «
        </a>
      </span>
    );
  }
}

function previous(props, current) {
  if (current > 1) {
    return (
      <span className="arrows">
        <a
          rel="prev"
          role="button"
          onClick={() => props.clickPagination(Number(current) - 1)}
          onKeyPress={() => props.clickPagination(Number(current) - 1)}
          tabIndex={current}
        >
          ‹
        </a>
      </span>
    );
  }
}

function next(props, current, total) {
  if (current < total) {
    return (
      <span className="arrows">
        <a
          rel="next"
          role="button"
          onClick={() => props.clickPagination(Number(current) + 1)}
          onKeyPress={() => props.clickPagination(Number(current) + 1)}
          tabIndex={current}
        >
          ›
        </a>
      </span>
    );
  }
}

function last(props, current, total) {
  if (current < total) {
    return (
      <span className="arrows">
        <a
          rel="last"
          role="button"
          onClick={() => props.clickPagination(total)}
          onKeyPress={() => props.clickPagination(total)}
          tabIndex={current}
        >
          »
        </a>
      </span>
    );
  }
}

function pages(total, current) {
  let initial = current - 5;
  if (initial < 1) initial = 1;
  let final = initial + 10;
  if (final > total) {
    final = total + 1;
    initial = final - 10;
    if (initial < 1) initial = 1;
  }
  return _.range(initial, final);
}

const Pagination = (props) => {
  let { current } = props.pagination;
  const { total } = props.pagination;
  current = current === undefined ? "1" : current;

  if (total <= 1) {
    return null;
  }

  return (
    <div className="row py-4">
      <div className="col">
        <nav className="pagination" role="navigation" aria-label="pager">
          {first(props, current)}

          {previous(props, current)}
          {pages(total, current).map((value, index) => (
            <span
              className={`page ${current === value ? "current" : ""}`}
              key={index}
            >
              <a
                role="button"
                onClick={() => props.clickPagination(value)}
                onKeyPress={() => props.clickPagination(value)}
                tabIndex={index}
              >
                {value}
              </a>
            </span>
          ))}
          {next(props, current, total)}
          {last(props, current, total)}
        </nav>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  pagination: PropTypes.object.isRequired,
  clickPagination: PropTypes.func.isRequired,
};

export default Pagination;
