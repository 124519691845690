import React from 'react'
import Paper from '../../../../components/Paper'
import { CardWrap, Container } from '../styles/config-card-styles'

interface IConfigCard {
  readonly title: string
  readonly description: string
  readonly icon: React.ReactElement
  readonly link: string
}

export default function ConfigCard({
  title,
  description,
  icon,
  link,
}: IConfigCard) {
  return (
    <Paper elevation={1}>
      <Container role='button' onClick={() => window.location.replace(link)}>
        {icon}
        <CardWrap>
          <h5>{title}</h5>
          <p className='small'>{description}</p>
        </CardWrap>
      </Container>
    </Paper>
  )
}
