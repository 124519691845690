import { useContext, useEffect, useState } from 'react'
import { Paper, Title, Text, SimpleGrid, Group, Button, CardAvatarAction } from 'components'
import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { ParticipantsContext } from '../../context/ParticipantsContext'
import { IconPlus } from '@tabler/icons-react'
import { OtherStudentsModal } from '../OtherStudentsModal'
import { getStudentsAnotherGroups } from 'pages/NewProject/service/projects'
import { isEmpty } from 'lodash'
import { convertTeacherArray } from '../ProjectParticipants/helper'

export interface OtherStudentsBlock {
  readonly termId: string
  readonly groupId: string
}

export interface Item {
  id: string
  fullName: string
  avatarSmall: string | null
  role: string
}

export const OtherStudentsBlock = ({ termId, groupId }: OtherStudentsBlock) => {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [loading, setLoading] = useState(false)
  const {
    selectedOtherStudents,
    studentsModalOpened,
    handleRemoveStudent,
    otherStudents,
    otherStudentsHandler,
    openStudentsModal,
  } = useContext(ParticipantsContext)

  useEffect(() => {
    const shouldFetchOtherStudents = !isEmpty(termId) && studentsModalOpened && !isEmpty(groupId)

    if (shouldFetchOtherStudents && otherStudents.length === 0) {
      setLoading(true)

      getStudentsAnotherGroups(groupId, termId)
        .then(res => {
          if (res !== undefined) {
            const sortedArray = convertTeacherArray(res).sort((a, b) =>
              a.name.localeCompare(b.name, 'pt-BR', { sensitivity: 'base' }),
            )

            otherStudentsHandler.setState(sortedArray)
          }
          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
          console.error(error)
        })
    }
  }, [groupId, termId, studentsModalOpened])

  return (
    <>
      <OtherStudentsModal loading={loading} />
      <Paper shadow='xs' withBorder radius='md' px={isMobile ? 'sm' : 'md'} py='sm'>
        <Group position='apart'>
          <Title order={5}>
            {t('new_project_page.students_from_other_groups')}{' '}
            <Text span color='dimmed'>
              ({selectedOtherStudents.length})
            </Text>
          </Title>
          <Button onClick={() => openStudentsModal()} leftIcon={<IconPlus />} size='sm' variant='light'>
            {t('new_project_page.add_students_other_groups')}
          </Button>
        </Group>
        {selectedOtherStudents.length > 0 && (
          <SimpleGrid
            mt='sm'
            breakpoints={[
              { minWidth: 'xl', cols: 3, spacing: 'md' },
              { minWidth: 'lg', cols: 3, spacing: 'md' },
              { minWidth: 'md', cols: 2, spacing: 'sm' },
              { minWidth: 'sm', cols: 1, spacing: 'sm' },
              { minWidth: 'xs', cols: 1, spacing: 'sm' },
            ]}
          >
            {selectedOtherStudents.map((otherStudent, index) => (
              <CardAvatarAction
                key={otherStudent.id}
                avatar={otherStudent.avatar}
                name={otherStudent.name}
                onClick={() => handleRemoveStudent(index)}
              />
            ))}
          </SimpleGrid>
        )}
      </Paper>
    </>
  )
}
