import { Layout } from 'components'
import { CurrentUserContextProvider } from 'context'
import { StudentDashboard } from './components/StudentDashboard'
import { StudentDashboardProvider } from './context/StudentDashboardContext'

export const StudentDashboardPage = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <StudentDashboardProvider>
          <StudentDashboard />
        </StudentDashboardProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
