import api from 'helpers/api'
import { IStudent } from './StudentsAssessmentsTypes'
import { IPostJournal, IpostScore } from './StudentsAssessmentsTypes'

export default {
  postJournal: async (payload: IPostJournal, id: string): Promise<any> => {
    const response = await api.patch(`/journals/${id}`, payload)
    return response.data
  },

  postScore: async (payload: IpostScore, id: string): Promise<any> => {
    const response = await api.patch(`/final_assessment_scores/${id}`, payload)
    return response.data
  },

  getStudents: async (meetingId: string): Promise<IStudent[]> => {
    const response = await api.get(`/projects/${meetingId}/list_final_assessments`)
    return response.data.students
  },

  getStudent: async (meetingId: string, studentId: string): Promise<IStudent> => {
    const response = await api.get(`/projects/${meetingId}/${studentId}/student_final_assessment`)
    return response.data.student
  },
}
