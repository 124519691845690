import styled from "styled-components"

export const AvatarText = styled.div`
  line-height: 1;
  span {
    font-size: inherit;
  }
  small {
    color: var(--color-gray);
  }
`
export const Avatar = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`
export const Link = styled.a`
  color: var(--color-link);
  cursor: pointer;
`