import styled from 'styled-components'

const GDriveContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const GDriveGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding-top: 1rem;
`

const GDriveTitle = styled.h2`
  font-size: 0.875rem;
  color: #000;
  line-height: 24px;
  margin-bottom: 10px;
`

export { GDriveContainer, GDriveGrid, GDriveTitle }
