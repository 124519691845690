import api from 'helpers/api'
import { IAssessmentScoresAndJournal } from '../StudentModalContent/StudentModalContentTypes'
import { IStudent } from './StudentsAssessmentsTypes'
import { IPostJournal, IpostScore } from './StudentsAssessmentsTypes'

export default {
  postJournal: async (payload: IPostJournal, id: string): Promise<any> => {
    const response = await api.patch(`/journals/${id}`, payload)
    return response.data
  },

  postScore: async (payload: IpostScore, id: string): Promise<any> => {
    const response = await api.patch(`/assessment_scores/${id}`, payload)
    return response.data
  },

  patchScoresAndJournal: async (payload: IAssessmentScoresAndJournal) => {
    const response = await api.patch('/assessment_scores_update_all', payload)
    return response.data
  },

  getStudents: async (meetingId: string): Promise<IStudent[]> => {

    const response = await api.get(
      `/session_meetings/${meetingId}/list_student_by_meeting`
    )
    return response.data
  },

  getStudentAssessment: async (meetingId: string, assessmentId: string): Promise<IStudent> => {
    const response = await api.get(
      `/session_meetings/${meetingId}/student_assessment/${assessmentId}`
    )
    return response.data
  },

  updateSessionMeeting: async (meetingId: string, payload: any) => {
    const response = await api.patch(`/session_meetings/${meetingId}/update_session_meeting`, payload)
    return response.data
  },
}
