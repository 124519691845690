import { Layout } from 'components'
import { CurrentUserContextProvider } from 'context'
import { TeacherDashboard } from './components/TeacherDashboard'
import { TeacherDashboardProvider } from './context/TeacherDashboardContext'

export const TeacherDashboardPage = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <TeacherDashboardProvider>
          <TeacherDashboard />
        </TeacherDashboardProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
