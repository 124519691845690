import DeviseLanguage from '@legacy/common/components/DeviseLanguage'
import { SchoolLogoColor } from '@legacy/common/types/whitelabel'
import Logo from '@legacy/components/shared/Logo/logo-component'
import { Mosaico } from '@legacy/pages/Account/SignIn/session-new-styles'
import { NotificationProvider } from 'context'
import { BodyForgotPasswordScreen } from './BodyForgotPasswordScreen'
import { Space, Heading } from 'components'
import { useTranslation } from 'react-i18next'
import { Container } from '@mantine/core'
import { Flex } from 'components/Flex'
import { useMediaQuery } from '@mantine/hooks'
import { LayoutLogin } from 'components'


export const ForgotPasswordScreen = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <NotificationProvider>
      <LayoutLogin>
        <Mosaico>
          <Logo color={SchoolLogoColor.White} />
          <DeviseLanguage />
        </Mosaico>
        <Space h='xl' />
        <Container size='54rem' px={isMobile ? 20 : 0}>
          <Flex justify='center'>
            <Heading
              size='xl'
              text={t('forgot_password.forgot_password_title')}
              supportText={t('forgot_password.forgot_password_subtitle')}
            />
          </Flex>
        </Container>
        <BodyForgotPasswordScreen />
      </LayoutLogin>
    </NotificationProvider>
  )
}
