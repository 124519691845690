import { createStyles, getBreakpointValue, em, rem} from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      color,
    }: {
      color: string | undefined
    },
  ) => ({
    root: {
      display: 'block',
    },
    separator: {
      [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'none',
      },
      [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'inline',
      },
      svg: {
        position: 'relative',
        top: rem(7),
        left: 0,
      },
    },
    badge: {
      [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'block',
        marginBottom: theme.spacing.xs,
      },
      [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'inline',
      },
      width: 'fit-content',
      backgroundColor: color,
    },
    text: {
      [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'block',
        marginBottom: theme.spacing.xs,
      },
      [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
        display: 'inline',
      },
      whiteSpace: 'break-spaces',
    },
  }),
)
