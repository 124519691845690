import { api } from 'helpers'
import { AxiosResponse } from 'axios'
const projectPaths = {
  getProjects: (query: string) => `/projects.json?${query}`,
  getFinalProducts: () => '/api/projects/modalities_and_final_products',
}

export async function getProjects(
  status: number,
  page: number,
  searchTerm?: string,
  schoolTermId?: string | null,
  finalProduct?: string[] | null,
  cycle?: string | null,
  group?: string | null,
): Promise<AxiosResponse> {
  try {
    const statusQuery = `&q[status_eq]=${status}`
    const pageQuery = `&page=${page}`
    const searchQuery = searchTerm
      ? `&q[name_or_synopsis_or_group_school_term_name_or_responsible_full_name_ucont]=${searchTerm}`
      : ''
    const schoolTermQuery = schoolTermId && schoolTermId !== null ? `&q[school_term_id_eq]=${schoolTermId}` : ''
    const cycleQuery = cycle && cycle !== null ? `&q[group_school_term_cycle_id_eq]=${cycle}` : ''
    const groupQuery = group && group !== null ? `&q[group_school_term_id_eq]=${group}` : ''
    const finalProductQuery =
      finalProduct && finalProduct !== null
        ? finalProduct?.map(product => `&q[final_product_kind_in][]=${product}`).join('')
        : ''

    const response = await api.get(
      projectPaths.getProjects(
        `${statusQuery}${pageQuery}${schoolTermQuery}${cycleQuery}${groupQuery}${finalProductQuery}${searchQuery}`,
      ),
    )
    return response
  } catch (error: any) {
    return error
  }
}
