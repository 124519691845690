import { ILessonCardActivity } from './ILessonCardActivity'

export interface ITask {
  readonly projectId: string
  readonly projectName: string
  readonly lessonId: string
  readonly lessonName: string
  readonly date: string
  readonly timeStart: string
  readonly timeEnd: string
  readonly canFillEditReport?: boolean
  readonly activities: ILessonCardActivity[] & { id: string; title: string }[]
  readonly status: string
  readonly hasAssessmentActivity: boolean
  readonly hasDeliverableActivity: boolean
}
