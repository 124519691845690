import Styled from 'styled-components'
import Colors from '@legacy/common/Constants/Colors'

export const GButton = Styled.button`
    background-color: ${Colors.primary.lighterGray};
    border: 1px solid ${Colors.primary.lightGray};
    box-sizing: border-box;
    border-radius: 72px;
    font-size: 0.75rem;
    padding: 11px 15px;
    
    cursor: pointer;
    margin-right: 8px;

    &:hover {
        border-color: ${Colors.primary.gray};
    }

    &:focus {
        outline: none;
    }

`
export const GWrapper = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: fit-content;


    span {
        margin-left: 8px;
    }
`
