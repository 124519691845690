import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'
import { SchoolLogoColor } from '@legacy/common/types/whitelabel'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { LumiarToastContainer, toastOptions } from '@legacy/common/_components/LumiarToast'
import Logo from '@legacy/common/components/shared/Logo'

import { GoogleButton } from './session-new-google-button-component'
import DeviseLanguage from '@legacy/common/components/DeviseLanguage/deviseLanguage'
import { useSessionNew } from './session-new-hook'
import { useForm } from '@mantine/form'
import { Mosaico, Form, PaperStyled } from './session-new-styles'
import {
  Button,
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Group,
  Divider,
  Container,
  Paper,
  Space,
} from '@mantine/core'

import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Flex } from 'components/Flex'
import { Heading } from 'components'
import { useNotification } from 'context/useNotificationContext'

export interface ISession {
  readonly children?: never
}

const SessionNew = (props: ISession) => {
  const { t } = useTranslation()
  const { payload, loading, change, check, logIn } = useSessionNew()
  const [visible, { toggle }] = useDisclosure(false)
  const [searchParams] = useSearchParams()
  const [urlToRedirect, setUrlToRedirect] = useState('')
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { handleNotification } = useNotification()

  const handleSubmit = (values: typeof form.values) => {
    payload.login = values.email
    payload.password = values.password
    payload.urlToRedirect = urlToRedirect
    logIn(payload)
  }

  const form = useForm({
    initialValues: { email: payload.login, password: payload.password },
  })

  useEffect(() => {
    const cookieUrlToRedirect: string | undefined | null = Cookies.get('url_to_redirect')
    if (cookieUrlToRedirect !== '' && cookieUrlToRedirect !== undefined && cookieUrlToRedirect !== null) {
      setUrlToRedirect(cookieUrlToRedirect)
    }
  }, [urlToRedirect])

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const paramType: string | null = searchParams.get('success')

    switch (paramType) {
      case 'change_sucess':
        handleNotification(true, t('forgot_password.change_sucess'), true, 'success')
        break
      case 'not_changed':
        handleNotification(true, t('forgot_password.not_changed'), true, 'error')
        break
      default:
        break
    }
  }, [])

  useEffect(() => {
    const sendResetPasswordInstructions = searchParams.get('send_reset_password_instructions')
    if (sendResetPasswordInstructions === 'true') toast.success(t('send_instructions'), toastOptions)
  }, [])

  return (
    <>
      <Mosaico>
        <Logo color={SchoolLogoColor.White} />
        <DeviseLanguage />
      </Mosaico>
      <div>
        <LumiarToastContainer />
        <Space h='xl' />
        <Flex align='center' direction='column' justify='center'>
          <Container px={isMobile ? 20 : 0} size={isMobile ? 'auto' : '52rem'}>
            <Heading size='xl' text={t('login')} />
          </Container>
          <PaperStyled>
            <Paper shadow='md' mt={20} radius='md' p='xl'>
              <Form onSubmit={form.onSubmit(handleSubmit)}>
                <TextInput
                  id='input-email'
                  size='md'
                  aria-label={t('account_signIn_placeholder_login')}
                  label={t('account_signIn_placeholder_login')}
                  name='login'
                  disabled={loading}
                  onChange={change}
                  {...form.getInputProps('email')}
                />
                <PasswordInput
                  mt='md'
                  id='input-password'
                  size='md'
                  label={t('account_signIn_placeholder_password')}
                  aria-label={t('account_signIn_placeholder_password')}
                  name='password'
                  disabled={loading}
                  onChange={change}
                  visible={visible}
                  onVisibilityChange={toggle}
                  {...form.getInputProps('password')}
                />
                <Group mt='md' position={isMobile ? 'center' : 'apart'}>
                  <Checkbox
                    label={t('account_signIn_remember')}
                    aria-label={t('account_signIn_remember')}
                    labelPosition='right'
                    onChange={check}
                    id='rememberMe'
                    name='rememberMe'
                    size='md'
                    checked={payload.rememberMe}
                    disabled={loading}
                  />
                  <Anchor href='/forgot-password' aria-label={t('account_signIn_forgot_password')}>
                    {t('account_signIn_forgot_password')}
                  </Anchor>
                </Group>

                <Button
                  fullWidth
                  mt='md'
                  variant='filled'
                  disabled={loading}
                  type='submit'
                  size='lg'
                  aria-label={t('account_signIn_login')}
                >
                  {t('account_signIn_login')}
                </Button>
              </Form>
              <Divider my='lg' label={t('account_signIn_divider')} labelPosition='center' />
              <GoogleButton disabled={loading} />
            </Paper>
          </PaperStyled>
        </Flex>
      </div>
    </>
  )
}

export default SessionNew
