import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

import { ButtonSizes, TypesButton } from './interfaces'

// eslint-disable-next-line no-shadow
enum buttonSizes {
  large = 'large',
  largeIcon = 'largeIcon',
  medium = 'medium',
  mediumIcon = 'mediumIcon',
  small = 'small',
  smallIcon = 'smallIcon',
}

const fontSize = (size: string) => {
  switch (size) {
    case buttonSizes.large:
    case buttonSizes.largeIcon:
      return '16px'
    case buttonSizes.medium:
    case buttonSizes.mediumIcon:
      return '12px'
    case buttonSizes.small:
    case buttonSizes.smallIcon:
      return '10px'
    default:
      return size
  }
}

const paddingHeightSize = (size: string) => {
  switch (size) {
    case buttonSizes.large:
    case buttonSizes.largeIcon:
      return '16px'
    case buttonSizes.medium:
    case buttonSizes.mediumIcon:
    case buttonSizes.small:
    case buttonSizes.smallIcon:
      return '8px'
    default:
      return size
  }
}

const paddingWidthtSize = (size: string) => {
  switch (size) {
    case buttonSizes.large:
    case buttonSizes.largeIcon:
      return '32px'
    case buttonSizes.medium:
    case buttonSizes.mediumIcon:
      return '16px'
    case buttonSizes.small:
    case buttonSizes.smallIcon:
      return '8px'
    default:
      return size
  }
}
interface ButtonProps {
  readonly colorBackground?: string
  readonly size?: ButtonSizes
  readonly kind?: TypesButton
  readonly active?: boolean
  readonly width?: string
  readonly height?: string
  readonly colorText?: string
  readonly colorBorder?: string
  readonly target?: string
  readonly hoverBackgroundColor?: string
  readonly hoverTextColor?: string
  readonly hoverColorBorder?: string
}

const ButtonStyled = styled.button<ButtonProps>`
  color: ${(props) =>
    props.colorText ? props.colorText : 'var(--color-black)'};
  background: ${(props) =>
    props.kind === 'primary' ? 'var(--color-primary)' : props.colorBackground};
  border: ${(props) =>
    props.kind === 'primary'
      ? '1px solid var(--color-primary)'
      : props.colorBorder};
  border-radius: 32px;
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: ${(props) =>
    props.size ? fontSize(props.size) : fontSize('medium')};
  line-height: 1.4em;
  padding-top: ${(props) =>
    props.size ? paddingHeightSize(props.size) : paddingHeightSize('medium')};
  padding-bottom: ${(props) =>
    props.size ? paddingHeightSize(props.size) : paddingHeightSize('medium')};
  padding-left: ${(props) =>
    props.size ? paddingWidthtSize(props.size) : paddingWidthtSize('medium')};
  padding-right: ${(props) =>
    props.size ? paddingWidthtSize(props.size) : paddingWidthtSize('medium')};
  z-index: 3;
  font-weight: 700;
  width: ${(props) => props.width ?? ''};
  height: ${(props) => props.height ?? ''};

  &:disabled {
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-light);
    color: var(--color-text-disabled);

    &:hover,
    &:focus {
      cursor: not-allowed;
      background: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
      color: var(--color-text-disabled);
    }
  }

  &:hover {
    color: ${(props) =>
      props.hoverTextColor ? props.hoverTextColor : 'var(--color-black)'};
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: ${(props) =>
      props.kind === 'primary'
        ? 'var(--color-primary)'
        : props.hoverBackgroundColor};
    border: ${(props) =>
      props.kind === 'primary'
        ? '1px solid var(--color-primary)'
        : props.hoverColorBorder};
  }

  &:focus {
    border: ${(props) =>
      props.kind === 'primary'
        ? '1px solid var(--color-black)'
        : '1px solid var(--color-primary)'};
  }
`

const ButtonIconOnlyStyled = styled.button<ButtonProps>`
  color: ${(props) =>
    props.colorText ? props.colorText : 'var(--color-black)'};
  font-weight: 700;
  background: ${(props) =>
    props.colorBackground
      ? props.colorBackground
      : 'var(--color-gray-lighter)'};
  padding: 0;
  border: ${(props) =>
    props.colorBorder
      ? props.colorBorder
      : ' 1px solid var(--color-gray-light)'};
  box-sizing: border-box;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;

  &:disabled {
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-light);
    color: var(--color-text-disabled);

    &:hover,
    &:focus {
      cursor: not-allowed;
      background: var(--color-gray-light);
      border: 1px solid var(--color-gray-light);
    }
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    outline: none;
    border: ${(props) =>
      props.hoverTextColor
        ? '1px solid var(--color-primary)'
        : props.hoverColorBorder};
    color: ${(props) =>
      props.hoverTextColor ? props.hoverTextColor : 'var(--color-black)'};
    background: ${(props) =>
      props.hoverBackgroundColor ? props.hoverBackgroundColor : ''};
  }

  &:focus {
    border: 1px solid var(--color-black);
  }
`

interface IContainerButtonStyled {
  readonly onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const ContainerButtonStyled = styled.button<IContainerButtonStyled>`
  background: none repeat scroll 0 0 transparent;
  border: 0;
  border-spacing: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`
export { ContainerButtonStyled, ButtonStyled, ButtonIconOnlyStyled }

export default ButtonStyled
