import RemoveCircle from '@legacy/common/Icons/RemoveCircle'
import { IButtonCircle } from './interfaces'
import { ContainerButtonStyled } from './styled'

const ButtonRemoveCircle = ({
  size = 24,
  disabled = false,
  onClick = () => {}
}: IButtonCircle) => (
  <ContainerButtonStyled onClick={onClick}>
    <RemoveCircle width={size} disabled={disabled} />
  </ContainerButtonStyled>
)


export default ButtonRemoveCircle
