import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context'
import { Group, Button, Tooltip } from 'components'
import { LessonJournalButtonsProps } from './interfaces'

export const LessonJournalButtons = ({
  projectStatus,
  lessonID,
  getJournalURL,
  getReportURL,
  canFillEditReport,
}: LessonJournalButtonsProps) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const reportButtonLabel = currentUser
    ? currentUser.profile_used === 'tutor' || currentUser.profile_used === 'manager'
      ? t('general_view_student_report')
      : t('general_view_teacher_report')
    : ''

  return (
    <Group spacing='sm'>
      {projectStatus != 'finished' && canFillEditReport && (
        <Button variant='filled' size='sm' component='a' href={getJournalURL(lessonID)}>
          {t('general_buttons_fill_journal')}
        </Button>
      )}

      { projectStatus != 'finished' && !canFillEditReport && (
        <Tooltip label={t('fill_report_disabled')} withinPortal withArrow position='top'>
          <Button
            data-disabled
            size='sm'
            sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
            data-testid='fill_report-disabled'
            onClick={event => event.preventDefault()}
          >
            {t('general_buttons_fill_journal')}
          </Button>
        </Tooltip>
      )}
      <Button variant='outline' size='sm' component='a' href={getReportURL(lessonID)}>
        {reportButtonLabel}
      </Button>
    </Group>
  )
}
