import { UnstyledButton, Checkbox, Text, Box } from 'components'
import { useUncontrolled } from 'hooks'
import { useStyles } from './CardCheckBoxStyles'
interface CheckboxCardProps {
  checked?: boolean | undefined
  onChange?(checked: boolean): void
  title: React.ReactNode
  description: React.ReactNode
  icon: React.ReactNode
}

export const CardCheckBox = ({ checked, onChange, title, description, icon }: CheckboxCardProps) => {
  const { classes } = useStyles({ checked: checked })
  const [value, handleChange] = useUncontrolled({
    value: checked,
    finalValue: false,
    onChange,
  })

  return (
    <UnstyledButton onClick={() => handleChange(!value)} className={classes.button}>
      <Checkbox className={classes.checkbox} checked={value} onChange={() => {}} tabIndex={-1} size='md' />
      <Box className={classes.icon}>{icon}</Box>
      <Box className={classes.textbox}>
        <Text fw={500}>{title}</Text>
        <Text fz='sm' c='dimmed'>
          {description}
        </Text>
      </Box>
    </UnstyledButton>
  )
}
