import { useContext, useState } from 'react'
import { FeedDrawerContext } from '../../../../contexts/FeedDrawerContext'
import { FeedListContext } from '../../../../contexts/FeedListContext'
import { StudentItem } from './components/StudentItem'
import { useEffect } from 'react'
import { Button, Box, Anchor, Select, Stack, TextInput, Input }  from 'components'
import { IconSearch } from '@tabler/icons-react'
import { searchStudents } from '../../../../contexts/FeedDrawerContext/searchStudents'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { useMixPanel } from 'hooks'
import { useStyles } from './styles'
import { notifications } from 'context'
import { IconX, IconCheck } from '@tabler/icons-react'
import * as serviceFeed from 'pages/Feed/serviceFeed'
import { FeedStatus } from 'pages/Feed/serviceFeed'

export const StepPageTwo = () => {
  const mixpanel = useMixPanel()
  const { classes } = useStyles()
  const { t } = useTranslation()

  const [allSelected, setAllSelected] = useState(false)
  const [selectAllText, setSelectAllText] = useState<string>(t('select_all'))

  const {
    getFeed,
    setActiveTab,
  } = useContext(FeedListContext)
 
  const {
    feedId,
    setStudents,
    students,
    setHeaderText,
    serverData,
    saveFeedLoading,
    seachString,
    selectedProject,
    setSelectedProject,
    setStepPage,
    stepPage,
    handleSearchOnChange,
    setSaveFeedLoading,
    userIsCreating,
    onCloseDrawer,
    feedStatus,
    payload,
  } = useContext(FeedDrawerContext)

  const selectAll = () => {
    setAllSelected(!allSelected)

    setStudents(students => {
      const selectAllStudents = students.some(({ filtered, selected }) => filtered && !selected)

      return students.map(student => (student.filtered ? { ...student, selected: selectAllStudents } : student))
    })
  }

  const assossiatedProjects = students
    .map(({ assossiatedProjects }) => assossiatedProjects)
    .flat()
    .map(({ id, name }) => ({ value: id, label: name }))
    .filter((project, index, self) => index === self.findIndex(({ value }) => value === project.value))

  const saveFeed = async () => {
    if (isEmpty(payload.studentIds)) {
      notifications.show({
        message: t('error_update_public_feed'),
        color: 'red',
        icon: <IconX size={24} />,
      })
      return
    } else {
      setSaveFeedLoading(true)
      try {
        isEmpty(feedId)
          ? await serviceFeed.createObservation(payload)
          : await serviceFeed.editObservation(feedId, payload)

        const successText = userIsCreating ? t('create_success') : t('edit_success')
        notifications.show({
          message: successText,
          color: 'green',
          icon: <IconCheck size={24} />,
        })
        onCloseDrawer(true)
        if (feedStatus === FeedStatus.Published) {
          setActiveTab('public')
        } else {
          setActiveTab('private')
        }
        getFeed()
      } catch (e) {
        const errorText = t('error_update_public_feed')
        notifications.show({
          title: t('general_error_title'),
          message: errorText,
          color: 'red',
          icon: <IconX size={24} />,
        })
      }
      setSaveFeedLoading(false)
    }
  }

  useEffect(() => {
    const [firstName, ...lastNameArray] = seachString.split(' ')
    const lastName = lastNameArray.join(' ')
    searchStudents(firstName, lastName, setStudents, selectedProject)
  }, [selectedProject])

  useEffect(() => {
    const hasAllSelected = allSelected ? t('unselect_all') : t('select_all')

    setSelectAllText(hasAllSelected)
  }, [allSelected])

  useEffect(() => {
    const allSelected = students.every(student => student.selected)
    setAllSelected(allSelected)
  }, [students])

  if (stepPage === 2) {
    return (
      <Stack spacing='md' className={classes.content}>
        <Select
          size='md'
          label={t('choose_project')}
          onChange={value => {
            setSelectedProject(value ? value : '')
          }}
          value={selectedProject}
          data={assossiatedProjects}
        />
        <Input.Wrapper size='md' label='Defina os(as) estudantes'>
          <Stack spacing='sm'>
            <TextInput
              size='md'
              icon={<IconSearch />}
              value={seachString}
              onChange={handleSearchOnChange}
              placeholder={t('search_by_name')}
              radius='xl'
            />
            <Box>
              <Anchor component='button' onClick={selectAll} mb='sm'>
                {selectAllText}
              </Anchor>
              <Stack spacing='sm'>
                {students
                  .filter(({ filtered }) => filtered)
                  .map(student => {
                    return <StudentItem key={student.id} setStudents={setStudents} selectedStudent={student} />
                  })}
              </Stack>
            </Box>
          </Stack>
        </Input.Wrapper>
        <Box className={classes.footer}>
          <Button
            variant='default'
            onClick={() => {
              setStepPage(1), serverData ? setHeaderText(t('edit_post')) : setHeaderText(t('new_post'))
            }}
            size='lg'
          >
            {t('general_buttons_back')}
          </Button>
          <Button
            onClick={() => {
              if (!isEmpty(feedId)) mixpanel.track('Salvar edição do post')
              saveFeed()
            }}
            size='lg'
            loading={saveFeedLoading}
          >
            {t('general_buttons_save')}
          </Button>
        </Box>
      </Stack>
    )
  }

  return <></>
}
