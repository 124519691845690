import { Badge, Tooltip } from 'components'
import { useTranslation } from 'react-i18next'
import { BadgeProps } from '@mantine/core'

interface BadgeCycleProps extends BadgeProps {
  children: React.ReactNode
}


export const BadgeCycle = ({ children, ...props }: BadgeCycleProps) => {
  const { t } = useTranslation()
  const text = children?.toString() || ''

  const getColor = (cycleText: string) => {
    switch (cycleText) {
      case 'I1':
        return 'grape'
      case 'I2':
        return 'violet'
      case 'I3':
        return 'indigo'
      case 'F1' || 'A1':
        return 'blue'
      case 'F2' || 'A2':
        return 'cyan'
      case 'F3' || 'A3':
        return 'teal'
      case 'F4':
        return 'pink'
      case 'EM':
        return 'lime'
      default:
        return 'cyan'
    }
  }

  return (
    <Tooltip label={t('new_project_page.cycle_tooltip')} zIndex={10} withinPortal withArrow multiline>
      <Badge {...props} variant='filled' color={getColor(text)}>
        {children}
      </Badge>
    </Tooltip>
  )
}
