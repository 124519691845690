import { useEffect, useState } from 'react'
import { TextInput, CloseButton } from 'components'
import { IconSearch } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { useDebouncedValue } from 'hooks'

interface TextSearchProps {
  placeholder: string
  onChange?: (value: string) => void
  size?: string
}

export function TextSearch({ placeholder, onChange, size }: TextSearchProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [debounced] = useDebouncedValue(value, 800)

  useEffect(() => {
    onChange?.(debounced)
  }, [debounced, onChange])

  return (
    <TextInput
      icon={<IconSearch size='1.1rem' />}
      radius='xl'
      size={size || 'md'}
      rightSection={
        value !== '' ? (
          <CloseButton
            size='md'
            title={t('general_buttons_filters_clear')}
            onClick={() => setValue('')}
            variant='transparent'
          />
        ) : null
      }
      placeholder={placeholder}
      rightSectionWidth={42}
      value={value}
      onChange={event => setValue(event.currentTarget.value)}
      mb='md'
    />
  )
}
