import { IReceivedCurrentUser } from '@legacy/common/models/user'
import env from '../env.json'

export default function connectProduktlyHook(currentUser: IReceivedCurrentUser | null | undefined): void {
  const dataEnv: any = env
  const token = dataEnv['process.env.REACT_APP_PRODUKTLY_TOKEN']
  const thereIsUser = currentUser !== undefined && currentUser !== null
  const thereIsToken = token !== undefined && token !== null && token !== ''

  if (thereIsToken && thereIsUser) {
    const script = document.createElement('script')
    script.async = true
    script.setAttribute('id', 'produktlyScript')
    script.dataset.clientToken = token.substring(1, token.length - 1)
    script.setAttribute('src', 'https://public.produktly.com/js/main.js')
    document.body.appendChild(script)

    const tutorOrManager = currentUser.profile_used === 'tutor' || currentUser.profile_used === 'manager'
    const loadWindow: any = window

    loadWindow.setTimeout(() => {
      loadWindow.Produktly.identifyUser(currentUser?.id, {
        school: currentUser?.selected_school?.name || '',
        profile: currentUser?.profile_used || '',
        environment: currentUser?.environment || '',
        full_name: currentUser?.full_name || '',
        username: currentUser?.username,
        email: currentUser?.email,
        language: currentUser?.locale,
        tutor_or_manager: tutorOrManager,
      })
    }, 1000)
  }
}
