import { useContext, FC } from 'react'
import TabContext from '../context/students-context'
import { InputTextSearch } from 'components/InputSearch'
import { Wrap } from './styled'
import { ITabContext } from '../types/student-types'

const WrapInputSearch: FC = () => {
  const { searchStudentByName } = useContext<ITabContext>(TabContext)

  return (
    <Wrap>
      <InputTextSearch
        fetchSearch={(value: string) => {
          searchStudentByName(value)
        }}
        cleanSearch={() => {
          searchStudentByName('')
        }}
      />
    </Wrap>
  )
}

export default WrapInputSearch
