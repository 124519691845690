import { Layout } from 'components/Layout'
import SessionNew from '@legacy/pages/Account/SignIn'
import { NotificationProvider } from 'context'

export const SignIn = () => {
  return (
    <NotificationProvider>
      <Layout.Login>
        <SessionNew />
      </Layout.Login>
    </NotificationProvider>
  )
}
