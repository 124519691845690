import { Component } from 'react'
import { isEmpty } from 'ramda'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Group } from 'components'
import i18n from '@i18n'
import { Modal } from 'components/ModalLegacy'
import { fetchUrl, deleteUrl, postFileUrl, loadJson } from '../Tools/Service'
import FilesUploadProgressContainer from '../_molecules/FilesUploadProgressContainer'
import FilesList from '../_molecules/FilesList'
import { MAX_SIZE, formatFileName, formatFileSize } from '../_shared/FileUploadHelper'
import InputFile from './InputFile'
import ButtonCard from './ButtonCard'
import Recorder from '../_molecules/Recorder'
import SafariRecorder from '../_molecules/SafariRecorder'
import { Container } from './HidenPrint/styled'

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uploadedFiles: undefined,
      loading: false,
      errors: [],
      uploadingFiles: {},
    }
  }

  componentDidMount() {
    this.loadUploadedFiles()
  }

  componentDidUpdate(prevProps) {
    const hasNewValue = this.props.fetchFilesUrl !== prevProps.fetchFilesUrl

    if (this.props.appliedFile && hasNewValue) {
      this.newloadUploadedFiles()
      return
    }

    if (hasNewValue) {
      this.loadUploadedFiles()
    }
  }

  async convertToPNG(imageFile) {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const image = new Image()

      image.onload = () => {
        canvas.width = image.width
        canvas.height = image.height
        context.drawImage(image, 0, 0)

        canvas.toBlob(blob => {
          resolve(blob)
        }, 'image/png')
      }

      image.onerror = () => {
        reject(new Error('Failed to load the image'))
      }

      image.src = URL.createObjectURL(imageFile)
    })
  }

  async recursiveSequentialUpload({ page, data, maxCalls }) {
    const { uploadUrl, typeFile, successUploadCallback } = this.props

    const file = data[page - 1]
    let error
    let request
    if (file.size > MAX_SIZE) {
      error = i18n.t('general_inputs_exceeded_max_size_short_message', {
        max_size: formatFileSize(MAX_SIZE),
      })
    } else {
      const formData = new FormData()
      let convertedFile = file

      formData.append(typeFile, convertedFile, convertedFile.name)
      request = postFileUrl(
        uploadUrl,
        formData,
        success => {
          this.loadUploadedFiles(file.name, success.response)
          if (successUploadCallback) {
            successUploadCallback()
          }
          return page < maxCalls && this.recursiveSequentialUpload({ page: page + 1, data, maxCalls })
        },
        error => {
          this.addUploadError(file.name, error)
          return page < maxCalls && this.recursiveSequentialUpload({ page: page + 1, data, maxCalls })
        },
        progressPercent => this.updateUploadProgressPercent(file.name, progressPercent),
      )
    }

    this.setState(prevState => {
      prevState.uploadingFiles[file.name] = {
        label: formatFileName(file.name),
        size: formatFileSize(file.size),
        request,
        progressPercent: 0,
        error,
      }
      return { uploadingFiles: prevState.uploadingFiles }
    })

    return { page, maxCalls }
  }

  submitFiles(files) {
    if (!files) return
    const { beforeUploadCallback } = this.props

    if (beforeUploadCallback) {
      beforeUploadCallback()
    }

    this.recursiveSequentialUpload({
      page: 1,
      data: files,
      maxCalls: files.length,
    })
  }

  updateUploadProgressPercent(fileName, progressPercent) {
    this.setState(prevState => {
      prevState.uploadingFiles[fileName].progressPercent = progressPercent
      return prevState
    })
  }

  deleteFile(file) {
    const { deleteFilesUrl, successUploadCallback, beforeUploadCallback } = this.props
    const textMessage = file.muralItemId
      ? i18n.t('file_confirm_delete_on_mural_message')
      : i18n.t('file_confirm_delete_message')

    Modal({
      title: i18n.t('file_confirm_delete_title'),
      text: textMessage,
      kind: 'confirmation',
    }).then(result => {
      if (result.isConfirmed) {
        this.setState({ loading: true }, () => {
          if (beforeUploadCallback) {
            beforeUploadCallback()
          }
          deleteUrl(
            deleteFilesUrl,
            file.id,
            () => {
              this.loadUploadedFiles()
              if (successUploadCallback) {
                successUploadCallback()
              }
            },
            (errors, message) => {
              this.setState({ errors })
            },
          )
        })
      }
    })
  }

  loadUploadedFiles(fileName = undefined, response = undefined) {
    const { fetchFilesUrl, onEachSuccessUpload } = this.props

    this.setState(
      prevState => {
        if (fileName) delete prevState.uploadingFiles[fileName]
        return { loading: true, uploadingFiles: prevState.uploadingFiles }
      },
      () => {
        if (response && onEachSuccessUpload) onEachSuccessUpload(response)
        if (!this.props.withListing) return

        fetchUrl(fetchFilesUrl, response => {
          this.setState({
            uploadedFiles: loadJson(response),
            loading: false,
          })
        })
      },
    )
  }

  newloadUploadedFiles() {
    const { fetchFilesUrl } = this.props
    this.setState({ loading: true })

    fetchUrl(fetchFilesUrl, response => {
      this.setState({
        uploadedFiles: loadJson(response),
        loading: false,
      })
    })
  }

  exceededSizeMessage(fileName) {
    console.error(fileName)
  }

  isMediaRecorderSupported() {
    return typeof MediaRecorder !== 'undefined'
  }

  abortUpload(fileName) {
    const uploadingFile = this.state.uploadingFiles[fileName]
    if (uploadingFile.request) uploadingFile.request.abort()

    this.setState(prevState => {
      delete prevState.uploadingFiles[fileName]
      return { uploadingFiles: prevState.uploadingFiles }
    })
  }

  addUploadError(fileName, error) {
    this.setState(prevState => {
      prevState.uploadingFiles[fileName].error = error
      return { uploadingFiles: prevState.uploadingFiles }
    })
  }

  render() {
    const { loading, errors, uploadingFiles, uploadedFiles } = this.state
    const { buttonsByFileType, withListing, fileUploadId, typeFile, customAction, alertKey } = this.props
    const canPerformCustomAction =
      customAction && uploadedFiles && uploadedFiles.actions && uploadedFiles.actions[customAction.action]

    if (customAction) customAction.afterAction = this.loadUploadedFiles.bind(this)

    return (
      <div className='files-container'>
        <InputFile
          id={`input-cover-file-${typeFile}-${fileUploadId}`}
          onChange={event => this.submitFiles(event)}
          errors={errors}
          className='custom-file-input d-none'
          extensions='*'
          multiple
        />

        {buttonsByFileType ? (
          <Group mt='xs' spacing='md'>
            <ButtonCard
              htmlFor={`input-cover-file-${typeFile}-${fileUploadId}`}
              description={i18n.t('general_inputs_upload_file')}
              iconClass='icon-sm-upload'
            />
            {this.isMediaRecorderSupported() ? (
              <Recorder
                submitFile={file => {
                  this.submitFiles([file])
                }}
              />
            ) : (
              <SafariRecorder
                submitFile={file => {
                  this.submitFiles([file])
                }}
              />
            )}
          </Group>
        ) : (
          <Container>
            <ButtonCard
              htmlFor={`input-cover-file-${typeFile}-${fileUploadId}`}
              description={i18n.t('general_inputs_upload_file')}
              iconClass='icon-sm-upload'
              className='mr-5 hide-in-print'
            />
          </Container>
        )}

        <FilesUploadProgressContainer uploadingFiles={uploadingFiles} abortUpload={this.abortUpload.bind(this)} />
        {withListing && (
          <div>
            {alertKey && canPerformCustomAction && (
              <div className='alert alert-success mt-4'>
                <span className='malva-bold'>
                  {i18n.t('components_molecules_file_upload_add_to_mural_new_feature')}
                </span>
                <span className='mx-2'>
                  {i18n.t(`components_molecules_file_upload_add_to_mural_${alertKey}_notice`)}
                </span>
                <i className={customAction.iconClass} />
              </div>
            )}
            {!isEmpty(uploadedFiles?.items) && (
              <div className='mt-5'>
                <FilesList
                  filesList={
                    uploadedFiles && (uploadedFiles.constructor === Array ? uploadedFiles : uploadedFiles.items)
                  }
                  loading={loading}
                  onDelete={this.deleteFile.bind(this)}
                  customAction={canPerformCustomAction ? customAction : undefined}
                />
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

function stringRequiredForFilesListing(props, propName) {
  const listingEnabled = props.withListing
  const isPresent = props[propName] !== undefined
  const isString = typeof props[propName] === 'string'

  if (listingEnabled && (!isPresent || !isString)) {
    return new Error(`The prop ${propName} is marked as required in \`FileUpload\`, but its value is \`undefined\`.`)
  }

  return undefined
}

FileUpload.propTypes = {
  typeFile: PropTypes.string.isRequired,
  fileUploadId: PropTypes.string,
  buttonsByFileType: PropTypes.bool,
  withListing: PropTypes.bool,
  uploadUrl: PropTypes.string.isRequired,
  fetchFilesUrl: stringRequiredForFilesListing,
  deleteFilesUrl: stringRequiredForFilesListing,
  onEachSuccessUpload: PropTypes.func,
  alertKey: PropTypes.string,
  customAction: PropTypes.shape({
    iconClass: PropTypes.string.isRequired,
    iconClassRemoval: PropTypes.string.isRequired,
    onAction: PropTypes.func.isRequired,
    action: PropTypes.string.isRequired,
    afterAction: PropTypes.func,
  }),
  beforeUploadCallback: PropTypes.func,
  successUploadCallback: PropTypes.func,
  appliedFile: PropTypes.bool,
}

FileUpload.defaultProps = {
  fileUploadId: '',
  buttonsByFileType: false,
  withListing: true,
  onEachSuccessUpload: undefined,
  fetchFilesUrl: undefined,
  deleteFilesUrl: undefined,
  alertKey: undefined,
  customAction: undefined,
  beforeUploadCallback: undefined,
  successUploadCallback: undefined,
  appliedFile: false,
}

export default FileUpload
