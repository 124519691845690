import { IUserInfo } from './interfaces'
import { Group, Avatar, Stack, Text, Skeleton } from 'components'
import { getInitials } from 'helpers'

export const UserInfo = ({ userName, image, loading, role }: IUserInfo) => {
  if (loading) {
    return (
      <Group spacing='xs'>
        <Skeleton w={24} h={24} radius='xl' />
        <Stack spacing={4}>
          <Skeleton w={180} h={16} />
          {role && <Skeleton w={180} h={16} />}
        </Stack>
      </Group>
    )
  }
  return (
    <Group spacing='xs'>
      <Avatar radius='xl' src={image}>
        {getInitials(userName)}
      </Avatar>
      <Stack spacing={4}>
        <Text size='md' weight={500} inline>
          {userName}
        </Text>
        {role && (
          <Text color='dimmed' size='sm' inline>
            {role}
          </Text>
        )}
      </Stack>
    </Group>
  )
}
