import { Layout, Heading, Space } from 'components'
import { CurrentUserContextProvider, NotificationProvider } from 'context'
import { useTranslation } from 'react-i18next'
import { Body } from './Body'

export const StudentCreationScreen = () => {
  const { t } = useTranslation()

  return (
    <CurrentUserContextProvider>
      <NotificationProvider>
        <Layout>
          <Heading
            size='xl'
            text={t('student_creation_screen.title')}
            supportText={t('student_creation_screen.subtitle')}
          />
          <Space h='lg' />
          <Body />
        </Layout>
      </NotificationProvider>
    </CurrentUserContextProvider>
  )
}
