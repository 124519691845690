/* eslint-disable */
import PropTypes from "prop-types";

const Check = ({ width, fill }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox={`0 0 ${width} ${width}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C3.85357 0 0.5 3.35357 0.5 7.5C0.5 11.6464 3.85357 15 8 15C12.1464 15 15.5 11.6464 15.5 7.5C15.5 3.35357 12.1464 0 8 0ZM7.4 11.0357C7.12143 11.3143 6.67143 11.3143 6.39286 11.0357L3.88571 8.44286L3.83214 8.38929C3.54286 8.11071 3.07143 7.63929 3.59643 6.99643C4.16429 6.31071 4.925 6.54643 5.20357 6.825L6.88571 8.44286L10.6679 4.575C10.9464 4.29643 11.9643 4.29643 12.3286 4.76786C12.6821 5.25 12.2429 6.09643 11.9643 6.375L7.4 11.0357Z"
        fill={fill}
      />
    </svg>
  );
};

Check.propTypes = {
  fill: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Check;
