import { api } from '../../../helpers'
import { ISkillsStandards, ISkillsStandardsInfo, updateData } from '../components/ProjectSkillsStandards/interfaces'

const step3Paths = {
  getSkillsStandards: (projectID: string) => `/api/projects/${projectID}/skills_and_contents`,
  getInfo: (projectID: string, activeLvl2ID: string) =>
    `/api/projects/${projectID}/developed_students_and_ongoing_projects/${activeLvl2ID}`,
  getInfoSearch: (projectID: string) =>
    `/api/projects/${projectID}/developed_students_and_ongoing_projects_by_lv4_items`,
  updateSkillsStandards: (projectID: string) => `/api/projects/${projectID}/update_skills_and_contents`,
}

export const getSkillsStandards = async (projectID: string): Promise<ISkillsStandards> => {
  const response = await api.get<ISkillsStandards>(step3Paths.getSkillsStandards(projectID))
  return response.data
}

export const getInfo = async (projectID: string, activeLvl2ID: string): Promise<ISkillsStandardsInfo[]> => {
  const response = await api.get<ISkillsStandardsInfo[]>(step3Paths.getInfo(projectID, activeLvl2ID))
  return response.data
}

export const getInfoSearch = async (projectID: string, lvl4IDs: string[]): Promise<ISkillsStandardsInfo[]> => {
  const response = await api.post<ISkillsStandardsInfo[]>(step3Paths.getInfoSearch(projectID), {
    lv4_item_ids: lvl4IDs,
  })
  return response.data

}

export const updateSelectedSkillsStandards = async (projectID: string, data: updateData) => {
  const response = await api.patch(step3Paths.updateSkillsStandards(projectID), data)
  return response.data
}
