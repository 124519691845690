import { useContext } from 'react'
import { Card, Text } from 'components'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { ICardSkillsStandardsLvl2 } from './interfaces'
import { useStyles } from './styles'
import { useTranslation } from 'react-i18next'

export function CardSkillsStandardsLvl2({ item, color, type }: ICardSkillsStandardsLvl2) {
  const { t } = useTranslation()
  const { classes } = useStyles({ color })

  const { handleSetCurrent,
    getLvl2SelectedQtd } = useContext(SkillsStandardsContext)

  const selectedQtd = getLvl2SelectedQtd(item.id, type)

  return (
    <Card component='a' onClick={() => handleSetCurrent(item.id, type)} shadow='md' withBorder className={classes.root}>
      <Card.Section p='md' className={classes.maxHeight}>
        <Text>{item.name}</Text>
        {selectedQtd ? (
          <Text size='sm' color='dimmed'>
            {selectedQtd}{' '}
            {type === 'skill' ? t('new_project_page.skills_selected') : t('new_project_page.standards_selected')}
          </Text>
        ) : null}
      </Card.Section>
      <Card.Section className={classes.borderBottom}></Card.Section>
    </Card>
  )
}
