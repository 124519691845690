/* eslint-disable */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { WrapperEvaluate, EvaluateHeader, ScoreContainer } from './StudentModalContentStyled'
import { ScoreCard } from 'components/ScoreComponent/ScoreCard'
import { times } from 'lodash'
import { IStudentAsssessmentsprops } from './EvaluativeItemTypes'
import { ScoreItemLabel } from 'components/ScoreComponent/ScoreItem'
import LV4ItemContainer from '@legacy/common/_molecules/Lv4ItemContainer'

const EvaluativeStudentItem = ({ item, updateScores }: IStudentAsssessmentsprops) => {
  const [score, setScore] = useState(item.studentScore)
  const { t } = useTranslation()

  const RUBRIC_KEYS = ['rubric_1', 'rubric_2', 'rubric_3', 'rubric_4', 'rubric_5']
  const DefaultModalRubrics = RUBRIC_KEYS.map(key => t(key))

  const onClickScore = (i: number) => {
    const scorepayload = score === i + 1 ? 0 : i + 1
    const itempayload = {
      assessment_score_id: item.id,
      student_score: scorepayload,
    }

    setScore(scorepayload)
    updateScores(itempayload)
  }

  return (
    <WrapperEvaluate>
      <EvaluateHeader>
        <LV4ItemContainer
          name={item.name}
          lv1Name={item.lv1Name}
          id={item.id}
          curriculumKind={'content'}
          color={item.color}
          originalCodes={item.originalCodes}
          cycles={item.cycles}
          rubrics={[]}
          hideContainer
        />
      </EvaluateHeader>

      <ScoreContainer>
        {times(5, i => (
          <ScoreCard
            label={(i + 1) as ScoreItemLabel}
            key={i + 1}
            active={score === i + 1}
            rubric={DefaultModalRubrics[i]}
            onClick={() => {
              onClickScore(i)
            }}
          />
        ))}
      </ScoreContainer>
    </WrapperEvaluate>
  )
}

export default EvaluativeStudentItem
