import React, { useMemo } from 'react'
import { v4 as UuidV4 } from 'uuid'

import { Container } from './styled'

export interface IToggle {
  readonly value: boolean
  readonly onToggle: React.ChangeEventHandler<HTMLInputElement>
  readonly children?: never
  readonly disabled?: boolean
}

const Toggle = ({ value, onToggle, disabled }: IToggle) => {
  const toogleId = useMemo(() => `toogle-checkbox-${UuidV4()}`, [])

  return (
    <Container>
      <input
        type="checkbox"
        id={toogleId}
        checked={value}
        onChange={onToggle}
        disabled={disabled}
      />
      <label htmlFor={toogleId}>{toogleId}</label>
    </Container>
  )
}

export default Toggle
