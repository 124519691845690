import { useSearchParams } from 'react-router-dom'
import { Tooltip, Text, Anchor, Box } from 'components'

export interface IStudentsList {
  readonly students: string[]
}

const StudentList = ({ students }: IStudentsList) => {
  const [queryParameter] = useSearchParams()
  const DISPLAYNUMBER = 4
  const studentsList = students.slice(0).splice(0, DISPLAYNUMBER)
  const studentsListTooltip = students.slice(0).splice(DISPLAYNUMBER)
  const isApp = queryParameter.get('app') === 'true'

  return (
    <Box mt='md'>
      <Text size='sm' span>{studentsList.join(', ')}</Text>
      {students.length > DISPLAYNUMBER && (
        <Text size='sm' span>
          {' e mais'}
          <Tooltip
            events={{ hover: !isApp, focus: !isApp, touch: isApp }}
            label={studentsListTooltip.join(', ')}
            withArrow
            withinPortal
          >
            <Anchor inline>
              {` ${students.length - DISPLAYNUMBER} ${
                studentsListTooltip.length === 1 ? 'estudante' : 'estudantes'
              }`}
            </Anchor>
          </Tooltip>
        </Text>
      )}
    </Box>
  )
}

export default StudentList
