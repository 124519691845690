import { useState, useEffect, useCallback } from 'react'
import mixpanel from 'mixpanel-browser'
import env from '../../../env.json'

import ErrorService from '@legacy/common/Tools/ErrorService'

import useCurrentUser from './current-user-hook'

function useMixPanel() {
  const [initialized, setInitialized] = useState(false)
  const token = env['process.env.REACT_APP_MIXPANELTOKEN']?.replaceAll('"', '')

  const currentUser = useCurrentUser()

  const indentifyCurrentUser = useCallback(() => {
    if (!currentUser || currentUser.loading) {
      return false
    }

    const { id, environment, lumiar_admin: lumiarAdmin, profile_used: profileUsed, locale } = currentUser.data

    const currentSchool = currentUser?.data?.selected_school?.name

    mixpanel.alias(id)
    mixpanel.identify(id)
    mixpanel.people.set({
      id: id,
      environment: environment,
      currentSchool: currentSchool,
      lumiarAdmin: lumiarAdmin,
      profileUsed: profileUsed,
      locale: locale,
    })
    return true
  }, [mixpanel, currentUser])

  useEffect(() => {
    if (token && currentUser?.fetchingUser === undefined && !initialized) {
      Promise.all([mixpanel.init(token, { debug: false }), indentifyCurrentUser()])
        .then(() => setInitialized(true))
        .catch(err => [ErrorService.notice(err), setInitialized(false)])
    }
  }, [currentUser, initialized])

  const track = useCallback(
    (eventName, eventProps = {}) => {
      const superProperties =
        currentUser.logged !== false
          ? {
              school: currentUser?.data?.selected_school?.name,
              profile_used: currentUser?.profileUsed,
              environment: currentUser?.environment,
            }
          : {}

      try {
        mixpanel.track(eventName, {
          ...superProperties,
          ...eventProps,
        })
      } catch (err) {
        ErrorService.notice(`Cannot sent mixpanel track event - ${eventName}`)
      }
    },
    [mixpanel, currentUser],
  )

  return { track, initialized }
}

export default useMixPanel
