import { distance } from 'fastest-levenshtein'
import { deburr } from 'lodash'
import { IStudentToSelect } from 'pages/Feed/interfaces'
import { Dispatch, SetStateAction } from 'react'

export const searchStudents = (
  firstName: string,
  lastName: string,
  setStudents: Dispatch<SetStateAction<IStudentToSelect[]>>,
  selectedProject: string,
) => {
  return setStudents(students => {
    const studentsWithDistances = students.map(student => {
      const normalizedStudent = {
        ...student,
        firstName: deburr(student.firstName.trim().toLowerCase()),
        lastName: deburr(student.lastName.trim().toLowerCase()),
      }

      const normalizeFirstName = deburr(firstName.toLowerCase().trim())

      const normalizeLastName = deburr(lastName.toLowerCase().trim())

      const firstNameToFirstNameDistance = distance(
        normalizedStudent.firstName,
        normalizeFirstName,
      )

      const lastNameToLastNameDistance = distance(
        normalizedStudent.lastName,
        normalizeLastName,
      )

      const firstNameToLastNameDistance = distance(
        normalizedStudent.firstName,
        normalizeLastName,
      )

      const lastNameToFirstNameDistance = distance(
        normalizedStudent.lastName,
        normalizeFirstName,
      )

      const hasInSelectedProject = selectedProject?.length
        ? student.assossiatedProjects.some(({ id }) => id === selectedProject)
        : true

      if (!hasInSelectedProject && student.selected) {
        student.selected = false
      }

      return {
        ...student,
        firstNameToFirstNameDistance,
        lastNameToLastNameDistance,
        firstNameToLastNameDistance,
        lastNameToFirstNameDistance,
        hasInSelectedProject,
        totalDistance:
          firstNameToFirstNameDistance + lastNameToLastNameDistance,
      }
    })

    let foundExactValue = false

    const exactValue = studentsWithDistances.map(student => {
      if (student.totalDistance === 0 && student.hasInSelectedProject) {
        foundExactValue = true
        return {
          ...student,
          filtered: true,
        }
      }

      return {
        ...student,
        filtered: false,
      }
    })

    if (foundExactValue) return exactValue

    let foundMachedStudents = false

    const matchedStudents = studentsWithDistances.map(student => {
      if (
        (student.firstNameToFirstNameDistance === 0 ||
          student.firstNameToLastNameDistance === 0 ||
          student.lastNameToFirstNameDistance === 0) &&
        student.hasInSelectedProject
      ) {
        foundMachedStudents = true
        return {
          ...student,
          filtered: true,
        }
      }

      return {
        ...student,
        filtered: false,
      }
    })

    if (foundMachedStudents) return matchedStudents

    return studentsWithDistances.map(student => ({
      ...student,
      filtered: student.hasInSelectedProject,
    }))
  })
}
