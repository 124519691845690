import { Stack, Skeleton, Divider } from 'components'

export const FinalProjectReportSkeleton = () => {
  return (
    <Stack spacing='md'>
      <Stack spacing='xs'>
        <Skeleton height={40} width={200} />
        <Skeleton height={20} width={100} />
      </Stack>
      <Divider />
      <Stack spacing='xs'>
        <Skeleton height={40} />
        <Skeleton height={20} />
      </Stack>
    </Stack>
  )
}
