import { useEffect, useContext, createContext, useState } from 'react'
import { getCurrentUser } from '@legacy/common/services/users-service'
import { ICurrentUser, CurrentUserContextValuesType } from './interface'

const initialValues = {
  currentUser: undefined,
  getData: () => {},
}

const CurrentUserContext = createContext<CurrentUserContextValuesType>(initialValues)

export const CurrentUserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = useState<ICurrentUser | undefined>(undefined)

  const getData = async () => {
    const currentUser = await getCurrentUser()
    if (currentUser.status === 'found' && currentUser.data) {
      const response = currentUser.data
      setCurrentUser(response)
    }
  }


  const value = {
    currentUser,
    getData,
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext)

  if (context === undefined) {
    throw new Error('useCurrentUserContext must be used within a CurrentUserContextProvider')
  }

  return context
}
