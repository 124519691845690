import { Checkbox, Avatar, Card, Group, Text } from 'components'
import { IStudentItem } from './interface'
import { getInitials } from 'helpers'
import { useStyles } from './styles'

export const StudentItem = ({ selectedStudent, setStudents }: IStudentItem) => {
  const { classes } = useStyles()

  const handleOnChange = () => {
    setStudents(students =>
      students.map(student => ({
        ...student,
        selected: student.id === selectedStudent.id ? !student.selected : student.selected,
      })),
    )
  }

  return (
    <Card onClick={handleOnChange} withBorder shadow='md' p='md'>
      <Checkbox
        classNames={classes}
        checked={selectedStudent.selected}
        id={selectedStudent.id}
        label={
          <Group spacing='sm'>
            <Avatar src='' size='md'>
              {getInitials(`${selectedStudent.firstName} ${selectedStudent.lastName}`)}
            </Avatar>
            <Text size='md'>{`${selectedStudent.firstName} ${selectedStudent.lastName}`}</Text>
          </Group>
        }
        size='md'
        onChange={() => {}} // empty arrow function needed
      />
    </Card>
  )
}
