import styled from 'styled-components'

export const StyledLogoImage = styled.img`
  width: auto;
  height: 52px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

export default {}
