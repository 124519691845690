import styled from 'styled-components'

export const ProjectContainer = styled.div`
    background: white;
    border-radius: 10px;
    padding: 40px;
    @media (max-width: 800px) and (min-width: 501px) {
        padding: 30px;
    }
    @media (max-width: 500px) {
        padding: 20px;
    }
`