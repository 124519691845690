import { createStyles } from 'hooks'

export const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flexFlow: 'column',
  },
  header: {
    flex: '0 1 auto',
    position: 'relative',
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    height: '100%',
    flexDirection: 'column',
    flexFlow: 'column',
  },
  contentBox: {
    flex: '1 1 auto',
    height: '100%',
  },
  contentStack: {
    flex: '1 1 auto',
  },
}))
