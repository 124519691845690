import { useMediaQuery, useMantineTheme } from 'hooks'
import { Stack, Skeleton } from 'components'

export const GuardianDashboardSkeleton = () => {
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  return (
    <Stack spacing='md' mt={isMobile ? 0 : 80}>
      <Skeleton height={70} />
      <Skeleton height={106} />
      <Skeleton height={80} />
      <Skeleton height={50} />
      <Skeleton height={430} />
    </Stack>
  )
}
