import { Layout } from 'components/Layout'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { StepperProvider } from './context/StepperContext'
import { ProjectStepper } from './components/ProjectStepper'
import { ProjectButtonsProvider } from './context/ProjectButtonsContext'

export const NewProject = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <ProjectButtonsProvider>
          <StepperProvider>
            <ProjectStepper />
          </StepperProvider>
        </ProjectButtonsProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
