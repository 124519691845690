import { useContext, useEffect, useRef, useState } from 'react'
import { Paper } from 'components'
import { useMantineTheme, useMediaQuery, rem } from 'hooks'
import { StepperContext } from '../../context/StepperContext'
import { ProjectStepsButtons } from '../ProjectStepsButtons'
import { useForm } from 'context'
import { LessonsList } from 'components'
import { LessonsProvider } from 'components'
import { getActivities, getProjectTab } from 'pages/NewProject/service/projects'
import LessonHeader from './LessonHeader'
import { IPagination, IProjectKind } from 'interfaces'

export function ProjectPlanning() {
  const { projectId, projectStatus } = useContext(StepperContext)
  const [lessons, setLessons] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [alreadyFetching, setAlreadyFetching] = useState<boolean>(false)
  const [schoolTermEndAt, setSchoolTermEndAt] = useState<string>('')
  const [schoolTermStartAt, setSchoolTermStartAt] = useState<string>('')
  const [page, setPage] = useState<number>(1)
  const [pagination, setPagination] = useState<IPagination>({} as IPagination)
  const loadingRef = useRef(loading)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const sentinelRef = useRef(null)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const [finalTabInfo, setFinalTabInfo] = useState<any>({})
  const [kind, setKind] = useState<IProjectKind>(null)

  useEffect(() => {
    loadingRef.current = loading
  }, [loading])

  const form = useForm({
    initialValues: {
      submitButton: 0,
    },
  })

  const incrementPage = () => {
    setPage(prevPage => prevPage + 1)
  }

  const fetchAndSetActivities = () => {
    setAlreadyFetching(true)
    const id = projectId

    if (!id) {
      setAlreadyFetching(false)
      return
    }

    if (page > pagination?.pages) return

    getActivities(id, page)
      .then(({ schoolTermEndAt, schoolTermStartAt, sessionMeetings, pagination }) => {
        setSchoolTermEndAt(schoolTermEndAt)
        setSchoolTermStartAt(schoolTermStartAt)

        const uniqueLessons = [...lessons, ...sessionMeetings].reduce((acc, lesson) => {
          acc[lesson.id] = lesson
          return acc
        }, {})

        setLessons(Object.values(uniqueLessons))
        incrementPage()
        setPagination(pagination)
      })
      .catch(error => {
        console.error('Error fetching activities:', error)
      })
      .finally(() => {
        setAlreadyFetching(false)
        if (sentinelRef.current && observerRef.current) {
          observerRef.current.observe(sentinelRef.current)
        }
      })
  }

  useEffect(() => {
    getActivitiesforFirstPageAndResetPagetoOne()
  }, [])

  useEffect(() => {
    const handleIntersect: IntersectionObserverCallback = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !loadingRef.current && !alreadyFetching) {
          if (page <= pagination?.pages) fetchAndSetActivities()
          if (observerRef.current) {
            observerRef.current.unobserve(entry.target)
          }
        }
      })
    }

    observerRef.current = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    })

    if (sentinelRef.current && observerRef.current) {
      observerRef.current.observe(sentinelRef.current)
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [fetchAndSetActivities])

  const getActivitiesforFirstPageAndResetPagetoOne = () => {
    setPage(1)
    setLoading(true)

    const id = projectId
    if (!id) return

    getActivities(id, 1).then(({ schoolTermEndAt, schoolTermStartAt, sessionMeetings, pagination }) => {
      setLessons(sessionMeetings)
      setSchoolTermEndAt(schoolTermEndAt)
      setSchoolTermStartAt(schoolTermStartAt)
      setPagination(pagination)
      setLoading(false)
    })
  }

  useEffect(() => {
    const id = projectId
    if (!id) return
    getProjectTab(id, '4').then(res => {
      if (res && res?.status !== null && res?.status !== undefined) {
        setKind(res.kind)
        if (res.status) {
          setFinalTabInfo({
            id: id,
            status: res.status,
          })
        }
      }
    })
  }, [])

  return (
    <LessonsProvider>
      <Paper p={isMobile ? 'md' : 'xl'} mx={isMobile ? rem(-20) : 0} radius='md' withBorder shadow='xs'>
        <LessonHeader />
        <LessonsList
          kind={kind}
          showLessonTitle={false}
          projectID={projectId}
          editable
          lessonsList={lessons}
          journal={false}
          loading={loading}
          schoolTermStartAt={schoolTermStartAt}
          schoolTermEndAt={schoolTermEndAt}
          fetchAndSetActivities={getActivitiesforFirstPageAndResetPagetoOne}
          projectStatus={projectStatus}
        />
        <ProjectStepsButtons
          formAction={form.setFieldValue}
          finalTabInfo={finalTabInfo}
          schoolTermEndAt={schoolTermEndAt}
          kind={kind}
        />
      </Paper>
      <div ref={sentinelRef}></div>
    </LessonsProvider>
  )
}
