import styled from 'styled-components'

export const Wrap = styled.button<{ active: boolean }>`
  background: none repeat scroll 0 0 transparent;
  background-color: white;
  border: medium none;
  border-spacing: 0;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-indent: 0;
  width: 62px;
  height: 72px;
  border: 2px solid ${props => (props.active ? 'var(--color-primary-ultradark)' : 'transparent')};
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  :hover {
    opacity: 0.9;
    border: 2px solid var(--color-primary-ultradark);
  }
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
`

export const Label = styled.span<{ active: boolean }>`
  font-size: 1.75rem;
  font-weight: 700;
  color: ${props => (props.active ? 'var(--color-primary-ultradark)' : 'var(--color-black)')};
`

export const IconContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
`
