import { createStyles } from 'hooks'

export const useStyles = createStyles(
  (
    theme,
    {
      fullWidth,
      disabled,
      inline,
    }: {
      fullWidth: boolean | undefined
      disabled: boolean | undefined
      inline: boolean | undefined
    },
  ) => ({
    anchor: {
      display: inline ? 'inline-flex' : 'flex',
      alignItems: 'center',
      width: fullWidth ? '100%' : 'auto',
      justifyContent: fullWidth ? 'center' : 'flex-start',
      cursor: disabled ? 'not-allowed' : 'pointer',
      opacity: disabled ? 0.5 : 1,
    },
    iconLeft: {
      color: 'inherit',
      marginRight: theme.spacing.xs,
    },
    iconRight: {
      color: 'inherit',
      marginLeft: theme.spacing.xs,
    },
  }),
)
