import styled from 'styled-components'
import { ITextAreaStyled } from './TextAreaTypes'

export const TextAreaStyled = styled.textarea<ITextAreaStyled>`
  width: 100%;
  padding: 16px;
  border: 1px solid;
  border-color: ${({ valid }) => (valid ? '#d3d2d7' : '#E5251A')};
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.2;
  color: var(--color-black);
  resize: none;
`

export const ValidationTextStyled = styled.span`
  color: red;
`
