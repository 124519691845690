import { Grid, CardFileUploaded } from 'components'

interface UploadedFile {
  id: string
  src: string
  name: string
}

interface FileListUploadedProps {
  files: UploadedFile[]
  onRemove?: () => void
}

export const FileListUploaded = ({ files, onRemove }: FileListUploadedProps) => {
  return (
    <Grid>
      {files && files.length > 0 ? files.map(file => (
        <Grid.Col key={file.id} span={6} xs={4} sm={3} md={2} lg={2} xl={2}>
          <CardFileUploaded
            name={file.name}
            src={file.src}
            onRemove={onRemove}
          />
        </Grid.Col>
      )) : null}
    </Grid>
  )
}
