/* eslint max-len: 0 */

interface IconProps {
  width: number
  height: number
  color: string
}

interface IconHandsProps {
  width?: number
  height?: number
  color?: string
  value: number
}

export const IconHands = ({ width = 24, height = 24, color = 'black', value }: IconHandsProps) => {
  const handIcons: { [key: number]: JSX.Element } = {
    5: <HandIcon5 width={width} height={height} color={color} />,
    4: <HandIcon4 width={width} height={height} color={color} />,
    3: <HandIcon3 width={width} height={height} color={color} />,
    2: <HandIcon2 width={width} height={height} color={color} />,
    1: <HandIcon1 width={width} height={height} color={color} />,
  }
  return handIcons[value] || handIcons[1]
}

export const HandIcon1 = ({ width = 24, height = 24, color = 'black' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4694_27173)'>
      <path
        d='M7.89796 14.3385C9.87818 14.3766 12.7071 16.7856 12.6685 18.7128M8.81133 24V21.2233C8.79847 21.0458 8.72132 20.8683 8.59273 20.7288L4.86375 16.8997C4.22082 16.2404 3.89936 14.9852 4.02794 14.3259C4.0408 14.2244 4.06652 14.1103 4.10509 13.9835L4.83803 12.0436C4.90232 11.9422 4.96662 11.8408 5.03091 11.752C5.40381 11.2702 5.78957 11.1054 6.40678 11.1181L12.9004 11.1561C13.119 11.1561 13.299 11.3463 13.2861 11.5745C13.2347 12.1958 12.8361 13.3496 12.7203 13.5524C12.3603 14.0976 11.8331 14.4146 11.1516 14.4019C10.6887 14.3893 9.49283 14.3766 8.82419 14.3639L7.60262 14.3385M19.5737 12.386V17.166C19.5737 20.2343 17.4263 19.7779 17.4263 21.7305C17.4263 22.973 17.4263 24 17.4263 24M16.1792 14.1484L16.9508 9.35571C17.0536 8.68373 17.6966 8.23996 18.3652 8.34139L18.9567 8.43014C19.6382 8.53158 20.0882 9.16553 19.9854 9.82484L19.2139 14.6175M19.2524 14.3385C19.1495 15.0105 18.5066 15.4543 17.838 15.3529L17.2465 15.2641C16.565 15.1627 16.1149 14.5287 16.2178 13.8694M10.4195 10.6109L11.0495 6.62969C11.1524 5.9577 11.7953 5.51393 12.464 5.61536L13.0555 5.70412C13.737 5.80555 14.187 6.4395 14.0841 7.09881L13.3126 11.8915M6.70304 10.3447L6.57445 2.26813C6.56159 1.58346 7.10165 1.02558 7.78316 1.0129L8.38751 1.00022C9.06902 0.987543 9.63479 1.52006 9.64765 2.19205L9.77624 10.2686M13.2862 12.665L14.0577 7.87229C14.1605 7.2003 14.8035 6.75653 15.4721 6.85797L16.0636 6.94672C16.7451 7.04815 17.1952 7.6821 17.0923 8.34141L16.3208 13.1341C16.2179 13.8061 15.5745 14.2499 14.9058 14.1484L14.3143 14.0597C13.6328 13.9582 13.1833 13.3243 13.2862 12.665Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4694_27173'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)

export const HandIcon2 = ({ width = 24, height = 24, color = 'black' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4694_27174)'>
      <rect width='24' height='24' fill='none' />
      <path
        d='M7.14163 14.1373C9.24562 14.1762 12.2513 16.6354 12.2103 18.6027M8.11203 24V21.1654C8.09837 20.9842 8.0164 20.803 7.87978 20.6607L3.91773 16.7518C3.23462 16.0788 2.89307 14.7974 3.02969 14.1244C3.04335 14.0208 3.07067 13.9043 3.11166 13.7749L3.89041 11.7946C3.95872 11.691 4.02703 11.5875 4.09534 11.5098C4.49154 11.018 4.90141 10.8368 5.5572 10.8497L12.4566 10.8886C12.6889 10.8886 12.8801 11.0827 12.8665 11.3157C12.8118 11.9499 12.3883 13.1277 12.2653 13.3348C11.8828 13.8914 11.3227 14.215 10.5986 14.202C10.1067 14.1891 8.83613 14.1761 8.1257 14.1632L6.82778 14.1373M19.5471 12.1441V17.0236C19.5471 20.1559 17.2655 19.6899 17.2655 21.6832C17.2655 22.9516 17.2655 24 17.2655 24M15.9404 13.9431L16.7602 9.0506C16.8695 8.36462 17.5526 7.91161 18.263 8.01515L18.8915 8.1187C19.6156 8.22224 20.0938 8.8694 19.9845 9.54244L19.1647 14.435M19.2057 14.1373C19.0964 14.8233 18.4132 15.2763 17.7028 15.1728L17.0744 15.0822C16.3503 14.9786 15.8721 14.3315 15.9814 13.6584M12.8664 13.063L13.6862 8.17052C13.7955 7.48453 14.4786 7.03152 15.189 7.13507L15.8175 7.22567C16.5416 7.32921 17.0198 7.97637 16.9105 8.64942L16.0907 13.5419M16.1318 13.2572C16.0225 13.9431 15.3394 14.3962 14.629 14.2926L13.9869 14.202C13.2628 14.0985 12.7846 13.4513 12.8939 12.7783M9.73777 10.7721V2.24254C9.73777 1.55656 10.3252 1 11.0493 1H11.6915C12.4156 1 13.003 1.55656 13.003 2.24254V11.4192M6.47234 10.2544L5.39303 4.6888C5.2564 4.00281 5.73458 3.35565 6.44502 3.22622L7.07348 3.12268C7.78392 2.99325 8.48069 3.44626 8.60365 4.1193L9.68296 9.68486'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4694_27174'>
        <rect width='24' height='24' fill='none' />
      </clipPath>
    </defs>
  </svg>
)
export const HandIcon3 = ({ width = 24, height = 24, color = 'black' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4694_27175)'>
      <rect width='24' height='24' fill='none' />
      <path
        d='M7.15371 14.1373C9.26383 14.1762 12.2783 16.6354 12.2372 18.6027M8.12694 24V21.1654C8.11324 20.9842 8.03103 20.803 7.894 20.6607L3.92041 16.7518C3.2353 16.0788 2.89275 14.7974 3.02977 14.1244C3.04348 14.0208 3.07088 13.9043 3.11199 13.7749L3.893 11.7946C3.96151 11.691 4.03002 11.5875 4.09853 11.5098C4.49589 11.018 4.90696 10.8368 5.56465 10.8497L12.4842 10.8886C12.7171 10.8886 12.909 11.0827 12.8953 11.3157C12.8404 11.9499 12.4157 13.1277 12.2924 13.3348C11.9087 13.8914 11.3469 14.215 10.6207 14.202C10.1274 14.1891 8.85315 14.1761 8.14064 14.1632L6.83895 14.1373M19.5953 12.1441V17.0236C19.5953 20.1559 17.3071 19.6899 17.3071 21.6832C17.3071 22.9516 17.3071 24 17.3071 24M13.6622 13.1277L17.2521 9.52954C17.7454 9.02476 18.5812 8.99887 19.1156 9.47777L19.5815 9.89195C20.1159 10.3579 20.1433 11.1474 19.6363 11.6522L16.0463 15.2504M16.252 15.0304C15.7588 15.5351 14.9229 15.561 14.3886 15.0821L13.9227 14.6679C13.3883 14.202 13.3609 13.4125 13.8679 12.9077M13.2378 8.57178L14.0599 3.67927C14.1695 2.99328 14.8546 2.54027 15.5671 2.64381L16.1974 2.73441C16.9236 2.83796 17.4032 3.48512 17.2936 4.15816L16.2385 10.4097M9.75741 10.7721V2.24254C9.75741 1.55656 10.3466 1 11.0728 1H11.7168C12.443 1 13.0322 1.55656 13.0322 2.24254V11.4192M6.48247 10.2544L5.4 4.6888C5.26298 4.00281 5.74256 3.35565 6.45506 3.22622L7.08536 3.12268C7.79787 2.99325 8.49667 3.44626 8.61999 4.1193L9.70245 9.68486'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4694_27175'>
        <rect width='24' height='24' fill='none' />
      </clipPath>
    </defs>
  </svg>
)
export const HandIcon4 = ({ width = 24, height = 24, color = 'black' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4694_27176)'>
      <rect width='24' height='24' fill='none' />
      <path
        d='M6.93421 14.402C8.93281 14.4398 11.788 16.833 11.749 18.7476M7.85599 24V21.2415C7.84302 21.0652 7.76515 20.8888 7.63537 20.7503L3.87177 16.9463C3.22287 16.2913 2.89842 15.0444 3.0282 14.3894C3.04118 14.2886 3.06713 14.1752 3.10607 14.0493L3.84581 12.1221C3.9107 12.0088 3.97559 11.908 4.04048 11.8324C4.41684 11.3538 4.80618 11.1774 5.42912 11.19L11.983 11.2278C12.2036 11.2278 12.3853 11.4167 12.3723 11.6435C12.3204 12.2607 11.9181 13.4069 11.8013 13.6084C11.4379 14.15 10.9058 14.4649 10.218 14.4523C9.75077 14.4398 8.54383 14.4272 7.86897 14.4146L6.63607 14.402M18.7183 11.9206V17.1983C18.7183 20.2465 16.551 19.793 16.551 21.7328C16.551 22.9672 16.551 23.9875 16.551 23.9875M15.6168 11.9206L16.9275 6.10134C17.0573 5.44636 17.7192 5.0181 18.394 5.14406L18.991 5.25742C19.6659 5.38338 20.1071 6.02577 19.9774 6.68075L18.7315 11.9206M12.5153 11.7694L12.8917 4.35047C12.9306 3.68289 13.5146 3.16646 14.2024 3.19165L14.8124 3.21684C15.5002 3.25463 16.0323 3.82144 16.0064 4.48902L15.63 11.908M9.4003 11.1145V2.2092C9.4003 1.54162 9.95836 1 10.6462 1H11.2562C11.944 1 12.502 1.54162 12.502 2.2092V11.7569M6.29843 10.6106L5.27317 5.1944C5.1434 4.52682 5.59762 3.89703 6.27248 3.77107L6.86946 3.6703C7.54431 3.54435 8.20619 3.9852 8.32299 4.64018L9.34825 10.0564'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4694_27176'>
        <rect width='24' height='24' fill='none' />
      </clipPath>
    </defs>
  </svg>
)
export const HandIcon5 = ({ width = 24, height = 24, color = 'black' }: IconProps) => (
  <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_4694_27177)'>
      <rect width='24' height='24' fill='none' />
      <path
        d='M12.0089 17.5558C11.774 15.6697 10.4115 14.2551 8.10919 13.9408L7.4514 12.9977C7.07551 12.4738 6.41772 11.5308 6.18279 11.164C5.80691 10.6401 5.29007 10.4305 4.72625 10.4829C4.53831 10.4829 2.47096 11.1116 3.12875 12.0547L6.51169 16.8223L9.37777 21.0137C9.47174 21.1708 9.51872 21.3804 9.51872 21.5376L9.47174 24M17.5532 24C17.5532 24 17.5532 23.0046 17.5532 21.7472C17.5532 19.8087 19.4326 20.2278 19.4326 17.1891V11.8975L19.9964 6.55351C20.0434 5.87242 19.6206 5.2961 19.0097 5.24371L18.4929 5.19132C17.8821 5.13893 17.3653 5.61046 17.3183 6.29155L16.7545 12.2118M14.0293 11.7403L14.3582 4.30068C14.4052 3.61958 14.922 3.09567 15.4859 3.14806L16.0027 3.20045C16.6135 3.25284 17.0834 3.82915 17.0364 4.45785L16.7075 11.8975M11.3042 11.1116V2.20501C11.3042 1.52392 11.774 1 12.3848 1H12.9017C13.5125 1 13.9823 1.52392 13.9823 2.20501V11.7927M8.71998 13.5741L7.07549 4.03874C6.93454 3.35765 7.35741 2.72895 7.92123 2.62416L8.43807 2.51938C9.04888 2.3622 9.6127 2.83373 9.70667 3.46243L11.0692 10.7449'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_4694_27177'>
        <rect width='24' height='24' fill='none' />
      </clipPath>
    </defs>
  </svg>
)
