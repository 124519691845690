import { useState, useEffect, useCallback, ChangeEvent, useRef } from 'react'
import { Input, ActionIcon } from '@mantine/core'
import { IconSearch, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

interface Props {
  readonly label?: string
  readonly fetchSearch: (value: string) => void
  readonly cleanSearch: (value: string) => void
  readonly value?: string
  readonly fetchOnEnter?: boolean
  readonly debounceTime?: number
}

export const InputTextSearch: React.FC<Props> = ({
  label,
  fetchSearch,
  cleanSearch,
  value = '',
  fetchOnEnter = false,
  debounceTime = 700,
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState(value)
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    setSearch(value)
  }, [value])

  const debouncedFetchSearch = useCallback(
    (value: string) => {
      if (timeoutId) clearTimeout(timeoutId)

      const newTimeoutId = setTimeout(() => {
        fetchSearch(value)
      }, debounceTime)

      setTimeoutId(newTimeoutId)
    },
    [fetchSearch, timeoutId, debounceTime],
  )

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setSearch(value)
    if (!fetchOnEnter) debouncedFetchSearch(value)
  }

  const handleClearInput = () => {
    setSearch('')
    cleanSearch('')
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <Input
      ref={inputRef}
      icon={<IconSearch />}
      placeholder={label || t('students_index_search')}
      size='md'
      radius='xl'
      value={search}
      onChange={handleSearch}
      rightSection={
        search.length > 0 && (
          <ActionIcon onClick={handleClearInput} mr={24}>
            <IconX />
          </ActionIcon>
        )
      }
    />
  )
}
