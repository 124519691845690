import { Skeleton, Flex, Space, Paper } from '../../../../components'
import { rem } from '@mantine/core'

export const OnLoadSkeleton = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Paper radius={isMobile ? 0 : 'md'} withBorder shadow='xs' p={isMobile ? 'md' : 'xl'} mx={isMobile ? rem(-20) : 0}>
      <Flex pt='md' direction='column'>
        <Skeleton h={30} w={200} />
        <Space h='md' />
        <Skeleton h={200} />
        <Space h='md' />
        <Skeleton h={30} w={200} />
        <Space h='md' />
        <Skeleton h={30} />
        <Space h='md' />
        <Skeleton h={30} w={200} />
        <Space h='md' />
        <Skeleton h={30} />
        <Space h='md' />
        <Skeleton h={30} w={200} />
        <Space h='md' />
        <Skeleton h={30} />
        <Space h='md' />
        <Skeleton h={30} w={200} />
        <Space h='md' />
        <Skeleton h={200} />
      </Flex>
    </Paper>
  )
}
