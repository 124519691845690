import { Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'
import styles from './styled'
import {
  IProjects,
  ISkillScore,
  IGroupSkillScores,
  ICurricularComponentScores,
} from './interface'

export const selectedProgressBar = (score: number) => {
  switch (score) {
    case 0:
      return styles.barProgressGreen0
    case 1:
      return styles.barProgressGreen1
    case 2:
      return styles.barProgressGreen2
    case 3:
      return styles.barProgressGreen3
    case 4:
      return styles.barProgressGreen4
    default:
      return styles.barProgressGreen5
  }
}

export const scoreSkillsBox = (score: number, margin: string) => {
  const stylesSkillsBox = {
    ...styles.skillsBox,
    marginLeft: margin,
  }
  const stylesSkillsBoxGray = {
    ...styles.skillsBoxGray,
    marginLeft: margin,
  }
  if (score <= 2) {
    return stylesSkillsBoxGray
  }
  return stylesSkillsBox
}


export const ProjectsContainer = ({ modality, name, assessedItems = [] }: IProjects) => {
  const { t } = useTranslation()
  
  return (
    <View style={styles.projectsBox}>
      <View>
        <Text style={styles.titleSM}>
          {t(`modality.${modality}`)} - {name}
        </Text>
      </View>
      <View style={styles.assessedItemsBox}>
        {assessedItems.map((item, index) => (
          <Text
            key={item.name}
            style={{
              backgroundColor: item.color || '#1ae56f',
              color: 'white',
              fontSize: '10px',
              padding: '3px 6px',
              marginLeft: index % 4 === 0 ? '0px' : '6px',
              borderRadius: '6px',
              width: 'auto',
              textAlign: 'center',
            }}
          >
            {item.name}
          </Text>
        ))}
      </View>
    </View>
  )
}

export const CurricularComponentScores = ({
  title,
  score,
  rubric,
}: ICurricularComponentScores) => (
  <View style={styles.curricularComponentItem}>
    <View style={styles.spaceBetween}>
      <Text style={styles.scoreTitle}>{title}</Text>
      <Text style={styles.scoreNumber}>{score}/5</Text>
    </View>
    <View style={styles.barProgressGray} />
    <View style={selectedProgressBar(score)} />
    <View style={styles.end}>
      <Text style={styles.rubric}>{rubric}</Text>
    </View>
  </View>
)

export const GroupSkillScores = ({
  title,
  score = 0,
  rubric,
  index,
}: IGroupSkillScores) => (
  <View
    wrap={false}
    style={
      index % 4 === 0
        ? scoreSkillsBox(score, '0px')
        : scoreSkillsBox(score, '6px')
    }
  >
    <Text style={styles.rubricTitle}>{title}</Text>
    <View style={styles.end}>
      <Text style={styles.rubricScore}>{score}/5</Text>
      <Text style={styles.rubric}>{rubric}</Text>
    </View>
  </View>
)

export const SkillReport = ({ title, score = 0, rubric, index }: ISkillScore) => (
  <View
    wrap={false}
    style={
      index % 4 === 0
        ? scoreSkillsBox(score, '0px')
        : scoreSkillsBox(score, '6px')
    }
  >
    <Text style={styles.rubricTitle}>
      {title}
    </Text>
    <View style={styles.end}>
      <Text style={styles.rubricScore}>{score}/5</Text>
      <Text style={styles.rubric}>{rubric}</Text>
    </View>
  </View>
)
