import { Layout } from 'components/Layout'
import { CurrentUserContextProvider } from 'context/useCurrentUser'
import { ProjectView } from './components/ProjectView'
import { ProjectViewProvider } from './context/ProjectViewContext'

export const NewProjectView = () => {
  return (
    <CurrentUserContextProvider>
      <Layout>
        <ProjectViewProvider>
          <ProjectView />
        </ProjectViewProvider>
      </Layout>
    </CurrentUserContextProvider>
  )
}
