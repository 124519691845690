import { useContext, useEffect, useState } from 'react'
import { LessonsContext } from './context/LessonsContext'
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Button, Timeline, RemoveConfirmModal, Title, EmptyStateBox, Box, Flex } from 'components'
import { IconCheck, IconCalendarPlus, IconAlertCircle, IconCalendarOff } from '@tabler/icons-react'
import { LessonModal } from '../LessonsList/components/LessonModal'
import { ActivityModal } from '../LessonsList/components/ActivityModal'
import { ActivityDetailsModal } from '../LessonsList/components/ActivityDetailsModal'
import { LessonListSkeleton } from '../LessonsList/components/LessonListSkeleton'
import { ILvl4Item } from 'interfaces'
import { LessonsListProps } from './interfaces'
import { LessonJournalButtons } from './components/LessonJournalButtons'
import { CardActivityReadOnly } from './components/CardActivityReadOnly'
import { CardActivityEditable } from './components/CardActivityEditable'
import { LessonHeader } from './components/LessonHeader'
import { changeActivityPosition, showSelectedItems } from 'pages/NewProject/service/projects'
import { deleteMeeting, deleteActivity } from 'pages/NewProject/service/projects'
import { EmptyLesson } from './components/EmptyLesson'
import { notifications } from 'context'
import { hasPermission } from 'hooks'
import LessonAlert from './LessonAlert'

export const LessonsList = ({
  projectID,
  lessonsList,
  editable,
  journal,
  loading,
  schoolTermStartAt,
  schoolTermEndAt,
  fetchAndSetActivities,
  kind,
  showLessonTitle = false,
  selectedSkills,
  selectedContents,
  isView,
  lessonsPagination,
  projectStatus,
}: LessonsListProps) => {
  const {
    lessons,
    editLesson,
    editActivity,
    openedRemoveConfirmationModal,
    removeType,
    lessonIdToRemove,
    setLessons,
    closeRemoveConfirmationModal,
    handleAddLesson,
    openedActivityModal,
    activityIdToRemove,
    getJournalURL,
    getReportURL,
  } = useContext(LessonsContext)

  const { t } = useTranslation()

  const [skills, setSkills] = useState<ILvl4Item[]>([])
  const [contents, setContents] = useState<ILvl4Item[]>([])
  const [activityWasSaved, setActivityWasSaved] = useState<boolean>(false)
  const [hasAlerts, setHasAlerts] = useState<boolean>(false)

  const successColor = 'green'
  const successIcon = <IconCheck size={24} />

  const calculateCurrentLesson = () => {
    const currentDate = new Date()
    const currentLesson = lessons.find(l => new Date(l.time) > currentDate)
    if (!currentLesson) {
      return lessons.length - 1
    }
    return lessons.indexOf(currentLesson) - 1
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result
    fetchAndSetActivities()

    if (!destination) return

    changeActivityPosition(result.draggableId, {
      sort_position: (destination?.index as number) + 1,
      ...(source.droppableId !== destination.droppableId ? { session_meeting_id: destination.droppableId } : null),
    })

    setLessons(
      lessons.map(lesson => {
        if (lesson.id !== source.droppableId && lesson.id !== destination.droppableId) return lesson

        const newActivities = [...lesson.sessionMeetingActivities]
        if (lesson.id === source.droppableId) {
          const [removed] = newActivities.splice(source.index, 1)
          if (source.droppableId !== destination.droppableId)
            return { ...lesson, sessionMeetingActivities: newActivities }
          newActivities.splice(destination.index, 0, removed)
        }
        if (lesson.id === destination.droppableId && source.droppableId !== destination.droppableId) {
          const sourceLesson = lessons.find(l => l.id === source.droppableId)
          if (!sourceLesson) return lesson
          const [removed] = sourceLesson.sessionMeetingActivities?.splice(source.index, 1) || []
          newActivities.splice(destination.index, 0, removed)
        }
        return { ...lesson, sessionMeetingActivities: newActivities }
      }),
    )
  }

  const handleDeleteActivity = async () => {
    try {
      const result = await deleteActivity(activityIdToRemove)

      if (result === null) {
        fetchAndSetActivities()
        const successMessage = t('new_project_page.activity_removed_message')

        notifications.show({
          message: successMessage,
          color: successColor,
          icon: successIcon,
        })
      }
    } catch (error) {
      console.error('Erro ao deletar atividade:', error)
    }
  }

  const handleDeleteMeeting = async () => {
    deleteMeeting(lessonIdToRemove)
      .then(result => {
        if (result.title) {
          setTimeout(() => {
            handleSuccessfulDeletion()
          }, 300)
        }
      })
      .catch(error => {
        console.error('Erro ao deletar reunião:', error)
      })
  }

  const handleSuccessfulDeletion = () => {
    const successMessage = t('new_project_page.lesson_removed_message')

    notifications.show({
      message: successMessage,
      color: successColor,
      icon: successIcon,
    })

    fetchAndSetActivities()
  }

  const handleRemoveConfirmationClose = () => {
    if (removeType === 'activity') {
      handleDeleteActivity()
      return
    }

    handleDeleteMeeting()
  }

  const onclose = () => {
    closeRemoveConfirmationModal()
  }

  const onSaveActivity = () => {
    setActivityWasSaved(true)
  }

  useEffect(() => {
    setLessons(lessonsList)
  }, [lessonsList])

  useEffect(() => {
    if ((!selectedContents && !selectedSkills) || (selectedContents?.length === 0 && selectedSkills?.length === 0)) {
      if (projectID) {
        showSelectedItems(projectID).then(res => {
          setSkills(res.specificSkills)
          setContents(res.contents)
        })
      } else {
        console.error('ID is missing')
      }
    } else {
      if (selectedContents) setContents(selectedContents)
      if (selectedSkills) setSkills(selectedSkills)
    }
  }, [projectID])

  useEffect(() => {
    if (!openedActivityModal && activityWasSaved) {
      fetchAndSetActivities()
      setActivityWasSaved(false)
    }
  }, [openedActivityModal])

  useEffect(() => {
    const alerts = lessons.some(lesson => {
      return (
        lesson.sessionMeetingStatus.status !== 'booking' &&
        lesson.sessionMeetingStatus.status !== 'no_activity' &&
        journal
      )
    })

    setHasAlerts(alerts)
  }, [lessons])

  if (loading) {
    return <LessonListSkeleton />
  }

  return (
    <>
      <RemoveConfirmModal
        type={removeType}
        opened={openedRemoveConfirmationModal}
        onClose={onclose}
        actionCancelConfirm={handleRemoveConfirmationClose}
      />

      <LessonModal
        projectID={projectID}
        schoolTermStartAt={schoolTermStartAt || null}
        schoolTermEndAt={schoolTermEndAt || null}
        isApplication={kind === 'application'}
        lesson={editLesson}
        fetchAndSetActivities={fetchAndSetActivities}
      />

      <ActivityModal
        projectID={projectID}
        activity={editActivity}
        skills={skills}
        contents={contents}
        onSaveActivity={onSaveActivity}
      />

      <ActivityDetailsModal />

      {showLessonTitle && (
        <Flex justify='space-between'>
          <Title order={2}>
            {t('activerecord_models_meeting_other')} ({lessonsPagination?.count || 0})
          </Title>
          {isView && editable && lessons.length > 0 && lessons[0].sessionMeetingActions.new && (
            <Button leftIcon={<IconCalendarPlus />} size='lg' variant='light' onClick={handleAddLesson}>
              {t('new_project_page.add_lesson')}
            </Button>
          )}
        </Flex>
      )}
      {editable && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Timeline
            mt={schoolTermEndAt !== null ? 'md' : 0}
            active={calculateCurrentLesson()}
            bulletSize={24}
            lineWidth={2}
            sx={{ paddingLeft: schoolTermEndAt !== null ? '' : '0 !important' }}
          >
            {lessons.length > 0 &&
              lessons.map((lesson, indexLesson) => (
                <Timeline.Item key={lesson.id} lineVariant={indexLesson === lessons.length - 1 ? 'dashed' : 'solid'}>
                  <LessonHeader lesson={lesson} editable={true} kind={kind} />
                  <Droppable droppableId={lesson.id} isDropDisabled={!lesson.sessionMeetingActions.edit}>
                    {provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {lesson.sessionMeetingActivities?.map((activity, index) => (
                          <Draggable key={activity.id} draggableId={activity.id} index={index}>
                            {provided => <CardActivityEditable provided={provided} activity={activity} />}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {lesson.sessionMeetingActivities?.length === 0 && (
                    <EmptyStateBox
                      withBorder
                      text={
                        kind !== 'application'
                          ? t('new_project_page.no_activity_template')
                          : t('new_project_page.no_activity')
                      }
                      icon={<IconAlertCircle />}
                    />
                  )}

                  {lesson.sessionMeetingStatus.status !== 'booking' &&
                    lesson.sessionMeetingStatus.status !== 'no_activity' &&
                    journal &&
                    !hasPermission(['guardian']) && (
                    <LessonAlert lesson={lesson} hasPermission={!hasPermission(['guardian'])} t={t} />
                  )}
                  {journal && lesson.id && lesson.sessionMeetingActivities?.length > 0 ? (
                    <LessonJournalButtons
                      projectStatus={projectStatus}
                      lessonID={lesson.id}
                      getJournalURL={getJournalURL}
                      getReportURL={getReportURL}
                      canFillEditReport={lesson.canFillEditReport}
                    />
                  ) : null}
                </Timeline.Item>
              ))}

            {lessons?.length === 0 && !loading && (
              <EmptyLesson
                isTemplate={kind !== 'application'}
                editable={editable && schoolTermEndAt !== null}
                handleAddLesson={handleAddLesson}
                projectStatus={projectStatus}
              />
            )}

            {editable && lessons.length > 0 && lessons[0].sessionMeetingActions.new && (
              <Timeline.Item
                title={
                  <Button leftIcon={<IconCalendarPlus />} size='lg' variant='light' onClick={handleAddLesson}>
                    {t('new_project_page.add_lesson')}
                  </Button>
                }
              ></Timeline.Item>
            )}
          </Timeline>
        </DragDropContext>
      )}

      {!editable && lessons?.length > 0 && (
        <Timeline
          mt={schoolTermEndAt !== null ? 'md' : 0}
          active={calculateCurrentLesson()}
          bulletSize={24}
          lineWidth={2}
          sx={{ paddingLeft: schoolTermEndAt !== null ? '' : '0 !important' }}
        >
          {lessons.map((lesson, indexLesson) => (
            <Timeline.Item key={lesson.id} lineVariant={indexLesson === lessons.length - 1 ? 'dashed' : 'solid'}>
              <LessonHeader lesson={lesson} editable={false} kind={kind} />
              {lesson.sessionMeetingActivities?.map(activity => (
                <CardActivityReadOnly key={activity.id} activity={activity} />
              ))}
              {!hasPermission(['guardian']) && lesson.id && lesson.sessionMeetingActivities?.length > 0 ? (
                <LessonJournalButtons
                  projectStatus={projectStatus}
                  lessonID={lesson.id}
                  getJournalURL={getJournalURL}
                  getReportURL={getReportURL}
                  canFillEditReport={lesson.canFillEditReport}
                />
              ) : null}
            </Timeline.Item>
          ))}
        </Timeline>
      )}
      {!editable && lessons.length === 0 && (
        <Box mb='md'>
          <EmptyStateBox withBorder icon={<IconCalendarOff />} text={t('new_project_page.no_lesson')} />
        </Box>
      )}
    </>
  )
}
