import { useContext } from 'react'
import { Button, Skeleton } from 'components'
import { useTranslation } from 'react-i18next'
import { useMixPanel }  from 'hooks'
import { FeedDrawerContext } from '../../../../contexts/FeedDrawerContext'
import { FeedListContext } from 'pages/Feed/contexts/FeedListContext'

export const NewObservationButton = () => {
  const { t } = useTranslation()
  const mixpanel = useMixPanel()

  const { openDrawer, setHeaderText, setUserIsCreating } = useContext(FeedDrawerContext)
  const { loading } = useContext(FeedListContext)

  return (
    <>
      {loading ? (
        <Skeleton height={50} width={140} />
      ) : (
        <Button
          size='lg'
          variant='filled'
          onClick={() => {
            openDrawer()
            setHeaderText(t('feed_new_post_title'))
            setUserIsCreating(true)
            mixpanel.track('Criar novo post')
          }}
        >
          {t('feed_new_post_button')}
        </Button>
      )}
    </>
  )
}

