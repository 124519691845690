import { useContext } from 'react'
import { useCurrentUser } from 'context'
import { TeacherDashboardContext } from '../../context'
import { Grid, GroupCard, Stack, Heading, EmptyStateBox } from 'components'
import { GroupsListSkeleton } from '../GroupsListSkeleton'
import { GroupActions } from '../GroupActions'
import { useTranslation } from 'react-i18next'
import { IconArticleOff, IconUsers } from '@tabler/icons-react'

export const GroupsList = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const { groups } = useContext(TeacherDashboardContext)

  return (
    <Stack spacing='xs'>
      <Heading size='md' text={t('new_teacher_dashboard.groups_title')} icon={<IconUsers size={32} />} />
      {groups && groups.length > 0 && (
        <Grid>
          {groups.map(group => (
            <Grid.Col span={12} md={4} key={group.id}>
              <GroupCard
                group={group}
                membersLabel={t('new_teacher_dashboard.group_card_students')}
                actions={
                  <GroupActions
                    groupURL={`/group_school_terms/${group.id}?origin=dashboard`}
                    viewDiscURL={`/group_school_terms/${group.id}/group_school_term_mosaics?origin=dashboard`}
                    addStudentURL={`/manually_register/${group.id}`}
                  />
                }
                showLink={true}
                byCycle={currentUser?.by_cycle || false}
              />
            </Grid.Col>
          ))}
        </Grid>
      )}
      {groups && groups.length === 0 && (
        <EmptyStateBox withBorder text={t('new_teacher_dashboard.no_groups')} icon={<IconArticleOff />} />
      )}
      {groups === null && <GroupsListSkeleton />}
    </Stack>
  )
}
