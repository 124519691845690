import ErrorService from '@legacy/common/Tools/ErrorService'
import { useCallback, useState } from 'react'
import { IStorageHookProps, IStorageHookResult } from '../types/storage'

export default function useLocalStorage ({
  key,
  initial,
}: IStorageHookProps): IStorageHookResult {
  const [stored, changeStored] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)

      return item ? JSON.parse(item) : initial
    } catch (error) {
      ErrorService.notice(error)

      return initial
    }
  })

  const change = useCallback((value: (arg0: any) => any) => {
    try {
      const valueToStore = value instanceof Function ? value(stored) : value
      changeStored(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      ErrorService.notice(error)
    }
  }, [])

  return {
    stored,
    change,
  }
}
