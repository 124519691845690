import { ILesson } from 'interfaces'

export interface ILessonModal {
  projectID: string
  lesson: ILesson | null
  schoolTermEndAt: string | null
  schoolTermStartAt: string | null
  fetchAndSetActivities: () => void
  isApplication?: boolean
}
export interface ILessonFormValues {
  title: string
  lessonDate: string | Date
  startTime: string
  endTime: string
  repeatLessons: string
  repeatDays: string[]
  repeatEnd: string
  repeatEndDate: string
  generateTasks: boolean
}

export const initialValues = {
  title: '',
  lessonDate: '',
  startTime: '',
  endTime: '',
  repeatLessons: '0',
  repeatDays: [],
  repeatEnd: '1',
  repeatEndDate: '',
  generateTasks: true,
}

export interface ICalculateDays {
  startDate: string
  repeatEndDate: string
  daysOfWeek: number[]
}
