import { Group, Text } from 'components'
import { useTranslation } from 'react-i18next'
import { IconCheck } from '@tabler/icons-react'

export const PaginationWarning = () => {
  const { t } = useTranslation()

  return (
    <Group align='center' position='center' mt='md' spacing='sm'>
      <IconCheck size='1.4rem' />
      <Text size='md'>{t('feed_warning')}</Text>
    </Group>
  )
}