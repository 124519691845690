/* eslint-disable max-len */
import React from 'react'

function Gift() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none' viewBox='0 0 32 32'>
      <circle cx='16' cy='16' r='16' fill='#fff'></circle>
      <path
        fill='#000'
        fillRule='evenodd'
        d='M11.724 5.013c-.047.011-.191.042-.32.068-.925.188-1.845.964-2.235 1.886a3.23 3.23 0 00.243 2.954l.145.227H8.186c-.754 0-1.475.02-1.602.044a1.957 1.957 0 00-1.541 1.541c-.06.312-.056 1.75.004 2.034.114.536.495 1.046.961 1.287l.28.145v4.991c0 3.214.016 5.074.044 5.223.146.772.77 1.395 1.541 1.541.32.061 15.934.061 16.254 0a1.957 1.957 0 001.541-1.541c.029-.15.044-2.009.044-5.223v-4.991l.28-.145c.466-.241.847-.752.961-1.287.06-.284.064-1.722.004-2.034a1.957 1.957 0 00-1.541-1.541c-.127-.024-.848-.044-1.602-.044h-1.37l.144-.227a3.23 3.23 0 00.243-2.954c-.389-.918-1.245-1.645-2.233-1.896-.346-.088-1.238-.077-1.59.02a2.69 2.69 0 00-1.27.736c-.558.555-.906 1.165-1.465 2.568-.138.349-.261.634-.273.634-.012 0-.135-.285-.274-.634-.558-1.403-.906-2.013-1.464-2.568a2.674 2.674 0 00-1.249-.73c-.25-.068-1.114-.124-1.289-.084zm1.13 1.387c.65.25 1.001.8 1.86 2.92l.335.828h-1.517c-.852 0-1.618-.02-1.748-.044a1.948 1.948 0 01-1.551-1.608c-.162-.974.53-1.965 1.517-2.173.291-.062.844-.023 1.104.077zm7.362-.077c1.01.191 1.716 1.181 1.551 2.173a1.948 1.948 0 01-1.55 1.608c-.13.025-.897.044-1.749.044h-1.517l.335-.828c.704-1.74 1.04-2.358 1.46-2.69.385-.304.909-.414 1.47-.307zm-6.794 6.404v1.29H10.11c-3.233 0-3.315-.002-3.48-.087-.302-.154-.343-.299-.343-1.203 0-.896.042-1.047.333-1.201.156-.082.26-.085 3.48-.087l3.32-.002v1.29zm3.867 5.846v7.136H14.71V11.437h2.58v7.136zm8.08-7.05c.302.155.343.3.343 1.204 0 .904-.041 1.05-.343 1.203-.165.085-.247.087-3.48.087h-3.31v-2.58h3.31c3.233 0 3.315.002 3.48.087zm-11.947 8.985v5.2h-2.667c-2.587 0-2.67-.002-2.835-.086a.592.592 0 01-.256-.256c-.086-.167-.087-.243-.087-5.115v-4.945H13.422v5.202zm11-.257c0 4.872 0 4.948-.086 5.115a.593.593 0 01-.256.256c-.164.084-.248.087-2.835.087h-2.667V15.306H24.423v4.945z'
        clipRule='evenodd'
      ></path>
    </svg>
  )
}

export default Gift
