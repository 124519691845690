import PropTypes from 'prop-types'
import { ProfilePic } from '@legacy/common/_components/ProfilePic'

const ResponsibleAvatar = props => {
  const {
    name, subtitle, img, onClick, hoverClass, isOnline, isCertificated, isMain, link
  } = props
  return (
    <a href={link}>
      <div className={`responsible-avatar ${hoverClass}`} role="button" tabIndex={0}>
        <div className="img-content">
          <ProfilePic img={img} fullName={name} className={`img-profile-medium${isMain ? ' main-responsible' : ''}`} />

          {isOnline && (
            <i className={`icon-cam-sm-record cam-icon ${isCertificated ? 'enabled' : 'disabled'}`} />
          )}
        </div>
        <div className="texts">
          <div className="name text-center">{name}</div>
          <small className="subtitle">{subtitle}</small>
        </div>
      </div>
    </a>
  )
}

ResponsibleAvatar.propTypes = {
  name: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  img: PropTypes.string,
  hoverClass: PropTypes.string,
  onClick: PropTypes.func,
  isOnline: PropTypes.bool,
  isCertificated: PropTypes.bool,
  isMain: PropTypes.bool,
}

ResponsibleAvatar.defaultProps = {
  img: undefined,
  onClick: null,
  hoverClass: '',
  isOnline: false,
  isCertificated: false,
  isMain: false,
}

export default ResponsibleAvatar
