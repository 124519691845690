import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

const dateFormats: { [key: string]: string } = {
  'pt-BR': 'DD/MM/YYYY',
  'en-US': 'MM/DD/YYYY',
}

const timeFormats: { [key: string]: string } = {
  'pt-BR': 'HH:mm',
  'en-US': 'hh:mm A',
}

export const useLocalizedDate = (language: string) => {
  const formatLocalizedDate = (date: string | Date): string => {
    if (date === null || date === undefined) return ''
    const dateString = typeof date === 'string' ? date?.split('T')[0] : date
    const currentFormat = dateFormats[language] || 'MM/DD/YYYY'
    return dayjs(dateString).format(currentFormat)
  }

  return formatLocalizedDate
}

export const useLocalizedTime = (language?: string) => {
  const formatLocalizedTime = (date: string | Date): string => {
    if (date === null || date === undefined) return ''
    const timeString = typeof date === 'string' ? date?.split('T')[1].split('.')[0] : date?.toTimeString().split(' ')[0]
    const currentFormat = timeFormats[language || 'pt-BR'] || 'hh:mm A'
    return dayjs(`1970-01-01T${timeString}`).format(currentFormat)
  }

  return formatLocalizedTime
}

export const addMinutesToDateStr = (dateString: string, minutes: number, language: string): string => {
  if (dateString === null || dateString === undefined) return ''
  if (minutes === null || minutes === undefined) return ''
  const timeParts = dateString?.split('T')[1].split(':')
  let hours = parseInt(timeParts[0], 10)
  let mins = parseInt(timeParts[1], 10)

  mins += minutes
  hours += Math.floor(mins / 60)
  mins %= 60
  hours %= 24

  const time = `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`
  const currentFormat = timeFormats[language] || 'hh:mm A'
  return dayjs(`1970-01-01T${time}`).format(currentFormat)
}
