import { createStyles } from 'hooks'

export const useStyles = createStyles(
  (theme, { isSkillsStandardsStep, isMobile }: { isSkillsStandardsStep: boolean | undefined; isMobile: boolean }) => ({
    buttonsBox: {
      marginLeft: isSkillsStandardsStep
        ? isMobile
          ? `calc(-1 * ${theme.spacing.md})`
          : `calc(-1 * ${theme.spacing.xl})`
        : '0',
      marginRight: isSkillsStandardsStep
        ? isMobile
          ? `calc(-1 * ${theme.spacing.md})`
          : `calc(-1 * ${theme.spacing.xl})`
        : '0',
      marginTop: theme.spacing.md,
      paddingLeft: isSkillsStandardsStep ? (isMobile ? 0 : `${theme.spacing.xl}`) : '0',
      paddingRight: isSkillsStandardsStep ? (isMobile ? 0 : `${theme.spacing.xl}`) : '0',
      paddingBottom: isSkillsStandardsStep ? `${theme.spacing.xs}` : '0',
      zIndex: isSkillsStandardsStep ? 100 : 'inherit',
      width: isSkillsStandardsStep
        ? isMobile
          ? `calc(100% + 2 * ${theme.spacing.md})`
          : `calc(100% + 2 * ${theme.spacing.xl})`
        : 'auto',
      position: isSkillsStandardsStep ? 'sticky' : 'inherit',
      backgroundColor: isSkillsStandardsStep ? 'white' : 'inherit',
      bottom: isSkillsStandardsStep ? 0 : 'inherit',
    },
  }),
)
