import { InitialState } from '../types/student-types'

export const reducer = (state: InitialState, action: any) => {
  switch (action.type) {
    case 'CHANGE_TAB':
      return { ...state, activeTab: action.payload, loading: true }
    case 'CHANGE_PAGE':
      return { ...state, activePage: action.payload, loading: true }
    case 'SEARCH':
      return {
        ...state,
        activePage: 1,
        search: action.payload,
        loading: true,
      }
    case 'LOAD_STUDENTS':
      return {
        ...state,
        students: action.payload.data,
        pagination: action.payload.pagination,
        meta: action.payload.meta,
        actions: action.payload.actions,
        loading: false,
      }
    default:
      return state
  }
}
