import { useTranslation } from 'react-i18next'
import { IScoreComponent } from './scoreComponentTypes'
import { ScoreItem, ScoreItemLabel } from './ScoreItem'
import { ScoreCard } from './ScoreCard'
import { ScoreInfo } from './ScoreInfo'
import { ScoreCardList } from './ScoreCardList'
import { ScoreList } from './ScoreList'

const ScoreComponent = ({ kind = 'scoreCardList', callback, active, disabled, values, variant }: IScoreComponent) => {
  const { t } = useTranslation()

  const RUBRIC_KEYS = ['rubric_1', 'rubric_2', 'rubric_3', 'rubric_4', 'rubric_5']
  const DefaultModalRubrics = RUBRIC_KEYS.map(key => t(key))
  
  if (kind === 'scoreItem') {
    return <ScoreItem variant={variant} disabled={disabled} label={values[0]} active={active === values[0]} />
  }

  if (kind === 'scoreInfo') {
    return <ScoreInfo variant={variant} label={values[0]} callback={callback} rubric='' score={0} />
  }

  if (kind === 'scoreCardList') {
    return <ScoreCardList variant={variant} values={values} callback={callback} active={active} disabled={disabled} />
  }

  if (kind === 'scoreList') {
    return <ScoreList variant={variant} values={values} callback={callback} active={active} disabled={disabled} />
  }

  if (kind === 'scoreCard') {
    const handleClick = (value: ScoreItemLabel) => {
      callback(value)
    }
    return (
      <ScoreCard
        variant={variant}
        label={values[0]}
        onClick={() => handleClick(values[0])}
        active={active === values[0]}
        rubric={DefaultModalRubrics[values[0]]}
        disabled={disabled}
      />
    )
  }
  return <></>
}

export default ScoreComponent
