interface ICheck {
  fill?: string
}

function Check({ fill = '#121019' }: ICheck): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm5.437-11.933a1.389 
        1.389 0 00-1.985-1.944l-5.129 5.236-2.248-2.248a1.389 1.389 0 10-1.964 1.964l3.24 3.24a1.389 
        1.389 0 001.974-.01l6.112-6.238z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Check
