import styled from 'styled-components'
import { DropdownMenuItem } from 'components/DropdownMenu'

export const DropdownMenuItemStyled = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  padding: 4px 0px 10px 20px;
  color: var(--color-black);
  cursor: pointer;
  &:focus-visible {
    outline: 0px;
    border: none;
  }

  i {
    font-size: 1.3em;
  }
`

export const ContainerWithIconStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 15.4px;
`

export const LinkStyled = styled.a`
  font-size: 0.875rem;

  padding-left: 4px;
  color: var(--color-black);

  &:hover {
    color: var(--color-black);
    text-decoration: none;
  }
`
