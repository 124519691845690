import { Flex, Title } from '@mantine/core'
import FileUpload from '@legacy/common/_components/FileUpload'
import GDriveWrapper from '@legacy/common/_components/GDriveWrapper'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: block;

  .files-list {
    margin-top: 1rem;
  }
`

const WrappFiles = ({ projectId, activityId }: { activityId: string; projectId: string }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Flex direction='column'>
        <Title order={4} mb='md'>
          {t('students_edit_files')}
        </Title>

        <FileUpload
          uploadUrl={`/projects/${projectId}/session_meeting_activities/${activityId}/planning_files`}
          fetchFilesUrl={`/projects/${projectId}/session_meeting_activities/${activityId}/planning_files.json`}
          deleteFilesUrl={`/projects/${projectId}/session_meeting_activities/${activityId}/planning_files`}
          typeFile='planning_files'
        />

        {activityId.length > 0 && (
          <GDriveWrapper
            value={{
              resourceId: activityId,
              resourceClass: 'session_meeting_activity_id',
            }}
          />
        )}
      </Flex>
    </Wrapper>
  )
}

export default WrappFiles
