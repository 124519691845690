import { Group } from '../Group'
import { Button } from '../Button'
import { Tooltip } from '@mantine/core'
import { forwardRef } from 'react'
import { Ref } from 'react'

interface DisabledTooltipButtonProps {
  readonly fullWidth?: boolean
  readonly label: string
  readonly tooltipLabel: string
}

type ButtonTooltipProps = {
  label: string
  fullWidth: boolean
}

const ButtonTooltip = forwardRef<HTMLDivElement, ButtonTooltipProps>((props, ref: Ref<HTMLDivElement>) => (
  <div ref={ref} style={{ width: props.fullWidth ? '100%' : 'fit-content' }}>
    <Button
      fullWidth={props.fullWidth}
      data-disabled
      sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
      data-testid='students_fill_report-disabled'
      size='lg'
    >
      {props.label}
    </Button>
  </div>
))

ButtonTooltip.displayName = 'btn-tooltip'

export const DisabledTooltipButton = ({
  fullWidth = false,
  label = '',
  tooltipLabel = '',
}: DisabledTooltipButtonProps) => {
  return (
    <Group position='center'>
      <Tooltip
        label={tooltipLabel}
        px={16}
        py={8}
        withArrow
        multiline
        arrowSize={10}
        sx={theme => ({
          textAlign: 'center',
          fontSize: theme.fontSizes.sm,
          maxWidth: 300,
        })}
      >
        <ButtonTooltip label={label} fullWidth={fullWidth} />
      </Tooltip>
    </Group>
  )
}
