import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context/useCurrentUser'
import { paths } from '../paths'
import {
  DropdownMenuSeparatorStyled,
  DropdownMenuItemStyled,
  DropdownMenuLabelStyled,
  LinkStyled,
  ContainerWithoutIconStyled,
} from '../NavbarMenuStyled'

export const AdminMenu = () => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()

  if (!currentUser?.admin_account) {
    return <></>
  }

  return (
    <>
      <DropdownMenuLabelStyled>
        Admin area
      </DropdownMenuLabelStyled>
      <DropdownMenuItemStyled>
        <ContainerWithoutIconStyled>
          <LinkStyled href={paths.account.admin.curriculums}>{t('curriculum')}</LinkStyled>
        </ContainerWithoutIconStyled>
      </DropdownMenuItemStyled>
      <DropdownMenuItemStyled>
        <ContainerWithoutIconStyled>
          <LinkStyled href={paths.account.admin.school}>{t('school')}</LinkStyled>
        </ContainerWithoutIconStyled>
      </DropdownMenuItemStyled>
      <DropdownMenuItemStyled>
        <ContainerWithoutIconStyled>
          <LinkStyled href={paths.account.admin.transfer_students}>{t('transfer_students')}</LinkStyled>
        </ContainerWithoutIconStyled>
      </DropdownMenuItemStyled>
      <DropdownMenuSeparatorStyled />
    </>
  )
}
