import { createStyles } from 'hooks'

export const useStyles = createStyles(
  theme => ({
    mainTeacherAvatar: {
      border:
        theme.colorScheme === 'dark'
          ? `2px solid ${theme.primaryColor}`
          : `2px solid ${theme.primaryColor}`,
    },
    defaultAvatar: {
      border:
        theme.colorScheme === 'dark'
          ? `2px solid ${theme.colors.dark[7]}`
          : '2px solid white',  
    },
  }),
)
