import { useTranslation } from 'react-i18next'
import { useMantineTheme, useMediaQuery } from 'hooks'
import { Group, Button, Text, Divider, Grid, Title, Badge, CopyButton, Stack, NewProjectActionMenu } from 'components'
import { IconClipboard, IconClipboardCheck } from '@tabler/icons-react'
import { MainTeacherBlock } from 'components'
import { ProjectHeaderProps } from './intefaces'
import dayjs from 'dayjs'

export const ProjectHeader = ({
  project,
  teachers,
  handleEdit,
  handleRemove,
  handlePublish,
  handleApprove,
  handleReopen,
  handleFinish,
}: ProjectHeaderProps) => {
  const { t, i18n } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const meetingsCount = project && project.totalMeetings ? project.totalMeetings : 0
  const meetingText =
    meetingsCount && meetingsCount === 1 ? t('new_project_view.lesson') : t('new_project_view.lessons')

  const updatedAt =
    project && project.updatedAt
      ? i18n.language === 'pt-BR'
        ? dayjs(project.updatedAt).format('DD/MM/YYYY HH:mm')
        : dayjs(project.updatedAt).format('MM/DD/YYYY HH:mm')
      : ''

  return (
    <>
      {project !== null && project.id !== '' && (
        <>
          <Grid>
            <Grid.Col span={12} xs={12} sm={7}>
              <Stack spacing='sm'>
                <Stack spacing={0} align='flex-start'>
                  <Title order={1}>{project.name}</Title>
                  <Group spacing='xs'>
                    {project.kind === 'template' && (
                      <Badge color='orange' variant='filled'>
                        {t('new_project_view.badge_template')}
                      </Badge>
                    )}
                    {project.status === 'draft' && (
                      <Badge color='gray' variant='filled'>
                        {t('new_project_view.badge_draft')}
                      </Badge>
                    )}
                    {project.status === 'approved' && (
                      <Badge color='blue' variant='filled'>
                        {t('new_project_view.badge_on_going')}
                      </Badge>
                    )}
                    {project.status === 'finished' && (
                      <Badge color='green' variant='filled'>
                        {t('new_project_view.badge_finished')}
                      </Badge>
                    )}
                  </Group>
                </Stack>
                <Text size='sm' color='dimmed'>
                  {t('new_project_view.label_last_update')} {updatedAt}
                </Text>
              </Stack>
            </Grid.Col>

            <Grid.Col span={12} xs={12} sm={5}>
              <Group spacing='sm' position='right'>
                <CopyButton value={window.location.href}>
                  {({ copied, copy }) => (
                    <Button
                      size='md'
                      variant='outline'
                      color={copied ? 'green' : ''}
                      onClick={copy}
                      fullWidth={isMobile}
                      leftIcon={copied ? <IconClipboardCheck /> : <IconClipboard />}
                    >
                      {copied ? t('general_buttons_copied_url') : t('general_buttons_copy_url')}
                    </Button>
                  )}
                </CopyButton>
                <NewProjectActionMenu
                  project={project}
                  handleEdit={handleEdit}
                  handleRemove={handleRemove}
                  handlePublish={handlePublish}
                  handleApprove={handleApprove}
                  handleReopen={handleReopen}
                  handleFinish={handleFinish}
                />
              </Group>
            </Grid.Col>
          </Grid>
          <Divider mt='md' mb='md' />
          <Grid columns={12}>
            <Grid.Col span={12} sm={6} md={2}>
              <Text size='sm' color='dimmed'>
                {t('new_project_view.label_project_type')}
              </Text>
              <Text>{t(`modality.${project.modality}`)}</Text>
            </Grid.Col>
            <Grid.Col span={12} sm={6} md={project.kind !== 'template' ? 2 : 'auto'}>
              <Text size='sm' color='dimmed'>
                {t('new_project_view.label_project_lessons')}
              </Text>
              <Text>
                {meetingsCount} {meetingText}
              </Text>
            </Grid.Col>
            {project.kind !== 'template' && (
              <Grid.Col span={12} sm={6} md='auto'>
                <Text size='sm' color='dimmed'>
                  {t('new_project_view.label_group')}
                </Text>
                <Text>{project.groupName !== null ? project.groupName : t('new_project_view.no_group_added')}</Text>
              </Grid.Col>
            )}
            <Grid.Col span={12} sm={6} md='content'>
              {project.tutorMain && <MainTeacherBlock teachers={teachers} mainTeacher={project.tutorMain} />}
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  )
}
