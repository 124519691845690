import { FeedStatus, IObservationPayload } from './serviceFeed'

export const paginationStructure = {
  scaffoldUrl: '',
  firstUrl: '',
  prevUrl: '',
  pageUrl: '',
  nextUrl: '',
  lastUrl: '',
  count: 0,
  page: 0,
  items: 0,
  vars: undefined,
  pages: 0,
  last: 0,
  in: 0,
  from: 0,
  to: 0,
  prev: undefined,
  next: 0,
  series: [],
}

export const initialGoogleDriveFiles = [
  {
    name: '',
    description: '',
    url: '',
    mime_type: 'application/vnd.google-apps.spreadsheet',
    created_at: new Date(),
    id: '',
    owner_id: '',
    owner_type: '',
    updated_at: new Date(),
    icon_url: '',
  },
]

export const initialPagination = {
  private: paginationStructure,
  public: paginationStructure,
}

export const initialPayload: IObservationPayload = {
  description: '',
  studentIds: [],
  googleDriveFiles: initialGoogleDriveFiles,
  status: FeedStatus.Draft,
  projectId: '',
}
