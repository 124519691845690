import { useContext } from 'react'
import { Grid, Accordion, Text } from 'components'
import { useMantineTheme, useMediaQuery } from 'hooks'

import { useTranslation } from 'react-i18next'
import { CardSkillsStandardsLvl2 } from '../CardSkillsStandardsLvl2'
import { ILvl1Item, ILvl2Item } from '../ProjectSkillsStandards/interfaces'
import { SkillsStandardsContext } from '../../context/SkillsStandardsContext'
import { ListSkillsStandardsPage1Skeleton } from '../ListSkillsStandardsPage1Skeleton'

export function ListSkillsStandardsPage1() {
  const { t } = useTranslation()
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)


  const { skillsStandards, currentTab, page1Loaded, getLvl1SelectedQtd } = useContext(SkillsStandardsContext)

  if (Object.keys(skillsStandards).length === 0) return (
    <ListSkillsStandardsPage1Skeleton length={5} />
  )
  else {
    const page1Data =
      currentTab === 'skill' ? skillsStandards.curriculumItems.axes : skillsStandards.curriculumItems.subjects

    const noItems = page1Loaded === true && page1Data && page1Data.length === 0
    const hasItems = page1Loaded === true && page1Data && page1Data.length > 0

    return (
      <>
        {!noItems && !hasItems && <ListSkillsStandardsPage1Skeleton length={5} />}
        {noItems && !hasItems && <Text>{t('new_project_page.no_items_found')}</Text>}
        {hasItems && (
          <Accordion variant='contained'>
            {page1Data.map((item: ILvl1Item) => {
              const selectedQtd = getLvl1SelectedQtd(item.id, currentTab)
              return (
                <Accordion.Item key={item.id} value={item.id}>
                  <Accordion.Control>
                    <Text span={!isMobile} fw='bold'>
                      {item.name}{' '}
                    </Text>
                    {selectedQtd ? (
                      <Text span={!isMobile} color='dimmed' size='sm'>
                        {!isMobile ? ' - ' : null}
                        {selectedQtd}{' '}
                        {currentTab === 'skill'
                          ? t('new_project_page.skills_selected')
                          : t('new_project_page.standards_selected')}
                      </Text>
                    ) : null}
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Grid>
                      {item.children && item.children.length > 0 ? (
                        item.children.map((item_lvl2: ILvl2Item) => (
                          <Grid.Col key={item_lvl2.id} xs={12} sm={6} md={4} lg={3}>
                            <CardSkillsStandardsLvl2 item={item_lvl2} color={item.color} type={currentTab} />
                          </Grid.Col>
                        ))
                      ) : (
                        <Text>{t('new_project_page.no_items_found')}</Text>
                      )}
                    </Grid>
                  </Accordion.Panel>
                </Accordion.Item>
              )
            })}
          </Accordion>
        )}
      </>
    )
  } 
}
