import { useEffect, useState } from 'react'
import ErrorService from '@legacy/common/Tools/ErrorService'

interface LoadingApiDataHookResult {
  readonly loading: true
}

interface LoadedApiDataHookResult<T> {
  readonly loading: false
  readonly data: T
}

export type ApiDataHookResult<T> = LoadingApiDataHookResult | LoadedApiDataHookResult<T>

function useApiData<T> (getService: Promise<T>): ApiDataHookResult<T> {
  const [data, changeData] = useState<T | null>(null)

  async function action () {
    const response = await getService
    changeData(response)
  }

  useEffect(() => {
    if (data === null) {
      action().catch(err => ErrorService.notice(err))
    }

    return () => {}
  }, [])

  if (data === null) {
    return { loading: true }
  }

  return { loading: false, data }
}

export default useApiData
