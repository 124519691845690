import { useCallback, useMemo, useState } from 'react'
import useApiData from "../../../hooks/api-data-hook";
import {
  getGradesByCycle,
  getGroups,
} from "../../../services/students-service";
import useMixPanel from "../../../hooks/mix-panel-hook";
import { useMetabase } from "../../../hooks/metabase-hook";
import { lumiarToastError } from "../../../_components/LumiarToast";
import ErrorService from "../../../Tools/ErrorService";
import { getPayloadParams } from './reports-group-utilities'
import { IReceivedGradesByCycle, ISelectedPayload, QuestionNumbers } from './reports-group-interfaces'

const inititalSelected: ISelectedPayload = {
  groupId: '',
  year: 'all'
}

export function useReportsGroup (isLumiarSchool: boolean) {
  const groups = useApiData(getGroups())
  const mixpanel = useMixPanel()
  const [selected, setSelected] = useState(inititalSelected)
  const [grades, setGrades] = useState<IReceivedGradesByCycle>()

  const payload = useMemo(() => {
    const selectedGroup = groups.loading
      ? undefined
      : groups.data.find(g => g.groupSchoolTermId === selected.groupId)

    const isNotSelectedYear = ['all', ''].includes(selected.year) || !selectedGroup || !grades
    const questionNumber = (): QuestionNumbers => {
      if (isNotSelectedYear) {
        return 128
      }
      if (isLumiarSchool) {
        return 129
      }
      return 130
    }

    return getPayloadParams(questionNumber(), selectedGroup, selected.year, grades?.current_year)
  }, [selected])

  const loadGrades = (cycleId: string) => {
    getGradesByCycle(cycleId)
      .then(response => {
        if (response) {
          setGrades(response)
        } else {
          lumiarToastError()
        }
      })
      .catch(ErrorService.notice)
  }

  return {
    url: useMetabase().url(payload),
    groups,
    grades,
    selected,
    changeGroup: useCallback(
      (value: string) => {
        setSelected(old => ({
          ...old,
          groupId: value,
          year: inititalSelected.year,
        }))
        if (value && !groups.loading) {
          loadGrades(
            groups.data.find(g => g.groupSchoolTermId === value)?.cycleId ?? '',
          )
        }
        mixpanel.track('Alterar turma - Relatório da turma')
      },
      [setSelected, groups],
    ),
    changeYear: useCallback(
      (value: string) => {
        setSelected(old => ({ ...old, year: value }))
        mixpanel.track('Alterar ano - Relatório da turma')
      },
      [setSelected],
    ),
  }
}
