import React, { createContext, useEffect, useState, useContext } from 'react'
import { useSetState, useScrollIntoView, useListState, useDisclosure } from 'hooks'
import { notifications } from 'context'
import {
  ILvl1Item,
  ILvl2Item,
  ILvl3Item,
  ILvl4Item,
  ISkillsStandards,
  ISkillsStandardsInfo,
  IWorkedProjects,
  ISelectedItem,
} from '../components/ProjectSkillsStandards/interfaces'
import {
  getSkillsStandards,
  getInfo,
  updateSelectedSkillsStandards,
  getInfoSearch,
} from '../service/SkillsStandardsService'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { Beforeunload } from 'react-beforeunload'
import { StepperContext } from '../context/StepperContext'
import { ProjectButtonsContext } from './ProjectButtonsContext'

export interface ISkillsStandardsContext {
  scrollIntoView: () => void
  targetRef: React.RefObject<HTMLDivElement>
  skillsStandards: ISkillsStandards
  setSkillsStandards: React.Dispatch<React.SetStateAction<ISkillsStandards>>
  skillsStandardsInfo: ISkillsStandardsInfo[]
  setSkillsStandardsInfo: React.Dispatch<React.SetStateAction<ISkillsStandardsInfo[]>>
  selectedStandards: ILvl4Item[]
  selectedStandardsHandler: {
    append: (value: ILvl4Item) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ILvl4Item[]>>
  }
  selectedSkills: ILvl4Item[]
  selectedSkillsHandler: {
    append: (value: ILvl4Item) => void
    remove: (index: number) => void
    setState: React.Dispatch<React.SetStateAction<ILvl4Item[]>>
  }
  page1Loaded: boolean
  setPage1Loaded: React.Dispatch<React.SetStateAction<boolean>>
  page2Loaded: boolean
  setPage2Loaded: React.Dispatch<React.SetStateAction<boolean>>
  currentTab: string
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  page2Data: ILvl3Item[]
  setPage2Data: React.Dispatch<React.SetStateAction<ILvl3Item[]>>
  currentSkillStandard: ILvl1Item
  setCurrentSkillStandard: React.Dispatch<React.SetStateAction<ILvl1Item>>
  currentMoreItems: ILvl2Item[]
  setCurrentMoreItems: React.Dispatch<React.SetStateAction<ILvl2Item[]>>
  handleSetCurrent: (id: string, type: string) => void
  handleBack: () => void
  handleChangeTab: (value: string | null) => void
  handleAddItem: (item: ILvl4Item, lv1ID: string, lv2ID: string) => void
  handleRemoveItem: (id: string, type: string) => void
  countSelected: () => number
  checkIfSelected: (id: string, type: string) => '' | ILvl4Item | undefined
  checkIfHasAssessment: (id: string, type: string) => boolean
  reviewModalOpened: boolean
  reviewModalOpen: () => void
  reviewModalClose: () => void
  projectsModalOpened: boolean
  projectsModalOpen: () => void
  projectsModalClose: () => void
  infoModalopened: boolean
  infoModalOpen: () => void
  infoModalClose: () => void
  currentInfo: ILvl4Item
  setCurrentInfo: React.Dispatch<React.SetStateAction<ILvl4Item>>
  currentWorkedProjects: IWorkedProjects[]
  setCurrentWorkedProjects: React.Dispatch<React.SetStateAction<IWorkedProjects[]>>
  handleShowInfo: (item: ILvl4Item) => void
  handleShowWorkedProjects: (ongoing: IWorkedProjects[]) => void
  searchTerm: string
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  handleChangeSearch: (value: string) => void
  searchResults: ILvl4Item[]
  setSearchResults: React.Dispatch<React.SetStateAction<ILvl4Item[]>>
  searchResultsLoaded: boolean
  setSearchResultsLoaded: React.Dispatch<React.SetStateAction<boolean>>
  getLvl1SelectedQtd: (id: string, type: string) => number
  getLvl2SelectedQtd: (id: string, type: string) => number
  getLvl4ByID: (item: ISelectedItem, type: string) => ILvl4Item | undefined
  selectedChanged: boolean
  setSelectedChanged: React.Dispatch<React.SetStateAction<boolean>>
  handleSaveDraft: () => void
  handleSaveAndContinue: () => void
  handleDiscard: () => void
}

export const SkillsStandardsContext = createContext<ISkillsStandardsContext>({} as ISkillsStandardsContext)

export const SkillsStandardsProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()

  const { active, setActive, nextStep, confirmModalClose, projectId, projectStatus, setHasChanged, isTemplate } =
    useContext(StepperContext)
  const { buttonHandlers } = useContext(ProjectButtonsContext)

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 0,
  })
  const [skillsStandards, setSkillsStandards] = useState<ISkillsStandards>({
    byCycle: false,
    groupSchoolTerm: {
      name: '',
    },
    totalStudents: 0,
    selectedItems: {
      contents: [],
      specificSkills: [],
    },
    curriculumItems: {
      subjects: [],
      axes: [],
    },
  } as ISkillsStandards)
  const [skillsStandardsInfo, setSkillsStandardsInfo] = useState<ISkillsStandardsInfo[]>([])
  const [selectedStandards, selectedStandardsHandler] = useListState<ILvl4Item>([])
  const [selectedSkills, selectedSkillsHandler] = useListState<ILvl4Item>([])
  const [selectedSkillLoaded, setSelectedSkillLoaded] = useState(false)
  const [selectedStandardLoaded, setSelectedStandardLoaded] = useState(false)
  const [page1Loaded, setPage1Loaded] = useState<boolean>(false)
  const [page2Loaded, setPage2Loaded] = useState<boolean>(false)
  const [currentTab, setCurrentTab] = useState<string>('content')
  const [page, setPage] = useState<number>(1)
  const [page2Data, setPage2Data] = useState<ILvl3Item[]>([])
  const [currentSkillStandard, setCurrentSkillStandard] = useSetState<ILvl1Item>({} as ILvl1Item)
  const [currentMoreItems, setCurrentMoreItems] = useState<ILvl2Item[]>([])
  const [reviewModalOpened, { open: reviewModalOpen, close: reviewModalClose }] = useDisclosure(false)
  const [projectsModalOpened, { open: projectsModalOpen, close: projectsModalClose }] = useDisclosure(false)
  const [infoModalopened, { open: infoModalOpen, close: infoModalClose }] = useDisclosure(false)
  const [currentInfo, setCurrentInfo] = useState<ILvl4Item>({} as ILvl4Item)
  const [currentWorkedProjects, setCurrentWorkedProjects] = useState<IWorkedProjects[]>([] as IWorkedProjects[])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<ILvl4Item[]>([])
  const [searchResultsLoaded, setSearchResultsLoaded] = useState<boolean>(false)
  const [selectedChanged, setSelectedChanged] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const goToProjectView = () => {
    const isReviewing = projectStatus === 'reviewing'
    const projectViewURL = isTemplate && isReviewing ? `/projects_library/${projectId}` : `/projects/${projectId}`

    const url = setTimeout(() => {
      window.location.replace(projectViewURL)
    }, 2000)
  }

  const handleChangeTab = (value: string | null) => {
    value === 'skill' ? setCurrentTab('skill') : setCurrentTab('content')
    setPage(1)
  }

  const fetchSkillsStandards = async () => {
    if (projectId) {
      try {
        const skillsStandardsData = await getSkillsStandards(projectId)
        setSkillsStandards(skillsStandardsData)
        setPage1Loaded(true)
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
        }
      }
    } else {
      notifications.show({
        message: t('new_project_page.message_no_project_associated'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    }
  }

  const fetchInfo = async (projectID: string, activeLvl2ID: string) => {
    try {
      const infoData = await getInfo(projectID, activeLvl2ID)
      setSkillsStandardsInfo(infoData)
      setPage2Loaded(true)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  const fetchInfoSearch = async (projectID: string, lvl4IDs: string[]) => {
    try {
      const infoDataSearch = await getInfoSearch(projectID, lvl4IDs)
      setSkillsStandardsInfo(infoDataSearch)
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message)
      }
    }
  }

  const updateProjectSkillsStandards = async () => {
    if (projectId !== null) {
      const skillsIds = selectedSkills.length > 0 ? selectedSkills.map(skill => skill.id) : []
      const standardsIds = selectedStandards.length > 0 ? selectedStandards.map(standard => standard.id) : []
      const skillsStandardsIds = skillsIds.concat(standardsIds)
      const updateData = {
        lv4_item_ids: skillsStandardsIds,
      }
      if (updateData) {
        const data = await updateSelectedSkillsStandards(projectId, updateData)
        return data
      }
    }
  }

  const handleSaveDraft = async () => {
    if (!projectId) {
      notifications.show({
        message: t('new_project_page.message_no_project_associated'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    } else {
      try {
        buttonHandlers.setState([
          { loading: true, disabled: false },
          { loading: false, disabled: true },
        ])
        const update = await updateProjectSkillsStandards()
        if (update.errors && update.errors !== '') {
          setError(update.errors)
          if (selectedSkills.length === 0 && selectedStandards.length === 0) {
            setSelectedChanged(false)
            goToProjectView()
          }
        }
        if (!update.errors) {
          setSelectedChanged(false)
          reviewModalClose()
          notifications.show({
            message: projectStatus ? t('new_project_page.draft_saved') : t('new_project_page.project_saved'),
            color: 'green',
            icon: <IconCheck size={24} />,
          })
          buttonHandlers.setState([
            { loading: false, disabled: false },
            { loading: false, disabled: false },
          ])
          setHasChanged(false)
          goToProjectView()
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
          goToProjectView()
        }
      }
    }
  }

  const handleSaveAndContinue = async () => {
    if (selectedSkills.length === 0 && selectedStandards.length === 0) {
      reviewModalClose()
      nextStep()
    } else if (!projectId) {
      notifications.show({
        message: t('new_project_page.message_no_project_associated'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    } else {
      try {
        buttonHandlers.setState([
          { loading: false, disabled: true },
          { loading: true, disabled: false },
        ])
        const update = await updateProjectSkillsStandards()
        if (update.errors && update.errors !== '') {
          setError(update.errors)
          goToProjectView()
        }
        if (!update.errors) {
          setSelectedChanged(false)
          notifications.show({
            message: t('new_project_page.message_skills_standards_saved'),
            color: 'green',
            icon: <IconCheck size={24} />,
          })
          reviewModalClose()
          nextStep()
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(error.message)
          goToProjectView()
        }
      }
    }
  }

  const handleBack = () => {
    setPage(1)
    scrollIntoView({
      alignment: 'center',
    })
  }

  function handleSetCurrent(id: string, type: string) {
    if (id && type) {
      setPage2Loaded(false)

      if (skillsStandards) {
        const currentLvl1 =
          type === 'skill'
            ? skillsStandards?.curriculumItems.axes.find(item => item.children?.find(child => child.id === id))
            : skillsStandards?.curriculumItems.subjects.find(item => item.children?.find(child => child.id === id))

        if (currentLvl1 && currentLvl1.children) {
          const moreItemsLvl2 = currentLvl1.children.filter(lvl2 => lvl2.id !== id)
          currentLvl1.children = currentLvl1.children.filter(lvl2 => lvl2.id === id)
          setCurrentSkillStandard(currentLvl1)
          setCurrentMoreItems(moreItemsLvl2)
          setPage(2)
        }
      }
    }
  }

  const handleAddItem = (item: ILvl4Item, lv1ID: string, lv2ID: string) => {
    const existItem =
      item.type === 'skill'
        ? selectedSkills.find(skill => skill.id === item.id)
        : selectedStandards.find(standard => standard.id === item.id)
    if (!existItem) {
      const addedItem = {
        ...item,
        lv1ID: lv1ID,
        lv2ID: lv2ID,
      }
      item.type === 'skill' ? selectedSkillsHandler.append(addedItem) : selectedStandardsHandler.append(addedItem)
      setSelectedChanged(true)
    } else {
      notifications.show({
        message: t('new_project_page.message_item_already_selected'),
        color: 'red',
        icon: <IconCheck size={24} />,
      })
    }
  }

  const handleRemoveItem = (id: string, type: string) => {
    type === 'skill'
      ? selectedSkillsHandler.remove(selectedSkills.findIndex(item => item.id === id))
      : selectedStandardsHandler.remove(selectedStandards.findIndex(item => item.id === id))
    setSelectedChanged(true)
  }

  const countSelected = () => {
    const total = selectedStandards.length + selectedSkills.length
    return total
  }

  const checkIfSelected = (id: string, type: string) => {
    const items = type === 'skill' ? selectedSkills : selectedStandards
    const hasItem = id && items && items.find(item => item.id === id)
    return hasItem
  }

  const checkIfHasAssessment = (id: string, type: string) => {
    if (id && type === 'skill' && selectedSkills && selectedSkills.find(item => item.id === id)?.hasAssess === true) {
      return true
    }
    if (
      id &&
      type === 'content' &&
      selectedStandards &&
      selectedStandards.find(item => item.id === id)?.hasAssess === true
    ) {
      return true
    }
    return false
  }

  const handleShowInfo = (item: ILvl4Item) => {
    setCurrentInfo(item)
    infoModalOpen()
  }

  const handleShowWorkedProjects = (ongoing: IWorkedProjects[]) => {
    setCurrentWorkedProjects(ongoing)
    projectsModalOpen()
  }

  const handleChangeSearch = (value: string) => {
    setSearchTerm(value)
  }

  const handleDiscard = () => {
    const goToStep = active > 0 ? active - 1 : active
    confirmModalClose()
    setActive(goToStep)
    setSelectedChanged(false)
  }

  const getLvl1SelectedQtd = (id: string, type: string) => {
    if (type === 'skill' && selectedSkills) return selectedSkills.filter(item => item.lv1ID === id).length
    if (type === 'content' && selectedStandards) return selectedStandards.filter(item => item.lv1ID === id).length
    return 0
  }

  const getLvl2SelectedQtd = (id: string, type: string) => {
    if (type === 'skill' && selectedSkills) return selectedSkills.filter(item => item.lv2ID === id).length
    if (type === 'content' && selectedStandards) return selectedStandards.filter(item => item.lv2ID === id).length
    return 0
  }

  const getLvl4ByID = (item: ISelectedItem, type: string) => {
    const curriculumItems =
      type === 'skill' ? skillsStandards.curriculumItems.axes : skillsStandards.curriculumItems.subjects
    const findLvl4 = (curriculumItems: ILvl1Item[]): ILvl4Item | undefined => {
      for (const lvl1 of curriculumItems) {
        for (const lvl2 of lvl1.children ?? []) {
          for (const lvl3 of lvl2.children ?? []) {
            for (const lvl4 of lvl3.children ?? []) {
              if (lvl4.id === item.id) {
                return {
                  ...lvl4,
                  lv1ID: lvl1.id,
                  lv1Name: lvl1.name,
                  lv2ID: lvl2.id,
                  lv2Name: lvl2.name,
                  hasAssess: item.hasAssess,
                }
              }
            }
          }
        }
      }
    }
    return findLvl4(curriculumItems)
  }

  const handlePageExit = (event: { preventDefault: () => void }) => {
    event.preventDefault()
  }

  const handleErrors = (error: string) => {
    if (error.includes('Cannot read properties of undefined')) {
      return t('general_error_message_data_not_loaded')
    }
    if (error.includes('já possui avaliação')) {
      return t('general_error_message_already_assessed')
    }
    return error
  }

  useEffect(() => {
    if (error && error !== '') {
      notifications.show({
        title: t('general_error_title'),
        message: handleErrors(error),
        color: 'red',
        icon: <IconX size={24} />,
      })
      setError('')
    }
  }, [error])

  useEffect(() => {
    if (searchTerm.length > 1 && skillsStandards && Object.keys(skillsStandards).length > 0 && projectId) {
      setSearchResultsLoaded(false)
      const lvl4IDs: string[] = []
      const skillsResults = skillsStandards.curriculumItems.axes.flatMap(lvl1 =>
        lvl1.children?.flatMap(lvl2 =>
          lvl2.children?.flatMap(lvl3 =>
            lvl3.children?.flatMap(lvl4 => {
              if (lvl4.cycles.length > 0 && !lvl4IDs.includes(lvl4.id)) {
                const cycles = lvl4.cycles ?? []
                const results = cycles.flatMap(cycle => {
                  if (
                    lvl4.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    lvl4.originalCodes?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    cycle.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    const isInArray = lvl4IDs.includes(lvl4.id)
                    if (!isInArray) {
                      lvl4IDs.push(lvl4.id)
                      return {
                        ...lvl4,
                        lv1ID: lvl1.id,
                        lv1Name: lvl1.name,
                        lv2ID: lvl2.id,
                        lv2Name: lvl2.name,
                      }
                    } else {
                      return []
                    }
                  } else {
                    return []
                  }
                })
                return results
              } else if (
                lvl4.cycles.length === 0 &&
                !lvl4IDs.includes(lvl4.id) &&
                (lvl4.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  lvl4.originalCodes?.toLowerCase().includes(searchTerm.toLowerCase()))
              ) {
                const isInArray = lvl4IDs.includes(lvl4.id)
                if (!isInArray) {
                  lvl4IDs.push(lvl4.id)
                  return {
                    ...lvl4,
                    lv1ID: lvl1.id,
                    lv1Name: lvl1.name,
                    lv2ID: lvl2.id,
                    lv2Name: lvl2.name,
                  }
                } else {
                  return []
                }
              } else {
                return []
              }
            }),
          ),
        ),
      )
      const standardsResults = skillsStandards.curriculumItems.subjects.flatMap(lvl1 =>
        lvl1.children?.flatMap(lvl2 =>
          lvl2.children?.flatMap(lvl3 =>
            lvl3.children?.flatMap(lvl4 => {
              if (lvl4.cycles.length > 0 && !lvl4IDs.includes(lvl4.id)) {
                const cycles = lvl4.cycles ?? []
                const results = cycles.flatMap(cycle => {
                  if (
                    lvl4.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    lvl4.originalCodes?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    cycle.name.toLowerCase().includes(searchTerm.toLowerCase())
                  ) {
                    const isInArray = lvl4IDs.includes(lvl4.id)
                    if (!isInArray) {
                      lvl4IDs.push(lvl4.id)
                      return {
                        ...lvl4,
                        lv1ID: lvl1.id,
                        lv1Name: lvl1.name,
                        lv2ID: lvl2.id,
                        lv2Name: lvl2.name,
                      }
                    } else {
                      return []
                    }
                  } else {
                    return []
                  }
                })
                return results
              } else if (
                lvl4.cycles.length === 0 &&
                !lvl4IDs.includes(lvl4.id) &&
                (lvl4.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  lvl4.originalCodes?.toLowerCase().includes(searchTerm.toLowerCase()))
              ) {
                const isInArray = lvl4IDs.includes(lvl4.id)
                if (!isInArray) {
                  lvl4IDs.push(lvl4.id)
                  return {
                    ...lvl4,
                    lv1ID: lvl1.id,
                    lv1Name: lvl1.name,
                    lv2ID: lvl2.id,
                    lv2Name: lvl2.name,
                  }
                } else {
                  return []
                }
              } else {
                return []
              }
            }),
          ),
        ),
      )
      const results = skillsResults.concat(standardsResults)
      setSearchResults(results as ILvl4Item[])
      if (lvl4IDs.length > 0) {
        fetchInfoSearch(projectId, lvl4IDs)
        setSearchResultsLoaded(true)
      } else {
        setSearchResultsLoaded(true)
      }
    }
  }, [searchTerm])

  useEffect(() => {
    fetchSkillsStandards()
    if (page === 2 && currentSkillStandard && currentSkillStandard.children && projectId) {
      fetchInfo(projectId, currentSkillStandard.children[0].id)
      setPage2Data(currentSkillStandard.children[0].children ? currentSkillStandard.children[0].children : [])
    }
  }, [page, currentSkillStandard])

  useEffect(() => {
    if (page2Loaded === true && page === 2) {
      scrollIntoView()
    }
  }, [page2Loaded])

  useEffect(() => {
    if (Object.keys(skillsStandards).length > 0) {
      if (
        selectedSkillLoaded === false &&
        skillsStandards.selectedItems.specificSkills &&
        skillsStandards.selectedItems.specificSkills.length > 0
      ) {
        skillsStandards.selectedItems.specificSkills.map(item => {
          const itemData = getLvl4ByID(item, 'skill')
          checkIfSelected(item.id, 'skill') ? null : selectedSkillsHandler.append(itemData as ILvl4Item)
          setSelectedSkillLoaded(true)
        })
      }
      if (
        selectedStandardLoaded === false &&
        skillsStandards.selectedItems.contents &&
        skillsStandards.selectedItems.contents.length > 0
      ) {
        skillsStandards.selectedItems.contents.map(item => {
          const itemData = getLvl4ByID(item, 'content')
          checkIfSelected(item.id, 'content') ? null : selectedStandardsHandler.append(itemData as ILvl4Item)
          setSelectedStandardLoaded(true)
        })
      }
    }
  }, [skillsStandards, selectedSkillLoaded, selectedStandardLoaded])

  return (
    <SkillsStandardsContext.Provider
      value={{
        scrollIntoView,
        targetRef,
        skillsStandards,
        setSkillsStandards,
        skillsStandardsInfo,
        setSkillsStandardsInfo,
        selectedStandards,
        selectedStandardsHandler,
        selectedSkills,
        selectedSkillsHandler,
        page1Loaded,
        setPage1Loaded,
        page2Loaded,
        setPage2Loaded,
        currentTab,
        setCurrentTab,
        page,
        setPage,
        page2Data,
        setPage2Data,
        currentSkillStandard,
        setCurrentSkillStandard,
        currentMoreItems,
        setCurrentMoreItems,
        handleSetCurrent,
        handleBack,
        handleChangeTab,
        handleAddItem,
        handleRemoveItem,
        checkIfSelected,
        checkIfHasAssessment,
        countSelected,
        reviewModalOpened,
        reviewModalOpen,
        reviewModalClose,
        projectsModalOpened,
        projectsModalOpen,
        projectsModalClose,
        infoModalopened,
        infoModalOpen,
        infoModalClose,
        currentInfo,
        setCurrentInfo,
        currentWorkedProjects,
        setCurrentWorkedProjects,
        handleShowInfo,
        handleShowWorkedProjects,
        searchTerm,
        setSearchTerm,
        handleChangeSearch,
        searchResults,
        setSearchResults,
        searchResultsLoaded,
        setSearchResultsLoaded,
        getLvl1SelectedQtd,
        getLvl2SelectedQtd,
        getLvl4ByID,
        selectedChanged,
        setSelectedChanged,
        handleSaveDraft,
        handleSaveAndContinue,
        handleDiscard,
      }}
    >
      {selectedChanged === true ? <Beforeunload onBeforeunload={event => handlePageExit(event)} /> : null}
      {children}
    </SkillsStandardsContext.Provider>
  )
}
