import styled from 'styled-components'
import { ScoreItemStates } from './ScoreItem'

export const Label = styled.span<ScoreItemStates>`
  font-size: 1.75rem;
  font-weight: 700;
  color: ${props =>
    props.disabled
      ? 'var(--color-text-disabled)'
      : props.active
        ? 'var(--color-primary-ultradark)'
        : 'var(--color-black)'};
`

export const StyledHandPath = styled.path<ScoreItemStates>`
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-linecap: round;
  stroke: ${props =>
    props.disabled
      ? 'var(--color-text-disabled)'
      : props.active
        ? 'var(--color-primary-ultradark)'
        : 'var(--color-black)'};
`
