import { notifications } from 'context'
import { IconX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import { createContext, useState, useEffect } from 'react'

const maxFileSize = 5242880 // Max file size of 5mb

interface CoverFileContextType {
  fileCoverSrc: string | null
  downloadURL?: string | null
  fileCover: File | null
  loadingCover: boolean
  setFileCover: (value: File | null) => void
  setFileCoverSrc: (value: string | null) => void
  setDownloadURL: (value: string | null) => void
  setLoadingCover: (value: boolean) => void
}

export const CoverFileContext = createContext<CoverFileContextType>({
  fileCover: null,
  fileCoverSrc: null,
  downloadURL: null,
  loadingCover: false,
  setFileCover: () => {},
  setLoadingCover: () => {},
  setFileCoverSrc: () => {},
  setDownloadURL: () => {},
})

export const CoverFileProvider = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation()

  const [fileCover, setFileCover] = useState<File | null>(null)
  const [fileCoverSrc, setFileCoverSrc] = useState<string | null>(null)
  const [downloadURL, setDownloadURL] = useState<string | null>(null)
  const [loadingCover, setLoadingCover] = useState<boolean>(false)

  useEffect(() => {
    if (fileCover) {
      if (fileCover.size > maxFileSize) {
        setFileCoverSrc(null)
        notifications.show({
          message: t('new_project_page.error_file_is_too_big'),
          color: 'red',
          icon: <IconX size={24} />,
        })
      }
      if (!fileCover.type.startsWith('image/')) {
        setFileCoverSrc(null)
        notifications.show({
          message: t('new_project_page.error_file_is_not_an_image'),
          color: 'red',
          icon: <IconX size={24} />,
        })
      }
      if (fileCover.type.startsWith('image/') && fileCover.size < maxFileSize) {
        const reader = new FileReader()
        reader.onload = e => {
          setFileCoverSrc(e.target?.result as string)
        }
        reader.readAsDataURL(fileCover)
      }
    }
  }, [fileCover])

  return (
    <CoverFileContext.Provider
      value={{
        fileCover,
        fileCoverSrc,
        downloadURL,
        loadingCover,
        setFileCoverSrc,
        setFileCover,
        setDownloadURL,
        setLoadingCover,
      }}
    >
      {children}
    </CoverFileContext.Provider>
  )
}
