import { useMemo } from 'react'
import { Stack, Text, Rating, Group } from 'components'
import { getMoodOfFive } from 'helpers'
import { useTranslation } from 'react-i18next'
import { RatingItemProps } from './interfaces'

export const RatingItem = ({ value, label, studentCount, answerCount }: RatingItemProps) => {
  const { t } = useTranslation()
  
  const moodValue = useMemo(() => value, [value])
  const moodLabel = useMemo(() => getMoodOfFive(value), [value])

  return (
    <Stack spacing={4}>
      <Text>{label}</Text>
      <Group spacing='xs' align='center'>
        <Rating size='md' readOnly value={moodValue} />
        <Text size='md' fw='bold'>
          {moodLabel}
        </Text>
      </Group>
      {studentCount && answerCount && (
        <Text size='sm' color='dimmed'>
          {t('new_final_project_report.response_count', { answerCount, studentCount })}
        </Text>
      )}
    </Stack>
  )
}
