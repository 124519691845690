import React from 'react'
import { Text } from '@react-pdf/renderer'
import { v4 as UuidV4 } from 'uuid'
import styles from './styled'
import { hasHTMLTags, parseHTML } from 'helpers'

interface IReportFromHtml {
  readonly stringhtml: string
}

const ReportFromHtml: React.FC<IReportFromHtml> = ({ stringhtml }) => {
  if (!hasHTMLTags(stringhtml)) {
    return <Text>{stringhtml}</Text>
  }

  const parsedHTML = parseHTML(stringhtml)
  const elements = parsedHTML.body.children
  return (
    <>
      {Array.from(elements).map((element: any) => {
        if (element?.innerText.trim() !== '') {
          return (
            <Text key={UuidV4()} style={styles.p}>
              {element?.innerText}
            </Text>
          )
        }

        return <Text key={UuidV4()} style={{ marginBottom: '4px', textAlign: 'justify' }} />
      })}
    </>
  )
}

export default ReportFromHtml
