import axios from "axios";
import {
  IReceivedStudentCurriculum,
  IStudentCurriculum,
  IStudentCycle,
} from "../models/student";
import {
  IStudentsGroupReportPayload,
  IStudentsReportPayload,
} from "../components/apps/students/report/students-report-hook";
import { IStudentReportData } from "../components/apps/reports_student/reports-student-interfaces";
import { IReceivedGradesByCycle } from "../components/apps/reports_group/reports-group-interfaces";

import baseRequest from "./base-request";
import Paths from "./service-paths";
import StudentsMapper from "./students-mapper";
import {
  IStudentGroupReportResponse,
  IStudentReportResponse,
  IStudentsGroup,
} from "./students-types";
import {
  IReceivedStudentGroupReportResponse,
  IReceivedStudentReportResponse,
  IReceivedStudentsGroup,
} from "./students-received-types";

async function getCurriculums(id: string): Promise<IStudentCurriculum[]> {
  const response = await baseRequest.get<IReceivedStudentCurriculum[]>(
    Paths.students.curriculums(id)
  );
  return StudentsMapper.curriculumsMapper(response);
}

export async function getGroupCurriculums(
  groupSchoolTermId: string
): Promise<IStudentCurriculum[]> {
  const response = await baseRequest.get<IReceivedStudentCurriculum[]>(
    Paths.students.groupCurriculums(groupSchoolTermId)
  );
  return StudentsMapper.curriculumsMapper(response);
}

export async function getLevel4ItemsReport(
  payload: IStudentsReportPayload
): Promise<IStudentReportResponse> {
  const response = await baseRequest.get<IReceivedStudentReportResponse>(
    Paths.students.level4ItemsReport(payload)
  );
  return StudentsMapper.level4ItemsReportMapper(response);
}

export async function getGroupLevel4ItemsReport(
  payload: IStudentsGroupReportPayload
): Promise<IStudentGroupReportResponse> {
  const response = await baseRequest.get<IReceivedStudentGroupReportResponse>(
    Paths.students.groups.level4ItemsReport(payload)
  );
  return StudentsMapper.groupLevel4ItemsReportMapper(response);
}

export async function getGroups(): Promise<IStudentsGroup[]> {
  const response = await baseRequest.get<IReceivedStudentsGroup[]>(
    Paths.students.groups.index
  );

  return StudentsMapper.groupsMapper(response);
}

export async function getCycles(): Promise<IStudentCycle[]> {
  const response = await baseRequest.get<IStudentCycle[]>("/cycles");
  return response;
}

export interface ICyclesAndCurriculumsByStudent {
  readonly items: IStudentReportData[];
  readonly current_cycle?: string;
  readonly current_grade?: string;
}

export async function getCyclesAndCurriculumsByStudent(
  studentId: string
): Promise<ICyclesAndCurriculumsByStudent> {
  const response = await baseRequest.get<ICyclesAndCurriculumsByStudent>(
    `/api/reports/cycles_and_curriculums_by_student/${studentId}`
  );
  return response;
}

export async function getGradesByCycle(
  cycleId: string
): Promise<IReceivedGradesByCycle> {
  const response = await baseRequest.get<IReceivedGradesByCycle>(
    `/api/reports/grades_by_cycle/${cycleId}`
  );
  return response;
}

export interface IStudentSearchResult {
  readonly student_id: string;
  readonly full_name: string;
}

export async function getStudentsByName(name?: string) {
  const response = await axios.get<IStudentSearchResult[]>(
    `/api/students?name=${name ?? ""}`
  );
  return response.data;
}

export default {
  getCurriculums,
  getGroupCurriculums,
  getLevel4ItemsReport,
  getGroupLevel4ItemsReport,
  getGroups,
};
