/* eslint-disable max-len */
import { Fragment } from 'react'
import { ScoreItemLabel, ScoreItemStates } from './ScoreItem'
import { StyledHandPath } from './scoreItemStyled'

export type ScoreItemHandAssetProps = ScoreItemStates & {
  readonly children: ScoreItemLabel;
};

const mappedHands = (
  states: ScoreItemStates,
): Record<ScoreItemLabel, JSX.Element> => ({
  0: <></>,
  1: (
    <StyledHandPath
      {...states}
      d="M6.68175 20.7178C9.56817 20.774 13.6916 24.3352 13.6354 27.1841M8.0131 35V30.8953C7.99435 30.6329 7.8819 30.3705 7.69447 30.1643L2.25902 24.5039C1.32187 23.5293 0.853298 21.6737 1.04073 20.6991C1.05947 20.5492 1.09696 20.3805 1.15318 20.193L2.22153 17.3254C2.31525 17.1754 2.40896 17.0255 2.50268 16.8943C3.04622 16.1821 3.60851 15.9384 4.50817 15.9571L13.9734 16.0134C14.292 16.0134 14.5544 16.2945 14.5356 16.6319C14.4607 17.5503 13.8796 19.2559 13.7109 19.5558C13.1861 20.3617 12.4177 20.8303 11.4243 20.8116C10.7496 20.7928 9.00647 20.7741 8.03184 20.7553L6.25126 20.7178M23.7006 17.8315V24.8976C23.7006 29.4333 20.5705 28.7586 20.5705 31.645C20.5705 33.4818 20.5705 35 20.5705 35M18.7527 20.4368L19.8773 13.3519C20.0272 12.3586 20.9644 11.7025 21.939 11.8525L22.8012 11.9837C23.7946 12.1336 24.4506 13.0708 24.3006 14.0454L23.1761 21.1302M23.2322 20.7179C23.0823 21.7112 22.1452 22.3672 21.1705 22.2173L20.3083 22.0861C19.315 21.9361 18.659 20.999 18.8089 20.0244M10.3571 15.2074L11.2755 9.32215C11.4255 8.32877 12.3626 7.67277 13.3373 7.82271L14.1994 7.95391C15.1928 8.10386 15.8488 9.041 15.6989 10.0156L14.5743 17.1005M4.94001 14.8139L4.75258 2.87462C4.73383 1.8625 5.52104 1.03781 6.51441 1.01907L7.39533 1.00033C8.38871 0.981585 9.2134 1.76879 9.23214 2.76216L9.41957 14.7014M14.5357 18.2439L15.6603 11.159C15.8102 10.1657 16.7474 9.50966 17.722 9.6596L18.5842 9.7908C19.5776 9.94075 20.2336 10.8779 20.0836 11.8525L18.959 18.9374C18.8091 19.9307 17.8712 20.5867 16.8966 20.4368L16.0344 20.3056C15.041 20.1557 14.3858 19.2185 14.5357 18.2439Z"
    />
  ),
  2: (
    <StyledHandPath
      {...states}
      d="M6.80019 20.4204C9.74673 20.4778 13.9561 24.1131 13.8987 27.0214M8.15919 35V30.8098C8.14006 30.5419 8.02526 30.2741 7.83392 30.0636L2.28524 24.2853C1.32858 23.2904 0.850242 21.3962 1.04158 20.4012C1.06071 20.2482 1.09898 20.076 1.15638 19.8846L2.24698 16.9572C2.34265 16.8042 2.43831 16.6511 2.53398 16.5363C3.08885 15.8092 3.66285 15.5414 4.58125 15.5605L14.2436 15.6179C14.5689 15.6179 14.8367 15.9049 14.8176 16.2493C14.7411 17.1868 14.1479 18.928 13.9757 19.2341C13.44 20.0568 12.6555 20.5352 11.6415 20.516C10.9527 20.4969 9.17326 20.4778 8.17833 20.4586L6.36065 20.4204M24.1735 17.4738V24.6871C24.1735 29.3174 20.9782 28.6286 20.9782 31.5751C20.9782 33.4502 20.9782 35 20.9782 35M19.1226 20.1333L20.2706 12.9009C20.4236 11.8868 21.3803 11.2172 22.3752 11.3702L23.2554 11.5233C24.2694 11.6764 24.9391 12.633 24.786 13.628L23.638 20.8604M23.6954 20.4204C23.5423 21.4345 22.5856 22.1041 21.5907 21.9511L20.7105 21.8171C19.6965 21.6641 19.0268 20.7074 19.1799 19.7125M14.8176 18.8323L15.9656 11.5999C16.1186 10.5858 17.0753 9.91616 18.0702 10.0692L18.9504 10.2032C19.9644 10.3562 20.6341 11.3129 20.481 12.3078L19.333 19.5402M19.3906 19.1193C19.2375 20.1333 18.2809 20.803 17.2859 20.6499L16.3867 20.516C15.3726 20.3629 14.7029 19.4063 14.856 18.4113M10.436 15.4457V2.8368C10.436 1.82274 11.2587 1 12.2728 1H13.172C14.1861 1 15.0088 1.82274 15.0088 2.8368V16.4024M5.86287 14.6804L4.35133 6.45301C4.16 5.43894 4.82967 4.48227 5.8246 4.29094L6.70474 4.13787C7.69968 3.94654 8.67548 4.6162 8.84768 5.61114L10.3592 13.8385"
    />
  ),
  3: (
    <Fragment>
      <StyledHandPath
        {...states}
        d="M6.39355 19.2914C9.18311 19.3458 13.1682 22.7874 13.1139 25.5408"
      />
      <StyledHandPath
        {...states}
        d="M7.68012 33.0943V29.1273C7.662 28.8737 7.55332 28.6201 7.37218 28.4209L2.11911 22.9504C1.21341 22.0085 0.760564 20.2152 0.941704 19.2733C0.959818 19.1284 0.996046 18.9654 1.05039 18.7842L2.08289 16.0128C2.17346 15.8679 2.26403 15.7229 2.3546 15.6143C2.8799 14.9259 3.42332 14.6723 4.2928 14.6904L13.4404 14.7448C13.7483 14.7448 14.0019 15.0165 13.9838 15.3425C13.9113 16.2301 13.3498 17.8785 13.1868 18.1683C12.6796 18.9472 11.9369 19.4001 10.9769 19.382C10.3248 19.3639 8.64016 19.3457 7.69823 19.3276L5.9774 19.2914"
      />
      <StyledHandPath
        {...states}
        d="M22.8412 16.5019V23.3308C22.8412 27.7144 19.8162 27.0623 19.8162 29.8519C19.8162 31.6271 19.8162 33.0943 19.8162 33.0943"
      />
      <StyledHandPath
        {...states}
        d="M14.9976 17.8785L19.7434 12.8428C20.3955 12.1364 21.5005 12.1002 22.2069 12.7704L22.8228 13.35C23.5293 14.0021 23.5655 15.1071 22.8953 15.8135L18.1494 20.8492"
      />
      <StyledHandPath
        {...states}
        d="M18.4215 20.5413C17.7694 21.2477 16.6644 21.2839 15.958 20.6137L15.3421 20.0341C14.6357 19.382 14.5994 18.277 15.2696 17.5706"
      />
      <StyledHandPath
        {...states}
        d="M14.4365 11.5024L15.5234 4.65533C15.6683 3.69529 16.574 3.0613 17.5159 3.20621L18.3492 3.33301C19.3092 3.47792 19.9432 4.38362 19.7983 5.32555L18.4035 14.0746"
      />
      <StyledHandPath
        {...states}
        d="M9.83569 14.5818V2.64465C9.83569 1.6846 10.6146 0.905701 11.5746 0.905701H12.426C13.386 0.905701 14.1649 1.6846 14.1649 2.64465V15.4875"
      />
      <StyledHandPath
        {...states}
        d="M5.50612 13.8572L4.07511 6.06818C3.89397 5.10814 4.52796 4.20244 5.46989 4.0213L6.30314 3.87638C7.24507 3.69524 8.16888 4.32923 8.33191 5.27116L9.76291 13.0602"
      />
    </Fragment>
  ),
  4: (
    <StyledHandPath
      {...states}
      d="M6.64455 20.8116C9.51203 20.8675 13.6084 24.4053 13.5526 27.2355M7.96708 35V30.9222C7.94846 30.6615 7.83674 30.4009 7.65054 30.1961L2.25076 24.5728C1.31976 23.6046 0.854261 21.7612 1.04046 20.793C1.05908 20.644 1.09632 20.4764 1.15218 20.2902L2.21352 17.4414C2.30662 17.2738 2.39972 17.1248 2.49282 17.0131C3.03279 16.3056 3.59139 16.0449 4.48515 16.0635L13.8882 16.1194C14.2048 16.1194 14.4654 16.3987 14.4468 16.7338C14.3723 17.6462 13.7951 19.3406 13.6275 19.6385C13.1062 20.4392 12.3428 20.9047 11.3559 20.8861C10.6856 20.8675 8.95394 20.8488 7.9857 20.8302L6.21681 20.8116M23.5516 17.1435V24.9453C23.5516 29.4513 20.4421 28.781 20.4421 31.6485C20.4421 33.4732 20.4421 34.9815 20.4421 34.9815M19.1018 17.1435L20.9824 8.54111C21.1686 7.57287 22.1182 6.9398 23.0864 7.126L23.9429 7.29358C24.9112 7.47978 25.5443 8.42939 25.3581 9.39763L23.5705 17.1435M14.652 16.92L15.1919 5.95286C15.2478 4.96601 16.0857 4.20259 17.0726 4.23983L17.9477 4.27707C18.9346 4.33293 19.698 5.17083 19.6607 6.15768L19.1207 17.1248M10.1828 15.9518V2.78752C10.1828 1.80066 10.9834 1 11.9703 1H12.8454C13.8323 1 14.6329 1.80066 14.6329 2.78752V16.9014M5.73239 15.207L4.26141 7.20042C4.07521 6.21356 4.72691 5.28256 5.69515 5.09636L6.55166 4.94741C7.5199 4.76121 8.46952 5.4129 8.6371 6.38114L10.1081 14.3877"
    />
  ),
  5: (
    <Fragment>
      <g clipPath="url(#clip0_3455_25087)">
        <StyledHandPath
          {...states}
          d="M6.17314 23.1348L0.881802 16.4087C-0.14707 15.0783 3.08653 14.1913 3.38049 14.1913C4.26238 14.1174 5.07078 14.4131 5.6587 15.1522C6.02616 15.6696 7.05503 17 7.64296 17.7391L8.67183 19.0696"
        />
        <StyledHandPath
          {...states}
          d="M8.67188 19.0695C12.2729 19.513 14.4042 21.5087 14.7716 24.1695"
        />
        <StyledHandPath
          {...states}
          d="M10.8033 33.2609L10.8768 29.7869C10.8768 29.5652 10.8033 29.2695 10.6563 29.0478L6.17334 23.1348"
        />
        <StyledHandPath
          {...states}
          d="M26.3832 16.187V23.6522C26.3832 27.9391 23.4436 27.3478 23.4436 30.0826C23.4436 31.8565 23.4436 33.2609 23.4436 33.2609"
        />
        <StyledHandPath
          {...states}
          d="M22.1943 16.6304L23.0762 8.27826C23.1497 7.31739 23.9581 6.65218 24.9135 6.72609L25.7219 6.8C26.6773 6.87392 27.3387 7.68696 27.2652 8.64783L26.3833 16.187"
        />
        <StyledHandPath
          {...states}
          d="M17.9319 15.9652L18.4463 5.46956C18.5198 4.50869 19.3282 3.76956 20.2101 3.84348L21.0185 3.91739C21.9739 3.9913 22.7088 4.80434 22.6353 5.6913L22.1209 16.187"
        />
        <StyledHandPath
          {...states}
          d="M13.6692 15.0783V2.51305C13.6692 1.55218 14.4041 0.813049 15.3595 0.813049H16.1679C17.1233 0.813049 17.8582 1.55218 17.8582 2.51305V16.0391"
        />
        <StyledHandPath
          {...states}
          d="M9.62736 18.5522L7.05518 5.1C6.83471 4.13913 7.49613 3.25217 8.37802 3.10435L9.18642 2.95652C10.1418 2.73478 11.0237 3.4 11.1707 4.28695L13.3019 14.5609"
        />
      </g>
      <defs>
        <clipPath id="clip0_3455_25087">
          <rect width="28" height="34" fill="white" />
        </clipPath>
      </defs>
    </Fragment>
  ),
})

export function ScoreItemHandAsset({
  children,
  ...states
}: ScoreItemHandAssetProps) {
  return (
    <svg
      width="28"
      height="36"
      viewBox="0 0 28 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {mappedHands(states)[children]}
    </svg>
  )
}
