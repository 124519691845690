import { useTranslation } from "react-i18next"
import { IProjectSubheader } from "../../interfaces"
import { ProjectShowDetailContainer } from "./styled"

export const ProjectShowDetail = ({ project }: { project?: IProjectSubheader }) => {
  const { t } = useTranslation()

  if (!project) return <></>

  const {
    group_school_term_name,
    sessions_count,
    name,
  } = project

  return (
    <ProjectShowDetailContainer>
      <span>{name}</span>
      <span>{group_school_term_name}</span>
      <span>{`${sessions_count} ${t(`activerecord_models_meeting_${sessions_count === 1 ? 'one' : 'other'}`)}`}</span>
    </ProjectShowDetailContainer>
  )
}