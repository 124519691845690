import { DevelopmentStageProps } from 'pages/NewProject/service/interfaces'
import { MappedObject } from './interfaces'

export const isBase64 = (value: string): boolean => {
  const base64Regex = /^data:image\/([a-zA-Z]+);base64,/
  return base64Regex.test(value)
}

export const mapArrayToObject = (array: DevelopmentStageProps[]): MappedObject[] =>
  array.map(obj => ({
    value: obj.id,
    label: obj.name,
    grades: obj.grades,
  }))

export const mapGrades = (array: any[]): { value: any; label: string }[] =>
  array.map((obj: { id: any; name: string }) => ({
    value: obj.id,
    label: obj.name,
  }))
