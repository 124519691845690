import { Stack, Button, TeachersModal, Anchor, UserInfo } from 'components'
import { useTranslation } from 'react-i18next'
import { MainTeacherBlockProps } from './interfaces'
import { useDisclosure } from 'hooks'
import { isEmpty } from 'lodash'

export const MainTeacherBlock = ({ teachers, mainTeacher }: MainTeacherBlockProps) => {
  const { t } = useTranslation()
  const [openedTeachersModal, { open: openTeachersModal, close: closeTeachersModal }] = useDisclosure(false)

  return (
    <>
      {teachers !== null && mainTeacher.id !== '' && (
        <TeachersModal
          teachers={teachers}
          mainTeacherID={mainTeacher.id}
          opened={openedTeachersModal}
          onClose={closeTeachersModal}
        />
      )}
      <Stack spacing='xs'>
        <Anchor href={mainTeacher.link}>
          <UserInfo
            userName={mainTeacher.name}
            image={mainTeacher.avatar || ''}
            role={mainTeacher.educatorRole}
          />
        </Anchor>
        {!isEmpty(teachers) && (
          <Button size='sm' variant='default' onClick={openTeachersModal}>
            {t('projects_show_teamModal_button')}
          </Button>
        )}
      </Stack>
    </>
  )
}
