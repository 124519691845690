import { useRef } from 'react'
import { IStudentsAssessmentsProps, IStudent } from './StudentsAssessmentsTypes'
import { StudentsGrid, Wrapper, ButtonMask, LoaderContainer } from './StudentAssessmentsStyled'
import LumiarDrawer from 'components/LumiarDrawer/LumiarDrawer'
import { useEffect, useState } from 'react'
import { Modal } from 'components/ModalLegacy'
import { useTranslation } from 'react-i18next'
import StudentCard from 'components/StudentCardPlus/StudentCard'
import StudentModalContent from '../StudentModalContent/StudentModalContent'
import service from './assessmentService'
import { IKind } from 'components/ModalLegacy'
import { Beforeunload } from 'react-beforeunload'
import CircularLoader from 'components/CircularLoader/CircularLoader'
import i18n from '@i18n'

const StudentsAssessments = ({
  sessionMeetingSetState,
  sessionMeetingState,
  mixPanelTracker,
  meetingId,
  meetingAssessmentPayload,
  hasChangesNotSavedOnMeetingAssessment,
}: IStudentsAssessmentsProps) => {
  const { t } = useTranslation()
  const [showDrawer, setShowDrawer] = useState(false)
  const [loadingDrawer, setLoadingDrawer] = useState(true)
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>()
  const [hasChanges, setHasChanges] = useState<boolean>(false)
  const [allStudents, setAllStudents] = useState<IStudent[]>([])
  const [loadingStudents, setLoadingStudents] = useState(false)
  const [studentIndex, setStudentIndex] = useState(0)
  const modalRef = useRef(null)

  useEffect(() => {
    fetchStudents()
  }, [])

  const fetchStudents = async () => {
    setLoadingStudents(true)

    const response = await service.getStudents(meetingId)

    setAllStudents(response as IStudent[])
    setLoadingStudents(false)
  }

  const fetchStudentAssessment = async (assessmentId: string) => {
    const response = await service.getStudentAssessment(meetingId, assessmentId)
    setSelectedStudent(response)
  }

  const handleCloseDrawer = (value: boolean, skipConfirmation: boolean = false) => {
    if (hasChanges === true && !skipConfirmation) {
      Modal({
        title: t('students_assessment_title_modal'),
        text: t('students_assessment_text_modal'),
        kind: IKind.ConfirmationExit,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          setShowDrawer(value)
          fetchStudents()
          setHasChanges(false)
          setSelectedStudent(null)
        }
      })
      return
    }

    setShowDrawer(value)
    setSelectedStudent(null)
  }

  const handleClickOnStudent = (student: IStudent, index: number) => {
    mixPanelTracker('Avaliar estudante')
    fetchStudentAssessment(student.assessmentId)
    setStudentIndex(index)
    setLoadingDrawer(false)
    setShowDrawer(true)
  }

  const saveAndGoNext = () => {
    handleClickOnStudent(allStudents[studentIndex + 1], studentIndex + 1)
  }

  const goNext = studentIndex + 1 < allStudents.length

  const meetingsReportWasNotCreated = i18n.t('meetings_report_wasNotCreated')

  if (loadingStudents) {
    return (
      <LoaderContainer>
        <CircularLoader size={32} color='var(--color-primary-dark)' thickness={3} time={1} />
      </LoaderContainer>
    )
  }

  if (!allStudents.length) return <h5 className='my-4 mx-4'>{meetingsReportWasNotCreated}</h5>

  return (
    <>
      <Wrapper>
        {hasChanges && <Beforeunload onBeforeunload={event => event.preventDefault()} />}
        <h2>{t('students_assessment_partial_title')}</h2>
        <StudentsGrid>
          {allStudents.map((student, index) => {
            return (
              <ButtonMask
                key={student.id}
                onClick={() => {
                  handleClickOnStudent(student, index)
                  document.body.style.overflow = 'hidden'
                }}
                role='button'
              >
                <StudentCard
                  name={student.fullName}
                  image={student.imageUrl}
                  status={student.assessmentStatus}
                  studentStatus={student.studentAssessmentStatus}
                  hasScore={student.hasScore}
                />
              </ButtonMask>
            )
          })}
        </StudentsGrid>

        <LumiarDrawer
          visibility={!!selectedStudent && showDrawer}
          onChange={handleCloseDrawer}
          side='right'
          modalRef={modalRef}
          scrollable={false}
        >
          {showDrawer && selectedStudent && (
            <StudentModalContent
              sessionMeetingSetState={sessionMeetingSetState}
              sessionMeetingState={sessionMeetingState}
              meetingAssessmentPayload={meetingAssessmentPayload}
              hasChangesNotSavedOnMeetingAssessment={hasChangesNotSavedOnMeetingAssessment}
              selectedStudent={selectedStudent}
              hasChanges={hasChanges}
              goNext={goNext}
              handleCloseDrawer={handleCloseDrawer}
              mixPanelTracker={mixPanelTracker}
              saveAndGoNext={saveAndGoNext}
              setStudents={setAllStudents}
              setSelectedStudent={setSelectedStudent}
              setHasChanges={setHasChanges}
              modalRef={modalRef}
              loadingDrawer={loadingDrawer}
              sessionMeetingId={meetingId}
            />
          )}
        </LumiarDrawer>
      </Wrapper>
    </>
  )
}
export default StudentsAssessments
