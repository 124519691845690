import { useTranslation } from 'react-i18next'
import { Grid, Button, TextLink, Menu } from 'components'
import { IconChartDonut3, IconChevronDown, IconUserPlus } from '@tabler/icons-react'

interface GroupActionsProps {
  groupURL: string
  viewDiscURL: string
  addStudentURL: string
}

export const GroupActions = ({ groupURL, viewDiscURL, addStudentURL }: GroupActionsProps) => {
  const { t } = useTranslation()

  return (
    <Grid justify='space-between' align='center'>
      <Grid.Col span={12} sm='auto'>
        <TextLink href={groupURL}>{t('new_teacher_dashboard.view_group_button')}</TextLink>
      </Grid.Col>
      <Grid.Col span={12} sm='content'>
        <Menu shadow='md'>
          <Menu.Target>
            <Button variant='light' leftIcon={<IconChevronDown />} size='md'>
              {t('general_buttons_actions')}
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item component='a' href={viewDiscURL} icon={<IconChartDonut3 />}>
              {t('new_teacher_dashboard.view_group_disc_button')}
            </Menu.Item>
            <Menu.Item component='a' href={addStudentURL} icon={<IconUserPlus />}>
              {t('new_teacher_dashboard.add_student_button')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Grid.Col>
    </Grid>
  )
}
