import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { CardActivityEditableProps } from './interfaces'
import { Card, Grid, Stack, Text, Group, Badge, Box, Anchor, ActionIcon } from 'components'
import { useMantineTheme, rem } from '@mantine/core'
import { IconGripVertical } from '@tabler/icons-react'
import { useStyles } from './styles'
import { LessonsContext } from 'components'
import { IconEdit, IconTrash } from '@tabler/icons-react'

export const CardActivityEditable = ({ provided, activity }: CardActivityEditableProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { handleViewActivityDetails, handleEditActivity, handleRemoveActivity } = useContext(LessonsContext)
  const theme = useMantineTheme()
  return (
    <Card shadow='md' withBorder mb='md' ref={provided.innerRef} {...provided.draggableProps}>
      <Grid align='center'>
        <Grid.Col span='content'>
          <Box {...provided.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical size={32} stroke={1.5} />
          </Box>
        </Grid.Col>
        <Grid.Col span={10} md='auto'>
          <Stack spacing={4}>
            <Text>{activity.title}</Text>
            <Group spacing='xs'>
              {activity.hasAssessment && (
                <Badge color='purple' size='md' variant='filled'>
                  {t('badges.badge_assessment')}
                </Badge>
              )}
              {activity.hasTasks && (
                <Badge color='blue' size='md' variant='filled'>
                  {t('badges.badge_deliverable')}
                </Badge>
              )}
            </Group>
          </Stack>
        </Grid.Col>
        <Grid.Col span='auto' md={2}>
          <Box sx={{ textAlign: 'right' }}>
            {activity.activityActions?.show && (
              <Anchor onClick={() => handleViewActivityDetails(activity.id)}>
                {t('new_project_page.activity_details')}
              </Anchor>
            )}
          </Box>
        </Grid.Col>
        <Grid.Col span='content'>
          <Group miw={rem(80)} spacing='sm' align='flex-start'>
            {activity.activityActions?.edit && (
              <ActionIcon color={theme.colors.turquoise[6]} onClick={() => handleEditActivity(activity.id)}>
                <IconEdit size={24} />
              </ActionIcon>
            )}
            {activity.activityActions?.delete && (
              <ActionIcon color={theme.colors.turquoise[6]} onClick={() => handleRemoveActivity(activity.id)}>
                <IconTrash size={24} />
              </ActionIcon>
            )}
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  )
}
