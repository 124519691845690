import { useContext } from 'react'
import { Card, Text, Button, Group, Tooltip, TextLink, Box, BreadCrumbCard } from 'components'
import { useMediaQuery, useMantineTheme } from 'hooks'
import { SkillsStandardsContext } from 'pages/NewProject/context/SkillsStandardsContext'
import { useTranslation } from 'react-i18next'
import { IconTrash } from '@tabler/icons-react'
import { useStyles } from './styles'
import { ICardSelectedItem } from './interfaces'

export const CardSelectedItem = ({ item }: ICardSelectedItem) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const { classes } = useStyles({ color: item.color })
  const { checkIfHasAssessment, handleRemoveItem, handleShowInfo, skillsStandards } = useContext(SkillsStandardsContext)
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const showCycleBadge = item.cycles && item.cycles.length > 0 && skillsStandards.byCycle === true

  return (
    <Card className={classes.card} shadow='md' withBorder>
      <Card.Section pt='md' px='md'>
        <BreadCrumbCard
          showCyclesBadges={showCycleBadge}
          cycles={item.cycles}
          breadcrumb={item.breadcrumb}
          color={item.color}
        />
      </Card.Section>
      <Card.Section mt='md' px='md' sx={{ flex: '1' }}>
        <Box>
          {item.originalCodes ? (
            <Text span fw='bold' size='md'>
              {item.originalCodes} -{' '}
            </Text>
          ) : null}
          <Text span size='md'>
            {item.name}
          </Text>
        </Box>
      </Card.Section>
      <Card.Section p='md'>
        <Group spacing='xs' position={item.descriptors.length > 0 ? 'apart' : 'right'}>
          {item.descriptors && item.descriptors.length > 0 ? (
            <TextLink size='md' onClick={() => handleShowInfo(item)}>
              {t('new_project_page.more_info')}
            </TextLink>
          ) : null}
          {checkIfHasAssessment(item.id, item.type) ? (
            <Tooltip label={t('new_project_page.has_assessment')} withArrow withinPortal multiline position='top'>
              <Button
                variant='light'
                color='red'
                size='md'
                data-disabled
                sx={{ '&[data-disabled]': { pointerEvents: 'all' } }}
                onClick={event => event.preventDefault()}
                leftIcon={<IconTrash size='1rem' />}
              >
                {t('general_buttons_remove')}
              </Button>
            </Tooltip>
          ) : (
            <Button
              fullWidth={isMobile}
              variant='light'
              size='md'
              color='red'
              leftIcon={<IconTrash size='1rem' />}
              onClick={() => handleRemoveItem(item.id, item.type)}
            >
              {t('general_buttons_remove')}
            </Button>
          )}
        </Group>
      </Card.Section>
      <Card.Section className={classes.cardBottom}></Card.Section>
    </Card>
  )
}
