
import { Breadcrumbs, Badge, Text } from 'components'
import { IconChevronRight } from '@tabler/icons-react'
import { useStyles } from './styles'
import { IBreadCrumbList } from './interfaces'

export function BreadCrumbList({ lvl1, lvl2, lvl3, color }: IBreadCrumbList) {
  const { classes } = useStyles({ color })

  return (
    <Breadcrumbs classNames={classes} separator={<IconChevronRight color={color} />}>
      <Badge className={classes.badge} size='lg' variant='filled'>
        {lvl1}
      </Badge>
      <Text className={classes.text} fw={500}>
        {lvl2}
      </Text>
      <Text className={classes.text}>
        {lvl3}
      </Text>
    </Breadcrumbs>
  )
}
