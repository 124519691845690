export const teacherMenu = [
  {
    link: '/teacher/dashboard',
    key: 'dashboard',
    icon: 'icon-home',
  },
  {
    link: '/projects',
    key: 'projects',
    icon: 'icon-activities',
  },
  {
    link: '/students',
    key: 'students',
    icon: 'icon-students',
  },
  {
    link: '/tasks',
    key: 'tasks',
    icon: 'icon-pendencies',
  },
  {
    link: '/projects_library',
    key: 'projects_hub',
    icon: 'icon-pen-ruler',
  },
  {
    link: '/reports/dashboard',
    key: 'reports',
    icon: 'icon-students-report',
  },
]