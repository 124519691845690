import { useTranslation } from 'react-i18next'
import { Button, Title, Text, BackgroundImage, Stack } from 'components'
import { Container } from '@mantine/core'
import notFoundImage from 'assets/not-found-img.png'
import { useStyles } from './styles'


export const NotFound = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <BackgroundImage src={notFoundImage} className={classes.backgroundBox}>
      <Container pt='xl'>
        <Stack spacing='md' align='center'>
          <Title order={1} align='center'>{t('not_found_page.title')}</Title>
          <Text align='center'>{t('not_found_page.message')}</Text>
          <Button variant='filled' size='lg' component='a' href='/'>
            {t('not_found_page.button_back')}
          </Button>
        </Stack>
      </Container>
    </BackgroundImage>
  )
}
