import { useRef } from 'react'
import { ActionIcon } from '@mantine/core'
import { TimeInput } from '@mantine/dates'
import { IconClock } from '@tabler/icons-react'


export const CustomTimeInput = ({ ...props }) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <TimeInput
      ref={ref}
      rightSection={
        <ActionIcon onClick={() => ref.current?.showPicker()}>
          <IconClock size='1rem' stroke={1.5} />
        </ActionIcon>
      }
      {...props}
    />
  )
}
