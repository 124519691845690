import { useContext } from 'react'
import { GuardianDashboardContext } from '../../context/GuardianDashboardContext'
import { Grid, Button } from 'components'
import { IconLayoutGrid, IconFileReport } from '@tabler/icons-react' 
import { CardActionsProps } from './intefaces'
import { useTranslation } from 'react-i18next'

export const CardActions = ({ project }: CardActionsProps) => {
  const { getMuralURL, getReportURL } = useContext(GuardianDashboardContext)

  const { t } = useTranslation()
 
  return (
    <Grid justify='flex-start' gutter='sm'>
      <Grid.Col span={12} sm={project.status === 'draft' ? 'auto' : 'auto'}>
        <Button
          fullWidth
          variant='light'
          size='md'
          radius='xl'
          leftIcon={<IconLayoutGrid />}
          component='a'
          href={getMuralURL(project.id)}
        >
          {t('new_guardian_dashboard.button_view_project_wall')}
        </Button>
      </Grid.Col>
      {project.status === 'finished' && (
        <Grid.Col span={12} sm='auto'>
          <Button
            fullWidth
            leftIcon={<IconFileReport />}
            variant='light'
            size='md'
            radius='xl'
            component='a'
            href={getReportURL(project.id)}
          >
            {t('new_guardian_dashboard.button_view_final_report')}
          </Button>
        </Grid.Col>
      )}
    </Grid>
  )
}
