import { IGoogleDriveFile, IReceivedGoogleDriveFiles } from '@legacy/common/types/GoogleDriveFiles'
import GoogleDriveFormatter from '../formaters/google-drive-formatter'
import Request from './base-request'

const paths = {
  getListFiles: (resourceClass: string, resourceId: string) =>
    `/api/google_drive_files?${resourceClass}=${resourceId}`,
  deleteFile: (fileId: string) => `/api/google_drive_files/${fileId}`
}

export default {
  activity: {
    getListFiles: async ({ resourceClass, resourceId }: { resourceClass: string, resourceId: string }) => {
      const response = await Request.get<IReceivedGoogleDriveFiles>(paths.getListFiles(resourceClass, resourceId))

      return response
    },
    postFile: async ({
      resourceClass,
      resourceId,
      file,
    }: {
      resourceClass: string
      resourceId: string
      file: IGoogleDriveFile
    }) => {
      const response = await Request.create(
        paths.getListFiles(resourceClass, resourceId),
        GoogleDriveFormatter.toApi(file),
      )

      return response
    },
    deleteFile: async ({
      fileId
    }: {
      fileId: string
    }) => {
      const response = await Request.remove(paths.deleteFile(fileId))
      return response
    },
  }
}
