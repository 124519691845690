import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useMantineTheme, ScrollArea } from 'hooks'
import { ISchoolTerm } from '../../interfaces'
import { IOldSchoolTerm, IProjectFilterFormValues, initialValues } from './interfaces'
import { Modal, Select, MultiSelect, Stack, Button, Box, Grid, Skeleton } from 'components'
import { ProjectListContext } from '../../context/ProjectListContext'
import { useForm, notifications, useCurrentUser } from 'context'
import { IconCheck, IconX } from '@tabler/icons-react'
import { useStyles } from './styles'
import { getSchoolTerms, getOldSchoolTerms, getModalityFinalProductList } from 'api'
import { SelectItem } from '@mantine/core/lib/Select/types'
import { isEqual } from 'lodash'

export const ProjectFilterModal = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const {
    openedProjectFilterModal,
    closeProjectFilterModal,
    setFilterSchoolTermID,
    setFilterFinalProduct,
    setFilterGroup,
    setFilterCycle,
    setFilterActive,
    error,
    setError,
  } = useContext(ProjectListContext)
  const theme = useMantineTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const { classes } = useStyles()
  const [schoolTermSelect, setSchoolTermSelect] = useState<SelectItem[]>([])
  const [oldSchoolTerm, setOldSchoolTerm] = useState<IOldSchoolTerm[]>([])
  const [finalProductKindList, setFinalProductKindList] = useState<(string | SelectItem)[]>([])
  const [cycleSelect, setCycleSelect] = useState<SelectItem[]>([])
  const [groupSelect, setGroupSelect] = useState<SelectItem[]>([])

  const convertSchoolTermToSelect = (schoolTermList: ISchoolTerm[]) => {
    const schoolTermSelect = schoolTermList.map(schoolTerm => {
      const label = schoolTerm.current
        ? schoolTerm.label + ' ' + t('new_projects_list.label_current')
        : schoolTerm.label
      return {
        value: schoolTerm.value,
        label: label,
      }
    })
    setSchoolTermSelect(schoolTermSelect)
  }

  const form = useForm<IProjectFilterFormValues>({
    initialValues,
  })

  const handleForm = async (values: IProjectFilterFormValues) => {
    if (isEqual(values, initialValues)) {
      handleClear(false)
    }
    if (form.isDirty() && !isEqual(values, initialValues)) {
      setFilterSchoolTermID(values.schoolTerm)
      setFilterGroup(values.group)
      setFilterCycle(values.cycle)
      setFilterFinalProduct(values.finalProduct)
      handleSuccess()
    }
  }

  const handleErrors = (errors: unknown) => {
    if (errors && Object.keys(errors).length > 0) {
      notifications.show({
        message: t('fill_the_required_fields'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
  }

  const handleSuccess = () => {
    setFilterActive(true)
    closeProjectFilterModal()
    notifications.show({
      message: t('new_projects_list.message_filters_applied'),
      color: 'green',
      icon: <IconCheck size={24} />,
    })
  }

  const handleClear = (button: boolean) => {
    if (button) {
      notifications.show({
        message: t('new_projects_list.message_filters_cleared'),
        color: 'green',
        icon: <IconCheck size={24} />,
      })
      form.reset()
    }
    setFilterActive(false)
    setFilterCycle(null)
    setFilterGroup(null)
    setFilterSchoolTermID(null)
    setFilterFinalProduct(null)
    closeProjectFilterModal()
  }

  const handleClose = () => {
    form.reset()
    closeProjectFilterModal()
  }
  useEffect(() => {
    if (error !== null) {
      notifications.show({
        title: t('general_error_title'),
        message: t('general_error_message_data_not_loaded'),
        color: 'red',
        icon: <IconX size={24} />,
      })
    }
    setError(null)
  }, [error])

  useEffect(() => {
    if (
      schoolTermSelect.length > 0 &&
      oldSchoolTerm.length > 0 &&
      currentUser?.profile_used === 'manager' &&
      form.values.schoolTerm !== null
    ) {
      const filteredSchoolTermItem = oldSchoolTerm.filter(
        item => item.id === form.values.schoolTerm,
      )[0]

      const groupSelect = [] as SelectItem[]
      const cycleSelect = [] as SelectItem[]

      filteredSchoolTermItem.groupSchoolTerms.length > 0 &&
        filteredSchoolTermItem.groupSchoolTerms.map(group => {
          const groupIndex = groupSelect.findIndex(groupSelect => groupSelect.value === group.id)
          if (groupIndex === -1)
            groupSelect.push({
              value: group.id,
              label: group.name,
            })
          const index = cycleSelect.findIndex(cycle => cycle.value === group.cycle.id)
          if (index === -1)
            cycleSelect.push({
              value: group.cycle.id,
              label: group.cycle.name,
            })
        })
      setGroupSelect(groupSelect)
      setCycleSelect(cycleSelect)

    }
  }, [schoolTermSelect, oldSchoolTerm, currentUser, form.values.schoolTerm])

  useEffect(() => {
    const getData = () => {
      getOldSchoolTerms()
        .then(res => {
          if (res.status === 200) {
            const oldSchoolTermList = res.data.schoolTerms
            setOldSchoolTerm(oldSchoolTermList)
          } else {
            setError(res.statusText)
          }
        })
        .catch(err => {
          setError(err.message)
        })
      getSchoolTerms()
        .then(res => {
          if (res.status === 200) {
            const schoolTermList = res
            convertSchoolTermToSelect(schoolTermList.data)
          } else {
            setError(res.statusText)
          }
        })
        .catch(err => {
          setError(err.message)
        })
      getModalityFinalProductList()
        .then(res => {
          if (res.status === 200) {
            const finalProductKindList = res.data.finalProductKinds
            setFinalProductKindList(finalProductKindList)
          } else {
            setError(res.statusText)
          }
        })
        .catch(err => {
          setError(err.message)
        })
    }
    getData()
  }, [])

  return (
    <Modal
      zIndex={200}
      size='lg'
      title={t('projects_filter')}
      opened={openedProjectFilterModal}
      onClose={closeProjectFilterModal}
      scrollAreaComponent={ScrollArea.Autosize}
      fullScreen={isMobile}
      classNames={classes}
    >
      {finalProductKindList.length > 0 && schoolTermSelect.length > 0 ? (
        <form className={classes.form} onSubmit={form.onSubmit(handleForm, handleErrors)}>
          <Stack spacing='md'>
            <Select
              size='md'
              name='schoolTerm'
              label={t('new_projects_list.label_school_term')}
              data={schoolTermSelect}
              withinPortal
              clearable
              {...form.getInputProps('schoolTerm')}
            ></Select>
            {currentUser?.profile_used === 'manager' && form.values.schoolTerm !== '' ? (
              <>
                <Select
                  size='md'
                  name='cycle'
                  label={t('new_projects_list.label_cycle')}
                  data={cycleSelect}
                  withinPortal
                  clearable
                  disabled={cycleSelect.length === 0}
                  {...form.getInputProps('cycle')}
                ></Select>
                <Select
                  size='md'
                  name='group'
                  label={t('new_projects_list.label_group')}
                  data={groupSelect}
                  withinPortal
                  clearable
                  disabled={groupSelect.length === 0}
                  {...form.getInputProps('group')}
                ></Select>
              </>
            ) : null}
            <MultiSelect
              size='md'
              name='finalProduct'
              label={t('new_projects_list.label_final_product')}
              data={finalProductKindList}
              withinPortal
              searchable
              {...form.getInputProps('finalProduct')}
            />
            <Box className={classes.footer}>
              <Grid grow sx={{ width: '100%' }} p={0} m={0}>
                <Grid.Col span={12} sm={3}>
                  <Button fullWidth variant='default' size='lg' radius='xl' onClick={handleClose}>
                    {t('general_buttons_cancel')}
                  </Button>
                </Grid.Col>
                <Grid.Col span={12} offset={0} sm={3} offsetSm={3}>
                  <Button fullWidth variant='default' size='lg' radius='xl' onClick={() => handleClear(true)}>
                    {t('general_buttons_filters_clear')}
                  </Button>
                </Grid.Col>
                <Grid.Col span={12} sm={3}>
                  <Button type='submit' fullWidth variant='filled' size='lg' radius='xl'>
                    {t('general_buttons_filters_apply')}
                  </Button>
                </Grid.Col>
              </Grid>
            </Box>
          </Stack>
        </form>
      ) : (
        <Stack spacing='md'>
          {Array.from({ length: 2 }).map((_, index) => (
            <Stack spacing='sm' key={index}>
              <Skeleton height={50} />
              <Skeleton height={50} />
            </Stack>
          ))}
        </Stack>
      )}
    </Modal>
  )
}
