import { createStyles, rem, em, getBreakpointValue } from 'hooks'

export const useStyles = createStyles(theme => ({
  root: {
    position: 'relative',
  },
  body: {
    position: 'relative',
    height: 'fit-content',
  },
  form: {
    position: 'relative',
    paddingBottom: rem(90),
    height: 'fit-content',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: theme.spacing.md,
    gap: theme.spacing.md,
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : 'white',
    borderTop: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.gray[2]}` : `1px solid ${theme.colors.gray[2]}`,
  },
  iconBox: {
    display: 'flex',
    alignContent: 'center',
    [`@media (max-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      justifyContent: 'flex-start',
      padding: `${theme.spacing.md} 0 0 ${theme.spacing.xs}`,
    },
    [`@media (min-width: ${em(getBreakpointValue(theme.breakpoints.sm) - 1)})`]: {
      justifyContent: 'center',
    },
  },
}))
