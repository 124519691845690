import { useTranslation } from 'react-i18next'
import { useCurrentUser } from 'context/useCurrentUser'
import { Avatar } from 'components/Avatar'
import { ProfileContainerStyled } from './ProfileStyled'

export const Profile = () => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  return (
    <ProfileContainerStyled
      data-intro={t('dashboards_onboarding_steps_step5')}
      data-step='5'>
      <Avatar
        image={currentUser?.avatar_url || ''}
        name={currentUser?.full_name || ''}
        size="small"
      />
    </ProfileContainerStyled>
  )
}
